 
@font-face {
    font-display: swap;
    font-family: 'FontAwesome';
    /* src: url('../themes/responsiv-clean/assets/vendors/font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('../themes/responsiv-clean/assets/vendors/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),url('../themes/responsiv-clean/assets/vendors/font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),url('../themes/responsiv-clean/assets/vendors/font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),url('../themes/responsiv-clean/assets/vendors/font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),url('../themes/responsiv-clean/assets/vendors/font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg'); */
    font-weight: normal;
    font-style: normal
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.fa-lg {
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%
}

.fa-2x {
    font-size: 2em
}

.fa-3x {
    font-size: 3em
}

.fa-4x {
    font-size: 4em
}

.fa-5x {
    font-size: 5em
}

.fa-fw {
    width: 1.28571429em;
    text-align: center
}

.fa-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none
}

.fa-ul>li {
    position: relative
}

.fa-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: .14285714em;
    text-align: center
}

.fa-li.fa-lg {
    left: -1.85714286em
}

.fa-border {
    padding: .2em .25em .15em;
    border: solid .08em #eee;
    border-radius: .1em
}

.fa-pull-left {
    float: left
}

.fa-pull-right {
    float: right
}

.fa.fa-pull-left {
    margin-right: .3em
}

.fa.fa-pull-right {
    margin-left: .3em
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

.fa.pull-left {
    margin-right: .3em
}

.fa.pull-right {
    margin-left: .3em
}

.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear
}

.fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8)
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.fa-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.fa-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.fa-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg)
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0,mirror=1)";
    -webkit-transform: scale(-1,1);
    -ms-transform: scale(-1,1);
    transform: scale(-1,1)
}

.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2,mirror=1)";
    -webkit-transform: scale(1,-1);
    -ms-transform: scale(1,-1);
    transform: scale(1,-1)
}

:root .fa-rotate-90,:root .fa-rotate-180,:root .fa-rotate-270,:root .fa-flip-horizontal,:root .fa-flip-vertical {
    filter: none
}

.fa-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle
}

.fa-stack-1x,.fa-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center
}

.fa-stack-1x {
    line-height: inherit
}

.fa-stack-2x {
    font-size: 2em
}

.fa-inverse {
    color: #fff
}

.fa-glass:before {
    content: "\f000"
}

.fa-music:before {
    content: "\f001"
}

.fa-search:before {
    content: "\f002"
}

.fa-envelope-o:before {
    content: "\f003"
}

.fa-heart:before {
    content: "\f004"
}

.fa-star:before {
    content: "\f005"
}

.fa-star-o:before {
    content: "\f006"
}

.fa-user:before {
    content: "\f007"
}

.fa-film:before {
    content: "\f008"
}

.fa-th-large:before {
    content: "\f009"
}

.fa-th:before {
    content: "\f00a"
}

.fa-th-list:before {
    content: "\f00b"
}

.fa-check:before {
    content: "\f00c"
}

.fa-remove:before,.fa-close:before,.fa-times:before {
    content: "\f00d"
}

.fa-search-plus:before {
    content: "\f00e"
}

.fa-search-minus:before {
    content: "\f010"
}

.fa-power-off:before {
    content: "\f011"
}

.fa-signal:before {
    content: "\f012"
}

.fa-gear:before,.fa-cog:before {
    content: "\f013"
}

.fa-trash-o:before {
    content: "\f014"
}

.fa-home:before {
    content: "\f015"
}

.fa-file-o:before {
    content: "\f016"
}

.fa-clock-o:before {
    content: "\f017"
}

.fa-road:before {
    content: "\f018"
}

.fa-download:before {
    content: "\f019"
}

.fa-arrow-circle-o-down:before {
    content: "\f01a"
}

.fa-arrow-circle-o-up:before {
    content: "\f01b"
}

.fa-inbox:before {
    content: "\f01c"
}

.fa-play-circle-o:before {
    content: "\f01d"
}

.fa-rotate-right:before,.fa-repeat:before {
    content: "\f01e"
}

.fa-refresh:before {
    content: "\f021"
}

.fa-list-alt:before {
    content: "\f022"
}

.fa-lock:before {
    content: "\f023"
}

.fa-flag:before {
    content: "\f024"
}

.fa-headphones:before {
    content: "\f025"
}

.fa-volume-off:before {
    content: "\f026"
}

.fa-volume-down:before {
    content: "\f027"
}

.fa-volume-up:before {
    content: "\f028"
}

.fa-qrcode:before {
    content: "\f029"
}

.fa-barcode:before {
    content: "\f02a"
}

.fa-tag:before {
    content: "\f02b"
}

.fa-tags:before {
    content: "\f02c"
}

.fa-book:before {
    content: "\f02d"
}

.fa-bookmark:before {
    content: "\f02e"
}

.fa-print:before {
    content: "\f02f"
}

.fa-camera:before {
    content: "\f030"
}

.fa-font:before {
    content: "\f031"
}

.fa-bold:before {
    content: "\f032"
}

.fa-italic:before {
    content: "\f033"
}

.fa-text-height:before {
    content: "\f034"
}

.fa-text-width:before {
    content: "\f035"
}

.fa-align-left:before {
    content: "\f036"
}

.fa-align-center:before {
    content: "\f037"
}

.fa-align-right:before {
    content: "\f038"
}

.fa-align-justify:before {
    content: "\f039"
}

.fa-list:before {
    content: "\f03a"
}

.fa-dedent:before,.fa-outdent:before {
    content: "\f03b"
}

.fa-indent:before {
    content: "\f03c"
}

.fa-video-camera:before {
    content: "\f03d"
}

.fa-photo:before,.fa-image:before,.fa-picture-o:before {
    content: "\f03e"
}

.fa-pencil:before {
    content: "\f040"
}

.fa-map-marker:before {
    content: "\f041"
}

.fa-adjust:before {
    content: "\f042"
}

.fa-tint:before {
    content: "\f043"
}

.fa-edit:before,.fa-pencil-square-o:before {
    content: "\f044"
}

.fa-share-square-o:before {
    content: "\f045"
}

.fa-check-square-o:before {
    content: "\f046"
}

.fa-arrows:before {
    content: "\f047"
}

.fa-step-backward:before {
    content: "\f048"
}

.fa-fast-backward:before {
    content: "\f049"
}

.fa-backward:before {
    content: "\f04a"
}

.fa-play:before {
    content: "\f04b"
}

.fa-pause:before {
    content: "\f04c"
}

.fa-stop:before {
    content: "\f04d"
}

.fa-forward:before {
    content: "\f04e"
}

.fa-fast-forward:before {
    content: "\f050"
}

.fa-step-forward:before {
    content: "\f051"
}

.fa-eject:before {
    content: "\f052"
}

.fa-chevron-left:before {
    content: "\f053"
}

.fa-chevron-right:before {
    content: "\f054"
}

.fa-plus-circle:before {
    content: "\f055"
}

.fa-minus-circle:before {
    content: "\f056"
}

.fa-times-circle:before {
    content: "\f057"
}

.fa-check-circle:before {
    content: "\f058"
}

.fa-question-circle:before {
    content: "\f059"
}

.fa-info-circle:before {
    content: "\f05a"
}

.fa-crosshairs:before {
    content: "\f05b"
}

.fa-times-circle-o:before {
    content: "\f05c"
}

.fa-check-circle-o:before {
    content: "\f05d"
}

.fa-ban:before {
    content: "\f05e"
}

.fa-arrow-left:before {
    content: "\f060"
}

.fa-arrow-right:before {
    content: "\f061"
}

.fa-arrow-up:before {
    content: "\f062"
}

.fa-arrow-down:before {
    content: "\f063"
}

.fa-mail-forward:before,.fa-share:before {
    content: "\f064"
}

.fa-expand:before {
    content: "\f065"
}

.fa-compress:before {
    content: "\f066"
}

.fa-plus:before {
    content: "\f067"
}

.fa-minus:before {
    content: "\f068"
}

.fa-asterisk:before {
    content: "\f069"
}

.fa-exclamation-circle:before {
    content: "\f06a"
}

.fa-gift:before {
    content: "\f06b"
}

.fa-leaf:before {
    content: "\f06c"
}

.fa-fire:before {
    content: "\f06d"
}

.fa-eye:before {
    content: "\f06e"
}

.fa-eye-slash:before {
    content: "\f070"
}

.fa-warning:before,.fa-exclamation-triangle:before {
    content: "\f071"
}

.fa-plane:before {
    content: "\f072"
}

.fa-calendar:before {
    content: "\f073"
}

.fa-random:before {
    content: "\f074"
}

.fa-comment:before {
    content: "\f075"
}

.fa-magnet:before {
    content: "\f076"
}

.fa-chevron-up:before {
    content: "\f077"
}

.fa-chevron-down:before {
    content: "\f078"
}

.fa-retweet:before {
    content: "\f079"
}

.fa-shopping-cart:before {
    content: "\f07a"
}

.fa-folder:before {
    content: "\f07b"
}

.fa-folder-open:before {
    content: "\f07c"
}

.fa-arrows-v:before {
    content: "\f07d"
}

.fa-arrows-h:before {
    content: "\f07e"
}

.fa-bar-chart-o:before,.fa-bar-chart:before {
    content: "\f080"
}

.fa-twitter-square:before {
    content: "\f081"
}

.fa-facebook-square:before {
    content: "\f082"
}

.fa-camera-retro:before {
    content: "\f083"
}

.fa-key:before {
    content: "\f084"
}

.fa-gears:before,.fa-cogs:before {
    content: "\f085"
}

.fa-comments:before {
    content: "\f086"
}

.fa-thumbs-o-up:before {
    content: "\f087"
}

.fa-thumbs-o-down:before {
    content: "\f088"
}

.fa-star-half:before {
    content: "\f089"
}

.fa-heart-o:before {
    content: "\f08a"
}

.fa-sign-out:before {
    content: "\f08b"
}

.fa-linkedin-square:before {
    content: "\f08c"
}

.fa-thumb-tack:before {
    content: "\f08d"
}

.fa-external-link:before {
    content: "\f08e"
}

.fa-sign-in:before {
    content: "\f090"
}

.fa-trophy:before {
    content: "\f091"
}

.fa-github-square:before {
    content: "\f092"
}

.fa-upload:before {
    content: "\f093"
}

.fa-lemon-o:before {
    content: "\f094"
}

.fa-phone:before {
    content: "\f095"
}

.fa-square-o:before {
    content: "\f096"
}

.fa-bookmark-o:before {
    content: "\f097"
}

.fa-phone-square:before {
    content: "\f098"
}

.fa-twitter:before {
    content: "\f099"
}

.fa-facebook-f:before,.fa-facebook:before {
    content: "\f09a"
}

.fa-github:before {
    content: "\f09b"
}

.fa-unlock:before {
    content: "\f09c"
}

.fa-credit-card:before {
    content: "\f09d"
}

.fa-feed:before,.fa-rss:before {
    content: "\f09e"
}

.fa-hdd-o:before {
    content: "\f0a0"
}

.fa-bullhorn:before {
    content: "\f0a1"
}

.fa-bell:before {
    content: "\f0f3"
}

.fa-certificate:before {
    content: "\f0a3"
}

.fa-hand-o-right:before {
    content: "\f0a4"
}

.fa-hand-o-left:before {
    content: "\f0a5"
}

.fa-hand-o-up:before {
    content: "\f0a6"
}

.fa-hand-o-down:before {
    content: "\f0a7"
}

.fa-arrow-circle-left:before {
    content: "\f0a8"
}

.fa-arrow-circle-right:before {
    content: "\f0a9"
}

.fa-arrow-circle-up:before {
    content: "\f0aa"
}

.fa-arrow-circle-down:before {
    content: "\f0ab"
}

.fa-globe:before {
    content: "\f0ac"
}

.fa-wrench:before {
    content: "\f0ad"
}

.fa-tasks:before {
    content: "\f0ae"
}

.fa-filter:before {
    content: "\f0b0"
}

.fa-briefcase:before {
    content: "\f0b1"
}

.fa-arrows-alt:before {
    content: "\f0b2"
}

.fa-group:before,.fa-users:before {
    content: "\f0c0"
}

.fa-chain:before,.fa-link:before {
    content: "\f0c1"
}

.fa-cloud:before {
    content: "\f0c2"
}

.fa-flask:before {
    content: "\f0c3"
}

.fa-cut:before,.fa-scissors:before {
    content: "\f0c4"
}

.fa-copy:before,.fa-files-o:before {
    content: "\f0c5"
}

.fa-paperclip:before {
    content: "\f0c6"
}

.fa-save:before,.fa-floppy-o:before {
    content: "\f0c7"
}

.fa-square:before {
    content: "\f0c8"
}

.fa-navicon:before,.fa-reorder:before,.fa-bars:before {
    content: "\f0c9"
}

.fa-list-ul:before {
    content: "\f0ca"
}

.fa-list-ol:before {
    content: "\f0cb"
}

.fa-strikethrough:before {
    content: "\f0cc"
}

.fa-underline:before {
    content: "\f0cd"
}

.fa-table:before {
    content: "\f0ce"
}

.fa-magic:before {
    content: "\f0d0"
}

.fa-truck:before {
    content: "\f0d1"
}

.fa-pinterest:before {
    content: "\f0d2"
}

.fa-pinterest-square:before {
    content: "\f0d3"
}

.fa-google-plus-square:before {
    content: "\f0d4"
}

.fa-google-plus:before {
    content: "\f0d5"
}

.fa-money:before {
    content: "\f0d6"
}

.fa-caret-down:before {
    content: "\f0d7"
}

.fa-caret-up:before {
    content: "\f0d8"
}

.fa-caret-left:before {
    content: "\f0d9"
}

.fa-caret-right:before {
    content: "\f0da"
}

.fa-columns:before {
    content: "\f0db"
}

.fa-unsorted:before,.fa-sort:before {
    content: "\f0dc"
}

.fa-sort-down:before,.fa-sort-desc:before {
    content: "\f0dd"
}

.fa-sort-up:before,.fa-sort-asc:before {
    content: "\f0de"
}

.fa-envelope:before {
    content: "\f0e0"
}

.fa-linkedin:before {
    content: "\f0e1"
}

.fa-rotate-left:before,.fa-undo:before {
    content: "\f0e2"
}

.fa-legal:before,.fa-gavel:before {
    content: "\f0e3"
}

.fa-dashboard:before,.fa-tachometer:before {
    content: "\f0e4"
}

.fa-comment-o:before {
    content: "\f0e5"
}

.fa-comments-o:before {
    content: "\f0e6"
}

.fa-flash:before,.fa-bolt:before {
    content: "\f0e7"
}

.fa-sitemap:before {
    content: "\f0e8"
}

.fa-umbrella:before {
    content: "\f0e9"
}

.fa-paste:before,.fa-clipboard:before {
    content: "\f0ea"
}

.fa-lightbulb-o:before {
    content: "\f0eb"
}

.fa-exchange:before {
    content: "\f0ec"
}

.fa-cloud-download:before {
    content: "\f0ed"
}

.fa-cloud-upload:before {
    content: "\f0ee"
}

.fa-user-md:before {
    content: "\f0f0"
}

.fa-stethoscope:before {
    content: "\f0f1"
}

.fa-suitcase:before {
    content: "\f0f2"
}

.fa-bell-o:before {
    content: "\f0a2"
}

.fa-coffee:before {
    content: "\f0f4"
}

.fa-cutlery:before {
    content: "\f0f5"
}

.fa-file-text-o:before {
    content: "\f0f6"
}

.fa-building-o:before {
    content: "\f0f7"
}

.fa-hospital-o:before {
    content: "\f0f8"
}

.fa-ambulance:before {
    content: "\f0f9"
}

.fa-medkit:before {
    content: "\f0fa"
}

.fa-fighter-jet:before {
    content: "\f0fb"
}

.fa-beer:before {
    content: "\f0fc"
}

.fa-h-square:before {
    content: "\f0fd"
}

.fa-plus-square:before {
    content: "\f0fe"
}

.fa-angle-double-left:before {
    content: "\f100"
}

.fa-angle-double-right:before {
    content: "\f101"
}

.fa-angle-double-up:before {
    content: "\f102"
}

.fa-angle-double-down:before {
    content: "\f103"
}

.fa-angle-left:before {
    content: "\f104"
}

.fa-angle-right:before {
    content: "\f105"
}

.fa-angle-up:before {
    content: "\f106"
}

.fa-angle-down:before {
    content: "\f107"
}

.fa-desktop:before {
    content: "\f108"
}

.fa-laptop:before {
    content: "\f109"
}

.fa-tablet:before {
    content: "\f10a"
}

.fa-mobile-phone:before,.fa-mobile:before {
    content: "\f10b"
}

.fa-circle-o:before {
    content: "\f10c"
}

.fa-quote-left:before {
    content: "\f10d"
}

.fa-quote-right:before {
    content: "\f10e"
}

.fa-spinner:before {
    content: "\f110"
}

.fa-circle:before {
    content: "\f111"
}

.fa-mail-reply:before,.fa-reply:before {
    content: "\f112"
}

.fa-github-alt:before {
    content: "\f113"
}

.fa-folder-o:before {
    content: "\f114"
}

.fa-folder-open-o:before {
    content: "\f115"
}

.fa-smile-o:before {
    content: "\f118"
}

.fa-frown-o:before {
    content: "\f119"
}

.fa-meh-o:before {
    content: "\f11a"
}

.fa-gamepad:before {
    content: "\f11b"
}

.fa-keyboard-o:before {
    content: "\f11c"
}

.fa-flag-o:before {
    content: "\f11d"
}

.fa-flag-checkered:before {
    content: "\f11e"
}

.fa-terminal:before {
    content: "\f120"
}

.fa-code:before {
    content: "\f121"
}

.fa-mail-reply-all:before,.fa-reply-all:before {
    content: "\f122"
}

.fa-star-half-empty:before,.fa-star-half-full:before,.fa-star-half-o:before {
    content: "\f123"
}

.fa-location-arrow:before {
    content: "\f124"
}

.fa-crop:before {
    content: "\f125"
}

.fa-code-fork:before {
    content: "\f126"
}

.fa-unlink:before,.fa-chain-broken:before {
    content: "\f127"
}

.fa-question:before {
    content: "\f128"
}

.fa-info:before {
    content: "\f129"
}

.fa-exclamation:before {
    content: "\f12a"
}

.fa-superscript:before {
    content: "\f12b"
}

.fa-subscript:before {
    content: "\f12c"
}

.fa-eraser:before {
    content: "\f12d"
}

.fa-puzzle-piece:before {
    content: "\f12e"
}

.fa-microphone:before {
    content: "\f130"
}

.fa-microphone-slash:before {
    content: "\f131"
}

.fa-shield:before {
    content: "\f132"
}

.fa-calendar-o:before {
    content: "\f133"
}

.fa-fire-extinguisher:before {
    content: "\f134"
}

.fa-rocket:before {
    content: "\f135"
}

.fa-maxcdn:before {
    content: "\f136"
}

.fa-chevron-circle-left:before {
    content: "\f137"
}

.fa-chevron-circle-right:before {
    content: "\f138"
}

.fa-chevron-circle-up:before {
    content: "\f139"
}

.fa-chevron-circle-down:before {
    content: "\f13a"
}

.fa-html5:before {
    content: "\f13b"
}

.fa-css3:before {
    content: "\f13c"
}

.fa-anchor:before {
    content: "\f13d"
}

.fa-unlock-alt:before {
    content: "\f13e"
}

.fa-bullseye:before {
    content: "\f140"
}

.fa-ellipsis-h:before {
    content: "\f141"
}

.fa-ellipsis-v:before {
    content: "\f142"
}

.fa-rss-square:before {
    content: "\f143"
}

.fa-play-circle:before {
    content: "\f144"
}

.fa-ticket:before {
    content: "\f145"
}

.fa-minus-square:before {
    content: "\f146"
}

.fa-minus-square-o:before {
    content: "\f147"
}

.fa-level-up:before {
    content: "\f148"
}

.fa-level-down:before {
    content: "\f149"
}

.fa-check-square:before {
    content: "\f14a"
}

.fa-pencil-square:before {
    content: "\f14b"
}

.fa-external-link-square:before {
    content: "\f14c"
}

.fa-share-square:before {
    content: "\f14d"
}

.fa-compass:before {
    content: "\f14e"
}

.fa-toggle-down:before,.fa-caret-square-o-down:before {
    content: "\f150"
}

.fa-toggle-up:before,.fa-caret-square-o-up:before {
    content: "\f151"
}

.fa-toggle-right:before,.fa-caret-square-o-right:before {
    content: "\f152"
}

.fa-euro:before,.fa-eur:before {
    content: "\f153"
}

.fa-gbp:before {
    content: "\f154"
}

.fa-dollar:before,.fa-usd:before {
    content: "\f155"
}

.fa-rupee:before,.fa-inr:before {
    content: "\f156"
}

.fa-cny:before,.fa-rmb:before,.fa-yen:before,.fa-jpy:before {
    content: "\f157"
}

.fa-ruble:before,.fa-rouble:before,.fa-rub:before {
    content: "\f158"
}

.fa-won:before,.fa-krw:before {
    content: "\f159"
}

.fa-bitcoin:before,.fa-btc:before {
    content: "\f15a"
}

.fa-file:before {
    content: "\f15b"
}

.fa-file-text:before {
    content: "\f15c"
}

.fa-sort-alpha-asc:before {
    content: "\f15d"
}

.fa-sort-alpha-desc:before {
    content: "\f15e"
}

.fa-sort-amount-asc:before {
    content: "\f160"
}

.fa-sort-amount-desc:before {
    content: "\f161"
}

.fa-sort-numeric-asc:before {
    content: "\f162"
}

.fa-sort-numeric-desc:before {
    content: "\f163"
}

.fa-thumbs-up:before {
    content: "\f164"
}

.fa-thumbs-down:before {
    content: "\f165"
}

.fa-youtube-square:before {
    content: "\f166"
}

.fa-youtube:before {
    content: "\f167"
}

.fa-xing:before {
    content: "\f168"
}

.fa-xing-square:before {
    content: "\f169"
}

.fa-youtube-play:before {
    content: "\f16a"
}

.fa-dropbox:before {
    content: "\f16b"
}

.fa-stack-overflow:before {
    content: "\f16c"
}

.fa-instagram:before {
    content: "\f16d"
}

.fa-flickr:before {
    content: "\f16e"
}

.fa-adn:before {
    content: "\f170"
}

.fa-bitbucket:before {
    content: "\f171"
}

.fa-bitbucket-square:before {
    content: "\f172"
}

.fa-tumblr:before {
    content: "\f173"
}

.fa-tumblr-square:before {
    content: "\f174"
}

.fa-long-arrow-down:before {
    content: "\f175"
}

.fa-long-arrow-up:before {
    content: "\f176"
}

.fa-long-arrow-left:before {
    content: "\f177"
}

.fa-long-arrow-right:before {
    content: "\f178"
}

.fa-apple:before {
    content: "\f179"
}

.fa-windows:before {
    content: "\f17a"
}

.fa-android:before {
    content: "\f17b"
}

.fa-linux:before {
    content: "\f17c"
}

.fa-dribbble:before {
    content: "\f17d"
}

.fa-skype:before {
    content: "\f17e"
}

.fa-foursquare:before {
    content: "\f180"
}

.fa-trello:before {
    content: "\f181"
}

.fa-female:before {
    content: "\f182"
}

.fa-male:before {
    content: "\f183"
}

.fa-gittip:before,.fa-gratipay:before {
    content: "\f184"
}

.fa-sun-o:before {
    content: "\f185"
}

.fa-moon-o:before {
    content: "\f186"
}

.fa-archive:before {
    content: "\f187"
}

.fa-bug:before {
    content: "\f188"
}

.fa-vk:before {
    content: "\f189"
}

.fa-weibo:before {
    content: "\f18a"
}

.fa-renren:before {
    content: "\f18b"
}

.fa-pagelines:before {
    content: "\f18c"
}

.fa-stack-exchange:before {
    content: "\f18d"
}

.fa-arrow-circle-o-right:before {
    content: "\f18e"
}

.fa-arrow-circle-o-left:before {
    content: "\f190"
}

.fa-toggle-left:before,.fa-caret-square-o-left:before {
    content: "\f191"
}

.fa-dot-circle-o:before {
    content: "\f192"
}

.fa-wheelchair:before {
    content: "\f193"
}

.fa-vimeo-square:before {
    content: "\f194"
}

.fa-turkish-lira:before,.fa-try:before {
    content: "\f195"
}

.fa-plus-square-o:before {
    content: "\f196"
}

.fa-space-shuttle:before {
    content: "\f197"
}

.fa-slack:before {
    content: "\f198"
}

.fa-envelope-square:before {
    content: "\f199"
}

.fa-wordpress:before {
    content: "\f19a"
}

.fa-openid:before {
    content: "\f19b"
}

.fa-institution:before,.fa-bank:before,.fa-university:before {
    content: "\f19c"
}

.fa-mortar-board:before,.fa-graduation-cap:before {
    content: "\f19d"
}

.fa-yahoo:before {
    content: "\f19e"
}

.fa-google:before {
    content: "\f1a0"
}

.fa-reddit:before {
    content: "\f1a1"
}

.fa-reddit-square:before {
    content: "\f1a2"
}

.fa-stumbleupon-circle:before {
    content: "\f1a3"
}

.fa-stumbleupon:before {
    content: "\f1a4"
}

.fa-delicious:before {
    content: "\f1a5"
}

.fa-digg:before {
    content: "\f1a6"
}

.fa-pied-piper-pp:before {
    content: "\f1a7"
}

.fa-pied-piper-alt:before {
    content: "\f1a8"
}

.fa-drupal:before {
    content: "\f1a9"
}

.fa-joomla:before {
    content: "\f1aa"
}

.fa-language:before {
    content: "\f1ab"
}

.fa-fax:before {
    content: "\f1ac"
}

.fa-building:before {
    content: "\f1ad"
}

.fa-child:before {
    content: "\f1ae"
}

.fa-paw:before {
    content: "\f1b0"
}

.fa-spoon:before {
    content: "\f1b1"
}

.fa-cube:before {
    content: "\f1b2"
}

.fa-cubes:before {
    content: "\f1b3"
}

.fa-behance:before {
    content: "\f1b4"
}

.fa-behance-square:before {
    content: "\f1b5"
}

.fa-steam:before {
    content: "\f1b6"
}

.fa-steam-square:before {
    content: "\f1b7"
}

.fa-recycle:before {
    content: "\f1b8"
}

.fa-automobile:before,.fa-car:before {
    content: "\f1b9"
}

.fa-cab:before,.fa-taxi:before {
    content: "\f1ba"
}

.fa-tree:before {
    content: "\f1bb"
}

.fa-spotify:before {
    content: "\f1bc"
}

.fa-deviantart:before {
    content: "\f1bd"
}

.fa-soundcloud:before {
    content: "\f1be"
}

.fa-database:before {
    content: "\f1c0"
}

.fa-file-pdf-o:before {
    content: "\f1c1"
}

.fa-file-word-o:before {
    content: "\f1c2"
}

.fa-file-excel-o:before {
    content: "\f1c3"
}

.fa-file-powerpoint-o:before {
    content: "\f1c4"
}

.fa-file-photo-o:before,.fa-file-picture-o:before,.fa-file-image-o:before {
    content: "\f1c5"
}

.fa-file-zip-o:before,.fa-file-archive-o:before {
    content: "\f1c6"
}

.fa-file-sound-o:before,.fa-file-audio-o:before {
    content: "\f1c7"
}

.fa-file-movie-o:before,.fa-file-video-o:before {
    content: "\f1c8"
}

.fa-file-code-o:before {
    content: "\f1c9"
}

.fa-vine:before {
    content: "\f1ca"
}

.fa-codepen:before {
    content: "\f1cb"
}

.fa-jsfiddle:before {
    content: "\f1cc"
}

.fa-life-bouy:before,.fa-life-buoy:before,.fa-life-saver:before,.fa-support:before,.fa-life-ring:before {
    content: "\f1cd"
}

.fa-circle-o-notch:before {
    content: "\f1ce"
}

.fa-ra:before,.fa-resistance:before,.fa-rebel:before {
    content: "\f1d0"
}

.fa-ge:before,.fa-empire:before {
    content: "\f1d1"
}

.fa-git-square:before {
    content: "\f1d2"
}

.fa-git:before {
    content: "\f1d3"
}

.fa-y-combinator-square:before,.fa-yc-square:before,.fa-hacker-news:before {
    content: "\f1d4"
}

.fa-tencent-weibo:before {
    content: "\f1d5"
}

.fa-qq:before {
    content: "\f1d6"
}

.fa-wechat:before,.fa-weixin:before {
    content: "\f1d7"
}

.fa-send:before,.fa-paper-plane:before {
    content: "\f1d8"
}

.fa-send-o:before,.fa-paper-plane-o:before {
    content: "\f1d9"
}

.fa-history:before {
    content: "\f1da"
}

.fa-circle-thin:before {
    content: "\f1db"
}

.fa-header:before {
    content: "\f1dc"
}

.fa-paragraph:before {
    content: "\f1dd"
}

.fa-sliders:before {
    content: "\f1de"
}

.fa-share-alt:before {
    content: "\f1e0"
}

.fa-share-alt-square:before {
    content: "\f1e1"
}

.fa-bomb:before {
    content: "\f1e2"
}

.fa-soccer-ball-o:before,.fa-futbol-o:before {
    content: "\f1e3"
}

.fa-tty:before {
    content: "\f1e4"
}

.fa-binoculars:before {
    content: "\f1e5"
}

.fa-plug:before {
    content: "\f1e6"
}

.fa-slideshare:before {
    content: "\f1e7"
}

.fa-twitch:before {
    content: "\f1e8"
}

.fa-yelp:before {
    content: "\f1e9"
}

.fa-newspaper-o:before {
    content: "\f1ea"
}

.fa-wifi:before {
    content: "\f1eb"
}

.fa-calculator:before {
    content: "\f1ec"
}

.fa-paypal:before {
    content: "\f1ed"
}

.fa-google-wallet:before {
    content: "\f1ee"
}

.fa-cc-visa:before {
    content: "\f1f0"
}

.fa-cc-mastercard:before {
    content: "\f1f1"
}

.fa-cc-discover:before {
    content: "\f1f2"
}

.fa-cc-amex:before {
    content: "\f1f3"
}

.fa-cc-paypal:before {
    content: "\f1f4"
}

.fa-cc-stripe:before {
    content: "\f1f5"
}

.fa-bell-slash:before {
    content: "\f1f6"
}

.fa-bell-slash-o:before {
    content: "\f1f7"
}

.fa-trash:before {
    content: "\f1f8"
}

.fa-copyright:before {
    content: "\f1f9"
}

.fa-at:before {
    content: "\f1fa"
}

.fa-eyedropper:before {
    content: "\f1fb"
}

.fa-paint-brush:before {
    content: "\f1fc"
}

.fa-birthday-cake:before {
    content: "\f1fd"
}

.fa-area-chart:before {
    content: "\f1fe"
}

.fa-pie-chart:before {
    content: "\f200"
}

.fa-line-chart:before {
    content: "\f201"
}

.fa-lastfm:before {
    content: "\f202"
}

.fa-lastfm-square:before {
    content: "\f203"
}

.fa-toggle-off:before {
    content: "\f204"
}

.fa-toggle-on:before {
    content: "\f205"
}

.fa-bicycle:before {
    content: "\f206"
}

.fa-bus:before {
    content: "\f207"
}

.fa-ioxhost:before {
    content: "\f208"
}

.fa-angellist:before {
    content: "\f209"
}

.fa-cc:before {
    content: "\f20a"
}

.fa-shekel:before,.fa-sheqel:before,.fa-ils:before {
    content: "\f20b"
}

.fa-meanpath:before {
    content: "\f20c"
}

.fa-buysellads:before {
    content: "\f20d"
}

.fa-connectdevelop:before {
    content: "\f20e"
}

.fa-dashcube:before {
    content: "\f210"
}

.fa-forumbee:before {
    content: "\f211"
}

.fa-leanpub:before {
    content: "\f212"
}

.fa-sellsy:before {
    content: "\f213"
}

.fa-shirtsinbulk:before {
    content: "\f214"
}

.fa-simplybuilt:before {
    content: "\f215"
}

.fa-skyatlas:before {
    content: "\f216"
}

.fa-cart-plus:before {
    content: "\f217"
}

.fa-cart-arrow-down:before {
    content: "\f218"
}

.fa-diamond:before {
    content: "\f219"
}

.fa-ship:before {
    content: "\f21a"
}

.fa-user-secret:before {
    content: "\f21b"
}

.fa-motorcycle:before {
    content: "\f21c"
}

.fa-street-view:before {
    content: "\f21d"
}

.fa-heartbeat:before {
    content: "\f21e"
}

.fa-venus:before {
    content: "\f221"
}

.fa-mars:before {
    content: "\f222"
}

.fa-mercury:before {
    content: "\f223"
}

.fa-intersex:before,.fa-transgender:before {
    content: "\f224"
}

.fa-transgender-alt:before {
    content: "\f225"
}

.fa-venus-double:before {
    content: "\f226"
}

.fa-mars-double:before {
    content: "\f227"
}

.fa-venus-mars:before {
    content: "\f228"
}

.fa-mars-stroke:before {
    content: "\f229"
}

.fa-mars-stroke-v:before {
    content: "\f22a"
}

.fa-mars-stroke-h:before {
    content: "\f22b"
}

.fa-neuter:before {
    content: "\f22c"
}

.fa-genderless:before {
    content: "\f22d"
}

.fa-facebook-official:before {
    content: "\f230"
}

.fa-pinterest-p:before {
    content: "\f231"
}

.fa-whatsapp:before {
    content: "\f232"
}

.fa-server:before {
    content: "\f233"
}

.fa-user-plus:before {
    content: "\f234"
}

.fa-user-times:before {
    content: "\f235"
}

.fa-hotel:before,.fa-bed:before {
    content: "\f236"
}

.fa-viacoin:before {
    content: "\f237"
}

.fa-train:before {
    content: "\f238"
}

.fa-subway:before {
    content: "\f239"
}

.fa-medium:before {
    content: "\f23a"
}

.fa-yc:before,.fa-y-combinator:before {
    content: "\f23b"
}

.fa-optin-monster:before {
    content: "\f23c"
}

.fa-opencart:before {
    content: "\f23d"
}

.fa-expeditedssl:before {
    content: "\f23e"
}

.fa-battery-4:before,.fa-battery:before,.fa-battery-full:before {
    content: "\f240"
}

.fa-battery-3:before,.fa-battery-three-quarters:before {
    content: "\f241"
}

.fa-battery-2:before,.fa-battery-half:before {
    content: "\f242"
}

.fa-battery-1:before,.fa-battery-quarter:before {
    content: "\f243"
}

.fa-battery-0:before,.fa-battery-empty:before {
    content: "\f244"
}

.fa-mouse-pointer:before {
    content: "\f245"
}

.fa-i-cursor:before {
    content: "\f246"
}

.fa-object-group:before {
    content: "\f247"
}

.fa-object-ungroup:before {
    content: "\f248"
}

.fa-sticky-note:before {
    content: "\f249"
}

.fa-sticky-note-o:before {
    content: "\f24a"
}

.fa-cc-jcb:before {
    content: "\f24b"
}

.fa-cc-diners-club:before {
    content: "\f24c"
}

.fa-clone:before {
    content: "\f24d"
}

.fa-balance-scale:before {
    content: "\f24e"
}

.fa-hourglass-o:before {
    content: "\f250"
}

.fa-hourglass-1:before,.fa-hourglass-start:before {
    content: "\f251"
}

.fa-hourglass-2:before,.fa-hourglass-half:before {
    content: "\f252"
}

.fa-hourglass-3:before,.fa-hourglass-end:before {
    content: "\f253"
}

.fa-hourglass:before {
    content: "\f254"
}

.fa-hand-grab-o:before,.fa-hand-rock-o:before {
    content: "\f255"
}

.fa-hand-stop-o:before,.fa-hand-paper-o:before {
    content: "\f256"
}

.fa-hand-scissors-o:before {
    content: "\f257"
}

.fa-hand-lizard-o:before {
    content: "\f258"
}

.fa-hand-spock-o:before {
    content: "\f259"
}

.fa-hand-pointer-o:before {
    content: "\f25a"
}

.fa-hand-peace-o:before {
    content: "\f25b"
}

.fa-trademark:before {
    content: "\f25c"
}

.fa-registered:before {
    content: "\f25d"
}

.fa-creative-commons:before {
    content: "\f25e"
}

.fa-gg:before {
    content: "\f260"
}

.fa-gg-circle:before {
    content: "\f261"
}

.fa-tripadvisor:before {
    content: "\f262"
}

.fa-odnoklassniki:before {
    content: "\f263"
}

.fa-odnoklassniki-square:before {
    content: "\f264"
}

.fa-get-pocket:before {
    content: "\f265"
}

.fa-wikipedia-w:before {
    content: "\f266"
}

.fa-safari:before {
    content: "\f267"
}

.fa-chrome:before {
    content: "\f268"
}

.fa-firefox:before {
    content: "\f269"
}

.fa-opera:before {
    content: "\f26a"
}

.fa-internet-explorer:before {
    content: "\f26b"
}

.fa-tv:before,.fa-television:before {
    content: "\f26c"
}

.fa-contao:before {
    content: "\f26d"
}

.fa-500px:before {
    content: "\f26e"
}

.fa-amazon:before {
    content: "\f270"
}

.fa-calendar-plus-o:before {
    content: "\f271"
}

.fa-calendar-minus-o:before {
    content: "\f272"
}

.fa-calendar-times-o:before {
    content: "\f273"
}

.fa-calendar-check-o:before {
    content: "\f274"
}

.fa-industry:before {
    content: "\f275"
}

.fa-map-pin:before {
    content: "\f276"
}

.fa-map-signs:before {
    content: "\f277"
}

.fa-map-o:before {
    content: "\f278"
}

.fa-map:before {
    content: "\f279"
}

.fa-commenting:before {
    content: "\f27a"
}

.fa-commenting-o:before {
    content: "\f27b"
}

.fa-houzz:before {
    content: "\f27c"
}

.fa-vimeo:before {
    content: "\f27d"
}

.fa-black-tie:before {
    content: "\f27e"
}

.fa-fonticons:before {
    content: "\f280"
}

.fa-reddit-alien:before {
    content: "\f281"
}

.fa-edge:before {
    content: "\f282"
}

.fa-credit-card-alt:before {
    content: "\f283"
}

.fa-codiepie:before {
    content: "\f284"
}

.fa-modx:before {
    content: "\f285"
}

.fa-fort-awesome:before {
    content: "\f286"
}

.fa-usb:before {
    content: "\f287"
}

.fa-product-hunt:before {
    content: "\f288"
}

.fa-mixcloud:before {
    content: "\f289"
}

.fa-scribd:before {
    content: "\f28a"
}

.fa-pause-circle:before {
    content: "\f28b"
}

.fa-pause-circle-o:before {
    content: "\f28c"
}

.fa-stop-circle:before {
    content: "\f28d"
}

.fa-stop-circle-o:before {
    content: "\f28e"
}

.fa-shopping-bag:before {
    content: "\f290"
}

.fa-shopping-basket:before {
    content: "\f291"
}

.fa-hashtag:before {
    content: "\f292"
}

.fa-bluetooth:before {
    content: "\f293"
}

.fa-bluetooth-b:before {
    content: "\f294"
}

.fa-percent:before {
    content: "\f295"
}

.fa-gitlab:before {
    content: "\f296"
}

.fa-wpbeginner:before {
    content: "\f297"
}

.fa-wpforms:before {
    content: "\f298"
}

.fa-envira:before {
    content: "\f299"
}

.fa-universal-access:before {
    content: "\f29a"
}

.fa-wheelchair-alt:before {
    content: "\f29b"
}

.fa-question-circle-o:before {
    content: "\f29c"
}

.fa-blind:before {
    content: "\f29d"
}

.fa-audio-description:before {
    content: "\f29e"
}

.fa-volume-control-phone:before {
    content: "\f2a0"
}

.fa-braille:before {
    content: "\f2a1"
}

.fa-assistive-listening-systems:before {
    content: "\f2a2"
}

.fa-asl-interpreting:before,.fa-american-sign-language-interpreting:before {
    content: "\f2a3"
}

.fa-deafness:before,.fa-hard-of-hearing:before,.fa-deaf:before {
    content: "\f2a4"
}

.fa-glide:before {
    content: "\f2a5"
}

.fa-glide-g:before {
    content: "\f2a6"
}

.fa-signing:before,.fa-sign-language:before {
    content: "\f2a7"
}

.fa-low-vision:before {
    content: "\f2a8"
}

.fa-viadeo:before {
    content: "\f2a9"
}

.fa-viadeo-square:before {
    content: "\f2aa"
}

.fa-snapchat:before {
    content: "\f2ab"
}

.fa-snapchat-ghost:before {
    content: "\f2ac"
}

.fa-snapchat-square:before {
    content: "\f2ad"
}

.fa-pied-piper:before {
    content: "\f2ae"
}

.fa-first-order:before {
    content: "\f2b0"
}

.fa-yoast:before {
    content: "\f2b1"
}

.fa-themeisle:before {
    content: "\f2b2"
}

.fa-google-plus-circle:before,.fa-google-plus-official:before {
    content: "\f2b3"
}

.fa-fa:before,.fa-font-awesome:before {
    content: "\f2b4"
}

.fa-handshake-o:before {
    content: "\f2b5"
}

.fa-envelope-open:before {
    content: "\f2b6"
}

.fa-envelope-open-o:before {
    content: "\f2b7"
}

.fa-linode:before {
    content: "\f2b8"
}

.fa-address-book:before {
    content: "\f2b9"
}

.fa-address-book-o:before {
    content: "\f2ba"
}

.fa-vcard:before,.fa-address-card:before {
    content: "\f2bb"
}

.fa-vcard-o:before,.fa-address-card-o:before {
    content: "\f2bc"
}

.fa-user-circle:before {
    content: "\f2bd"
}

.fa-user-circle-o:before {
    content: "\f2be"
}

.fa-user-o:before {
    content: "\f2c0"
}

.fa-id-badge:before {
    content: "\f2c1"
}

.fa-drivers-license:before,.fa-id-card:before {
    content: "\f2c2"
}

.fa-drivers-license-o:before,.fa-id-card-o:before {
    content: "\f2c3"
}

.fa-quora:before {
    content: "\f2c4"
}

.fa-free-code-camp:before {
    content: "\f2c5"
}

.fa-telegram:before {
    content: "\f2c6"
}

.fa-thermometer-4:before,.fa-thermometer:before,.fa-thermometer-full:before {
    content: "\f2c7"
}

.fa-thermometer-3:before,.fa-thermometer-three-quarters:before {
    content: "\f2c8"
}

.fa-thermometer-2:before,.fa-thermometer-half:before {
    content: "\f2c9"
}

.fa-thermometer-1:before,.fa-thermometer-quarter:before {
    content: "\f2ca"
}

.fa-thermometer-0:before,.fa-thermometer-empty:before {
    content: "\f2cb"
}

.fa-shower:before {
    content: "\f2cc"
}

.fa-bathtub:before,.fa-s15:before,.fa-bath:before {
    content: "\f2cd"
}

.fa-podcast:before {
    content: "\f2ce"
}

.fa-window-maximize:before {
    content: "\f2d0"
}

.fa-window-minimize:before {
    content: "\f2d1"
}

.fa-window-restore:before {
    content: "\f2d2"
}

.fa-times-rectangle:before,.fa-window-close:before {
    content: "\f2d3"
}

.fa-times-rectangle-o:before,.fa-window-close-o:before {
    content: "\f2d4"
}

.fa-bandcamp:before {
    content: "\f2d5"
}

.fa-grav:before {
    content: "\f2d6"
}

.fa-etsy:before {
    content: "\f2d7"
}

.fa-imdb:before {
    content: "\f2d8"
}

.fa-ravelry:before {
    content: "\f2d9"
}

.fa-eercast:before {
    content: "\f2da"
}

.fa-microchip:before {
    content: "\f2db"
}

.fa-snowflake-o:before {
    content: "\f2dc"
}

.fa-superpowers:before {
    content: "\f2dd"
}

.fa-wpexplorer:before {
    content: "\f2de"
}

.fa-meetup:before {
    content: "\f2e0"
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0
}

.sr-only-focusable:active,.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

body {
    margin: 0
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
    display: block
}

audio,canvas,progress,video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],template {
    display: none
}

a {
    background-color: transparent
}

a:active,a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,strong {
    font-weight: 700
}

dfn {
    font-style: italic
}

h1 {
    margin: .67em 0;
    font-size: 2em
}

mark {
    color: #000;
    background: #ff0
}

small {
    font-size: 80%
}

sub,sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 1em 40px
}

hr {
    height: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

pre {
    overflow: auto
}

code,kbd,pre,samp {
    font-family: monospace,monospace;
    font-size: 1em
}

button,input,optgroup,select,textarea {
    margin: 0;
    font: inherit;
    color: inherit
}

button {
    overflow: visible
}

button,select {
    text-transform: none
}

button,html input[type=button],input[type=reset],input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,input::-moz-focus-inner {
    padding: 0;
    border: 0
}

input {
    line-height: normal
}

input[type=checkbox],input[type=radio] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: textfield
}

input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

fieldset {
    padding: .35em .625em .75em;
    margin: 0 2px;
    border: 1px solid silver
}

legend {
    padding: 0;
    border: 0
}

textarea {
    overflow: auto
}

optgroup {
    font-weight: 700
}

table {
    border-spacing: 0;
    border-collapse: collapse
}

td,th {
    padding: 0
}

@media print {
    *,:after,:before {
        color: #000!important;
        text-shadow: none!important;
        background: 0 0!important;
        -webkit-box-shadow: none!important;
        box-shadow: none!important
    }

    a,a:visited {
        text-decoration: underline
    }

    a[href]:after {
        content: " (" attr(href) ")"
    }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    a[href^="javascript:"]:after,a[href^="#"]:after {
        content: ""
    }

    blockquote,pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img,tr {
        page-break-inside: avoid
    }

    img {
        max-width: 100%!important
    }

    h2,h3,p {
        orphans: 3;
        widows: 3
    }

    h2,h3 {
        page-break-after: avoid
    }

    .navbar {
        display: none
    }

    .btn>.caret,.dropup>.btn>.caret {
        border-top-color: #000!important
    }

    .label {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse!important
    }

    .table td,.table th {
        background-color: #fff!important
    }

    .table-bordered td,.table-bordered th {
        border: 1px solid #ddd!important
    }
}

@font-face {
    font-family: 'Glyphicons Halflings';
    /* src: url(../themes/responsiv-clean/assets/fonts/glyphicons-halflings-regular.eot);
    src: url(../themes/responsiv-clean/assets/fonts/glyphicons-halflings-regular.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/glyphicons-halflings-regular.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/glyphicons-halflings-regular.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/glyphicons-halflings-regular.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular) format("svg") */
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.glyphicon-asterisk:before {
    content: "\002a"
}

.glyphicon-plus:before {
    content: "\002b"
}

.glyphicon-eur:before,.glyphicon-euro:before {
    content: "\20ac"
}

.glyphicon-minus:before {
    content: "\2212"
}

.glyphicon-cloud:before {
    content: "\2601"
}

.glyphicon-envelope:before {
    content: "\2709"
}

.glyphicon-pencil:before {
    content: "\270f"
}

.glyphicon-glass:before {
    content: "\e001"
}

.glyphicon-music:before {
    content: "\e002"
}

.glyphicon-search:before {
    content: "\e003"
}

.glyphicon-heart:before {
    content: "\e005"
}

.glyphicon-star:before {
    content: "\e006"
}

.glyphicon-star-empty:before {
    content: "\e007"
}

.glyphicon-user:before {
    content: "\e008"
}

.glyphicon-film:before {
    content: "\e009"
}

.glyphicon-th-large:before {
    content: "\e010"
}

.glyphicon-th:before {
    content: "\e011"
}

.glyphicon-th-list:before {
    content: "\e012"
}

.glyphicon-ok:before {
    content: "\e013"
}

.glyphicon-remove:before {
    content: "\e014"
}

.glyphicon-zoom-in:before {
    content: "\e015"
}

.glyphicon-zoom-out:before {
    content: "\e016"
}

.glyphicon-off:before {
    content: "\e017"
}

.glyphicon-signal:before {
    content: "\e018"
}

.glyphicon-cog:before {
    content: "\e019"
}

.glyphicon-trash:before {
    content: "\e020"
}

.glyphicon-home:before {
    content: "\e021"
}

.glyphicon-file:before {
    content: "\e022"
}

.glyphicon-time:before {
    content: "\e023"
}

.glyphicon-road:before {
    content: "\e024"
}

.glyphicon-download-alt:before {
    content: "\e025"
}

.glyphicon-download:before {
    content: "\e026"
}

.glyphicon-upload:before {
    content: "\e027"
}

.glyphicon-inbox:before {
    content: "\e028"
}

.glyphicon-play-circle:before {
    content: "\e029"
}

.glyphicon-repeat:before {
    content: "\e030"
}

.glyphicon-refresh:before {
    content: "\e031"
}

.glyphicon-list-alt:before {
    content: "\e032"
}

.glyphicon-lock:before {
    content: "\e033"
}

.glyphicon-flag:before {
    content: "\e034"
}

.glyphicon-headphones:before {
    content: "\e035"
}

.glyphicon-volume-off:before {
    content: "\e036"
}

.glyphicon-volume-down:before {
    content: "\e037"
}

.glyphicon-volume-up:before {
    content: "\e038"
}

.glyphicon-qrcode:before {
    content: "\e039"
}

.glyphicon-barcode:before {
    content: "\e040"
}

.glyphicon-tag:before {
    content: "\e041"
}

.glyphicon-tags:before {
    content: "\e042"
}

.glyphicon-book:before {
    content: "\e043"
}

.glyphicon-bookmark:before {
    content: "\e044"
}

.glyphicon-print:before {
    content: "\e045"
}

.glyphicon-camera:before {
    content: "\e046"
}

.glyphicon-font:before {
    content: "\e047"
}

.glyphicon-bold:before {
    content: "\e048"
}

.glyphicon-italic:before {
    content: "\e049"
}

.glyphicon-text-height:before {
    content: "\e050"
}

.glyphicon-text-width:before {
    content: "\e051"
}

.glyphicon-align-left:before {
    content: "\e052"
}

.glyphicon-align-center:before {
    content: "\e053"
}

.glyphicon-align-right:before {
    content: "\e054"
}

.glyphicon-align-justify:before {
    content: "\e055"
}

.glyphicon-list:before {
    content: "\e056"
}

.glyphicon-indent-left:before {
    content: "\e057"
}

.glyphicon-indent-right:before {
    content: "\e058"
}

.glyphicon-facetime-video:before {
    content: "\e059"
}

.glyphicon-picture:before {
    content: "\e060"
}

.glyphicon-map-marker:before {
    content: "\e062"
}

.glyphicon-adjust:before {
    content: "\e063"
}

.glyphicon-tint:before {
    content: "\e064"
}

.glyphicon-edit:before {
    content: "\e065"
}

.glyphicon-share:before {
    content: "\e066"
}

.glyphicon-check:before {
    content: "\e067"
}

.glyphicon-move:before {
    content: "\e068"
}

.glyphicon-step-backward:before {
    content: "\e069"
}

.glyphicon-fast-backward:before {
    content: "\e070"
}

.glyphicon-backward:before {
    content: "\e071"
}

.glyphicon-play:before {
    content: "\e072"
}

.glyphicon-pause:before {
    content: "\e073"
}

.glyphicon-stop:before {
    content: "\e074"
}

.glyphicon-forward:before {
    content: "\e075"
}

.glyphicon-fast-forward:before {
    content: "\e076"
}

.glyphicon-step-forward:before {
    content: "\e077"
}

.glyphicon-eject:before {
    content: "\e078"
}

.glyphicon-chevron-left:before {
    content: "\e079"
}

.glyphicon-chevron-right:before {
    content: "\e080"
}

.glyphicon-plus-sign:before {
    content: "\e081"
}

.glyphicon-minus-sign:before {
    content: "\e082"
}

.glyphicon-remove-sign:before {
    content: "\e083"
}

.glyphicon-ok-sign:before {
    content: "\e084"
}

.glyphicon-question-sign:before {
    content: "\e085"
}

.glyphicon-info-sign:before {
    content: "\e086"
}

.glyphicon-screenshot:before {
    content: "\e087"
}

.glyphicon-remove-circle:before {
    content: "\e088"
}

.glyphicon-ok-circle:before {
    content: "\e089"
}

.glyphicon-ban-circle:before {
    content: "\e090"
}

.glyphicon-arrow-left:before {
    content: "\e091"
}

.glyphicon-arrow-right:before {
    content: "\e092"
}

.glyphicon-arrow-up:before {
    content: "\e093"
}

.glyphicon-arrow-down:before {
    content: "\e094"
}

.glyphicon-share-alt:before {
    content: "\e095"
}

.glyphicon-resize-full:before {
    content: "\e096"
}

.glyphicon-resize-small:before {
    content: "\e097"
}

.glyphicon-exclamation-sign:before {
    content: "\e101"
}

.glyphicon-gift:before {
    content: "\e102"
}

.glyphicon-leaf:before {
    content: "\e103"
}

.glyphicon-fire:before {
    content: "\e104"
}

.glyphicon-eye-open:before {
    content: "\e105"
}

.glyphicon-eye-close:before {
    content: "\e106"
}

.glyphicon-warning-sign:before {
    content: "\e107"
}

.glyphicon-plane:before {
    content: "\e108"
}

.glyphicon-calendar:before {
    content: "\e109"
}

.glyphicon-random:before {
    content: "\e110"
}

.glyphicon-comment:before {
    content: "\e111"
}

.glyphicon-magnet:before {
    content: "\e112"
}

.glyphicon-chevron-up:before {
    content: "\e113"
}

.glyphicon-chevron-down:before {
    content: "\e114"
}

.glyphicon-retweet:before {
    content: "\e115"
}

.glyphicon-shopping-cart:before {
    content: "\e116"
}

.glyphicon-folder-close:before {
    content: "\e117"
}

.glyphicon-folder-open:before {
    content: "\e118"
}

.glyphicon-resize-vertical:before {
    content: "\e119"
}

.glyphicon-resize-horizontal:before {
    content: "\e120"
}

.glyphicon-hdd:before {
    content: "\e121"
}

.glyphicon-bullhorn:before {
    content: "\e122"
}

.glyphicon-bell:before {
    content: "\e123"
}

.glyphicon-certificate:before {
    content: "\e124"
}

.glyphicon-thumbs-up:before {
    content: "\e125"
}

.glyphicon-thumbs-down:before {
    content: "\e126"
}

.glyphicon-hand-right:before {
    content: "\e127"
}

.glyphicon-hand-left:before {
    content: "\e128"
}

.glyphicon-hand-up:before {
    content: "\e129"
}

.glyphicon-hand-down:before {
    content: "\e130"
}

.glyphicon-circle-arrow-right:before {
    content: "\e131"
}

.glyphicon-circle-arrow-left:before {
    content: "\e132"
}

.glyphicon-circle-arrow-up:before {
    content: "\e133"
}

.glyphicon-circle-arrow-down:before {
    content: "\e134"
}

.glyphicon-globe:before {
    content: "\e135"
}

.glyphicon-wrench:before {
    content: "\e136"
}

.glyphicon-tasks:before {
    content: "\e137"
}

.glyphicon-filter:before {
    content: "\e138"
}

.glyphicon-briefcase:before {
    content: "\e139"
}

.glyphicon-fullscreen:before {
    content: "\e140"
}

.glyphicon-dashboard:before {
    content: "\e141"
}

.glyphicon-paperclip:before {
    content: "\e142"
}

.glyphicon-heart-empty:before {
    content: "\e143"
}

.glyphicon-link:before {
    content: "\e144"
}

.glyphicon-phone:before {
    content: "\e145"
}

.glyphicon-pushpin:before {
    content: "\e146"
}

.glyphicon-usd:before {
    content: "\e148"
}

.glyphicon-gbp:before {
    content: "\e149"
}

.glyphicon-sort:before {
    content: "\e150"
}

.glyphicon-sort-by-alphabet:before {
    content: "\e151"
}

.glyphicon-sort-by-alphabet-alt:before {
    content: "\e152"
}

.glyphicon-sort-by-order:before {
    content: "\e153"
}

.glyphicon-sort-by-order-alt:before {
    content: "\e154"
}

.glyphicon-sort-by-attributes:before {
    content: "\e155"
}

.glyphicon-sort-by-attributes-alt:before {
    content: "\e156"
}

.glyphicon-unchecked:before {
    content: "\e157"
}

.glyphicon-expand:before {
    content: "\e158"
}

.glyphicon-collapse-down:before {
    content: "\e159"
}

.glyphicon-collapse-up:before {
    content: "\e160"
}

.glyphicon-log-in:before {
    content: "\e161"
}

.glyphicon-flash:before {
    content: "\e162"
}

.glyphicon-log-out:before {
    content: "\e163"
}

.glyphicon-new-window:before {
    content: "\e164"
}

.glyphicon-record:before {
    content: "\e165"
}

.glyphicon-save:before {
    content: "\e166"
}

.glyphicon-open:before {
    content: "\e167"
}

.glyphicon-saved:before {
    content: "\e168"
}

.glyphicon-import:before {
    content: "\e169"
}

.glyphicon-export:before {
    content: "\e170"
}

.glyphicon-send:before {
    content: "\e171"
}

.glyphicon-floppy-disk:before {
    content: "\e172"
}

.glyphicon-floppy-saved:before {
    content: "\e173"
}

.glyphicon-floppy-remove:before {
    content: "\e174"
}

.glyphicon-floppy-save:before {
    content: "\e175"
}

.glyphicon-floppy-open:before {
    content: "\e176"
}

.glyphicon-credit-card:before {
    content: "\e177"
}

.glyphicon-transfer:before {
    content: "\e178"
}

.glyphicon-cutlery:before {
    content: "\e179"
}

.glyphicon-header:before {
    content: "\e180"
}

.glyphicon-compressed:before {
    content: "\e181"
}

.glyphicon-earphone:before {
    content: "\e182"
}

.glyphicon-phone-alt:before {
    content: "\e183"
}

.glyphicon-tower:before {
    content: "\e184"
}

.glyphicon-stats:before {
    content: "\e185"
}

.glyphicon-sd-video:before {
    content: "\e186"
}

.glyphicon-hd-video:before {
    content: "\e187"
}

.glyphicon-subtitles:before {
    content: "\e188"
}

.glyphicon-sound-stereo:before {
    content: "\e189"
}

.glyphicon-sound-dolby:before {
    content: "\e190"
}

.glyphicon-sound-5-1:before {
    content: "\e191"
}

.glyphicon-sound-6-1:before {
    content: "\e192"
}

.glyphicon-sound-7-1:before {
    content: "\e193"
}

.glyphicon-copyright-mark:before {
    content: "\e194"
}

.glyphicon-registration-mark:before {
    content: "\e195"
}

.glyphicon-cloud-download:before {
    content: "\e197"
}

.glyphicon-cloud-upload:before {
    content: "\e198"
}

.glyphicon-tree-conifer:before {
    content: "\e199"
}

.glyphicon-tree-deciduous:before {
    content: "\e200"
}

.glyphicon-cd:before {
    content: "\e201"
}

.glyphicon-save-file:before {
    content: "\e202"
}

.glyphicon-open-file:before {
    content: "\e203"
}

.glyphicon-level-up:before {
    content: "\e204"
}

.glyphicon-copy:before {
    content: "\e205"
}

.glyphicon-paste:before {
    content: "\e206"
}

.glyphicon-alert:before {
    content: "\e209"
}

.glyphicon-equalizer:before {
    content: "\e210"
}

.glyphicon-king:before {
    content: "\e211"
}

.glyphicon-queen:before {
    content: "\e212"
}

.glyphicon-pawn:before {
    content: "\e213"
}

.glyphicon-bishop:before {
    content: "\e214"
}

.glyphicon-knight:before {
    content: "\e215"
}

.glyphicon-baby-formula:before {
    content: "\e216"
}

.glyphicon-tent:before {
    content: "\26fa"
}

.glyphicon-blackboard:before {
    content: "\e218"
}

.glyphicon-bed:before {
    content: "\e219"
}

.glyphicon-apple:before {
    content: "\f8ff"
}

.glyphicon-erase:before {
    content: "\e221"
}

.glyphicon-hourglass:before {
    content: "\231b"
}

.glyphicon-lamp:before {
    content: "\e223"
}

.glyphicon-duplicate:before {
    content: "\e224"
}

.glyphicon-piggy-bank:before {
    content: "\e225"
}

.glyphicon-scissors:before {
    content: "\e226"
}

.glyphicon-bitcoin:before {
    content: "\e227"
}

.glyphicon-btc:before {
    content: "\e227"
}

.glyphicon-xbt:before {
    content: "\e227"
}

.glyphicon-yen:before {
    content: "\00a5"
}

.glyphicon-jpy:before {
    content: "\00a5"
}

.glyphicon-ruble:before {
    content: "\20bd"
}

.glyphicon-rub:before {
    content: "\20bd"
}

.glyphicon-scale:before {
    content: "\e230"
}

.glyphicon-ice-lolly:before {
    content: "\e231"
}

.glyphicon-ice-lolly-tasted:before {
    content: "\e232"
}

.glyphicon-education:before {
    content: "\e233"
}

.glyphicon-option-horizontal:before {
    content: "\e234"
}

.glyphicon-option-vertical:before {
    content: "\e235"
}

.glyphicon-menu-hamburger:before {
    content: "\e236"
}

.glyphicon-modal-window:before {
    content: "\e237"
}

.glyphicon-oil:before {
    content: "\e238"
}

.glyphicon-grain:before {
    content: "\e239"
}

.glyphicon-sunglasses:before {
    content: "\e240"
}

.glyphicon-text-size:before {
    content: "\e241"
}

.glyphicon-text-color:before {
    content: "\e242"
}

.glyphicon-text-background:before {
    content: "\e243"
}

.glyphicon-object-align-top:before {
    content: "\e244"
}

.glyphicon-object-align-bottom:before {
    content: "\e245"
}

.glyphicon-object-align-horizontal:before {
    content: "\e246"
}

.glyphicon-object-align-left:before {
    content: "\e247"
}

.glyphicon-object-align-vertical:before {
    content: "\e248"
}

.glyphicon-object-align-right:before {
    content: "\e249"
}

.glyphicon-triangle-right:before {
    content: "\e250"
}

.glyphicon-triangle-left:before {
    content: "\e251"
}

.glyphicon-triangle-bottom:before {
    content: "\e252"
}

.glyphicon-triangle-top:before {
    content: "\e253"
}

.glyphicon-console:before {
    content: "\e254"
}

.glyphicon-superscript:before {
    content: "\e255"
}

.glyphicon-subscript:before {
    content: "\e256"
}

.glyphicon-menu-left:before {
    content: "\e257"
}

.glyphicon-menu-right:before {
    content: "\e258"
}

.glyphicon-menu-down:before {
    content: "\e259"
}

.glyphicon-menu-up:before {
    content: "\e260"
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

:after,:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff
}

button,input,select,textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

a {
    color: #337ab7;
    text-decoration: none
}

a:focus,a:hover {
    color: #23527c;
    text-decoration: underline
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

figure {
    margin: 0
}

img {
    vertical-align: middle
}

.carousel-inner>.item>a>img,.carousel-inner>.item>img,.img-responsive,.thumbnail a>img,.thumbnail>img {
    display: block;
    max-width: 100%;
    height: auto
}

.img-rounded {
    border-radius: 6px
}

.img-thumbnail {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.img-circle {
    border-radius: 50%
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0
}

.sr-only-focusable:active,.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

[role=button] {
    cursor: pointer
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

.h1 .small,.h1 small,.h2 .small,.h2 small,.h3 .small,.h3 small,.h4 .small,.h4 small,.h5 .small,.h5 small,.h6 .small,.h6 small,h1 .small,h1 small,h2 .small,h2 small,h3 .small,h3 small,h4 .small,h4 small,h5 .small,h5 small,h6 .small,h6 small {
    font-weight: 400;
    line-height: 1;
    color: #777
}

.h1,.h2,.h3,h1,h2,h3 {
    margin-top: 20px;
    margin-bottom: 10px
}

.h1 .small,.h1 small,.h2 .small,.h2 small,.h3 .small,.h3 small,h1 .small,h1 small,h2 .small,h2 small,h3 .small,h3 small {
    font-size: 65%
}

.h4,.h5,.h6,h4,h5,h6 {
    margin-top: 10px;
    margin-bottom: 10px
}

.h4 .small,.h4 small,.h5 .small,.h5 small,.h6 .small,.h6 small,h4 .small,h4 small,h5 .small,h5 small,h6 .small,h6 small {
    font-size: 75%
}

.h1,h1 {
    font-size: 36px
}

.h2,h2 {
    font-size: 30px
}

.h3,h3 {
    font-size: 24px
}

.h4,h4 {
    font-size: 18px
}

.h5,h5 {
    font-size: 14px
}

.h6,h6 {
    font-size: 12px
}

p {
    margin: 0 0 10px
}

.lead {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4
}

@media (min-width: 768px) {
    .lead {
        font-size:21px
    }
}

.small,small {
    font-size: 85%
}

.mark,mark {
    padding: .2em;
    background-color: #fcf8e3
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

.text-nowrap {
    white-space: nowrap
}

.text-lowercase {
    text-transform: lowercase
}

.text-uppercase {
    text-transform: uppercase
}

.text-capitalize {
    text-transform: capitalize
}

.text-muted {
    color: #777
}

.text-primary {
    color: #337ab7
}

a.text-primary:focus,a.text-primary:hover {
    color: #286090
}

.text-success {
    color: #3c763d
}

a.text-success:focus,a.text-success:hover {
    color: #2b542c
}

.text-info {
    color: #31708f
}

a.text-info:focus,a.text-info:hover {
    color: #245269
}

.text-warning {
    color: #8a6d3b
}

a.text-warning:focus,a.text-warning:hover {
    color: #66512c
}

.text-danger {
    color: #a94442
}

a.text-danger:focus,a.text-danger:hover {
    color: #843534
}

.bg-primary {
    color: #fff;
    background-color: #337ab7
}

a.bg-primary:focus,a.bg-primary:hover {
    background-color: #286090
}

.bg-success {
    background-color: #dff0d8
}

a.bg-success:focus,a.bg-success:hover {
    background-color: #c1e2b3
}

.bg-info {
    background-color: #d9edf7
}

a.bg-info:focus,a.bg-info:hover {
    background-color: #afd9ee
}

.bg-warning {
    background-color: #fcf8e3
}

a.bg-warning:focus,a.bg-warning:hover {
    background-color: #f7ecb5
}

.bg-danger {
    background-color: #f2dede
}

a.bg-danger:focus,a.bg-danger:hover {
    background-color: #e4b9b9
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee
}

ol,ul {
    margin-top: 0;
    margin-bottom: 10px
}

ol ol,ol ul,ul ol,ul ul {
    margin-bottom: 0
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none
}

.list-inline>li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px
}

dl {
    margin-top: 0;
    margin-bottom: 20px
}

dd,dt {
    line-height: 1.42857143
}

dt {
    font-weight: 700
}

dd {
    margin-left: 0
}

@media (min-width: 768px) {
    .dl-horizontal dt {
        float:left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .dl-horizontal dd {
        margin-left: 180px
    }
}

abbr[data-original-title],abbr[title] {
    cursor: help;
    border-bottom: 1px dotted #777
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee
}

blockquote ol:last-child,blockquote p:last-child,blockquote ul:last-child {
    margin-bottom: 0
}

blockquote .small,blockquote footer,blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777
}

blockquote .small:before,blockquote footer:before,blockquote small:before {
    content: '\2014 \00A0'
}

.blockquote-reverse,blockquote.pull-right {
    padding-right: 15px;
    padding-left: 0;
    text-align: right;
    border-right: 5px solid #eee;
    border-left: 0
}

.blockquote-reverse .small:before,.blockquote-reverse footer:before,.blockquote-reverse small:before,blockquote.pull-right .small:before,blockquote.pull-right footer:before,blockquote.pull-right small:before {
    content: ''
}

.blockquote-reverse .small:after,.blockquote-reverse footer:after,.blockquote-reverse small:after,blockquote.pull-right .small:after,blockquote.pull-right footer:after,blockquote.pull-right small:after {
    content: '\00A0 \2014'
}

address {
    margin-bottom: 20px;
    font-style: normal;
    line-height: 1.42857143
}

code,kbd,pre,samp {
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace
}

code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px
}

kbd {
    padding: 2px 4px;
    font-size: 90%;
    color: #fff;
    background-color: #333;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.25);
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.25)
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    -webkit-box-shadow: none;
    box-shadow: none
}

pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px
}

pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 768px) {
    .container {
        width:90%
    }
}

@media (min-width: 992px) {
    .container {
        width:970px
    }
}

@media (min-width: 1200px) {
    .container {
        width:1170px
    }
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.row {
    margin-right: -15px;
    margin-left: -15px
}

.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9 {
    float: left
}

.col-xs-12 {
    width: 100%
}

.col-xs-11 {
    width: 91.66666667%
}

.col-xs-10 {
    width: 83.33333333%
}

.col-xs-9 {
    width: 75%
}

.col-xs-8 {
    width: 66.66666667%
}

.col-xs-7 {
    width: 58.33333333%
}

.col-xs-6 {
    width: 50%
}

.col-xs-5 {
    width: 41.66666667%
}

.col-xs-4 {
    width: 33.33333333%
}

.col-xs-3 {
    width: 25%
}

.col-xs-2 {
    width: 16.66666667%
}

.col-xs-1 {
    width: 8.33333333%
}

.col-xs-pull-12 {
    right: 100%
}

.col-xs-pull-11 {
    right: 91.66666667%
}

.col-xs-pull-10 {
    right: 83.33333333%
}

.col-xs-pull-9 {
    right: 75%
}

.col-xs-pull-8 {
    right: 66.66666667%
}

.col-xs-pull-7 {
    right: 58.33333333%
}

.col-xs-pull-6 {
    right: 50%
}

.col-xs-pull-5 {
    right: 41.66666667%
}

.col-xs-pull-4 {
    right: 33.33333333%
}

.col-xs-pull-3 {
    right: 25%
}

.col-xs-pull-2 {
    right: 16.66666667%
}

.col-xs-pull-1 {
    right: 8.33333333%
}

.col-xs-pull-0 {
    right: auto
}

.col-xs-push-12 {
    left: 100%
}

.col-xs-push-11 {
    left: 91.66666667%
}

.col-xs-push-10 {
    left: 83.33333333%
}

.col-xs-push-9 {
    left: 75%
}

.col-xs-push-8 {
    left: 66.66666667%
}

.col-xs-push-7 {
    left: 58.33333333%
}

.col-xs-push-6 {
    left: 50%
}

.col-xs-push-5 {
    left: 41.66666667%
}

.col-xs-push-4 {
    left: 33.33333333%
}

.col-xs-push-3 {
    left: 25%
}

.col-xs-push-2 {
    left: 16.66666667%
}

.col-xs-push-1 {
    left: 8.33333333%
}

.col-xs-push-0 {
    left: auto
}

.col-xs-offset-12 {
    margin-left: 100%
}

.col-xs-offset-11 {
    margin-left: 91.66666667%
}

.col-xs-offset-10 {
    margin-left: 83.33333333%
}

.col-xs-offset-9 {
    margin-left: 75%
}

.col-xs-offset-8 {
    margin-left: 66.66666667%
}

.col-xs-offset-7 {
    margin-left: 58.33333333%
}

.col-xs-offset-6 {
    margin-left: 50%
}

.col-xs-offset-5 {
    margin-left: 41.66666667%
}

.col-xs-offset-4 {
    margin-left: 33.33333333%
}

.col-xs-offset-3 {
    margin-left: 25%
}

.col-xs-offset-2 {
    margin-left: 16.66666667%
}

.col-xs-offset-1 {
    margin-left: 8.33333333%
}

.col-xs-offset-0 {
    margin-left: 0
}

@media (min-width: 768px) {
    .col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9 {
        float:left
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-11 {
        width: 91.66666667%
    }

    .col-sm-10 {
        width: 83.33333333%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-8 {
        width: 66.66666667%
    }

    .col-sm-7 {
        width: 58.33333333%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-5 {
        width: 41.66666667%
    }

    .col-sm-4 {
        width: 33.33333333%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-2 {
        width: 16.66666667%
    }

    .col-sm-1 {
        width: 8.33333333%
    }

    .col-sm-pull-12 {
        right: 100%
    }

    .col-sm-pull-11 {
        right: 91.66666667%
    }

    .col-sm-pull-10 {
        right: 83.33333333%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-8 {
        right: 66.66666667%
    }

    .col-sm-pull-7 {
        right: 58.33333333%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-5 {
        right: 41.66666667%
    }

    .col-sm-pull-4 {
        right: 33.33333333%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-2 {
        right: 16.66666667%
    }

    .col-sm-pull-1 {
        right: 8.33333333%
    }

    .col-sm-pull-0 {
        right: auto
    }

    .col-sm-push-12 {
        left: 100%
    }

    .col-sm-push-11 {
        left: 91.66666667%
    }

    .col-sm-push-10 {
        left: 83.33333333%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-8 {
        left: 66.66666667%
    }

    .col-sm-push-7 {
        left: 58.33333333%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-5 {
        left: 41.66666667%
    }

    .col-sm-push-4 {
        left: 33.33333333%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-2 {
        left: 16.66666667%
    }

    .col-sm-push-1 {
        left: 8.33333333%
    }

    .col-sm-push-0 {
        left: auto
    }

    .col-sm-offset-12 {
        margin-left: 100%
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%
    }

    .col-sm-offset-0 {
        margin-left: 0
    }
}

@media (min-width: 992px) {
    .col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9 {
        float:left
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-11 {
        width: 91.66666667%
    }

    .col-md-10 {
        width: 83.33333333%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-8 {
        width: 66.66666667%
    }

    .col-md-7 {
        width: 58.33333333%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-5 {
        width: 41.66666667%
    }

    .col-md-4 {
        width: 33.33333333%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-2 {
        width: 16.66666667%
    }

    .col-md-1 {
        width: 8.33333333%
    }

    .col-md-pull-12 {
        right: 100%
    }

    .col-md-pull-11 {
        right: 91.66666667%
    }

    .col-md-pull-10 {
        right: 83.33333333%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-8 {
        right: 66.66666667%
    }

    .col-md-pull-7 {
        right: 58.33333333%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-5 {
        right: 41.66666667%
    }

    .col-md-pull-4 {
        right: 33.33333333%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-2 {
        right: 16.66666667%
    }

    .col-md-pull-1 {
        right: 8.33333333%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-push-12 {
        left: 100%
    }

    .col-md-push-11 {
        left: 91.66666667%
    }

    .col-md-push-10 {
        left: 83.33333333%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-8 {
        left: 66.66666667%
    }

    .col-md-push-7 {
        left: 58.33333333%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-5 {
        left: 41.66666667%
    }

    .col-md-push-4 {
        left: 33.33333333%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-2 {
        left: 16.66666667%
    }

    .col-md-push-1 {
        left: 8.33333333%
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-offset-12 {
        margin-left: 100%
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%
    }

    .col-md-offset-0 {
        margin-left: 0
    }
}

@media (min-width: 1200px) {
    .col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9 {
        float:left
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-11 {
        width: 91.66666667%
    }

    .col-lg-10 {
        width: 83.33333333%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-8 {
        width: 66.66666667%
    }

    .col-lg-7 {
        width: 58.33333333%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-5 {
        width: 41.66666667%
    }

    .col-lg-4 {
        width: 33.33333333%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-2 {
        width: 16.66666667%
    }

    .col-lg-1 {
        width: 8.33333333%
    }

    .col-lg-pull-12 {
        right: 100%
    }

    .col-lg-pull-11 {
        right: 91.66666667%
    }

    .col-lg-pull-10 {
        right: 83.33333333%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-8 {
        right: 66.66666667%
    }

    .col-lg-pull-7 {
        right: 58.33333333%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-5 {
        right: 41.66666667%
    }

    .col-lg-pull-4 {
        right: 33.33333333%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-2 {
        right: 16.66666667%
    }

    .col-lg-pull-1 {
        right: 8.33333333%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-push-12 {
        left: 100%
    }

    .col-lg-push-11 {
        left: 91.66666667%
    }

    .col-lg-push-10 {
        left: 83.33333333%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-8 {
        left: 66.66666667%
    }

    .col-lg-push-7 {
        left: 58.33333333%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-5 {
        left: 41.66666667%
    }

    .col-lg-push-4 {
        left: 33.33333333%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-2 {
        left: 16.66666667%
    }

    .col-lg-push-1 {
        left: 8.33333333%
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-offset-12 {
        margin-left: 100%
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }
}

table {
    background-color: transparent
}

caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #777;
    text-align: left
}

th {
    text-align: left
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px
}

.table>tbody>tr>td,.table>tbody>tr>th,.table>tfoot>tr>td,.table>tfoot>tr>th,.table>thead>tr>td,.table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd
}

.table>caption+thead>tr:first-child>td,.table>caption+thead>tr:first-child>th,.table>colgroup+thead>tr:first-child>td,.table>colgroup+thead>tr:first-child>th,.table>thead:first-child>tr:first-child>td,.table>thead:first-child>tr:first-child>th {
    border-top: 0
}

.table>tbody+tbody {
    border-top: 2px solid #ddd
}

.table .table {
    background-color: #fff
}

.table-condensed>tbody>tr>td,.table-condensed>tbody>tr>th,.table-condensed>tfoot>tr>td,.table-condensed>tfoot>tr>th,.table-condensed>thead>tr>td,.table-condensed>thead>tr>th {
    padding: 5px
}

.table-bordered {
    border: 1px solid #ddd
}

.table-bordered>tbody>tr>td,.table-bordered>tbody>tr>th,.table-bordered>tfoot>tr>td,.table-bordered>tfoot>tr>th,.table-bordered>thead>tr>td,.table-bordered>thead>tr>th {
    border: 1px solid #ddd
}

.table-bordered>thead>tr>td,.table-bordered>thead>tr>th {
    border-bottom-width: 2px
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9
}

.table-hover>tbody>tr:hover {
    background-color: #f5f5f5
}

table col[class*=col-] {
    position: static;
    display: table-column;
    float: none
}

table td[class*=col-],table th[class*=col-] {
    position: static;
    display: table-cell;
    float: none
}

.table>tbody>tr.active>td,.table>tbody>tr.active>th,.table>tbody>tr>td.active,.table>tbody>tr>th.active,.table>tfoot>tr.active>td,.table>tfoot>tr.active>th,.table>tfoot>tr>td.active,.table>tfoot>tr>th.active,.table>thead>tr.active>td,.table>thead>tr.active>th,.table>thead>tr>td.active,.table>thead>tr>th.active {
    background-color: #f5f5f5
}

.table-hover>tbody>tr.active:hover>td,.table-hover>tbody>tr.active:hover>th,.table-hover>tbody>tr:hover>.active,.table-hover>tbody>tr>td.active:hover,.table-hover>tbody>tr>th.active:hover {
    background-color: #e8e8e8
}

.table>tbody>tr.success>td,.table>tbody>tr.success>th,.table>tbody>tr>td.success,.table>tbody>tr>th.success,.table>tfoot>tr.success>td,.table>tfoot>tr.success>th,.table>tfoot>tr>td.success,.table>tfoot>tr>th.success,.table>thead>tr.success>td,.table>thead>tr.success>th,.table>thead>tr>td.success,.table>thead>tr>th.success {
    background-color: #dff0d8
}

.table-hover>tbody>tr.success:hover>td,.table-hover>tbody>tr.success:hover>th,.table-hover>tbody>tr:hover>.success,.table-hover>tbody>tr>td.success:hover,.table-hover>tbody>tr>th.success:hover {
    background-color: #d0e9c6
}

.table>tbody>tr.info>td,.table>tbody>tr.info>th,.table>tbody>tr>td.info,.table>tbody>tr>th.info,.table>tfoot>tr.info>td,.table>tfoot>tr.info>th,.table>tfoot>tr>td.info,.table>tfoot>tr>th.info,.table>thead>tr.info>td,.table>thead>tr.info>th,.table>thead>tr>td.info,.table>thead>tr>th.info {
    background-color: #d9edf7
}

.table-hover>tbody>tr.info:hover>td,.table-hover>tbody>tr.info:hover>th,.table-hover>tbody>tr:hover>.info,.table-hover>tbody>tr>td.info:hover,.table-hover>tbody>tr>th.info:hover {
    background-color: #c4e3f3
}

.table>tbody>tr.warning>td,.table>tbody>tr.warning>th,.table>tbody>tr>td.warning,.table>tbody>tr>th.warning,.table>tfoot>tr.warning>td,.table>tfoot>tr.warning>th,.table>tfoot>tr>td.warning,.table>tfoot>tr>th.warning,.table>thead>tr.warning>td,.table>thead>tr.warning>th,.table>thead>tr>td.warning,.table>thead>tr>th.warning {
    background-color: #fcf8e3
}

.table-hover>tbody>tr.warning:hover>td,.table-hover>tbody>tr.warning:hover>th,.table-hover>tbody>tr:hover>.warning,.table-hover>tbody>tr>td.warning:hover,.table-hover>tbody>tr>th.warning:hover {
    background-color: #faf2cc
}

.table>tbody>tr.danger>td,.table>tbody>tr.danger>th,.table>tbody>tr>td.danger,.table>tbody>tr>th.danger,.table>tfoot>tr.danger>td,.table>tfoot>tr.danger>th,.table>tfoot>tr>td.danger,.table>tfoot>tr>th.danger,.table>thead>tr.danger>td,.table>thead>tr.danger>th,.table>thead>tr>td.danger,.table>thead>tr>th.danger {
    background-color: #f2dede
}

.table-hover>tbody>tr.danger:hover>td,.table-hover>tbody>tr.danger:hover>th,.table-hover>tbody>tr:hover>.danger,.table-hover>tbody>tr>td.danger:hover,.table-hover>tbody>tr>th.danger:hover {
    background-color: #ebcccc
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto
}

@media screen and (max-width: 767px) {
    .table-responsive {
        width:100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd
    }

    .table-responsive>.table {
        margin-bottom: 0
    }

    .table-responsive>.table>tbody>tr>td,.table-responsive>.table>tbody>tr>th,.table-responsive>.table>tfoot>tr>td,.table-responsive>.table>tfoot>tr>th,.table-responsive>.table>thead>tr>td,.table-responsive>.table>thead>tr>th {
        white-space: nowrap
    }

    .table-responsive>.table-bordered {
        border: 0
    }

    .table-responsive>.table-bordered>tbody>tr>td:first-child,.table-responsive>.table-bordered>tbody>tr>th:first-child,.table-responsive>.table-bordered>tfoot>tr>td:first-child,.table-responsive>.table-bordered>tfoot>tr>th:first-child,.table-responsive>.table-bordered>thead>tr>td:first-child,.table-responsive>.table-bordered>thead>tr>th:first-child {
        border-left: 0
    }

    .table-responsive>.table-bordered>tbody>tr>td:last-child,.table-responsive>.table-bordered>tbody>tr>th:last-child,.table-responsive>.table-bordered>tfoot>tr>td:last-child,.table-responsive>.table-bordered>tfoot>tr>th:last-child,.table-responsive>.table-bordered>thead>tr>td:last-child,.table-responsive>.table-bordered>thead>tr>th:last-child {
        border-right: 0
    }

    .table-responsive>.table-bordered>tbody>tr:last-child>td,.table-responsive>.table-bordered>tbody>tr:last-child>th,.table-responsive>.table-bordered>tfoot>tr:last-child>td,.table-responsive>.table-bordered>tfoot>tr:last-child>th {
        border-bottom: 0
    }
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700
}

input[type=search] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

input[type=checkbox],input[type=radio] {
    margin: 4px 0 0;
    margin-top: 1px\9;
    line-height: normal
}

input[type=file] {
    display: block
}

input[type=range] {
    display: block;
    width: 100%
}

select[multiple],select[size] {
    height: auto
}

input[type=file]:focus,input[type=checkbox]:focus,input[type=radio]:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

output {
    display: block;
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
}

.form-control::-moz-placeholder {
    color: #999;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #999
}

.form-control::-webkit-input-placeholder {
    color: #999
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control[disabled],.form-control[readonly],fieldset[disabled] .form-control {
    background-color: #eee;
    opacity: 1
}

.form-control[disabled],fieldset[disabled] .form-control {
    cursor: not-allowed
}

textarea.form-control {
    height: auto
}

input[type=search] {
    -webkit-appearance: none
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type=date].form-control,input[type=time].form-control,input[type=datetime-local].form-control,input[type=month].form-control {
        line-height:34px
    }

    .input-group-sm input[type=date],.input-group-sm input[type=time],.input-group-sm input[type=datetime-local],.input-group-sm input[type=month],input[type=date].input-sm,input[type=time].input-sm,input[type=datetime-local].input-sm,input[type=month].input-sm {
        line-height: 30px
    }

    .input-group-lg input[type=date],.input-group-lg input[type=time],.input-group-lg input[type=datetime-local],.input-group-lg input[type=month],input[type=date].input-lg,input[type=time].input-lg,input[type=datetime-local].input-lg,input[type=month].input-lg {
        line-height: 46px
    }
}

.form-group {
    margin-bottom: 15px
}

.checkbox,.radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px
}

.checkbox label,.radio label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer
}

.checkbox input[type=checkbox],.checkbox-inline input[type=checkbox],.radio input[type=radio],.radio-inline input[type=radio] {
    position: absolute;
    margin-top: 4px\9;
    margin-left: -20px
}

.checkbox+.checkbox,.radio+.radio {
    margin-top: -5px
}

.checkbox-inline,.radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer
}

.checkbox-inline+.checkbox-inline,.radio-inline+.radio-inline {
    margin-top: 0;
    margin-left: 10px
}

fieldset[disabled] input[type=checkbox],fieldset[disabled] input[type=radio],input[type=checkbox].disabled,input[type=checkbox][disabled],input[type=radio].disabled,input[type=radio][disabled] {
    cursor: not-allowed
}

.checkbox-inline.disabled,.radio-inline.disabled,fieldset[disabled] .checkbox-inline,fieldset[disabled] .radio-inline {
    cursor: not-allowed
}

.checkbox.disabled label,.radio.disabled label,fieldset[disabled] .checkbox label,fieldset[disabled] .radio label {
    cursor: not-allowed
}

.form-control-static {
    min-height: 34px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0
}

.form-control-static.input-lg,.form-control-static.input-sm {
    padding-right: 0;
    padding-left: 0
}

.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

select.input-sm {
    height: 30px;
    line-height: 30px
}

select[multiple].input-sm,textarea.input-sm {
    height: auto
}

.form-group-sm .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.form-group-sm select.form-control {
    height: 30px;
    line-height: 30px
}

.form-group-sm select[multiple].form-control,.form-group-sm textarea.form-control {
    height: auto
}

.form-group-sm .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

select.input-lg {
    height: 46px;
    line-height: 46px
}

select[multiple].input-lg,textarea.input-lg {
    height: auto
}

.form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

.form-group-lg select.form-control {
    height: 46px;
    line-height: 46px
}

.form-group-lg select[multiple].form-control,.form-group-lg textarea.form-control {
    height: auto
}

.form-group-lg .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.3333333
}

.has-feedback {
    position: relative
}

.has-feedback .form-control {
    padding-right: 42.5px
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none
}

.form-group-lg .form-control+.form-control-feedback,.input-group-lg+.form-control-feedback,.input-lg+.form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px
}

.form-group-sm .form-control+.form-control-feedback,.input-group-sm+.form-control-feedback,.input-sm+.form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px
}

.has-success .checkbox,.has-success .checkbox-inline,.has-success .control-label,.has-success .help-block,.has-success .radio,.has-success .radio-inline,.has-success.checkbox label,.has-success.checkbox-inline label,.has-success.radio label,.has-success.radio-inline label {
    color: #3c763d
}

.has-success .form-control {
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075)
}

.has-success .form-control:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #67b168
}

.has-success .input-group-addon {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #3c763d
}

.has-success .form-control-feedback {
    color: #3c763d
}

.has-warning .checkbox,.has-warning .checkbox-inline,.has-warning .control-label,.has-warning .help-block,.has-warning .radio,.has-warning .radio-inline,.has-warning.checkbox label,.has-warning.checkbox-inline label,.has-warning.radio label,.has-warning.radio-inline label {
    color: #8a6d3b
}

.has-warning .form-control {
    border-color: #8a6d3b;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075)
}

.has-warning .form-control:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #c0a16b
}

.has-warning .input-group-addon {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #8a6d3b
}

.has-warning .form-control-feedback {
    color: #8a6d3b
}

.has-error .checkbox,.has-error .checkbox-inline,.has-error .control-label,.has-error .help-block,.has-error .radio,.has-error .radio-inline,.has-error.checkbox label,.has-error.checkbox-inline label,.has-error.radio label,.has-error.radio-inline label {
    color: #a94442
}

.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075)
}

.has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #ce8483
}

.has-error .input-group-addon {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442
}

.has-error .form-control-feedback {
    color: #a94442
}

.has-feedback label~.form-control-feedback {
    top: 25px
}

.has-feedback label.sr-only~.form-control-feedback {
    top: 0
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373
}

@media (min-width: 768px) {
    .form-inline .form-group {
        display:inline-block;
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-static {
        display: inline-block
    }

    .form-inline .input-group {
        display: inline-table;
        vertical-align: middle
    }

    .form-inline .input-group .form-control,.form-inline .input-group .input-group-addon,.form-inline .input-group .input-group-btn {
        width: auto
    }

    .form-inline .input-group>.form-control {
        width: 100%
    }

    .form-inline .control-label {
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .checkbox,.form-inline .radio {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .checkbox label,.form-inline .radio label {
        padding-left: 0
    }

    .form-inline .checkbox input[type=checkbox],.form-inline .radio input[type=radio] {
        position: relative;
        margin-left: 0
    }

    .form-inline .has-feedback .form-control-feedback {
        top: 0
    }
}

.form-horizontal .checkbox,.form-horizontal .checkbox-inline,.form-horizontal .radio,.form-horizontal .radio-inline {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0
}

.form-horizontal .checkbox,.form-horizontal .radio {
    min-height: 27px
}

.form-horizontal .form-group {
    margin-right: -15px;
    margin-left: -15px
}

@media (min-width: 768px) {
    .form-horizontal .control-label {
        padding-top:7px;
        margin-bottom: 0;
        text-align: right
    }
}

.form-horizontal .has-feedback .form-control-feedback {
    right: 15px
}

@media (min-width: 768px) {
    .form-horizontal .form-group-lg .control-label {
        padding-top:11px;
        font-size: 18px
    }
}

@media (min-width: 768px) {
    .form-horizontal .form-group-sm .control-label {
        padding-top:6px;
        font-size: 12px
    }
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px
}

.btn.active.focus,.btn.active:focus,.btn.focus,.btn:active.focus,.btn:active:focus,.btn:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.btn.focus,.btn:focus,.btn:hover {
    color: #333;
    text-decoration: none
}

.btn.active,.btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125)
}

.btn.disabled,.btn[disabled],fieldset[disabled] .btn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65
}

a.btn.disabled,fieldset[disabled] a.btn {
    pointer-events: none
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc
}

.btn-default.focus,.btn-default:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c
}

.btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad
}

.btn-default.active,.btn-default:active,.open>.dropdown-toggle.btn-default {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad
}

.btn-default.active.focus,.btn-default.active:focus,.btn-default.active:hover,.btn-default:active.focus,.btn-default:active:focus,.btn-default:active:hover,.open>.dropdown-toggle.btn-default.focus,.open>.dropdown-toggle.btn-default:focus,.open>.dropdown-toggle.btn-default:hover {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c
}

.btn-default.active,.btn-default:active,.open>.dropdown-toggle.btn-default {
    background-image: none
}

.btn-default.disabled.focus,.btn-default.disabled:focus,.btn-default.disabled:hover,.btn-default[disabled].focus,.btn-default[disabled]:focus,.btn-default[disabled]:hover,fieldset[disabled] .btn-default.focus,fieldset[disabled] .btn-default:focus,fieldset[disabled] .btn-default:hover {
    background-color: #fff;
    border-color: #ccc
}

.btn-default .badge {
    color: #fff;
    background-color: #333
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4
}

.btn-primary.focus,.btn-primary:focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40
}

.btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74
}

.btn-primary.active,.btn-primary:active,.open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #286090;
    border-color: #204d74
}

.btn-primary.active.focus,.btn-primary.active:focus,.btn-primary.active:hover,.btn-primary:active.focus,.btn-primary:active:focus,.btn-primary:active:hover,.open>.dropdown-toggle.btn-primary.focus,.open>.dropdown-toggle.btn-primary:focus,.open>.dropdown-toggle.btn-primary:hover {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40
}

.btn-primary.active,.btn-primary:active,.open>.dropdown-toggle.btn-primary {
    background-image: none
}

.btn-primary.disabled.focus,.btn-primary.disabled:focus,.btn-primary.disabled:hover,.btn-primary[disabled].focus,.btn-primary[disabled]:focus,.btn-primary[disabled]:hover,fieldset[disabled] .btn-primary.focus,fieldset[disabled] .btn-primary:focus,fieldset[disabled] .btn-primary:hover {
    background-color: #337ab7;
    border-color: #2e6da4
}

.btn-primary .badge {
    color: #337ab7;
    background-color: #fff
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c
}

.btn-success.focus,.btn-success:focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625
}

.btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439
}

.btn-success.active,.btn-success:active,.open>.dropdown-toggle.btn-success {
    color: #fff;
    background-color: #449d44;
    border-color: #398439
}

.btn-success.active.focus,.btn-success.active:focus,.btn-success.active:hover,.btn-success:active.focus,.btn-success:active:focus,.btn-success:active:hover,.open>.dropdown-toggle.btn-success.focus,.open>.dropdown-toggle.btn-success:focus,.open>.dropdown-toggle.btn-success:hover {
    color: #fff;
    background-color: #398439;
    border-color: #255625
}

.btn-success.active,.btn-success:active,.open>.dropdown-toggle.btn-success {
    background-image: none
}

.btn-success.disabled.focus,.btn-success.disabled:focus,.btn-success.disabled:hover,.btn-success[disabled].focus,.btn-success[disabled]:focus,.btn-success[disabled]:hover,fieldset[disabled] .btn-success.focus,fieldset[disabled] .btn-success:focus,fieldset[disabled] .btn-success:hover {
    background-color: #5cb85c;
    border-color: #4cae4c
}

.btn-success .badge {
    color: #5cb85c;
    background-color: #fff
}

.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da
}

.btn-info.focus,.btn-info:focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85
}

.btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc
}

.btn-info.active,.btn-info:active,.open>.dropdown-toggle.btn-info {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc
}

.btn-info.active.focus,.btn-info.active:focus,.btn-info.active:hover,.btn-info:active.focus,.btn-info:active:focus,.btn-info:active:hover,.open>.dropdown-toggle.btn-info.focus,.open>.dropdown-toggle.btn-info:focus,.open>.dropdown-toggle.btn-info:hover {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85
}

.btn-info.active,.btn-info:active,.open>.dropdown-toggle.btn-info {
    background-image: none
}

.btn-info.disabled.focus,.btn-info.disabled:focus,.btn-info.disabled:hover,.btn-info[disabled].focus,.btn-info[disabled]:focus,.btn-info[disabled]:hover,fieldset[disabled] .btn-info.focus,fieldset[disabled] .btn-info:focus,fieldset[disabled] .btn-info:hover {
    background-color: #5bc0de;
    border-color: #46b8da
}

.btn-info .badge {
    color: #5bc0de;
    background-color: #fff
}

.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236
}

.btn-warning.focus,.btn-warning:focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d
}

.btn-warning:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512
}

.btn-warning.active,.btn-warning:active,.open>.dropdown-toggle.btn-warning {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512
}

.btn-warning.active.focus,.btn-warning.active:focus,.btn-warning.active:hover,.btn-warning:active.focus,.btn-warning:active:focus,.btn-warning:active:hover,.open>.dropdown-toggle.btn-warning.focus,.open>.dropdown-toggle.btn-warning:focus,.open>.dropdown-toggle.btn-warning:hover {
    color: #fff;
    background-color: #d58512;
    border-color: #985f0d
}

.btn-warning.active,.btn-warning:active,.open>.dropdown-toggle.btn-warning {
    background-image: none
}

.btn-warning.disabled.focus,.btn-warning.disabled:focus,.btn-warning.disabled:hover,.btn-warning[disabled].focus,.btn-warning[disabled]:focus,.btn-warning[disabled]:hover,fieldset[disabled] .btn-warning.focus,fieldset[disabled] .btn-warning:focus,fieldset[disabled] .btn-warning:hover {
    background-color: #f0ad4e;
    border-color: #eea236
}

.btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a
}

.btn-danger.focus,.btn-danger:focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19
}

.btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925
}

.btn-danger.active,.btn-danger:active,.open>.dropdown-toggle.btn-danger {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925
}

.btn-danger.active.focus,.btn-danger.active:focus,.btn-danger.active:hover,.btn-danger:active.focus,.btn-danger:active:focus,.btn-danger:active:hover,.open>.dropdown-toggle.btn-danger.focus,.open>.dropdown-toggle.btn-danger:focus,.open>.dropdown-toggle.btn-danger:hover {
    color: #fff;
    background-color: #ac2925;
    border-color: #761c19
}

.btn-danger.active,.btn-danger:active,.open>.dropdown-toggle.btn-danger {
    background-image: none
}

.btn-danger.disabled.focus,.btn-danger.disabled:focus,.btn-danger.disabled:hover,.btn-danger[disabled].focus,.btn-danger[disabled]:focus,.btn-danger[disabled]:hover,fieldset[disabled] .btn-danger.focus,fieldset[disabled] .btn-danger:focus,fieldset[disabled] .btn-danger:hover {
    background-color: #d9534f;
    border-color: #d43f3a
}

.btn-danger .badge {
    color: #d9534f;
    background-color: #fff
}

.btn-link {
    font-weight: 400;
    color: #337ab7;
    border-radius: 0
}

.btn-link,.btn-link.active,.btn-link:active,.btn-link[disabled],fieldset[disabled] .btn-link {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-link,.btn-link:active,.btn-link:focus,.btn-link:hover {
    border-color: transparent
}

.btn-link:focus,.btn-link:hover {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent
}

.btn-link[disabled]:focus,.btn-link[disabled]:hover,fieldset[disabled] .btn-link:focus,fieldset[disabled] .btn-link:hover {
    color: #777;
    text-decoration: none
}

.btn-group-lg>.btn,.btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

.btn-group-sm>.btn,.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.btn-group-xs>.btn,.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: 5px
}

input[type=button].btn-block,input[type=reset].btn-block,input[type=submit].btn-block {
    width: 100%
}

.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.fade.in {
    opacity: 1
}

.collapse {
    display: none
}

.collapse.in {
    display: block
}

tr.collapse.in {
    display: table-row
}

tbody.collapse.in {
    display: table-row-group
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .35s;
    -o-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-property: height,visibility;
    -o-transition-property: height,visibility;
    transition-property: height,visibility
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent
}

.dropdown,.dropup {
    position: relative
}

.dropdown-toggle:focus {
    outline: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175)
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5
}

.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap
}

.dropdown-menu>li>a:focus,.dropdown-menu>li>a:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5
}

.dropdown-menu>.active>a,.dropdown-menu>.active>a:focus,.dropdown-menu>.active>a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #337ab7;
    outline: 0
}

.dropdown-menu>.disabled>a,.dropdown-menu>.disabled>a:focus,.dropdown-menu>.disabled>a:hover {
    color: #777
}

.dropdown-menu>.disabled>a:focus,.dropdown-menu>.disabled>a:hover {
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.open>.dropdown-menu {
    display: block
}

.open>a {
    outline: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777;
    white-space: nowrap
}

.dropdown-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990
}

.pull-right>.dropdown-menu {
    right: 0;
    left: auto
}

.dropup .caret,.navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9
}

.dropup .dropdown-menu,.navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px
}

@media (min-width: 768px) {
    .navbar-right .dropdown-menu {
        right:0;
        left: auto
    }

    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0
    }
}

.btn-group,.btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle
}

.btn-group-vertical>.btn,.btn-group>.btn {
    position: relative;
    float: left
}

.btn-group-vertical>.btn.active,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:hover,.btn-group>.btn.active,.btn-group>.btn:active,.btn-group>.btn:focus,.btn-group>.btn:hover {
    z-index: 2
}

.btn-group .btn+.btn,.btn-group .btn+.btn-group,.btn-group .btn-group+.btn,.btn-group .btn-group+.btn-group {
    margin-left: -1px
}

.btn-toolbar {
    margin-left: -5px
}

.btn-toolbar .btn,.btn-toolbar .btn-group,.btn-toolbar .input-group {
    float: left
}

.btn-toolbar>.btn,.btn-toolbar>.btn-group,.btn-toolbar>.input-group {
    margin-left: 5px
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0
}

.btn-group>.btn:first-child {
    margin-left: 0
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn:last-child:not(:first-child),.btn-group>.dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group>.btn-group {
    float: left
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-radius: 0
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group .dropdown-toggle:active,.btn-group.open .dropdown-toggle {
    outline: 0
}

.btn-group>.btn+.dropdown-toggle {
    padding-right: 8px;
    padding-left: 8px
}

.btn-group>.btn-lg+.dropdown-toggle {
    padding-right: 12px;
    padding-left: 12px
}

.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125)
}

.btn-group.open .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn .caret {
    margin-left: 0
}

.btn-lg .caret {
    border-width: 5px 5px 0;
    border-bottom-width: 0
}

.dropup .btn-lg .caret {
    border-width: 0 5px 5px
}

.btn-group-vertical>.btn,.btn-group-vertical>.btn-group,.btn-group-vertical>.btn-group>.btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%
}

.btn-group-vertical>.btn-group>.btn {
    float: none
}

.btn-group-vertical>.btn+.btn,.btn-group-vertical>.btn+.btn-group,.btn-group-vertical>.btn-group+.btn,.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0
}

.btn-group-vertical>.btn:not(:first-child):not(:last-child) {
    border-radius: 0
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-radius: 0
}

.btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child,.btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate
}

.btn-group-justified>.btn,.btn-group-justified>.btn-group {
    display: table-cell;
    float: none;
    width: 1%
}

.btn-group-justified>.btn-group .btn {
    width: 100%
}

.btn-group-justified>.btn-group .dropdown-menu {
    left: auto
}

[data-toggle=buttons]>.btn input[type=checkbox],[data-toggle=buttons]>.btn input[type=radio],[data-toggle=buttons]>.btn-group>.btn input[type=checkbox],[data-toggle=buttons]>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate
}

.input-group[class*=col-] {
    float: none;
    padding-right: 0;
    padding-left: 0
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0
}

.input-group .form-control:focus {
    z-index: 3
}

.input-group-lg>.form-control,.input-group-lg>.input-group-addon,.input-group-lg>.input-group-btn>.btn {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

select.input-group-lg>.form-control,select.input-group-lg>.input-group-addon,select.input-group-lg>.input-group-btn>.btn {
    height: 46px;
    line-height: 46px
}

select[multiple].input-group-lg>.form-control,select[multiple].input-group-lg>.input-group-addon,select[multiple].input-group-lg>.input-group-btn>.btn,textarea.input-group-lg>.form-control,textarea.input-group-lg>.input-group-addon,textarea.input-group-lg>.input-group-btn>.btn {
    height: auto
}

.input-group-sm>.form-control,.input-group-sm>.input-group-addon,.input-group-sm>.input-group-btn>.btn {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

select.input-group-sm>.form-control,select.input-group-sm>.input-group-addon,select.input-group-sm>.input-group-btn>.btn {
    height: 30px;
    line-height: 30px
}

select[multiple].input-group-sm>.form-control,select[multiple].input-group-sm>.input-group-addon,select[multiple].input-group-sm>.input-group-btn>.btn,textarea.input-group-sm>.form-control,textarea.input-group-sm>.input-group-addon,textarea.input-group-sm>.input-group-btn>.btn {
    height: auto
}

.input-group .form-control,.input-group-addon,.input-group-btn {
    display: table-cell
}

.input-group .form-control:not(:first-child):not(:last-child),.input-group-addon:not(:first-child):not(:last-child),.input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0
}

.input-group-addon,.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px
}

.input-group-addon.input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px
}

.input-group-addon.input-lg {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px
}

.input-group-addon input[type=checkbox],.input-group-addon input[type=radio] {
    margin-top: 0
}

.input-group .form-control:first-child,.input-group-addon:first-child,.input-group-btn:first-child>.btn,.input-group-btn:first-child>.btn-group>.btn,.input-group-btn:first-child>.dropdown-toggle,.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group-addon:first-child {
    border-right: 0
}

.input-group .form-control:last-child,.input-group-addon:last-child,.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,.input-group-btn:first-child>.btn:not(:first-child),.input-group-btn:last-child>.btn,.input-group-btn:last-child>.btn-group>.btn,.input-group-btn:last-child>.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-addon:last-child {
    border-left: 0
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap
}

.input-group-btn>.btn {
    position: relative
}

.input-group-btn>.btn+.btn {
    margin-left: -1px
}

.input-group-btn>.btn:active,.input-group-btn>.btn:focus,.input-group-btn>.btn:hover {
    z-index: 2
}

.input-group-btn:first-child>.btn,.input-group-btn:first-child>.btn-group {
    margin-right: -1px
}

.input-group-btn:last-child>.btn,.input-group-btn:last-child>.btn-group {
    z-index: 2;
    margin-left: -1px
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav>li {
    position: relative;
    display: block
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px
}

.nav>li>a:focus,.nav>li>a:hover {
    text-decoration: none;
    background-color: #eee
}

.nav>li.disabled>a {
    color: #777
}

.nav>li.disabled>a:focus,.nav>li.disabled>a:hover {
    color: #777;
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent
}

.nav .open>a,.nav .open>a:focus,.nav .open>a:hover {
    background-color: #eee;
    border-color: #337ab7
}

.nav .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5
}

.nav>li>a>img {
    max-width: none
}

.nav-tabs {
    border-bottom: 1px solid #ddd
}

.nav-tabs>li {
    float: left;
    margin-bottom: -1px
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0
}

.nav-tabs>li>a:hover {
    border-color: #eee #eee #ddd
}

.nav-tabs>li.active>a,.nav-tabs>li.active>a:focus,.nav-tabs>li.active>a:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent
}

.nav-tabs.nav-justified {
    width: 100%;
    border-bottom: 0
}

.nav-tabs.nav-justified>li {
    float: none
}

.nav-tabs.nav-justified>li>a {
    margin-bottom: 5px;
    text-align: center
}

.nav-tabs.nav-justified>.dropdown .dropdown-menu {
    top: auto;
    left: auto
}

@media (min-width: 768px) {
    .nav-tabs.nav-justified>li {
        display:table-cell;
        width: 1%
    }

    .nav-tabs.nav-justified>li>a {
        margin-bottom: 0
    }
}

.nav-tabs.nav-justified>li>a {
    margin-right: 0;
    border-radius: 4px
}

.nav-tabs.nav-justified>.active>a,.nav-tabs.nav-justified>.active>a:focus,.nav-tabs.nav-justified>.active>a:hover {
    border: 1px solid #ddd
}

@media (min-width: 768px) {
    .nav-tabs.nav-justified>li>a {
        border-bottom:1px solid #ddd;
        border-radius: 4px 4px 0 0
    }

    .nav-tabs.nav-justified>.active>a,.nav-tabs.nav-justified>.active>a:focus,.nav-tabs.nav-justified>.active>a:hover {
        border-bottom-color: #fff
    }
}

.nav-pills>li {
    float: left
}

.nav-pills>li>a {
    border-radius: 4px
}

.nav-pills>li+li {
    margin-left: 2px
}

.nav-pills>li.active>a,.nav-pills>li.active>a:focus,.nav-pills>li.active>a:hover {
    color: #fff;
    background-color: #337ab7
}

.nav-stacked>li {
    float: none
}

.nav-stacked>li+li {
    margin-top: 2px;
    margin-left: 0
}

.nav-justified {
    width: 100%
}

.nav-justified>li {
    float: none
}

.nav-justified>li>a {
    margin-bottom: 5px;
    text-align: center
}

.nav-justified>.dropdown .dropdown-menu {
    top: auto;
    left: auto
}

@media (min-width: 768px) {
    .nav-justified>li {
        display:table-cell;
        width: 1%
    }

    .nav-justified>li>a {
        margin-bottom: 0
    }
}

.nav-tabs-justified {
    border-bottom: 0
}

.nav-tabs-justified>li>a {
    margin-right: 0;
    border-radius: 4px
}

.nav-tabs-justified>.active>a,.nav-tabs-justified>.active>a:focus,.nav-tabs-justified>.active>a:hover {
    border: 1px solid #ddd
}

@media (min-width: 768px) {
    .nav-tabs-justified>li>a {
        border-bottom:1px solid #ddd;
        border-radius: 4px 4px 0 0
    }

    .nav-tabs-justified>.active>a,.nav-tabs-justified>.active>a:focus,.nav-tabs-justified>.active>a:hover {
        border-bottom-color: #fff
    }
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent
}

@media (min-width: 768px) {
    .navbar {
        border-radius:4px
    }
}

@media (min-width: 768px) {
    .navbar-header {
        float:left
    }
}

.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1)
}

.navbar-collapse.in {
    overflow-y: auto
}

@media (max-width: 768px) {
    .navbar-collapse {
        width:auto;
        border-top: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .navbar-collapse.collapse {
        display: none!important;
        height: auto!important;
        padding-bottom: 0;
        overflow: visible!important
    }

    .navbar-collapse.in {
        overflow-y: visible;
        display: block!important
    }

    .navbar-fixed-bottom .navbar-collapse,.navbar-fixed-top .navbar-collapse,.navbar-static-top .navbar-collapse {
        padding-right: 0;
        padding-left: 0
    }
}

.navbar-fixed-bottom .navbar-collapse,.navbar-fixed-top .navbar-collapse {
    max-height: 340px
}

@media (max-device-width: 480px) and (orientation:landscape) {
    .navbar-fixed-bottom .navbar-collapse,.navbar-fixed-top .navbar-collapse {
        max-height:200px
    }
}

.container-fluid>.navbar-collapse,.container-fluid>.navbar-header,.container>.navbar-collapse,.container>.navbar-header {
    margin-right: -15px;
    margin-left: -15px
}

@media (min-width: 768px) {
    .container-fluid>.navbar-collapse,.container-fluid>.navbar-header,.container>.navbar-collapse,.container>.navbar-header {
        margin-right:0;
        margin-left: 0
    }
}

.navbar-static-top {
    z-index: 1000;
    border-width: 0 0 1px
}

@media (min-width: 768px) {
    .navbar-static-top {
        border-radius:0
    }
}

.navbar-fixed-bottom,.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030
}

@media (min-width: 768px) {
    .navbar-fixed-bottom,.navbar-fixed-top {
        border-radius:0
    }
}

.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px
}

.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
    border-width: 1px 0 0
}

.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px;
    font-size: 18px;
    line-height: 20px
}

.navbar-brand:focus,.navbar-brand:hover {
    text-decoration: none
}

.navbar-brand>img {
    display: block
}

@media (min-width: 768px) {
    .navbar>.container .navbar-brand,.navbar>.container-fluid .navbar-brand {
        margin-left:-15px
    }
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px
}

.navbar-toggle:focus {
    outline: 0
}

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px
}

@media (min-width: 768px) {
    .navbar-toggle {
        display:none
    }
}

.navbar-nav {
    margin: 7.5px -15px
}

.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        position:static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .navbar-nav .open .dropdown-menu .dropdown-header,.navbar-nav .open .dropdown-menu>li>a {
        padding: 5px 15px 5px 25px
    }

    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 20px
    }

    .navbar-nav .open .dropdown-menu>li>a:focus,.navbar-nav .open .dropdown-menu>li>a:hover {
        background-image: none
    }
}

@media (min-width: 768px) {
    .navbar-nav {
        float:left;
        margin: 0
    }

    .navbar-nav>li {
        float: left
    }

    .navbar-nav>li>a {
        padding-top: 15px;
        padding-bottom: 15px
    }
}

.navbar-form {
    padding: 10px 15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1),0 1px 0 rgba(255,255,255,.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1),0 1px 0 rgba(255,255,255,.1);
    margin: 8px -15px
}

@media (min-width: 768px) {
    .navbar-form .form-group {
        display:inline-block;
        margin-bottom: 0;
        vertical-align: middle
    }

    .navbar-form .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .navbar-form .form-control-static {
        display: inline-block
    }

    .navbar-form .input-group {
        display: inline-table;
        vertical-align: middle
    }

    .navbar-form .input-group .form-control,.navbar-form .input-group .input-group-addon,.navbar-form .input-group .input-group-btn {
        width: auto
    }

    .navbar-form .input-group>.form-control {
        width: 100%
    }

    .navbar-form .control-label {
        margin-bottom: 0;
        vertical-align: middle
    }

    .navbar-form .checkbox,.navbar-form .radio {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle
    }

    .navbar-form .checkbox label,.navbar-form .radio label {
        padding-left: 0
    }

    .navbar-form .checkbox input[type=checkbox],.navbar-form .radio input[type=radio] {
        position: relative;
        margin-left: 0
    }

    .navbar-form .has-feedback .form-control-feedback {
        top: 0
    }
}

@media (max-width: 767px) {
    .navbar-form .form-group {
        margin-bottom:5px
    }

    .navbar-form .form-group:last-child {
        margin-bottom: 0
    }
}

@media (min-width: 768px) {
    .navbar-form {
        width:auto;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0;
        margin-left: 0;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }
}

.navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.navbar-btn {
    margin-top: 8px;
    margin-bottom: 8px
}

.navbar-btn.btn-sm {
    margin-top: 10px;
    margin-bottom: 10px
}

.navbar-btn.btn-xs {
    margin-top: 14px;
    margin-bottom: 14px
}

.navbar-text {
    margin-top: 15px;
    margin-bottom: 15px
}

@media (min-width: 768px) {
    .navbar-text {
        float:left;
        margin-right: 15px;
        margin-left: 15px
    }
}

@media (min-width: 768px) {
    .navbar-left {
        float:left!important
    }

    .navbar-right {
        float: right!important;
        margin-right: -15px
    }

    .navbar-right~.navbar-right {
        margin-right: 0
    }
}

.navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7
}

.navbar-default .navbar-brand {
    color: #777
}

.navbar-default .navbar-brand:focus,.navbar-default .navbar-brand:hover {
    color: #5e5e5e;
    background-color: transparent
}

.navbar-default .navbar-text {
    color: #777
}

.navbar-default .navbar-nav>li>a {
    color: #777
}

.navbar-default .navbar-nav>li>a:focus,.navbar-default .navbar-nav>li>a:hover {
    color: #333;
    background-color: transparent
}

.navbar-default .navbar-nav>.active>a,.navbar-default .navbar-nav>.active>a:focus,.navbar-default .navbar-nav>.active>a:hover {
    color: #555;
    background-color: #e7e7e7
}

.navbar-default .navbar-nav>.disabled>a,.navbar-default .navbar-nav>.disabled>a:focus,.navbar-default .navbar-nav>.disabled>a:hover {
    color: #ccc;
    background-color: transparent
}

.navbar-default .navbar-toggle {
    border-color: #ddd
}

.navbar-default .navbar-toggle:focus,.navbar-default .navbar-toggle:hover {
    background-color: #ddd
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #888
}

.navbar-default .navbar-collapse,.navbar-default .navbar-form {
    border-color: #e7e7e7
}

.navbar-default .navbar-nav>.open>a,.navbar-default .navbar-nav>.open>a:focus,.navbar-default .navbar-nav>.open>a:hover {
    color: #555;
    background-color: #e7e7e7
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color:#777
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,.navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #333;
        background-color: transparent
    }

    .navbar-default .navbar-nav .open .dropdown-menu>.active>a,.navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus,.navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover {
        color: #555;
        background-color: #e7e7e7
    }

    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,.navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus,.navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover {
        color: #ccc;
        background-color: transparent
    }
}

.navbar-default .navbar-link {
    color: #777
}

.navbar-default .navbar-link:hover {
    color: #333
}

.navbar-default .btn-link {
    color: #777
}

.navbar-default .btn-link:focus,.navbar-default .btn-link:hover {
    color: #333
}

.navbar-default .btn-link[disabled]:focus,.navbar-default .btn-link[disabled]:hover,fieldset[disabled] .navbar-default .btn-link:focus,fieldset[disabled] .navbar-default .btn-link:hover {
    color: #ccc
}

.navbar-inverse {
    background-color: #222;
    border-color: #080808
}

.navbar-inverse .navbar-brand {
    color: #9d9d9d
}

.navbar-inverse .navbar-brand:focus,.navbar-inverse .navbar-brand:hover {
    color: #fff;
    background-color: transparent
}

.navbar-inverse .navbar-text {
    color: #9d9d9d
}

.navbar-inverse .navbar-nav>li>a {
    color: #9d9d9d
}

.navbar-inverse .navbar-nav>li>a:focus,.navbar-inverse .navbar-nav>li>a:hover {
    color: #fff;
    background-color: transparent
}

.navbar-inverse .navbar-nav>.active>a,.navbar-inverse .navbar-nav>.active>a:focus,.navbar-inverse .navbar-nav>.active>a:hover {
    color: #fff;
    background-color: #080808
}

.navbar-inverse .navbar-nav>.disabled>a,.navbar-inverse .navbar-nav>.disabled>a:focus,.navbar-inverse .navbar-nav>.disabled>a:hover {
    color: #444;
    background-color: transparent
}

.navbar-inverse .navbar-toggle {
    border-color: #333
}

.navbar-inverse .navbar-toggle:focus,.navbar-inverse .navbar-toggle:hover {
    background-color: #333
}

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #fff
}

.navbar-inverse .navbar-collapse,.navbar-inverse .navbar-form {
    border-color: #101010
}

.navbar-inverse .navbar-nav>.open>a,.navbar-inverse .navbar-nav>.open>a:focus,.navbar-inverse .navbar-nav>.open>a:hover {
    color: #fff;
    background-color: #080808
}

@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
        border-color:#080808
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
        background-color: #080808
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: #9d9d9d
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus,.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #fff;
        background-color: transparent
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus,.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover {
        color: #fff;
        background-color: #080808
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,.navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus,.navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover {
        color: #444;
        background-color: transparent
    }
}

.navbar-inverse .navbar-link {
    color: #9d9d9d
}

.navbar-inverse .navbar-link:hover {
    color: #fff
}

.navbar-inverse .btn-link {
    color: #9d9d9d
}

.navbar-inverse .btn-link:focus,.navbar-inverse .btn-link:hover {
    color: #fff
}

.navbar-inverse .btn-link[disabled]:focus,.navbar-inverse .btn-link[disabled]:hover,fieldset[disabled] .navbar-inverse .btn-link:focus,fieldset[disabled] .navbar-inverse .btn-link:hover {
    color: #444
}

.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px
}

.breadcrumb>li {
    display: inline-block
}

.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0"
}

.breadcrumb>.active {
    color: #777
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px
}

.pagination>li {
    display: inline
}

.pagination>li>a,.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd
}

.pagination>li:first-child>a,.pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.pagination>li:last-child>a,.pagination>li:last-child>span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.pagination>li>a:focus,.pagination>li>a:hover,.pagination>li>span:focus,.pagination>li>span:hover {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd
}

.pagination>.active>a,.pagination>.active>a:focus,.pagination>.active>a:hover,.pagination>.active>span,.pagination>.active>span:focus,.pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7
}

.pagination>.disabled>a,.pagination>.disabled>a:focus,.pagination>.disabled>a:hover,.pagination>.disabled>span,.pagination>.disabled>span:focus,.pagination>.disabled>span:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd
}

.pagination-lg>li>a,.pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333
}

.pagination-lg>li:first-child>a,.pagination-lg>li:first-child>span {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.pagination-lg>li:last-child>a,.pagination-lg>li:last-child>span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.pagination-sm>li>a,.pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5
}

.pagination-sm>li:first-child>a,.pagination-sm>li:first-child>span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

.pagination-sm>li:last-child>a,.pagination-sm>li:last-child>span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.pager {
    padding-left: 0;
    margin: 20px 0;
    text-align: center;
    list-style: none
}

.pager li {
    display: inline
}

.pager li>a,.pager li>span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px
}

.pager li>a:focus,.pager li>a:hover {
    text-decoration: none;
    background-color: #eee
}

.pager .next>a,.pager .next>span {
    float: right
}

.pager .previous>a,.pager .previous>span {
    float: left
}

.pager .disabled>a,.pager .disabled>a:focus,.pager .disabled>a:hover,.pager .disabled>span {
    color: #777;
    cursor: not-allowed;
    background-color: #fff
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em
}

a.label:focus,a.label:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer
}

.label:empty {
    display: none
}

.btn .label {
    position: relative;
    top: -1px
}

.label-default {
    background-color: #777
}

.label-default[href]:focus,.label-default[href]:hover {
    background-color: #5e5e5e
}

.label-primary {
    background-color: #337ab7
}

.label-primary[href]:focus,.label-primary[href]:hover {
    background-color: #286090
}

.label-success {
    background-color: #5cb85c
}

.label-success[href]:focus,.label-success[href]:hover {
    background-color: #449d44
}

.label-info {
    background-color: #5bc0de
}

.label-info[href]:focus,.label-info[href]:hover {
    background-color: #31b0d5
}

.label-warning {
    background-color: #f0ad4e
}

.label-warning[href]:focus,.label-warning[href]:hover {
    background-color: #ec971f
}

.label-danger {
    background-color: #d9534f
}

.label-danger[href]:focus,.label-danger[href]:hover {
    background-color: #c9302c
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.btn-group-xs>.btn .badge,.btn-xs .badge {
    top: 0;
    padding: 1px 5px
}

a.badge:focus,a.badge:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer
}

.list-group-item.active>.badge,.nav-pills>.active>a>.badge {
    color: #337ab7;
    background-color: #fff
}

.list-group-item>.badge {
    float: right
}

.list-group-item>.badge+.badge {
    margin-right: 5px
}

.nav-pills>li>a>.badge {
    margin-left: 3px
}

.jumbotron {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    color: inherit;
    background-color: #eee
}

.jumbotron .h1,.jumbotron h1 {
    color: inherit
}

.jumbotron p {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: 200
}

.jumbotron>hr {
    border-top-color: #d5d5d5
}

.container .jumbotron,.container-fluid .jumbotron {
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 6px
}

.jumbotron .container {
    max-width: 100%
}

@media screen and (min-width: 768px) {
    .jumbotron {
        padding-top:48px;
        padding-bottom: 48px
    }

    .container .jumbotron,.container-fluid .jumbotron {
        padding-right: 60px;
        padding-left: 60px
    }

    .jumbotron .h1,.jumbotron h1 {
        font-size: 63px
    }
}

.thumbnail {
    display: block;
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out
}

.thumbnail a>img,.thumbnail>img {
    margin-right: auto;
    margin-left: auto
}

a.thumbnail.active,a.thumbnail:focus,a.thumbnail:hover {
    border-color: #337ab7
}

.thumbnail .caption {
    padding: 9px;
    color: #333
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px
}

.alert h4 {
    margin-top: 0;
    color: inherit
}

.alert .alert-link {
    font-weight: 700
}

.alert>p,.alert>ul {
    margin-bottom: 0
}

.alert>p+p {
    margin-top: 5px
}

.alert-dismissable,.alert-dismissible {
    padding-right: 35px
}

.alert-dismissable .close,.alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit
}

.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6
}

.alert-success hr {
    border-top-color: #c9e2b3
}

.alert-success .alert-link {
    color: #2b542c
}

.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1
}

.alert-info hr {
    border-top-color: #a6e1ec
}

.alert-info .alert-link {
    color: #245269
}

.alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc
}

.alert-warning hr {
    border-top-color: #f7e1b5
}

.alert-warning .alert-link {
    color: #66512c
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1
}

.alert-danger hr {
    border-top-color: #e4b9c0
}

.alert-danger .alert-link {
    color: #843534
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }

    to {
        background-position: 0 0
    }
}

@-o-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1)
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease
}

.progress-bar-striped,.progress-striped .progress-bar {
    background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    -webkit-background-size: 40px 40px;
    background-size: 40px 40px
}

.progress-bar.active,.progress.active .progress-bar {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite
}

.progress-bar-success {
    background-color: #5cb85c
}

.progress-striped .progress-bar-success {
    background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)
}

.progress-bar-info {
    background-color: #5bc0de
}

.progress-striped .progress-bar-info {
    background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)
}

.progress-bar-warning {
    background-color: #f0ad4e
}

.progress-striped .progress-bar-warning {
    background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)
}

.progress-bar-danger {
    background-color: #d9534f
}

.progress-striped .progress-bar-danger {
    background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)
}

.media {
    margin-top: 15px
}

.media:first-child {
    margin-top: 0
}

.media,.media-body {
    overflow: hidden;
    zoom:1}

.media-body {
    width: 10000px
}

.media-object {
    display: block
}

.media-object.img-thumbnail {
    max-width: none
}

.media-right,.media>.pull-right {
    padding-left: 10px
}

.media-left,.media>.pull-left {
    padding-right: 10px
}

.media-body,.media-left,.media-right {
    display: table-cell;
    vertical-align: top
}

.media-middle {
    vertical-align: middle
}

.media-bottom {
    vertical-align: bottom
}

.media-heading {
    margin-top: 0;
    margin-bottom: 5px
}

.media-list {
    padding-left: 0;
    list-style: none
}

.list-group {
    padding-left: 0;
    margin-bottom: 20px
}

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd
}

.list-group-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

a.list-group-item,button.list-group-item {
    color: #555
}

a.list-group-item .list-group-item-heading,button.list-group-item .list-group-item-heading {
    color: #333
}

a.list-group-item:focus,a.list-group-item:hover,button.list-group-item:focus,button.list-group-item:hover {
    color: #555;
    text-decoration: none;
    background-color: #f5f5f5
}

button.list-group-item {
    width: 100%;
    text-align: left
}

.list-group-item.disabled,.list-group-item.disabled:focus,.list-group-item.disabled:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #eee
}

.list-group-item.disabled .list-group-item-heading,.list-group-item.disabled:focus .list-group-item-heading,.list-group-item.disabled:hover .list-group-item-heading {
    color: inherit
}

.list-group-item.disabled .list-group-item-text,.list-group-item.disabled:focus .list-group-item-text,.list-group-item.disabled:hover .list-group-item-text {
    color: #777
}

.list-group-item.active,.list-group-item.active:focus,.list-group-item.active:hover {
    z-index: 2;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7
}

.list-group-item.active .list-group-item-heading,.list-group-item.active .list-group-item-heading>.small,.list-group-item.active .list-group-item-heading>small,.list-group-item.active:focus .list-group-item-heading,.list-group-item.active:focus .list-group-item-heading>.small,.list-group-item.active:focus .list-group-item-heading>small,.list-group-item.active:hover .list-group-item-heading,.list-group-item.active:hover .list-group-item-heading>.small,.list-group-item.active:hover .list-group-item-heading>small {
    color: inherit
}

.list-group-item.active .list-group-item-text,.list-group-item.active:focus .list-group-item-text,.list-group-item.active:hover .list-group-item-text {
    color: #c7ddef
}

.list-group-item-success {
    color: #3c763d;
    background-color: #dff0d8
}

a.list-group-item-success,button.list-group-item-success {
    color: #3c763d
}

a.list-group-item-success .list-group-item-heading,button.list-group-item-success .list-group-item-heading {
    color: inherit
}

a.list-group-item-success:focus,a.list-group-item-success:hover,button.list-group-item-success:focus,button.list-group-item-success:hover {
    color: #3c763d;
    background-color: #d0e9c6
}

a.list-group-item-success.active,a.list-group-item-success.active:focus,a.list-group-item-success.active:hover,button.list-group-item-success.active,button.list-group-item-success.active:focus,button.list-group-item-success.active:hover {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d
}

.list-group-item-info {
    color: #31708f;
    background-color: #d9edf7
}

a.list-group-item-info,button.list-group-item-info {
    color: #31708f
}

a.list-group-item-info .list-group-item-heading,button.list-group-item-info .list-group-item-heading {
    color: inherit
}

a.list-group-item-info:focus,a.list-group-item-info:hover,button.list-group-item-info:focus,button.list-group-item-info:hover {
    color: #31708f;
    background-color: #c4e3f3
}

a.list-group-item-info.active,a.list-group-item-info.active:focus,a.list-group-item-info.active:hover,button.list-group-item-info.active,button.list-group-item-info.active:focus,button.list-group-item-info.active:hover {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f
}

.list-group-item-warning {
    color: #8a6d3b;
    background-color: #fcf8e3
}

a.list-group-item-warning,button.list-group-item-warning {
    color: #8a6d3b
}

a.list-group-item-warning .list-group-item-heading,button.list-group-item-warning .list-group-item-heading {
    color: inherit
}

a.list-group-item-warning:focus,a.list-group-item-warning:hover,button.list-group-item-warning:focus,button.list-group-item-warning:hover {
    color: #8a6d3b;
    background-color: #faf2cc
}

a.list-group-item-warning.active,a.list-group-item-warning.active:focus,a.list-group-item-warning.active:hover,button.list-group-item-warning.active,button.list-group-item-warning.active:focus,button.list-group-item-warning.active:hover {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b
}

.list-group-item-danger {
    color: #a94442;
    background-color: #f2dede
}

a.list-group-item-danger,button.list-group-item-danger {
    color: #a94442
}

a.list-group-item-danger .list-group-item-heading,button.list-group-item-danger .list-group-item-heading {
    color: inherit
}

a.list-group-item-danger:focus,a.list-group-item-danger:hover,button.list-group-item-danger:focus,button.list-group-item-danger:hover {
    color: #a94442;
    background-color: #ebcccc
}

a.list-group-item-danger.active,a.list-group-item-danger.active:focus,a.list-group-item-danger.active:hover,button.list-group-item-danger.active,button.list-group-item-danger.active:focus,button.list-group-item-danger.active:hover {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442
}

.list-group-item-heading {
    margin-top: 0;
    margin-bottom: 5px
}

.list-group-item-text {
    margin-bottom: 0;
    line-height: 1.3
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05)
}

.panel-body {
    padding: 15px
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.panel-heading>.dropdown .dropdown-toggle {
    color: inherit
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit
}

.panel-title>.small,.panel-title>.small>a,.panel-title>a,.panel-title>small,.panel-title>small>a {
    color: inherit
}

.panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.panel>.list-group,.panel>.panel-collapse>.list-group {
    margin-bottom: 0
}

.panel>.list-group .list-group-item,.panel>.panel-collapse>.list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0
}

.panel>.list-group:first-child .list-group-item:first-child,.panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.panel>.list-group:last-child .list-group-item:last-child,.panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.panel-heading+.list-group .list-group-item:first-child {
    border-top-width: 0
}

.list-group+.panel-footer {
    border-top-width: 0
}

.panel>.panel-collapse>.table,.panel>.table,.panel>.table-responsive>.table {
    margin-bottom: 0
}

.panel>.panel-collapse>.table caption,.panel>.table caption,.panel>.table-responsive>.table caption {
    padding-right: 15px;
    padding-left: 15px
}

.panel>.table-responsive:first-child>.table:first-child,.panel>.table:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child,.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child,.panel>.table:first-child>tbody:first-child>tr:first-child,.panel>.table:first-child>thead:first-child>tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child,.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,.panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,.panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,.panel>.table:first-child>thead:first-child>tr:first-child td:first-child,.panel>.table:first-child>thead:first-child>tr:first-child th:first-child {
    border-top-left-radius: 3px
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child,.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,.panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,.panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,.panel>.table:first-child>thead:first-child>tr:first-child td:last-child,.panel>.table:first-child>thead:first-child>tr:first-child th:last-child {
    border-top-right-radius: 3px
}

.panel>.table-responsive:last-child>.table:last-child,.panel>.table:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child,.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child,.panel>.table:last-child>tbody:last-child>tr:last-child,.panel>.table:last-child>tfoot:last-child>tr:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child,.panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,.panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,.panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,.panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
    border-bottom-left-radius: 3px
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child,.panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,.panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,.panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,.panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
    border-bottom-right-radius: 3px
}

.panel>.panel-body+.table,.panel>.panel-body+.table-responsive,.panel>.table+.panel-body,.panel>.table-responsive+.panel-body {
    border-top: 1px solid #ddd
}

.panel>.table>tbody:first-child>tr:first-child td,.panel>.table>tbody:first-child>tr:first-child th {
    border-top: 0
}

.panel>.table-bordered,.panel>.table-responsive>.table-bordered {
    border: 0
}

.panel>.table-bordered>tbody>tr>td:first-child,.panel>.table-bordered>tbody>tr>th:first-child,.panel>.table-bordered>tfoot>tr>td:first-child,.panel>.table-bordered>tfoot>tr>th:first-child,.panel>.table-bordered>thead>tr>td:first-child,.panel>.table-bordered>thead>tr>th:first-child,.panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,.panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,.panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child,.panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,.panel>.table-responsive>.table-bordered>thead>tr>td:first-child,.panel>.table-responsive>.table-bordered>thead>tr>th:first-child {
    border-left: 0
}

.panel>.table-bordered>tbody>tr>td:last-child,.panel>.table-bordered>tbody>tr>th:last-child,.panel>.table-bordered>tfoot>tr>td:last-child,.panel>.table-bordered>tfoot>tr>th:last-child,.panel>.table-bordered>thead>tr>td:last-child,.panel>.table-bordered>thead>tr>th:last-child,.panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,.panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,.panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child,.panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,.panel>.table-responsive>.table-bordered>thead>tr>td:last-child,.panel>.table-responsive>.table-bordered>thead>tr>th:last-child {
    border-right: 0
}

.panel>.table-bordered>tbody>tr:first-child>td,.panel>.table-bordered>tbody>tr:first-child>th,.panel>.table-bordered>thead>tr:first-child>td,.panel>.table-bordered>thead>tr:first-child>th,.panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,.panel>.table-responsive>.table-bordered>tbody>tr:first-child>th,.panel>.table-responsive>.table-bordered>thead>tr:first-child>td,.panel>.table-responsive>.table-bordered>thead>tr:first-child>th {
    border-bottom: 0
}

.panel>.table-bordered>tbody>tr:last-child>td,.panel>.table-bordered>tbody>tr:last-child>th,.panel>.table-bordered>tfoot>tr:last-child>td,.panel>.table-bordered>tfoot>tr:last-child>th,.panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,.panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th {
    border-bottom: 0
}

.panel>.table-responsive {
    margin-bottom: 0;
    border: 0
}

.panel-group {
    margin-bottom: 20px
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px
}

.panel-group .panel+.panel {
    margin-top: 5px
}

.panel-group .panel-heading {
    border-bottom: 0
}

.panel-group .panel-heading+.panel-collapse>.list-group,.panel-group .panel-heading+.panel-collapse>.panel-body {
    border-top: 1px solid #ddd
}

.panel-group .panel-footer {
    border-top: 0
}

.panel-group .panel-footer+.panel-collapse .panel-body {
    border-bottom: 1px solid #ddd
}

.panel-default {
    border-color: #ddd
}

.panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ddd
}

.panel-default>.panel-heading .badge {
    color: #f5f5f5;
    background-color: #333
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ddd
}

.panel-primary {
    border-color: #337ab7
}

.panel-primary>.panel-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #337ab7
}

.panel-primary>.panel-heading .badge {
    color: #337ab7;
    background-color: #fff
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #337ab7
}

.panel-success {
    border-color: #d6e9c6
}

.panel-success>.panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #d6e9c6
}

.panel-success>.panel-heading .badge {
    color: #dff0d8;
    background-color: #3c763d
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #d6e9c6
}

.panel-info {
    border-color: #bce8f1
}

.panel-info>.panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #bce8f1
}

.panel-info>.panel-heading .badge {
    color: #d9edf7;
    background-color: #31708f
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #bce8f1
}

.panel-warning {
    border-color: #faebcc
}

.panel-warning>.panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #faebcc
}

.panel-warning>.panel-heading .badge {
    color: #fcf8e3;
    background-color: #8a6d3b
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #faebcc
}

.panel-danger {
    border-color: #ebccd1
}

.panel-danger>.panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ebccd1
}

.panel-danger>.panel-heading .badge {
    color: #f2dede;
    background-color: #a94442
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ebccd1
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden
}

.embed-responsive .embed-responsive-item,.embed-responsive embed,.embed-responsive iframe,.embed-responsive object,.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-16by9 {
    padding-bottom: 56.25%
}

.embed-responsive-4by3 {
    padding-bottom: 75%
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05)
}

.well blockquote {
    border-color: #ddd;
    border-color: rgba(0,0,0,.15)
}

.well-lg {
    padding: 24px;
    border-radius: 6px
}

.well-sm {
    padding: 9px;
    border-radius: 3px
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2
}

.close:focus,.close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .5
}

button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0
}

.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0,-25%);
    -ms-transform: translate(0,-25%);
    -o-transform: translate(0,-25%);
    transform: translate(0,-25%)
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0)
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
}

.modal-content {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    box-shadow: 0 3px 9px rgba(0,0,0,.5)
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

.modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0
}

.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5
}

.modal-header .close {
    margin-top: -2px
}

.modal-title {
    margin: 0;
    line-height: 1.42857143
}

.modal-body {
    position: relative;
    padding: 15px
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5
}

.modal-footer .btn+.btn {
    margin-bottom: 0;
    margin-left: 5px
}

.modal-footer .btn-group .btn+.btn {
    margin-left: -1px
}

.modal-footer .btn-block+.btn-block {
    margin-left: 0
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width: 768px) {
    .modal-dialog {
        width:600px;
        margin: 30px auto
    }

    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
        box-shadow: 0 5px 15px rgba(0,0,0,.5)
    }

    .modal-sm {
        width: 300px
    }
}

@media (min-width: 992px) {
    .modal-lg {
        width:900px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    filter: alpha(opacity=0);
    opacity: 0;
    line-break: auto
}

.tooltip.in {
    filter: alpha(opacity=90);
    opacity: .9
}

.tooltip.top {
    padding: 5px 0;
    margin-top: -3px
}

.tooltip.right {
    padding: 0 5px;
    margin-left: 3px
}

.tooltip.bottom {
    padding: 5px 0;
    margin-top: 3px
}

.tooltip.left {
    padding: 0 5px;
    margin-left: -3px
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000
}

.tooltip.top-left .tooltip-arrow {
    right: 5px;
    bottom: 0;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000
}

.tooltip.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000
}

.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000
}

.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    line-break: auto
}

.popover.top {
    margin-top: -10px
}

.popover.right {
    margin-left: 10px
}

.popover.bottom {
    margin-top: 10px
}

.popover.left {
    margin-left: -10px
}

.popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0
}

.popover-content {
    padding: 9px 14px
}

.popover>.arrow,.popover>.arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.popover>.arrow {
    border-width: 11px
}

.popover>.arrow:after {
    content: "";
    border-width: 10px
}

.popover.top>.arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0,0,0,.25);
    border-bottom-width: 0
}

.popover.top>.arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0
}

.popover.right>.arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0,0,0,.25);
    border-left-width: 0
}

.popover.right>.arrow:after {
    bottom: -10px;
    left: 1px;
    content: " ";
    border-right-color: #fff;
    border-left-width: 0
}

.popover.bottom>.arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0,0,0,.25)
}

.popover.bottom>.arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff
}

.popover.left>.arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0,0,0,.25)
}

.popover.left>.arrow:after {
    right: 1px;
    bottom: -10px;
    content: " ";
    border-right-width: 0;
    border-left-color: #fff
}

.carousel {
    position: relative
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner>.item {
    position: relative;
    display: none;
    -webkit-transition: .6s ease-in-out left;
    -o-transition: .6s ease-in-out left;
    transition: .6s ease-in-out left
}

.carousel-inner>.item>a>img,.carousel-inner>.item>img {
    line-height: 1
}

@media all and (transform-3d),(-webkit-transform-3d) {
    .carousel-inner>.item {
        -webkit-transition: -webkit-transform .6s ease-in-out;
        -o-transition: -o-transform .6s ease-in-out;
        transition: transform .6s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px
    }

    .carousel-inner>.item.active.right,.carousel-inner>.item.next {
        left: 0;
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }

    .carousel-inner>.item.active.left,.carousel-inner>.item.prev {
        left: 0;
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0)
    }

    .carousel-inner>.item.active,.carousel-inner>.item.next.left,.carousel-inner>.item.prev.right {
        left: 0;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }
}

.carousel-inner>.active,.carousel-inner>.next,.carousel-inner>.prev {
    display: block
}

.carousel-inner>.active {
    left: 0
}

.carousel-inner>.next,.carousel-inner>.prev {
    position: absolute;
    top: 0;
    width: 100%
}

.carousel-inner>.next {
    left: 100%
}

.carousel-inner>.prev {
    left: -100%
}

.carousel-inner>.next.left,.carousel-inner>.prev.right {
    left: 0
}

.carousel-inner>.active.left {
    left: -100%
}

.carousel-inner>.active.right {
    left: 100%
}

.carousel-control {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0,0,0,.6);
    background-color: rgba(0,0,0,0);
    filter: alpha(opacity=50);
    opacity: .5
}

.carousel-control.left {
    background-image: -webkit-linear-gradient(left,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
    background-image: -o-linear-gradient(left,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
    background-image: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.5)),to(rgba(0,0,0,.0001)));
    background-image: linear-gradient(to right,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000',endColorstr='#00000',GradientType=1);
    background-repeat: repeat-x
}

.carousel-control.right {
    right: 0;
    left: auto;
    background-image: -webkit-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
    background-image: -o-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
    background-image: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.0001)),to(rgba(0,0,0,.5)));
    background-image: linear-gradient(to right,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000',endColorstr='#80000000',GradientType=1);
    background-repeat: repeat-x
}

.carousel-control:focus,.carousel-control:hover {
    color: #fff;
    text-decoration: none;
    filter: alpha(opacity=90);
    outline: 0;
    opacity: .9
}

.carousel-control .glyphicon-chevron-left,.carousel-control .glyphicon-chevron-right,.carousel-control .icon-next,.carousel-control .icon-prev {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px
}

.carousel-control .glyphicon-chevron-left,.carousel-control .icon-prev {
    left: 50%;
    margin-left: -10px
}

.carousel-control .glyphicon-chevron-right,.carousel-control .icon-next {
    right: 50%;
    margin-right: -10px
}

.carousel-control .icon-next,.carousel-control .icon-prev {
    width: 20px;
    height: 20px;
    font-family: serif;
    line-height: 1
}

.carousel-control .icon-prev:before {
    content: '\2039'
}

.carousel-control .icon-next:before {
    content: '\203a'
}

.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none
}

.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000\9;
    background-color: rgba(0,0,0,0);
    border: 1px solid #fff;
    border-radius: 10px
}

.carousel-indicators .active {
    width: 12px;
    height: 12px;
    margin: 0;
    background-color: #fff
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0,0,0,.6)
}

.carousel-caption .btn {
    text-shadow: none
}

@media screen and (min-width: 768px) {
    .carousel-control .glyphicon-chevron-left,.carousel-control .glyphicon-chevron-right,.carousel-control .icon-next,.carousel-control .icon-prev {
        width:30px;
        height: 30px;
        margin-top: -10px;
        font-size: 30px
    }

    .carousel-control .glyphicon-chevron-left,.carousel-control .icon-prev {
        margin-left: -10px
    }

    .carousel-control .glyphicon-chevron-right,.carousel-control .icon-next {
        margin-right: -10px
    }

    .carousel-caption {
        right: 20%;
        left: 20%;
        padding-bottom: 30px
    }

    .carousel-indicators {
        bottom: 20px
    }
}

.btn-group-vertical>.btn-group:after,.btn-group-vertical>.btn-group:before,.btn-toolbar:after,.btn-toolbar:before,.clearfix:after,.clearfix:before,.container-fluid:after,.container-fluid:before,.container:after,.container:before,.dl-horizontal dd:after,.dl-horizontal dd:before,.form-horizontal .form-group:after,.form-horizontal .form-group:before,.modal-footer:after,.modal-footer:before,.modal-header:after,.modal-header:before,.nav:after,.nav:before,.navbar-collapse:after,.navbar-collapse:before,.navbar-header:after,.navbar-header:before,.navbar:after,.navbar:before,.pager:after,.pager:before,.panel-body:after,.panel-body:before,.row:after,.row:before {
    display: table;
    content: " "
}

.btn-group-vertical>.btn-group:after,.btn-toolbar:after,.clearfix:after,.container-fluid:after,.container:after,.dl-horizontal dd:after,.form-horizontal .form-group:after,.modal-footer:after,.modal-header:after,.nav:after,.navbar-collapse:after,.navbar-header:after,.navbar:after,.pager:after,.panel-body:after,.row:after {
    clear: both
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto
}

.pull-right {
    float: right!important
}

.pull-left {
    float: left!important
}

.hide {
    display: none!important
}

.show {
    display: block!important
}

.invisible {
    visibility: hidden
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.hidden {
    display: none!important
}

.affix {
    position: fixed
}

.visible-lg,.visible-md,.visible-sm,.visible-xs {
    display: none!important
}

.visible-lg-block,.visible-lg-inline,.visible-lg-inline-block,.visible-md-block,.visible-md-inline,.visible-md-inline-block,.visible-sm-block,.visible-sm-inline,.visible-sm-inline-block,.visible-xs-block,.visible-xs-inline,.visible-xs-inline-block {
    display: none!important
}

@media (max-width: 767px) {
    .visible-xs {
        display:block!important
    }

    table.visible-xs {
        display: table!important
    }

    tr.visible-xs {
        display: table-row!important
    }

    td.visible-xs,th.visible-xs {
        display: table-cell!important
    }
}

@media (max-width: 767px) {
    .visible-xs-block {
        display:block!important
    }
}

@media (max-width: 767px) {
    .visible-xs-inline {
        display:inline!important
    }
}

@media (max-width: 767px) {
    .visible-xs-inline-block {
        display:inline-block!important
    }
}

@media (min-width: 768px) and (max-width:991px) {
    .visible-sm {
        display:block!important
    }

    table.visible-sm {
        display: table!important
    }

    tr.visible-sm {
        display: table-row!important
    }

    td.visible-sm,th.visible-sm {
        display: table-cell!important
    }

    .visible-sm-block {
        display: block!important
    }

    .visible-sm-inline {
        display: inline!important
    }

    .visible-sm-inline-block {
        display: inline-block!important
    }
}

@media (min-width: 992px) and (max-width:1199px) {
    .visible-md {
        display:block!important
    }

    table.visible-md {
        display: table!important
    }

    tr.visible-md {
        display: table-row!important
    }

    td.visible-md,th.visible-md {
        display: table-cell!important
    }

    .visible-md-block {
        display: block!important
    }

    .visible-md-inline {
        display: inline!important
    }

    .visible-md-inline-block {
        display: inline-block!important
    }
}

@media (min-width: 1200px) {
    .visible-lg {
        display:block!important
    }

    table.visible-lg {
        display: table!important
    }

    tr.visible-lg {
        display: table-row!important
    }

    td.visible-lg,th.visible-lg {
        display: table-cell!important
    }
}

@media (min-width: 1200px) {
    .visible-lg-block {
        display:block!important
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline {
        display:inline!important
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline-block {
        display:inline-block!important
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display:none!important
    }
}

@media (min-width: 768px) and (max-width:991px) {
    .hidden-sm {
        display:none!important
    }
}

@media (min-width: 992px) and (max-width:1199px) {
    .hidden-md {
        display:none!important
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display:none!important
    }
}

.visible-print {
    display: none!important
}

@media print {
    .visible-print {
        display: block!important
    }

    table.visible-print {
        display: table!important
    }

    tr.visible-print {
        display: table-row!important
    }

    td.visible-print,th.visible-print {
        display: table-cell!important
    }
}

.visible-print-block {
    display: none!important
}

@media print {
    .visible-print-block {
        display: block!important
    }
}

.visible-print-inline {
    display: none!important
}

@media print {
    .visible-print-inline {
        display: inline!important
    }
}

.visible-print-inline-block {
    display: none!important
}

@media print {
    .visible-print-inline-block {
        display: inline-block!important
    }
}

@media print {
    .hidden-print {
        display: none!important
    }
}

.flickity-enabled {
    position: relative
}

.flickity-enabled:focus {
    outline: none
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%
}

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing
}

.flickity-prev-next-button {
    position: absolute;
    top: 50%;
    width: 44px;
    height: 44px;
    border: none;
    border-radius: 50%;
    background: #fff;
    background: hsla(0,0%,100%,0.75);
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.flickity-prev-next-button:hover {
    background: #fff
}

.flickity-prev-next-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #09F
}

.flickity-prev-next-button:active {
    opacity: .6
}

.flickity-prev-next-button.previous {
    left: 10px
}

.flickity-prev-next-button.next {
    right: 10px
}

.flickity-prev-next-button:disabled {
    opacity: .3;
    cursor: auto
}

.flickity-prev-next-button svg {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%
}

.flickity-prev-next-button .arrow {
    fill: #333
}

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1
}

.flickity-rtl .flickity-page-dots {
    direction: rtl
}

.flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: .25;
    cursor: pointer
}

.flickity-page-dots .dot.is-selected {
    opacity: 1
}

.fr-window,.fr-window [class^="fr-"],.fr-overlay,.fr-overlay [class^="fr-"],.fr-spinner,.fr-spinner [class^="fr-"] {
    box-sizing: border-box
}

.fr-window {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font: 13px/20px "Lucida Sans","Lucida Sans Unicode","Lucida Grande",Verdana,Arial,sans-serif;
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px)
}

.fr-overlay {
    z-index: 99998
}

.fr-window {
    z-index: 99999
}

.fr-spinner {
    z-index: 100000
}

.fr-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.fr-overlay-background {
    float: left;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .9;
    filter: alpha(opacity=90)
}

.fr-overlay-ui-fullclick .fr-overlay-background {
    background: #292929;
    opacity: 1;
    filter: alpha(opacity=100)
}

.fr-window.fr-mobile-touch,.fr-overlay.fr-mobile-touch {
    position: absolute;
    overflow: visible
}

.fr-measured {
    margin: 0!important;
    min-width: 0!important;
    min-height: 0!important
}

.fr-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.fr-pages {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.fr-page {
    position: absolute;
    width: 100%;
    height: 100%
}

.fr-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    text-align: center
}

.fr-hovering-clickable .fr-container {
    cursor: pointer
}

.fr-ui-inside .fr-container {
    padding: 20px
}

.fr-ui-inside.fr-no-sides .fr-container {
    padding: 20px
}

.fr-ui-outside .fr-container {
    padding: 20px 82px
}

.fr-ui-outside.fr-no-sides .fr-container {
    padding: 20px 48px
}

@media all and (max-width: 700px) and (orientation:portrait),all and (orientation:landscape) and (max-height:700px) {
    .fr-ui-inside .fr-container {
        padding:12px
    }

    .fr-ui-inside.fr-no-sides .fr-container {
        padding: 12px
    }

    .fr-ui-outside .fr-container {
        padding: 12px 72px
    }

    .fr-ui-outside.fr-no-sides .fr-container {
        padding: 12px 48px
    }
}

@media all and (max-width: 500px) and (orientation:portrait),all and (orientation:landscape) and (max-height:500px) {
    .fr-ui-inside .fr-container {
        padding:0
    }

    .fr-ui-inside.fr-no-sides .fr-container {
        padding: 0
    }

    .fr-ui-outside .fr-container {
        padding: 0 72px
    }

    .fr-ui-outside.fr-no-sides .fr-container {
        padding: 0 48px
    }
}

.fr-ui-fullclick .fr-container {
    padding: 0
}

.fr-ui-fullclick.fr-no-sides .fr-container {
    padding: 0
}

.fr-ui-fullclick.fr-type-video .fr-container {
    padding: 0 62px
}

.fr-ui-fullclick.fr-no-sides.fr-type-video .fr-container {
    padding: 48px 0
}

.fr-overflow-y .fr-container {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.fr-content,.fr-content-background {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: visible
}

.fr-content-element {
    float: left;
    width: 100%;
    height: 100%
}

.fr-content-background {
    background: #101010
}

.fr-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #efefef;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text
}

.fr-info-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    line-height: 1%;
    -webkit-filter: none;
    filter: none;
    opacity: 1
}

.fr-ui-inside .fr-info-background {
    filter: alpha(opacity=80);
    opacity: .8;
    zoom:1}

.fr-ui-outside .fr-info-background {
    background: #0d0d0d
}

.fr-content .fr-info {
    top: auto;
    bottom: 0
}

.fr-info-padder {
    display: block;
    overflow: hidden;
    padding: 12px;
    position: relative;
    width: auto
}

.fr-caption {
    width: auto;
    display: inline
}

.fr-position {
    color: #b3b3b3;
    float: right;
    line-height: 21px;
    opacity: .99;
    position: relative;
    text-align: right;
    margin-left: 15px;
    white-space: nowrap
}

.fr-position-outside,.fr-position-inside {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 12px;
    width: auto;
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 11px;
    line-height: 20px;
    text-shadow: 0 1px 0 rgba(0,0,0,0.4);
    display: none;
    overflow: hidden;
    white-space: nowrap;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text
}

.fr-position-inside {
    border: 0
}

.fr-position-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0d0d0d;
    filter: alpha(opacity=80);
    opacity: .8;
    zoom:1}

.fr-position-text {
    color: #b3b3b3
}

.fr-position-outside .fr-position-text,.fr-position-inside .fr-position-text {
    float: left;
    position: relative;
    text-shadow: 0 1px 1px rgba(0,0,0,0.3);
    opacity: 1
}

.fr-ui-outside .fr-position-outside {
    display: block
}

.fr-ui-outside .fr-info .fr-position {
    display: none
}

.fr-ui-inside.fr-no-caption .fr-position-inside {
    display: block
}

.fr-info a,.fr-info a:hover {
    color: #ccc;
    border: 0;
    background: none;
    text-decoration: underline
}

.fr-info a:hover {
    color: #eee
}

.fr-ui-outside.fr-no-caption .fr-info {
    display: none
}

.fr-ui-inside.fr-no-caption .fr-caption {
    display: none
}

.fr-stroke {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px
}

.fr-stroke-vertical {
    width: 1px;
    height: 100%
}

.fr-stroke-horizontal {
    padding: 0 1px
}

.fr-stroke-right {
    left: auto;
    right: 0
}

.fr-stroke-bottom {
    top: auto;
    bottom: 0
}

.fr-stroke-color {
    float: left;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.08)
}

.fr-info .fr-stroke-color {
    background: rgba(80,80,80,0.3)
}

.fr-ui-outside.fr-has-caption .fr-content .fr-stroke-bottom {
    display: none
}

.fr-ui-fullclick .fr-stroke {
    display: none
}

.fr-ui-fullclick .fr-content-background {
    box-shadow: none
}

.fr-info .fr-stroke-top {
    display: none
}

.fr-side {
    position: absolute;
    top: 50%;
    width: 54px;
    height: 72px;
    margin: 0 9px;
    margin-top: -36px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    zoom:1}

.fr-side-previous {
    left: 0
}

.fr-side-next {
    right: 0;
    left: auto
}

.fr-side-disabled {
    cursor: default
}

.fr-side-hidden {
    display: none!important
}

.fr-side-button {
    float: left;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0
}

.fr-side-button-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #101010
}

.fr-side-button-icon {
    float: left;
    position: relative;
    height: 100%;
    width: 100%;
    zoom:1;background-position: 50% 50%;
    background-repeat: no-repeat
}

@media all and (max-width: 500px) and (orientation:portrait),all and (orientation:landscape) and (max-height:414px) {
    .fr-side {
        width:54px;
        height: 60px;
        margin: 0;
        margin-top: -30px
    }

    .fr-side-button {
        width: 48px;
        height: 60px;
        margin: 0 3px
    }
}

.fr-close {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 0;
    right: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.fr-close-background,.fr-close-icon {
    position: absolute;
    top: 12px;
    left: 12px;
    height: 26px;
    width: 26px;
    background-position: 50% 50%;
    background-repeat: no-repeat
}

.fr-close-background {
    background-color: #000
}

.fr-thumbnails {
    position: absolute;
    overflow: hidden
}

.fr-thumbnails-disabled .fr-thumbnails {
    display: none!important
}

.fr-thumbnails-horizontal .fr-thumbnails {
    width: 100%;
    height: 12%;
    min-height: 74px;
    max-height: 160px;
    bottom: 0
}

.fr-thumbnails-vertical .fr-thumbnails {
    height: 100%;
    width: 10%;
    min-width: 74px;
    max-width: 160px;
    left: 0
}

.fr-thumbnails,.fr-thumbnails * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.fr-thumbnails-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%
}

.fr-thumbnails-vertical .fr-thumbnails-wrapper {
    top: 50%;
    left: 0
}

.fr-thumbnails-slider {
    position: relative;
    width: 100%;
    height: 100%;
    float: left;
    zoom:1}

.fr-thumbnails-slider-slide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%
}

.fr-thumbnails-thumbs {
    float: left;
    height: 100%;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0
}

.fr-thumbnails-slide {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%
}

.fr-thumbnail-frame {
    position: absolute;
    zoom:1;overflow: hidden
}

.fr-thumbnail {
    position: absolute;
    width: 30px;
    height: 100%;
    left: 50%;
    top: 50%;
    zoom:1;cursor: pointer;
    margin: 0 10px
}

.fr-ltIE9 .fr-thumbnail * {
    overflow: hidden;
    z-index: 1;
    zoom:1}

.fr-thumbnail-wrapper {
    position: relative;
    background: #161616;
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    display: inline;
    z-index: 0
}

.fr-thumbnail-overlay {
    cursor: pointer
}

.fr-thumbnail-active .fr-thumbnail-overlay {
    cursor: default
}

.fr-thumbnail-overlay,.fr-thumbnail-overlay-background,.fr-thumbnail-overlay-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 0;
    overflow: hidden;
    border-style: solid;
    border-color: transparent
}

.fr-ltIE9 .fr-thumbnail-overlay-border {
    border-width: 0!important
}

.fr-thumbnail .fr-thumbnail-image {
    position: absolute;
    filter: alpha(opacity=85);
    opacity: .85;
    max-width: none
}

.fr-thumbnail:hover .fr-thumbnail-image,.fr-thumbnail-active:hover .fr-thumbnail-image {
    filter: alpha(opacity=99);
    opacity: .99
}

.fr-thumbnail-active .fr-thumbnail-image,.fr-thumbnail-active:hover .fr-thumbnail-image {
    filter: alpha(opacity=35);
    opacity: .35
}

.fr-thumbnail-active {
    cursor: default
}

.fr-thumbnail-loading,.fr-thumbnail-loading-background,.fr-thumbnail-loading-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.fr-thumbnail-loading-background {
    background-color: #161616;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    opacity: .8;
    position: relative;
    float: left
}

.fr-thumbnail-loading-icon {
    display: none
}

.fr-thumbnail-error .fr-thumbnail-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #202020
}

.fr-thumbnails-side {
    float: left;
    height: 100%;
    width: 28px;
    margin: 0 5px;
    position: relative;
    overflow: hidden
}

.fr-thumbnails-side-previous {
    margin-left: 12px
}

.fr-thumbnails-side-next {
    margin-right: 12px
}

.fr-thumbnails-vertical .fr-thumbnails-side {
    height: 28px;
    width: 100%;
    margin: 10px 0
}

.fr-thumbnails-vertical .fr-thumbnails-side-previous {
    margin-top: 20px
}

.fr-thumbnails-vertical .fr-thumbnails-side-next {
    margin-bottom: 20px
}

.fr-thumbnails-side-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -14px;
    margin-left: -14px;
    width: 28px;
    height: 28px;
    cursor: pointer
}

.fr-thumbnails-side-button-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    filter: alpha(opacity=80);
    opacity: .8;
    transition: background-color .2s ease-in;
    background-color: #333;
    cursor: pointer;
    border-radius: 4px
}

.fr-thumbnails-side-button:hover .fr-thumbnails-side-button-background {
    background-color: #3b3b3b
}

.fr-thumbnails-side-button-disabled * {
    cursor: default
}

.fr-thumbnails-side-button-disabled:hover .fr-thumbnails-side-button-background {
    background-color: #333
}

.fr-thumbnails-side-button-icon {
    height: 42px;
    width: 42px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.fr-thumbnails-vertical .fr-thumbnails-side,.fr-thumbnails-vertical .fr-thumbnails-thumbs,.fr-thumbnails-vertical .fr-thumbnail-frame {
    clear: both
}

.fr-window-ui-fullclick .fr-thumbnails {
    background: #090909
}

.fr-window-ui-fullclick.fr-thumbnails-enabled.fr-thumbnails-horizontal .fr-info .fr-stroke-bottom {
    display: block!important
}

.fr-window-ui-fullclick.fr-thumbnails-enabled.fr-thumbnails-vertical .fr-info .fr-stroke-left {
    display: block!important
}

.fr-thumbnails-horizontal .fr-thumbnails-thumbs {
    padding: 12px 5px
}

.fr-thumbnails-vertical .fr-thumbnails-thumbs {
    padding: 5px 12px
}

.fr-thumbnails-measured .fr-thumbnails-thumbs {
    padding: 0!important
}

@media all and (min-height: 700px) {
    .fr-thumbnails-horizontal .fr-thumbnails-thumbs {
        padding:16px 8px
    }

    .fr-thumbnails-horizontal .fr-thumbnails-side {
        margin: 0 8px
    }

    .fr-thumbnails-horizontal .fr-thumbnails-side-previous {
        margin-left: 16px
    }

    .fr-thumbnails-horizontal .fr-thumbnails-side-next {
        margin-right: 16px
    }
}

@media all and (min-height: 980px) {
    .fr-thumbnails-horizontal .fr-thumbnails-thumbs {
        padding:20px 10px
    }

    .fr-thumbnails-horizontal .fr-thumbnails-side {
        margin: 0 10px
    }

    .fr-thumbnails-horizontal .fr-thumbnails-side-previous {
        margin-left: 20px
    }

    .fr-thumbnails-horizontal .fr-thumbnails-side-next {
        margin-right: 20px
    }
}

@media all and (min-width: 1200px) {
    .fr-thumbnails-vertical .fr-thumbnails-thumbs {
        padding:8px 16px
    }

    .fr-thumbnails-vertical .fr-thumbnails-side {
        margin: 0 8px
    }

    .fr-thumbnails-vertical .fr-thumbnails-side-previous {
        margin-top: 16px
    }

    .fr-thumbnails-vertical .fr-thumbnails-side-next {
        margin-bottom: 16px
    }
}

@media all and (min-width: 1800px) {
    .fr-thumbnails-vertical .fr-thumbnails-thumbs {
        padding:10px 20px
    }

    .fr-thumbnails-vertical .fr-thumbnails-side {
        margin: 10px 0
    }

    .fr-thumbnails-vertical .fr-thumbnails-side-previous {
        margin-top: 20px
    }

    .fr-thumbnails-vertical .fr-thumbnails-side-next {
        margin-bottom: 20px
    }
}

@media all and (max-width: 500px) and (orientation:portrait),all and (orientation:landscape) and (max-height:500px) {
    .fr-thumbnails-horizontal .fr-thumbnails {
        display:none!important
    }
}

@media all and (max-width: 700px) and (orientation:portrait),all and (orientation:landscape) and (max-height:414px) {
    .fr-thumbnails-vertical .fr-thumbnails {
        display:none!important
    }
}

@media all and (max-width: 500px) and (orientation:portrait),all and (orientation:landscape) and (max-height:414px) {
    .fr-page {
        min-width:100%;
        min-height: 100%
    }
}

.fr-window-ui-fullclick .fr-side-next-outside,.fr-window-ui-fullclick .fr-side-previous-outside,.fr-window-ui-fullclick .fr-close-outside,.fr-window-ui-fullclick .fr-content .fr-side-next,.fr-window-ui-fullclick .fr-content .fr-side-previous,.fr-window-ui-fullclick .fr-content .fr-close,.fr-window-ui-fullclick .fr-content .fr-info,.fr-window-ui-outside .fr-side-next-fullclick,.fr-window-ui-outside .fr-side-previous-fullclick,.fr-window-ui-outside .fr-close-fullclick,.fr-window-ui-outside .fr-content .fr-side-next,.fr-window-ui-outside .fr-content .fr-side-previous,.fr-window-ui-outside .fr-content .fr-close,.fr-window-ui-outside .fr-content .fr-info,.fr-window-ui-inside .fr-page>.fr-info,.fr-window-ui-inside .fr-side-next-fullclick,.fr-window-ui-inside .fr-side-previous-fullclick,.fr-window-ui-inside .fr-close-fullclick,.fr-window-ui-inside .fr-side-next-outside,.fr-window-ui-inside .fr-side-previous-outside,.fr-window-ui-inside .fr-close-outside {
    display: none!important
}

.fr-toggle-ui {
    opacity: 0;
    transition: opacity .3s
}

.fr-visible-fullclick-ui .fr-box>.fr-toggle-ui,.fr-visible-inside-ui .fr-ui-inside .fr-toggle-ui {
    opacity: 1
}

.fr-hidden-fullclick-ui .fr-box>.fr-toggle-ui,.fr-hidden-inside-ui .fr-ui-inside .fr-toggle-ui {
    transition: opacity .3s
}

.fr-ltIE9.fr-hidden-fullclick-ui .fr-box>.fr-toggle-ui,.fr-ltIE9.fr-hidden-inside-ui .fr-ui-inside .fr-toggle-ui {
    display: none
}

.fr-spinner {
    position: fixed;
    width: 52px;
    height: 52px;
    background: #101010;
    background: rgba(16,16,16,0.85);
    border-radius: 5px
}

.fr-spinner div {
    position: absolute;
    top: 0;
    left: 0;
    height: 64%;
    width: 64%;
    margin-left: 18%;
    margin-top: 18%;
    opacity: 1;
    -webkit-animation: fresco-12 1.2s infinite ease-in-out;
    animation: fresco-12 1.2s infinite ease-in-out
}

.fr-spinner div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 8px;
    margin-left: -1px;
    background: #fff;
    box-shadow: 0 0 1px transparent
}

.fr-spinner div.fr-spin-1 {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s
}

.fr-spinner div.fr-spin-2 {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation-delay: -1s;
    animation-delay: -1s
}

.fr-spinner div.fr-spin-3 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation-delay: -.9s;
    animation-delay: -.9s
}

.fr-spinner div.fr-spin-4 {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s
}

.fr-spinner div.fr-spin-5 {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
    -webkit-animation-delay: -.7s;
    animation-delay: -.7s
}

.fr-spinner div.fr-spin-6 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-delay: -.6s;
    animation-delay: -.6s;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-delay: -.6s;
    animation-delay: -.6s
}

.fr-spinner div.fr-spin-7 {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
    -webkit-animation-delay: -.5s;
    animation-delay: -.5s
}

.fr-spinner div.fr-spin-8 {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
    -webkit-animation-delay: -.4s;
    animation-delay: -.4s
}

.fr-spinner div.fr-spin-9 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-animation-delay: -.3s;
    animation-delay: -.3s
}

.fr-spinner div.fr-spin-10 {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
    -webkit-animation-delay: -.2s;
    animation-delay: -.2s
}

.fr-spinner div.fr-spin-11 {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
    -webkit-animation-delay: -.1s;
    animation-delay: -.1s
}

.fr-spinner div.fr-spin-12 {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-animation-delay: 0;
    animation-delay: 0
}

@-webkit-keyframes fresco-12 {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes fresco-12 {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.fr-thumbnail-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    width: 40px;
    height: 40px
}

.fr-thumbnail-spinner-spin {
    position: relative;
    float: left;
    margin: 8px 0 0 8px;
    text-indent: -9999em;
    border-top: 2px solid rgba(255,255,255,0.2);
    border-right: 2px solid rgba(255,255,255,0.2);
    border-bottom: 2px solid rgba(255,255,255,0.2);
    border-left: 2px solid #fff;
    -webkit-animation: fr-thumbnail-spin 1.1s infinite linear;
    animation: fr-thumbnail-spin 1.1s infinite linear
}

.fr-thumbnail-spinner-spin,.fr-thumbnail-spinner-spin:after {
    border-radius: 50%;
    width: 24px;
    height: 24px
}

@-webkit-keyframes fr-thumbnail-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes fr-thumbnail-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.fr-error {
    float: left;
    position: relative;
    background-color: #ca3434;
    width: 160px;
    height: 160px
}

.fr-error-icon {
    position: absolute;
    width: 42px;
    height: 42px;
    top: 50%;
    left: 50%;
    margin-left: -21px;
    margin-top: -21px
}

.fr-window-skin-fresco .fr-side-button-icon,.fr-window-skin-fresco .fr-close-icon,.fr-window-skin-fresco .fr-thumbnails-side-button-icon,.fr-window-skin-fresco .fr-error-icon {
    /* background-image: url(../themes/responsiv-clean/assets/vendors/fresco/fresco-skins/sprite.svg) */
}

.fr-window-skin-fresco.fr-no-svg .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-close-icon,.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-button-icon,.fr-window-skin-fresco .fr-error-icon {
    /* background-image: url(../themes/responsiv-clean/assets/vendors/fresco/fresco-skins/sprite.png) */
}

.fr-window-skin-fresco .fr-error-icon {
    background-position: -160px -126px
}

.fr-window-skin-fresco .fr-content-background {
    background: #101010;
    box-shadow: 0 0 5px rgba(0,0,0,0.4)
}

.fr-window-skin-fresco.fr-window-ui-fullclick .fr-content-background {
    box-shadow: none
}

.fr-window-skin-fresco .fr-thumbnail-wrapper {
    box-shadow: 0 0 3px rgba(0,0,0,0.3)
}

.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-wrapper {
    box-shadow: 0 0 1px rgba(0,0,0,0.1)
}

.fr-window-skin-fresco .fr-side-button-background {
    background-color: transparent
}

.fr-window-skin-fresco .fr-side-previous .fr-side-button-icon {
    background-position: -13px -14px
}

.fr-window-skin-fresco .fr-side-next .fr-side-button-icon {
    background-position: -93px -14px
}

.fr-window-skin-fresco .fr-side-previous:hover .fr-side-button-icon {
    background-position: -13px -114px
}

.fr-window-skin-fresco .fr-side-next:hover .fr-side-button-icon {
    background-position: -93px -114px
}

.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-previous .fr-side-button-icon {
    background-position: -13px -114px
}

.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-next .fr-side-button-icon {
    background-position: -93px -114px
}

.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous.fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled:hover .fr-side-button-icon {
    background-position: -13px -214px
}

.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next.fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled:hover .fr-side-button-icon {
    background-position: -93px -214px
}

.fr-window-skin-fresco.fr-svg .fr-side-previous .fr-side-button-icon {
    background-position: -13px -114px
}

.fr-window-skin-fresco.fr-svg .fr-side-next .fr-side-button-icon {
    background-position: -93px -114px
}

.fr-window-skin-fresco.fr-svg .fr-side-button-icon {
    opacity: .5
}

.fr-window-skin-fresco.fr-svg .fr-side:hover .fr-side-button-icon,.fr-window-skin-fresco.fr-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,.fr-window-skin-fresco.fr-svg .fr-hovering-next .fr-side-next .fr-side-button-icon {
    opacity: 1
}

.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-side .fr-side-button-icon {
    opacity: .8
}

.fr-window-skin-fresco.fr-svg .fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-svg .fr-hovering-previous .fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-svg .fr-hovering-next .fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-svg .fr-side-disabled:hover .fr-side-button-icon,.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-side-disabled .fr-side-button-icon {
    opacity: .2
}

.fr-window-skin-fresco.fr-window-ui-inside .fr-type-image .fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-window-ui-fullclick.fr-showing-type-image .fr-side-disabled .fr-side-button-icon {
    background-image: none
}

@media all and (max-width: 500px) and (orientation:portrait),all and (orientation:landscape) and (max-height:414px) {
    .fr-window-skin-fresco .fr-side-previous .fr-side-button-icon {
        background-position:0 -300px
    }

    .fr-window-skin-fresco .fr-side-next .fr-side-button-icon {
        background-position: -48px -300px
    }

    .fr-window-skin-fresco .fr-side-previous:hover .fr-side-button-icon {
        background-position: 0 -360px
    }

    .fr-window-skin-fresco .fr-side-next:hover .fr-side-button-icon {
        background-position: -48px -360px
    }

    .fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-previous .fr-side-button-icon {
        background-position: 0 -360px
    }

    .fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-next .fr-side-button-icon {
        background-position: -48px -360px
    }

    .fr-window-skin-fresco.fr-svg .fr-side-previous .fr-side-button-icon {
        background-position: 0 -360px
    }

    .fr-window-skin-fresco.fr-svg .fr-side-next .fr-side-button-icon {
        background-position: -48px -360px
    }

    .fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous.fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled:hover .fr-side-button-icon {
        background-position: 0 -420px
    }

    .fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next.fr-side-disabled .fr-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled:hover .fr-side-button-icon {
        background-position: -48px -420px
    }
}

.fr-window-skin-fresco.fr-window-ui-outside .fr-close-background {
    background-color: #363636
}

.fr-window-skin-fresco.fr-window-ui-outside .fr-close:hover .fr-close-background {
    background-color: #434343
}

.fr-window-skin-fresco.fr-window-ui-inside .fr-close-background,.fr-window-skin-fresco.fr-window-ui-fullclick .fr-close-background {
    background-color: #131313;
    filter: alpha(opacity=80);
    opacity: .8
}

.fr-window-skin-fresco.fr-window-ui-inside .fr-close:hover .fr-close-background,.fr-window-skin-fresco.fr-window-ui-fullclick .fr-close:hover .fr-close-background {
    background-color: #191919
}

.fr-window-skin-fresco .fr-close .fr-close-icon {
    background-position: -168px -8px
}

.fr-window-skin-fresco .fr-close:hover .fr-close-icon {
    background-position: -210px -8px
}

.fr-window-skin-fresco.fr-svg .fr-close .fr-close-icon {
    background-position: -210px -8px;
    opacity: .8
}

.fr-window-skin-fresco .fr-close:hover .fr-close-icon {
    opacity: 1
}

.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-close .fr-close-icon,.fr-window-skin-fresco.fr-mobile-touch .fr-close:hover .fr-close-icon {
    opacity: 1
}

.fr-window-skin-fresco .fr-thumbnail-wrapper {
    border-color: transparent;
    border-style: solid;
    border-width: 0;
    box-shadow: 0 0 3px rgba(0,0,0,0.3)
}

.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-wrapper {
    box-shadow: 0 0 1px rgba(0,0,0,0.1)
}

.fr-window-skin-fresco .fr-thumbnail-wrapper {
    box-shadow: 0 -1px 4px rgba(0,0,0,0.3)
}

.fr-window-skin-fresco .fr-thumbnail-overlay-border {
    border-width: 1px;
    border-color: rgba(255,255,255,0.08)
}

.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-overlay-border,.fr-window-skin-fresco .fr-thumbnail-active:hover .fr-thumbnail-overlay-border {
    border: 0
}

.fr-window-skin-fresco .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon {
    background-position: -167px -49px
}

.fr-window-skin-fresco .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-icon {
    background-position: -209px -49px
}

.fr-window-skin-fresco .fr-thumbnails-side-next .fr-thumbnails-side-button-icon {
    background-position: -167px -91px
}

.fr-window-skin-fresco .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-icon {
    background-position: -209px -91px
}

.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon {
    background-position: -293px -49px
}

.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-icon {
    background-position: -335px -49px
}

.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-icon {
    background-position: -293px -91px
}

.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-icon {
    background-position: -335px -91px
}

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side .fr-thumbnails-side-button-icon {
    transition: opacity .2s ease-in;
    opacity: .8
}

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon,.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled {
    background-position: -167px -49px
}

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-icon,.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled {
    background-position: -209px -91px
}

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-icon {
    opacity: 1
}

.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon,.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled {
    background-position: -293px -49px
}

.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-icon,.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled {
    background-position: -335px -91px
}

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side .fr-thumbnails-side-button-disabled,.fr-window-skin-fresco.fr-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-disabled {
    opacity: .5
}

.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
    background-position: -251px -49px
}

.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
    background-position: -251px -91px
}

.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-background,.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-background {
    filter: alpha(opacity=50)
}

.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
    background-position: -377px -49px
}

.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
    background-position: -377px -91px
}

.lity {
    z-index: 9990;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    background: #0b0b0b;
    background: rgba(0,0,0,0.9);
    outline: none!important;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.lity.lity-opened {
    opacity: 1
}

.lity.lity-closed {
    opacity: 0
}

.lity * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.lity-wrap {
    z-index: 9990;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    outline: none!important
}

.lity-wrap:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -.25em
}

.lity-loader {
    z-index: 9991;
    color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -.8em;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Arial,Helvetica,sans-serif;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.lity-loading .lity-loader {
    opacity: 1
}

.lity-container {
    z-index: 9992;
    position: relative;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    white-space: normal;
    max-width: 100%;
    max-height: 100%;
    outline: none!important
}

.lity-content {
    z-index: 9993;
    width: 100%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    -o-transition: -o-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease,-webkit-transform .3s ease,-o-transform .3s ease
}

.lity-loading .lity-content,.lity-closed .lity-content {
    -webkit-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8)
}

.lity-content:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.6);
    box-shadow: 0 0 8px rgba(0,0,0,0.6)
}

.lity-close {
    z-index: 9994;
    width: 35px;
    height: 35px;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-appearance: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-family: Arial,Baskerville,monospace;
    line-height: 35px;
    text-shadow: 0 1px 2px rgba(0,0,0,0.6);
    border: 0;
    background: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.lity-close::-moz-focus-inner {
    border: 0;
    padding: 0
}

.lity-close:hover,.lity-close:focus,.lity-close:active,.lity-close:visited {
    text-decoration: none;
    text-align: center;
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-family: Arial,Baskerville,monospace;
    line-height: 35px;
    text-shadow: 0 1px 2px rgba(0,0,0,0.6);
    border: 0;
    background: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.lity-close:active {
    top: 1px
}

.lity-image img {
    max-width: 100%;
    display: block;
    line-height: 0;
    border: 0
}

.lity-iframe .lity-container,.lity-youtube .lity-container,.lity-vimeo .lity-container,.lity-facebookvideo .lity-container,.lity-googlemaps .lity-container {
    width: 100%;
    max-width: 964px
}

.lity-iframe-container {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: auto;
    pointer-events: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-overflow-scrolling: touch
}

.lity-iframe-container iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.6);
    box-shadow: 0 0 8px rgba(0,0,0,0.6);
    background: #000
}

.lity-hide {
    display: none
}

.mb_YTPlayer:focus {
    outline: 0
}

.YTPWrapper {
    display: block;
    -webkit-transform: translateZ(0) translate3d(0,0,0);
    transform: translateZ(0) translate3d(0,0,0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1000;
    perspective: 1000px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.mb_YTPlayer .loading {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    color: #fff;
    background: rgba(0,0,0,0.51);
    text-align: center;
    padding: 2px 4px;
    border-radius: 5px;
    font-family: "Droid Sans",sans-serif;
    -webkit-animation: fade .1s infinite alternate;
    animation: fade .1s infinite alternate
}

.inlinePlayButton,.mb_YTPBar .ytpicon {
    font-family: ytpregular
}

@-webkit-keyframes fade {
    0% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}

@keyframes fade {
    0% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}

.YTPFullscreen {
    display: block!important;
    position: fixed!important;
    width: 100%!important;
    height: 100%!important;
    top: 0!important;
    left: 0!important;
    margin: 0!important;
    border: none!important;
    opacity: 1!important;
    background-color: #000!important;
    padding: 0!important
}

.inlinePlayButton {
    font-size: 45px;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    width: 80px;
    height: 80px;
    line-height: 80px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    opacity: .8;
    -webkit-transition: all .4s;
    transition: all .4s;
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
}

.mb_YTPlayer:hover .inlinePlayButton {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.inlinePlayButton:hover {
    background-color: #000;
    -webkit-transform: scale(1.3)!important;
    transform: scale(1.3)!important
}

.mbYTP_wrapper iframe {
    max-width: 8000px!important
}

.inline_YTPlayer {
    margin-bottom: 20px;
    vertical-align: top;
    position: relative;
    left: 0;
    overflow: hidden;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.7);
    box-shadow: 0 0 5px rgba(0,0,0,0.7);
    background: rgba(0,0,0,0.5)
}

.inline_YTPlayer img {
    border: none!important;
    margin: 0!important;
    padding: 0!important;
    -webkit-transform: none!important;
    transform: none!important
}

.mb_YTPBar,.mb_YTPBar .buttonBar {
    left: 0;
    padding: 5px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.mb_YTPBar .ytpicon {
    font-size: 20px
}

.mb_YTPBar .mb_YTPUrl.ytpicon {
    font-size: 30px
}

.mb_YTPBar {
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
    display: block;
    height: 10px;
    background: #333;
    position: fixed;
    bottom: 0;
    text-align: left;
    z-index: 1000;
    font: 14px/16px sans-serif;
    color: #fff;
    opacity: .1
}

.mb_YTPBar.visible,.mb_YTPBar:hover {
    opacity: 1
}

.mb_YTPBar .buttonBar {
    -webkit-transition: all .5s;
    transition: all .5s;
    background: 0 0;
    font: 12px/14px san-serif;
    position: absolute;
    top: -30px;
    height: 40px
}

.mb_YTPBar:hover .buttonBar {
    background: rgba(0,0,0,0.4)
}

.mb_YTPBar span {
    display: inline-block;
    font: 16px/20px sans-serif;
    position: relative;
    width: 30px;
    height: 25px;
    vertical-align: middle
}

.mb_YTPBar span.mb_YTPTime {
    width: 130px
}

.mb_YTPBar span.mb_OnlyYT,.mb_YTPBar span.mb_YTPUrl {
    position: absolute;
    width: auto;
    display: block;
    top: 6px;
    right: 10px;
    cursor: pointer
}

.mb_YTPBar span.mb_YTPUrl img {
    width: 60px
}

.mb_YTPBar span.mb_OnlyYT {
    left: 300px;
    right: auto
}

.mb_YTPBar span.mb_OnlyYT img {
    width: 25px
}

.mb_YTPBar span.mb_YTPUrl a {
    color: #fff
}

.mb_YTPBar .mb_YTPMuteUnmute,.mb_YTPBar .mb_YTPPlayPause,.mb_YTPlayer .mb_YTPBar .mb_YTPPlayPause img {
    cursor: pointer
}

.mb_YTPBar .mb_YTPProgress {
    height: 10px;
    width: 100%;
    background: #222;
    bottom: 0;
    left: 0
}

.mb_YTPBar .mb_YTPLoaded {
    height: 10px;
    width: 0;
    background: #444;
    left: 0
}

.mb_YTPBar .mb_YTPseekbar {
    height: 10px;
    width: 0;
    background: #bb110e;
    bottom: 0;
    left: 0;
    -webkit-box-shadow: rgba(82,82,82,0.47) 1px 1px 3px;
    box-shadow: rgba(82,82,82,0.47) 1px 1px 3px
}

.mb_YTPBar .YTPOverlay {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: flat;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.mb_YTPBar .simpleSlider {
    position: relative;
    width: 100px;
    height: 10px;
    border: 1px solid #fff;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 10px;
    cursor: pointer!important;
    border-radius: 3px
}

.mb_YTPBar.compact .simpleSlider {
    width: 40px
}

.mb_YTPBar.compact span.mb_OnlyYT {
    left: 260px
}

.mb_YTPBar .simpleSlider.muted {
    opacity: .3
}

.mb_YTPBar .level {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.mb_YTPBar .level.horizontal {
    height: 100%;
    width: 0
}

.mb_YTPBar .level.vertical {
    height: auto;
    width: 100%
}

:root {
    --color-font: #808291;
    --color-primary: #3ed2a7;
    --color-secondary: #ffb09f;
    --color-link: #a7a9b8;
    --color-link-hover: #181b31;
    --color-gradient-start: #4fda91;
    --color-gradient-stop: #34dbc5;
    --color-img-holder: #efefef
}

@font-face {
    font-family: Averta;
    /* src: url(../themes/responsiv-clean/assets/fonts/averta/averta-regular-webfont.eot);
    src: url(../themes/responsiv-clean/assets/fonts/averta/averta-regular-webfont.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-regular-webfont.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/averta/averta-regular-webfont.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/averta/averta-regular-webfont.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-regular-webfont.svg) format("svg"); */
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Averta;
    /* src: url(../themes/responsiv-clean/assets/fonts/averta/averta-regularitalic-webfont.eot);
    src: url(../themes/responsiv-clean/assets/fonts/averta/averta-regularitalic-webfont.eot?#iefix) format("translate3dembedded-opentype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-regularitalic-webfont.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/averta/averta-regularitalic-webfont.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/averta/averta-regularitalic-webfont.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-regularitalic-webfont.svg) format("svg"); */
    font-weight: 400;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: Averta;
    /* src: url(../themes/responsiv-clean/assets/fonts/averta/averta-semibold-webfont.eot);
    src: url(../themes/responsiv-clean/assets/fonts/averta/averta-semibold-webfont.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-semibold-webfont.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/averta/averta-semibold-webfont.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/averta/averta-semibold-webfont.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-semibold-webfont.svg) format("svg"); */
    font-weight: 600;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Averta;
    /* src: url(../themes/responsiv-clean/assets/fonts/averta/averta-semibolditalic-webfont.eot);
    src: url(../themes/responsiv-clean/assets/fonts/averta/averta-semibolditalic-webfont.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-semibolditalic-webfont.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/averta/averta-semibolditalic-webfont.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/averta/averta-semibolditalic-webfont.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-semibolditalic-webfont.svg) format("svg"); */
    font-weight: 600;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: Averta;
    /* src: url(../themes/responsiv-clean/assets/fonts/averta/averta-bold-webfont.eot);
    src: url(../themes/responsiv-clean/assets/fonts/averta/averta-bold-webfont.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-bold-webfont.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/averta/averta-bold-webfont.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/averta/averta-bold-webfont.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-bold-webfont.svg) format("svg"); */
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Averta;
    /* src: url(../themes/responsiv-clean/assets/fonts/averta/averta-bolditalic-webfont.eot);
    src: url(../themes/responsiv-clean/assets/fonts/averta/averta-bolditalic-webfont.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-bolditalic-webfont.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/averta/averta-bolditalic-webfont.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/averta/averta-bolditalic-webfont.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/averta/averta-bolditalic-webfont.svg) format("svg"); */
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: cubix_blog-webfont;
    /* src: url(../themes/responsiv-clean/assets/fonts/blogfonts/cubix_blog-webfont.eot);
    src: url(../themes/responsiv-clean/assets/fonts/blogfonts/cubix_blog-webfont.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/cubix_blog-webfont.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/blogfonts/cubix_blog-webfont.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/blogfonts/cubix_blog-webfont.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/cubix_blog-webfont.svg#untitledttfuploaded_file) format("svg"); */
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Copyright Klim Type Foundry';
    /* src: url(../themes/responsiv-clean/assets/fonts/blogfonts/CopyrightKlimTypeFoundry.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/blogfonts/CopyrightKlimTypeFoundry.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/blogfonts/CopyrightKlimTypeFoundry.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/CopyrightKlimTypeFoundry.svg#CopyrightKlimTypeFoundry) format("svg"); */
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: marat_sansitalic;
    /* src: url(../themes/responsiv-clean/assets/fonts/blogfonts/maratsans-regularitalic-webfont.eot);
    src: url(../themes/responsiv-clean/assets/fonts/blogfonts/maratsans-regularitalic-webfont.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/maratsans-regularitalic-webfont.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/blogfonts/maratsans-regularitalic-webfont.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/blogfonts/maratsans-regularitalic-webfont.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/maratsans-regularitalic-webfont.svg#marat_sansitalic) format("svg"); */
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: italicblog;
    /* src: url(../themes/responsiv-clean/assets/fonts/blogfonts/italicblog.eot);
    src: url(../themes/responsiv-clean/assets/fonts/blogfonts/italicblog.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/italicblog.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/blogfonts/italicblog.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/blogfonts/italicblog.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/italicblog.svg#.) format("svg"); */
    font-weight: 400;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: '75518571d79e7ac0ba77d620976f66e3';
    /* src: url(../themes/responsiv-clean/assets/fonts/blogfonts/75518571d79e7ac0ba77d620976f66e3.eot);
    src: url(../themes/responsiv-clean/assets/fonts/blogfonts/75518571d79e7ac0ba77d620976f66e3.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/75518571d79e7ac0ba77d620976f66e3.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/blogfonts/75518571d79e7ac0ba77d620976f66e3.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/blogfonts/75518571d79e7ac0ba77d620976f66e3.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/75518571d79e7ac0ba77d620976f66e3.svg#.) format("svg"); */
    font-weight: 400;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: '22a106e87605fc3fa6e2bbe7b4074464';
    /* src: url(../themes/responsiv-clean/assets/fonts/blogfonts/22a106e87605fc3fa6e2bbe7b4074464.eot);
    src: url(../themes/responsiv-clean/assets/fonts/blogfonts/22a106e87605fc3fa6e2bbe7b4074464.eot?#iefix) format("embedded-opentype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/22a106e87605fc3fa6e2bbe7b4074464.woff2) format("woff2"),url(../themes/responsiv-clean/assets/fonts/blogfonts/22a106e87605fc3fa6e2bbe7b4074464.woff) format("woff"),url(../themes/responsiv-clean/assets/fonts/blogfonts/22a106e87605fc3fa6e2bbe7b4074464.ttf) format("truetype"),url(../themes/responsiv-clean/assets/fonts/blogfonts/22a106e87605fc3fa6e2bbe7b4074464.svg#.) format("svg"); */
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

.header-module a.phone-number {
    color: #000
}

h1 .lqd-lines:nth-child(2) {
    text-transform: lowercase!important
}

h2 .lqd-lines:nth-child(2) {
    text-transform: lowercase!important
}

h3 .lqd-lines:nth-child(2) {
    text-transform: lowercase!important
}

h4 .lqd-lines:nth-child(2) {
    text-transform: lowercase!important
}

h5 .lqd-lines:nth-child(2) {
    text-transform: lowercase!important
}

h6 .lqd-lines:nth-child(2) {
    text-transform: lowercase!important
}

h1,h2,h3,h4,h5,h6 {
    text-transform: lowercase!important
}

h1 .lqd-lines::first-letter {
    text-transform: uppercase!important
}

h1:nth-child(2) {
    text-transform: lowercase!important
}

h1::first-letter {
    text-transform: capitalize!important
}

h1::first-letter .lqd-lines {
    text-transform: lowercase!important
}

h2 .lqd-lines::first-letter {
    text-transform: uppercase!important
}

h2:nth-child(2) {
    text-transform: lowercase!important
}

h2::first-letter {
    text-transform: capitalize!important
}

h2::first-letter .lqd-lines {
    text-transform: lowercase!important
}

h3 .lqd-lines::first-letter {
    text-transform: uppercase!important
}

h3:nth-child(2) {
    text-transform: lowercase!important
}

h3::first-letter {
    text-transform: capitalize!important
}

h3::first-letter .lqd-lines {
    text-transform: lowercase!important
}

h4 .lqd-lines::first-letter {
    text-transform: uppercase!important
}

h4:nth-child(2) {
    text-transform: lowercase!important
}

h4::first-letter {
    text-transform: capitalize!important
}

h4::first-letter .lqd-lines {
    text-transform: lowercase!important
}

h5 .lqd-lines::first-letter {
    text-transform: uppercase!important
}

h5:nth-child(2) {
    text-transform: lowercase!important
}

h5::first-letter {
    text-transform: capitalize!important
}

h5::first-letter .lqd-lines {
    text-transform: lowercase!important
}

h6 .lqd-lines::first-letter {
    text-transform: uppercase!important
}

h6:nth-child(2) {
    text-transform: lowercase!important
}

h6::first-letter {
    text-transform: capitalize!important
}

h6::first-letter .lqd-lines {
    text-transform: lowercase!important
}

.page-blog-post h1 ::first-letter {
    text-transform: uppercase!important
}

.page-blog-post h1 :nth-child(2) ::first-letter {
    text-transform: lowercase!important
}

.page-blog h1 ::first-letter {
    text-transform: uppercase!important
}

.page-blog h1 :nth-child(2) ::first-letter {
    text-transform: lowercase!important
}

.page-blog h2 ::first-letter {
    text-transform: uppercase!important
}

.page-blog h2 :nth-child(2) ::first-letter {
    text-transform: lowercase!important
}

.page-blog h2 :nth-child(3) ::first-letter {
    text-transform: lowercase!important
}

.page-blog h2 :nth-child(4) ::first-letter {
    text-transform: lowercase!important
}

.page-about-events h2::first-letter {
    text-transform: uppercase!important
}

.page-about-events h2 {
    text-transform: lowercase!important
}

.ouruppers h2,.page-about-news h2,.page-about-news h2::first-letter,.page-insights-post h1,.page-insights-post h1::first-letter {
    text-transform: initial!important
}

.uppers {
    text-transform: uppercase!important
}

.uppers h1 .lqd-lines:nth-child(2) {
    text-transform: initial!important
}

.uppers h1.blog-single-title {
    text-transform: initial!important
}

.uppers .lqd-lines:nth-child(2) ::first-letter {
    text-transform: initial!important
}

.page-about-press-release h2,.post-author-info h6,.uppers-own h1 {
    text-transform: initial!important
}

.post-author .post-author-info h3,.post-author .post-author-info p {
    font-family: Averta,sans-serif!important
}

.post-author-info h3 {
    text-transform: initial!important
}

.flickity-slider h1 ::first-letter {
    text-transform: uppercase!important
}

.flickity-slider h1 :nth-child(2) ::first-letter {
    text-transform: lowercase!important
}

.flickity-slider h2 ::first-letter {
    text-transform: uppercase!important
}

.flickity-slider h2 :nth-child(2) ::first-letter {
    text-transform: lowercase!important
}

.flickity-slider h3 ::first-letter {
    text-transform: uppercase!important
}

.flickity-slider h3 :nth-child(2) ::first-letter {
    text-transform: lowercase!important
}

.flickity-slider h4 ::first-letter {
    text-transform: uppercase!important
}

.flickity-slider h4 :nth-child(2) ::first-letter {
    text-transform: lowercase!important
}

.flickity-slider h5 ::first-letter {
    text-transform: uppercase!important
}

.flickity-slider h5 :nth-child(2) ::first-letter {
    text-transform: lowercase!important
}

.flickity-slider h6 ::first-letter {
    text-transform: uppercase!important
}

.flickity-slider h6 :nth-child(2) ::first-letter {
    text-transform: lowercase!important
}

.tabs-nav a::first-letter {
    text-transform: uppercase!important
}

.tabs-nav a {
    text-transform: lowercase!important
}

p.margin-labelsd {
    margin-bottom: .25em!important
}

ul.removestyled li {
    list-style: none;
    text-indent: 0
}

ul.mb-5.ul-styleons li {
    list-style: disc;
    text-indent: 20px
}

.own-font-sizeds h2 {
    font-size: 35px;
    margin-bottom: 1rem!important
}

.own-font-sizeds h3 {
    font-size: 28px;
    margin-bottom: 1rem!important
}

.own-font-sizeds p {
    margin-bottom: 3rem
}

.own-font-sizeds li {
    text-indent: 18px;
    line-height: 1.7
}

.header-settings li,.header-settings p {
    font-size: 19px!important;
    line-height: 1.7
}

.own-font-sizeds ul {
    text-indent: 0;
    margin-left: 0!important;
    padding-left: 0!important
}

.page-blog-post .own-contents .blog-single-footer ul {
    margin-bottom: 0!important
}

.page-blog-post .own-contents label {
    margin-bottom: 1rem!important;
    font-weight: 700;
    font-family: 'Copyright Klim Type Foundry';
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-size: 30px
}

.page-blog-post .own-contents ul {
    margin-bottom: 3rem!important;
    margin-left: 0!important;
    padding-left: 0;
    text-indent: 15px
}

.page-blog-post .own-contents li {
    padding-bottom: 10px;
    font-size: 1.2em;
    font-weight: 400;
    color: #000;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.page-blog-post .own-contents li:last-child {
    padding-bottom: 0
}

.ourbxdss.build-box-content {
    padding: 50px!important
}

.ourbxdss.build-box-content p {
    padding-bottom: 0!important;
    margin-bottom: 0
}

.row.d-flex.spceoff-row {
    margin-left: -30px
}

.spaceings .ulbox-list {
    padding: 40px
}

.spaceings .ulbox-list img {
    position: absolute;
    right: 0;
    width: 180px;
    top: -10px
}

.spaceings ul.sps-list li {
    font-size: 19px
}

.ulnewspc li {
    margin-bottom: 3em!important
}

ul.font-ssized {
    font-size: 19px;
    margin-left: 0!important;
    text-indent: 0!important
}

.form_div_toggle {
    position: fixed;
    top: 50%;
    right: -240px;
    transform: translateY(-40%);
    width: 240px;
    height: auto;
    padding: 10px 20px 0!important;
    background: #fff;
    box-sizing: border-box;
    transition: .5s;
    box-shadow: 0 8px 15px -3px rgba(0,0,0,.08);
    border: 1px solid #c2d1db;
    border-radius: 10px;
    z-index: 9
}

.form_div_toggle.active {
    right: -3px
}

.toggle {
    position: absolute;
    height: 171px;
    width: 48px;
    text-align: center;
    cursor: pointer;
    left: -48px;
    top: 50%;
    line-height: 68px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transform: translateY(-50%);
    transition: all .2s ease-in-out
}

.toggle.active {
    height: 50px
}

.toggle.active:before {
    content: '\f00d';
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 18px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #061a79;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: 900
}

.form_div_toggle .toggle span {
    width: 114px;
    font-size: 14px
}

.form_div_toggle .toggle.active {
    height: 40px!important
}

.toggle span {
    color: #fff;
    display: block;
    transform: rotate(-90deg);
    width: 150px;
    height: 100%;
    font-size: 18px;
    letter-spacing: 1px;
    z-index: 1;
    font-weight: 600;
    text-transform: uppercase
}

.form_div_toggle .toggle {
    position: absolute;
    height: 140px;
    width: 38px;
    left: -39px
}

.toggle.active span {
    display: none
}

.toggle {
    background: #5174fd!important
}

.toggle.active:before {
    background-color: #5174fd!important
}

.formside_toggle h3 {
    font-size: 18px!important;
    margin-bottom: 15px;
    padding-top: 0;
    margin-top: 11px!important;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: #000;
    font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
}

.div-centers {
    justify-content: center;
    align-items: center
}

.pagination li a {
    font-weight: 400!important
}

.page-blog-post .own-contents h4 {
    font-weight: 700;
    font-size: 28px
}

.page-blog-post .own-contents h5 {
    margin-bottom: 3rem!important;
    font-size: 24px;
    font-weight: bold !important;
    font-weight: 400;
    font-family: 'Copyright Klim Type Foundry';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: rgba(0,0,0,.84) !important
}

.page-blog-post .own-contents h5,.page-blog-post .own-contents h6,.page-blog-post .own-contents p {
    margin-bottom: 1.3em !important
}

.page-blog-post .own-contents p a {
    text-decoration: underline
}

.pagination li a:hover {
    border: 1px solid #4869e8!important
}

.page-blog-post .own-contents .post-author-info h3,.page-blog-post .own-contents .post-author-info h6 {
    margin-bottom: 0!important
}

.pagination>li>a:focus,.pagination>li>a:hover,.pagination>li>span:focus,.pagination>li>span:hover {
    border: 1px solid #4869e8!important
}

.page-blog-post .own-contents p {
    font-size: 1.2em;
    font-weight: 400;
    color: #000;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.page-blog-post .own-contents p em {
    font-size: 21px;
    font-family: '75518571d79e7ac0ba77d620976f66e3';
    font-weight: 400;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-style: initial
}

.panel-affix.affix {
    padding-right: 20px
}

.page-blog-post .own-contents h1 {
    font-family: Averta,sans-serif;
    font-weight: 700;
    text-align: center
}

h1.own-contents {
    font-weight: 400;
    font-family: 'Copyright Klim Type Foundry';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

h3.own-contents {
    font-weight: 400;
    font-family: 'Copyright Klim Type Foundry';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.own-contents p {
    font-size: 21px;
    font-weight: 400;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

label.own-contents,p.own-contents {
    font-size: 21px;
    font-family: cubix_blog-webfont;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.own-contents p em {
    font-size: 21px;
    font-family: '75518571d79e7ac0ba77d620976f66e3';
    font-weight: 400;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-style: initial
}

.own-contents p i {
    font-size: 21px;
    font-family: '75518571d79e7ac0ba77d620976f66e3';
    font-weight: 400;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.own-contents h1 {
    font-weight: 400;
    font-family: 'Copyright Klim Type Foundry';
    color: #000;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.own-contents h2 {
    font-size: 35px;
    font-weight: 700;
    font-family: Averta,sans-serif;
    color: #000;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.own-contents h3 {
    font-weight: 700;
    color: #000;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.own-contents-sub h1,.own-contents-sub h2,.own-contents-sub h3,.own-contents-sub h4,.own-contents-sub h5,.own-contents-sub h6 {
    font-weight: 400;
    font-family: '22a106e87605fc3fa6e2bbe7b4074464'!important;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.own-contents h4 {
    font-weight: 700;
    color: #000;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

body,html {
    max-width: 100vw
}

html {
    font-size: 100%;
    overflow-x: hidden
}

body {
    font-family: Averta,sans-serif;
    font-size: 1em;
    line-height: 1.7;
    font-weight: 400;
    color: var(--color-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden
}

#content,#wrap {
    position: relative
}

#content {
    z-index: 2;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    background-color: #fff
}

#content:after,#content:before {
    content: '';
    display: table
}

#content:after {
    clear: both
}

audio {
    width: 100%
}

body.archive #content,body.blog #content {
    padding-top: 70px;
    padding-bottom: 70px
}

body.blog .main-sidebar {
    margin-top: 0!important
}

a {
    text-decoration: none;
    color: #4869e8;
    -webkit-transition: all .3s;
    transition: all .3s
}

a:hover {
    color: var(--color-link-hover)
}

a:focus,a:hover {
    text-decoration: none;
    cursor: pointer
}

img {
    max-width: 100%;
    height: auto
}

textarea {
    resize: vertical
}

dd {
    margin-left: 1.5em;
    margin-top: .5em;
    margin-bottom: .5em
}

table {
    border: 1px solid #dedede
}

table tr {
    border-bottom: 1px solid #dedede
}

table td,table th {
    padding: .45em 1em
}

table tbody td,table tbody th,table thead th {
    border-right: 1px solid #dedede
}

table.wp-block-table {
    border-color: #dedede
}

table.wp-block-table td,table.wp-block-table th,table.wp-block-table tr {
    border-color: inherit
}

table.wp-block-table td,table.wp-block-table th {
    padding: .45em 1em
}

pre {
    margin-bottom: 1.5em
}

blockquote {
    display: block;
    padding: 0;
    margin-bottom: 2.5em;
    border: 0
}

blockquote.wp-block-quote.is-large {
    padding-left: 0;
    padding-right: 0
}

.wp-block-pullquote__citation,.wp-block-quote__citation,cite {
    display: block;
    position: relative;
    padding-top: 2.0833333333em;
    margin-top: 1em;
    font-size: 12px;
    letter-spacing: .2em;
    text-transform: uppercase;
    font-style: normal
}

.wp-block-pullquote__citation:before,.wp-block-quote__citation:before,cite:before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #a7a9b8
}

.wp-block-quote.is-large .wp-block-pullquote__citation,.wp-block-quote.is-large .wp-block-quote__citation,.wp-block-quote.is-large cite {
    text-align: inherit;
    font-size: 12px
}

.wp-block-quote.aligncenter .wp-block-quote__citation:before,.wp-block-quote.aligncenter cite:before {
    left: 50%;
    margin-left: -15px
}

.wp-block-quote.alignright .wp-block-quote__citation:before,.wp-block-quote.alignright cite:before {
    left: auto;
    right: 0
}

.wp-block-pullquote,.wp-block-quote {
    margin-bottom: 32px
}

.wp-block-pullquote blockquote,.wp-block-quote blockquote {
    margin-bottom: 0
}

.wp-block-pullquote .wp-block-pullquote__citation:before,.wp-block-pullquote cite:before {
    left: 50%;
    margin-left: -15px
}

.wp-block-pullquote.is-style-solid-color .wp-block-pullquote__citation:before,.wp-block-pullquote.is-style-solid-color cite:before {
    left: 0;
    margin-left: 0
}

figcaption {
    margin: 1.3em auto;
    color: #808291;
    font-style: italic
}

.fp-sr-only,.screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    top: 0;
    left: 0;
    width: 1px;
    word-wrap: normal!important
}

.split-inner {
    display: inline-block
}

p {
    margin-bottom: 1.3em
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    font-family: Roboto,sans-serif;
    margin: 1.25em 0 .75em;
    font-weight: 500;
    line-height: 1.2;
    color: #181b31
}

.h1,h1 {
    margin-top: 0;
    font-size: 52px
}

.h2,h2 {
    font-size: 40px
}

.h3,h3 {
    font-size: 32px
}

.h4,h4 {
    font-size: 25px
}

.h5,h5 {
    font-size: 21px
}

.h6,h6 {
    font-size: 18px
}

.font_small,small {
    font-size: 1em
}

.ld-lazyload {
    opacity: .0001;
    -webkit-transition: opacity 1s;
    transition: opacity 1s
}

.ld-lazyload.loaded {
    opacity: 1
}

.lqd-img-holder {
    display: block;
    position: relative;
    background-color: var(--color-img-holder)
}

.lqd-img-holder>img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    image-rendering: auto
}

.lqd-sticky-bg,.lqd-sticky-bg-spacer,.lqd-sticky-bg-wrap {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    background-size: inherit;
    background-position: inherit;
    background-attachment: inherit;
    background-repeat: inherit
}

.lqd-sticky-bg-wrap {
    overflow: hidden
}

[data-shrink-borders=true] .lqd-sticky-bg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh
}

.lqd-css-sticky {
    top: 0
}

.lqd-css-sticky:not(.vc_row) {
    position: -webkit-sticky!important;
    position: sticky!important
}

.lqd-css-sticky-wrap .lqd-css-sticky.vc_row {
    position: -webkit-sticky!important;
    position: sticky!important
}

.lqd-css-sticky-wrap .lqd-css-sticky.vc_row[data-parallax] {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.lqd-css-sticky-wrap,.lqd-css-sticky-wrap-inner {
    width: 100%
}

.lqd-css-sticky-wrap-inner {
    height: 200%;
    top: 0;
    left: 0
}

.lqd-section-borders-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    opacity: 0
}

.lqd-section-borders-wrap.sticky-applied {
    opacity: 1;
    visibility: visible
}

.lqd-section-border-sentinel {
    top: 0;
    left: 0;
    width: 100%;
    min-height: 1px;
    z-index: -1
}

.lqd-section-border {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #fff
}

.lqd-section-border[data-axis=x] {
    width: 4.69vw;
    height: 100%
}

.lqd-section-border[data-axis=y] {
    width: 100%;
    height: 4.69vw
}

.lqd-section-border-top {
    -webkit-transform-origin: center top;
    transform-origin: center top
}

.lqd-section-border-right {
    left: auto;
    right: 0;
    -webkit-transform-origin: right center;
    transform-origin: right center
}

.lqd-section-border-bottom {
    top: auto;
    bottom: 0;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
}

.lqd-section-border-left {
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.scrollmagic-pin-spacer>.wpb_column {
    width: 100%
}

.main-header .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.main-header .no-gutters {
    margin-right: 0;
    margin-left: 0
}

.main-header .no-gutters>.col,.main-header .no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.main-header .col,.main-header .col-auto,.main-header .col-lg,.main-header .col-lg-1,.main-header .col-lg-10,.main-header .col-lg-11,.main-header .col-lg-12,.main-header .col-lg-2,.main-header .col-lg-3,.main-header .col-lg-4,.main-header .col-lg-5,.main-header .col-lg-6,.main-header .col-lg-7,.main-header .col-lg-8,.main-header .col-lg-9,.main-header .col-lg-auto,.main-header .col-xl,.main-header .col-xl-1,.main-header .col-xl-10,.main-header .col-xl-11,.main-header .col-xl-12,.main-header .col-xl-2,.main-header .col-xl-3,.main-header .col-xl-4,.main-header .col-xl-5,.main-header .col-xl-6,.main-header .col-xl-7,.main-header .col-xl-8,.main-header .col-xl-9,.main-header .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.main-header .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}

.main-header .col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none
}

@media (min-width: 1200px) {
    .main-header .container {
        max-width:1170px
    }
}

@media (max-width: 1200px) and (min-width:991px) {
    .main-nav>li>a {
        padding-left:12px!important;
        padding-right: 12px!important;
        font-size: 15px!important
    }
}

@media (min-width: 991px) {
    .main-header .d-lg-none {
        display:none!important
    }

    .main-header .flex-lg-row {
        -ms-flex-direction: row!important;
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        flex-direction: row!important
    }

    .main-header .flex-lg-column {
        -ms-flex-direction: column!important;
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        flex-direction: column!important
    }

    .main-header .flex-lg-row-reverse {
        -ms-flex-direction: row-reverse!important;
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        flex-direction: row-reverse!important
    }

    .main-header .flex-lg-column-reverse {
        -ms-flex-direction: column-reverse!important;
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        flex-direction: column-reverse!important
    }

    .main-header .flex-lg-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .main-header .flex-lg-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .main-header .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .main-header .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .main-header .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .main-header .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .main-header .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .main-header .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .main-header .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .main-header .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .main-header .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .main-header .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .main-header .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .main-header .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .main-header .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .main-header .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .main-header .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .main-header .order-lg-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .main-header .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .main-header .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .main-header .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .main-header .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .main-header .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .main-header .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .main-header .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .main-header .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .main-header .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .main-header .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .main-header .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .main-header .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .main-header .offset-lg-0 {
        margin-left: 0
    }

    .main-header .offset-lg-1 {
        margin-left: 8.333333%
    }

    .main-header .offset-lg-2 {
        margin-left: 16.666667%
    }

    .main-header .offset-lg-3 {
        margin-left: 25%
    }

    .main-header .offset-lg-4 {
        margin-left: 33.333333%
    }

    .main-header .offset-lg-5 {
        margin-left: 41.666667%
    }

    .main-header .offset-lg-6 {
        margin-left: 50%
    }

    .main-header .offset-lg-7 {
        margin-left: 58.333333%
    }

    .main-header .offset-lg-8 {
        margin-left: 66.666667%
    }

    .main-header .offset-lg-9 {
        margin-left: 75%
    }

    .main-header .offset-lg-10 {
        margin-left: 83.333333%
    }

    .main-header .offset-lg-11 {
        margin-left: 91.666667%
    }
}

@media (min-width: 992px) {
    .main-header .d-xl-none {
        display:none!important
    }

    .main-header .flex-xl-row {
        -ms-flex-direction: row!important;
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        flex-direction: row!important
    }

    .main-header .flex-xl-column {
        -ms-flex-direction: column!important;
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        flex-direction: column!important
    }

    .main-header .flex-xl-row-reverse {
        -ms-flex-direction: row-reverse!important;
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        flex-direction: row-reverse!important
    }

    .main-header .flex-xl-column-reverse {
        -ms-flex-direction: column-reverse!important;
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        flex-direction: column-reverse!important
    }

    .main-header .flex-xl-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .main-header .flex-xl-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .main-header .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .main-header .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .main-header .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .main-header .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .main-header .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .main-header .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .main-header .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .main-header .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .main-header .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .main-header .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .main-header .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .main-header .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .main-header .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .main-header .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .main-header .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .main-header .order-xl-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .main-header .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .main-header .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .main-header .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .main-header .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .main-header .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .main-header .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .main-header .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .main-header .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .main-header .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .main-header .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .main-header .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .main-header .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .main-header .offset-xl-0 {
        margin-left: 0
    }

    .main-header .offset-xl-1 {
        margin-left: 8.333333%
    }

    .main-header .offset-xl-2 {
        margin-left: 16.666667%
    }

    .main-header .offset-xl-3 {
        margin-left: 25%
    }

    .main-header .offset-xl-4 {
        margin-left: 33.333333%
    }

    .main-header .offset-xl-5 {
        margin-left: 41.666667%
    }

    .main-header .offset-xl-6 {
        margin-left: 50%
    }

    .main-header .offset-xl-7 {
        margin-left: 58.333333%
    }

    .main-header .offset-xl-8 {
        margin-left: 66.666667%
    }

    .main-header .offset-xl-9 {
        margin-left: 75%
    }

    .main-header .offset-xl-10 {
        margin-left: 83.333333%
    }

    .main-header .offset-xl-11 {
        margin-left: 91.666667%
    }
}

@media (min-width: 991px) {
    .main-header .flex-lg-row-reverse {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .main-header .flex-lg-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .main-header .flex-lg-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .main-header .flex-lg-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .main-header .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }
}

@media (min-width: 992px) {
    .main-header .flex-xl-row-reverse {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .main-header .flex-xl-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .main-header .flex-xl-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .main-header .flex-xl-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .main-header .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }
}

.main-header {
    position: relative;
    z-index: 9999
}

.main-header .wpb_single_image {
    margin-bottom: 0
}

.main-header P:empty {
    display: none
}

.main-header-overlay {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.main-header.megamenu-item-active {
    background-color: #fff
}

.lqd-main-header-default .mainbar {
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.lqd-main-header-default .main-nav>li>a {
    color: #5a5b63
}

.lqd-main-header-default+.content {
    padding-top: 70px
}

.header-module {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative
}

.header-module~.header-module {
    margin-left: 25px
}

.header-module p {
    margin-bottom: 0
}

.header-module .btn+p {
    width: 100%;
    margin-top: .25em;
    text-align: center
}

.text-right .header-module {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.text-left .header-module {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.text-center .header-module {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.module-title {
    font-size: 16px;
    margin: 1.5em 0
}

.nav-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    border: none;
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.nav-trigger .bar,.nav-trigger .bars,.nav-trigger .txt {
    display: inline-block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: inherit;
    flex-flow: inherit;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit
}

.nav-trigger .bars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

.nav-trigger .bar {
    background-color: #000
}

.nav-trigger .bar:after,.nav-trigger .bar:before {
    background-color: inherit
}

.nav-trigger .txt {
    display: inline-block;
    margin-left: 13px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1
}

.nav-trigger.style-1 .bars {
    width: 25px;
    height: 13px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: all .3s .2s cubic-bezier(0.65,.05,.36,1);
    transition: all .3s .2s cubic-bezier(0.65,.05,.36,1)
}

.nav-trigger.style-1 .bar {
    width: 25px;
    height: 1px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    -webkit-transition: all .3s cubic-bezier(0.65,.05,.36,1);
    transition: all .3s cubic-bezier(0.65,.05,.36,1)
}

.nav-trigger.style-1 .bar:first-child,.nav-trigger.style-1 .bar:last-child {
    width: 16px;
    opacity: 0;
    visibility: hidden
}

.nav-trigger.style-1 .bar:first-child {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px)
}

.nav-trigger.style-1 .bar:last-child {
    margin-bottom: 0;
    -webkit-transform: translateY(7px);
    transform: translateY(7px)
}

.nav-trigger.style-1 .bar:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
    -webkit-transition-timing-function: cubic-bezier(0.65,.05,.36,1);
    transition-timing-function: cubic-bezier(0.65,.05,.36,1)
}

.nav-trigger.style-1 .bar:nth-child(2):before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: inherit;
    transition: inherit;
    background-color: inherit;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.nav-trigger.style-mobile .bars {
    width: 20px;
    height: 15px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: all .45s .2s cubic-bezier(0.23,1,.32,1);
    transition: all .45s .2s cubic-bezier(0.23,1,.32,1)
}

.nav-trigger.style-mobile .bar {
    width: 20px;
    height: 2px;
    border-radius: 50em;
    margin-bottom: 4.25px;
    position: relative;
    -webkit-transition: all .45s cubic-bezier(0.23,1,.32,1);
    transition: all .45s cubic-bezier(0.23,1,.32,1)
}

.nav-trigger.style-mobile .bar:first-child,.nav-trigger.style-mobile .bar:last-child {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .45s cubic-bezier(0.23,1,.32,1);
    transition: all .45s cubic-bezier(0.23,1,.32,1)
}

.nav-trigger.style-mobile .bar:first-child {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px)
}

.nav-trigger.style-mobile .bar:last-child {
    margin-bottom: 0;
    -webkit-transform: translateY(3px);
    transform: translateY(3px)
}

.nav-trigger.style-mobile .bar:nth-child(2) {
    width: 22px;
    margin-right: auto;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1)
}

.nav-trigger.style-mobile .bar:nth-child(2):before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    -webkit-transition: inherit;
    transition: inherit;
    background-color: inherit;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.nav-trigger.txt-left {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.nav-trigger.txt-left .txt {
    margin-left: 0;
    margin-right: 13px
}

.nav-trigger.fill-solid .bars {
    width: 42px;
    height: 40px;
    -webkit-transform: none;
    transform: none;
    background-color: rgba(255,255,255,.95)
}

.nav-trigger.rotate-90 {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.nav-trigger.scheme-light {
    color: #fff
}

.nav-trigger.scheme-light .bar {
    background-color: #fff
}

.nav-trigger.scheme-light.fill-solid .bars {
    background-color: rgba(0,0,0,.3)
}

.nav-trigger.collapsed .bars {
    -webkit-transform: none;
    transform: none;
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.nav-trigger.collapsed .bar:first-child,.nav-trigger.collapsed .bar:last-child,.nav-trigger.collapsed .bar:nth-child(2) {
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    transform: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.nav-trigger.collapsed .bar:first-child:after,.nav-trigger.collapsed .bar:first-child:before,.nav-trigger.collapsed .bar:last-child:after,.nav-trigger.collapsed .bar:last-child:before,.nav-trigger.collapsed .bar:nth-child(2):after,.nav-trigger.collapsed .bar:nth-child(2):before {
    -webkit-transform: rotate(0) translate(0,0);
    transform: rotate(0) translate(0,0)
}

.nav-trigger.collapsed.style-mobile .bar:first-child,.nav-trigger.collapsed.style-mobile .bar:last-child {
    -webkit-transition-delay: .15s;
    transition-delay: .15s
}

.nav-trigger.collapsed.style-mobile .bar:nth-child(2) {
    width: 16px
}

.nav-trigger:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.ld-module-trigger {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    cursor: pointer;
    -webkit-transition: color .3s;
    transition: color .3s
}

.ld-module-trigger-icon {
    font-size: 24px
}

.ld-module-trigger-count {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 18px;
    height: 18px;
    margin-left: 5px;
    background-color: var(--color-primary);
    border-radius: 50em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    color: #fff
}

.ld-module-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 20;
    background-color: #fff;
    text-align: left
}

.ld-module-dropdown.left {
    right: auto;
    left: 0
}

.ld-module-cart .ld-module-dropdown {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 85vh
}

.ld-cart-contents {
    width: 400px;
    height: 100%;
    padding: 25px 0 0;
    border: 1px solid rgba(0,0,0,.1);
    color: #000
}

.ld-cart-contents .empty h3 {
    font-size: 22px
}

.ld-cart-contents .empty,.ld-cart-foot,.ld-cart-head,.ld-cart-product {
    padding-left: 35px;
    padding-right: 35px
}

.ld-cart-foot,.ld-cart-head {
    font-size: 12px
}

.ld-cart-head {
    margin-bottom: 20px
}

a.remove.ld-cart-product-remove {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 20px;
    height: 20px;
    border: 1px solid #dedede;
    border-radius: 50em;
    position: absolute;
    top: -10px;
    left: 25px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    color: #000!important;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s;
    transition: all .3s
}

a.remove.ld-cart-product-remove:before {
    content: '';
    display: inline-block;
    width: 150%;
    height: 150%;
    position: absolute;
    top: -25%;
    left: -25%
}

a.remove.ld-cart-product-remove:hover {
    border-color: red;
    background-color: red;
    color: #fff!important
}

.ld-cart-product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    padding-bottom: 17px;
    margin-bottom: 17px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0,0,0,.1);
    font-size: 14px;
    line-height: 1.571em
}

.ld-cart-product img {
    width: 65px;
    border-radius: 3px
}

.ld-cart-product:hover .ld-cart-product-remove {
    opacity: 1;
    visibility: visible
}

.ld-cart-product-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.ld-cart-product-info a {
    display: inherit;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    color: inherit
}

.ld-cart-product-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

figure+.ld-cart-product-details {
    margin-left: 15px
}

.ld-cart-product-details dl.variation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 3px
}

.ld-cart-product-details dl.variation dd {
    margin: 0 0 0 5px
}

.ld-cart-product-details dl.variation dd+dt {
    margin-left: 8px
}

.ld-cart-product-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.ld-cart-foot {
    margin-top: 5px;
    margin-bottom: 20px
}

.ld-cart-foot .btn {
    font-weight: 700
}

.ld-cart-foot .btn,.ld-cart-foot .btn>span {
    width: 100%
}

.ld-cart-foot .btn>span {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.ld-cart-foot .btn-solid {
    background-color: #2A2A2A;
    border: none
}

.ld-cart-foot .btn-solid:hover {
    background-color: var(--color-primary)
}

.ld-cart-foot .btn-solid+.btn {
    margin-left: 0;
    margin-top: 2em
}

.ld-cart-foot .btn-naked {
    color: #2A2A2A
}

.ld-cart-total {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px
}

.ld-cart-total-price {
    font-size: 20px;
    font-weight: 600
}

.ld-cart-message {
    padding: 1em;
    background-color: var(--color-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: .024em;
    color: #fff;
    text-align: center
}

.ld-module-trigger-txt {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ld-module-trigger-txt i {
    margin-left: .35em
}

.ld-dropdown-menu-content {
    width: 210px;
    padding: 20px 30px;
    border: 1px solid rgba(0,0,0,.1)
}

.ld-dropdown-menu-content a {
    display: inline-block;
    position: relative
}

.ld-dropdown-menu-content ul {
    padding: 0;
    margin: 0;
    list-style: none
}

.ld-dropdown-menu-content ul ul {
    margin-left: .3em
}

.ld-dropdown-menu-content li {
    position: relative
}

.ld-dropdown-menu-content li:not(:last-child) a {
    margin-bottom: .625em
}

.ld-dropdown-menu-underlined li a:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background-color: currentColor;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform .45s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .45s cubic-bezier(0.23,1,.32,1);
    transition: transform .45s cubic-bezier(0.23,1,.32,1);
    transition: transform .45s cubic-bezier(0.23,1,.32,1),-webkit-transform .45s cubic-bezier(0.23,1,.32,1)
}

.ld-dropdown-menu-underlined li a:hover:before {
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.ld-module-v-sep {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 1px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    position: relative
}

.ld-module-v-sep .ld-v-sep-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ededed
}

.ld-module-search .ld-module-dropdown {
    right: -15px
}

.ld-module-search .ld-module-dropdown.left {
    right: auto;
    left: -15px
}

.ld-search-form-container {
    background-color: #fff;
    width: 360px;
    padding: 30px;
    border: 1px solid rgba(0,0,0,.1)
}

.ld-search-form {
    position: relative;
    line-height: 1
}

.ld-search-form input {
    width: 100%;
    padding: 10px 20px 10px 30px;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.1);
    font-size: 16px;
    letter-spacing: .025em
}

.ld-search-form input::-webkit-input-placeholder {
    color: #a7a9b8
}

.ld-search-form input::-moz-placeholder {
    color: #a7a9b8
}

.ld-search-form input:-moz-placeholder {
    color: #a7a9b8
}

.ld-search-form input:-ms-input-placeholder {
    color: #a7a9b8
}

.ld-search-form input:focus {
    outline: 0;
    border-color: rgba(0,0,0,.2)
}

.ld-search-form .input-icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #b8beca
}

.ld-module-search-visible-form .ld-search-form-container {
    padding: 0;
    background: 0 0;
    border: none
}

.ld-module-search-visible-form .ld-search-form input {
    background: 0 0
}

.header-module .iconbox {
    margin-bottom: 0;
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
}

.header-module .iconbox h3 {
    margin-bottom: .25em
}

.header-module .iconbox h3:last-child {
    margin-bottom: 0
}

.header-module .iconbox-inline h3,.header-module .iconbox-side .contents {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto
}

.lqd-lang-module img {
    width: 18px
}

.lqd-lang-module img+span {
    margin-left: .6em
}

.navbar-brand {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: auto;
    padding: 30px 0;
    line-height: normal;
    float: none;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: none;
    transition: none
}

.navbar-brand img {
    -webkit-transition: opacity .3s,visibiliy .3s;
    transition: opacity .3s,visibiliy .3s
}

.navbar-brand .logo-sticky {
    display: none
}

.navbar-brand .logo-dark,.navbar-brand .logo-light {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden
}

.navbar-brand:hover .navbar-brand-hover {
    width: 100%
}

.is-stuck .navbar-brand .logo-sticky {
    display: block
}

.is-stuck .navbar-brand .logo-sticky~img {
    display: none
}

.navbar-brand-inner {
    display: inline-block;
    position: relative
}

.navbar-brand-hover {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: width .3s;
    transition: width .3s
}

.navbar-brand-hover img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: none
}

.navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.navbar-header:after,.navbar-header:before {
    content: none
}

.navbar-header .navbar-toggle {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-left: 20px
}

.navbar-header:only-child {
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0
}

.navbar-collapse {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    padding: 0
}

.navbar-collapse .header-module {
    margin-bottom: 20px
}

.navbar-collapse .header-module:last-child {
    margin-bottom: 45px
}

.navbar-nav>li>a {
    line-height: normal
}

.main-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    margin-left: auto;
    margin-right: auto
}

.main-nav>li {
    position: relative
}

.main-nav>li,.main-nav>li>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

.main-nav>li>a {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding-left: 16px;
    padding-right: 16px;
    color: #a7a9b8;
    white-space: nowrap
}

.main-nav>li>a:focus,.main-nav>li>a:hover {
    background-color: transparent
}

.main-nav>li.active>a,.main-nav>li.current-menu-item>a,.main-nav>li>a:hover {
    color: #fff
}

.main-nav .link-txt {
    position: relative
}

.main-nav .link-txt .txt {
    position: relative;
    z-index: 2;
    font-weight: 600
}

.main-nav .link-ext {
    z-index: 0
}

.main-nav.justify-content-lg-end {
    margin-right: 0
}

.main-nav.justify-content-lg-start {
    margin-left: 0
}

.submenu-expander {
    display: none;
    position: absolute;
    top: 50%;
    left: auto;
    z-index: 3;
    font-size: 18px;
    width: 36px;
    height: 36px;
    margin-left: 18px;
    border-radius: 3px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.submenu-expander:hover {
    background-color: rgba(0,0,0,.05)
}

.menu-item-has-children>a .submenu-expander,.page_item_has_children>a .submenu-expander {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.main-nav .children,.nav-item-children {
    min-width: 232px;
    padding: 1.0625em 0;
    border-radius: 5px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 16px 50px rgba(0,0,0,.07);
    box-shadow: 0 16px 50px rgba(0,0,0,.07);
    visibility: hidden;
    font-size: 16px;
    line-height: 1.5em;
    list-style: none;
    text-align: left
}

.main-nav .children>li,.nav-item-children>li {
    position: relative;
    font-size: 1em;
    line-height: normal
}

.main-nav .children>li>a,.nav-item-children>li>a {
    display: block;
    padding: .7em 2.5em;
    width: 100%;
    color: #797b86
}

.main-nav .children>li:hover>a,.nav-item-children>li:hover>a {
    background-color: #e7eaee
}

.main-nav .children>li.active>a,.main-nav .children>li.current-menu-item>a,.main-nav .children>li:hover>a,.nav-item-children>li.active>a,.nav-item-children>li.current-menu-item>a,.nav-item-children>li:hover>a {
    color: #181b31
}

.main-nav .children .children,.main-nav .children .nav-item-children,.nav-item-children .children,.nav-item-children .nav-item-children {
    bottom: auto;
    top: 0;
    left: 100%
}

.main-nav .children .children.to-left,.main-nav .children .nav-item-children.to-left,.nav-item-children .children.to-left,.nav-item-children .nav-item-children.to-left {
    right: 100%
}

.main-nav .children.to-left,.nav-item-children.to-left {
    left: auto;
    right: 0
}

.main-nav .children.to-left .children,.main-nav .children.to-left .nav-item-children,.nav-item-children.to-left .children,.nav-item-children.to-left .nav-item-children {
    left: auto;
    right: 100%
}

.main-nav .position-applied>.children,.position-applied>.nav-item-children {
    display: none;
    visibility: visible
}

.megamenu-heading {
    margin: 0 0 1.1em;
    font-size: 16px;
    letter-spacing: .01em;
    font-weight: 600
}

.megamenu-heading-lg {
    font-size: 22px;
    font-weight: 400
}

.megamenu-inner-row {
    border-top: 1px solid rgba(0,0,0,.07)
}

.megamenu .lqd-custom-menu li {
    font-size: 15px
}

[data-megamenu-bg-scheme=dark] .megamenu-inner-row {
    border-top-color: rgba(255,255,255,.07)
}

[data-megamenu-bg-scheme=dark] .lqd-custom-menu a {
    color: #d0d1d6
}

.mainbar-wrap .row {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.mainbar-wrap .row>div:empty {
    display: none
}

.mainbar-wrap .megamenu-hover-bg {
    display: none
}

.mainbar-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.mainbar-container:after,.mainbar-container:before {
    content: none
}

.mainbar-container .container {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1
}

.mainbar-row {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.mainbar-row:after,.mainbar-row:before {
    content: none
}

.mainbar-row>[class^=col] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: static
}

.mainbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.mainbar .main-nav-trigger,.mainbar .navbar-header {
    position: relative;
    z-index: 10
}

.secondarybar-wrap {
    position: relative;
    z-index: 11
}

.secondarybar-wrap~.secondarybar-wrap {
    z-index: 9
}

.secondarybar-row {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.secondarybar-row:after,.secondarybar-row:before {
    content: none
}

.secondarybar-row>[class^=col] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.secondarybar-row>[class^=col]:empty {
    display: none
}

.secondarybar .header-module {
    padding-top: 10px;
    padding-bottom: 10px
}

@-webkit-keyframes stickySlideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes stickySlideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.is-stuck {
    -webkit-animation: stickySlideDown .65s cubic-bezier(0.23,1,.32,1) both;
    animation: stickySlideDown .65s cubic-bezier(0.23,1,.32,1) both
}

.is-stuck .navbar-brand {
    padding-top: 24px;
    padding-bottom: 24px
}

.is-stuck.mainbar-wrap {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.is-stuck>.megamenu-hover-bg {
    display: none!important
}

.lqd-sticky-sentinel {
    width: 100%;
    min-height: 1px;
    top: 100%;
    left: 0;
    z-index: -1
}

.titlebar>.lqd-sticky-sentinel,.vc_row>.lqd-sticky-sentinel {
    top: calc(100% - 2px)
}

.is-stuck {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99
}

.main-footer .widget-title {
    font-size: 18px;
    margin-bottom: 1.35em
}

.main-footer>.vc_row {
    margin-left: 0;
    margin-right: 0
}

.main-footer .wpb_single_image {
    margin-bottom: 0
}

.lqd-main-footer-default {
    padding-bottom: 30px;
    padding-top: 30px;
    margin-top: 70px;
    background-color: #f2f2f2;
    color: #3b3b3b
}

.titlebar-scroll-link {
    font-size: 30px;
    position: absolute;
    bottom: 2.25em;
    left: auto
}

.text-center .titlebar-scroll-link {
    margin-left: -15px
}

.titlebar-col {
    position: static
}

.titlebar-inner {
    position: relative;
    z-index: 2;
    color: #000
}

.titlebar-inner .breadcrumb,.titlebar-inner h1,.titlebar-inner p {
    position: relative;
    z-index: 1
}

.titlebar-inner a,.titlebar-inner h1,.titlebar-inner p {
    color: inherit
}

.titlebar-inner h1 {
    margin: 0;
    font-size: 60px;
    font-weight: 600
}

.titlebar-inner p {
    font-size: 20px;
    font-weight: 500
}

.titlebar-inner p:last-child {
    margin-bottom: 0
}

.titlebar-inner .titlebar-scroll-link,.titlebar-inner p {
    opacity: .9
}

.titlebar {
    position: relative;
    background-color: inherit;
    background-size: cover
}

.titlebar .breadcrumb {
    margin-top: 1.5em
}

.titlebar .breadcrumb ol {
    background: 0 0;
    border-radius: 0
}

.titlebar .breadcrumb.bordered {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3
}

.titlebar>.breadcrumb {
    margin-top: 0
}

.titlebar .titlebar-scroll-link:hover {
    opacity: 1
}

.titlebar .main-header-overlay {
    position: relative;
    top: auto;
    left: auto
}

.titlebar[data-parallax=true]:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    -webkit-transition: opacity .3s,visibility .3s;
    transition: opacity .3s,visibility .3s
}

.titlebar[data-parallax=true] .liquid-parallax-container {
    opacity: 0
}

.titlebar[data-parallax=true].liquid-parallax-bg:before {
    opacity: 0;
    visibility: hidden
}

.titlebar[data-parallax=true].liquid-parallax-bg .liquid-parallax-container {
    -webkit-animation: fadeIn .3s .1s cubic-bezier(0.39,.575,.565,1) both alternate;
    animation: fadeIn .3s .1s cubic-bezier(0.39,.575,.565,1) both alternate
}

.titlebar[data-parallax=true].bg-none {
    background-image: none!important
}

.titlebar-default .titlebar-inner {
    padding-top: 50px;
    padding-bottom: 50px
}

.titlebar-default .titlebar-inner h1 {
    font-size: 48px
}

.titlebar-sm .titlebar-inner {
    padding-top: 75px;
    padding-bottom: 75px
}

.titlebar-md .titlebar-inner {
    padding-top: 100px;
    padding-bottom: 100px
}

.titlebar.scheme-light {
    background-color: #10131D;
    color: #fff
}

.titlebar.scheme-light .titlebar-inner,.titlebar.scheme-light .titlebar-inner h1,.titlebar.scheme-light .titlebar-inner p {
    color: inherit
}

.contents-container .ld-container {
    width: auto
}

@-webkit-keyframes lqdStackArrowNext {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1
    }

    60% {
        -webkit-transform: translateX(50%);
        transform: translateX(50%)
    }

    61% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1
    }
}

@keyframes lqdStackArrowNext {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1
    }

    60% {
        -webkit-transform: translateX(50%);
        transform: translateX(50%)
    }

    61% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1
    }
}

@-webkit-keyframes lqdStackArrowPrev {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1
    }

    60% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    61% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1
    }
}

@keyframes lqdStackArrowPrev {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1
    }

    60% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    61% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1
    }
}

@-webkit-keyframes lqdStackNumberActive {
    from {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes lqdStackNumberActive {
    from {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes lqdStackNumberPassed {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    to {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}

@keyframes lqdStackNumberPassed {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    to {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}

@-webkit-keyframes lqdStackNumberActiveUp {
    from {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes lqdStackNumberActiveUp {
    from {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes lqdStackNumberPassedUp {
    from {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 1
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

@keyframes lqdStackNumberPassedUp {
    from {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 1
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

@-webkit-keyframes lqdStackArrowDash {
    from {
        stroke-dasharray: 5
    }

    to {
        stroke-dasharray: 40
    }
}

@keyframes lqdStackArrowDash {
    from {
        stroke-dasharray: 5
    }

    to {
        stroke-dasharray: 40
    }
}

@-webkit-keyframes lqdStackRowFadeScaleEnterFromBottom {
    from {
        -webkit-transform: translate3d(0,100vh,0);
        transform: translate3d(0,100vh,0)
    }

    to {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }
}

@keyframes lqdStackRowFadeScaleEnterFromBottom {
    from {
        -webkit-transform: translate3d(0,100vh,0);
        transform: translate3d(0,100vh,0)
    }

    to {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }
}

@-webkit-keyframes lqdStackRowFadeScaleEnter {
    from {
        -webkit-transform: scale3d(0.9,.9,1);
        transform: scale3d(0.9,.9,1);
        opacity: 0
    }

    to {
        -webkit-transform: scale3d(1,1,1);
        transform: scale3d(1,1,1);
        opacity: 1
    }
}

@keyframes lqdStackRowFadeScaleEnter {
    from {
        -webkit-transform: scale3d(0.9,.9,1);
        transform: scale3d(0.9,.9,1);
        opacity: 0
    }

    to {
        -webkit-transform: scale3d(1,1,1);
        transform: scale3d(1,1,1);
        opacity: 1
    }
}

@-webkit-keyframes lqdStackRowFadeScaleLeave {
    from {
        -webkit-transform: scale3d(1,1,1);
        transform: scale3d(1,1,1)
    }

    to {
        -webkit-transform: scale3d(0.9,.9,1);
        transform: scale3d(0.9,.9,1);
        opacity: 0
    }
}

@keyframes lqdStackRowFadeScaleLeave {
    from {
        -webkit-transform: scale3d(1,1,1);
        transform: scale3d(1,1,1)
    }

    to {
        -webkit-transform: scale3d(0.9,.9,1);
        transform: scale3d(0.9,.9,1);
        opacity: 0
    }
}

@-webkit-keyframes lqdStackRowFadeScaleLeaveToBottom {
    from {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        -webkit-transform: translate3d(0,100vh,0);
        transform: translate3d(0,100vh,0)
    }
}

@keyframes lqdStackRowFadeScaleLeaveToBottom {
    from {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        -webkit-transform: translate3d(0,100vh,0);
        transform: translate3d(0,100vh,0)
    }
}

@-webkit-keyframes lqdStackRowSlideOverEnterFromTop {
    from {
        -webkit-transform: translate3d(0,-100vh,0);
        transform: translate3d(0,-100vh,0)
    }

    to {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }
}

@keyframes lqdStackRowSlideOverEnterFromTop {
    from {
        -webkit-transform: translate3d(0,-100vh,0);
        transform: translate3d(0,-100vh,0)
    }

    to {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }
}

@-webkit-keyframes lqdStackRowSlideOverEnterFromBottom {
    from {
        -webkit-transform: translate3d(0,100vh,0);
        transform: translate3d(0,100vh,0)
    }

    to {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }
}

@keyframes lqdStackRowSlideOverEnterFromBottom {
    from {
        -webkit-transform: translate3d(0,100vh,0);
        transform: translate3d(0,100vh,0)
    }

    to {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }
}

@-webkit-keyframes lqdStackRowSlideOverLeaveToTop {
    from {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        -webkit-transform: translate3d(0,-50vh,0);
        transform: translate3d(0,-50vh,0)
    }
}

@keyframes lqdStackRowSlideOverLeaveToTop {
    from {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        -webkit-transform: translate3d(0,-50vh,0);
        transform: translate3d(0,-50vh,0)
    }
}

@-webkit-keyframes lqdStackRowSlideOverLeaveToBottom {
    from {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        -webkit-transform: translate3d(0,50vh,0);
        transform: translate3d(0,50vh,0)
    }
}

@keyframes lqdStackRowSlideOverLeaveToBottom {
    from {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        -webkit-transform: translate3d(0,50vh,0);
        transform: translate3d(0,50vh,0)
    }
}

.lqd-stack-extra,.lqd-stack-page-number,.lqd-stack-prevnext-wrap {
    display: none
}

.pp-section {
    width: 100vw;
    height: 100vh;
    position: absolute!important;
    top: 0;
    left: 0;
    background-color: #fff;
    -webkit-transition: -webkit-transform 1.15s cubic-bezier(0.22,.61,.36,1);
    transition: -webkit-transform 1.15s cubic-bezier(0.22,.61,.36,1);
    transition: transform 1.15s cubic-bezier(0.22,.61,.36,1);
    transition: transform 1.15s cubic-bezier(0.22,.61,.36,1),-webkit-transform 1.15s cubic-bezier(0.22,.61,.36,1)
}

.pp-section:not(.active):not(.lqd-stack-row-entering):not(.lqd-stack-row-leaving) {
    left: -999999px;
    pointer-events: none
}

.pp-section.vc_row-o-content-bottom {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.pp-section.vc_row-o-content-top {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.pp-table {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: start;
    align-content: flex-start
}

.pp-tableCell {
    height: auto!important;
    width: 100%
}

.pp-scrollable {
    overflow-y: auto
}

.pp-auto-height {
    height: auto!important
}

.lqd-stack-section-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.pp-enabled .titlebar {
    display: none!important
}

.pp-enabled [data-liquid-stack=true] {
    height: 100vh
}

.pp-enabled [data-liquid-stack=true]>section {
    width: 100%;
    height: 100vh;
    -ms-flex-line-pack: center;
    align-content: center
}

.pp-enabled [data-liquid-stack=true] .main-footer {
    top: auto;
    bottom: 0;
    -webkit-animation: none!important;
    animation: none!important
}

#pp-nav {
    position: fixed;
    top: 50%;
    right: 50px;
    z-index: 9;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .2em;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

#pp-nav .pp-tooltip {
    display: inline-block;
    margin-right: 15px;
    position: absolute;
    top: 50%;
    right: 100%;
    opacity: 0;
    visibility: hidden;
    white-space: nowrap;
    -webkit-transition-property: visibility,opacity,color,-webkit-transform;
    transition-property: visibility,opacity,color,-webkit-transform;
    transition-property: visibility,opacity,transform,color;
    transition-property: visibility,opacity,transform,color,-webkit-transform;
    -webkit-transition-duration: .45s;
    transition-duration: .45s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

#pp-nav a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 36px;
    height: 1px;
    position: relative;
    background-color: rgba(0,0,0,.3);
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform-origin: right center;
    transform-origin: right center
}

#pp-nav a:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 21px;
    position: absolute;
    top: -10px;
    left: 0
}

#pp-nav a.active {
    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
    background-color: #000
}

#pp-nav a.active+.pp-tooltip {
    -webkit-transform: translate(-18px,-50%);
    transform: translate(-18px,-50%)
}

#pp-nav li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin: 30px 0
}

#pp-nav li:first-child {
    margin-top: 0
}

#pp-nav li:last-child {
    margin-bottom: 0
}

.lqd-stack-has-footer #pp-nav li:nth-last-child(2) {
    margin-bottom: 0
}

#pp-nav li:hover a {
    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
    background-color: #000
}

#pp-nav li:hover .pp-tooltip {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-18px,-50%);
    transform: translate(-18px,-50%)
}

#pp-nav ul {
    padding: 0;
    margin: 0;
    list-style: none
}

.lqd-stack-prevnext-button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    border: none;
    position: fixed;
    top: 50%;
    z-index: 10;
    white-space: nowrap;
    background: 0 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: color .3s;
    transition: color .3s
}

.lqd-stack-prevnext-button:focus {
    outline: 0
}

.lqd-stack-prevnext-button svg {
    stroke: currentColor
}

.lqd-stack-prev-button {
    left: 50px;
    margin-right: 5px
}

.lqd-stack-next-button {
    right: 50px;
    margin-left: 5px
}

.lqd-stack-buttons-style-1 .lqd-stack-prevnext-button {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: .1em
}

.lqd-stack-buttons-style-1 .lqd-stack-prevnext-button svg {
    display: none
}

.lqd-stack-buttons-style-1 .lqd-stack-button-labbel {
    -webkit-transition: -webkit-transform .45s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .45s cubic-bezier(0.23,1,.32,1);
    transition: transform .45s cubic-bezier(0.23,1,.32,1);
    transition: transform .45s cubic-bezier(0.23,1,.32,1),-webkit-transform .45s cubic-bezier(0.23,1,.32,1)
}

.lqd-stack-buttons-style-1 .lqd-stack-button-ext {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.lqd-stack-buttons-style-1 .lqd-stack-button-ext:before {
    display: inline-block;
    font: normal normal 2em liquid-icon
}

.lqd-stack-buttons-style-1 .lqd-stack-prev-button {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.lqd-stack-buttons-style-1 .lqd-stack-prev-button .lqd-stack-button-ext {
    margin-right: 10px
}

.lqd-stack-buttons-style-1 .lqd-stack-prev-button .lqd-stack-button-ext:before {
    content: '\f117'
}

.lqd-stack-buttons-style-1 .lqd-stack-prev-button:hover .lqd-stack-button-labbel {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px)
}

.lqd-stack-buttons-style-1 .lqd-stack-prev-button:hover .lqd-stack-button-ext {
    -webkit-animation: lqdStackArrowPrev .45s cubic-bezier(0.77,0,.175,1) both alternate;
    animation: lqdStackArrowPrev .45s cubic-bezier(0.77,0,.175,1) both alternate
}

.lqd-stack-buttons-style-1 .lqd-stack-next-button .lqd-stack-button-ext {
    margin-left: 10px
}

.lqd-stack-buttons-style-1 .lqd-stack-next-button .lqd-stack-button-ext:before {
    content: '\f119'
}

.lqd-stack-buttons-style-1 .lqd-stack-next-button:hover .lqd-stack-button-labbel {
    -webkit-transform: translateX(2px);
    transform: translateX(2px)
}

.lqd-stack-buttons-style-1 .lqd-stack-next-button:hover .lqd-stack-button-ext {
    -webkit-animation: lqdStackArrowNext .45s cubic-bezier(0.77,0,.175,1) both alternate;
    animation: lqdStackArrowNext .45s cubic-bezier(0.77,0,.175,1) both alternate
}

.lqd-stack-buttons-style-2 .lqd-stack-button-labbel {
    clip: rect(1px,1px,1px,1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    top: 0;
    left: 0;
    width: 1px;
    word-wrap: normal!important
}

.lqd-stack-buttons-style-2 .lqd-stack-button-ext {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px
}

.lqd-stack-buttons-style-2 .lqd-stack-button-circ {
    opacity: .3
}

.lqd-stack-buttons-style-2 .lqd-stack-button-circ-clone {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    stroke-dasharray: 110;
    stroke-dashoffset: 110;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.23,1,.32,1);
    transition: stroke-dashoffset 1s cubic-bezier(0.23,1,.32,1)
}

.lqd-stack-buttons-style-2 .lqd-stack-button-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.lqd-stack-buttons-style-2 .lqd-stack-prevnext-button:hover .lqd-stack-button-arrow {
    -webkit-animation: lqdStackArrowDash 1s ease both alternate;
    animation: lqdStackArrowDash 1s ease both alternate
}

.lqd-stack-buttons-style-2 .lqd-stack-prev-button .lqd-stack-button-arrow {
    -webkit-transform: translate(-50%,-50%) rotate(180deg);
    transform: translate(-50%,-50%) rotate(180deg);
    margin-left: -1px
}

.lqd-stack-buttons-style-2 .lqd-stack-prev-button:hover .lqd-stack-button-circ-clone {
    stroke-dashoffset: 166
}

.lqd-stack-buttons-style-2 .lqd-stack-next-button .lqd-stack-button-arrow {
    margin-left: 1px
}

.lqd-stack-buttons-style-2 .lqd-stack-next-button:hover .lqd-stack-button-circ-clone {
    stroke-dashoffset: 53.5
}

.lqd-stack-page-number {
    position: fixed;
    bottom: 40px;
    left: 50%;
    z-index: 10;
    font-size: 20px;
    font-weight: 700;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition-property: opacity,visibility,color;
    transition-property: opacity,visibility,color;
    -webkit-transition-duration: .45s;
    transition-duration: .45s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1)
}

.lqd-stack-page-number span {
    display: inline-block
}

.lqd-stack-page-number-counter {
    position: relative
}

.lqd-stack-page-number-current {
    overflow: hidden
}

.lqd-stack-page-number-passed {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0
}

.lqd-stack-page-number-total {
    font-size: .55em
}

.lqd-stack-page-number-total:before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 1.75em;
    margin-right: 4px;
    margin-left: 2px;
    background-color: currentColor;
    -webkit-transform: skewX(-18deg);
    transform: skewX(-18deg)
}

.lqd-stack-row-entering {
    z-index: 10!important
}

.lqd-stack-effect-fadeScale.lqd-stack-moving-up .lqd-stack-row-entering {
    -webkit-animation: lqdStackRowFadeScaleEnter 1.2s cubic-bezier(0.23,1,.32,1) both alternate;
    animation: lqdStackRowFadeScaleEnter 1.2s cubic-bezier(0.23,1,.32,1) both alternate;
    z-index: 0!important
}

.lqd-stack-effect-fadeScale.lqd-stack-moving-down .lqd-stack-row-entering {
    -webkit-animation: lqdStackRowFadeScaleEnterFromBottom 1.2s cubic-bezier(0.23,1,.32,1) both alternate;
    animation: lqdStackRowFadeScaleEnterFromBottom 1.2s cubic-bezier(0.23,1,.32,1) both alternate
}

.lqd-stack-effect-slideOver.lqd-stack-moving-up .lqd-stack-row-entering {
    -webkit-animation: lqdStackRowSlideOverEnterFromTop 1.2s cubic-bezier(0.23,1,.32,1) both alternate;
    animation: lqdStackRowSlideOverEnterFromTop 1.2s cubic-bezier(0.23,1,.32,1) both alternate
}

.lqd-stack-effect-slideOver.lqd-stack-moving-down .lqd-stack-row-entering {
    -webkit-animation: lqdStackRowSlideOverEnterFromBottom 1.2s cubic-bezier(0.23,1,.32,1) both alternate;
    animation: lqdStackRowSlideOverEnterFromBottom 1.2s cubic-bezier(0.23,1,.32,1) both alternate
}

.lqd-stack-effect-fadeScale.lqd-stack-moving-up .lqd-stack-row-leaving {
    -webkit-animation: lqdStackRowFadeScaleLeaveToBottom 1.2s cubic-bezier(0.23,1,.32,1) both alternate;
    animation: lqdStackRowFadeScaleLeaveToBottom 1.2s cubic-bezier(0.23,1,.32,1) both alternate
}

.lqd-stack-effect-fadeScale.lqd-stack-moving-down .lqd-stack-row-leaving {
    -webkit-animation: lqdStackRowFadeScaleLeave 1.2s cubic-bezier(0.23,1,.32,1) both alternate;
    animation: lqdStackRowFadeScaleLeave 1.2s cubic-bezier(0.23,1,.32,1) both alternate
}

.lqd-stack-effect-slideOver.lqd-stack-moving-up .lqd-stack-row-leaving {
    -webkit-animation: lqdStackRowSlideOverLeaveToBottom 1.2s cubic-bezier(0.23,1,.32,1) both alternate;
    animation: lqdStackRowSlideOverLeaveToBottom 1.2s cubic-bezier(0.23,1,.32,1) both alternate
}

.lqd-stack-effect-slideOver.lqd-stack-moving-down .lqd-stack-row-leaving {
    -webkit-animation: lqdStackRowSlideOverLeaveToTop 1.2s cubic-bezier(0.23,1,.32,1) both alternate;
    animation: lqdStackRowSlideOverLeaveToTop 1.2s cubic-bezier(0.23,1,.32,1) both alternate
}

.lqd-stack-extra {
    display: block
}

.lqd-stack-extra-content {
    position: fixed;
    bottom: 40px;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    -webkit-transition-property: opacity,visibility,color;
    transition-property: opacity,visibility,color;
    -webkit-transition-duration: .65s;
    transition-duration: .65s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1)
}

.lqd-stack-extra-content>* {
    margin: 0;
    color: inherit
}

.lqd-stack-extra-content a {
    color: inherit
}

.pp-enabled .lqd-stack-extra-content {
    opacity: 1;
    visibility: visible
}

.lqd-stack-extra-content .social-icon a {
    -webkit-transition-property: opacity;
    transition-property: opacity
}

.lqd-stack-extra-content .social-icon a:hover {
    opacity: .75
}

.lqd-stack-extra-content-bottom-left {
    left: 50px
}

.lqd-stack-extra-content-bottom-right {
    right: 50px
}

.lqd-stack-initiated .main-header:not(.header-side) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.lqd-stack-initiated.admin-bar .main-header {
    top: 32px
}

.lqd-stack-initiated .lqd-stack-page-number,.lqd-stack-initiated .lqd-stack-prevnext-wrap {
    display: block
}

.lqd-stack-has-nav .lqd-stack-prevnext-button {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    -webkit-transform: none;
    transform: none
}

.lqd-stack-has-nav .lqd-stack-prevnext-wrap {
    position: absolute;
    bottom: 40px;
    right: 50px;
    z-index: 10
}

.lqd-stack-has-nav.lqd-stack-has-content-bottom-right .lqd-stack-prevnext-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    bottom: auto;
    top: 50%;
    right: auto;
    left: 50px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.lqd-stack-has-nav.lqd-stack-has-content-bottom-right .lqd-stack-prevnext-button {
    margin: 0
}

.lqd-stack-has-nav.lqd-stack-has-content-bottom-right .lqd-stack-prev-button {
    margin-bottom: 5px
}

.lqd-stack-has-nav.lqd-stack-has-content-bottom-right .lqd-stack-next-button {
    margin-top: 5px
}

.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-1 .lqd-stack-prevnext-button {
    margin: 0;
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-1 .lqd-stack-prev-button {
    -webkit-transform: rotate(90deg) translateX(-50%);
    transform: rotate(90deg) translateX(-50%)
}

.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-1 .lqd-stack-prev-button .lqd-stack-button-labbel {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-1 .lqd-stack-next-button {
    -webkit-transform: rotate(90deg) translateX(50%);
    transform: rotate(90deg) translateX(50%)
}

.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-1 .lqd-stack-next-button .lqd-stack-button-labbel {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-2 .lqd-stack-next-button,.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-2 .lqd-stack-prev-button {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.lqd-stack-moving #pp-nav a,.lqd-stack-moving .lqd-stack-prevnext-button {
    pointer-events: none
}

.lqd-stack-moving .lqd-stack-page-number-current {
    -webkit-animation: lqdStackNumberActive .45s cubic-bezier(0.39,.575,.565,1) both alternate;
    animation: lqdStackNumberActive .45s cubic-bezier(0.39,.575,.565,1) both alternate
}

.lqd-stack-moving .lqd-stack-page-number-passed {
    -webkit-animation: lqdStackNumberPassed .45s cubic-bezier(0.39,.575,.565,1) both alternate;
    animation: lqdStackNumberPassed .45s cubic-bezier(0.39,.575,.565,1) both alternate
}

.lqd-stack-moving.lqd-stack-moving-up .lqd-stack-page-number-current {
    -webkit-animation-name: lqdStackNumberActiveUp;
    animation-name: lqdStackNumberActiveUp
}

.lqd-stack-moving.lqd-stack-moving-up .lqd-stack-page-number-passed {
    -webkit-animation-name: lqdStackNumberPassedUp;
    animation-name: lqdStackNumberPassedUp
}

.lqd-stack-active-row-dark #pp-nav a {
    color: inherit;
    background-color: rgba(255,255,255,.3)
}

.lqd-stack-active-row-dark #pp-nav a.active,.lqd-stack-active-row-dark #pp-nav li:hover a {
    background-color: #fff
}

.lqd-stack-active-row-dark #pp-nav li,.lqd-stack-active-row-dark .lqd-stack-extra-content,.lqd-stack-active-row-dark .lqd-stack-page-number,.lqd-stack-active-row-dark .lqd-stack-prevnext-button {
    color: #fff
}

.lqd-stack-moving-up #pp-nav a,.lqd-stack-moving-up #pp-nav li {
    -webkit-transition-delay: .15s;
    transition-delay: .15s
}

.lqd-stack-moving-up .lqd-stack-extra-content,.lqd-stack-moving-up .lqd-stack-page-number,.lqd-stack-moving-up .lqd-stack-prevnext-button {
    -webkit-transition-delay: .35s;
    transition-delay: .35s
}

.lqd-stack-moving-up.lqd-stack-has-content-bottom-right .lqd-stack-prevnext-button {
    -webkit-transition-delay: .15s;
    transition-delay: .15s
}

.lqd-stack-footer-active .lqd-stack-extra-content,.lqd-stack-footer-active .lqd-stack-page-number {
    opacity: 0;
    visibility: hidden
}

.lqd-stack-footer-active section.vc_row:last-of-type {
    left: auto!important;
    visibility: visible!important;
    pointer-events: auto!important
}

.site-boxed-layout {
    background-color: #eee
}

.site-boxed-layout.site-boxed-layout-shadow-1 #wrap {
    -webkit-box-shadow: 0 0 13px rgba(0,0,0,.1);
    box-shadow: 0 0 13px rgba(0,0,0,.1)
}

.site-boxed-layout.site-boxed-layout-shadow-2 #wrap {
    -webkit-box-shadow: 0 0 25px rgba(0,0,0,.15);
    box-shadow: 0 0 25px rgba(0,0,0,.15)
}

.site-boxed-layout.site-boxed-layout-shadow-3 #wrap {
    -webkit-box-shadow: 0 0 50px rgba(0,0,0,.2);
    box-shadow: 0 0 50px rgba(0,0,0,.2)
}

@-webkit-keyframes btnIconRipple {
    0% {
        border-width: 4px;
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    80% {
        border-width: 1px;
        -webkit-transform: scale(1.35);
        transform: scale(1.35)
    }

    100% {
        opacity: 0
    }
}

@keyframes btnIconRipple {
    0% {
        border-width: 4px;
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    80% {
        border-width: 1px;
        -webkit-transform: scale(1.35);
        transform: scale(1.35)
    }

    100% {
        opacity: 0
    }
}

@-webkit-keyframes btnDotAnimations {
    from {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes btnDotAnimations {
    from {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes btnSplit {
    0%,100% {
        -webkit-transform-origin: left;
        transform-origin: left;
        -webkit-transform: translateX(100%) scaleX(1);
        transform: translateX(100%) scaleX(1)
    }

    50% {
        -webkit-transform: translateX(0%) scaleX(2);
        transform: translateX(0%) scaleX(2)
    }

    100% {
        -webkit-transform: translateX(0%) scaleX(1);
        transform: translateX(0%) scaleX(1)
    }
}

@keyframes btnSplit {
    0%,100% {
        -webkit-transform-origin: left;
        transform-origin: left;
        -webkit-transform: translateX(100%) scaleX(1);
        transform: translateX(100%) scaleX(1)
    }

    50% {
        -webkit-transform: translateX(0%) scaleX(2);
        transform: translateX(0%) scaleX(2)
    }

    100% {
        -webkit-transform: translateX(0%) scaleX(1);
        transform: translateX(0%) scaleX(1)
    }
}

@-webkit-keyframes btnSplitHover {
    0%,100% {
        -webkit-transform-origin: left;
        transform-origin: left;
        -webkit-transform: translateX(0%) scaleX(1);
        transform: translateX(0%) scaleX(1)
    }

    50% {
        -webkit-transform: translateX(0%) scaleX(2);
        transform: translateX(0%) scaleX(2)
    }

    100% {
        -webkit-transform: translateX(100%) scaleX(1);
        transform: translateX(100%) scaleX(1)
    }
}

@keyframes btnSplitHover {
    0%,100% {
        -webkit-transform-origin: left;
        transform-origin: left;
        -webkit-transform: translateX(0%) scaleX(1);
        transform: translateX(0%) scaleX(1)
    }

    50% {
        -webkit-transform: translateX(0%) scaleX(2);
        transform: translateX(0%) scaleX(2)
    }

    100% {
        -webkit-transform: translateX(100%) scaleX(1);
        transform: translateX(100%) scaleX(1)
    }
}

.btn {
    text-align: center;
    display: inline-block;
    border: none;
    padding: 0;
    position: relative;
    border-radius: 0;
    font-size: 1em;
    font-weight: 700;
    white-space: nowrap;
    color: var(--color-primary);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.05),0 0 0 rgba(0,0,0,.05),0 0 0 rgba(0,0,0,.05),0 0 0 rgba(0,0,0,.05),0 0 0 rgba(0,0,0,.05);
    box-shadow: 0 0 0 rgba(0,0,0,.05),0 0 0 rgba(0,0,0,.05),0 0 0 rgba(0,0,0,.05),0 0 0 rgba(0,0,0,.05),0 0 0 rgba(0,0,0,.05)
}

.btn i,.btn strong,.btn>span {
    -webkit-transition: -webkit-transform .3s cubic-bezier(0.22,.665,.34,.875);
    transition: -webkit-transform .3s cubic-bezier(0.22,.665,.34,.875);
    transition: transform .3s cubic-bezier(0.22,.665,.34,.875);
    transition: transform .3s cubic-bezier(0.22,.665,.34,.875),-webkit-transform .3s cubic-bezier(0.22,.665,.34,.875)
}

.btn>span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 1.15em 2.1em;
    border-radius: inherit;
    border-color: inherit;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.btn.text-uppercase {
    letter-spacing: .1em
}

.btn.semi-round {
    border-radius: 2px
}

.btn.round {
    border-radius: 4px
}

.btn.circle {
    border-radius: 50em
}

.btn.disabled {
    pointer-events: none;
    cursor: none
}

.btn.disabled .btn-icon,.btn.disabled>span {
    opacity: .5
}

.btn.border-none {
    border-width: 0
}

.btn.border-none>span {
    margin: 0
}

.btn.border-none .btn-gradient-border rect {
    stroke-width: 0
}

.btn.border-thin {
    border-width: 1px
}

.btn.border-thin>span {
    margin: -.5px
}

.btn.border-thin .btn-gradient-border rect {
    stroke-width: 1px
}

.btn.border-thick {
    border-width: 2px
}

.btn.border-thick>span {
    margin: -1px
}

.btn.border-thick .btn-gradient-border rect {
    stroke-width: 2px
}

.btn.border-thicker {
    border-width: 3px
}

.btn.border-thicker>span {
    margin: -2px
}

.btn.border-thicker .btn-gradient-border rect {
    stroke-width: 3px
}

.btn+.btn {
    margin-left: .75em
}

.btn.wide>span {
    padding-left: 2.4em;
    padding-right: 2.4em
}

.btn:hover {
    border-color: transparent;
    background-color: var(--color-primary);
    color: #fff;
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05),0 2px 2px rgba(0,0,0,.05),0 4px 4px rgba(0,0,0,.05),0 8px 8px rgba(0,0,0,.05),0 16px 16px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05),0 2px 2px rgba(0,0,0,.05),0 4px 4px rgba(0,0,0,.05),0 8px 8px rgba(0,0,0,.05),0 16px 16px rgba(0,0,0,.05)
}

.btn:hover .btn-gradient-bg-hover {
    opacity: 1
}

.btn-xsm>span {
    padding: .6em 1em
}

.btn-xsm.wide>span {
    padding-left: 1.7em;
    padding-right: 1.7em
}

.btn-sm>span {
    padding: .85em 1.5em
}

.btn-sm.wide>span {
    padding-left: 3em;
    padding-right: 3em
}

.btn-md>span {
    padding: 1em 2.3em
}

.btn-md.wide>span {
    padding-left: 3em;
    padding-right: 3em
}

.btn-lg>span {
    padding: 1.3em 2.7em
}

.btn-lg.wide>span {
    padding-left: 3.6em;
    padding-right: 3.6em
}

.btn-xlg>span {
    padding: 1.5em 3.5em
}

.btn-xlg.wide>span {
    padding-left: 4.5em;
    padding-right: 4.5em
}

.btn-custom-sized>span {
    width: 100%;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0
}

.btn-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1em;
    letter-spacing: 0;
    -webkit-transition: inherit;
    transition: inherit;
    background-size: 99.99%!important
}

.btn-icon i {
    position: relative;
    z-index: 3
}

.btn-txt+.btn-icon {
    margin-right: 0;
    margin-left: 8px
}

.btn-icon:only-child {
    margin-bottom: 0!important
}

.btn-gradient-bg+.btn-icon,.btn-icon:first-child {
    margin-left: auto;
    margin-right: auto
}

.btn-icon,.btn-txt {
    position: relative;
    z-index: 3
}

.btn-txt small {
    display: block;
    opacity: .7;
    font-size: 1em;
    text-transform: none;
    letter-spacing: normal;
    text-align: left
}

.btn-icon-bordered .btn-icon,.btn-icon-solid .btn-icon {
    width: 2.5em;
    height: 2.5em;
    margin-left: 20px;
    border-width: 1px;
    border-style: solid;
    margin-top: -5%;
    margin-bottom: -5%
}

.btn-icon-bordered.btn-icon-left .btn-txt+.btn-icon,.btn-icon-solid.btn-icon-left .btn-txt+.btn-icon {
    margin-left: 0;
    margin-right: 20px
}

.btn-icon-semi-round .btn-icon {
    border-radius: 2px
}

.btn-icon-round .btn-icon {
    border-radius: 4px
}

.btn-icon-circle .btn-icon {
    border-radius: 50em
}

.btn-icon-circle.btn-icon-ripple .btn-icon:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    border-radius: inherit;
    border: 1px solid var(--color-primary);
    -webkit-animation: btnIconRipple 2s cubic-bezier(0.23,1,.32,1) both infinite;
    animation: btnIconRipple 2s cubic-bezier(0.23,1,.32,1) both infinite
}

.btn-icon-circle.btn-icon-ripple .btn-icon[class*=text-] {
    border-color: currentColor
}

.btn-icon-solid .btn-icon {
    border: none;
    background-color: var(--color-primary);
    color: #fff
}

.btn-icon-block .btn-icon,.btn-icon-block .btn-txt {
    text-align: center;
    margin-left: auto!important;
    margin-right: auto!important
}

.btn-icon-block .btn-icon {
    margin-top: 1.5em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.btn-icon-block .btn-txt {
    width: 100%
}

.btn-icon-left .btn-icon,.btn-icon-top .btn-icon {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
}

.btn-icon-left .btn-icon {
    margin-left: 0;
    margin-right: 8px
}

.btn-icon-top.btn-icon-block .btn-icon {
    margin-bottom: 1.5em;
    margin-top: 0
}

.btn-icon-top.btn-icon-block.btn-icon-xlg .btn-icon {
    margin-bottom: .5em
}

.btn-icon-xsm .btn-icon {
    width: 1.5em;
    height: 1.5em
}

.btn-icon-sm .btn-icon {
    width: 2em;
    height: 2em
}

.btn-icon-lg .btn-icon {
    width: 3.2em;
    height: 3.2em
}

.btn-icon-xlg .btn-icon {
    width: 3.5em;
    height: 3.5em
}

.btn-icon-xxlg .btn-icon {
    width: 6.25em;
    height: 6.25em
}

.btn-icon-border-thick .btn-icon {
    border-width: 2px
}

.btn-icon-border-thicker .btn-icon {
    border-width: 3px
}

.btn-solid {
    background: var(--color-primary);
    color: #fff
}

.btn-solid.color-secondary {
    background-color: #4fda90;
    border-color: #4fda90
}

.btn-solid.color-secondary .btn-gradient-bg {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)));
    background: linear-gradient(to right,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)
}

.btn-solid:hover {
    color: #fff
}

.btn-bordered.color-secondary {
    border-color: var(--color-secondary);
    color: var(--color-secondary)
}

.btn-bordered.color-secondary .btn-gradient-bg-hover {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-stop-secondary)),to(var(--color-gradient-start-secondary)));
    background: linear-gradient(to right,var(--color-gradient-stop-secondary) 0,var(--color-gradient-start-secondary) 100%)
}

.btn-bordered.color-secondary:hover {
    background-color: var(--color-secondary);
    color: #fff
}

.btn-naked {
    border: none
}

.btn-naked>span {
    padding: 0
}

.btn-naked:hover {
    color: var(--color-primary);
    -webkit-transform: translateX(0)!important;
    transform: translateX(0)!important;
    background-color: transparent!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important
}

.backgroundcliptext .btn-naked:not(.btn-icon-solid).btn-gradient .btn-icon i,.backgroundcliptext .btn-naked:not(.btn-icon-solid).btn-gradient .btn-txt,.backgroundcliptext .btn-underlined:not(.btn-icon-solid).btn-gradient .btn-icon i,.backgroundcliptext .btn-underlined:not(.btn-icon-solid).btn-gradient .btn-txt {
    background-clip: text!important;
    -webkit-background-clip: text!important;
    text-fill-color: transparent!important;
    -webkit-text-fill-color: transparent!important
}

.btn-naked>span>.btn-gradient-bg,.btn-underlined>span>.btn-gradient-bg {
    display: none
}

.btn-underlined {
    border: none;
    background-color: transparent!important;
    position: relative;
    font-weight: 600;
    color: var(--color-primary);
    display: inline-block
}

.btn-underlined:after,.btn-underlined:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(var(--color-primary),.25)
}

.btn-underlined:after {
    background: var(--color-primary);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transition: -webkit-transform .3s cubic-bezier(0.4,0,.2,1);
    transition: -webkit-transform .3s cubic-bezier(0.4,0,.2,1);
    transition: transform .3s cubic-bezier(0.4,0,.2,1);
    transition: transform .3s cubic-bezier(0.4,0,.2,1),-webkit-transform .3s cubic-bezier(0.4,0,.2,1)
}

.btn-underlined>span {
    padding: .4em 0
}

.btn-underlined:hover {
    background-color: transparent;
    color: var(--color-primary);
    -webkit-transform: none;
    transform: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-weight: 600;
    display: inline-block
}

.btn-underlined:hover:after {
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.btn-underlined.border-none:after,.btn-underlined.border-none:before {
    height: 0
}

.btn-underlined.border-thin:after,.btn-underlined.border-thin:before {
    height: 1px
}

.btn-underlined.border-thick:after,.btn-underlined.border-thick:before {
    height: 2px
}

.btn-underlined.border-thicker:after,.btn-underlined.border-thicker:before {
    height: 3px
}

.btn-underlined.color-secondary {
    color: var(--color-secondary)
}

.btn-underlined.color-secondary:before {
    background-color: var(--color-secondary)
}

.btn-underlined.color-secondary:after {
    background-color: #fff
}

.btn-split,.btn-split:hover {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: 0 0;
    color: #ffa487
}

.btn-split:hover .btn-split-bg {
    -webkit-animation: btnSplitHover .65s cubic-bezier(0.25,1,.35,1) both;
    animation: btnSplitHover .65s cubic-bezier(0.25,1,.35,1) both
}

.btn-block {
    white-space: normal
}

.btn-block>span {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.btn-block:hover {
    -webkit-transform: none;
    transform: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-gradient {
    border: none
}

.btn-gradient>span {
    margin: 0!important
}

.btn-gradient:hover {
    background-color: transparent
}

.btn-bordered-gradient {
    border: none
}

.btn-bordered-gradient:hover defs stop:first-child {
    stop-color: var(--color-gradient-start)
}

.btn-bordered-gradient:hover defs stop:last-child {
    stop-color: var(--color-gradient-stop)
}

.btn-bordered-gradient-primary:before {
    background: -webkit-gradient(linear,left top,right top,from(rgba(120,11,238,.6)),to(rgba(29,225,209,.6)));
    background: linear-gradient(to right,rgba(120,11,238,.6) 0,rgba(29,225,209,.6) 100%)
}

.btn-bordered-gradient-primary:after {
    background: #fff
}

.btn-gradient-bg,.btn-split-bg {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background-size: 99.99%
}

.btn-gradient-bg {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)
}

.btn-split-bg {
    width: 50%;
    background: #ffe1d8;
    -webkit-box-shadow: 0 10px 15px rgba(0,0,0,.07);
    box-shadow: 0 10px 15px rgba(0,0,0,.07);
    -webkit-animation: btnSplit .65s cubic-bezier(0.25,1,.35,1) both;
    animation: btnSplit .65s cubic-bezier(0.25,1,.35,1) both
}

.btn-gradient-bg-hover {
    z-index: 1;
    opacity: 0;
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-stop)),to(var(--color-gradient-start)));
    background: linear-gradient(to right,var(--color-gradient-stop) 0,var(--color-gradient-start) 100%);
    background-size: 99.99%
}

.btn-gradient-border {
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    position: absolute;
    fill: transparent;
    stroke: transparent;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: visible!important
}

.btn-gradient-border defs stop {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    stop-opacity: 1
}

.btn-gradient-border defs stop:first-child {
    stop-color: var(--color-gradient-start)
}

.btn-gradient-border defs stop:last-child {
    stop-color: var(--color-gradient-stop)
}

.btn-gradient-border rect {
    width: 100%;
    height: 100%;
    fill: transparent;
    stroke-width: 1
}

.ld-ajax-loadmore {
    display: inline-block;
    border: 1px solid;
    position: relative;
    text-align: center;
    white-space: nowrap
}

.ld-ajax-loadmore span {
    display: inline-block
}

.ld-ajax-loadmore>span {
    display: block;
    padding: .75em 2em
}

.ld-ajax-loadmore .all-loaded,.ld-ajax-loadmore .loading,.ld-ajax-loadmore .static {
    display: block;
    -webkit-transition: opacity .3s,visibility .3s,-webkit-transform .3s;
    transition: opacity .3s,visibility .3s,-webkit-transform .3s;
    transition: opacity .3s,visibility .3s,transform .3s;
    transition: opacity .3s,visibility .3s,transform .3s,-webkit-transform .3s
}

.ld-ajax-loadmore .dots {
    display: block
}

.ld-ajax-loadmore .dots>span {
    width: 6px;
    height: 6px;
    margin: 0 1px;
    border-radius: 50em;
    background-color: currentColor;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.ld-ajax-loadmore .all-loaded,.ld-ajax-loadmore .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0)
}

.ld-ajax-loadmore .loading {
    font-size: 10px
}

.ld-ajax-loadmore .all-loaded i {
    margin-left: 8px
}

.ld-ajax-loadmore.all-items-loaded,.ld-ajax-loadmore.items-loading {
    pointer-events: none;
    border-color: transparent
}

.ld-ajax-loadmore.all-items-loaded .static,.ld-ajax-loadmore.items-loading .static {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.ld-ajax-loadmore.items-loading .loading {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.ld-ajax-loadmore.items-loading .loading .dots>span {
    -webkit-animation: dotAnimations .6s cubic-bezier(0.23,1,.32,1) infinite alternate backwards;
    animation: dotAnimations .6s cubic-bezier(0.23,1,.32,1) infinite alternate backwards
}

.ld-ajax-loadmore.items-loading .loading .dots>span:nth-child(0) {
    -webkit-animation-delay: 0;
    animation-delay: 0
}

.ld-ajax-loadmore.items-loading .loading .dots>span:nth-child(1) {
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.ld-ajax-loadmore.items-loading .loading .dots>span:nth-child(2) {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.ld-ajax-loadmore.items-loading .loading .dots>span:nth-child(3) {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.ld-ajax-loadmore.all-items-loaded {
    color: #000
}

.ld-ajax-loadmore.all-items-loaded .all-loaded {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.btn-secondary.btn-bordered {
    border-color: var(--color-secondary);
    color: var(--color-secondary)
}

.btn-secondary.btn-bordered:hover {
    background-color: var(--color-secondary);
    color: #fff
}

.btn-secondary.btn-solid {
    border-color: var(--color-secondary);
    background-color: var(--color-secondary)
}

.btn-light.btn-bordered {
    color: #000;
    border-color: #e4e4e4
}

.btn-light.btn-bordered:hover {
    border-color: #000;
    background-color: #000;
    color: #fff
}

.btn-dark.btn-underlined {
    color: #3f4147
}

.btn-dark.btn-underlined:before {
    background: rgba(63,65,71,.5)
}

.btn-dark.btn-underlined:after {
    background: #000
}

.btn-dark.btn-solid {
    background-color: #222246;
    border-color: #222246
}

.btn-black.btn-underlined {
    color: #000
}

.btn-black.btn-underlined:before {
    background: rgba(0,0,0,.5)
}

.btn-black.btn-underlined:after {
    background: #000
}

.btn-black.btn-solid {
    background-color: #000;
    border-color: #000
}

.btn-white.btn-bordered {
    color: #fff;
    border-color: #fff
}

.btn-white.btn-bordered:hover,.btn-white.btn-solid {
    border-color: #fff;
    background-color: #fff;
    color: #000
}

.btn-white.btn-underlined:before {
    background-color: rgba(255,255,255,.75)
}

.btn-white.btn-underlined:after {
    background-color: #fff
}

.btn-white-fade-border.btn-bordered {
    border-color: rgba(255,255,255,.2);
    color: #fff
}

.btn-hover-white.btn-solid:hover,.btn-white-fade-border.btn-bordered:hover {
    border-color: #fff;
    background-color: #fff;
    color: #000
}

.social-icon {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 15px;
    line-height: 1em
}

.social-icon li {
    display: inline-block;
    margin-right: 1.2em;
    font-size: 1em;
    line-height: 1em
}

.social-icon li:last-child {
    margin-right: 0
}

.social-icon i {
    display: inherit;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit
}

.social-icon.social-icon-sm {
    font-size: 14px
}

.social-icon.social-icon-md {
    font-size: 18px
}

.social-icon.social-icon-lg {
    font-size: 20px
}

.social-icon.social-icon-lg li {
    margin-right: 1em
}

.social-icon.vertical li {
    display: block;
    margin-right: 0!important;
    margin-bottom: 1.2em
}

.social-icon.vertical li:last-child {
    margin-bottom: 0
}

.social-icon.circle li,.social-icon.round li,.social-icon.semi-round li,.social-icon.square li {
    margin-right: .5em
}

.social-icon.circle a,.social-icon.round a,.social-icon.semi-round a,.social-icon.square a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 40px;
    height: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--color-primary);
    color: #fff
}

.social-icon.circle a:hover,.social-icon.round a:hover,.social-icon.semi-round a:hover,.social-icon.square a:hover {
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.1);
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    -webkit-transform: translate(0,-2px);
    transform: translate(0,-2px)
}

.social-icon.circle.social-icon-sm li,.social-icon.round.social-icon-sm li,.social-icon.semi-round.social-icon-sm li,.social-icon.square.social-icon-sm li {
    margin-right: .3em
}

.social-icon.circle.social-icon-sm a,.social-icon.round.social-icon-sm a,.social-icon.semi-round.social-icon-sm a,.social-icon.square.social-icon-sm a {
    width: 30px;
    height: 30px
}

.social-icon.circle.social-icon-md li,.social-icon.round.social-icon-md li,.social-icon.semi-round.social-icon-md li,.social-icon.square.social-icon-md li {
    margin-right: .6em
}

.social-icon.circle.social-icon-md a,.social-icon.round.social-icon-md a,.social-icon.semi-round.social-icon-md a,.social-icon.square.social-icon-md a {
    width: 48px;
    height: 48px
}

.social-icon.circle.social-icon-lg li,.social-icon.round.social-icon-lg li,.social-icon.semi-round.social-icon-lg li,.social-icon.square.social-icon-lg li {
    margin-right: .7em
}

.social-icon.circle.social-icon-lg a,.social-icon.round.social-icon-lg a,.social-icon.semi-round.social-icon-lg a,.social-icon.square.social-icon-lg a {
    width: 55px;
    height: 55px
}

.social-icon.round a,.social-icon.round i {
    border-radius: 8%
}

.social-icon.semi-round a,.social-icon.semi-round i {
    border-radius: 2%
}

.social-icon.circle a,.social-icon.circle i {
    border-radius: 50em
}

.social-icon.scheme-dark a {
    color: #000
}

.social-icon.scheme-dark.circle a,.social-icon.scheme-dark.round a,.social-icon.scheme-dark.semi-round a,.social-icon.scheme-dark.square a {
    background-color: #000;
    color: #fff
}

.social-icon.scheme-gray a {
    color: #999
}

.social-icon.scheme-gray.circle a,.social-icon.scheme-gray.round a,.social-icon.scheme-gray.semi-round a,.social-icon.scheme-gray.square a {
    background-color: #999
}

.social-icon.scheme-white a {
    color: #fff
}

.social-icon.scheme-white.circle a,.social-icon.scheme-white.round a,.social-icon.scheme-white.semi-round a,.social-icon.scheme-white.square a {
    background-color: #fff;
    color: #000
}

.social-icon.branded-text a {
    background-color: transparent
}

.social-icon.branded i {
    width: 100%;
    height: 100%;
    position: relative
}

.social-icon.branded a {
    background-color: transparent;
    line-height: normal
}

.accordion {
    margin-bottom: 2em
}

.accordion-title {
    margin: 0;
    position: relative;
    line-height: 1.5em
}

.accordion-title a {
    display: block;
    width: 100%;
    padding: .93em 30px .93em 0;
    color: inherit
}

.accordion-expander {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 30px;
    font-size: 16px;
    color: inherit;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.accordion-expander>i {
    display: inline-block
}

.accordion-expander>i:last-child {
    display: none
}

.active .accordion-expander>i:last-child {
    display: inline-block
}

.active .accordion-expander>i:first-child {
    display: none
}

.accordion-content {
    padding: 1em 0
}

.accordion-content>:last-child,.accordion-content>:only-child {
    margin-bottom: 0
}

.accordion-active-has-fill .accordion-title a {
    padding-left: 30px;
    padding-right: 60px;
    background-color: transparent;
    color: inherit
}

.accordion-active-has-fill .accordion-expander {
    right: 30px
}

.accordion-active-has-fill .accordion-content {
    padding-left: 30px;
    padding-right: 30px
}

.accordion-active-has-fill.accordion-title-bordered .active .accordion-title a,.accordion-active-has-fill.accordion-title-underlined .active .accordion-title a {
    border-color: transparent
}

.accordion-active-has-fill .active .accordion-title a {
    background-color: var(--color-primary);
    color: #fff
}

.accordion-body-underlined .accordion-item {
    border-bottom: 1px solid #e0e1eb
}

.accordion-body-underlined .accordion-item:last-child {
    border-width: 0
}

.accordion-body-bordered .accordion-content,.accordion-body-bordered .accordion-title a {
    padding-left: 30px;
    padding-right: 30px
}

.accordion-body-bordered .accordion-item {
    margin-bottom: 20px;
    border: 1px solid rgba(0,0,0,.1)
}

.accordion-body-bordered .accordion-content {
    padding-top: 10px;
    padding-bottom: 30px
}

.accordion-title-underlined .accordion-title a {
    padding-right: 0;
    border-bottom: 1px solid #dadde1
}

.accordion-title-underlined .accordion-expander {
    right: 0
}

.accordion-title-bordered .accordion-item {
    margin-bottom: 1.25em
}

.accordion-title-bordered .accordion-title a {
    padding-left: 30px;
    padding-right: 60px;
    border: 1px solid #d8dbe2
}

.accordion-title-round .accordion-title a {
    border-radius: 4px
}

.accordion-title-circle .accordion-title a {
    border-radius: 50em
}

.accordion-body-round .accordion-item {
    border-radius: 4px
}

.accordion-active-has-shadow .accordion-title a {
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.1);
    box-shadow: 0 0 0 rgba(0,0,0,.1)
}

.accordion-active-has-shadow .active .accordion-title a {
    -webkit-box-shadow: 0 15px 30px rgba(0,0,0,.1);
    box-shadow: 0 15px 30px rgba(0,0,0,.1)
}

.accordion-expander-left .accordion-expander {
    right: auto;
    left: 0
}

.accordion-expander-left .accordion-title a {
    padding-left: 30px
}

.accordion-expander-left.accordion-active-has-fill .accordion-title a,.accordion-expander-left.accordion-body-bordered .accordion-title a,.accordion-expander-left.accordion-title-bordered .accordion-title a {
    padding-right: 30px;
    padding-left: 60px
}

.accordion-expander-left.accordion-active-has-fill .accordion-expander,.accordion-expander-left.accordion-body-bordered .accordion-expander,.accordion-expander-left.accordion-title-bordered .accordion-expander {
    left: 30px
}

.accordion-expander-left.accordion-body-underlined .accordion-content {
    padding-left: 30px
}

.accordion-xs .accordion-item {
    margin-bottom: .5em
}

.accordion-xs .accordion-title a {
    padding-top: 0;
    padding-bottom: 0
}

.accordion-sm .accordion-title a {
    padding-top: .65em;
    padding-bottom: .65em
}

.accordion-lg .accordion-title a {
    padding-top: 1.05em;
    padding-bottom: 1.05em
}

.accordion-expander-lg .accordion-expander {
    font-size: 22px
}

.accordion-expander-xl .accordion-expander {
    font-size: 26px
}

.accordion-active-bg-white .active .accordion-title a {
    background-color: #fff;
    color: var(--color-primary)
}

.accordion-active-color-primary .active .accordion-title a {
    color: var(--color-primary)
}

.accordion-light .accordion-title a {
    color: #fff
}

.accordion-light.accordion-body-bordered .accordion-item,.accordion-light.accordion-body-underlined .accordion-item,.accordion-light.accordion-title-bordered .accordion-title a,.accordion-light.accordion-title-underlined .accordion-title a {
    border-color: rgba(255,255,255,.35)
}

.accordion-titles-gray .accordion-title a {
    background-color: #F7F8F9
}

@-webkit-keyframes iconRipple {
    0% {
        border-width: 4px;
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    80% {
        border-width: 1px;
        -webkit-transform: scale(1.36);
        transform: scale(1.36)
    }

    100% {
        opacity: 0
    }
}

@keyframes iconRipple {
    0% {
        border-width: 4px;
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    80% {
        border-width: 1px;
        -webkit-transform: scale(1.36);
        transform: scale(1.36)
    }

    100% {
        opacity: 0
    }
}

.iconbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 2em;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    -webkit-transition: background .3s ease,-webkit-box-shadow .3s ease;
    transition: background .3s ease,-webkit-box-shadow .3s ease;
    transition: box-shadow .3s ease,background .3s ease;
    transition: box-shadow .3s ease,background .3s ease,-webkit-box-shadow .3s ease;
    width: 100%
}

.iconbox .contents,.iconbox .iconbox-icon-container,.iconbox>h3 {
    position: relative;
    z-index: 2
}

.iconbox .iconbox-icon-container,.iconbox h3,.iconbox p {
    -webkit-transition-property: color,background;
    transition-property: color,background;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease
}

.iconbox h3 {
    display: inline-block;
    margin: 0 0 .7em;
    font-size: 24px;
    line-height: 1.5em
}

.iconbox .iconbox-icon-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: .3em;
    border: 0 solid #F2F4F7;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 40px;
    line-height: 1em;
    color: var(--color-primary);
    -webkit-transition: border .3s,-webkit-box-shadow .3s;
    transition: border .3s,-webkit-box-shadow .3s;
    transition: border .3s,box-shadow .3s;
    transition: border .3s,box-shadow .3s,-webkit-box-shadow .3s
}

.iconbox .iconbox-icon-container i,.iconbox .iconbox-icon-container img,.iconbox .iconbox-icon-container object,.iconbox .iconbox-icon-container>svg {
    position: relative;
    z-index: 2
}

.iconbox .iconbox-icon-container img[src$=".svg"],.iconbox .iconbox-icon-container object,.iconbox .iconbox-icon-container>svg {
    display: inline-block;
    width: 40px;
    line-height: 0
}

.iconbox .iconbox-icon-container img[src$=".svg"] stop,.iconbox .iconbox-icon-container object stop,.iconbox .iconbox-icon-container>svg stop {
    -webkit-transition: stop-color .3s ease;
    transition: stop-color .3s ease
}

.iconbox .iconbox-icon-container i {
    display: inline-block;
    font-size: 1em
}

.iconbox .iconbox-icon-container[data-plugin-animated-icon] {
    font-size: 1em;
    line-height: 1em!important
}

.iconbox .iconbox-icon-container[data-plugin-animated-icon] object,.iconbox .iconbox-icon-container[data-plugin-animated-icon]>svg {
    opacity: 0
}

.iconbox .contents {
    max-width: 100%
}

.iconbox .iconbox-fill-el,.iconbox .iconbox-icon-hover-bg {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    opacity: 0;
    background-size: cover
}

.iconbox p {
    margin-bottom: 0
}

.iconbox .btn {
    margin-top: 1.5em;
    margin-bottom: .5em
}

.iconbox.text-left {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.iconbox.text-right {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.iconbox:hover .iconbox-fill-el,.iconbox:hover .iconbox-icon-hover-bg {
    opacity: 1
}

.iconbox-p {
    padding: 10%
}

[data-animate-icon=true] .iconbox-icon-container>object,[data-animate-icon=true] .iconbox-icon-container>svg {
    opacity: 0
}

[data-animate-icon=true].iconbox-icon-animating .iconbox-icon-container>object,[data-animate-icon=true].iconbox-icon-animating .iconbox-icon-container>svg {
    opacity: 1
}

.iconbox-circle .iconbox-icon-container i,.iconbox-circle .iconbox-icon-container img[src$=".svg"],.iconbox-circle .iconbox-icon-container object,.iconbox-circle .iconbox-icon-container>svg,.iconbox-lozenge .iconbox-icon-container i,.iconbox-lozenge .iconbox-icon-container img[src$=".svg"],.iconbox-lozenge .iconbox-icon-container object,.iconbox-lozenge .iconbox-icon-container>svg,.iconbox-square .iconbox-icon-container i,.iconbox-square .iconbox-icon-container img[src$=".svg"],.iconbox-square .iconbox-icon-container object,.iconbox-square .iconbox-icon-container>svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}

.iconbox-circle.iconbox-icon-solid img,.iconbox-circle[data-shape-border] img,.iconbox-lozenge.iconbox-icon-solid img,.iconbox-lozenge[data-shape-border] img,.iconbox-square.iconbox-icon-solid img,.iconbox-square[data-shape-border] img {
    max-width: 68%
}

.iconbox-label {
    display: inline-block;
    padding: 8px 12px;
    position: absolute;
    top: 13px;
    right: 13px;
    border-radius: 2px;
    background: #EFEFEF;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: #000
}

.iconbox-circle .iconbox-icon-container,.iconbox-hexagon .iconbox-icon-container,.iconbox-lozenge .iconbox-icon-container,.iconbox-square .iconbox-icon-container {
    border-radius: 6px;
    background: var(--color-primary);
    font-size: 40px;
    color: #fff
}

.iconbox-circle .iconbox-icon-container img[src$=".svg"],.iconbox-circle .iconbox-icon-container object,.iconbox-circle .iconbox-icon-container>svg,.iconbox-hexagon .iconbox-icon-container img[src$=".svg"],.iconbox-hexagon .iconbox-icon-container object,.iconbox-hexagon .iconbox-icon-container>svg,.iconbox-lozenge .iconbox-icon-container img[src$=".svg"],.iconbox-lozenge .iconbox-icon-container object,.iconbox-lozenge .iconbox-icon-container>svg,.iconbox-square .iconbox-icon-container img[src$=".svg"],.iconbox-square .iconbox-icon-container object,.iconbox-square .iconbox-icon-container>svg {
    width: 50px
}

.iconbox-circle .iconbox-icon-container,.iconbox-lozenge .iconbox-icon-container,.iconbox-square .iconbox-icon-container {
    width: 80px;
    height: 80px
}

.iconbox-circle .iconbox-icon-container {
    border-radius: 50em
}

.iconbox-circle .iconbox-icon-container img[src$=".svg"],.iconbox-circle .iconbox-icon-container object,.iconbox-circle .iconbox-icon-container>svg {
    width: 44px
}

.iconbox-lozenge .iconbox-icon-container {
    border-radius: 15px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.iconbox-lozenge .iconbox-icon-container i,.iconbox-lozenge .iconbox-icon-container img[src$=".svg"],.iconbox-lozenge .iconbox-icon-container object,.iconbox-lozenge .iconbox-icon-container>svg {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg)
}

.iconbox-hexagon .iconbox-icon-container {
    width: 80px;
    height: 50px;
    margin-top: 26.67px;
    margin-bottom: 26.67px
}

.iconbox-hexagon .iconbox-icon-container:after,.iconbox-hexagon .iconbox-icon-container:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: inherit;
    height: inherit;
    background: inherit;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    border-radius: inherit;
    z-index: -1
}

.iconbox-hexagon .iconbox-icon-container:before {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg)
}

.iconbox-hexagon .iconbox-icon-container:after {
    -webkit-transform: rotate(-60deg);
    transform: rotate(-60deg)
}

.iconbox-wavebg .iconbox-icon-container {
    margin-top: .6em;
    margin-bottom: 1em
}

.iconbox-wavebg .iconbox-icon-container .icon-wave-bg {
    opacity: .22
}

.iconbox-wavebg .iconbox-icon-container .icon-wave-bg>img,.iconbox-wavebg .iconbox-icon-container .icon-wave-bg>svg {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}

.iconbox-wavebg .iconbox-icon-container .icon-wave-bg>svg {
    width: 3.1em;
    height: auto
}

.iconbox-wavebg .iconbox-icon-container .icon-wave-bg>img {
    max-width: none
}

.iconbox-wavebg .iconbox-icon-container .icon-wave-bg path {
    fill: var(--color-primary)
}

.iconbox-wavebg .iconbox-icon-container .icon-wave-bg.default-opacity {
    opacity: 1
}

.iconbox-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left
}

.iconbox-inline .iconbox-icon-container,.iconbox-inline h3 {
    margin-bottom: 0
}

.iconbox-inline .iconbox-icon-wrap {
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.iconbox-inline .iconbox-icon-container {
    margin-right: 15px
}

.iconbox-inline h3 {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0
}

.iconbox-inline .contents {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-top: 15px
}

.iconbox-inline.text-right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.iconbox-inline.text-right .iconbox-icon-container {
    margin-right: 0;
    margin-left: 15px
}

.iconbox-inline.iconbox-wavebg .iconbox-icon-container {
    margin-right: 1.5em
}

.iconbox-inline.iconbox-wavebg.text-right {
    margin-right: 0;
    margin-left: 1.5em
}

.iconbox-side {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left
}

.iconbox-side .iconbox-icon-wrap {
    margin-right: 30px
}

.iconbox-side .iconbox-icon-container {
    margin-bottom: 0
}

.iconbox-side .contents {
    overflow: hidden;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.iconbox-side.text-right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.iconbox-side.text-right .iconbox-icon-wrap {
    margin-right: 0;
    margin-left: 30px
}

.iconbox-side.iconbox-wavebg .iconbox-icon-container {
    margin-right: 1.5em
}

.iconbox-side.iconbox-wavebg.text-right {
    margin-right: 0;
    margin-left: 1.5em
}

[data-shape-border="1"] .iconbox-icon-container {
    border-width: 1px
}

[data-shape-border="2"] .iconbox-icon-container {
    border-width: 2px
}

[data-shape-border="3"] .iconbox-icon-container {
    border-width: 3px
}

.iconbox-bordered,.iconbox-filled,.iconbox-shadow,.iconbox-shadow-hover {
    background-color: #fff
}

.iconbox-bordered.iconbox-inline,.iconbox-bordered.iconbox-side,.iconbox-filled.iconbox-inline,.iconbox-filled.iconbox-side,.iconbox-shadow-hover.iconbox-inline,.iconbox-shadow-hover.iconbox-side,.iconbox-shadow.iconbox-inline,.iconbox-shadow.iconbox-side {
    padding: 45px 5%
}

.iconbox-semiround {
    border-radius: 5px
}

.iconbox-round {
    border-radius: 10px
}

.iconbox-shadow {
    -webkit-box-shadow: 0 25px 70px rgba(0,0,0,.07);
    box-shadow: 0 25px 70px rgba(0,0,0,.07)
}

.iconbox-shadow-sm {
    -webkit-box-shadow: 0 25px 70px rgba(0,0,0,.07);
    box-shadow: 0 9px 16px rgba(0,0,0,.07)
}

.iconbox-shadow-hover {
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 0 rgba(0,0,0,0)
}

.iconbox-shadow-hover:hover {
    -webkit-box-shadow: 0 25px 70px rgba(0,0,0,.07);
    box-shadow: 0 25px 70px rgba(0,0,0,.07)
}

.iconbox-bordered {
    padding-top: 40px;
    padding-bottom: 40px;
    border: 1px solid #E1E1E1
}

.iconbox-filled-hover:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    border-radius: inherit;
    background: center/cover var(--color-primary)
}

.iconbox-filled-hover:hover {
    color: #fff
}

.iconbox-filled-hover:hover .btn[class*=text-],.iconbox-filled-hover:hover .iconbox-icon-container,.iconbox-filled-hover:hover a,.iconbox-filled-hover:hover h3,.iconbox-filled-hover:hover p,.iconbox-filled-hover:hover p span {
    color: inherit!important
}

.iconbox-filled-hover:hover .btn-underlined:after,.iconbox-filled-hover:hover .btn-underlined:before {
    background: currentColor
}

.iconbox-filled-hover:hover:before {
    opacity: 1
}

.iconbox-filled-hover:hover.iconbox-icon-image .iconbox-icon-container svg path {
    fill: currentColor;
    stroke: currentColor;
    -webkit-filter: none;
    filter: none
}

.iconbox-filled-hover.iconbox-has-fill-element:before {
    content: none
}

.backgroundcliptext .iconbox-filled-hover.iconbox-heading-gradient:hover .iconbox-icon-container i,.backgroundcliptext .iconbox-filled-hover.iconbox-heading-gradient:hover h3 {
    background: #fff
}

.iconbox-filled-hover.iconbox-scale-bg:before {
    -webkit-transition: opacity .35s .1s cubic-bezier(0.23,1,.32,1),-webkit-transform .35s cubic-bezier(0.23,1,.32,1);
    transition: opacity .35s .1s cubic-bezier(0.23,1,.32,1),-webkit-transform .35s cubic-bezier(0.23,1,.32,1);
    transition: transform .35s cubic-bezier(0.23,1,.32,1),opacity .35s .1s cubic-bezier(0.23,1,.32,1);
    transition: transform .35s cubic-bezier(0.23,1,.32,1),opacity .35s .1s cubic-bezier(0.23,1,.32,1),-webkit-transform .35s cubic-bezier(0.23,1,.32,1)
}

.iconbox-filled-hover.iconbox-scale-bg:hover:before {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: opacity .1s 0 cubic-bezier(0.23,1,.32,1),-webkit-transform .35s cubic-bezier(0.23,1,.32,1);
    transition: opacity .1s 0 cubic-bezier(0.23,1,.32,1),-webkit-transform .35s cubic-bezier(0.23,1,.32,1);
    transition: transform .35s cubic-bezier(0.23,1,.32,1),opacity .1s 0 cubic-bezier(0.23,1,.32,1);
    transition: transform .35s cubic-bezier(0.23,1,.32,1),opacity .1s 0 cubic-bezier(0.23,1,.32,1),-webkit-transform .35s cubic-bezier(0.23,1,.32,1)
}

.iconbox-filled-hover.iconbox-light-onhover:before {
    background-color: #fff
}

.iconbox-filled-hover.iconbox-light-onhover:hover {
    color: rgba(30,22,102,.55)
}

.iconbox-filled-hover.iconbox-light-onhover:hover .iconbox-icon-container {
    color: var(--color-primary)!important
}

.iconbox-filled-hover.iconbox-light-onhover:hover h3 {
    color: #1e1666!important
}

.iconbox-icon-shadow .iconbox-icon-container {
    -webkit-box-shadow: 0 17px 30px rgba(0,0,0,.07);
    box-shadow: 0 17px 30px rgba(0,0,0,.07)
}

.iconbox-icon-hover-shadow .iconbox-icon-container {
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 0 rgba(0,0,0,0)
}

.iconbox-icon-hover-shadow:hover .iconbox-icon-container {
    -webkit-box-shadow: 0 17px 30px rgba(0,0,0,.07);
    box-shadow: 0 17px 30px rgba(0,0,0,.07)
}

.iconbox-icon-linked .iconbox-icon-wrap {
    position: relative
}

.iconbox-icon-linked .iconbox-icon-wrap:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 200%;
    position: absolute;
    top: 0;
    left: 50%;
    background-color: #E9E9E9
}

.iconbox-icon-linked:last-child .iconbox-icon-wrap:after {
    display: none
}

.iconbox-contents-show-onhover.hide-target .contents>* {
    opacity: 0
}

.iconbox-heading-xs h3 {
    font-size: 18px
}

.iconbox-heading-sm h3 {
    font-size: 20px
}

.iconbox-heading-md h3 {
    font-size: 24px
}

.iconbox-heading-lg h3 {
    font-size: 28px
}

.backgroundcliptext .iconbox-heading-gradient h3,.backgroundcliptext .iconbox-icon-gradient .iconbox-icon-container i {
    background: -webkit-gradient(linear,left top,right top,from(#1DE1D1),to(#780BEE));
    background: linear-gradient(to right,#1DE1D1 0,#780BEE 100%);
    background-clip: text!important;
    -webkit-background-clip: text!important;
    text-fill-color: transparent!important;
    -webkit-text-fill-color: transparent!important
}

.iconbox-xxs .iconbox-icon-container img {
    width: 20px
}

.iconbox-xxs.iconbox-circle .iconbox-icon-container,.iconbox-xxs.iconbox-lozenge .iconbox-icon-container,.iconbox-xxs.iconbox-square .iconbox-icon-container {
    width: 20px;
    height: 20px
}

.iconbox-xxs.iconbox-hexagon .iconbox-icon-container {
    width: 20px;
    height: 12.5px
}

.iconbox-xxs.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
    margin-top: 6.67px;
    margin-bottom: 6.67px
}

.iconbox-xs .iconbox-icon-container img {
    width: 45px
}

.iconbox-xs.iconbox-circle .iconbox-icon-container,.iconbox-xs.iconbox-lozenge .iconbox-icon-container,.iconbox-xs.iconbox-square .iconbox-icon-container {
    width: 45px;
    height: 45px
}

.iconbox-xs.iconbox-hexagon .iconbox-icon-container {
    width: 45px;
    height: 28.13px
}

.iconbox-xs.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
    margin-top: 15px;
    margin-bottom: 15px
}

.iconbox-sm .iconbox-icon-container img {
    width: 60px
}

.iconbox-sm.iconbox-circle .iconbox-icon-container,.iconbox-sm.iconbox-lozenge .iconbox-icon-container,.iconbox-sm.iconbox-square .iconbox-icon-container {
    width: 60px;
    height: 60px
}

.iconbox-sm.iconbox-hexagon .iconbox-icon-container {
    width: 60px;
    height: 37.5px
}

.iconbox-sm.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
    margin-top: 20px;
    margin-bottom: 20px
}

.iconbox-md .iconbox-icon-container img {
    width: 90px
}

.iconbox-md.iconbox-circle .iconbox-icon-container,.iconbox-md.iconbox-lozenge .iconbox-icon-container,.iconbox-md.iconbox-square .iconbox-icon-container {
    width: 90px;
    height: 90px
}

.iconbox-md.iconbox-hexagon .iconbox-icon-container {
    width: 90px;
    height: 56.25px
}

.iconbox-md.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
    margin-top: 30px;
    margin-bottom: 30px
}

.iconbox-lg .iconbox-icon-container img {
    width: 100px
}

.iconbox-lg.iconbox-circle .iconbox-icon-container,.iconbox-lg.iconbox-lozenge .iconbox-icon-container,.iconbox-lg.iconbox-square .iconbox-icon-container {
    width: 100px;
    height: 100px
}

.iconbox-lg.iconbox-hexagon .iconbox-icon-container {
    width: 100px;
    height: 62.5px
}

.iconbox-lg.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
    margin-top: 33.33px;
    margin-bottom: 33.33px
}

.iconbox-xl .iconbox-icon-container img {
    width: 125px
}

.iconbox-xl.iconbox-circle .iconbox-icon-container,.iconbox-xl.iconbox-lozenge .iconbox-icon-container,.iconbox-xl.iconbox-square .iconbox-icon-container {
    width: 125px;
    height: 125px
}

.iconbox-xl.iconbox-hexagon .iconbox-icon-container {
    width: 125px;
    height: 78.13px
}

.iconbox-xl.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
    margin-top: 41.67px;
    margin-bottom: 41.67px
}

.iconbox-xxs .iconbox-icon-container {
    font-size: 20px
}

.iconbox-xxs .iconbox-icon-container img[src$=".svg"],.iconbox-xxs .iconbox-icon-container object,.iconbox-xxs .iconbox-icon-container>svg {
    width: 20px
}

.iconbox-xs .iconbox-icon-container {
    font-size: 20px
}

.iconbox-xs .iconbox-icon-container img[src$=".svg"],.iconbox-xs .iconbox-icon-container object,.iconbox-xs .iconbox-icon-container>svg {
    width: 20px
}

.iconbox-sm .iconbox-icon-container {
    font-size: 25px
}

.iconbox-sm .iconbox-icon-container img[src$=".svg"],.iconbox-sm .iconbox-icon-container object,.iconbox-sm .iconbox-icon-container>svg {
    width: 25px
}

.iconbox-md .iconbox-icon-container {
    font-size: 40px
}

.iconbox-md .iconbox-icon-container img[src$=".svg"],.iconbox-md .iconbox-icon-container object,.iconbox-md .iconbox-icon-container>svg {
    width: 40px
}

.iconbox-lg .iconbox-icon-container {
    font-size: 45px
}

.iconbox-lg .iconbox-icon-container img[src$=".svg"],.iconbox-lg .iconbox-icon-container object,.iconbox-lg .iconbox-icon-container>svg {
    width: 45px
}

.iconbox-xl .iconbox-icon-container {
    font-size: 50px
}

.iconbox-xl .iconbox-icon-container img[src$=".svg"],.iconbox-xl .iconbox-icon-container object,.iconbox-xl .iconbox-icon-container>svg {
    width: 50px
}

.iconbox-icon-ripple .iconbox-icon-container:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    border: 1px solid var(--color-primary);
    -webkit-animation: iconRipple 2s cubic-bezier(0.23,1,.32,1) both infinite;
    animation: iconRipple 2s cubic-bezier(0.23,1,.32,1) both infinite
}

.iconbox-blue {
    background-color: #dde1f8
}

.iconbox-blue .contents {
    color: #3d59e8
}

.iconbox-pink {
    background-color: #fcd7e0
}

.iconbox-pink .contents {
    color: #fe688b
}

.iconbox-green {
    background-color: #c7f7ea
}

.iconbox-green .contents {
    color: #28d5a7
}

.lqd-parallax-iconboxes {
    padding: 35px 35px 15px;
    -webkit-box-shadow: 0 2px 14px 0 rgba(0,0,0,.082);
    box-shadow: 0 2px 14px 0 rgba(0,0,0,.082)
}

.iconbox-color-pippin .iconbox-icon-container {
    color: #fd623c;
    background-color: #ffe4df
}

.iconbox-color-apple .iconbox-icon-container {
    color: #46d664;
    background-color: #cdf2d6
}

.compose-mode [data-animate-icon=true] .iconbox-icon-container>object,.compose-mode [data-animate-icon=true] .iconbox-icon-container>svg {
    opacity: 1
}

@media screen and (min-width: 992px) {
    .lqd-parallax-iconboxes {
        margin-top:-110%
    }
}

@media screen and (min-width: 768px) {
    .lqd-iconbox-stack {
        position:relative;
        text-align: center
    }

    .lqd-iconbox-stack .iconbox {
        padding-left: 10px;
        padding-right: 10px;
        width: 150px
    }

    .lqd-iconbox-stack .iconbox-icon-container {
        margin-bottom: 0
    }

    .lqd-iconbox-stack .iconbox-blue,.lqd-iconbox-stack .iconbox-green {
        position: absolute;
        top: 26%;
        z-index: 2
    }

    .lqd-iconbox-stack .iconbox-blue {
        top: 30%;
        left: 7%
    }

    .lqd-iconbox-stack .iconbox-pink {
        margin-top: 12vw
    }

    .lqd-iconbox-stack .iconbox-green {
        right: 7%
    }
}

@media screen and (max-width: 767px) {
    .lqd-iconbox-stack .iconbox {
        width:100%
    }
}

.tabs-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-bottom: 2em;
    position: relative
}

.tabs-nav>li,.tabs-nav>li>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.tabs-nav a {
    position: relative;
    z-index: 2;
    -webkit-box-align: center;
    -ms-flex-align: center;
    padding: .6em 1em;
    font-size: 1em;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.tabs-nav li {
    padding: 0;
    margin: 0;
    text-align: center;
    line-height: 1.5em;
    letter-spacing: inherit;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.tabs-nav li a:focus,.tabs-nav li a:hover,.tabs-nav li.active a {
    color: var(--color-link-hover);
    background-color: transparent
}

.tabs-pane {
    display: none
}

.tabs-pane.active {
    display: block
}

.tabs-nav-centered .tabs-nav {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.tabs-nav-justified .tabs-nav li,.tabs-nav-justified .tabs-nav li a {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.tabs-nav-spaced .tabs-nav li+li {
    margin-left: 8px
}

.tabs-nav-bordered .tabs-nav {
    border: 1px solid #e0e1eb
}

.tabs-nav-underlined .tabs-nav {
    border-bottom: 1px solid #e0e1eb
}

.tabs-nav-items-bordered .tabs-nav li {
    font-size: 12px;
    border: 1px solid #E1E1E1
}

.tabs-nav-items-bordered .tabs-nav a {
    padding: 1.25em 3.6666666667em;
    margin: -1px
}

.tabs-nav-filled .tabs-nav a {
    padding: 1.15em 2.8em;
    background-color: rgba(0,0,0,.07);
    color: #000
}

.tabs-nav-filled.tabs-content-filled .tabs-nav a {
    background: 0 0
}

.tabs-nav-active-filled .tabs-nav li a:hover,.tabs-nav-active-filled .tabs-nav li.active a {
    background-color: #e0e1eb
}

.tabs-nav-active-underlined .tabs-nav li {
    position: relative;
    overflow: hidden
}

.tabs-nav-active-underlined .tabs-nav li:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 0;
    pointer-events: none;
    background-color: var(--color-primary);
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
}

.tabs-nav-active-underlined .tabs-nav li.active:after,.tabs-nav-active-underlined .tabs-nav li:hover:after {
    -webkit-transform: scaleY(1);
    transform: scaleY(1)
}

.tabs-nav-shadowed .tabs-nav {
    -webkit-box-shadow: 0 10px 50px rgba(0,0,0,.05);
    box-shadow: 0 10px 50px rgba(0,0,0,.05)
}

.tabs-contents-shadowed .tabs-nav {
    margin: 0
}

.tabs-contents-shadowed .tabs-content {
    padding: 40px 30px 1.25em;
    background-color: #fff;
    -webkit-box-shadow: 0 20px 50px rgba(0,0,0,.07);
    box-shadow: 0 20px 50px rgba(0,0,0,.07)
}

.tabs-content-filled .tabs-nav {
    margin-bottom: 0
}

.tabs-content-filled .tabs-content {
    padding: 30px 40px;
    background-color: #ECECEC
}

.tabs-nav-icon {
    margin-right: .65em;
    font-size: 26px
}

.tabs-nav-sm .tabs-nav li {
    margin-left: 35px;
    margin-right: 35px
}

.tabs-nav-sm .tabs-nav a {
    padding: .05em 0
}

.tabs-nav-lg .tabs-nav a {
    padding: 1.2em 2em
}

.tabs-nav-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch
}

.tabs-nav-side .tabs-content,.tabs-nav-side .tabs-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.tabs-nav-side .tabs-nav {
    width: 26.5%;
    padding: 35px 0 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.tabs-nav-side .tabs-nav a {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    padding: 9px 40px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.tabs-nav-side .tabs-nav a:after {
    content: '';
    display: inline-block;
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
    background-color: currentColor;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s
}

.tabs-nav-side .tabs-nav a:hover:after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.tabs-nav-side .tabs-nav li {
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 16px;
    text-align: left;
    -webkit-transition: font-weight .3s;
    transition: font-weight .3s
}

.tabs-nav-side .tabs-nav li.active {
    font-weight: 700
}

.tabs-nav-side .tabs-nav li.active a:after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.tabs-nav-side .tabs-nav .tabs-nav-title {
    padding: 0 40px;
    margin-bottom: 20px
}

.tabs-nav-side .tabs-nav .tabs-nav-title h6 {
    margin: 0;
    color: #5A5A5A
}

.tabs-nav-side .tabs-content {
    width: 73.5%;
    padding: 30px
}

.tabs-nav-side-alt .tabs-nav {
    width: 31.6%
}

.tabs-nav-side-alt .tabs-nav li {
    border-bottom: 1px solid #F0F0F0;
    font-size: 20px;
    font-weight: 600
}

.tabs-nav-side-alt .tabs-nav li a {
    padding: 1.15em 2.25em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    color: #100F26;
    font-weight: 700
}

.tabs-nav-side-alt .tabs-nav li a:after {
    content: '\f105';
    width: auto;
    height: auto;
    position: absolute;
    top: 1.15em;
    left: 0;
    background: 0 0;
    opacity: 0;
    font-family: fontAwesome;
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-transition: opacity .3s,-webkit-transform .3s;
    transition: opacity .3s,-webkit-transform .3s;
    transition: transform .3s,opacity .3s;
    transition: transform .3s,opacity .3s,-webkit-transform .3s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1)
}

.tabs-nav-side-alt .tabs-nav li a span {
    margin-top: .65em;
    font-size: .75em;
    line-height: 1.7333333333em;
    font-weight: 400
}

.tabs-nav-side-alt .tabs-nav li a span,.tabs-nav-side-alt .tabs-nav li.active a span {
    color: #84898F
}

.tabs-nav-side-alt .tabs-nav li.active a:after,.tabs-nav-side-alt .tabs-nav li:hover a:after {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.tabs-nav-side-alt .tabs-nav li.active {
    font-weight: 600
}

.tabs-nav-side-alt .tabs-nav li.active a {
    color: var(--color-primary)
}

.tabs-nav-side-alt .tabs-content {
    width: 68.4%
}

.tabs-nav-side-block .tabs-nav li {
    border: none;
    border-radius: 6px;
    font-size: 21px;
    font-weight: 500
}

.tabs-nav-side-block .tabs-nav li:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    background-color: var(--color-primary);
    opacity: 0;
    -webkit-transform: scale(0.97,.95);
    transform: scale(0.97,.95);
    -webkit-transition: opacity .45s cubic-bezier(0.2,.95,.2,1),-webkit-transform .45s cubic-bezier(0.2,.95,.2,1);
    transition: opacity .45s cubic-bezier(0.2,.95,.2,1),-webkit-transform .45s cubic-bezier(0.2,.95,.2,1);
    transition: transform .45s cubic-bezier(0.2,.95,.2,1),opacity .45s cubic-bezier(0.2,.95,.2,1);
    transition: transform .45s cubic-bezier(0.2,.95,.2,1),opacity .45s cubic-bezier(0.2,.95,.2,1),-webkit-transform .45s cubic-bezier(0.2,.95,.2,1)
}

.tabs-nav-side-block .tabs-nav li:not(:last-child) {
    margin-bottom: .1em
}

.tabs-nav-side-block .tabs-nav li a {
    padding-left: 30px;
    padding-right: 30px
}

.tabs-nav-side-block .tabs-nav li a:after {
    content: none
}

.tabs-nav-side-block .tabs-nav li a span {
    margin-top: .5em;
    opacity: .65;
    font-size: .7142857143em;
    color: inherit
}

.tabs-nav-side-block .tabs-nav li.active:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.tabs-nav-side-block .tabs-nav li.active a {
    color: #fff
}

.tabs-nav-side-block .tabs-nav li.active a span,.tabs-nav-side-block .tabs-nav li:hover a span {
    color: inherit
}

.tabs-nav-side-block .tabs-nav li.active {
    font-weight: 500
}

@-webkit-keyframes formRotate360 {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes formRotate360 {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.ld-sf {
    position: relative;
    margin-bottom: 2em
}

.ld-sf input:focus {
    outline: 0
}

.ld-sf p {
    line-height: 1.5em;
    margin-bottom: 20px;
    position: relative
}

.ld-sf p [type=submit] {
    padding-top: 0;
    padding-bottom: 0
}

.ld-sf [type=email],.ld-sf [type=submit],.ld-sf [type=text] {
    height: 60px
}

.ld-sf [type=email],.ld-sf [type=text] {
    display: block;
    width: 100%;
    padding: 0 2.5em;
    border-width: 1px;
    border-style: solid;
    border-color: #ededed;
    border-radius: 2px;
    background-color: transparent;
    line-height: 1.5em;
    -webkit-transition: background-color .3s ease,border-color .3s ease,-webkit-box-shadow .3s ease;
    transition: background-color .3s ease,border-color .3s ease,-webkit-box-shadow .3s ease;
    transition: background-color .3s ease,border-color .3s ease,box-shadow .3s ease;
    transition: background-color .3s ease,border-color .3s ease,box-shadow .3s ease,-webkit-box-shadow .3s ease
}

.ld-sf [type=email]:focus,.ld-sf [type=text]:focus {
    border-color: #aaa
}

.ld-sf [type=email]::-webkit-input-placeholder,.ld-sf [type=text]::-webkit-input-placeholder {
    color: inherit
}

.ld-sf [type=email]::-moz-placeholder,.ld-sf [type=text]::-moz-placeholder {
    color: inherit
}

.ld-sf [type=email]:-moz-placeholder,.ld-sf [type=text]:-moz-placeholder {
    color: inherit
}

.ld-sf [type=email]:-ms-input-placeholder,.ld-sf [type=text]:-ms-input-placeholder {
    color: inherit
}

.ld-sf select {
    display: inline-block;
    width: auto
}

.ld-sf [type=submit] {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 0 40px;
    margin: 0;
    border: 0;
    position: relative;
    border-radius: 2px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 13px;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: .13em;
    outline: 0;
    background-color: var(--color-primary);
    color: #fff;
    -webkit-transition: background .3s ease,color .3s ease,border .3s ease;
    transition: background .3s ease,color .3s ease,border .3s ease
}

.ld-sf [type=submit]:hover .submit-icon,.ld-sf [type=submit]:hover .submit-text+.submit-icon {
    -webkit-transform: translate3d(2px,0,0);
    transform: translate3d(2px,0,0)
}

.ld-sf .submit-icon,.ld-sf .submit-text {
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease,-webkit-transform .3s ease;
    line-height: 1em
}

.ld-sf .submit-icon {
    font-size: 1.1em
}

.ld-sf .submit-icon i {
    margin: 0
}

.ld-sf .submit-icon+.submit-text,.ld-sf .submit-text+.submit-icon {
    margin-left: .65em
}

.ld-sf .submit-text:empty {
    display: none
}

.ld-sf[data-plugin-ld-sf] {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s ease,visiblity .3s ease;
    transition: opacity .3s ease,visiblity .3s ease
}

.ld-sf--is-initialized {
    opacity: 1!important;
    visibility: visible!important
}

.ld-sf--size-xs [type=email],.ld-sf--size-xs [type=submit],.ld-sf--size-xs [type=text] {
    height: 45px
}

.ld-sf--size-sm [type=email],.ld-sf--size-sm [type=submit],.ld-sf--size-sm [type=text] {
    height: 50px
}

.ld-sf--size-md [type=email],.ld-sf--size-md [type=submit],.ld-sf--size-md [type=text] {
    height: 60px
}

.ld-sf--size-lg [type=email],.ld-sf--size-lg [type=text] {
    height: 65px
}

.ld-sf--size-lg [type=submit] {
    height: 60px
}

.ld-sf--size-xl [type=email],.ld-sf--size-xl [type=text] {
    height: 70px
}

.ld-sf--size-xl [type=submit] {
    height: 65px
}

.ld-sf--input-solid [type=email],.ld-sf--input-solid [type=text] {
    border: none;
    background-color: #fff;
    color: #000
}

.ld-sf--input-underlined [type=email],.ld-sf--input-underlined [type=text] {
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-bottom-width: 1px;
    border-left: none;
    border-radius: 0!important
}

.ld-sf--input-underlined [type=email]:focus,.ld-sf--input-underlined [type=text]:focus {
    background-color: transparent
}

html:not([dir=rtl]) .ld-sf--input-underlined [type=email],html:not([dir=rtl]) .ld-sf--input-underlined [type=text] {
    padding-left: 0!important
}

.ld-sf--button-inside [type=submit] {
    position: absolute;
    bottom: 0;
    right: 0;
    min-height: 100%;
    max-height: 100%;
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important
}

.ld-sf--button-inside label~[type=submit] {
    min-height: 0
}

.ld-sf--button-inside.ld-sf--button-bordered [type=submit] {
    bottom: 1px;
    height: calc(100% - 2px);
    min-height: calc(100% - 2px);
    border-top: none;
    border-bottom: none;
    border-right: none
}

.ld-sf--button-inside.ld-sf--button-bordered .submit-icon,.ld-sf--button-inside.ld-sf--button-bordered .submit-text {
    margin-top: 0
}

.ld-sf--button-inside.ld-sf--button-bordered.ld-sf--border-thick [type=submit] {
    bottom: 2px;
    height: calc(100% - 4px);
    min-height: calc(100% - 4px)
}

.ld-sf--button-inside.ld-sf--button-bordered.ld-sf--border-thicker [type=submit] {
    bottom: 3px;
    height: calc(100% - 6px);
    min-height: calc(100% - 6px)
}

.ld-sf--button-inside.ld-sf--button-naked [type=submit] {
    padding-right: 1em
}

.ld-sf--button-inside.button-shrinked [type=submit] {
    bottom: 14%;
    right: 12px;
    max-height: 72%;
    min-height: 72%;
    height: 72%
}

.ld-sf--button-inline form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.ld-sf--button-inline [type=submit],.ld-sf--button-inline p {
    vertical-align: bottom
}

.ld-sf--button-inline p {
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0;
    padding-right: 20px
}

.ld-sf--button-inline [type=email],.ld-sf--button-inline [type=text] {
    display: inline-block
}

.ld-sf--button-block [type=submit] {
    width: 100%
}

.ld-sf--button-bordered [type=submit] {
    background-color: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary)
}

.ld-sf--button-naked [type=submit] {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border: none;
    color: var(--color-primary)
}

.ld-sf--button-underlined [type=submit] {
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid var(--color-primary);
    color: var(--color-primary)
}

.ld-sf--input-shadow [type=email],.ld-sf--input-shadow [type=text] {
    -webkit-box-shadow: 0 16px 50px rgba(0,0,0,.07);
    box-shadow: 0 16px 50px rgba(0,0,0,.07)
}

.ld-sf--input-shadow [type=email]:focus,.ld-sf--input-shadow [type=text]:focus {
    -webkit-box-shadow: 0 8px 30px rgba(0,0,0,.12);
    box-shadow: 0 8px 30px rgba(0,0,0,.12)
}

.ld-sf--input-inner-shadow [type=email],.ld-sf--input-inner-shadow [type=text] {
    -webkit-box-shadow: 0 0 7px rgba(0,0,0,.1) inset;
    box-shadow: 0 0 7px rgba(0,0,0,.1) inset
}

.ld-sf--input-inner-shadow [type=email]:focus,.ld-sf--input-inner-shadow [type=text]:focus {
    -webkit-box-shadow: 0 0 2px rgba(0,0,0,.1) inset;
    box-shadow: 0 0 2px rgba(0,0,0,.1) inset
}

.ld-sf--sharp [type=email],.ld-sf--sharp [type=submit],.ld-sf--sharp [type=text] {
    border-radius: 0
}

.ld-sf--round [type=email],.ld-sf--round [type=submit],.ld-sf--round [type=text] {
    border-radius: 4px
}

.ld-sf--circle [type=email],.ld-sf--circle [type=submit],.ld-sf--circle [type=text] {
    border-radius: 50em
}

.ld-sf--border-thin [type=email],.ld-sf--border-thin [type=submit],.ld-sf--border-thin [type=text] {
    border-width: 1px
}

.ld-sf--border-thin .submit-icon,.ld-sf--border-thin .submit-text {
    margin-top: -1px
}

.ld-sf--border-thick [type=email],.ld-sf--border-thick [type=submit],.ld-sf--border-thick [type=text] {
    border-width: 2px
}

.ld-sf--border-thick .submit-icon,.ld-sf--border-thick .submit-text {
    margin-top: -2px
}

.ld-sf--border-thicker [type=email],.ld-sf--border-thicker [type=submit],.ld-sf--border-thicker [type=text] {
    border-width: 3px
}

.ld-sf--border-thicker .submit-icon,.ld-sf--border-thicker .submit-text {
    margin-top: -3px
}

.ld-sf--button-hide-label [type=submit] .submit-text {
    display: none!important
}

.ld-sf--button-hide-label [type=submit] .submit-icon {
    margin-left: 0!important;
    margin-right: 0!important
}

.ld-sf--button-hidden [type=submit] {
    display: none!important
}

.ld_sf_form {
    position: relative
}

.ld-sf-spinner {
    display: inline-block;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50em;
    -webkit-transform: translate(-50%,-50%) scale(1.5);
    transform: translate(-50%,-50%) scale(1.5);
    color: inherit;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition-property: opacity,visibility,-webkit-transform;
    transition-property: opacity,visibility,-webkit-transform;
    transition-property: opacity,visibility,transform;
    transition-property: opacity,visibility,transform,-webkit-transform;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.ld-sf-spinner span,.ld-sf-spinner:before {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    border: 2px solid
}

.ld-sf-spinner:before {
    content: '';
    opacity: .1
}

.ld-sf-spinner span {
    text-indent: -99999px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-clip-path: polygon(0 0,100% 50%,100% 0,0 0);
    clip-path: polygon(0 0,100% 50%,100% 0,0 0);
    -webkit-animation: formRotate360 1s linear both infinite;
    animation: formRotate360 1s linear both infinite
}

.form-submitting .submit-icon,.form-submitting .submit-text {
    opacity: 0;
    visibility: hidden
}

.form-submitting .ld-sf-spinner {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%,-50%) scale(1);
    transform: translate(-50%,-50%) scale(1)
}

#ld_sf_response p {
    padding: .75em 1.25em;
    border-radius: 3px;
    font-weight: 700;
    color: #000
}

.ld_sf_response-success {
    background-color: rgba(107,228,117,.4);
    color: #1ca13d
}

.ld_sf_response-error {
    background-color: rgba(228,107,107,.4);
    color: #a11c1c
}

.contact-form {
    margin-bottom: 2em
}

.contact-form p {
    margin-bottom: 0;
    position: relative
}

.contact-form .ld-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px
}

.contact-form .ui-selectmenu-button,.contact-form input,.contact-form select,.contact-form textarea {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 50px;
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: 20px;
    position: relative;
    border: 1px solid #d5d6de;
    border-radius: 0;
    background: 0 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #b7b9c4;
    -webkit-transition: all .3s;
    transition: all .3s
}

.contact-form .ui-selectmenu-button::-webkit-input-placeholder,.contact-form input::-webkit-input-placeholder,.contact-form select::-webkit-input-placeholder,.contact-form textarea::-webkit-input-placeholder {
    color: inherit
}

.contact-form .ui-selectmenu-button::-moz-placeholder,.contact-form input::-moz-placeholder,.contact-form select::-moz-placeholder,.contact-form textarea::-moz-placeholder {
    color: inherit
}

.contact-form .ui-selectmenu-button:-moz-placeholder,.contact-form input:-moz-placeholder,.contact-form select:-moz-placeholder,.contact-form textarea:-moz-placeholder {
    color: inherit
}

.contact-form .ui-selectmenu-button:-ms-input-placeholder,.contact-form input:-ms-input-placeholder,.contact-form select:-ms-input-placeholder,.contact-form textarea:-ms-input-placeholder {
    color: inherit
}

.contact-form .ui-selectmenu-button:focus,.contact-form input:focus,.contact-form select:focus,.contact-form textarea:focus {
    outline: 0;
    border-color: #9c9eb1;
    color: #9c9eb1
}

.contact-form input[type=date] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.contact-form button[type=submit],.contact-form input[type=submit] {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
    height: 50px;
    margin-bottom: 0;
    padding: 0 2.8em;
    border: 1px solid var(--color-primary);
    background-color: var(--color-primary);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.contact-form button[type=submit]:hover,.contact-form input[type=submit]:hover {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05),0 2px 2px rgba(0,0,0,.05),0 4px 4px rgba(0,0,0,.05),0 8px 8px rgba(0,0,0,.05),0 16px 16px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05),0 2px 2px rgba(0,0,0,.05),0 4px 4px rgba(0,0,0,.05),0 8px 8px rgba(0,0,0,.05),0 16px 16px rgba(0,0,0,.05)
}

.contact-form input[type=checkbox],.contact-form input[type=radio] {
    display: initial;
    width: auto;
    height: auto
}

.contact-form select {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.contact-form select[multiple] {
    padding: 0;
    height: auto
}

.contact-form select[multiple] option {
    padding: .5em 2em
}

.contact-form label {
    font-weight: inherit;
    margin-right: 5px
}

.contact-form textarea {
    height: auto!important;
    padding-top: 1em;
    padding-bottom: 1em;
    border-radius: 0!important
}

.contact-form i {
    display: inline-block;
    position: absolute;
    top: 50%;
    z-index: 2;
    left: calc(1em + 15px);
    margin-top: -10px;
    pointer-events: none;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.contact-form i~input,.contact-form i~label,.contact-form i~textarea {
    text-indent: 1em
}

.contact-form .ui-selectmenu-button .ui-selectmenu-text {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.contact-form .ui-selectmenu-button .ui-selectmenu-icon {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.contact-form .ui-spinner {
    width: 100%;
    padding: 0;
    border-radius: 0;
    border: none
}

.contact-form input.ui-spinner-input {
    margin: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.contact-form a.ui-spinner-button {
    border: none;
    border-radius: 0;
    background-color: transparent
}

.contact-form a.ui-spinner-button:active {
    background-color: #181b31
}

.contact-form-inputs-sm .ui-selectmenu-button,.contact-form-inputs-sm button[type=submit],.contact-form-inputs-sm input,.contact-form-inputs-sm input[type=submit],.contact-form-inputs-sm select,.contact-form-inputs-sm textarea {
    height: 40px
}

.contact-form-inputs-md .ui-selectmenu-button,.contact-form-inputs-md button[type=submit],.contact-form-inputs-md input,.contact-form-inputs-md input[type=submit],.contact-form-inputs-md select,.contact-form-inputs-md textarea {
    height: 50px
}

.contact-form-inputs-lg .ui-selectmenu-button,.contact-form-inputs-lg input,.contact-form-inputs-lg select,.contact-form-inputs-lg textarea {
    height: 60px;
    margin-bottom: 30px
}

.contact-form-inputs-lg button[type=submit],.contact-form-inputs-lg input[type=submit] {
    height: 60px
}

.contact-form-inputs-underlined .ui-selectmenu-button,.contact-form-inputs-underlined input,.contact-form-inputs-underlined select,.contact-form-inputs-underlined textarea {
    padding-left: 0;
    padding-right: 0;
    border-width: 0 0 1px
}

.contact-form-inputs-underlined i {
    left: 15px
}

.contact-form-inputs-underlined i~input,.contact-form-inputs-underlined i~label,.contact-form-inputs-underlined i~textarea {
    text-indent: 2em
}

.contact-form-inputs-filled .ui-selectmenu-button,.contact-form-inputs-filled input,.contact-form-inputs-filled select,.contact-form-inputs-filled textarea {
    border: none;
    background-color: #fff
}

.contact-form-inputs-border-thin .ui-selectmenu-button,.contact-form-inputs-border-thin button[type=submit],.contact-form-inputs-border-thin input,.contact-form-inputs-border-thin input[type=submit],.contact-form-inputs-border-thin select,.contact-form-inputs-border-thin textarea {
    border-width: 1px
}

.contact-form-inputs-border-thick .ui-selectmenu-button,.contact-form-inputs-border-thick button[type=submit],.contact-form-inputs-border-thick input,.contact-form-inputs-border-thick input[type=submit],.contact-form-inputs-border-thick select,.contact-form-inputs-border-thick textarea {
    border-width: 2px
}

.contact-form-inputs-border-thicker .ui-selectmenu-button,.contact-form-inputs-border-thicker button[type=submit],.contact-form-inputs-border-thicker input,.contact-form-inputs-border-thicker input[type=submit],.contact-form-inputs-border-thicker select,.contact-form-inputs-border-thicker textarea {
    border-width: 3px
}

.contact-form-inputs-sharp .ui-selectmenu-button,.contact-form-inputs-sharp button[type=submit],.contact-form-inputs-sharp input,.contact-form-inputs-sharp input[type=submit],.contact-form-inputs-sharp select,.contact-form-inputs-sharp textarea {
    border-radius: 0
}

.contact-form-inputs-round .ui-selectmenu-button,.contact-form-inputs-round button[type=submit],.contact-form-inputs-round input,.contact-form-inputs-round input[type=submit],.contact-form-inputs-round select,.contact-form-inputs-round textarea {
    border-radius: 4px
}

.contact-form-inputs-circle .ui-selectmenu-button,.contact-form-inputs-circle input,.contact-form-inputs-circle select,.contact-form-inputs-circle textarea {
    border-radius: 50em
}

.contact-form-inputs-circle textarea {
    border-radius: 15px!important;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0
}

.contact-form-inputs-circle textarea[rows="1"] {
    border-radius: 50em!important
}

.contact-form-inputs-circle button[type=submit],.contact-form-inputs-circle input[type=submit] {
    border-radius: 50em
}

.contact-form-inputs-circle .ui-spinner-button {
    right: 1em
}

.contact-form-inputs-circle:not(.contact-form-inputs-filled) textarea {
    padding-left: 0;
    padding-right: 0
}

.contact-form-inputs-underlined .ui-selectmenu-button,.contact-form-inputs-underlined input,.contact-form-inputs-underlined select,.contact-form-inputs-underlined textarea {
    border-radius: 0
}

.contact-form-button-sm button[type=submit],.contact-form-button-sm input[type=submit] {
    height: 40px
}

.contact-form-button-md button[type=submit],.contact-form-button-md input[type=submit] {
    height: 50px
}

.contact-form-button-lg button[type=submit],.contact-form-button-lg input[type=submit] {
    height: 60px;
    margin-bottom: 30px
}

.contact-form-button-lg i {
    margin-top: -15px
}

.contact-form-button-block button[type=submit],.contact-form-button-block input[type=submit] {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
}

.contact-form-button-underlined button[type=submit],.contact-form-button-underlined input[type=submit] {
    padding-left: 0;
    padding-right: 0;
    border-width: 0 0 1px;
    background-color: transparent;
    color: var(--color-primary)
}

.contact-form-button-underlined button[type=submit]:hover,.contact-form-button-underlined input[type=submit]:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    transform: none
}

.contact-form-button-bordered button[type=submit],.contact-form-button-bordered input[type=submit] {
    color: var(--color-primary);
    background-color: transparent
}

.contact-form-button-bordered button[type=submit]:hover,.contact-form-button-bordered input[type=submit]:hover {
    background-color: var(--color-primary);
    color: #fff
}

.contact-form-button-border-thin button[type=submit],.contact-form-button-border-thin input[type=submit] {
    border-width: 1px
}

.contact-form-button-border-thick button[type=submit],.contact-form-button-border-thick input[type=submit] {
    border-width: 2px
}

.contact-form-button-border-thicker button[type=submit],.contact-form-button-border-thicker input[type=submit] {
    border-width: 3px
}

.contact-form-button-sharp button[type=submit],.contact-form-button-sharp input[type=submit] {
    border-radius: 0
}

.contact-form-button-round button[type=submit],.contact-form-button-round input[type=submit] {
    border-radius: 4px
}

.contact-form-button-circle button[type=submit],.contact-form-button-circle input[type=submit] {
    border-radius: 50em
}

.liquid-cl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: calc(100% - 20px);
    margin: 0;
    position: absolute;
    top: 0;
    left: calc(15px + 2em);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: none;
    -webkit-transition: all .3s;
    transition: all .3s
}

.liquid-cl:after {
    content: attr(data-label);
    position: absolute;
    bottom: 100%;
    left: 0;
    opacity: 0;
    font-size: 75%;
    -webkit-transition: inherit;
    transition: inherit
}

.liquid-cl span {
    -webkit-transition: inherit;
    transition: inherit
}

textarea~.liquid-cl {
    padding-top: 1em;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.contact-form-inputs-lg .liquid-cl {
    height: calc(100% - 30px)
}

.contact-form-inputs-underlined .liquid-cl {
    left: 15px
}

.input-filled .liquid-cl span,.input-focused .liquid-cl span,input:focus~.liquid-cl span,textarea:focus~.liquid-cl span {
    opacity: 0
}

.liquid-cl-1:after {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px)
}

.input-filled .liquid-cl-1 span,.input-focused .liquid-cl-1 span,input:focus~.liquid-cl-1 span,textarea:focus~.liquid-cl-1 span {
    -webkit-transform: translateX(10px);
    transform: translateX(10px)
}

.input-filled .liquid-cl-1:after,.input-focused .liquid-cl-1:after,input:focus~.liquid-cl-1:after,textarea:focus~.liquid-cl-1:after {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: .13s;
    transition-delay: .13s
}

.liquid-cl-2 {
    -webkit-transition-duration: .25s;
    transition-duration: .25s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.liquid-cl-2:after {
    -webkit-transform: translateY(10px) rotateX(30deg);
    transform: translateY(10px) rotateX(30deg)
}

.input-filled .liquid-cl-2 span,.input-focused .liquid-cl-2 span,input:focus~.liquid-cl-2 span,textarea:focus~.liquid-cl-2 span {
    -webkit-transform: translateY(10px) rotateX(-30deg);
    transform: translateY(10px) rotateX(-30deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in
}

.input-filled .liquid-cl-2:after,.input-focused .liquid-cl-2:after,input:focus~.liquid-cl-2:after,textarea:focus~.liquid-cl-2:after {
    opacity: 1;
    -webkit-transform: translateY(0) rotateX(0);
    transform: translateY(0) rotateX(0);
    -webkit-transition-delay: .13s;
    transition-delay: .13s
}

.liquid-cl-3:after {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all .25s ease;
    transition: all .25s ease
}

.input-filled .liquid-cl-3 span,.input-focused .liquid-cl-3 span,input:focus~.liquid-cl-3 span,textarea:focus~.liquid-cl-3 span {
    -webkit-transform: translateY(-75%);
    transform: translateY(-75%);
    -webkit-transition: all .25s ease;
    transition: all .25s ease
}

.input-filled .liquid-cl-3:after,.input-focused .liquid-cl-3:after,input:focus~.liquid-cl-3:after,textarea:focus~.liquid-cl-3:after {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: .15s;
    transition-delay: .15s
}

.contact-form-result {
    margin-top: 1.5em
}

.form-message {
    padding: .5em 1.5em;
    border-radius: 3px;
    font-weight: 700
}

.form-success {
    background-color: rgba(107,228,117,.4);
    color: #1ca13d
}

.form-error {
    background-color: rgba(228,107,107,.4);
    color: #a11c1c
}

.fancy-title {
    margin-bottom: 2.5em;
    position: relative
}

.fancy-title>:empty:not(br):not(hr):not(i.line) {
    display: none
}

.fancy-title h2,.fancy-title h3,.fancy-title h4 {
    margin: .25em 0
}

.fancy-title i {
    margin-right: .27em
}

.fancy-title>:first-child {
    margin-top: 0
}

.fancy-title>:last-child {
    margin-bottom: 0
}

.fancy-title .text-uppercase {
    letter-spacing: .1em
}

.fancy-title i.line {
    display: inline-block;
    width: 1.138em;
    height: 3px;
    vertical-align: middle;
    background-color: var(--color-primary)
}

.fancy-title .underlined {
    display: inline-block;
    padding-bottom: 1.25em;
    position: relative;
    clear: both
}

.fancy-title .underlined:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary)
}

.fancy-title.text-center .underlined:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.fancy-title.text-right .underlined:after {
    left: auto;
    right: 0
}

.fancy-title-underlined {
    padding-bottom: 2.5em
}

.fancy-title-underlined:after {
    content: '';
    display: inline-block;
    width: 50px;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary)
}

.fancy-title-underlined.text-center:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.fancy-title-underlined.text-right:after {
    left: auto;
    right: 0
}

.fancy-title .lined-alt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.fancy-title .line-alt {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    height: 1px;
    position: relative;
    background-color: #E5E5E5;
    border-radius: 50em;
    margin-right: 2em
}

.fancy-title .line-alt:last-child {
    margin-right: 0;
    margin-left: 2em
}

.fancy-title .line-alt-doubled {
    top: .3em
}

.fancy-title .line-alt-doubled:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -.6em;
    left: 0;
    border-radius: inherit;
    background-color: inherit
}

@media screen and (min-width: 1200px) {
    .fancy-title .pull-to-left {
        margin-left:-2.3076923077em
    }
}

.pricing-table {
    margin-bottom: 2em;
    position: relative;
    text-align: center
}

.pricing-table ul {
    padding: 0;
    margin: 0;
    list-style: none
}

.pricing-table ul li {
    display: block
}

.pricing-table .btn {
    font-size: 14px;
    font-weight: 600
}

.pricing-table .pricing {
    margin: 0
}

.pricing-table.featured {
    z-index: 2
}

.pricing-table-footer:empty {
    display: none
}

.pricing-table-default {
    border: 1px solid #e4e4eb
}

.pricing-table-default .pricing-table-header {
    padding: 16px;
    background-color: #f0f2f4;
    border-bottom: 1px solid #fff;
    border-color: inherit
}

.pricing-table-default h5 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    line-height: 2em;
    font-weight: 700;
    letter-spacing: -.01em;
    text-transform: uppercase;
    color: #181a23
}

.pricing-table-default h5 small {
    display: block;
    font-size: 50%;
    font-weight: 600;
    letter-spacing: .1em;
    color: var(--color-primary);
    margin-bottom: 10px
}

.pricing-table-default .pricing-table-body {
    padding: 24px 60px;
    border-color: inherit
}

.pricing-table-default ul {
    color: #828593;
    border-color: inherit
}

.pricing-table-default ul li {
    padding: 1.333em 0;
    font-size: 15px;
    line-height: 1.6em;
    border-bottom: 1px solid #fff;
    border-color: inherit
}

.pricing-table-default ul strong {
    display: block;
    font-size: 1.0714285714286em;
    color: #181a23
}

.pricing-table-default .btn {
    margin-bottom: 50px
}

.pricing-table-default .pricing {
    margin-bottom: .666em;
    font-size: 30px;
    font-weight: 700;
    color: var(--color-primary)
}

.pricing-table-default .pricing sup {
    font-size: 60%;
    font-weight: 400
}

.pricing-table-colorful {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 3px;
    background-color: #fff;
    -webkit-box-shadow: 14px 14px 70px rgba(0,0,0,.07);
    box-shadow: 14px 14px 70px rgba(0,0,0,.07)
}

.pricing-table-colorful .pricing-table-header {
    padding: 18px 50px;
    position: relative
}

.pricing-table-colorful .pricing-table-header:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 3px 3px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .1;
    z-index: 0;
    background-color: var(--color-primary)
}

.pricing-table-colorful h5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin: 7px 0;
    position: relative;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
    color: #181b31;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.pricing-table-colorful h5 small {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    font-size: 1.875em;
    color: var(--color-primary)
}

.pricing-table-colorful .pricing-table-body {
    padding: 30px 0
}

.pricing-table-colorful ul {
    color: #808291
}

.pricing-table-colorful ul li {
    border-bottom: 1px solid #e7eaef;
    padding: 1em
}

.pricing-table-colorful ul li:last-child {
    border: none
}

.pricing-table-colorful ul strong {
    color: #181b31
}

.pricing-table-colorful .pricing-table-footer {
    margin-bottom: 42px
}

.pricing-table-colorful .pricing-table-footer:after,.pricing-table-colorful .pricing-table-footer:before {
    content: '';
    display: table
}

.pricing-table-colorful .pricing-table-footer:after {
    clear: both
}

.pricing-table-colorful .featured-tag {
    color: var(--color-primary)
}

.pricing-table-colorful .featured-tag i,.pricing-table-colorful .featured-tag span {
    display: block
}

.pricing-table-colorful .featured-tag i {
    font-size: 30px;
    margin-bottom: .25em
}

.pricing-table-colorful.color-green .pricing-table-header:before {
    background-color: #46d664
}

.pricing-table-colorful.color-green .featured-tag,.pricing-table-colorful.color-green h5 small {
    color: #46d664
}

.pricing-table-colorful.color-red .pricing-table-header:before {
    background-color: #fd623c
}

.pricing-table-colorful.color-red .featured-tag,.pricing-table-colorful.color-red h5 small {
    color: #fd623c
}

.pricing-table-colorful.color-blue .pricing-table-header:before {
    background-color: #02a0e5
}

.pricing-table-colorful.color-blue .featured-tag,.pricing-table-colorful.color-blue h5 small {
    color: #02a0e5
}

.pricing-table-colorful.first {
    margin-left: 0
}

.pricing-table-colorful.last {
    margin-right: 0
}

.pricing-table-colorful.featured {
    margin-top: -50px
}

.pricing-table-modern {
    padding: 50px 45px 55px;
    color: #5E6575
}

.pricing-table-modern:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: auto
}

.pricing-table-modern .pricing-table-body,.pricing-table-modern .pricing-table-footer,.pricing-table-modern .pricing-table-header {
    position: relative;
    z-index: 2
}

.pricing-table-modern h5 {
    margin-top: 0;
    margin-bottom: 1.35em;
    color: #32325C;
    font-size: 24px;
    font-weight: 700
}

.pricing-table-modern .pricing {
    font-size: 72px;
    color: var(--color-primary);
    line-height: 1
}

.pricing-table-modern .pricing small {
    display: block;
    margin-top: 1em;
    font-size: .2222222222em;
    color: #5B6C94
}

.pricing-table-modern .pricing-table-body {
    padding: 50px 15px 0;
    font-size: 16px
}

.pricing-table-modern ul {
    font-size: 16px;
    line-height: 1.5em
}

.pricing-table-modern ul li:not(:last-child) {
    margin-bottom: 1.45em
}

.pricing-table-modern .pricing-table-footer {
    margin-top: 60px
}

.pricing-table-modern.featured {
    color: #fff
}

.pricing-table-modern.featured:before {
    background: -webkit-gradient(linear,left top,left bottom,from(#0856F7),to(#1DFFDD));
    background: linear-gradient(to bottom,#0856F7 0,#1DFFDD 100%)
}

.pricing-table-modern.featured .pricing,.pricing-table-modern.featured .pricing small,.pricing-table-modern.featured h5 {
    color: inherit
}

.pricing-table-modern.featured[class*=bg-]:before {
    background: inherit
}

.pricing-table-minimal {
    padding: 45px 50px 50px;
    border-radius: 10px;
    background-color: #fff;
    color: #2D3136
}

.pricing-table-minimal .pricing-table-header {
    text-align: center
}

.pricing-table-minimal .pricing-table-header h5 {
    display: inline-block;
    padding: .6em 1.2em;
    margin: 0;
    background-color: #F1F3F7;
    border-radius: 50em;
    font-size: 15px;
    line-height: 1.5em;
    font-weight: 400;
    color: #2D3136
}

.pricing-table-minimal .pricing {
    margin: 0;
    font-size: 60px;
    line-height: 1em
}

.pricing-table-minimal .pricing>span {
    font-size: .35em
}

.pricing-table-minimal .pricing small {
    font-size: .25em
}

.pricing-table-minimal .pricing-table-body {
    margin-top: 35px;
    font-size: 16px
}

.pricing-table-minimal .pricing-table-body li {
    margin-bottom: .625em
}

.pricing-table-minimal .pricing-table-footer {
    margin-top: 30px
}

.pricing-table-agency {
    padding-top: 6rem;
    padding-bottom: 4.5rem;
    background-color: #fff;
    -webkit-box-shadow: 0 40px 125px rgba(0,0,0,.07);
    box-shadow: 0 40px 125px rgba(0,0,0,.07)
}

.pricing-table-agency .pricing-table-featured-label {
    padding: 5px 30px;
    border-radius: 0 0 50em 50em;
    position: absolute;
    top: 0;
    left: 50%;
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 16px;
    line-height: 1.5em;
    color: #fff
}

.pricing-table-agency .pricing-table-header {
    padding: 0 1.5rem;
    margin-bottom: 1.5rem
}

.pricing-table-agency .pricing-table-header h5 {
    margin: 0;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 400
}

.pricing-table-agency .pricing {
    font-size: 56px;
    line-height: 1.5em;
    font-weight: 700;
    color: #000
}

.pricing-table-agency .pricing small,.pricing-table-agency .pricing sup {
    font-size: .3571428571em;
    font-weight: 400
}

.pricing-table-agency .pricing sup {
    top: -1.25em;
    margin-right: .2em
}

.pricing-table-agency .pricing small {
    opacity: .6
}

.pricing-table-agency .pricing small:before {
    content: '/'
}

.pricing-table-agency .pricing-table-body {
    padding: 0 5rem;
    margin-bottom: 1.5rem
}

.pricing-table-agency .pricing-table-body li {
    padding: 1.5em 1em;
    border-top: 1px solid #ebeff1;
    font-size: 14px;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: .05em;
    color: #666
}

.pricing-table-agency .pricing-table-footer {
    padding: 0 1.5rem
}

.testimonial {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    position: relative;
    margin-bottom: 2em;
    font-size: 1em
}

.testimonial .testimonial-info,.testimonial .testimonial-quote-mark {
    position: relative;
    z-index: 2
}

.testimonial blockquote {
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    z-index: 2;
    font-size: 1em;
    font-style: inherit
}

.testimonial blockquote strong {
    font-weight: 600
}

.testimonial blockquote small {
    font-size: .83333333333333em
}

.testimonial blockquote small:before {
    content: none
}

.testimonial .testimonial-quote {
    margin-bottom: 1.25em;
    position: relative;
    font-size: 20px;
    line-height: 1.875em
}

.testimonial .testimonial-quote h5 {
    margin-bottom: 1em
}

.testimonial .star-rating,.testimonial time {
    font-size: 14px;
    color: #a7a9b8
}

.testimonial>time {
    margin-top: 2.25em
}

.testimonial time.size-sm {
    font-size: 11px
}

.testimonial .testimonial-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

.testimonial .testimonial-details h5,.testimonial .testimonial-details h6 {
    margin: 0;
    line-height: 1.5em
}

.testimonial .testimonial-details h5 {
    font-size: 18px;
    color: #16171e
}

.testimonial .testimonial-details h6 {
    font-size: 16px;
    color: #a7a9b8
}

.testimonial .testimonial-details h6.sm {
    font-size: 10px
}

.testimonial .testimonial-details h6.md {
    font-size: 12px
}

.testimonial .testimonial-details .star-rating,.testimonial .testimonial-details time {
    margin-left: auto
}

.testimonial .testimonial-details .star-rating:first-child,.testimonial .testimonial-details time:first-child {
    margin-left: 0
}

.testimonial .avatar {
    width: 70px;
    margin-right: 20px;
    border-radius: 50em;
    overflow: hidden;
    position: relative;
    z-index: 2
}

.testimonial .avatar img {
    width: 100%
}

.testimonial .testimonial-quote-mark {
    margin-right: 15px
}

.testimonial .testimonial-quote-mark svg {
    fill: var(--color-primary)
}

.testimonials-quotes .testimonial-quote>blockquote>:first-child {
    margin-top: 0
}

.testimonials-quotes .testimonial-quote>blockquote>:last-child {
    margin-bottom: 0
}

.testimonial-fill-onhover,.testimonial-quote-filled .testimonial-quote,.testimonial-quote-shadowed .testimonial-quote,.testimonial-whole-filled,.testimonial-whole-shadowed {
    padding: 7.5%
}

.testimonial-quote-filled .testimonial-details,.testimonial-quote-shadowed .testimonial-details {
    margin-left: 60px;
    margin-right: 60px
}

.testimonial-whole-filled {
    background-color: #fff;
    border-radius: 5px
}

.testimonial-quote-filled .testimonial-quote {
    background-color: #fff
}

.testimonial-quote-filled .testimonial-quote:after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: -10px;
    left: calc(60px + 35px - 14px);
    z-index: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: inherit
}

.testimonial-quote-filled.testimonial-avatar-lg .testimonial-quote:after {
    left: calc(60px + 40px - 14px)
}

.testimonial-quote-filled.testimonial-avatar-sm .testimonial-quote:after {
    left: calc(60px + 30px - 14px)
}

.testimonial-quote-filled.testimonial-details-top .testimonial-quote:after {
    bottom: auto;
    top: -10px
}

.testimonial-whole-shadowed {
    -webkit-box-shadow: 0 18px 50px rgba(0,0,0,.07);
    box-shadow: 0 18px 50px rgba(0,0,0,.07);
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    transition: box-shadow .3s;
    transition: box-shadow .3s,-webkit-box-shadow .3s
}

.flickity-viewport .testimonial-whole-shadowed {
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.15);
    box-shadow: 0 0 0 rgba(0,0,0,.15);
    margin-bottom: 50px
}

.flickity-viewport .is-selected .testimonial-whole-shadowed {
    -webkit-box-shadow: 0 18px 50px rgba(0,0,0,.07);
    box-shadow: 0 18px 50px rgba(0,0,0,.07)
}

.testimonial-whole-shadowed-alt {
    padding-left: 50px;
    padding-right: 50px;
    -webkit-box-shadow: 0 20px 40px rgba(0,0,0,.14);
    box-shadow: 0 20px 40px rgba(0,0,0,.14)
}

.flickity-viewport .testimonial-whole-shadowed-alt {
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.3);
    box-shadow: 0 0 0 rgba(0,0,0,.3);
    margin-bottom: 70px
}

.flickity-viewport .is-selected .testimonial-whole-shadowed-alt {
    -webkit-box-shadow: 0 20px 40px rgba(0,0,0,.14);
    box-shadow: 0 20px 40px rgba(0,0,0,.14)
}

.testimonial-quote-shadowed .testimonial-quote {
    border-radius: 5px;
    -webkit-box-shadow: 0 18px 50px rgba(0,0,0,.07);
    box-shadow: 0 18px 50px rgba(0,0,0,.07)
}

.testimonial-quote-shadowed.testimonial-details-top .testimonial-quote {
    -webkit-box-shadow: 0 -9px 50px rgba(0,0,0,.07);
    box-shadow: 0 -9px 50px rgba(0,0,0,.07)
}

.testimonial-avatar-shadowed .avatar {
    -webkit-box-shadow: 0 18px 50px rgba(0,0,0,.1);
    box-shadow: 0 18px 50px rgba(0,0,0,.1)
}

.testimonial-fill-onhover:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: var(--color-primary);
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.testimonial-fill-onhover>* {
    z-index: 2
}

.testimonial-fill-onhover .testimonial-quote-mark svg {
    -webkit-transition: fill .3s;
    transition: fill .3s
}

.testimonial-fill-onhover .testimonial-info h5,.testimonial-fill-onhover .testimonial-info h6,.testimonial-fill-onhover .testimonial-quote {
    -webkit-transition: color .3s;
    transition: color .3s
}

.is-selected .testimonial-fill-onhover {
    color: #fff
}

.is-selected .testimonial-fill-onhover:after {
    opacity: 1
}

.is-selected .testimonial-fill-onhover .testimonial-quote-mark svg {
    fill: #fff
}

.is-selected .testimonial-fill-onhover .star-rating,.is-selected .testimonial-fill-onhover .star-rating li,.is-selected .testimonial-fill-onhover .testimonial-info h5,.is-selected .testimonial-fill-onhover .testimonial-info h6,.is-selected .testimonial-fill-onhover .testimonial-quote,.is-selected .testimonial-fill-onhover .testimonial-quote p span {
    color: inherit
}

.testimonial-quote-indented .testimonial-quote,.testimonial-quote-indented>time {
    margin-left: 90px
}

.testimonial-quote-indented.text-center .testimonial-quote,.testimonial-quote-indented.text-center>time {
    margin-left: 0;
    margin-right: 0
}

.testimonial-quote-indented.text-right .testimonial-quote,.testimonial-quote-indented.text-right>time {
    margin-left: 0!important;
    margin-right: 90px
}

.testimonial-xl .testimonial-quote {
    font-size: 36px;
    line-height: 1.25em
}

.testimonial-lg .testimonial-quote {
    font-size: 30px;
    line-height: 1.6em
}

.testimonial-sm .testimonial-quote {
    font-size: 18px;
    line-height: 1.666em
}

.testimonial-xs .testimonial-quote {
    font-size: 16px;
    line-height: 1.875em
}

.testimonial-details-xl .testimonial-details h5 {
    font-size: 24px
}

.testimonial-details-xl .testimonial-details h6 {
    font-size: 12px
}

.testimonial-details-lg .testimonial-details h5 {
    font-size: 20px
}

.testimonial-details-lg .testimonial-details h6 {
    font-size: 14px
}

.testimonial-details-sm .testimonial-details h5 {
    font-size: 16px
}

.testimonial-details-sm .testimonial-details h6 {
    font-size: 14px
}

.testimonial-avatar-xl .avatar {
    width: 86px
}

.testimonial-avatar-xl.testimonial-quote-indented .testimonial-quote,.testimonial-avatar-xl.testimonial-quote-indented>time {
    margin-left: 106px
}

.testimonial-avatar-xl.testimonial-quote-indented.text-right .testimonial-quote,.testimonial-avatar-xl.testimonial-quote-indented.text-right>time {
    margin-right: 106px
}

.testimonial-avatar-lg .avatar {
    width: 80px
}

.testimonial-avatar-lg.testimonial-quote-indented .testimonial-quote,.testimonial-avatar-lg.testimonial-quote-indented>time {
    margin-left: 100px
}

.testimonial-avatar-lg.testimonial-quote-indented.text-right .testimonial-quote,.testimonial-avatar-lg.testimonial-quote-indented.text-right>time {
    margin-right: 100px
}

.testimonial-avatar-sm .avatar {
    width: 60px
}

.testimonial-avatar-sm.testimonial-quote-indented .testimonial-quote,.testimonial-avatar-sm.testimonial-quote-indented>time {
    margin-left: 80px
}

.testimonial-avatar-sm.testimonial-quote-indented.text-right .testimonial-quote,.testimonial-avatar-sm.testimonial-quote-indented.text-right>time {
    margin-right: 80px
}

.testimonial-avatar-topleft,.testimonial-avatar-topright {
    margin-top: 35px
}

.testimonial-avatar-topleft .avatar,.testimonial-avatar-topright .avatar {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0!important
}

.testimonial-avatar-topleft.testimonial-avatar-lg,.testimonial-avatar-topright.testimonial-avatar-lg {
    margin-top: 40px
}

.testimonial-avatar-topleft.testimonial-avatar-sm,.testimonial-avatar-topright.testimonial-avatar-sm {
    margin-top: 30px
}

.testimonial-avatar-topleft .avatar {
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}

.testimonial-avatar-topright .avatar {
    left: auto;
    right: 0;
    -webkit-transform: translateX(50%) translateY(-50%);
    transform: translateX(50%) translateY(-50%)
}

.testimonial-avatar-topcenter .testimonial-details {
    margin-top: 1em
}

.testimonial-avatar-topcenter .avatar {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}

.testimonial-details-top .testimonial-quote {
    margin-bottom: 0;
    margin-top: 1.25em
}

.testimonial-details-top .testimonial-details {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
}

.testimonial-info-inline .testimonial-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.testimonial-info-inline .testimonial-info h5:after {
    content: '/';
    margin-left: .25em;
    margin-right: .25em
}

.testimonial.text-center .star-rating,.testimonial.text-center time {
    margin: 10px auto 0!important
}

.testimonial.text-center .testimonial-details {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.testimonial.text-center .avatar {
    margin: 0 0 10px
}

.testimonial.text-center .testimonial-quote-mark {
    margin-right: 0
}

.testimonial.text-right .testimonial-quote-mark {
    margin-left: 15px;
    margin-right: 0
}

.testimonial.text-right .testimonial-details {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.testimonial.text-right .testimonial-details .star-rating,.testimonial.text-right .testimonial-details time {
    margin-left: 0;
    margin-right: auto
}

.testimonial.text-right .avatar {
    margin-right: 0;
    margin-left: 20px
}

.testimonial-quote-filled .testimonial-quote,.testimonial-quote-filled>time,.testimonial-quote-shadowed .testimonial-quote,.testimonial-quote-shadowed>time {
    margin-left: 0!important;
    margin-right: 0!important
}

.testimonial-quote-filled.text-right .testimonial-details,.testimonial-quote-shadowed.text-right .testimonial-details {
    margin-left: 0
}

.testimonial-quote-filled.text-center .testimonial-details,.testimonial-quote-shadowed.text-center .testimonial-details {
    margin-left: 0;
    margin-right: 0
}

.testimonial-quote-filled.text-center .testimonial-quote {
    text-align: left
}

.testimonial-quote-filled.text-center .testimonial-quote:after {
    left: 50%;
    margin-left: -14px
}

.testimonial-quote-filled.text-right .testimonial-quote:after {
    left: auto;
    right: calc(60px + 35px - 14px)
}

.testimonial-quote-filled.text-right.testimonial-avatar-lg .testimonial-quote:after {
    right: calc(60px + 40px - 14px)
}

.testimonial-quote-filled.text-right.testimonial-avatar-sm .testimonial-quote:after {
    right: calc(60px + 30px - 14px)
}

.testimonials-details {
    width: 75%;
    margin: 0 auto
}

.testimonials-details .flickity-prev-next-button {
    position: absolute
}

.testimonials-details .flickity-prev-next-button.previous {
    left: -45px;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.testimonials-details .flickity-prev-next-button.next {
    right: -45px;
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.testimonials-details .flickity-slider>div {
    padding-left: 6px;
    padding-right: 6px
}

.testimonials-details .testimonial {
    margin: 0
}

.carousel-nav-bottom .testimonials-details {
    width: 100%
}

.testimonials-details .flickity-viewport {
    margin: 0
}

.testimonials-details.w-100 {
    width: 100%
}

.testimonials-quote-only .testimonial-quote {
    margin-bottom: .75em
}

.testimonials-quote-only .flickity-viewport {
    margin: 0
}

.testimonials-quote-only .flickity-prev-next-button {
    top: 150%
}

.testimonials-details-only {
    opacity: .5;
    cursor: pointer
}

.testimonials-details-only .avatar {
    margin-left: 0!important;
    margin-right: 0!important
}

.testimonials-details-only .testimonial-details,.testimonials-details-only .testimonial-info {
    width: 100%
}

.testimonials-details-only:not(.text-center) .testimonial-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.testimonials-details-only:not(.text-center) .testimonial-details h5,.testimonials-details-only:not(.text-center) .testimonial-details h6 {
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all .38s;
    transition: all .38s
}

.testimonials-details-only:not(.text-center) .testimonial-details h5 {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.testimonials-details-only:not(.text-center) .testimonial-details h6 {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.is-nav-selected .testimonials-details-only {
    opacity: 1
}

.is-nav-selected .testimonials-details-only .testimonial-details h5,.is-nav-selected .testimonials-details-only .testimonial-details h6 {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.is-nav-selected .testimonials-details-only .testimonial-details h5 {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.is-nav-selected .testimonials-details-only .testimonial-details h6 {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.carousel-items>.carousel-item {
    float: left;
    position: absolute;
    top: 0;
    left: 0
}

.carousel-items>.carousel-item:first-of-type {
    position: relative;
    top: auto;
    left: auto
}

.carousel-items p:empty {
    display: none
}

.flickity-equal-cells .flickity-slider>* {
    min-height: 100%
}

.carousel-item {
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.carousel-item .carousel-item {
    width: 100%!important;
    padding-left: 0!important;
    padding-right: 0!important
}

.carousel-item-inner {
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    transition: box-shadow .3s;
    transition: box-shadow .3s,-webkit-box-shadow .3s;
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.carousel-item-inner .wpb_single_image:only-child .vc_figure,.carousel-item-inner .wpb_single_image:only-child .vc_single_image-wrapper,.carousel-item-inner .wpb_single_image:only-child img {
    width: 100%
}

.flickity-prev-next-button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 40px;
    height: 40px;
    position: relative;
    top: auto;
    left: auto;
    padding: 0;
    border-radius: 0;
    border: 1px solid transparent;
    background-color: transparent;
    font-size: 22px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all .3s;
    transition: all .3s
}

.flickity-prev-next-button svg {
    width: auto;
    height: auto;
    position: relative;
    top: auto;
    left: auto;
    stroke: #181b30;
    stroke-width: 1px;
    -webkit-transition: stroke .3s;
    transition: stroke .3s
}

.flickity-prev-next-button i {
    position: relative;
    z-index: 1
}

.flickity-prev-next-button.previous {
    margin-right: 5px;
    left: 0
}

.flickity-prev-next-button.next {
    margin-left: 5px;
    right: 0
}

.flickity-prev-next-button:focus,.flickity-prev-next-button:hover {
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.flickity-prev-next-button:active {
    opacity: 1
}

.carousel-nav {
    position: relative
}

.carousel-nav-floated .carousel-nav,.carousel-nav-floated.carousel-nav {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.carousel-nav-floated .flickity-prev-next-button {
    position: absolute
}

.carousel-nav-center .carousel-nav {
    text-align: center
}

.carousel-nav-left .carousel-nav {
    text-align: left
}

.carousel-nav-right .carousel-nav {
    text-align: right
}

.carousel-nav-right.carousel-nav-floated .carousel-nav {
    left: auto;
    right: 0
}

.carousel-nav-bottom .flickity-prev-next-button.next,.carousel-nav-bottom .flickity-prev-next-button.previous,.carousel-nav-top .flickity-prev-next-button.next,.carousel-nav-top .flickity-prev-next-button.previous {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.carousel-nav-top .carousel-nav {
    top: 0
}

.carousel-nav-bottom .carousel-nav {
    top: auto;
    bottom: 0;
    -webkit-transform: translate(0);
    transform: translate(0)
}

.carousel-nav-middle .flickity-prev-next-button {
    top: 50%
}

.carousel-nav-middle .flickity-prev-next-button.next,.carousel-nav-middle .flickity-prev-next-button.previous {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.carousel-nav-vertical .carousel-nav {
    width: auto
}

.carousel-nav-vertical .flickity-prev-next-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    -webkit-transform: none!important;
    transform: none!important
}

.carousel-nav-vertical .flickity-prev-next-button.previous {
    margin-bottom: 10px
}

.carousel-nav-vertical .flickity-prev-next-button.next {
    margin-top: 10px
}

.carousel-nav-vertical.carousel-nav-center .flickity-prev-next-button {
    margin-left: auto!important;
    margin-right: auto!important
}

.carousel-nav-vertical.carousel-nav-center .carousel-nav {
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%)
}

.carousel-nav-vertical.carousel-nav-left .flickity-prev-next-button {
    margin-right: auto
}

.carousel-nav-vertical.carousel-nav-right .flickity-prev-next-button {
    margin-left: auto
}

.carousel-nav-xl .flickity-prev-next-button {
    font-size: 28px;
    width: 60px;
    height: 60px
}

.carousel-nav-xl.carousel-nav-rectangle .flickity-prev-next-button {
    width: 70px;
    height: 40px
}

.carousel-nav-xl.carousel-nav-rectangle .flickity-prev-next-button svg {
    max-width: 35%
}

.carousel-nav-lg .flickity-prev-next-button {
    font-size: 26px;
    width: 50px;
    height: 50px
}

.carousel-nav-lg.carousel-nav-rectangle .flickity-prev-next-button {
    height: 30px
}

.carousel-nav-md.carousel-nav-rectangle .flickity-prev-next-button {
    height: 28px
}

.carousel-nav-sm .flickity-prev-next-button {
    font-size: 20px;
    width: 35px;
    height: 35px
}

.carousel-nav-sm.carousel-nav-rectangle .flickity-prev-next-button {
    height: 24px
}

.carousel-nav-bordered .flickity-prev-next-button:before,.carousel-nav-solid .flickity-prev-next-button:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: inherit;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    background: -webkit-gradient(linear,right top,left top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background: linear-gradient(to left,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)
}

.carousel-nav-bordered .flickity-prev-next-button:hover,.carousel-nav-solid .flickity-prev-next-button:hover {
    color: #fff;
    stroke: #fff
}

.carousel-nav-bordered .flickity-prev-next-button:hover svg,.carousel-nav-solid .flickity-prev-next-button:hover svg {
    stroke: inherit
}

.carousel-nav-bordered .flickity-prev-next-button:hover:before,.carousel-nav-solid .flickity-prev-next-button:hover:before {
    opacity: 1
}

.carousel-nav-bordered .flickity-prev-next-button {
    border-color: #181b30
}

.carousel-nav-bordered .flickity-prev-next-button:before {
    background: #181b30
}

.carousel-nav-solid .flickity-prev-next-button {
    background: #fff;
    border: none
}

.carousel-nav-circle .flickity-prev-next-button svg,.carousel-nav-rectangle .flickity-prev-next-button svg,.carousel-nav-square .flickity-prev-next-button svg {
    max-width: 50%
}

.carousel-nav-circle .flickity-prev-next-button {
    border-radius: 50em
}

.carousel-nav-shadowed .flickity-prev-next-button,.carousel-nav-shadowed-onhover .flickity-prev-next-button:hover {
    -webkit-box-shadow: 0 7px 28px rgba(0,0,0,.12);
    box-shadow: 0 7px 28px rgba(0,0,0,.12)
}

.flickity-page-dots .dot {
    position: relative;
    background-color: rgba(51,51,51,.25);
    color: rgba(51,51,51,.25);
    opacity: 1;
    -webkit-transition: all .3s;
    transition: all .3s
}

.flickity-page-dots .dot.is-selected {
    background-color: #333;
    color: #333
}

.carousel-dots-left .flickity-page-dots {
    text-align: left
}

.carousel-dots-right .flickity-page-dots {
    text-align: right
}

.carousel-dots-sm .flickity-page-dots .dot {
    width: 9px;
    height: 9px
}

.carousel-dots-lg .flickity-page-dots .dot {
    width: 12px;
    height: 12px
}

.carousel-dots-style2 .dot:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    bottom: -1.5px;
    right: -1.5px;
    border: 3px solid;
    border-radius: inherit;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
    -webkit-transition: opacity .15s,-webkit-transform .3s cubic-bezier(0.23,1,.32,1);
    transition: opacity .15s,-webkit-transform .3s cubic-bezier(0.23,1,.32,1);
    transition: transform .3s cubic-bezier(0.23,1,.32,1),opacity .15s;
    transition: transform .3s cubic-bezier(0.23,1,.32,1),opacity .15s,-webkit-transform .3s cubic-bezier(0.23,1,.32,1)
}

.carousel-dots-style2 .dot.is-selected,.carousel-dots-style2 .dot:hover {
    background-color: transparent!important
}

.carousel-dots-style2 .dot.is-selected:before,.carousel-dots-style2 .dot:hover:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.carousel-dots-style3 .dot:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    border-radius: inherit;
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    background: -webkit-gradient(linear,right top,left top,from(#5792FF),to(#32D7FF));
    background: linear-gradient(to left,#5792FF 0,#32D7FF 100%);
    -webkit-transition: opacity .15s,-webkit-transform .3s cubic-bezier(0.23,1,.32,1);
    transition: opacity .15s,-webkit-transform .3s cubic-bezier(0.23,1,.32,1);
    transition: transform .3s cubic-bezier(0.23,1,.32,1),opacity .15s;
    transition: transform .3s cubic-bezier(0.23,1,.32,1),opacity .15s,-webkit-transform .3s cubic-bezier(0.23,1,.32,1)
}

.carousel-dots-style3 .dot:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #A4A4A4;
    border-radius: inherit;
    -webkit-transition: background .3s cubic-bezier(0.23,1,.32,1);
    transition: background .3s cubic-bezier(0.23,1,.32,1)
}

.carousel-dots-style3 .dot.is-selected,.carousel-dots-style3 .dot:hover {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    background-color: transparent!important
}

.carousel-dots-style3 .dot.is-selected:before,.carousel-dots-style3 .dot:hover:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.carousel-dots-style3 .dot.is-selected:after,.carousel-dots-style3 .dot:hover:after {
    background-color: #fff
}

.carousel-dots-style4 .dot {
    background-color: #A4A4A4;
    -webkit-transition: background .3s;
    transition: background .3s
}

.carousel-dots-style4 .dot.is-selected,.carousel-dots-style4 .dot:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    background-color: #000
}

.carousel-dots-inside .flickity-page-dots {
    bottom: 25px
}

.carousel-shadow-active .carousel-item-inner {
    margin-bottom: 100px;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 0 rgba(0,0,0,0)
}

.carousel-shadow-active .carousel-item-inner .wpb_single_image:only-child {
    margin-bottom: 0
}

.carousel-shadow-active .carousel-item.is-selected .carousel-item-inner {
    -webkit-box-shadow: 0 50px 75px rgba(0,0,0,.1);
    box-shadow: 0 50px 75px rgba(0,0,0,.1)
}

.carousel-shadow-all .carousel-item-inner {
    margin-bottom: 100px;
    -webkit-box-shadow: 0 50px 75px rgba(0,0,0,.2);
    box-shadow: 0 50px 75px rgba(0,0,0,.2)
}

.carousel-shadow-all .carousel-item-inner .wpb_single_image:only-child {
    margin-bottom: 0
}

.carousel-nav-light .flickity-button {
    border-color: rgba(255,255,255,.35);
    color: #fff
}

.carousel-nav-light .flickity-button svg {
    stroke: currentColor
}

.carousel-nav-light .flickity-button.previous:after {
    background-color: rgba(255,255,255,.4)!important
}

.carousel-nav-light .flickity-button:hover {
    border-color: #fff;
    color: #000
}

.carousel-nav-light .flickity-button:hover svg {
    stroke: currentColor
}

.carousel-nav-light .flickity-button:hover:before {
    background-color: #fff
}

.carousel-nav-border-gray .flickity-button {
    border-color: #e6e6e6
}

.carousel-nav-border-transparent .flickity-button {
    border-color: transparent
}

.carousel-nav-border-none .flickity-button {
    border: none
}

.carousel-nav-hover-gray .flickity-button {
    color: #000
}

.carousel-nav-hover-gray .flickity-button svg {
    stroke: currentColor
}

.carousel-nav-hover-gray .flickity-button:before {
    background-color: #f6f7fc
}

.carousel-nav-hover-gray .flickity-button:hover {
    border-color: #f6f7fc;
    color: #000
}

.carousel-nav-hover-gray .flickity-button:hover svg {
    stroke: currentColor
}

.carousel-nav-hover-light .flickity-button:hover {
    border-color: #fff;
    color: #fff
}

.carousel-nav-hover-light .flickity-button:hover svg {
    stroke: currentColor
}

.carousel-dots-light .flickity-page-dots .dot {
    background-color: rgba(255,255,255,.5);
    color: #fff
}

.carousel-dots-light .flickity-page-dots .dot.is-selected {
    background-color: #fff;
    color: #fff
}

@media (min-width: 768px) {
    .carousel-items-4-5 .carousel-item {
        width:50%
    }
}

@media (min-width: 992px) {
    .carousel-items-4-5 .carousel-item {
        width:26.666666666667%
    }
}

@media (min-width: 1200px) {
    .carousel-items-4-5 .carousel-item {
        width:22.222222222222%
    }
}

.carousel-vertical-3d .carousel-items {
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1200px;
    perspective: 1200px
}

.carousel-vertical-3d .carousel-item {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform
}

.carousel-vertical-3d .carousel-item * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.carousel-vertical-3d .carousel-item:not(.is-active) {
    cursor: pointer
}

.carousel-vertical-3d .carousel-item:not(.is-active)>* a {
    pointer-events: none
}

.carousel-vertical-3d.carousel-initialized .carousel-item {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0
}

.carousel-vertical-3d.carousel-initialized .carousel-item>:only-child {
    margin-bottom: 0
}

.carousel-vertical-3d.carousel-initialized .carousel-item.is-active,.carousel-vertical-3d.carousel-initialized .carousel-item.is-bottom,.carousel-vertical-3d.carousel-initialized .carousel-item.is-top {
    opacity: 1
}

.carousel-vertical-3d.carousel-initialized .carousel-item.is-active {
    position: relative;
    top: auto;
    left: auto
}

.carousel-vertical-3d.carousel-initialized .carousel-item.is-moving.was-bottom,.carousel-vertical-3d.carousel-initialized .carousel-item.is-moving.was-top {
    opacity: 1;
    -webkit-transition: opacity .1s linear;
    transition: opacity .1s linear
}

.liquid-lp-media {
    margin-bottom: 1.5em;
    position: relative;
    background-size: cover;
    background-position: center;
    -webkit-transition: -webkit-transform .3s,-webkit-box-shadow .3s,-webkit-transform .3s;
    transition: -webkit-transform .3s,-webkit-box-shadow .3s,-webkit-transform .3s;
    transition: transform .3s,box-shadow .3s,transform .3s;
    transition: transform .3s,box-shadow .3s,transform .3s,-webkit-transform .3s,-webkit-box-shadow .3s,-webkit-transform .3s;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.15);
    box-shadow: 0 0 0 rgba(0,0,0,.15)
}

.liquid-lp-media img {
    width: 100%
}

.liquid-lp-media>a {
    display: block;
    position: relative;
    z-index: 2
}

.liquid-lp-media.round {
    border-radius: 4px
}

.liquid-lp-media.round a,.liquid-lp-media.round figure,.liquid-lp-media.round img {
    border-radius: inherit
}

.liquid-lp-media[data-parallax]>a>img {
    visibility: hidden
}

.liquid-lp-media.w-auto {
    display: inline-block
}

.liquid-lp-media.w-auto img {
    width: auto
}

.liquid-lp-media-frame span {
    position: absolute;
    z-index: 3;
    background-color: var(--color-primary);
    -webkit-transition: -webkit-transform .65s cubic-bezier(0.21,.98,.35,1);
    transition: -webkit-transform .65s cubic-bezier(0.21,.98,.35,1);
    transition: transform .65s cubic-bezier(0.21,.98,.35,1);
    transition: transform .65s cubic-bezier(0.21,.98,.35,1),-webkit-transform .65s cubic-bezier(0.21,.98,.35,1)
}

.liquid-lp-media-frame .bottom,.liquid-lp-media-frame .top {
    left: 0;
    width: 100%;
    height: 10px;
    -webkit-transform: scaleY(0);
    transform: scaleY(0)
}

.liquid-lp-media-frame .left,.liquid-lp-media-frame .right {
    top: 0;
    width: 10px;
    height: 100%;
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
}

.liquid-lp-media-frame .top {
    top: 0;
    -webkit-transform-origin: center top;
    transform-origin: center top
}

.liquid-lp-media-frame .right {
    right: 0;
    -webkit-transform-origin: right center;
    transform-origin: right center
}

.liquid-lp-media-frame .bottom {
    bottom: 0;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
}

.liquid-lp-media-frame .left {
    left: 0;
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.liquid-lp-title {
    margin: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word
}

.liquid-lp-title a {
    display: block;
    color: inherit
}

.liquid-lp-title a:hover {
    color: var(--color-primary)
}

.liquid-lp-title .split-inner {
    display: inline-block;
    position: relative
}

.liquid-lp-title .split-inner:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: .1em;
    position: absolute;
    bottom: -.05em;
    left: 0;
    background-color: #181b31;
    -webkit-transition: -webkit-transform .35s cubic-bezier(0.2,.95,.25,1);
    transition: -webkit-transform .35s cubic-bezier(0.2,.95,.25,1);
    transition: transform .35s cubic-bezier(0.2,.95,.25,1);
    transition: transform .35s cubic-bezier(0.2,.95,.25,1),-webkit-transform .35s cubic-bezier(0.2,.95,.25,1);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-transform: scaleY(0);
    transform: scaleY(0)
}

.liquid-lp-title.size-xl {
    font-size: 60px
}

.liquid-lp-title.size-lg {
    font-size: 48px
}

.liquid-lp-title.size-md {
    font-size: 36px
}

.liquid-lp-title.size-sm {
    font-size: 23px
}

.liquid-lp-title.size-xsm {
    font-size: 18px
}

.liquid-lp-title:last-child {
    margin-bottom: 0
}

.liquid-lp-excerpt {
    margin-top: 1em;
    margin-bottom: 1em
}

.liquid-lp-excerpt p {
    margin: 0
}

.liquid-lp-footer {
    margin-top: 1.5em;
    position: relative
}

.liquid-lp-footer .category,.liquid-lp-footer .post_tag {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500
}

.liquid-lp-footer .category ul,.liquid-lp-footer .post_tag ul {
    margin-left: .5em;
    margin-bottom: 0;
    font-size: 1em
}

.liquid-lp-footer .category {
    margin-right: .75em
}

.liquid-lp-footer .post_tag {
    margin-left: .75em
}

.liquid-lp-extra-icon {
    font-size: 24px
}

.liquid-lp-category {
    padding: 0;
    margin: 0 0 .25em;
    list-style: none;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1em
}

.liquid-lp-category li {
    display: inline-block
}

.liquid-lp-category li:after {
    content: '\002C';
    margin-right: .25em
}

.liquid-lp-category li:last-child:after {
    content: none
}

.liquid-lp-category a {
    position: relative
}

.liquid-lp-category .liquid-lp-gradient-border {
    fill: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.liquid-lp-category .liquid-lp-gradient-border rect {
    fill: none;
    /* stroke: url(../themes/responsiv-clean/assets/css/#liquid-lp-gradient) */
}

.liquid-lp-category.bordered li {
    margin-right: .5em;
    position: relative;
    border-radius: 50em
}

.liquid-lp-category.bordered li:after,.liquid-lp-category.bordered li:before {
    content: '';
    display: inline-block;
    position: absolute;
    border-radius: inherit
}

.liquid-lp-category.bordered li:before {
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #fff;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.liquid-lp-category.bordered li:after {
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    z-index: 0;
    margin: 0;
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)
}

.liquid-lp-category.bordered li:hover:before {
    opacity: 0
}

.liquid-lp-category.bordered a {
    display: inline-block;
    position: relative;
    z-index: 3;
    padding: 3px 9px;
    font-size: 12px;
    font-weight: 600
}

.liquid-lp-category.bordered a:hover {
    background-color: transparent;
    color: #fff!important
}

.liquid-lp-category.circle a {
    border-radius: 50em;
    padding: 7px 15px;
    line-height: 1.5em;
    font-weight: 400
}

.liquid-lp-category.square li {
    border-radius: 0
}

.liquid-lp-category.square li:after,.liquid-lp-category.square li:before {
    content: none
}

.liquid-lp-category.size-sm {
    color: inherit;
    font-size: inherit;
    text-transform: none;
    letter-spacing: 0;
    font-weight: 400
}

.liquid-lp-category.underlined-onhover a {
    position: relative
}

.liquid-lp-category.underlined-onhover a:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-color: #181b31;
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
    -webkit-transition: opacity .3s,-webkit-transform .3s;
    transition: opacity .3s,-webkit-transform .3s;
    transition: transform .3s,opacity .3s;
    transition: transform .3s,opacity .3s,-webkit-transform .3s
}

.liquid-lp-category.underlined-onhover a:hover:after {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.liquid-lp-category:not(.size-sm) a {
    color: var(--color-primary)
}

svg+.liquid-lp-category {
    display: inline-block
}

.liquid-lp-category-filled li {
    margin-bottom: 5px;
    margin-right: 5px
}

.liquid-lp-category-filled a {
    display: inline-block;
    padding: .2em .6666666667em;
    background-color: rgba(0,0,0,.5);
    font-size: 15px;
    color: #fff!important
}

.liquid-lp-category-filled a:hover {
    background-color: #000
}

.liquid-lp-featured-label {
    display: inline-block;
    margin-bottom: 1.25em;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1em
}

.liquid-lp-date {
    display: block;
    margin-top: 1em;
    font-size: .75em
}

.liquid-lp-date.size-lg {
    font-size: .875em
}

.liquid-lp-date.size-sm {
    font-size: .625em
}

.liquid-lp-date.text-uppercase {
    letter-spacing: .2em
}

.liquid-lp-meta {
    font-size: .875em
}

.liquid-lp-meta>a {
    margin-right: 20px
}

.liquid-lp-meta i,.liquid-lp-meta svg {
    margin-right: 5px;
    vertical-align: middle
}

.liquid-lp-details {
    display: inline-block;
    position: relative;
    z-index: 3;
    margin-top: .75em;
    font-size: 15px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    color: rgba(0,0,0,.35)
}

.liquid-lp-details:first-child {
    margin-top: 0;
    margin-bottom: .5em
}

.liquid-lp-details .liquid-lp-category,.liquid-lp-details time {
    display: inline-block;
    margin: 0;
    font-size: 1em;
    font-weight: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit
}

.liquid-lp-details .liquid-lp-category a {
    color: inherit;
    font-weight: inherit
}

.liquid-lp-details a:hover {
    color: #000
}

.liquid-lp-details.size-lg {
    font-size: 18px
}

.liquid-lp-details.size-sm {
    font-size: 10px
}

.liquid-lp-details-lined {
    white-space: nowrap;
    margin-left: 4em
}

.liquid-lp-details-lined:after,.liquid-lp-details-lined:before {
    content: '';
    display: inline-block;
    position: absolute;
    height: .1em;
    min-height: 1px;
    left: -4em;
    background-color: #181b31
}

.liquid-lp-details-lined:before {
    width: 3em;
    margin-top: -.05em;
    top: 50%;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform .3s .15s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .3s .15s cubic-bezier(0.23,1,.32,1);
    transition: transform .3s .15s cubic-bezier(0.23,1,.32,1);
    transition: transform .3s .15s cubic-bezier(0.23,1,.32,1),-webkit-transform .3s .15s cubic-bezier(0.23,1,.32,1)
}

.liquid-lp-details-lined:after {
    bottom: 0;
    width: 100%;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform .3s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .3s cubic-bezier(0.23,1,.32,1);
    transition: transform .3s cubic-bezier(0.23,1,.32,1);
    transition: transform .3s cubic-bezier(0.23,1,.32,1),-webkit-transform .3s cubic-bezier(0.23,1,.32,1)
}

.liquid-lp-details-lined>* {
    display: inline-block;
    -webkit-transition: -webkit-transform .3s .15s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .3s .15s cubic-bezier(0.23,1,.32,1);
    transition: transform .3s .15s cubic-bezier(0.23,1,.32,1);
    transition: transform .3s .15s cubic-bezier(0.23,1,.32,1),-webkit-transform .3s .15s cubic-bezier(0.23,1,.32,1)
}

.liquid-lp-details-lined.liquid-lp-details-lined-alt:after {
    content: none
}

.liquid-lp-details-lined+.liquid-lp-read-more-overlay {
    left: 4em!important
}

.ld-post-author {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ld-post-author figure {
    width: 50px;
    margin-right: 15px
}

.ld-post-author figure img {
    width: 100%
}

.ld-post-author .ld-author-info {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.ld-post-author time {
    color: #9B9B9B;
    font-size: 12px;
    line-height: 1.5em
}

.ld-post-author h5 {
    font-size: 11px;
    line-height: 1.5em;
    font-weight: 400;
    color: #000;
    letter-spacing: 0
}

.liquid-lp-read-more .btn-line {
    display: inline-block;
    width: 2.727em;
    height: 2px;
    margin-right: 1em;
    position: relative;
    background-color: var(--color-primary);
    -webkit-transition: -webkit-transform .3s cubic-bezier(0.2,.95,.25,1);
    transition: -webkit-transform .3s cubic-bezier(0.2,.95,.25,1);
    transition: transform .3s cubic-bezier(0.2,.95,.25,1);
    transition: transform .3s cubic-bezier(0.2,.95,.25,1),-webkit-transform .3s cubic-bezier(0.2,.95,.25,1);
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.liquid-lp-read-more .btn-line~.btn-txt {
    -webkit-transition: -webkit-transform .3s cubic-bezier(0.2,.95,.25,1);
    transition: -webkit-transform .3s cubic-bezier(0.2,.95,.25,1);
    transition: transform .3s cubic-bezier(0.2,.95,.25,1);
    transition: transform .3s cubic-bezier(0.2,.95,.25,1),-webkit-transform .3s cubic-bezier(0.2,.95,.25,1)
}

.liquid-lp-read-more .btn-line-after {
    -webkit-transform: scaleX(0) translateX(0);
    transform: scaleX(0) translateX(0)
}

.liquid-lp-read-more .btn-line-after:after {
    content: '\f054';
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -3px;
    margin-top: 1px;
    -webkit-transform: translateY(-50%) scaleX(0);
    transform: translateY(-50%) scaleX(0);
    font: normal normal 1em/1 fontAwesome
}

.liquid-lp-read-more .btn-txt~.btn-line {
    margin-right: 0;
    margin-left: 1em
}

.liquid-lp-read-more.size-md {
    font-size: 12px
}

.liquid-lp-read-more.size-sm {
    font-size: 11px
}

.liquid-lp-read-more.size-xs {
    font-size: 10px
}

.liquid-lp-read-more.liquid-lp-read-more-overlay {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.liquid-blog-item-inner[data-hover3d=true] {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.liquid-lp {
    position: relative;
    margin-bottom: 30px
}

.liquid-lp hr {
    margin-top: 2em;
    margin-bottom: 2em
}

.carousel-items .liquid-lp {
    margin-top: 3px
}

.liquid-lp .liquid-overlay-link~.liquid-lp-category,.liquid-lp .liquid-overlay-link~header .liquid-lp-category {
    position: relative;
    z-index: 21
}

.liquid-lp.sticky .liquid-lp-title>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.liquid-lp.sticky .liquid-lp-title>a:before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: .5em;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9Ii0xNTggLTE5IDYxNiA2MTYuMDE4MDIiIHdpZHRoPSIyNHB4Ij48cGF0aCBkPSJtMTI4LjEzMjgxMiAzMDAuMDg5ODQ0djI2NS4wMzkwNjJjMCA2Ljg4NjcxOSA1LjU4MjAzMiAxMi40NzI2NTYgMTIuNDcyNjU3IDEyLjQ3MjY1NnMxMi40NzI2NTYtNS41ODU5MzcgMTIuNDcyNjU2LTEyLjQ3MjY1NnYtMjY0Ljk1NzAzMWMzNS4xODM1OTQtMi41ODk4NDQgNjguMjczNDM3LTE3LjcyMjY1NiA5My4yNS00Mi42NDQ1MzEgNTAuMzQzNzUtNDkuNDk2MDk0IDU5LjQ0NTMxMy0xMjcuMzcxMDk0IDIxLjg2NzE4Ny0xODcuMTQwNjI1LTM3LjU4MjAzMS01OS43NzM0MzgtMTExLjcwNzAzMS04NS4zMTY0MDctMTc4LjEyODkwNi02MS4zOTg0MzgtNjYuNDI5Njg3IDIzLjkyMTg3NS0xMDcuMjQ2MDk0IDkwLjg1OTM3NS05OC4wOTM3NSAxNjAuODYzMjgxIDkuMTU2MjUgNzAuMDExNzE5IDY1LjgxMjUgMTI0LjIwMzEyNiAxMzYuMTYwMTU2IDEzMC4yMzgyODJ6bTEyLjQ3MjY1Ny0yNzQuMzI0MjE5YzUwLjY0ODQzNy4xNTYyNSA5Ni4yMjI2NTYgMzAuNzgxMjUgMTE1LjUwNzgxMiA3Ny42MTcxODcgMTkuMjg1MTU3IDQ2LjgzNTkzOCA4LjQ4ODI4MSAxMDAuNjc1NzgyLTI3LjM2NzE4NyAxMzYuNDQ1MzEzLTIzLjE0ODQzOCAyMy4wNDI5NjktNTQuNTI3MzQ0IDM1LjkxMDE1Ni04Ny4xOTE0MDYgMzUuNzUtLjI4NTE1NyAwLS41NzQyMTktLjA4NTkzNy0uODU5Mzc2LS4wODU5MzdoLS4wODk4NDNjLTY4Ljk1NzAzMSAwLTEyNC44NjMyODEtNTUuOTA2MjUtMTI0Ljg2MzI4MS0xMjQuODY3MTg4IDAtNjguOTU3MDMxIDU1LjkwNjI1LTEyNC44NTkzNzUgMTI0Ljg2MzI4MS0xMjQuODU5Mzc1em0wIDAiIGZpbGw9IiMwMDAwMDAiLz48cGF0aCBkPSJtMTUyLjUyNzM0NCA5Mi41MzkwNjJjMTguODYzMjgxIDMuOTM3NSAzNC42Njc5NjggMTYuNzI2NTYzIDQyLjQ0OTIxOCAzNC4zNTU0NjkgMi43ODEyNSA2LjI5Njg3NSAxMC4xNDg0MzggOS4xNDg0MzggMTYuNDQ5MjE5IDYuMzYzMjgxIDYuMzAwNzgxLTIuNzg1MTU2IDkuMTQ4NDM4LTEwLjE1MjM0MyA2LjM2NzE4OC0xNi40NDkyMTgtMTEuMDUwNzgxLTI1LTMzLjQ3NjU2My00My4xMzY3MTktNjAuMjMwNDY5LTQ4LjcwNzAzMi00LjM3NS0uOTE0MDYyLTguOTAyMzQ0LjU3ODEyNi0xMS44NzUgMy45MTAxNTctMi45NzY1NjIgMy4zMzU5MzctMy45Mzc1IDguMDAzOTA2LTIuNTIzNDM4IDEyLjI0MjE4NyAxLjQxNDA2MyA0LjIzODI4MiA0Ljk4NDM3NiA3LjM5ODQzOCA5LjM2MzI4MiA4LjI4NTE1NnptMCAwIiBmaWxsPSIjMDAwMDAwIi8+PC9zdmc+Cg==);
    background-size: 1em;
    background-repeat: no-repeat;
    background-position: center
}

.liquid-lp:hover .liquid-lp-media {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 20px 70px rgba(0,0,0,.1);
    box-shadow: 0 20px 70px rgba(0,0,0,.1)
}

.liquid-lp:hover .liquid-lp-media-frame .bottom,.liquid-lp:hover .liquid-lp-media-frame .top {
    -webkit-transform: scaleY(1);
    transform: scaleY(1)
}

.liquid-lp:hover .liquid-lp-media-frame .left,.liquid-lp:hover .liquid-lp-media-frame .right {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(0) .split-inner:after {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(1) .split-inner:after {
    -webkit-transition-delay: .0666666667s;
    transition-delay: .0666666667s
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(2) .split-inner:after {
    -webkit-transition-delay: .1333333333s;
    transition-delay: .1333333333s
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(3) .split-inner:after {
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(4) .split-inner:after {
    -webkit-transition-delay: .2666666667s;
    transition-delay: .2666666667s
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(5) .split-inner:after {
    -webkit-transition-delay: .3333333333s;
    transition-delay: .3333333333s
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(6) .split-inner:after {
    -webkit-transition-delay: .4s;
    transition-delay: .4s
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(7) .split-inner:after {
    -webkit-transition-delay: .4666666667s;
    transition-delay: .4666666667s
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(8) .split-inner:after {
    -webkit-transition-delay: .5333333333s;
    transition-delay: .5333333333s
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(9) .split-inner:after {
    -webkit-transition-delay: .6s;
    transition-delay: .6s
}

.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(10) .split-inner:after {
    -webkit-transition-delay: .6666666667s;
    transition-delay: .6666666667s
}

.liquid-lp:hover .liquid-lp-title .split-inner:after {
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    -webkit-transform: scaleY(1);
    transform: scaleY(1)
}

.liquid-lp:hover .liquid-lp-details-lined:before {
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
}

.liquid-lp:hover .liquid-lp-details-lined:after {
    -webkit-transition-delay: .15s;
    transition-delay: .15s;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.liquid-lp:hover .liquid-lp-details-lined>* {
    -webkit-transform: translateX(-4em);
    transform: translateX(-4em)
}

.liquid-lp:hover .liquid-lp-details-lined>:nth-child(0) {
    -webkit-transition-delay: .02s;
    transition-delay: .02s
}

.liquid-lp:hover .liquid-lp-details-lined>:nth-child(1) {
    -webkit-transition-delay: .0866666667s;
    transition-delay: .0866666667s
}

.liquid-lp:hover .liquid-lp-details-lined>:nth-child(2) {
    -webkit-transition-delay: .1533333333s;
    transition-delay: .1533333333s
}

.liquid-lp:hover .liquid-lp-details-lined>:nth-child(3) {
    -webkit-transition-delay: .22s;
    transition-delay: .22s
}

.liquid-lp:hover .liquid-lp-details-lined>:nth-child(4) {
    -webkit-transition-delay: .2866666667s;
    transition-delay: .2866666667s
}

.liquid-lp:hover .liquid-lp-details-lined>:nth-child(5) {
    -webkit-transition-delay: .3533333333s;
    transition-delay: .3533333333s
}

.liquid-lp:hover .liquid-lp-details-lined.liquid-lp-details-lined-alt:before,.liquid-lp:hover .liquid-lp-details-lined.liquid-lp-details-lined-alt>* {
    -webkit-transform: none;
    transform: none
}

.liquid-lp:hover .liquid-lp-read-more .btn-line~.btn-txt {
    -webkit-transform: translateX(-3.727em);
    transform: translateX(-3.727em)
}

.liquid-lp:hover .liquid-lp-read-more .btn-line-before {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.liquid-lp:hover .liquid-lp-read-more .btn-line-after {
    -webkit-transform: scaleX(1) translateX(-3.727em);
    transform: scaleX(1) translateX(-3.727em)
}

.liquid-lp:hover .liquid-lp-read-more .btn-line-after:after {
    -webkit-transition: -webkit-transform .35s;
    transition: -webkit-transform .35s;
    transition: transform .35s;
    transition: transform .35s,-webkit-transform .35s;
    -webkit-transform: translateY(-50%) scaleX(1);
    transform: translateY(-50%) scaleX(1);
    -webkit-transition-delay: .13s;
    transition-delay: .13s
}

.liquid-lp-time-aside {
    margin-bottom: 4em
}

.liquid-lp-time-aside>time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 80px;
    height: 82px;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    border: 1px solid #EEE;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.25;
    text-align: center
}

.liquid-lp-time-aside>time span {
    display: block;
    font-size: 2.142em;
    font-weight: 700;
    color: #181b31
}

.liquid-lp-time-aside .liquid-lp-excerpt,.liquid-lp-time-aside .liquid-lp-footer,.liquid-lp-time-aside header {
    margin-left: 100px;
    overflow: hidden
}

.liquid-lp-time-aside .liquid-lp-footer {
    margin-top: 0
}

.liquid-lp-time-aside .liquid-lp-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.liquid-lp-time-aside .liquid-lp-meta>span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.liquid-lp-time-aside .liquid-lp-meta>span+span {
    margin-left: 1em
}

.liquid-lp-time-aside .liquid-lp-meta .liquid-lp-category {
    margin-bottom: 0
}

.liquid-lp-gradient defs stop:first-child {
    stop-color: var(--color-gradient-start)
}

.liquid-lp-gradient defs stop:last-child {
    stop-color: var(--color-gradient-stop)
}

.liquid-lp-sp-block {
    padding-bottom: 61.5%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    overflow: hidden
}

.liquid-lp-sp-block .liquid-lp-inner,.liquid-lp-sp-block .liquid-lp-media {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0
}

.liquid-lp-sp-block .liquid-lp-media {
    margin: 0;
    right: 0;
    background-size: cover;
    -webkit-transition: -webkit-transform .3s cubic-bezier(0.3,1,.4,1),-webkit-filter .3s cubic-bezier(0.3,1,.4,1);
    transition: -webkit-transform .3s cubic-bezier(0.3,1,.4,1),-webkit-filter .3s cubic-bezier(0.3,1,.4,1);
    transition: transform .3s cubic-bezier(0.3,1,.4,1),filter .3s cubic-bezier(0.3,1,.4,1);
    transition: transform .3s cubic-bezier(0.3,1,.4,1),filter .3s cubic-bezier(0.3,1,.4,1),-webkit-transform .3s cubic-bezier(0.3,1,.4,1),-webkit-filter .3s cubic-bezier(0.3,1,.4,1)
}

.liquid-lp-sp-block .liquid-lp-media a {
    display: block
}

.liquid-lp-sp-block .liquid-lp-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 30px;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto
}

.liquid-lp-sp-block .liquid-lp-category {
    margin-bottom: 2em
}

.liquid-lp-sp-block .liquid-lp-category a {
    border: none
}

.liquid-lp-sp-block .liquid-lp-category a span {
    position: relative;
    z-index: 2
}

.liquid-lp-sp-block .liquid-lp-category a:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 0;
    background-image: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background-image: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%);
    -webkit-transition: all .3s;
    transition: all .3s
}

.liquid-lp-sp-block .liquid-lp-category a:hover {
    background: 0 0;
    color: #fff
}

.liquid-lp-sp-block .liquid-lp-category a:hover:before {
    opacity: 1
}

.liquid-lp-sp-block .liquid-lp-title {
    font-size: 18px;
    line-height: 1.333em;
    text-transform: uppercase;
    letter-spacing: 0
}

.liquid-lp-sp-block .liquid-lp-media img {
    visibility: hidden
}

.liquid-lp-sp-block .liquid-lp-footer {
    margin-top: auto;
    position: relative;
    padding-top: 1.5em
}

.liquid-lp-sp-block .liquid-lp-footer:before {
    content: '';
    display: inline-block;
    width: 40px;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background-image: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)
}

.liquid-lp-sp-block.liquid-lp-featured,.liquid-lp-sp-block.liquid-lp-sp-instagram {
    color: #fff
}

.liquid-lp-sp-block.liquid-lp-featured:before,.liquid-lp-sp-block.liquid-lp-sp-instagram:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: .5;
    background-color: #181a23
}

.liquid-lp-sp-block.liquid-lp-featured .liquid-lp-inner,.liquid-lp-sp-block.liquid-lp-featured .liquid-lp-media,.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-inner,.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-media {
    width: 100%
}

.liquid-lp-sp-block.liquid-lp-featured .liquid-lp-media,.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-media {
    width: 100%;
    z-index: 1
}

.liquid-lp-sp-block.liquid-lp-featured .liquid-lp-category,.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-category {
    margin-bottom: 1em
}

.liquid-lp-sp-block.liquid-lp-featured .liquid-lp-inner,.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-inner {
    padding-left: 50px;
    padding-right: 50px;
    z-index: 3;
    background-color: transparent;
    color: inherit
}

.liquid-lp-sp-block.liquid-lp-featured .liquid-lp-title,.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-title {
    font-size: 28px;
    line-height: 1.5em;
    color: inherit
}

.liquid-lp-sp-block.liquid-lp-featured .liquid-lp-title a,.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-title a {
    color: inherit
}

.liquid-lp-sp-block.liquid-lp-reverse .liquid-lp-media {
    left: 0;
    right: auto
}

.liquid-lp-sp-block.liquid-lp-reverse .liquid-lp-inner {
    left: auto;
    right: 0
}

.liquid-lp-sp-block.liquid-lp-featured .liquid-lp-category a {
    color: #fff
}

.liquid-lp-sp-block.liquid-lp-sp-instagram:before {
    opacity: .9;
    background-image: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background-image: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)
}

.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.liquid-lp-sp-block.liquid-lp-sp-instagram h3 {
    margin: auto 15%;
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.5em;
    color: #fff
}

.liquid-lp-sp-block.liquid-lp-sp-instagram a {
    color: #fff
}

.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-inner {
    padding: 20px 30px
}

.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-category {
    margin-bottom: 0
}

.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-category a {
    border: 1px solid #fff
}

.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-category a:before {
    content: none
}

.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-category a:hover {
    background-color: #fff;
    color: #000!important
}

.liquid-lp-sp-block:hover .liquid-lp-media {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    transform: none
}

.liquid-lp-sp-block:hover.liquid-lp-featured .liquid-lp-media,.liquid-lp-sp-block:hover.liquid-lp-sp-instagram .liquid-lp-media {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.liquid-lp-sp-block-alt {
    border: 1px solid #e8e8e8
}

.liquid-lp-sp-block-alt .liquid-lp-inner {
    padding-top: 45px;
    padding-bottom: 40px
}

.liquid-lp-sp-block-alt .liquid-lp-title {
    font-size: 21px;
    line-height: 1.428em
}

.liquid-lp-sp-block-alt .liquid-lp-category li a {
    padding: 6px 10px;
    border: 1px solid var(--color-primary);
    line-height: 1.5em;
    color: #000
}

.liquid-lp-sp-block-alt .liquid-lp-category li a:before {
    background-image: none;
    background-color: var(--color-primary)
}

.liquid-lp-sp-block-alt .liquid-lp-excerpt {
    font-size: 17px;
    line-height: 1.47em
}

.liquid-lp-sp-block-alt .liquid-lp-footer time {
    margin: 0;
    font-size: 13px
}

.liquid-lp-sp-block-alt .liquid-lp-footer:before {
    content: none
}

.liquid-lp-sp-block-alt.liquid-lp-featured:before {
    opacity: .18;
    background-color: #000
}

.liquid-lp-sp-block-alt.liquid-lp-featured .liquid-lp-title {
    margin-top: 1em;
    font-size: 25px;
    line-height: 1.32em
}

.ld-lp-carousel-filterable .fancy-title h6 {
    font-size: 13px
}

.ld-lp-carousel-filterable .fancy-title h2 {
    font-size: 48px;
    line-height: 1em;
    letter-spacing: -.025em
}

.ld-lp-carousel-filterable .liquid-filter-items {
    margin-bottom: 1em
}

.ld-lp-carousel-filterable .filter-list {
    width: 100%;
    font-size: 14px
}

.ld-lp-carousel-filterable .ld-lp-carousel-filterable-btn {
    padding-left: .5em;
    padding-right: .5em;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1em
}

.ld-lp-carousel-filterable .ld-lp-carousel-filterable-btn .btn-gradient-bg-hover {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)
}

.ld-lp-carousel-filterable .liquid-lp:hover .liquid-lp-media {
    -webkit-transform: none;
    transform: none;
    -webkit-box-shadow: 0 15px 50px -8px rgba(0,0,0,.15);
    box-shadow: 0 15px 50px -8px rgba(0,0,0,.15)
}

.liquid-blog-item .liquid-lp-media {
    margin-bottom: 1.25em
}

.liquid-blog-item .liquid-lp-excerpt {
    margin-top: .35em;
    margin-bottom: .75em
}

.liquid-blog-item .liquid-lp-title {
    line-height: 1.3em;
    letter-spacing: -.05em
}

.liquid-blog-item .liquid-lp-footer {
    margin-top: 0
}

.liquid-blog-item:hover .liquid-lp-media {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    transform: none
}

.carousel-items .liquid-blog-item {
    margin-bottom: 0!important
}

.liquid-blog-contents-inside .liquid-blog-item-inner,.liquid-blog-contents-inside .liquid-lp-media {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.liquid-blog-contents-inside .liquid-blog-item-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 25px 5% 40px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.liquid-blog-contents-inside .liquid-lp-footer {
    position: absolute;
    bottom: 40px;
    left: 5%;
    right: 5%
}

.liquid-blog-contents-inside .liquid-lp-media {
    margin: 0;
    overflow: hidden
}

.liquid-blog-contents-inside .liquid-lp-media img {
    visibility: hidden
}

.liquid-blog-contents-inside .liquid-lp-media:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    background: -webkit-gradient(linear,left bottom,left top,color-stop(50%,#141622),to(transparent));
    background: linear-gradient(to top,#141622 50%,transparent 100%);
    -webkit-transition: opacity .45s cubic-bezier(0.3,.95,.5,1),-webkit-transform .45s cubic-bezier(0.3,.95,.5,1);
    transition: opacity .45s cubic-bezier(0.3,.95,.5,1),-webkit-transform .45s cubic-bezier(0.3,.95,.5,1);
    transition: transform .45s cubic-bezier(0.3,.95,.5,1),opacity .45s cubic-bezier(0.3,.95,.5,1);
    transition: transform .45s cubic-bezier(0.3,.95,.5,1),opacity .45s cubic-bezier(0.3,.95,.5,1),-webkit-transform .45s cubic-bezier(0.3,.95,.5,1)
}

.liquid-blog-contents-inside.contents-bottom .liquid-blog-item-inner {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.liquid-blog-contents-inside.contents-middle .liquid-blog-item-inner {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.liquid-blog-contents-inside.contents-top .liquid-blog-item-inner {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.liquid-blog-contents-inside.h-100 {
    padding-bottom: 52.65%
}

.liquid-blog-contents-inside.h-300 {
    padding-bottom: 66.67%
}

.liquid-blog-contents-inside.h-400 {
    padding-bottom: 88.89%
}

.liquid-blog-contents-inside.h-450 {
    padding-bottom: 100%
}

.liquid-blog-contents-inside.h-500 {
    padding-bottom: 111.112%
}

.liquid-blog-contents-inside.h-600 {
    padding-bottom: 133.335%
}

.liquid-blog-contents-inside:hover .liquid-lp-media:after {
    opacity: .7;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.liquid-blog-cloned-title .liquid-lp-title .split-unit {
    overflow: hidden;
    line-height: 1.15em
}

.liquid-blog-cloned-title .liquid-lp-title .split-inner {
    -webkit-transition: -webkit-transform .6s cubic-bezier(0.215,.61,.355,1);
    transition: -webkit-transform .6s cubic-bezier(0.215,.61,.355,1);
    transition: transform .6s cubic-bezier(0.215,.61,.355,1);
    transition: transform .6s cubic-bezier(0.215,.61,.355,1),-webkit-transform .6s cubic-bezier(0.215,.61,.355,1)
}

.liquid-blog-cloned-title .liquid-lp-title .split-inner:after {
    content: none
}

.liquid-blog-cloned-title .liquid-lp-title-clone [data-split-text=true] {
    visibility: hidden
}

.liquid-blog-cloned-title .liquid-lp-title-clone [data-split-text=true].split-text-applied {
    visibility: visible
}

.liquid-blog-cloned-title .liquid-lp-title-clone .split-inner {
    display: inline-block;
    -webkit-transform: translateY(150%);
    transform: translateY(150%)
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(0) .split-inner {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(1) .split-inner {
    -webkit-transition-delay: .0769230769s;
    transition-delay: .0769230769s
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(2) .split-inner {
    -webkit-transition-delay: .1538461538s;
    transition-delay: .1538461538s
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(3) .split-inner {
    -webkit-transition-delay: .2307692308s;
    transition-delay: .2307692308s
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(4) .split-inner {
    -webkit-transition-delay: .3076923077s;
    transition-delay: .3076923077s
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(5) .split-inner {
    -webkit-transition-delay: .3846153846s;
    transition-delay: .3846153846s
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(6) .split-inner {
    -webkit-transition-delay: .4615384615s;
    transition-delay: .4615384615s
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(7) .split-inner {
    -webkit-transition-delay: .5384615385s;
    transition-delay: .5384615385s
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(8) .split-inner {
    -webkit-transition-delay: .6153846154s;
    transition-delay: .6153846154s
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(9) .split-inner {
    -webkit-transition-delay: .6923076923s;
    transition-delay: .6923076923s
}

.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(10) .split-inner {
    -webkit-transition-delay: .7692307692s;
    transition-delay: .7692307692s
}

.liquid-blog-cloned-title:hover .liquid-lp-title-original .split-inner {
    -webkit-transition: -webkit-transform .45s cubic-bezier(0.35,.95,.4,1);
    transition: -webkit-transform .45s cubic-bezier(0.35,.95,.4,1);
    transition: transform .45s cubic-bezier(0.35,.95,.4,1);
    transition: transform .45s cubic-bezier(0.35,.95,.4,1),-webkit-transform .45s cubic-bezier(0.35,.95,.4,1);
    -webkit-transform: translateY(-150%);
    transform: translateY(-150%)
}

.liquid-blog-cloned-title:hover .liquid-lp-title-clone .split-inner {
    -webkit-transition: -webkit-transform .6s cubic-bezier(0.1,.9,.4,1);
    transition: -webkit-transform .6s cubic-bezier(0.1,.9,.4,1);
    transition: transform .6s cubic-bezier(0.1,.9,.4,1);
    transition: transform .6s cubic-bezier(0.1,.9,.4,1),-webkit-transform .6s cubic-bezier(0.1,.9,.4,1);
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.liquid-blog-item-masonry {
    padding: 0!important
}

.liquid-blog-item-masonry .liquid-lp-media {
    min-height: inherit;
    position: relative;
    top: auto;
    left: auto
}

.liquid-blog-item-masonry .liquid-lp-title {
    margin-bottom: .5em
}

.liquid-blog-item-masonry .liquid-lp-footer {
    margin: 0
}

.container-fluid .liquid-blog-item-masonry {
    min-height: 300px
}

.liquid-blog-item-carousel .liquid-lp-title {
    margin-bottom: .5em
}

.liquid-blog-item-carousel .liquid-lp-category+.liquid-lp-date:before,.liquid-blog-item-carousel span+.liquid-lp-date:before {
    content: '-';
    margin-right: .25em
}

.liquid-blog-item-split {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.liquid-blog-item-split .liquid-lp-details-lined:after {
    content: none
}

.liquid-blog-item-split .liquid-blog-item-inner,.liquid-blog-item-split .liquid-lp-media {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.liquid-blog-item-split .liquid-blog-item-inner {
    padding-top: 1.5em;
    padding-bottom: 1.5em
}

.liquid-blog-item-split .liquid-blog-item-inner:only-child {
    padding-left: 0
}

.liquid-blog-item-split .liquid-lp-media {
    margin-bottom: 0
}

.liquid-blog-item-split .liquid-lp-title {
    margin-bottom: .3em
}

.liquid-blog-item-split .liquid-lp-excerpt {
    margin-top: 1em;
    margin-bottom: 1em
}

.liquid-blog-item-fullwidth .liquid-lp-read-more {
    border-color: #fff
}

.liquid-blog-item-fullwidth .liquid-lp-read-more>span {
    padding: 1.666em 2.916em
}

.liquid-blog-item-fullwidth .liquid-lp-footer {
    margin-top: 2.5em
}

.liquid-blog-item-fullwidth .liquid-lp-media {
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw
}

.liquid-blog-item-fullwidth .liquid-lp-media:after {
    background: #141622;
    height: 100%!important;
    opacity: .3!important;
    -webkit-transform: none!important;
    transform: none!important
}

.liquid-blog-item-fullwidth .liquid-blog-item-inner {
    padding: 15% 0
}

.liquid-blog-item-fullwidth.liquid-blog-scheme-dark .liquid-lp-read-more {
    border-color: #000
}

.liquid-blog-item-fullwidth.liquid-blog-contents-inside .liquid-lp-footer {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto
}

.liquid-blog-item-fullwidth:hover .liquid-lp-read-more {
    background-color: #fff;
    color: #000
}

.liquid-blog-item-fullwidth:hover.liquid-blog-scheme-dark .liquid-lp-read-more {
    background-color: #000;
    color: #fff
}

.liquid-blog-item-timeline {
    padding: 20px 40px 45px;
    border: 1px solid #ebeef3;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.25);
    box-shadow: 0 0 0 rgba(0,0,0,.25);
    background-color: #fff;
    -webkit-transition: -webkit-box-shadow .6s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-box-shadow .6s cubic-bezier(0.23,1,.32,1);
    transition: box-shadow .6s cubic-bezier(0.23,1,.32,1);
    transition: box-shadow .6s cubic-bezier(0.23,1,.32,1),-webkit-box-shadow .6s cubic-bezier(0.23,1,.32,1)
}

.liquid-blog-item-timeline .liquid-lp-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 1.75em
}

.liquid-blog-item-timeline .liquid-lp-category.bordered a {
    padding: .15em 10px;
    border-radius: 4px;
    letter-spacing: .1em
}

.liquid-blog-item-timeline .liquid-lp-media {
    margin-bottom: 2em
}

.liquid-blog-item-timeline .liquid-lp-media img {
    border-radius: 4px
}

.liquid-blog-item-timeline .liquid-lp-excerpt {
    margin-top: .95em
}

.liquid-blog-item-timeline.liquid-blog-scheme-dark .liquid-lp-details {
    color: rgba(0,0,0,.35)
}

.liquid-blog-item-timeline:hover {
    -webkit-box-shadow: 0 20px 70px rgba(0,0,0,.12);
    box-shadow: 0 20px 70px rgba(0,0,0,.12)
}

.liquid-blog-item-gray {
    min-height: 350px;
    background-color: #F4F5F6;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.15);
    box-shadow: 0 0 0 rgba(0,0,0,.15);
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    transition: box-shadow .3s;
    transition: box-shadow .3s,-webkit-box-shadow .3s
}

.liquid-blog-item-gray .liquid-blog-item-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: inherit;
    padding: 40px
}

.liquid-blog-item-gray .liquid-lp-title {
    font-size: 22px;
    line-height: 1.5454545455em;
    font-weight: 400;
    letter-spacing: 0
}

.liquid-blog-item-gray .liquid-lp-excerpt {
    font-size: 16px;
    line-height: 1.5em;
    color: #9B9B9B
}

.liquid-blog-grid .masonry-item:first-child .liquid-blog-item-gray {
    border-radius: 4px 0 0 4px
}

.liquid-blog-grid .masonry-item:last-child .liquid-blog-item-gray {
    border-radius: 0 4px 4px 0
}

.liquid-blog-grid .masonry-item+.masonry-item .liquid-blog-item-gray {
    border-left: 1px solid #EAEAEA
}

.liquid-blog-item-gray:hover {
    -webkit-box-shadow: 0 15px 30px rgba(0,0,0,.07);
    box-shadow: 0 15px 30px rgba(0,0,0,.07);
    z-index: 2
}

.liquid-blog-item-candy .liquid-lp-media {
    margin-bottom: 27px;
    overflow: hidden
}

.liquid-blog-item-candy .liquid-lp-header {
    margin-bottom: 15px
}

.liquid-blog-item-candy .liquid-lp-footer a,.liquid-blog-item-candy .liquid-lp-footer time {
    color: #000
}

.liquid-blog-item-candy .liquid-lp-details-lined:before {
    background-color: var(--color-primary)
}

.liquid-blog-scheme-dark .liquid-lp-title,.liquid-blog-scheme-dark a,.liquid-blog-scheme-dark a:focus,.liquid-blog-scheme-dark a:hover,.liquid-blog-scheme-light .liquid-lp-title,.liquid-blog-scheme-light a,.liquid-blog-scheme-light a:focus,.liquid-blog-scheme-light a:hover {
    color: inherit
}

.liquid-blog-scheme-dark .liquid-lp-category.bordered a,.liquid-blog-scheme-light .liquid-lp-category.bordered a {
    color: var(--color-primary)
}

.liquid-blog-scheme-dark {
    color: #181b31
}

.liquid-blog-scheme-dark .btn-line,.liquid-blog-scheme-dark .liquid-lp-title .split-inner:after {
    background-color: #181b31
}

.liquid-blog-scheme-light {
    color: #fff
}

.liquid-blog-scheme-light .btn-line,.liquid-blog-scheme-light .liquid-lp-category.underlined-onhover a:after,.liquid-blog-scheme-light .liquid-lp-details-lined:after,.liquid-blog-scheme-light .liquid-lp-details-lined:before,.liquid-blog-scheme-light .liquid-lp-title .split-inner:after {
    background-color: #fff
}

.liquid-blog-scheme-light .liquid-lp-details {
    color: rgba(255,255,255,.55)
}

.liquid-blog-scheme-light .liquid-lp-details a:hover {
    color: #fff
}

.liquid-blog-scheme-dark-alt .liquid-lp-details,.liquid-blog-scheme-light-alt .liquid-lp-details {
    color: inherit
}

.liquid-blog-scheme-dark-alt .liquid-lp-excerpt {
    color: rgba(24,27,49,.55)
}

.liquid-blog-scheme-light-alt .liquid-lp-excerpt {
    color: rgba(255,255,255,.55)
}

.liquid-blog-item-square .liquid-blog-item-inner {
    padding: 6% 30px
}

.liquid-blog-item-square .liquid-lp-header {
    width: 85%
}

.liquid-blog-item-square .liquid-lp-category {
    font-size: 12px
}

.liquid-blog-item-square .liquid-lp-title {
    font-size: 22px;
    line-height: 1.333em
}

.liquid-lp-small-thumb .liquid-lp-media {
    width: 70px;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.liquid-lp-small-thumb .liquid-lp-header {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1
}

.liquid-blog-style-masonry .masonry-item {
    padding-left: 6px;
    padding-right: 6px
}

.liquid-blog-style-masonry .liquid-lp {
    margin-bottom: 12px
}

.liquid-lp.format-link .liquid-lp-title {
    margin-bottom: 1em
}

.liquid-lp.format-link .liquid-lp-title a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-decoration: underline
}

.liquid-lp.format-link .liquid-lp-title svg {
    margin-right: .625em;
    fill: currentColor;
    width: 1.25em;
    height: 1.25em
}

.liquid-lp.format-quote {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 4px;
    padding: 50px
}

.liquid-lp.format-quote .liquid-lp-title,.liquid-lp.format-quote blockquote {
    margin: 0
}

.liquid-lp.format-quote .liquid-lp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.liquid-lp.format-quote svg {
    margin-bottom: .6666666667em
}

.liquid-lp.format-quote cite {
    padding-top: 10px;
    font-size: 12px;
    color: #888
}

.liquid-lp.format-quote cite:before {
    content: none
}

.liquid-lp.format-gallery .liquid-lp-media .carousel-nav button {
    width: 53px;
    height: 53px;
    font-size: 14px;
    background-color: #fff;
    color: #000
}

.liquid-lp.format-audio:hover .liquid-lp-media,.liquid-lp.format-video:hover .liquid-lp-media {
    -webkit-transform: none!important;
    transform: none!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important
}

.liquid-filter-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1.5em;
    color: #16171e
}

.liquid-filter-items-inner {
    display: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: inherit;
    flex-flow: inherit;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit
}

.liquid-filter-items-inner:only-child {
    width: 100%
}

.liquid-filter-items-label {
    margin-bottom: 0;
    line-height: 1
}

.liquid-filter-items-label.size-xxl {
    font-size: 72px;
    letter-spacing: -.025em
}

.liquid-filter-items-label.size-xl {
    font-size: 55px;
    line-height: 1
}

.liquid-filter-items-label.size-lg {
    font-size: 24px
}

.liquid-filter-items-label.size-md {
    font-size: 18px
}

.liquid-filter-items-label+.filter-list li {
    margin-bottom: 0
}

.filter-list {
    padding: 0;
    margin: 0 0 30px;
    list-style: none;
    font-size: .8125em
}

.filter-list.size-lg {
    font-size: 24px
}

.filter-list.size-md {
    font-size: 18px
}

.filter-list.size-sm {
    font-size: 12px
}

.filter-list li {
    margin-bottom: .5em;
    position: relative;
    color: #9ba1af;
    cursor: pointer;
    -webkit-transition: color .3s;
    transition: color .3s
}

.filter-list li span {
    display: inline-block
}

.filter-list li.active,.filter-list li:hover {
    color: #16171e
}

.liquid-filter-items-label+.filter-list {
    margin-left: 3.5em;
    margin-bottom: 0
}

.filter-list-inline li {
    display: inline-block
}

.filter-list-scheme-light li {
    color: rgba(255,255,255,.5)
}

.filter-list-scheme-light li.active,.filter-list-scheme-light li:hover {
    color: #fff
}

.filters-line-through li span,.filters-underline li span {
    position: relative
}

.filters-line-through li span:after,.filters-underline li span:after {
    content: '';
    height: .0909090909em;
    min-height: 1px;
    position: absolute;
    background-color: #16171e;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
}

.filters-line-through li.active span:after,.filters-line-through li:hover span:after,.filters-underline li.active span:after,.filters-underline li:hover span:after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.filters-line-through li span:after {
    width: 110%;
    top: 50%;
    left: -5%;
    margin-top: -.0454545455em
}

.filters-underline li span:after {
    bottom: -.5833333333em;
    left: 0;
    width: 100%;
    height: .1666666667em;
    min-height: 2px
}

.filter-list-style-1 {
    margin-top: 10px;
    margin-bottom: 70px
}

.filter-list-style-1 span {
    -webkit-transition: -webkit-transform .4s cubic-bezier(0.4,1,.5,1);
    transition: -webkit-transform .4s cubic-bezier(0.4,1,.5,1);
    transition: transform .4s cubic-bezier(0.4,1,.5,1);
    transition: transform .4s cubic-bezier(0.4,1,.5,1),-webkit-transform .4s cubic-bezier(0.4,1,.5,1)
}

.filter-list-style-1 li {
    margin-bottom: 2em
}

.filter-list-style-1 li:before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 1.2em;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -.6em;
    background-image: -webkit-gradient(linear,left top,left bottom,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background-image: linear-gradient(to bottom,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%);
    opacity: 0;
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
    -webkit-transition: opacity .3s cubic-bezier(0.4,1,.5,1),-webkit-transform .3s cubic-bezier(0.4,1,.5,1);
    transition: opacity .3s cubic-bezier(0.4,1,.5,1),-webkit-transform .3s cubic-bezier(0.4,1,.5,1);
    transition: transform .3s cubic-bezier(0.4,1,.5,1),opacity .3s cubic-bezier(0.4,1,.5,1);
    transition: transform .3s cubic-bezier(0.4,1,.5,1),opacity .3s cubic-bezier(0.4,1,.5,1),-webkit-transform .3s cubic-bezier(0.4,1,.5,1)
}

.filter-list-style-1 li.active:before,.filter-list-style-1 li:hover:before {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: .05s;
    transition-delay: .05s
}

.filter-list-style-1 li.active span,.filter-list-style-1 li:hover span {
    -webkit-transform: translateX(15px);
    transform: translateX(15px)
}

.fancy-box {
    position: relative;
    margin-bottom: 30px
}

.fancy-box figure {
    background-size: cover
}

.fancy-box h3 {
    font-size: 1.5em;
    line-height: 1.5em;
    letter-spacing: 0;
    margin: 0
}

.fancy-box p {
    margin: 0
}

.fancy-box img {
    width: 100%
}

.fancy-box .cb-img-container,.fancy-box .fancy-box-image {
    position: relative
}

.fancy-box .cb-img-container {
    overflow: hidden
}

.fancy-box .blur-image-canvas,.fancy-box .blur-image-container,.fancy-box .blur-image-inner,.fancy-box .blur-main-image {
    position: absolute;
    top: 0;
    left: 0
}

.fancy-box .blur-image-container,.fancy-box .blur-image-inner {
    width: 100%;
    height: 100%
}

.fancy-box .blur-image-container {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.fancy-box .blur-image-canvas {
    width: 100%!important;
    height: 100%!important;
    -o-object-fit: cover;
    object-fit: cover
}

.fancy-box .cb-img-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s,visibility .3s;
    transition: opacity .3s,visibility .3s;
    background-size: cover;
    background-position: center
}

.fancy-box .fancy-box-footer {
    position: relative;
    z-index: 2
}

.fancy-box:hover .cb-img-btn,.fancy-box:hover .cb-img-overlay {
    opacity: 1;
    visibility: visible
}

.fancy-box:hover .cb-img-btn-inner {
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
    -webkit-transition-delay: .05s;
    transition-delay: .05s
}

.fancy-box:hover .cb-img-btn-inner .btn {
    opacity: 1;
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
    -webkit-transition-delay: .18s;
    transition-delay: .18s
}

.cb-subtitle {
    display: inline-block;
    padding: 3px 10px;
    margin-bottom: 10px;
    background-color: var(--color-primary);
    font-size: 12px
}

.cb-img-btn,.cb-img-btn-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.cb-img-btn-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 15px;
    border: 2px solid #fff;
    position: absolute;
    top: 15px;
    left: 15px;
    bottom: 15px;
    right: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: scale3d(1.1,1.1,0);
    transform: scale3d(1.1,1.1,0);
    -webkit-transition: -webkit-transform .8s cubic-bezier(0.17,.67,.2,1);
    transition: -webkit-transform .8s cubic-bezier(0.17,.67,.2,1);
    transition: transform .8s cubic-bezier(0.17,.67,.2,1);
    transition: transform .8s cubic-bezier(0.17,.67,.2,1),-webkit-transform .8s cubic-bezier(0.17,.67,.2,1)
}

.cb-img-btn {
    background-color: rgba(0,0,0,.35);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s,visibility .3s;
    transition: opacity .3s,visibility .3s
}

.cb-img-btn .liquid-overlay-link {
    z-index: 2
}

.cb-img-btn .btn {
    opacity: 0;
    -webkit-transform: scale3d(1.1,1.1,0);
    transform: scale3d(1.1,1.1,0)
}

.cb-img-btn .btn-naked {
    color: #fff
}

.fancy-box-booking .fancy-box-contents {
    padding: 3.125em 1.875em
}

.fancy-box-booking .fancy-box-info {
    width: 75%;
    margin: 2.8125em 1.875em;
    position: relative
}

.fancy-box-booking .fancy-box-info:before {
    content: '';
    display: inline-block;
    width: 1.875em;
    height: 1px;
    position: absolute;
    top: .5em;
    left: -60px;
    background-color: var(--color-primary)
}

.fancy-box-booking .fancy-box-header {
    display: inline-block;
    padding: .625em 1.875em 1.25em;
    position: absolute;
    bottom: 0;
    left: 1.875em;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    background-color: #fff;
    -webkit-box-shadow: 0 7px 50px rgba(0,0,0,.1);
    box-shadow: 0 7px 50px rgba(0,0,0,.1)
}

.fancy-box-booking .fancy-box-details {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: var(--color-primary)
}

.fancy-box-booking .fancy-box-details>span:after {
    content: '\2758';
    margin-left: 1em;
    margin-right: .83em
}

.fancy-box-booking .fancy-box-details>span:last-child:after {
    content: none
}

.fancy-box-booking .btn {
    -webkit-box-shadow: 0 10px 40px rgba(0,0,0,.075);
    box-shadow: 0 10px 40px rgba(0,0,0,.075)
}

.fancy-box-classes {
    background-color: #fff;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.15);
    box-shadow: 0 0 0 rgba(0,0,0,.15);
    -webkit-transition: -webkit-box-shadow .45s cubic-bezier(0.32,.98,.37,1);
    transition: -webkit-box-shadow .45s cubic-bezier(0.32,.98,.37,1);
    transition: box-shadow .45s cubic-bezier(0.32,.98,.37,1);
    transition: box-shadow .45s cubic-bezier(0.32,.98,.37,1),-webkit-box-shadow .45s cubic-bezier(0.32,.98,.37,1)
}

.fancy-box-classes .fancy-box-contents {
    padding: 1em 25px
}

.fancy-box-classes h3 {
    margin-bottom: .4375em
}

.fancy-box-classes .fancy-box-label {
    display: inline-block;
    padding: 4px 10px;
    position: absolute;
    top: 30px;
    left: 0;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .025em;
    color: #fff;
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)
}

.fancy-box-classes .trainer {
    font-size: .8125em;
    color: #828593
}

.fancy-box-classes .trainer i {
    display: inline-block;
    margin-right: 5px
}

.backgroundcliptext .fancy-box-classes .trainer i {
    padding-top: 1px;
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%);
    background-clip: text!important;
    -webkit-background-clip: text!important;
    text-fill-color: transparent!important;
    -webkit-text-fill-color: transparent!important
}

.fancy-box-classes:hover {
    -webkit-box-shadow: 0 30px 50px rgba(0,0,0,.07);
    box-shadow: 0 30px 50px rgba(0,0,0,.07)
}

.flickity-slider .fancy-box-classes {
    margin-bottom: 50px
}

.fancy-box-travel {
    height: 100%;
    overflow: hidden;
    color: #fff
}

.fancy-box-travel:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-image: -webkit-gradient(linear,left bottom,left top,from(#181b31),to(transparent));
    background-image: linear-gradient(to top,#181b31 0,transparent 100%);
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    -webkit-transition: -webkit-transform .5s cubic-bezier(0.86,0,.07,1);
    transition: -webkit-transform .5s cubic-bezier(0.86,0,.07,1);
    transition: transform .5s cubic-bezier(0.86,0,.07,1);
    transition: transform .5s cubic-bezier(0.86,0,.07,1),-webkit-transform .5s cubic-bezier(0.86,0,.07,1)
}

.fancy-box-travel h3 {
    margin: .4375em 0;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0;
    color: #fff
}

.fancy-box-travel .fancy-box-contents,.fancy-box-travel .fancy-box-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0
}

.fancy-box-travel .fancy-box-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 0;
    -webkit-transition: -webkit-transform .5s cubic-bezier(0.86,0,.07,1),-webkit-filter .5s cubic-bezier(0.86,0,.07,1);
    transition: -webkit-transform .5s cubic-bezier(0.86,0,.07,1),-webkit-filter .5s cubic-bezier(0.86,0,.07,1);
    transition: transform .5s cubic-bezier(0.86,0,.07,1),filter .5s cubic-bezier(0.86,0,.07,1);
    transition: transform .5s cubic-bezier(0.86,0,.07,1),filter .5s cubic-bezier(0.86,0,.07,1),-webkit-transform .5s cubic-bezier(0.86,0,.07,1),-webkit-filter .5s cubic-bezier(0.86,0,.07,1)
}

.fancy-box-travel .fancy-box-image img {
    visibility: hidden
}

.fancy-box-travel .fancy-box-contents {
    margin: 30px;
    z-index: 2
}

.fancy-box-travel .fancy-box-time {
    display: inline-block;
    padding: .25em .625em;
    background-color: var(--color-primary);
    font-size: .75em;
    letter-spacing: .05em
}

.fancy-box-travel .fancy-box-footer {
    margin-top: 1.875em
}

.fancy-box-travel .btn {
    font-size: .875em;
    font-weight: 700
}

.fancy-box-travel.fancy-box-big .fancy-box-contents {
    right: 30%
}

.fancy-box-travel.fancy-box-tall {
    padding-bottom: 134.375%
}

.fancy-box-travel.fancy-box-small {
    padding-bottom: 69.5%
}

.fancy-box-travel.fancy-box-wide {
    padding-bottom: 33.36%
}

.fancy-box-travel.fancy-box-square {
    padding-bottom: 100%
}

.fancy-box-travel.hide-target .fancy-box-info .fancy-box-footer,.fancy-box-travel.hide-target .fancy-box-info p {
    opacity: 0
}

.fancy-box-travel:hover:after {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.fancy-box-travel:hover .fancy-box-image {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
}

.fancy-box-tour {
    background-color: #fff;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.15);
    box-shadow: 0 0 0 rgba(0,0,0,.15);
    -webkit-transition: -webkit-box-shadow .45s cubic-bezier(0.32,.98,.37,1);
    transition: -webkit-box-shadow .45s cubic-bezier(0.32,.98,.37,1);
    transition: box-shadow .45s cubic-bezier(0.32,.98,.37,1);
    transition: box-shadow .45s cubic-bezier(0.32,.98,.37,1),-webkit-box-shadow .45s cubic-bezier(0.32,.98,.37,1)
}

.fancy-box-tour .fancy-box-contents,.fancy-box-tour .fancy-box-footer {
    border: 1px solid #e7e9ef;
    border-top: none
}

.fancy-box-tour .fancy-box-contents {
    padding: 1.25em 20px 1.5625em
}

.fancy-box-tour .fancy-box-info {
    font-size: .8125em
}

.fancy-box-tour .rating {
    font-size: 12px;
    margin: .45em 0;
    color: #a7a9b8
}

.fancy-box-tour .star-rating {
    display: inline-block;
    font-size: 1em;
    color: var(--color-primary)
}

.fancy-box-tour .fancy-box-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.0625em 20px
}

.fancy-box-tour .fancy-box-footer h6 {
    margin: 0;
    font-size: 1em;
    color: #181b31
}

.fancy-box-tour .fancy-box-footer h6 strong {
    font-weight: 700;
    font-size: 1.5em;
    color: var(--color-primary)
}

.fancy-box-tour .fancy-box-icon {
    display: inline-block;
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
    opacity: 0;
    color: var(--color-primary);
    -webkit-transition: opacity .3s,-webkit-transform .3s;
    transition: opacity .3s,-webkit-transform .3s;
    transition: transform .3s,opacity .3s;
    transition: transform .3s,opacity .3s,-webkit-transform .3s
}

.fancy-box-tour .fancy-box-icon i {
    display: inline-block
}

.fancy-box-tour:hover {
    -webkit-box-shadow: 0 20px 50px rgba(0,0,0,.07);
    box-shadow: 0 20px 50px rgba(0,0,0,.07)
}

.fancy-box-tour:hover .fancy-box-icon {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1
}

.flickity-slider .fancy-box-tour {
    margin-bottom: 50px
}

.fancy-box-offer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #e5e7ed
}

.fancy-box-offer .fancy-box-cell {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-preferred-size: 16.666666666667%;
    flex-basis: 16.666666666667%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0
}

.fancy-box-offer .fancy-box-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%
}

.fancy-box-offer .fancy-box-image {
    margin-right: 18px;
    -webkit-box-flex: 0;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.fancy-box-offer small {
    display: block;
    font-size: 13px;
    letter-spacing: .05em;
    color: #808291;
    font-weight: 400
}

.fancy-box-offer h3 {
    line-height: 1.5em;
    font-weight: 600
}

.fancy-box-offer h5 {
    margin: 0;
    font-size: 1.125em;
    line-height: 1.5em;
    font-weight: 600
}

.fancy-box-offer img {
    max-width: 120px
}

.fancy-box-offer p span {
    display: inline-block
}

.fancy-box-offer .btn {
    font-size: 14px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.fancy-box-offer .btn-md>span {
    padding-top: 1em;
    padding-bottom: 1em
}

.fancy-box-offer:last-child {
    border-bottom: none
}

.fancy-box-offer-header {
    padding-bottom: 15px
}

.fancy-box-offer-header h3 {
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -.01em
}

.fancy-box-cell:before,.fancy-box-offer-header p {
    font-size: .875em;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: #a7a9b8
}

.fancy-box-case-study {
    -webkit-perspective: 1200px;
    perspective: 1200px;
    height: 400px;
    -webkit-transition: -webkit-transform .6s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .6s cubic-bezier(0.23,1,.32,1);
    transition: transform .6s cubic-bezier(0.23,1,.32,1);
    transition: transform .6s cubic-bezier(0.23,1,.32,1),-webkit-transform .6s cubic-bezier(0.23,1,.32,1)
}

.fancy-box-case-study h3 {
    font-size: 27px
}

.fancy-box-case-study figure {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    -webkit-transform: scale(1.075);
    transform: scale(1.075);
    -webkit-transition: -webkit-transform .6s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .6s cubic-bezier(0.23,1,.32,1);
    transition: transform .6s cubic-bezier(0.23,1,.32,1);
    transition: transform .6s cubic-bezier(0.23,1,.32,1),-webkit-transform .6s cubic-bezier(0.23,1,.32,1)
}

.fancy-box-case-study figure:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(13,15,30,.35)
}

.fancy-box-case-study .cb-img-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden
}

.fancy-box-case-study .fancy-box-contents {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: inherit;
    perspective: inherit;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 0 rgba(0,0,0,0);
    -webkit-transition: -webkit-box-shadow .6s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-box-shadow .6s cubic-bezier(0.23,1,.32,1);
    transition: box-shadow .6s cubic-bezier(0.23,1,.32,1);
    transition: box-shadow .6s cubic-bezier(0.23,1,.32,1),-webkit-box-shadow .6s cubic-bezier(0.23,1,.32,1)
}

.fancy-box-case-study .fancy-box-header {
    position: absolute;
    bottom: 40px;
    left: 35px;
    right: 35px;
    -webkit-transform: translateZ(35px) scale(0.942);
    transform: translateZ(35px) scale(0.942);
    color: #fff
}

.fancy-box-case-study .ld-cb-icon {
    display: block;
    font-size: 44px;
    line-height: 1;
    opacity: 0;
    -webkit-transition-property: opacity,-webkit-transform;
    transition-property: opacity,-webkit-transform;
    transition-property: opacity,transform;
    transition-property: opacity,transform,-webkit-transform;
    -webkit-transition-duration: .6s;
    transition-duration: .6s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1);
    -webkit-transform: scale(0.95);
    transform: scale(0.95)
}

.fancy-box-case-study .ld-cb-cat {
    font-size: 13px;
    opacity: .6
}

.fancy-box-case-study.scheme-light .fancy-box-header {
    color: #fff
}

.fancy-box-case-study.scheme-light .fancy-box-header h3 {
    color: inherit
}

.fancy-box-case-study:hover {
    -webkit-transform: scale(1.075);
    transform: scale(1.075)
}

.fancy-box-case-study:hover figure {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.fancy-box-case-study:hover .fancy-box-contents {
    -webkit-box-shadow: 0 60px 110px rgba(0,0,0,.23);
    box-shadow: 0 60px 110px rgba(0,0,0,.23)
}

.fancy-box-case-study:hover .ld-cb-icon {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.fancy-box-overlay {
    padding-bottom: 53.335%;
    overflow: hidden;
    color: rgba(0,0,0,.7);
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 0 rgba(0,0,0,0);
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    transition: box-shadow .3s;
    transition: box-shadow .3s,-webkit-box-shadow .3s
}

.fancy-box-overlay .cb-img-container,.fancy-box-overlay .fancy-box-contents {
    position: absolute;
    top: 0;
    left: 0
}

.fancy-box-overlay .cb-img-container,.fancy-box-overlay .fancy-box-contents,.fancy-box-overlay .fancy-box-image {
    width: 100%;
    height: 100%
}

.fancy-box-overlay .cb-img-container {
    width: 102%;
    height: 102%;
    left: -1%;
    top: -1%
}

.fancy-box-overlay .fancy-box-image {
    background-position: center
}

.fancy-box-overlay .blur-image-container {
    opacity: 0;
    -webkit-transition: opacity .3s cubic-bezier(0.39,.575,.565,1);
    transition: opacity .3s cubic-bezier(0.39,.575,.565,1)
}

.fancy-box-overlay .cb-overlay {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.4);
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.fancy-box-overlay .fancy-box-contents {
    width: 85%;
    padding: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.fancy-box-overlay .fancy-box-footer {
    margin-top: auto;
    z-index: 2
}

.fancy-box-overlay h3 {
    font-size: 24px;
    margin-bottom: 1.5rem
}

.fancy-box-overlay.shadowed {
    -webkit-box-shadow: 0 20px 40px rgba(0,0,0,.18);
    box-shadow: 0 20px 40px rgba(0,0,0,.18)
}

.fancy-box-overlay.tall {
    padding-bottom: 133.335%
}

.fancy-box-overlay.tall .fancy-box-contents {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px
}

.fancy-box-overlay.tall h3 {
    margin-bottom: .5rem
}

.fancy-box-overlay.scheme-light {
    color: rgba(255,255,255,.75)
}

.fancy-box-overlay.scheme-light h3 {
    color: #fff
}

.fancy-box-overlay.scheme-light .cb-overlay {
    background-color: rgba(0,0,0,.4)
}

.fancy-box-overlay.fancy-box-overlay-alt {
    padding-left: 30px;
    padding-right: 30px
}

.fancy-box-overlay.fancy-box-overlay-alt .fancy-box-contents {
    width: 100%
}

.fancy-box-overlay.fancy-box-overlay-alt .fancy-box-footer {
    margin-top: 1.5rem
}

.fancy-box-overlay:hover {
    -webkit-box-shadow: 0 20px 40px rgba(0,0,0,.18);
    box-shadow: 0 20px 40px rgba(0,0,0,.18)
}

.fancy-box-overlay:hover .cb-overlay {
    opacity: .9
}

.fancy-box-overlay:hover .blur-image-container {
    opacity: 1
}

.fancy-box-classic h3 {
    margin-bottom: .5rem
}

.fancy-box-classic.text-center h3 {
    margin-bottom: 1.25rem
}

.fancy-box-custom-height {
    padding-bottom: 0!important
}

.fancy-box-heading-lg h3 {
    font-size: 36px
}

.fancy-box-heading-sm h3 {
    font-size: 18px
}

.liquid-counter {
    display: inline-block;
    position: relative;
    z-index: 1;
    margin-bottom: 30px
}

.liquid-counter:hover .liquid-counter-element-hover {
    opacity: 1
}

.liquid-counter-element {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 700;
    line-height: 1em
}

.liquid-counter-element>span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.liquid-counter-text {
    line-height: 1.5em
}

.liquid-counter-element-hover,.liquid-counter-element-static {
    display: none
}

.liquid-counter-element-hover span,.liquid-counter-element-static span {
    display: inline-block;
    vertical-align: middle
}

.counter-animated .liquid-counter-element-hover,.counter-animated .liquid-counter-element-static {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.liquid-counter-element-static>span {
    background: inherit
}

.counter-animated .liquid-counter-element-static~span {
    display: none
}

.liquid-counter-element-hover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.backgroundcliptext .liquid-counter-element-hover {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)));
    background: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%);
    background-clip: text!important;
    -webkit-background-clip: text!important;
    text-fill-color: transparent!important;
    -webkit-text-fill-color: transparent!important
}

.backgroundcliptext .liquid-counter-element-hover span {
    background: inherit
}

.liquid-counter-regular .liquid-counter-element {
    font-weight: 400
}

.liquid-counter-medium .liquid-counter-element {
    font-weight: 500
}

.liquid-counter-semibold .liquid-counter-element {
    font-weight: 600
}

.liquid-counter-bold .liquid-counter-element {
    font-weight: 700
}

.liquid-counter-sm .liquid-counter-element {
    font-size: 36px
}

.liquid-counter-md .liquid-counter-element {
    font-size: 47px
}

.liquid-counter-lg .liquid-counter-element {
    font-size: 60px
}

.liquid-counter-lg2 .liquid-counter-element {
    font-size: 200px
}

.liquid-counter-xl .liquid-counter-element {
    font-size: 300px
}

.liquid-counter-animator {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    background: inherit
}

.liquid-animator-value {
    display: inline-block;
    visibility: hidden
}

.liquid-animator-numbers {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: inherit;
    overflow: hidden;
    visibility: visible
}

.liquid-animator-numbers ul {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    background: inherit
}

.liquid-animator-numbers li {
    margin: 0;
    background: inherit
}

.liquid-counter-huge .liquid-counter-element {
    line-height: .8em
}

.liquid-counter-huge .liquid-text-top {
    display: block;
    margin-bottom: 2em;
    position: relative;
    top: 2em;
    font-size: .875em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em
}

.liquid-counter-huge .liquid-text-bottom {
    position: absolute;
    bottom: 0;
    left: 100%;
    font-size: 1.125em;
    line-height: 1.5em;
    font-style: italic
}

.liquid-counter-bordered {
    width: 100%;
    padding: 30px 5%;
    border: 1px solid #d8dbe2;
    text-align: center
}

.liquid-counter-bordered .liquid-counter-element {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.liquid-counter-bordered .liquid-counter-text {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: #a7a9b8
}

.backgroundcliptext .liquid-counter-has-gradient .liquid-counter-element-static,.backgroundcliptext .liquid-counter-has-gradient .liquid-counter-element>span {
    background-clip: text!important;
    -webkit-background-clip: text!important;
    text-fill-color: transparent!important;
    -webkit-text-fill-color: transparent!important
}

.liquid-form .input-container {
    display: inline-block;
    margin-bottom: 20px;
    position: relative
}

.liquid-form .input-container .ui-selectmenu-button {
    margin-bottom: 0!important
}

.liquid-form .ui-selectmenu-button,.liquid-form input,.liquid-form select,.liquid-form textarea {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 2em;
    position: relative;
    border: 1px solid #d5d6de;
    border-radius: 0;
    background: 0 0;
    color: #b7b9c4;
    -webkit-transition: all .3s;
    transition: all .3s
}

.liquid-form .ui-selectmenu-button::-webkit-input-placeholder,.liquid-form input::-webkit-input-placeholder,.liquid-form select::-webkit-input-placeholder,.liquid-form textarea::-webkit-input-placeholder {
    color: inherit
}

.liquid-form .ui-selectmenu-button::-moz-placeholder,.liquid-form input::-moz-placeholder,.liquid-form select::-moz-placeholder,.liquid-form textarea::-moz-placeholder {
    color: inherit
}

.liquid-form .ui-selectmenu-button:-moz-placeholder,.liquid-form input:-moz-placeholder,.liquid-form select:-moz-placeholder,.liquid-form textarea:-moz-placeholder {
    color: inherit
}

.liquid-form .ui-selectmenu-button:-ms-input-placeholder,.liquid-form input:-ms-input-placeholder,.liquid-form select:-ms-input-placeholder,.liquid-form textarea:-ms-input-placeholder {
    color: inherit
}

.liquid-form .ui-selectmenu-button:focus,.liquid-form input:focus,.liquid-form select:focus,.liquid-form textarea:focus {
    outline: 0;
    border-color: #9c9eb1;
    color: #9c9eb1
}

.liquid-form .ui-selectmenu-icon {
    visibility: hidden;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.liquid-form .input[type=submit],.liquid-form button[type=submit] {
    height: 50px
}

.liquid-form button[type=submit]>span {
    padding-top: 0;
    padding-bottom: 0
}

.liquid-form-inputs-sm .input[type=submit],.liquid-form-inputs-sm .ui-selectmenu-button,.liquid-form-inputs-sm button[type=submit],.liquid-form-inputs-sm input,.liquid-form-inputs-sm select,.liquid-form-inputs-sm textarea {
    height: 40px
}

.liquid-form-inputs-lg .input[type=submit],.liquid-form-inputs-lg .ui-selectmenu-button,.liquid-form-inputs-lg button[type=submit],.liquid-form-inputs-lg input,.liquid-form-inputs-lg select,.liquid-form-inputs-lg textarea {
    height: 60px
}

.liquid-form-inputs-round .ui-selectmenu-button,.liquid-form-inputs-round button[type=submit],.liquid-form-inputs-round input,.liquid-form-inputs-round input[type=submit],.liquid-form-inputs-round select,.liquid-form-inputs-round textarea {
    border-radius: 3px
}

.date-picker {
    cursor: pointer
}

.date-picker:focus {
    cursor: auto
}

.liquid-input-icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.liquid-input-icon-right {
    left: auto;
    right: 15px
}

.liquid-input-icon-left {
    right: auto;
    left: 15px
}

.ui-button.ui-state-active:hover,.ui-button:active,.ui-state-active,.ui-widget-content .ui-state-active,.ui-widget-header .ui-state-active,a.ui-button:active {
    background-color: #ededed;
    border-color: #ededed;
    color: #000
}

.ui-selectmenu-button:active {
    background-color: transparent;
    color: #9c9eb1
}

.ui-widget.ui-widget-content {
    border-color: #e4e4eb
}

.ui-widget {
    font-family: inherit
}

.ui-datepicker {
    border-color: #f7f7f7!important;
    border-radius: 12px;
    -webkit-box-shadow: 0 20px 70px rgba(0,0,0,.07);
    box-shadow: 0 20px 70px rgba(0,0,0,.07)
}

.ui-datepicker table,.ui-datepicker table tr {
    border: none
}

.ui-datepicker .ui-state-default {
    width: 30px;
    height: 30px;
    padding: 0;
    background: 0 0;
    border: none;
    font-size: 14px;
    line-height: 30px;
    border-radius: 50em;
    text-align: center
}

.ui-datepicker .ui-state-default:hover {
    background-color: var(--color-primary);
    color: #fff
}

.ui-datepicker .ui-state-active,.ui-datepicker .ui-state-highlight {
    color: #fff
}

.ui-datepicker .ui-state-highlight {
    background-color: #666
}

.ui-datepicker .ui-state-active {
    background-color: var(--color-primary);
    -webkit-box-shadow: 0 5px 12px rgba(0,0,0,.12);
    box-shadow: 0 5px 12px rgba(0,0,0,.12)
}

.ui-datepicker-header {
    border: none;
    border-bottom: 1px solid #eee;
    border-radius: 0;
    background: 0 0
}

.ui-datepicker-next,.ui-datepicker-prev {
    top: 2px!important;
    border: none!important;
    background: none!important;
    cursor: pointer;
    font-weight: 400
}

.ui-datepicker-next span,.ui-datepicker-prev span {
    position: relative;
    background: none!important;
    font-size: 12px;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s
}

.ui-datepicker-next span:before,.ui-datepicker-prev span:before {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-family: fontAwesome;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-indent: 0
}

.ui-datepicker-prev {
    left: 2px!important
}

.ui-datepicker-prev span:before {
    content: '\f060'
}

.ui-datepicker-prev:hover span {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px)
}

.ui-datepicker-next {
    right: 2px!important
}

.ui-datepicker-next span:before {
    content: '\f061'
}

.ui-datepicker-next:hover span {
    -webkit-transform: translateX(2px);
    transform: translateX(2px)
}

.liquid-form-reservation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

.liquid-form-reservation .input-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 285px;
    padding: 0 15px;
    margin-bottom: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-left: 1px solid #d8dbe2
}

.liquid-form-reservation .input-container:first-child,.liquid-form-reservation .input-container:last-child {
    border: none
}

.liquid-form-reservation .input-container:first-child {
    padding-left: 0
}

.liquid-form-reservation .liquid-input-icon-right {
    right: 30px
}

.liquid-form-reservation .liquid-input-icon-left {
    left: 30px;
    color: var(--color-primary)
}

.liquid-form-reservation .ui-selectmenu-button,.liquid-form-reservation button,.liquid-form-reservation input,.liquid-form-reservation select {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

.liquid-form-reservation .ui-selectmenu-button:focus,.liquid-form-reservation button:focus,.liquid-form-reservation input:focus,.liquid-form-reservation select:focus {
    outline: 0
}

.liquid-form-reservation .ui-selectmenu-button,.liquid-form-reservation input,.liquid-form-reservation select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 30px 0 60px;
    background: 0 0;
    border: none;
    font-size: .75em;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: #3f4147
}

.liquid-form-reservation .ui-selectmenu-button::-webkit-input-placeholder,.liquid-form-reservation input::-webkit-input-placeholder,.liquid-form-reservation select::-webkit-input-placeholder {
    color: #3f4147
}

.liquid-form-reservation .ui-selectmenu-button::-moz-placeholder,.liquid-form-reservation input::-moz-placeholder,.liquid-form-reservation select::-moz-placeholder {
    color: #3f4147
}

.liquid-form-reservation .ui-selectmenu-button:-moz-placeholder,.liquid-form-reservation input:-moz-placeholder,.liquid-form-reservation select:-moz-placeholder {
    color: #3f4147
}

.liquid-form-reservation .ui-selectmenu-button:-ms-input-placeholder,.liquid-form-reservation input:-ms-input-placeholder,.liquid-form-reservation select:-ms-input-placeholder {
    color: #3f4147
}

.liquid-form-reservation button {
    text-align: center
}

.liquid-form-reservation button>span {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.liquid-form-reservation .ui-selectmenu-icon {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    display: none
}

.liquid-form-reservation .ui-selectmenu-button {
    width: 100%
}

.liquid-form-tour {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 40px 30px 20px;
    -webkit-box-shadow: 0 20px 50px rgba(0,0,0,.07);
    box-shadow: 0 20px 50px rgba(0,0,0,.07)
}

.liquid-form-tour .ui-selectmenu-button,.liquid-form-tour input,.liquid-form-tour select,.liquid-form-tour textarea {
    padding-left: 25px;
    padding-right: 25px;
    border-color: transparent;
    -webkit-box-shadow: 0 0 7px rgba(0,0,0,.07) inset;
    box-shadow: 0 0 7px rgba(0,0,0,.07) inset;
    background-color: #f4f5f7
}

.liquid-form-tour .ui-selectmenu-button:focus,.liquid-form-tour input:focus,.liquid-form-tour select:focus,.liquid-form-tour textarea:focus {
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.07);
    box-shadow: 0 0 0 rgba(0,0,0,.07)
}

.liquid-form-tour .input-container {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    margin: 10px .4%
}

.liquid-form-tour .input-container:first-child {
    margin-left: 0
}

.liquid-form-tour .input-container:last-child {
    margin-right: 0
}

.liquid-form-tour .liquid-tour-hot-destinations {
    margin-top: 1em;
    margin-bottom: .5em
}

.liquid-tour-hot-destinations {
    font-size: .875em
}

.liquid-tour-hot-destinations>a,.liquid-tour-hot-destinations>span {
    margin-right: 15px
}

.liquid-tour-hot-destinations>span {
    color: #a7a9b8
}

.liquid-tour-hot-destinations>span i {
    margin-left: 15px
}

.liquid-tour-hot-destinations>a:after {
    content: '\2758';
    margin-left: 15px;
    color: #d8dbe2
}

.liquid-tour-hot-destinations>a:last-child:after {
    content: none
}

.liquid-progressbar {
    position: relative;
    margin-top: 2.2em;
    margin-bottom: 3.25em
}

.liquid-progressbar.values-not-encountering .liquid-progressbar-percentage {
    opacity: 1
}

.liquid-progressbar-inner {
    position: relative;
    display: block;
    width: 100%;
    height: 15px;
    background-color: #eef0f3
}

.liquid-progressbar-bar {
    display: inline-block;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    color: #181b31;
    background-color: var(--color-primary)
}

.liquid-progressbar-percentage {
    position: absolute;
    top: -2.2em;
    right: 0;
    opacity: 0;
    -webkit-transition: opacity .1s;
    transition: opacity .1s
}

.liquid-progressbar-title {
    margin: 0;
    position: absolute;
    top: -2.2em;
    left: 0;
    font-size: 1em;
    color: #808291
}

.liquid-progressbar-thin .liquid-progressbar-inner {
    height: 1px
}

.liquid-progressbar-thick .liquid-progressbar-inner {
    height: 2px
}

.liquid-progressbar-sm .liquid-progressbar-inner {
    height: 10px
}

.liquid-progressbar-lg .liquid-progressbar-inner {
    height: 20px
}

.liquid-progressbar-xl .liquid-progressbar-inner {
    height: 30px
}

.liquid-progressbar-round .liquid-progressbar-inner {
    border-radius: 3px
}

.liquid-progressbar-circle .liquid-progressbar-inner {
    border-radius: 50em
}

.liquid-progressbar-values-bottom {
    margin-top: 0;
    margin-bottom: 3.5em
}

.liquid-progressbar-values-bottom .liquid-progressbar-percentage,.liquid-progressbar-values-bottom .liquid-progressbar-title {
    top: auto;
    bottom: -2.2em
}

.liquid-progressbar-values-inside .liquid-progressbar-bar {
    color: #fff
}

.liquid-progressbar-values-inside .liquid-progressbar-percentage,.liquid-progressbar-values-inside .liquid-progressbar-title {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.liquid-progressbar-values-inside .liquid-progressbar-title {
    left: 15px;
    right: auto;
    white-space: nowrap;
    color: #fff
}

.ld-prgbr-details-sm {
    font-size: 14px
}

.liquid-progressbar-percentage.style-tooltip {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 6px 10px;
    margin-top: -10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #666;
    line-height: 1.5em;
    color: #fff
}

.liquid-progressbar-percentage.style-tooltip:after {
    content: '';
    display: inline-block;
    width: 0;
    position: absolute;
    bottom: -4px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: #666 transparent transparent
}

.liquid-progressbar-percentage.style-tooltip.round {
    border-radius: 4px
}

.liquid-progressbar-percentage.style-tooltip.circle {
    margin-top: -17px;
    padding: 0;
    width: 38px;
    height: 38px;
    font-size: 12px;
    border-radius: 50em
}

.liquid-progressbar-percentage.style-tooltip.circle:after {
    bottom: -3px
}

.liquid-progressbar-count-hide .liquid-progressbar-value {
    display: none
}

.ld-prgbr-bar-shadow .liquid-progressbar-bar {
    -webkit-box-shadow: 0 8px 50px rgba(0,0,0,.1);
    box-shadow: 0 8px 50px rgba(0,0,0,.1)
}

.ld-prgbr-circle {
    margin-top: 0
}

.ld-prgbr-circle .liquid-progressbar-bar,.ld-prgbr-circle .liquid-progressbar-inner,.ld-prgbr-circle .liquid-progressbar-percentage {
    background: 0 0;
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    top: auto;
    left: auto;
    opacity: 1
}

.ld-prgbr-circle .liquid-progressbar-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: auto;
    text-align: center
}

.ld-prgbr-circle .liquid-progressbar-percentage {
    font-size: 48px;
    font-weight: 700;
    color: #000
}

.ld-prgbr-circle .liquid-progressbar-details {
    margin-top: 20px
}

.ld-prgbr-circle .liquid-progressbar-title {
    position: relative;
    top: auto;
    left: auto;
    font-size: 17px;
    text-align: center;
    color: #4E4E4E
}

.liquid-milestone {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 2em
}

.liquid-milestone .liquid-milestone-time {
    margin: 0 15px 0 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 36px;
    line-height: 1em;
    font-weight: 700;
    letter-spacing: -.01em;
    color: var(--color-primary)
}

.liquid-milestone .liquid-milestone-time span {
    color: inherit
}

.liquid-milestone-content {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    overflow: hidden
}

.liquid-milestone-content h5 {
    margin: 0;
    font-weight: 700
}

.liquid-milestone-content p {
    margin: 0
}

.liquid-ig-feed {
    margin-bottom: 2em
}

.liquid-ig-feed-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    list-style: none
}

.liquid-ig-feed-list>li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    position: relative
}

.liquid-ig-feed-list>li img {
    width: 100%;
    height: auto
}

.liquid-ig-feed-list>li:hover .liquid-ig-feed-overlay {
    opacity: 1;
    visibility: visible
}

.liquid-ig-feed-list>li:hover .liquid-ig-feed-overlay i,.liquid-ig-feed-list>li:hover .liquid-ig-feed-overlay span {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.liquid-ig-feed-list>li:hover .liquid-ig-feed-overlay span {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.liquid-stretch-images .liquid-ig-feed-list img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

[data-list-columns="1"] .liquid-ig-feed-list>li {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%
}

[data-list-columns="2"] .liquid-ig-feed-list>li {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%
}

[data-list-columns="3"] .liquid-ig-feed-list>li {
    -ms-flex-preferred-size: 33.333333333333%;
    flex-basis: 33.333333333333%
}

[data-list-columns="4"] .liquid-ig-feed-list>li {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%
}

[data-list-columns="6"] .liquid-ig-feed-list>li {
    -ms-flex-preferred-size: 16.666666666667%;
    flex-basis: 16.666666666667%
}

[data-list-gap="2"] .liquid-ig-feed-list {
    margin-left: -1px;
    margin-right: -1px
}

[data-list-gap="2"] .liquid-ig-feed-list>li {
    padding: 1px
}

[data-list-gap="4"] .liquid-ig-feed-list {
    margin-left: -2px;
    margin-right: -2px
}

[data-list-gap="4"] .liquid-ig-feed-list>li {
    padding: 2px
}

[data-list-gap="6"] .liquid-ig-feed-list {
    margin-left: -3px;
    margin-right: -3px
}

[data-list-gap="6"] .liquid-ig-feed-list>li {
    padding: 3px
}

[data-list-gap="8"] .liquid-ig-feed-list {
    margin-left: -4px;
    margin-right: -4px
}

[data-list-gap="8"] .liquid-ig-feed-list>li {
    padding: 4px
}

.liquid-ig-feed-overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: var(--color-primary);
    color: #fff;
    -webkit-transition: opacity .3s,visibility 0,3s;
    transition: opacity .3s,visibility 0,3s
}

.liquid-ig-feed-overlay i,.liquid-ig-feed-overlay span {
    display: block;
    opacity: 0;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    -webkit-transition: opacity .3s,-webkit-transform .3s;
    transition: opacity .3s,-webkit-transform .3s;
    transition: opacity .3s,transform .3s;
    transition: opacity .3s,transform .3s,-webkit-transform .3s
}

.liquid-ig-feed-overlay i:only-child,.liquid-ig-feed-overlay span:only-child {
    margin-bottom: 0
}

.liquid-ig-feed-overlay i {
    margin-bottom: .34em;
    font-size: 50px
}

.liquid-ig-feed-overlay span {
    text-transform: uppercase;
    letter-spacing: .01em;
    font-weight: 700
}

.liquid-ig-feed-overlay:hover {
    color: #fff
}

.liquid-twitter-feed {
    margin-bottom: 2em
}

.liquid-twitter-feed-list {
    margin: 0;
    padding: 0;
    list-style-type: none
}

.liquid-twitter-feed-list>li {
    margin-bottom: .9375em;
    line-height: 1.7142857143em
}

.liquid-twitter-feed-list>li:last-child {
    margin-bottom: 0
}

.liquid-twitter-feed-list p {
    margin-bottom: 0
}

.liquid-twitter-feed-list blockquote {
    font-style: normal;
    margin: 0;
    font-size: 1em
}

.liquid-twitter-feed-icon {
    display: block;
    margin-bottom: .7142857143em;
    font-size: 28px;
    line-height: 1em;
    color: #1da1f2
}

.liquid-tweet-time,.liquid-twitter-feed-list .twitter-tweet>a {
    display: block;
    font-size: 11px;
    letter-spacing: .025em;
    text-transform: uppercase
}

.liquid-img-group-container {
    position: relative;
    margin-bottom: 2em
}

.liquid-img-group-container[data-custom-animations] {
    opacity: 1;
    visibility: visible
}

.liquid-img-group-container[data-custom-animations]:not(.ca-initvalues-applied) {
    min-height: 45px
}

.liquid-img-group-container[data-custom-animations]:before {
    content: '';
    display: inline-block;
    width: 44px;
    height: 44px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -22px 0 0 -22px;
    background-image: url(data:image/svg+xml;base64,PCEtLSBCeSBTYW0gSGVyYmVydCAoQHNoZXJiKSwgZm9yIGV2ZXJ5b25lLiBNb3JlIEAgaHR0cDovL2dvby5nbC83QUp6YkwgLS0+Cjxzdmcgd2lkdGg9IjM4IiBoZWlnaHQ9IjM4IiB2aWV3Qm94PSIwIDAgMzggMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjMDAwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlLXdpZHRoPSIyIj4KICAgICAgICAgICAgPGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjIiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMzYgMThjMC05Ljk0LTguMDYtMTgtMTgtMTgiPgogICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0KICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iCiAgICAgICAgICAgICAgICAgICAgdHlwZT0icm90YXRlIgogICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiCiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIKICAgICAgICAgICAgICAgICAgICBkdXI9IjAuOHMiCiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICAgICAgICAgICAgPC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    background-size: 44px;
    background-repeat: no-repeat;
    background-position: center
}

.liquid-img-group-container[data-custom-animations] .liquid-img-group-single {
    opacity: 0;
    visibility: hidden
}

.liquid-img-group-container[data-custom-animations].ca-initvalues-applied:before {
    content: none
}

.liquid-img-group-container[data-custom-animations].ca-initvalues-applied .liquid-img-group-single {
    opacity: 1;
    visibility: visible
}

[data-row-bg]:not(.row-bg-appended) .liquid-img-group-container[data-custom-animations] {
    opacity: 0;
    visibility: hidden
}

.liquid-img-group-img-container {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    border-radius: inherit;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.liquid-img-container-inner {
    width: 100%;
    position: relative
}

.liquid-img-group-single {
    display: block;
    position: relative
}

.liquid-img-group-single .ld-parallax-wrap,.liquid-img-group-single .liquid-img-container-inner,.liquid-img-group-single .liquid-img-group-img-container,.liquid-img-group-single figure,.liquid-img-group-single img {
    border-radius: inherit
}

.liquid-img-group-single .ld-parallax-wrap,.liquid-img-group-single figure {
    width: 100%
}

.liquid-img-group-single figure {
    display: inline-block;
    margin: 0
}

.liquid-img-group-single[data-roundness="2"] {
    border-radius: 2px
}

.liquid-img-group-single[data-roundness="4"] {
    border-radius: 4px
}

.liquid-img-group-single[data-roundness="6"] {
    border-radius: 6px
}

.liquid-img-group-single[data-roundness="8"] {
    border-radius: 8px
}

.liquid-img-group-single[data-animate-shadow][data-shadow-style="1"].is-in-view .ld-parallax-wrap.overflow-hidden,.liquid-img-group-single[data-animate-shadow][data-shadow-style="1"].is-in-view .liquid-img-container-inner,.liquid-img-group-single[data-shadow-style="1"] .ld-parallax-wrap.overflow-hidden,.liquid-img-group-single[data-shadow-style="1"] .liquid-img-container-inner {
    -webkit-box-shadow: 0 25px 30px rgba(0,0,0,.07);
    box-shadow: 0 25px 30px rgba(0,0,0,.07)
}

.liquid-img-group-single[data-animate-shadow][data-shadow-style="2"].is-in-view .ld-parallax-wrap.overflow-hidden,.liquid-img-group-single[data-animate-shadow][data-shadow-style="2"].is-in-view .liquid-img-container-inner,.liquid-img-group-single[data-shadow-style="2"] .ld-parallax-wrap.overflow-hidden,.liquid-img-group-single[data-shadow-style="2"] .liquid-img-container-inner {
    -webkit-box-shadow: 0 30px 50px rgba(0,0,0,.15);
    box-shadow: 0 30px 50px rgba(0,0,0,.15)
}

.liquid-img-group-single[data-animate-shadow][data-shadow-style="3"].is-in-view .ld-parallax-wrap.overflow-hidden,.liquid-img-group-single[data-animate-shadow][data-shadow-style="3"].is-in-view .liquid-img-container-inner,.liquid-img-group-single[data-shadow-style="3"] .ld-parallax-wrap.overflow-hidden,.liquid-img-group-single[data-shadow-style="3"] .liquid-img-container-inner {
    -webkit-box-shadow: 0 30px 70px rgba(0,0,0,.15);
    box-shadow: 0 30px 70px rgba(0,0,0,.15)
}

.liquid-img-group-single[data-animate-shadow][data-shadow-style="4"].is-in-view .ld-parallax-wrap.overflow-hidden,.liquid-img-group-single[data-animate-shadow][data-shadow-style="4"].is-in-view .liquid-img-container-inner,.liquid-img-group-single[data-shadow-style="4"] .ld-parallax-wrap.overflow-hidden,.liquid-img-group-single[data-shadow-style="4"] .liquid-img-container-inner {
    -webkit-box-shadow: 0 30px 100px rgba(0,0,0,.17);
    box-shadow: 0 30px 100px rgba(0,0,0,.17)
}

.liquid-img-group-single[data-animate-shadow] .liquid-img-container-inner {
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.3);
    box-shadow: 0 0 0 rgba(0,0,0,.3);
    -webkit-transition: -webkit-box-shadow .5s cubic-bezier(0.7,0,.3,1);
    transition: -webkit-box-shadow .5s cubic-bezier(0.7,0,.3,1);
    transition: box-shadow .5s cubic-bezier(0.7,0,.3,1);
    transition: box-shadow .5s cubic-bezier(0.7,0,.3,1),-webkit-box-shadow .5s cubic-bezier(0.7,0,.3,1)
}

.liquid-img-group-single[data-animate-shadow] img {
    -webkit-transition: opacity .65s cubic-bezier(0.5,1,.5,1);
    transition: opacity .65s cubic-bezier(0.5,1,.5,1)
}

.liquid-img-group-single[data-animate-shadow].is-in-view img {
    opacity: 1;
    -webkit-transition-delay: .35s;
    transition-delay: .35s
}

.liquid-img-group-single[data-animate-shadow].is-in-view img.ld-lazyload {
    opacity: 1E-8
}

.liquid-img-group-single[data-animate-shadow].is-in-view img.ld-lazyload.loaded {
    opacity: 1
}

.liquid-img-group-single.pos-abs {
    top: 0;
    left: 0
}

.liquid-img-group-single.custom-height-applied>.liquid-img-group-img-container,.liquid-img-group-single.custom-height-applied>.liquid-img-group-img-container>.ld-parallax-wrap {
    height: 100%
}

.liquid-img-group-single.reset-color-adjust-enabled figure {
    -webkit-transition: -webkit-filter .3s;
    transition: -webkit-filter .3s;
    transition: filter .3s;
    transition: filter .3s,-webkit-filter .3s
}

div:not(.carousel-item-inner)>.liquid-img-group-container .liquid-img-group-single.reset-color-adjust-enabled:hover figure {
    -webkit-filter: none;
    filter: none
}

.carousel-item.is-selected .liquid-img-group-single.reset-color-adjust-enabled figure {
    -webkit-filter: none;
    filter: none
}

.liquid-img-group-browser {
    display: block;
    margin-bottom: 2em
}

.liquid-img-group-browser .liquid-img-group-img-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.liquid-img-group-browser .liquid-img-group-img-container:before {
    content: '';
    display: inline-block;
    width: 100%;
    padding-top: 5.25%;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-image: url(../themes/responsiv-clean/assets/img/misc/browser-bar.jpg); */
    background-position: center top
}

.liquid-img-group-browser figure {
    background-size: cover
}

.liquid-img-group-browser figure img {
    opacity: 0;
    visibility: hidden
}

.liquid-img-group-browser .liquid-img-group-url {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 50%;
    height: 9.5%;
    position: absolute;
    top: 0;
    left: 20.2%;
    z-index: 2;
    font-size: 14px;
    color: #515151
}

.liquid-img-group-browser .liquid-img-group-url span {
    color: #cecece
}

.liquid-img-group-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 10
}

.liquid-img-group-content.content-floated-mid,.liquid-img-group-content.content-floated-mid-left,.liquid-img-group-content.content-floated-mid-right {
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}

.liquid-img-group-content.content-floated-mid {
    left: 50%
}

.liquid-img-group-content.content-floated-mid-left {
    left: 0
}

.liquid-img-group-content.content-floated-mid-right {
    left: 100%
}

.block-revealer__element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    pointer-events: none;
    opacity: 0
}

.block-revealer__content {
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    border-radius: inherit
}

.block-revealer__content img:not(.ld-lazyload) {
    opacity: 1
}

.compose-mode .liquid-img-group-container[data-custom-animations]:not(.ca-initvalues-applied) {
    min-height: 0
}

.compose-mode .liquid-img-group-container[data-custom-animations]:before {
    content: none
}

.compose-mode .liquid-img-group-container[data-custom-animations] .liquid-img-group-single {
    opacity: 1;
    visibility: visible
}

[data-row-bg]:not(.row-bg-appended) .compose-mode .liquid-img-group-container[data-custom-animations] {
    opacity: 1;
    visibility: visible
}

.compose-mode .liquid-img-group-single {
    position: relative!important;
    top: auto!important;
    left: auto!important;
    right: auto!important;
    margin: 0!important
}

.compose-mode .liquid-img-group-single[data-animate-shadow] img {
    opacity: 1
}

.compose-mode .liquid-img-group-browser figure img {
    opacity: 1;
    visibility: visible
}

.lqd-parallax-images .liquid-img-group-single:last-child {
    position: absolute;
    bottom: -10%;
    left: 30%
}

.lqd-parallax-images-2 .liquid-img-group-container:first-child {
    margin-top: 60px
}

.lqd-parallax-images-2 .liquid-img-group-container:nth-child(2) {
    position: absolute;
    bottom: 110px;
    left: 20%
}

.lqd-parallax-images-2 .liquid-img-group-container:last-child {
    position: absolute;
    bottom: 230px;
    left: 36%
}

.lqd-parallax-images-3 .liquid-img-group-single:last-child,.lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
    position: absolute
}

.lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
    top: 0;
    right: 10%;
    left: 10%
}

.lqd-parallax-images-3 .liquid-img-group-single:last-child {
    top: 12%;
    right: 23%;
    left: 13%
}

.lqd-parallax-images-04 .liquid-img-group-single:last-child,.lqd-parallax-images-04 .liquid-img-group-single:nth-child(2) {
    position: absolute
}

.lqd-parallax-images-04 .liquid-img-group-single:nth-child(2) {
    top: 0;
    right: 10%;
    left: -8%
}

.lqd-parallax-images-04 .liquid-img-group-single:last-child {
    top: 12%;
    left: 23%;
    right: 13%
}

.lqd-parallax-images-4 .liquid-counter {
    font-family: Scheherazade,serif!important;
    font-weight: 400;
    color: #bba07a
}

.lqd-parallax-images-4-alt .liquid-counter {
    font-family: inherit!important
}

.lqd-parallax-images-4-alt .liquid-counter-element {
    font-size: 80px
}

.lqd-parallax-images-6 .liquid-img-group-single:first-of-type {
    position: absolute;
    bottom: -10%;
    left: 30%
}

.lqd-parallax-images-6 .liquid-img-group-single:nth-of-type(2) {
    position: absolute
}

.lqd-parallax-images-8 .btn-icon {
    color: #fe506c;
    font-size: 20px
}

.lqd-parallax-images-8 .btn-icon-solid .btn-icon {
    background: #fff
}

.lqd-parallax-images-8 .liquid-img-group-single:last-of-type {
    position: absolute;
    right: 0;
    bottom: -10%
}

.lqd-parallax-images-9 .liquid-img-group-single {
    position: absolute
}

.lqd-parallax-images-9 .liquid-img-group-single:nth-child(1) {
    bottom: 50vh;
    left: 10%
}

.lqd-parallax-images-9 .liquid-img-group-single:nth-child(2) {
    bottom: 0;
    left: -30%
}

.lqd-parallax-images-9 .liquid-img-group-single:nth-child(3) {
    bottom: -25vh;
    left: 0
}

.lqd-parallax-images-9 .liquid-img-group-single:nth-child(4) {
    right: 35%;
    bottom: 0
}

.lqd-parallax-images-9 .liquid-img-group-single:nth-child(5) {
    right: -25%;
    bottom: 10vh
}

@media (min-width: 768px) {
    .lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
        left:20%
    }

    .lqd-parallax-images-3 .liquid-img-group-single:last-child {
        right: 0;
        left: 22.5%
    }

    .lqd-parallax-images-04 .liquid-img-group-single:last-child {
        left: 0;
        right: 22.5%
    }

    .lqd-parallax-images-04 .liquid-img-group-single:nth-child(2) {
        right: 20%
    }
}

@media (min-width: 992px) {
    .lqd-parallax-images .liquid-img-group-single:last-child {
        bottom:-6%;
        left: -45%
    }

    .lqd-parallax-images-2 .liquid-img-group-container:first-child {
        margin-top: 0;
        margin-right: -40%;
        margin-left: 60%
    }

    .lqd-parallax-images-2 .liquid-img-group-container:nth-child(2) {
        bottom: 80px;
        left: 30%
    }

    .lqd-parallax-images-2 .liquid-img-group-container:last-child {
        bottom: 240px;
        left: 17%
    }

    .lqd-parallax-images-3 .liquid-img-group-single:first-child {
        margin-left: -20%
    }

    .lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
        left: 0
    }

    .lqd-parallax-images-3 .liquid-img-group-single:last-child {
        left: -8%
    }

    .lqd-parallax-images-04 .liquid-img-group-single:first-child {
        margin-left: 12%
    }

    .lqd-parallax-images-04 .liquid-img-group-single:nth-child(2) {
        right: 0
    }

    .lqd-parallax-images-04 .liquid-img-group-single:last-child {
        right: -8%
    }

    .lqd-parallax-images-4 .liquid-img-group-container:nth-child(2) {
        margin-top: -120px;
        margin-left: -30px
    }

    .lqd-parallax-images-4 .liquid-img-group-container:last-child .liquid-img-group-single {
        margin-top: -40%;
        margin-left: -68%;
        left: -80%
    }

    .lqd-parallax-images-4-alt .liquid-img-group-container:last-child .liquid-img-group-single {
        left: 45%
    }

    .lqd-parallax-images-5 .txt-container {
        margin-top: -200px
    }

    .lqd-parallax-images-6 .liquid-img-group-single:first-of-type {
        right: -45%;
        bottom: -13%
    }

    .lqd-parallax-images-6 .liquid-img-group-single:nth-of-type(2) {
        top: -40%;
        right: -5%
    }

    .lqd-parallax-images-6 .liquid-img-group-single:last-of-type {
        left: -20%
    }

    .lqd-parallax-images-7 .liquid-img-group-single:first-of-type {
        position: absolute;
        top: 0;
        left: 0
    }

    .lqd-parallax-images-7 .liquid-img-group-single:last-of-type {
        margin-right: -100%;
        margin-left: 30%;
        margin-bottom: -200px
    }

    .lqd-parallax-images-8 .liquid-img-group-single:last-of-type {
        right: 0;
        bottom: -8%
    }

    .lqd-parallax-images-10 .liquid-img-group-single:first-of-type {
        margin-right: 20vw;
        margin-left: -14vw;
        top: -120px
    }

    .lqd-parallax-images-10 .liquid-img-group-single:last-of-type {
        margin-top: -70%;
        margin-left: 25%
    }
}

@media screen and (max-width: 991px) {
    .lqd-parallax-images-5 .txt-container {
        -webkit-transform:none!important;
        transform: none!important
    }
}

.page-nav {
    margin-top: 1em;
    margin-bottom: 1em
}

.page-nav.bordered-top {
    margin-top: 2em;
    padding-top: 45px;
    border-top: 3px solid #181b31
}

.page-links,ul.pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    clear: both
}

ul.pagination {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1em;
    margin: 3em 0 6em
}

ul.pagination .page-numbers,ul.pagination li {
    display: inline-block
}

ul.pagination li {
    margin-left: 4px;
    margin-right: 4px
}

ul.pagination .page-numbers.current {
    color: #16171e
}

.page-links {
    float: left;
    margin-top: 1.5em
}

.page-links a span,.page-links>span {
    display: inline-block
}

.page-links>a,.page-links>span {
    padding-left: 8px;
    padding-right: 8px
}

.page-links .page-links-title {
    padding: 0
}

.liquid-blog-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

.liquid-blog-grid:after,.liquid-blog-grid:before {
    content: none
}

.liquid-blog-grid>.carousel-container,.liquid-blog-grid>div {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto
}

[data-liquid-masonry=true] {
    -webkit-transition: height .45s cubic-bezier(0.23,1,.32,1);
    transition: height .45s cubic-bezier(0.23,1,.32,1)
}

.ld-parallax-wrap.overflow-hidden:after,.ld-parallax-wrap.overflow-hidden:before {
    content: '';
    display: table
}

.ld-parallax-wrap.overflow-hidden:after {
    clear: both
}

.liquid-parallax-bg {
    position: relative
}

.liquid-parallax-bg:not(.vc_row):not(.titlebar) {
    overflow: hidden
}

.liquid-parallax-container,.liquid-parallax-figure {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: inherit;
    background-position: inherit;
    background-repeat: no-repeat
}

.liquid-parallax-container {
    overflow: hidden
}

[data-responsive-bg][data-parallax]:not(.loaded) .liquid-parallax-figure {
    background-image: none!important
}

.lqd-parallax-sentinel {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: -1
}

.ld-tm-name,.ld-tm-pos {
    margin: 0;
    line-height: 1.5em
}

.ld-tm-name {
    font-size: 24px;
    font-weight: 700
}

.ld-tm-name.size-sm {
    font-size: 20px
}

.ld-tm-pos {
    font-size: 12px
}

.ld-tm-img img {
    width: 100%
}

.ld-tm-img.text-center img {
    width: auto
}

.ld-tm-img.text-center img.circle {
    width: 160px
}

.ld-tm-info .ld-tm-social:empty {
    display: none
}

.ld-tm-info.ld-overlay {
    background-color: rgba(255,255,255,.9);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s,visibility .3s;
    transition: opacity .3s,visibility .3s
}

.ld-tm-info.ld-overlay .ld-tm-name,.ld-tm-info.ld-overlay .ld-tm-pos,.ld-tm-info.ld-overlay .ld-tm-social {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
    -webkit-transition: opacity .45s cubic-bezier(0.19,1,.22,1),-webkit-transform .45s cubic-bezier(0.19,1,.22,1);
    transition: opacity .45s cubic-bezier(0.19,1,.22,1),-webkit-transform .45s cubic-bezier(0.19,1,.22,1);
    transition: transform .45s cubic-bezier(0.19,1,.22,1),opacity .45s cubic-bezier(0.19,1,.22,1);
    transition: transform .45s cubic-bezier(0.19,1,.22,1),opacity .45s cubic-bezier(0.19,1,.22,1),-webkit-transform .45s cubic-bezier(0.19,1,.22,1)
}

.ld-tm-info.ld-overlay .ld-tm-name {
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.ld-tm-info.ld-overlay .ld-tm-pos {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.ld-tm-info .iconbox-xs {
    margin-bottom: 10px
}

.ld-tm-info .iconbox-xs:last-child {
    margin-bottom: 0
}

.ld-tm-info .iconbox-xs .iconbox-icon-container {
    font-size: 15px
}

.ld-tm-info .iconbox-xs h3 {
    font-size: 14px
}

.ld-tm-info.text-center .iconbox-xs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
}

.ld-tm-info.text-center .iconbox-xs h3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.ld-tm {
    margin-bottom: 30px;
    color: #181b31
}

.ld-tm a,.ld-tm h1,.ld-tm h2,.ld-tm h3,.ld-tm h4,.ld-tm h5,.ld-tm h6 {
    color: inherit
}

.ld-tm .ld-tm-pos.color-primary,.ld-tm a:hover {
    color: var(--color-primary)
}

.ld-tm.text-light {
    color: #fff
}

.ld-tm.text-light .ld-overlay {
    background-color: rgba(24,27,49,.9)
}

.ld-tm:hover .ld-overlay {
    opacity: 1;
    visibility: visible
}

.ld-tm:hover .ld-overlay .ld-tm-name {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.ld-tm:hover .ld-overlay .ld-tm-pos {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.ld-tm:hover .ld-overlay .ld-tm-social {
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.ld-tm:hover .ld-overlay .ld-tm-name,.ld-tm:hover .ld-overlay .ld-tm-pos,.ld-tm:hover .ld-overlay .ld-tm-social {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.ld-tm:hover .ld-overlay .ld-tm-pos {
    opacity: .55
}

.ld-tm.text-light:hover .ld-overlay .ld-tm-pos {
    opacity: 1
}

.ld-tm-circ {
    position: relative;
    padding-bottom: 100%
}

.ld-tm-circ .ld-tm-avatar {
    position: absolute;
    border-radius: 50em
}

.ld-tm-circ .ld-tm-avatar figure {
    border-radius: inherit;
    -webkit-box-shadow: 0 15px 70px rgba(0,0,0,.13);
    box-shadow: 0 15px 70px rgba(0,0,0,.13)
}

.ld-tm-circ .ld-tm-avatar img {
    width: 70px;
    height: auto;
    border-radius: inherit
}

.ld-tm-circ .ld-tm-bg {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    background: -webkit-gradient(linear,right top,left top,from(#ffa5cd),to(#ffba83));
    background: linear-gradient(270deg,#ffa5cd 0,#ffba83 100%)
}

.ld-tm-circ .ld-tm-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.ld-tm-circ .ld-tm-container>div {
    position: absolute;
    border-radius: 50em
}

.ld-tm-circ-outer {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0
}

.ld-tm-circ-outer .ld-tm-bg {
    opacity: .1
}

.ld-tm-circ-middle {
    width: 66.67%;
    height: 66.67%;
    top: 16.665%;
    left: 16.665%;
    z-index: 1
}

.ld-tm-circ-middle .ld-tm-bg {
    opacity: .2
}

.ld-tm-circ-inner {
    width: 31.9%;
    height: 31.9%;
    top: 34.05%;
    left: 34.05%;
    z-index: 2
}

.ld-tm-circ-inner .ld-tm-bg {
    opacity: .3
}

.lqd-parallax-team-members .liquid-img-group-container:first-child {
    top: -80px;
    left: -90%
}

.lqd-parallax-team-members .liquid-img-group-container:nth-child(2) {
    margin-top: -400px
}

.lqd-parallax-team-members .liquid-img-group-container:last-child {
    margin-top: -300px;
    left: -90%
}

.ld-pf-btns {
    position: relative;
    z-index: 3
}

.pf-btns-mid .ld-pf-btns {
    margin-top: auto!important;
    margin-bottom: auto!important
}

.ld-pf-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 46px;
    height: 46px;
    font-size: 18px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition-property: background,color,border;
    transition-property: background,color,border;
    will-change: transform,opacity
}

.ld-pf-btn-brd {
    border: 1px solid rgba(0,0,0,.5);
    color: #000
}

.ld-pf-btn-brd:hover {
    border-color: #000;
    background-color: #000;
    color: #fff
}

.ld-pf-btn-solid {
    background-color: rgba(0,0,0,.5);
    color: #fff
}

.ld-pf-btn-solid:hover {
    background-color: #000
}

.ld-pf-btn-brd-thick {
    border-width: 1.6px
}

.title-size-48 .ld-pf-title {
    font-size: 48px;
    line-height: .8333333333em;
    margin-bottom: .3541666667em
}

.title-size-42 .ld-pf-title {
    font-size: 42px
}

.title-size-36 .ld-pf-title {
    font-size: 36px
}

.title-size-32 .ld-pf-title {
    font-size: 32px
}

.title-size-30 .ld-pf-title {
    font-size: 30px;
    letter-spacing: -.01em
}

.title-size-26 .ld-pf-title {
    font-size: 26px
}

.title-size-24 .ld-pf-title {
    font-size: 24px
}

.title-size-18 .ld-pf-title {
    font-size: 18px
}

.ld-pf-category {
    position: relative;
    z-index: 3;
    font-size: 16px
}

.ld-pf-category .read-more {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap
}

.ld-pf-category p {
    margin: 0
}

.ld-pf-category.size-lg {
    font-size: 15px
}

.ld-pf-category.size-md {
    font-size: 14px
}

.ld-pf-category.size-sm {
    font-size: 12px
}

.ld-pf-category-item {
    position: relative
}

.ld-pf-category-lined {
    padding-left: 2.5em
}

.ld-pf-category-lined:after,.ld-pf-category-lined:before {
    content: '';
    display: inline-block;
    width: 2em;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -.5px;
    margin-right: .25em;
    margin-bottom: .25em;
    background-color: #000
}

.ld-pf-category-lined:after {
    background-color: var(--color-primary);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s
}

.ld-pf-category-lined .read-more {
    left: 2.5em
}

.ld-pf-category-lined .read-more[data-split-text=true] {
    opacity: 0
}

.ld-pf-category-lined .read-more .split-inner {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0
}

.ld-pf-category-lined .read-more.split-text-applied {
    opacity: 1
}

.ld-pf-image {
    position: relative;
    overflow: hidden
}

.ld-pf-image figure {
    height: 100%;
    background-position: center;
    background-size: cover;
    -webkit-transition: -webkit-transform .3s linear,-webkit-filter .3s linear;
    transition: -webkit-transform .3s linear,-webkit-filter .3s linear;
    transition: transform .3s linear,filter .3s linear;
    transition: transform .3s linear,filter .3s linear,-webkit-transform .3s linear,-webkit-filter .3s linear
}

.ld-pf-image .liquid-parallax-figure {
    -webkit-transition: -webkit-filter .3s linear;
    transition: -webkit-filter .3s linear;
    transition: filter .3s linear;
    transition: filter .3s linear,-webkit-filter .3s linear
}

.ld-pf-image img {
    width: 100%
}

.ld-pf-image .liquid-parallax-bg>.ld-lazyload {
    opacity: 0
}

.ld-pf-image .ld-pf-bg {
    z-index: 2;
    opacity: 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.pf-details-inside .ld-pf-image img {
    visibility: hidden
}

.ld-pf-image.shadowed {
    -webkit-box-shadow: 0 12px 60px rgba(0,0,0,.12);
    box-shadow: 0 12px 60px rgba(0,0,0,.12)
}

.ld-pf-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 45px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    z-index: 2
}

.ld-pf-details>* {
    margin: 0;
    line-height: 1.25em;
    color: inherit
}

.ld-pf-details h1,.ld-pf-details h2,.ld-pf-details h3,.ld-pf-details h4,.ld-pf-details h5,.ld-pf-details h6 {
    color: #000
}

.ld-pf-details h3 {
    margin-bottom: .15em
}

.ld-pf-details .split-inner {
    display: inline-block
}

.pf-details-h-mid .ld-pf-details {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.pf-details-h-str .ld-pf-details {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.pf-details-h-end .ld-pf-details {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.pf-details-v-str .ld-pf-details {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.pf-details-v-mid .ld-pf-details {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
}

.pf-details-v-end .ld-pf-details {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.pf-details-inside .ld-pf-details {
    position: absolute;
    top: 0;
    left: 0
}

.pf-details-full .ld-pf-details {
    width: 100%;
    height: 100%
}

.pf-details-boxed .ld-pf-details {
    width: 75%;
    padding: 30px
}

.pf-details-w-auto .ld-pf-details {
    width: auto
}

.pf-details-circle .ld-pf-details {
    width: 260px;
    height: 260px;
    padding: 2em 3em 3.5em 3.5em;
    border-radius: 50em
}

.pf-details-h-mid.pf-details-boxed .ld-pf-details {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.pf-details-h-end.pf-details-boxed .ld-pf-details {
    left: auto;
    right: 0
}

.pf-details-v-mid.pf-details-boxed .ld-pf-details {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.pf-details-v-end.pf-details-boxed .ld-pf-details {
    top: auto;
    bottom: 0
}

.pf-details-v-mid.pf-details-h-mid.pf-details-boxed .ld-pf-details {
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%)
}

.pf-details-pull-up .ld-pf-details {
    margin-top: -95px
}

.pf-details-pull-up-half .ld-pf-details {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.pf-details-pull-down .ld-pf-details {
    margin-bottom: -50px
}

.pf-details-pull-left .ld-pf-details {
    margin-left: -35px
}

.pf-details-pull-right .ld-pf-details {
    margin-left: 30px
}

.pf-details-inside [data-hover3d=true] .ld-pf-details {
    -webkit-transform: translate3d(0,0,.01px);
    transform: translate3d(0,0,.01px);
    -webkit-transform-style: inherit;
    transform-style: inherit;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: inherit;
    perspective: inherit
}

.ld-pf-details-inner {
    display: inherit;
    width: 100%;
    height: 100%;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: inherit;
    flex-flow: inherit;
    position: relative;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s,visibility .3s;
    transition: opacity .3s,visibility .3s
}

.ld-pf-details-inner>:first-child {
    margin-top: 0
}

.ld-pf-details-inner>:last-child {
    margin-bottom: 0
}

.ld-pf-details-inner .ld-pf-details-inner {
    width: auto!important;
    height: auto!important;
    -webkit-transform: none!important;
    transform: none!important
}

.pf-contents-mid .ld-pf-details-inner {
    -ms-flex-item-align: center;
    align-self: center;
    margin: auto
}

.pf-details-inner-full .ld-pf-details-inner {
    width: 100%;
    height: 100%
}

.pf-details-visible .ld-pf-details-inner {
    opacity: 1;
    visibility: visible
}

.pf-details-inside [data-hover3d=true] .ld-pf-details-inner {
    -webkit-transform: translate3d(0,0,50px) scale(0.78);
    transform: translate3d(0,0,50px) scale(0.78)
}

.ld-pf-item .ld-pf-bg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    background-color: rgba(255,255,255,.8);
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    will-change: transform,opacity
}

.ld-pf-item.pf-details-visible .ld-pf-bg {
    opacity: 1
}

.ld-pf-item.pf-bg-shadow .ld-pf-bg {
    -webkit-box-shadow: 0 13px 50px rgba(0,0,0,.1);
    box-shadow: 0 13px 50px rgba(0,0,0,.1)
}

.ld-pf-item.pf-bg-hidden .ld-pf-bg {
    opacity: 0
}

.ld-pf-wrap[data-hover3d=true] {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    overflow: visible
}

[data-hover3d] .ld-pf-inner {
    -webkit-transform-style: inherit;
    transform-style: inherit;
    -webkit-backface-visibility: inherit;
    backface-visibility: inherit;
    -webkit-perspective: inherit;
    perspective: inherit;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0)
}

.ld-pf-item {
    position: relative;
    margin-bottom: 30px;
    border-radius: 0
}

.ld-pf-item div,.ld-pf-item figure,.ld-pf-item img {
    border-radius: inherit
}

.ld-pf-item .liquid-overlay-link {
    z-index: 2
}

.ld-pf-item time {
    font-size: 12px
}

.ld-pf-item [data-split-text]:not(.split-text-applied) {
    visibility: hidden
}

.flickity-equal-cells .carousel-item .ld-pf-item {
    height: 100%;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    margin-bottom: 0
}

.flickity-equal-cells .carousel-item .ld-pf-item.pf-details-inside .ld-pf-image,.flickity-equal-cells .carousel-item .ld-pf-item.pf-details-inside .ld-pf-inner {
    height: 100%
}

.flickity-equal-cells .carousel-item .ld-pf-item.pf-hover-shadow {
    height: calc(100% - 50px);
    max-height: 550px
}

.ld-pf-item.pf-details-inside {
    overflow: hidden
}

.ld-pf-item:hover .ld-pf-bg {
    opacity: 1
}

.ld-pf-item:hover .ld-pf-details-inner {
    opacity: 1;
    visibility: visible
}

.ld-pf-item:hover .ld-pf-category-lined:after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.ld-pf-item:hover .pf-extra-arrow {
    height: 32px;
    margin-bottom: 1em
}

.ld-pf-item:hover .pf-extra-arrow line,.ld-pf-item:hover .pf-extra-arrow polyline {
    stroke-dashoffset: 0;
    -webkit-transition: stroke-dasharray .6s cubic-bezier(0.23,1,.32,1),stroke-dashoffset .6s cubic-bezier(0.23,1,.32,1);
    transition: stroke-dasharray .6s cubic-bezier(0.23,1,.32,1),stroke-dashoffset .6s cubic-bezier(0.23,1,.32,1);
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.pf-carousel-equal-height .carousel-item {
    max-height: 550px
}

.ld-pf-semiround {
    border-radius: 4px
}

.ld-pf-round {
    border-radius: 8px
}

.ld-pf-circle {
    border-radius: 50em
}

.liquid-portfolio-list-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.liquid-portfolio-list-row:after,.liquid-portfolio-list-row:before {
    content: none
}

.pf-extra-arrow {
    position: relative;
    height: 0;
    -webkit-transition: height .3s cubic-bezier(0.86,0,.07,1),margin .3s;
    transition: height .3s cubic-bezier(0.86,0,.07,1),margin .3s
}

.pf-extra-arrow line,.pf-extra-arrow polyline {
    -webkit-transition: stroke-dasharray .25s cubic-bezier(0.23,1,.32,1),stroke-dashoffset .25s cubic-bezier(0.23,1,.32,1);
    transition: stroke-dasharray .25s cubic-bezier(0.23,1,.32,1),stroke-dashoffset .25s cubic-bezier(0.23,1,.32,1);
    stroke-dasharray: 30;
    stroke-dashoffset: 30
}

.grid-stamp {
    position: absolute;
    top: 0;
    left: 0
}

.grid-stamp.is-right {
    left: auto;
    right: 0
}

@media screen and (max-width: 768px) {
    .grid-stamp.col-xs-12,.grid-stamp:not([class*=col-xs-]) {
        display:none
    }
}

.ld-pf-item.ld-pf-light {
    color: rgba(255,255,255,.7)
}

.ld-pf-item.ld-pf-light h1,.ld-pf-item.ld-pf-light h2,.ld-pf-item.ld-pf-light h3,.ld-pf-item.ld-pf-light h4,.ld-pf-item.ld-pf-light h5,.ld-pf-item.ld-pf-light h6 {
    color: #fff
}

.ld-pf-item.ld-pf-light .ld-pf-details,.ld-pf-item.ld-pf-light .ld-pf-details-inner,.ld-pf-item.ld-pf-light a {
    color: inherit
}

.ld-pf-item.ld-pf-light a:hover {
    color: #fff
}

.ld-pf-item.ld-pf-light .ld-pf-bg {
    background-color: rgba(0,0,0,.8)
}

.ld-pf-item.ld-pf-light .pf-extra-arrow svg {
    fill: #fff
}

.ld-pf-item.ld-pf-light .ld-pf-category-lined:before {
    background-color: #fff
}

.ld-pf-item.ld-pf-light .ld-pf-btn {
    color: #fff
}

.ld-pf-item.ld-pf-light .ld-pf-btn-brd {
    border-color: rgba(255,255,255,.5)
}

.ld-pf-item.ld-pf-light .ld-pf-btn-brd:hover {
    border-color: #fff;
    background-color: #fff;
    color: #000
}

.ld-pf-item.ld-pf-light .ld-pf-btn-solid {
    background-color: rgba(255,255,255,.5);
    color: #000
}

.ld-pf-item.ld-pf-light .ld-pf-btn-solid:hover {
    background-color: #fff
}

.ld-pf-item.ld-pf-light-alt {
    color: #fff
}

.ld-pf-item.ld-pf-dark {
    color: inherit
}

.ld-pf-item.ld-pf-dark h1,.ld-pf-item.ld-pf-dark h2,.ld-pf-item.ld-pf-dark h3,.ld-pf-item.ld-pf-dark h4,.ld-pf-item.ld-pf-dark h5,.ld-pf-item.ld-pf-dark h6 {
    color: #000
}

.ld-pf-item.ld-pf-dark a {
    color: rgba(0,0,0,.5)
}

.ld-pf-item.ld-pf-dark a:hover {
    color: #000
}

.ld-pf-item.ld-pf-dark .ld-pf-btn,.ld-pf-item.ld-pf-dark .ld-pf-btn:hover {
    color: #fff
}

.ld-pf-item.ld-pf-dark-alt {
    color: #000
}

.pf-hover-blurimage:hover .ld-pf-image figure {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
}

.pf-hover-masktext .split-unit {
    overflow: hidden
}

.pf-hover-masktext .split-inner {
    display: block;
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.pf-hover-shadow .ld-pf-image {
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 0 rgba(0,0,0,0);
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    transition: box-shadow .3s;
    transition: box-shadow .3s,-webkit-box-shadow .3s
}

.pf-hover-shadow:hover .ld-pf-image {
    -webkit-box-shadow: 0 28px 45px rgba(0,0,0,.15);
    box-shadow: 0 28px 45px rgba(0,0,0,.15)
}

.pf-hover-shadow-alt:hover .ld-pf-image {
    -webkit-box-shadow: 0 8px 15px rgba(0,0,0,.1);
    box-shadow: 0 8px 15px rgba(0,0,0,.1)
}

.pf-hover-shadow-alt-2:hover .ld-pf-image {
    -webkit-box-shadow: 0 15px 30px rgba(0,0,0,.1);
    box-shadow: 0 15px 30px rgba(0,0,0,.1)
}

.pf-hover-img-border .ld-pf-image {
    -webkit-perspective: 1200px;
    perspective: 1200px;
    -webkit-transition: -webkit-clip-path .8s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-clip-path .8s cubic-bezier(0.23,1,.32,1);
    transition: clip-path .8s cubic-bezier(0.23,1,.32,1);
    transition: clip-path .8s cubic-bezier(0.23,1,.32,1),-webkit-clip-path .8s cubic-bezier(0.23,1,.32,1);
    -webkit-clip-path: inset(0px);
    clip-path: inset(0px)
}

.pf-hover-img-border:hover .ld-pf-image {
    -webkit-clip-path: inset(10px);
    clip-path: inset(10px)
}

.ld-pf-item.hover-3d {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    overflow: visible
}

.ld-pf-item.hover-3d .liquid-overlay-link {
    -webkit-transform: translateZ(60px) scale(0.85);
    transform: translateZ(60px) scale(0.85)
}

.ld-pf-item.hover-3d .ld-pf-bg {
    -webkit-transform: translateZ(1px);
    transform: translateZ(1px)
}

.ld-pf-item.hover-3d:hover {
    z-index: 2
}

.pf-hover-rise .ld-pf-image {
    -webkit-transition: -webkit-transform .45s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .45s cubic-bezier(0.23,1,.32,1);
    transition: transform .45s cubic-bezier(0.23,1,.32,1);
    transition: transform .45s cubic-bezier(0.23,1,.32,1),-webkit-transform .45s cubic-bezier(0.23,1,.32,1)
}

.pf-hover-rise .ld-pf-image.shadowed {
    -webkit-transition: -webkit-transform .45s cubic-bezier(0.23,1,.32,1),-webkit-box-shadow .45s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .45s cubic-bezier(0.23,1,.32,1),-webkit-box-shadow .45s cubic-bezier(0.23,1,.32,1);
    transition: transform .45s cubic-bezier(0.23,1,.32,1),box-shadow .45s cubic-bezier(0.23,1,.32,1);
    transition: transform .45s cubic-bezier(0.23,1,.32,1),box-shadow .45s cubic-bezier(0.23,1,.32,1),-webkit-transform .45s cubic-bezier(0.23,1,.32,1),-webkit-box-shadow .45s cubic-bezier(0.23,1,.32,1)
}

.pf-hover-rise:hover .ld-pf-image {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px)
}

.pf-hover-rise:hover .ld-pf-image.shadowed {
    -webkit-box-shadow: 0 25px 70px rgba(0,0,0,.1);
    box-shadow: 0 25px 70px rgba(0,0,0,.1)
}

.ld-pf-single-2 .pf-single-contents .carousel-item img {
    width: 100%
}

.pf-single-title {
    font-size: 48px;
    line-height: 1em
}

.pf-single-title.size-xl {
    font-size: 100px
}

.pf-single-title.size-lg {
    font-size: 60px
}

.pf-single-title:last-child {
    margin-bottom: -.2em!important
}

.pf-single-cat {
    font-size: 12px;
    color: inherit
}

.pf-info {
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    font-size: 16px;
    line-height: 1.2em
}

.pf-info>span {
    display: inline-block;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 47%;
    flex-basis: 47%
}

.pf-info>span:nth-child(3n+1) {
    margin-left: 0
}

.pf-info small {
    display: block
}

.pf-info h5 {
    font-size: 1em
}

.pf-info hr {
    width: 50px;
    position: absolute;
    top: -2.1875em;
    left: 0;
    margin: 0;
    border-color: #181b31
}

.pf-single-header {
    margin-bottom: 2em
}

.pf-single-header h4 {
    font-size: 24px
}

.pf-single-header.bg-solid {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #fff
}

.post-nav.pf-nav .nav-subtitle {
    font-size: 16px
}

.pf-related-posts {
    padding-top: 2em
}

.pf-related {
    margin-bottom: 2em;
    position: relative
}

.pf-related-title {
    margin-bottom: .5em;
    position: relative
}

.pf-related-title a {
    color: inherit
}

.pf-related-title .split-inner {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow: hidden
}

.pf-related-title .title-shad {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--color-primary)
}

.pf-related-cat {
    font-size: 12px
}

.pf-related-cat li {
    position: relative;
    z-index: 2
}

.pf-related-alt {
    padding-bottom: 69.5%;
    color: #fff
}

.pf-related-alt a,.pf-related-alt h2 {
    color: inherit
}

.pf-related-alt h2 {
    line-height: 1em
}

.pf-related-alt figure,.pf-related-alt header {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.pf-related-alt>figure {
    background-size: cover;
    background-position: center
}

.pf-related-alt>figure:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: .7;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.pf-related-alt img {
    visibility: hidden
}

.pf-related-alt header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 2em 25%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
}

.pf-related-alt:hover>figure:after {
    opacity: 0
}

.blog-single-cover {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    color: rgba(24,27,49,.5);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1200px;
    perspective: 1200px
}

.blog-single-cover .blog-single-media:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 101%;
    position: absolute;
    top: 0;
    left: 0
}

.blog-single-cover .blog-single-media[data-responsive-bg]>img {
    visibility: hidden
}

.blog-single-cover .entry-title,.blog-single-cover a {
    color: #181b31
}

.blog-single-cover .ld-parallax-wrap {
    overflow: visible!important
}

.blog-single-cover .lightbox-link svg {
    stroke: #181b31
}

.blog-single-cover .liquid-parallax-bg>img {
    visibility: hidden
}

.blog-single-cover .carousel-item {
    padding-left: 0;
    padding-right: 0
}

.blog-single-cover .flickity-button {
    border-color: #fefefe;
    color: #fefefe
}

.blog-single-cover .flickity-button:before {
    background-color: currentColor
}

.blog-single-cover .flickity-button svg {
    stroke: currentColor
}

.blog-single-cover .flickity-button:hover svg {
    stroke: #000
}

.blog-single-cover.scheme-dark .blog-single-media:after {
    background-image: -webkit-gradient(linear,left top,right top,from(rgba(255,255,255,.6)),to(transparent));
    background-image: linear-gradient(to right,rgba(255,255,255,.6) 0,transparent 100%)
}

.blog-single-cover.scheme-light {
    background-color: #12131a;
    color: rgba(255,255,255,.5)
}

.blog-single-cover.scheme-light .blog-single-media:after {
    background-image: -webkit-gradient(linear,left top,right top,from(rgba(20,22,34,.6)),to(transparent));
    background-image: linear-gradient(to right,rgba(20,22,34,.6) 0,transparent 100%)
}

.blog-single-cover.scheme-light .entry-title,.blog-single-cover.scheme-light a {
    color: #fff
}

.blog-single-cover.scheme-light .post-meta {
    color: inherit
}

.blog-single-cover.scheme-light .post-meta a {
    color: #fff
}

.blog-single-cover.scheme-light .lightbox-link svg {
    stroke: #fff
}

.blog-single-cover.scheme-light .flickity-prev-next-button {
    color: #fff
}

.blog-single-cover.scheme-light .flickity-prev-next-button svg {
    stroke: #fff
}

.blog-single-cover[data-inview=true]:before {
    content: '';
    display: inline-block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    border-radius: 5px;
    background-color: rgba(0,0,0,.3);
    /* background-image: url(../themes/responsiv-clean/assets/img/spinners/spinner-4-light.svg); */
    background-size: 35px;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transition: opacity .3s,visibility .3s;
    transition: opacity .3s,visibility .3s
}

.blog-single-cover[data-inview=true] .blog-single-media {
    -webkit-transform: translateZ(150px);
    transform: translateZ(150px);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .5s,-webkit-transform 1s cubic-bezier(0.06,.78,.35,1);
    transition: opacity .5s,-webkit-transform 1s cubic-bezier(0.06,.78,.35,1);
    transition: transform 1s cubic-bezier(0.06,.78,.35,1),opacity .5s;
    transition: transform 1s cubic-bezier(0.06,.78,.35,1),opacity .5s,-webkit-transform 1s cubic-bezier(0.06,.78,.35,1)
}

.blog-single-cover[data-inview=true] .blog-single-media:after {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: -webkit-transform .5s cubic-bezier(0.075,.82,.165,1);
    transition: -webkit-transform .5s cubic-bezier(0.075,.82,.165,1);
    transition: transform .5s cubic-bezier(0.075,.82,.165,1);
    transition: transform .5s cubic-bezier(0.075,.82,.165,1),-webkit-transform .5s cubic-bezier(0.075,.82,.165,1)
}

.blog-single-cover[data-inview=true] .blog-single-title .split-unit,.blog-single-cover[data-inview=true] .post-meta>span {
    -webkit-transform: translateY(80px);
    transform: translateY(80px);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .8s cubic-bezier(0.06,.78,.35,1),-webkit-transform .8s cubic-bezier(0.06,.78,.35,1);
    transition: opacity .8s cubic-bezier(0.06,.78,.35,1),-webkit-transform .8s cubic-bezier(0.06,.78,.35,1);
    transition: transform .8s cubic-bezier(0.06,.78,.35,1),opacity .8s cubic-bezier(0.06,.78,.35,1);
    transition: transform .8s cubic-bezier(0.06,.78,.35,1),opacity .8s cubic-bezier(0.06,.78,.35,1),-webkit-transform .8s cubic-bezier(0.06,.78,.35,1)
}

.blog-single-cover[data-inview=true] .blog-single-title {
    visibility: hidden
}

.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(0) {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(1) {
    -webkit-transition-delay: .0666666667s;
    transition-delay: .0666666667s
}

.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(2) {
    -webkit-transition-delay: .1333333333s;
    transition-delay: .1333333333s
}

.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(3) {
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(4) {
    -webkit-transition-delay: .2666666667s;
    transition-delay: .2666666667s
}

.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(5) {
    -webkit-transition-delay: .3333333333s;
    transition-delay: .3333333333s
}

.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(6) {
    -webkit-transition-delay: .4s;
    transition-delay: .4s
}

.blog-single-cover[data-inview=true] .blog-single-title.split-text-applied {
    visibility: visible
}

.blog-single-cover[data-inview=true] .post-meta>span {
    visibility: hidden
}

.blog-single-cover[data-inview=true] .post-meta>span:nth-child(1) {
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.blog-single-cover[data-inview=true] .post-meta>span:nth-child(2) {
    -webkit-transition-delay: .2666666667s;
    transition-delay: .2666666667s
}

.blog-single-cover[data-inview=true] .post-meta>span:nth-child(3) {
    -webkit-transition-delay: .3333333333s;
    transition-delay: .3333333333s
}

.blog-single-cover[data-inview=true] .post-meta>span:nth-child(4) {
    -webkit-transition-delay: .4s;
    transition-delay: .4s
}

.blog-single-cover[data-inview=true] .blog-single-details-extra .lightbox-link svg path {
    stroke-dasharray: 228;
    stroke-dashoffset: 228;
    -webkit-transition: stroke-dashoffset 1s .4s cubic-bezier(0.23,1,.32,1);
    transition: stroke-dashoffset 1s .4s cubic-bezier(0.23,1,.32,1)
}

.blog-single-cover[data-inview=true] .blog-single-details-extra .lightbox-link svg path:last-child {
    -webkit-transition-duration: 5s;
    transition-duration: 5s;
    -webkit-transition-delay: .35s;
    transition-delay: .35s
}

.blog-single-cover[data-inview=true] .blog-single-details-extra .lightbox-link span {
    display: inline-block;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
    opacity: 0;
    -webkit-transition: opacity .6s .5s cubic-bezier(0.23,1,.32,1),-webkit-transform .6s .5s cubic-bezier(0.23,1,.32,1);
    transition: opacity .6s .5s cubic-bezier(0.23,1,.32,1),-webkit-transform .6s .5s cubic-bezier(0.23,1,.32,1);
    transition: transform .6s .5s cubic-bezier(0.23,1,.32,1),opacity .6s .5s cubic-bezier(0.23,1,.32,1);
    transition: transform .6s .5s cubic-bezier(0.23,1,.32,1),opacity .6s .5s cubic-bezier(0.23,1,.32,1),-webkit-transform .6s .5s cubic-bezier(0.23,1,.32,1)
}

.blog-single-cover[data-inview=true] .blog-single-details-extra .lightbox-link+.carousel-nav {
    margin-top: 1em
}

.blog-single-cover[data-inview=true] .blog-single-details-extra .carousel-nav {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
    -webkit-transition: opacity .6s .5s cubic-bezier(0.23,1,.32,1),-webkit-transform .6s .5s cubic-bezier(0.23,1,.32,1);
    transition: opacity .6s .5s cubic-bezier(0.23,1,.32,1),-webkit-transform .6s .5s cubic-bezier(0.23,1,.32,1);
    transition: transform .6s .5s cubic-bezier(0.23,1,.32,1),opacity .6s .5s cubic-bezier(0.23,1,.32,1);
    transition: transform .6s .5s cubic-bezier(0.23,1,.32,1),opacity .6s .5s cubic-bezier(0.23,1,.32,1),-webkit-transform .6s .5s cubic-bezier(0.23,1,.32,1)
}

.blog-single-cover[data-inview=true] .blog-single-details-extra .flickity-button {
    border-color: #fefefe;
    color: #fefefe
}

.blog-single-cover[data-inview=true] .blog-single-details-extra .flickity-button:before {
    background-color: currentColor
}

.blog-single-cover[data-inview=true] .blog-single-details-extra .flickity-button svg {
    stroke: currentColor
}

.blog-single-cover[data-inview=true] .blog-single-details-extra .flickity-button:hover svg {
    stroke: #000
}

.blog-single-cover.is-in-view:before {
    opacity: 0;
    visibility: hidden
}

.blog-single-cover.is-in-view .blog-single-title .split-unit,.blog-single-cover.is-in-view .post-meta>span {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible
}

.blog-single-cover.is-in-view .blog-single-media {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
    visibility: visible
}

.blog-single-cover.is-in-view .blog-single-media:after {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.blog-single-cover.is-in-view .blog-single-details-extra .lightbox-link svg path {
    stroke-dashoffset: 0
}

.blog-single-cover.is-in-view .blog-single-details-extra .lightbox-link span {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.blog-single-cover.is-in-view .blog-single-details-extra .carousel-nav {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.blog-single-title {
    margin-top: 0;
    margin-bottom: .6em;
    letter-spacing: -.01em;
    -ms-word-wrap: break-word;
    word-wrap: break-word
}

.post-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-bottom: 2em;
    font-size: 16px;
    line-height: 1.5em
}

.post-meta .block {
    display: block;
    font-size: 12px
}

.post-meta>span {
    display: inline-block
}

.post-meta>span+span {
    margin-left: 3.25em
}

.text-center .post-meta {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.blog-single-details-extra {
    overflow: hidden
}

.blog-single-details-extra h3 {
    margin-top: 0
}

.blog-single-details-extra .lightbox-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px
}

.blog-single-details-extra .lightbox-link svg {
    margin-right: 20px
}

.blog-single-details-extra .carousel-thumbs {
    width: 50%;
    margin: 0 auto;
    position: relative
}

.blog-single-details-extra .carousel-thumbs img {
    width: 100%;
    border-radius: 50em;
    border: 2px solid transparent;
    -webkit-transition: border-color .3s;
    transition: border-color .3s
}

.blog-single-details-extra .carousel-thumbs .flickity-prev-next-button {
    border: none
}

.blog-single-details-extra .carousel-thumbs .flickity-prev-next-button.previous {
    left: -45px
}

.blog-single-details-extra .carousel-thumbs .flickity-prev-next-button.next {
    right: -45px
}

.blog-single-details-extra .carousel-thumbs .carousel-item {
    padding-left: 10px;
    padding-right: 10px;
    opacity: .5;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    cursor: pointer
}

.blog-single-details-extra .carousel-thumbs .is-nav-selected {
    opacity: 1
}

.blog-single-details-extra .carousel-thumbs .is-nav-selected img {
    border-color: #fff
}

.blog-single-content {
    padding-top: 4.3181818182em
}

.blog-single-content:after,.blog-single-content:before {
    content: '';
    display: table
}

.blog-single-content:after {
    clear: both
}

.blog-single-content .ld-container {
    width: 100%
}

.blog-single-content .liquid-img-group-container,.blog-single-content figure {
    margin-top: 3.75em;
    margin-bottom: 1.5em
}

.blog-single-content blockquote {
    text-align: center
}

.blog-single-content blockquote cite:before {
    left: 50%;
    margin-left: -15px
}

.blog-single-footer,.post-author,.post-nav {
    margin: .2em 0 1.25em
}

.blog-single-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.post-categories,.tags-links {
    margin: 0;
    padding: 0;
    font-size: 10px;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: .1em;
    list-style: none
}

.post-categories>a,.post-categories>li,.tags-links>a,.tags-links>li {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: .2em
}

.post-categories li a,.post-categories>a,.tags-links li a,.tags-links>a {
    padding: .5em 1.5em;
    margin-bottom: .5em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #16171e;
    color: #16171e;
    opacity: .6
}

.post-categories li a:hover,.post-categories>a:hover,.tags-links li a:hover,.tags-links>a:hover {
    opacity: 1
}

.share-links {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: .1em
}

.share-links>span {
    margin-right: 1em
}

.share-links .social-icon {
    display: inline-block
}

.post-author {
    padding: 2.25em 0;
    border-top: 1px solid #d8dbe2;
    border-bottom: 1px solid #d8dbe2
}

.post-author figure {
    float: left;
    margin-right: 30px
}

.post-author figure img {
    border-radius: 50em
}

.post-author p {
    margin: 0;
    font-size: 1em;
    line-height: 1.6em
}

.post-author h3,.post-author h6 {
    margin: 0;
    line-height: 1.5em
}

.post-author h3 {
    font-size: 22px;
    font-weight: 700;
    color: #181b31
}

.post-author h3 a {
    color: inherit
}

.post-author h6 {
    margin-bottom: 1em;
    margin-top: .5em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: #a7a9b8
}

.post-author .post-author-info {
    overflow: hidden
}

.post-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 2.25em 0;
    font-size: 1.0909090909em;
    line-height: 1.25em
}

.post-nav a {
    display: inline-block;
    position: relative
}

.post-nav .nav-subtitle {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: .1em
}

.post-nav .nav-subtitle:last-child {
    margin-bottom: 0
}

.post-nav .nav-title {
    color: #16171e
}

.post-nav .nav-next {
    text-align: right
}

.post-nav svg {
    position: absolute;
    top: 50%;
    margin-top: -12px;
    -webkit-transition: -webkit-transform .3s cubic-bezier(0,.95,.3,1);
    transition: -webkit-transform .3s cubic-bezier(0,.95,.3,1);
    transition: transform .3s cubic-bezier(0,.95,.3,1);
    transition: transform .3s cubic-bezier(0,.95,.3,1),-webkit-transform .3s cubic-bezier(0,.95,.3,1)
}

.post-nav .nav-previous {
    padding-left: 40px
}

.post-nav .nav-previous svg {
    left: -40px
}

.post-nav .nav-previous:hover svg {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px)
}

.post-nav .nav-next {
    padding-right: 40px
}

.post-nav .nav-next svg {
    right: -40px
}

.post-nav .nav-next:hover svg {
    -webkit-transform: translateX(7px);
    transform: translateX(7px)
}

.related-posts>.container>.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

.related-posts>.container>.row>div {
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%
}

.related-posts>.container>.row>.col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%
}

.related-post .related-post-title {
    font-size: 23px;
    line-height: 1.5em;
    -ms-word-wrap: break-word;
    word-wrap: break-word
}

.single-post.has-sidebar .sidebar-container {
    margin-top: 100px
}

.single-post.has-sidebar .contents-container .related-posts>.container {
    width: auto;
    padding-left: 0;
    padding-right: 0
}

.blog-single>:last-child {
    margin-bottom: 0
}

.post-password-form input {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 50px;
    padding: 5px 2em;
    border: none;
    border-radius: 3px;
    background: 0 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 400
}

.post-password-form input[type=password] {
    border: 1px solid #eaeaea;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    transition: box-shadow .3s;
    transition: box-shadow .3s,-webkit-box-shadow .3s
}

.post-password-form input[type=password]:focus {
    -webkit-box-shadow: 0 10px 25px rgba(0,0,0,.1);
    box-shadow: 0 10px 25px rgba(0,0,0,.1);
    outline: 0
}

.post-password-form input[type=submit] {
    background-color: var(--color-primary);
    color: #fff
}

.comments-area:last-child {
    margin-bottom: 2.25em
}

.comment-respond {
    margin: .2em 0 0
}

.comment-respond.filled {
    padding: 2.75em 0 3em;
    background-color: #f4f5f8
}

.comment-form {
    margin-top: 3em;
    font-size: 16px
}

.comment-form input,.comment-form textarea {
    z-index: 1;
    width: 100%;
    border: none;
    position: relative;
    background-color: transparent;
    line-height: 1.5em
}

.comment-form input:focus,.comment-form textarea:focus {
    outline: 0
}

.comment-form input {
    height: 45px
}

.comment-form textarea+.input-placeholder {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.comment-form input[type=submit] {
    display: inline-block;
    width: auto;
    height: auto;
    padding: 16px 55px;
    border: none;
    background-color: #181b31;
    font-size: 14px;
    letter-spacing: .1em;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.5);
    box-shadow: 0 0 0 rgba(0,0,0,.5);
    -webkit-transition: -webkit-transform .3s,-webkit-box-shadow .3s;
    transition: -webkit-transform .3s,-webkit-box-shadow .3s;
    transition: transform .3s,box-shadow .3s;
    transition: transform .3s,box-shadow .3s,-webkit-transform .3s,-webkit-box-shadow .3s
}

.comment-form input[type=submit]:hover {
    -webkit-box-shadow: 0 5px 25px rgba(0,0,0,.2);
    box-shadow: 0 5px 25px rgba(0,0,0,.2);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px)
}

.comment-form .input-placeholder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: none
}

.comment-form p {
    margin-bottom: 35px;
    position: relative;
    -webkit-perspective: 1200px;
    perspective: 1200px
}

.comment-form p:after,.comment-form p:before {
    content: '';
    display: table;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    -webkit-transition: -webkit-transform .6s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .6s cubic-bezier(0.23,1,.32,1);
    transition: transform .6s cubic-bezier(0.23,1,.32,1);
    transition: transform .6s cubic-bezier(0.23,1,.32,1),-webkit-transform .6s cubic-bezier(0.23,1,.32,1)
}

.comment-form p:before {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    background-color: #d8dbe2
}

.comment-form p:after {
    clear: both;
    -webkit-transform: scale(0,2);
    transform: scale(0,2);
    -webkit-transform-origin: right center;
    transform-origin: right center;
    background-color: #181b31
}

.comment-form p.form-submit {
    margin-bottom: 90px
}

.comment-form p.comment-notes:after,.comment-form p.comment-notes:before,.comment-form p.form-submit:after,.comment-form p.form-submit:before {
    content: none
}

.comment-form p.input-focused:before {
    -webkit-transform: scale(0,2);
    transform: scale(0,2);
    -webkit-transform-origin: right center;
    transform-origin: right center
}

.comment-form p.input-focused:after {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.comment-form p.input-filled .split-unit {
    opacity: 0!important;
    -webkit-transform: translateY(-27%) rotateX(-45de)!important;
    transform: translateY(-27%) rotateX(-45de)!important
}

.comment-form .logged-in-as {
    padding-left: 15px;
    padding-right: 14px
}

.comment-form .logged-in-as:after,.comment-form .logged-in-as:before {
    content: none
}

.comment-notes,.comment-reply-title {
    text-align: center
}

.comment-reply-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase
}

.comment-reply-title small {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

#cancel-comment-reply-link {
    padding: .35em .75em .5em;
    margin-left: 1em;
    background-color: rgba(253,58,24,.6);
    font-size: 12px;
    line-height: 1.5em;
    text-transform: none;
    color: #fff;
    border-radius: 2px
}

#cancel-comment-reply-link:hover {
    background-color: #fd3a18
}

.blog-single-cover-fade .comment-respond .container,.blog-single-cover-fade .comments-area>.container {
    width: 100%
}

.blog-single-cover-fade .comment-respond .container>.row>div,.blog-single-cover-fade .comments-area>.container>.row>div {
    width: 100%;
    margin-left: 0
}

.blog-single-default .comments-area,.blog-single-image-left .comments-area {
    padding: 2.75em 0 3em;
    background-color: #f4f5f8;
    margin-bottom: 0!important
}

.blog-single-default .comments-area:last-child,.blog-single-image-left .comments-area:last-child {
    margin-bottom: 0
}

.blog-single-default .comment-form p.form-submit,.blog-single-image-left .comment-form p.form-submit {
    margin-bottom: 0
}

.comment-author,.comment-author a {
    color: #16171e
}

.comment-author .avatar {
    width: 70px;
    margin-right: 18px;
    border-radius: 50em
}

.comment-author .says {
    display: none
}

.comment-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.comment-metadata {
    font-size: .7777777778em
}

.comment-metadata a {
    color: #a7a9b8
}

.comment .comment-extras,.comment-content {
    padding-left: 93px
}

.comment-content {
    margin-top: -18px
}

.comment-content p {
    margin-bottom: .75em
}

.comment-extras {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.comment-extras p {
    margin: 0
}

.comment-body {
    margin-bottom: 30px
}

.comment {
    font-size: 18px;
    line-height: 1.3333333333em
}

.comment .reply a {
    display: inline-block;
    padding: 7px 13px;
    font-size: 10px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: #181b31;
    border: 1px solid #d8dbe2
}

.comment .reply a:hover {
    border-color: #181b31;
    background-color: #181b31;
    color: #fff
}

.comment.bypostauthor .comment-author,.comment.bypostauthor .comment-author a {
    color: var(--color-primary)
}

.pingback {
    font-size: 18px;
    line-height: 1.3333333333em;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #d8dbe2
}

.pingback cite {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    padding: 0;
    margin: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-transform: none;
    letter-spacing: 0;
    font-size: 1em
}

.pingback cite:before {
    content: none
}

.pingback time {
    margin: 0 1.5em
}

.comment-list {
    padding: 1em 0;
    margin: 2.25em 0 1em
}

.comment-list,.comment-list ol.children {
    list-style: none
}

.comment-list ol.children {
    padding: 0;
    margin: 0 0 0 86px
}

.comment-list ol.children .avatar {
    width: 45px
}

.comment-list ol.children .comment .comment-extras,.comment-list ol.children .comment-content {
    padding-left: 68px
}

.comment-list ol.children .comment-content {
    margin-top: -8px
}

.comment-list .comment-respond {
    background: 0 0;
    padding: 0
}

.comment-list .comment-respond .container,.comment-list .comment-respond .container>.row>div,.has-sidebar .contents-container .comment-respond>.container,.has-sidebar .contents-container .comment-respond>.container>.row>div,.has-sidebar .contents-container>.comments-area>.container,.has-sidebar .contents-container>.comments-area>.container>.row>div {
    width: 100%;
    margin: 0
}

.blog-single-image-left .blog-single {
    margin-top: 2.2727272727em
}

.blog-single-image-left .blog-single-cover {
    margin-bottom: 2.727em
}

.blog-single-image-left .blog-single-title {
    letter-spacing: -.01em
}

.blog-single-image-left .blog-single-content {
    padding-top: 1em
}

.blog-single-cover-fade .blog-single-cover.scheme-light .blog-single-media:after {
    background: -webkit-gradient(linear,left bottom,left top,from(#12131a),to(rgba(18,19,26,.6)));
    background: linear-gradient(to top,#12131a 0,rgba(18,19,26,.6) 100%)
}

.blog-single-cover-fade .blog-single-cover.scheme-dark .blog-single-media:after {
    background: -webkit-gradient(linear,left bottom,left top,from(#fff),to(rgba(255,255,255,.6)));
    background: linear-gradient(to top,#fff 0,rgba(255,255,255,.6) 100%)
}

.blog-single-cover-fade .blog-single-cover .blog-single-details-extra {
    margin-bottom: 75px
}

.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-media:after {
    -webkit-transform: translate(0);
    transform: translate(0)
}

.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details,.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details>.container,.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details>.container>.row,.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details>.container>.row>[class^=col-] {
    height: 100%
}

.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details>.container>.row>[class^=col-] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details .blog-single-header {
    margin-top: auto;
    margin-bottom: auto
}

.blog-single-cover-fade .related-posts>.container {
    width: auto;
    padding-left: 0;
    padding-right: 0
}

.blog-single-cover-fade .related-posts .related-posts-title.text-left {
    text-align: center!important
}

.blog-single-cover-bordered .related-posts>.container {
    width: auto
}

.blog-single-cover-bordered .comment-respond>.container,.blog-single-cover-bordered .comments-area>.container {
    width: auto;
    padding-left: 0;
    padding-right: 0
}

.blog-single-cover-bordered .comment-respond>.container>.row>.col-md-8,.blog-single-cover-bordered .comments-area>.container>.row>.col-md-8 {
    width: 100%;
    margin-left: 0
}

.blog-single-cover-bordered.blog-single-post-has-thumbnail .lqd-main-header-default+.content {
    padding-top: 0
}

.related-posts {
    margin: 1.5em 0 .5em
}

.related-posts-title {
    margin-bottom: 1.75em;
    font-size: 24px;
    line-height: 1.5em;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center
}

.related-post-categories,.related-post-date {
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 3;
    list-style: none;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .1em
}

.related-post-image {
    margin-bottom: .9090909091em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.3);
    box-shadow: 0 0 0 rgba(0,0,0,.3);
    -webkit-transition: -webkit-box-shadow .3s,-webkit-transform .3s;
    transition: -webkit-box-shadow .3s,-webkit-transform .3s;
    transition: box-shadow .3s,transform .3s;
    transition: box-shadow .3s,transform .3s,-webkit-box-shadow .3s,-webkit-transform .3s
}

.related-post-image img {
    width: 100%
}

.related-post-title {
    margin: 0 0 .4166666667em;
    font-size: 1.0909090909em;
    line-height: 1.0833333333em;
    letter-spacing: -.01em
}

.related-post-title a {
    color: #16171e
}

.related-post {
    position: relative;
    margin-bottom: 1.875em
}

.related-post .liquid-overlay-link {
    z-index: 2
}

.related-post:hover .related-post-image {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-box-shadow: 0 5px 30px rgba(0,0,0,.2);
    box-shadow: 0 5px 30px rgba(0,0,0,.2)
}

.related-post-alt {
    color: #fff;
    padding-bottom: 70%;
    overflow: hidden
}

.related-post-alt a {
    color: #fff
}

.related-post-alt .related-post-header,.related-post-alt .related-post-image {
    position: absolute;
    bottom: 0;
    left: 0
}

.related-post-alt .related-post-image {
    width: 100%;
    height: 100%;
    margin: 0
}

.related-post-alt .related-post-image:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: -webkit-gradient(linear,left bottom,left top,from(#141622),to(transparent));
    background: linear-gradient(to top,#141622 0,transparent 100%)
}

.related-post-alt .related-post-image img {
    visibility: hidden
}

.related-post-alt .related-post-header {
    width: 100%;
    padding: 0 30px 22px;
    z-index: 2
}

.related-post-alt:hover .related-post-image {
    -webkit-transform: none;
    transform: none
}

.page-scheme-dark .post-meta a {
    color: #16171e
}

.ld-particles-container {
    width: 100%;
    height: 450px
}

.lqd-particles-bg-wrap .ld-particles-container {
    height: 100%
}

.ld-particles-inner,.lqd-particles-bg-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.lqd-particles-bg-wrap {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.ld-carousel-laptop {
    text-align: center
}

.ld-carousel-laptop .ld-carousel-laptop-inner {
    position: relative;
    display: inline-block
}

.ld-carousel-laptop .mockup-content-container {
    position: absolute;
    top: 7.3%;
    left: 10.8%;
    bottom: 11.3%;
    right: 10.4%;
    z-index: 2
}

.ld-carousel-laptop .carousel-container,.ld-carousel-laptop .carousel-items,.ld-carousel-laptop .mockup-content-inner {
    width: 100%;
    height: 100%
}

.ld-carousel-laptop .flickity-viewport {
    height: 100%!important
}

.ld-carousel-laptop .carousel-items {
    margin: 0
}

.ld-carousel-laptop .carousel-item {
    padding: 0
}

.ld-carousel-laptop img {
    width: 100%
}

.ld-carousel-laptop .flickity-prev-next-button.previous {
    left: 6%
}

.ld-carousel-laptop .flickity-prev-next-button.next {
    right: 6%
}

.ld-fancy-heading h1,.ld-fancy-heading h2,.ld-fancy-heading h3,.ld-fancy-heading h4,.ld-fancy-heading h5,.ld-fancy-heading h6,.ld-fancy-heading p,.ld-fancy-heading>div {
    display: inline-block;
    margin-top: 0;
    margin-bottom: .5em
}

.ld-fancy-heading .ld-fh-txt {
    position: relative;
    display: inline-block
}

.ld-fancy-heading .ld-fh-txt i {
    vertical-align: middle
}

.ld-fancy-heading.carousel-nav-appended {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.ld-fancy-heading.has-mask-image>* {
    background-position: center;
    background-clip: text!important;
    -webkit-background-clip: text!important;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    color: transparent
}

.ld-fancy-heading.has-mask-image .ld-fh-txt {
    position: static;
    display: inline
}

.mask-text .split-unit {
    overflow: hidden
}

.no-backgroundcliptext .ld-gradient-heading {
    background: none!important
}

.backgroundcliptext .ld-gradient-heading .ld-fh-txt {
    -webkit-background-clip: text!important;
    background-clip: text!important;
    -webkit-text-fill-color: transparent!important;
    text-fill-color: transparent!important
}

.backgroundcliptext .ld-gradient-heading .split-inner,.backgroundcliptext .ld-gradient-heading .split-unit,.backgroundcliptext .ld-gradient-heading .txt-rotate-keywords,.backgroundcliptext .ld-gradient-heading .txt-rotate-keywords .keyword {
    background: inherit
}

.backgroundcliptext .ld-gradient-heading .split-inner {
    line-height: 1.5em;
    margin-top: -.75em;
    margin-bottom: -.75em
}

.ld-fh-underline {
    display: inline-block;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.txt-rotate-keywords {
    display: inline-block;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    white-space: nowrap
}

.txt-rotate-keywords .keyword {
    display: inline-block;
    position: absolute;
    left: 0;
    top: auto;
    opacity: 0;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.txt-rotate-keywords .keyword:first-child {
    position: relative;
    top: auto;
    left: auto;
    opacity: 1
}

.text-slide-activated .txt-rotate-keywords .keyword {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0
}

.text-slide-activated .txt-rotate-keywords .keyword.active {
    position: relative;
    opacity: 1
}

@media screen and (min-width: 480px) {
    .ld-fancy-heading .lqd-lines {
        white-space:nowrap
    }

    .window-resizing .ld-fancy-heading .lqd-lines {
        white-space: normal
    }
}

.bg-overlay-30:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3)
}

.bg-overlay-40:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4)
}

.bg-overlay-50:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5)
}

.bg-overlay-60:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6)
}

.bg-overlay-70:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.7)
}

.bg-overlay-80:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8)
}

.bg-overlay-90:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.9)
}

.liquid-row-overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute!important;
    top: 0;
    left: 0;
    -webkit-transition: background .3s,opacity .3s;
    transition: background .3s,opacity .3s
}

.liquid-row-overlay-hover {
    opacity: 0
}

@-webkit-keyframes markerWave {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .8
    }

    20% {
        opacity: .8
    }

    100% {
        -webkit-transform: scale(5.5);
        transform: scale(5.5);
        opacity: 0
    }
}

@keyframes markerWave {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .8
    }

    20% {
        opacity: .8
    }

    100% {
        -webkit-transform: scale(5.5);
        transform: scale(5.5);
        opacity: 0
    }
}

.ld-gmap-container {
    width: 100%;
    height: 450px
}

.ld-gmap {
    height: 100%
}

.map_marker {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50em;
    background-color: var(--color-primary)
}

.map_marker div {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50em;
    opacity: 1;
    background-color: var(--color-primary)
}

.map_marker>div:first-child {
    -webkit-animation: markerWave 1.5s cubic-bezier(0.4,0,.2,1) infinite forwards;
    animation: markerWave 1.5s cubic-bezier(0.4,0,.2,1) infinite forwards
}

.map_marker>div:first-child+div {
    -webkit-animation: markerWave 1.5s .45s cubic-bezier(0,0,.2,1) infinite forwards;
    animation: markerWave 1.5s .45s cubic-bezier(0,0,.2,1) infinite forwards
}

@media screen and (min-width: 992px) {
    .row.align-items-stretch .ld-gmap-container {
        height:auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: stretch;
        -ms-flex-pack: stretch;
        justify-content: stretch;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    .row.align-items-stretch .ld-gmap-container .ld-gmap {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        height: auto;
        min-height: 350px
    }
}

[data-custom-animations=true] {
    visibility: hidden;
    opacity: 0
}

.ca-initvalues-applied {
    visibility: visible;
    opacity: 1
}

.compose-mode [data-custom-animations=true] {
    visibility: visible!important;
    opacity: 1!important
}

.ld-masked-image svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover
}

.ld-masked-image svg clipPath,.ld-masked-image svg defs,.ld-masked-image svg path {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.ld-masked-image figure {
    background-position: -90% 10%;
    background-size: 95%
}

.ld-masked-image img {
    width: 100%;
    height: auto;
    visibility: hidden
}

@media (min-width: 768px) {
    .ld-masked-image {
        position:absolute;
        right: -90vw;
        left: 42vw
    }
}

@media (min-width: 992px) {
    .ld-masked-image {
        top:-58vh;
        right: -65vw;
        left: 5vw
    }
}

.slideshow-applied {
    position: relative
}

.ld-slideshow-bg-inner,.ld-slideshow-bg-wrap,.ld-slideshow-figure,.ld-slideshow-item {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.ld-slideshow-bg-wrap {
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s,visibility .3s;
    transition: opacity .3s,visibility .3s
}

.slideshow-applied .ld-slideshow-bg-wrap {
    opacity: 1;
    visibility: visible
}

.ld-slideshow-bg-inner {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.ld-slideshow-item {
    overflow: hidden
}

.ld-slideshow-figure {
    background-size: cover;
    background-position: center
}

.ld-slideshow-figure img {
    visibility: hidden
}

.ld-slideshow-figure.active {
    z-index: 1
}

.lqd-custom-menu li a {
    display: block
}

.lqd-custom-menu li.is-active a {
    color: var(--color-primary)
}

.lqd-custom-menu.menu-items-have-fill a {
    padding: .5em 1em
}

.lqd-custom-menu[data-move-element] {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s,visiblity .3s;
    transition: opacity .3s,visiblity .3s
}

.lqd-custom-menu[data-move-element].element-was-moved {
    opacity: 1;
    visibility: visible
}

.lqd-custom-menu[class*=text-] a,.lqd-custom-menu[class*=text-hover] a {
    color: inherit
}

.lqd-custom-menu.active-dark li.is-active a {
    color: #000!important
}

.lqd-sticky-menu {
    width: 100%;
    padding: 1em;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: #f6f6f6
}

.lqd-sticky-menu.on-top {
    bottom: auto;
    top: 0
}

.reset-ul {
    padding: 0;
    margin: 0;
    list-style: none
}

.inline-nav>li {
    display: inline-block
}

.inline-nav>li+li {
    margin-left: 15px
}

.inline-nav li a {
    display: inline
}

.comma-sep-li>li {
    display: inline-block
}

.comma-sep-li>li:not(:last-child):after {
    content: ',';
    margin-right: .25em
}

.ld-shop-banner {
    position: relative;
    margin-bottom: 30px
}

.ld-shop-banner.round .ld-shop-banner-image figure,.ld-shop-banner.round .ld-shop-banner-inner {
    border-radius: 5px
}

.ld-shop-banner.custom-height-applied {
    padding: 0
}

.ld-shop-banner-container {
    -webkit-perspective: 1600px;
    perspective: 1600px
}

.ld-shop-banner-inner {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: inherit;
    perspective: inherit
}

.ld-shop-banner-image figure {
    width: 100%;
    height: 100%;
    background-size: cover
}

.ld-shop-banner-image img {
    visibility: hidden
}

.ld-shop-banner-content {
    padding: 20px 30px;
    font-size: 20px;
    color: #000;
    -webkit-transform: translateZ(50px) scale(0.92);
    transform: translateZ(50px) scale(0.92)
}

.ld-shop-banner-style1 .ld-shop-banner-inner {
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 0 rgba(0,0,0,0);
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    transition: box-shadow .3s;
    transition: box-shadow .3s,-webkit-box-shadow .3s
}

.ld-shop-banner-style1 .ld-shop-banner-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%
}

.ld-shop-banner-style1 .ld-shop-banner-image {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: inherit;
    perspective: inherit
}

.ld-shop-banner-style1 .ld-shop-banner-carousel-main .carousel-item,.ld-shop-banner-style1 .ld-shop-banner-carousel-main .carousel-item-inner {
    width: 100%
}

.ld-shop-banner-style1 .ld-shop-banner-carousel-nav {
    width: 70%;
    height: 80px;
    position: absolute;
    bottom: 3%;
    right: 1%;
    -webkit-transform: translateZ(70px) scale(0.884);
    transform: translateZ(70px) scale(0.884)
}

.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item {
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    padding-top: 2px;
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
    opacity: 0;
    -webkit-transition-property: opacity,-webkit-transform;
    transition-property: opacity,-webkit-transform;
    transition-property: opacity,transform;
    transition-property: opacity,transform,-webkit-transform;
    -webkit-transition-duration: .45s;
    transition-duration: .45s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1)
}

.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item figure {
    position: relative;
    border-radius: 3px;
    margin-bottom: 15px;
    background-size: cover;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item figure:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: 2px solid #fff;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s
}

.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item img {
    visibility: hidden
}

.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item.is-nav-selected figure {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px)
}

.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item.is-nav-selected figure:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.ld-shop-banner-style1 .liquid-overlay-link {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px)
}

.ld-shop-banner-style1:hover .ld-shop-banner-inner {
    -webkit-box-shadow: 0 40px 60px rgba(0,0,0,.1);
    box-shadow: 0 40px 60px rgba(0,0,0,.1)
}

.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1
}

.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item.is-selected:nth-child(0) {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item.is-selected:nth-child(1) {
    -webkit-transition-delay: .0666666667s;
    transition-delay: .0666666667s
}

.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item.is-selected:nth-child(2) {
    -webkit-transition-delay: .1333333333s;
    transition-delay: .1333333333s
}

.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item.is-selected:nth-child(3) {
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item.is-selected:nth-child(4) {
    -webkit-transition-delay: .2666666667s;
    transition-delay: .2666666667s
}

.ld-carousel-phone {
    position: relative
}

.ld-carousel-phone .mockup-container {
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none
}

.ld-carousel-phone .mockup-container img {
    width: 325px
}

.ld-carousel-phone .mockup-content-container {
    width: 100%;
    position: absolute;
    top: 2.5%;
    bottom: 2.75%;
    left: 0;
    z-index: 0;
    overflow: hidden
}

.ld-carousel-phone .carousel-container,.ld-carousel-phone .carousel-item,.ld-carousel-phone .carousel-item-inner,.ld-carousel-phone .carousel-items,.ld-carousel-phone .flickity-viewport,.ld-carousel-phone .mockup-content-inner {
    height: 100%!important
}

.ld-carousel-phone .carousel-item {
    max-width: 365px;
    padding: 0;
    margin: 0 1%
}

.ld-carousel-phone .carousel-item img {
    width: 100%;
    border-radius: 35px
}

.ld-carousel-phone .carousel-item-inner {
    margin: 0 40px;
    position: relative
}

.ld-carousel-phone .is-selected .carousel-item-inner:after {
    opacity: 0
}

.ld-media-icon,.ld-media-item-overlay {
    -webkit-transition-duration: .45s;
    transition-duration: .45s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1)
}

.ld-media-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 32px;
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
    opacity: 0;
    -webkit-transition-property: opacity,-webkit-transform;
    transition-property: opacity,-webkit-transform;
    transition-property: transform,opacity;
    transition-property: transform,opacity,-webkit-transform
}

.icon-sm .ld-media-icon {
    font-size: 28px
}

.icon-lg .ld-media-icon {
    font-size: 44px
}

.ld-media-icon.icon-play {
    width: 55px;
    height: 55px;
    font-size: 15px;
    border-radius: 50em;
    -webkit-transition-property: opacity,border,-webkit-transform;
    transition-property: opacity,border,-webkit-transform;
    transition-property: transform,opacity,border;
    transition-property: transform,opacity,border,-webkit-transform
}

.ld-media-icon.icon-play.bordered {
    border: 2px solid #fff
}

.ld-media-icon.icon-play.solid {
    background-color: var(--color-primary)
}

.ld-media-icon.icon-play.size-sm {
    width: 45px;
    height: 45px
}

.ld-media-icon.icon-play.size-lg {
    width: 65px;
    height: 65px
}

.ld-media-bg {
    background-color: rgba(0,0,0,.4)
}

.ld-media-bg,.ld-media-item-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.ld-media-item-overlay {
    padding: 30px;
    opacity: 0;
    visibility: hidden;
    color: #fff;
    -webkit-transition-property: opacity,visibility;
    transition-property: opacity,visibility
}

.ld-media-content {
    position: relative;
    z-index: 2
}

.ld-media-item {
    overflow: hidden;
    position: relative;
    color: #000
}

.ld-media-item h3,.ld-media-item h6 {
    margin: 0;
    color: inherit;
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition-property: opacity,-webkit-transform;
    transition-property: opacity,-webkit-transform;
    transition-property: transform,opacity;
    transition-property: transform,opacity,-webkit-transform;
    -webkit-transition-duration: .45s;
    transition-duration: .45s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1)
}

.ld-media-item h3 {
    margin-bottom: .25em
}

.ld-media-item h6 {
    font-size: 12px
}

.ld-media-item figure {
    background-size: cover;
    position: relative
}

.ld-media-item figure,.ld-media-item img {
    -webkit-transition: -webkit-transform .85s cubic-bezier(0.215,.61,.355,1);
    transition: -webkit-transform .85s cubic-bezier(0.215,.61,.355,1);
    transition: transform .85s cubic-bezier(0.215,.61,.355,1);
    transition: transform .85s cubic-bezier(0.215,.61,.355,1),-webkit-transform .85s cubic-bezier(0.215,.61,.355,1)
}

.ld-media-item img {
    width: 100%;
    visibility: hidden
}

.ld-media-item.shadow-onhover {
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    transition: box-shadow .3s;
    transition: box-shadow .3s,-webkit-box-shadow .3s;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 0 rgba(0,0,0,0)
}

.ld-media-item.content-bottom h3,.ld-media-item.content-bottom h6,.ld-media-item.contents-visible .ld-media-bg,.ld-media-item.contents-visible .ld-media-icon,.ld-media-item.contents-visible .ld-media-item-overlay,.ld-media-item.contents-visible h3,.ld-media-item.contents-visible h6 {
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    transform: none
}

.ld-media-item.content-bottom img {
    visibility: visible
}

.ld-media-item.content-bottom figure {
    overflow: hidden;
    background: none!important
}

.ld-media-item.content-bottom.shadow-onhover>.ld-media-content {
    padding-left: 18px;
    padding-right: 18px
}

.ld-media-item:hover h3,.ld-media-item:hover h6 {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.ld-media-item:hover h3 {
    opacity: 1;
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.ld-media-item:hover h6 {
    opacity: .4;
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.ld-media-item:hover figure {
    -webkit-transform: scale(1.075);
    transform: scale(1.075)
}

.ld-media-item:hover .ld-media-item-overlay {
    opacity: 1;
    visibility: visible
}

.ld-media-item:hover .ld-media-icon {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.ld-media-item:hover .liquid-overlay-link {
    z-index: 3
}

.ld-media-item:hover.shadow-onhover {
    -webkit-box-shadow: 0 50px 75px rgba(0,0,0,.25);
    box-shadow: 0 50px 75px rgba(0,0,0,.25)
}

.ld-media-item:hover.contents-visible .icon-play {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    border-width: 1px
}

.ld-media-item:hover.content-bottom figure {
    -webkit-transform: none;
    transform: none
}

.ld-media-item:hover.content-bottom figure img {
    -webkit-transform: scale(1.075);
    transform: scale(1.075)
}

.liquid-media-element-custom-height .ld-media-item,.liquid-media-element-custom-height figure {
    height: 100%
}

.ld-media-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

.ld-media-row>div {
    margin-bottom: 30px
}

.lity {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    background: #0b0b0b;
    background: rgba(0,0,0,.9);
    outline: 0!important;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.lity.lity-opened {
    opacity: 1
}

.lity.lity-closed {
    opacity: 0
}

.lity * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.lity-wrap {
    z-index: 9990;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    outline: 0!important
}

.lity-wrap:before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -.25em
}

.lity-loader {
    z-index: 9991;
    color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -.8em;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Arial,Helvetica,sans-serif;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.lity-loading .lity-loader {
    opacity: 1
}

.lity-content {
    z-index: 9993;
    width: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease,-webkit-transform .3s ease
}

.lity-closed .lity-content,.lity-loading .lity-content {
    -webkit-transform: scale(0.8);
    transform: scale(0.8)
}

.lity-content:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,.6);
    box-shadow: 0 0 8px rgba(0,0,0,.6)
}

.lity-close {
    z-index: 9994;
    width: 35px;
    height: 35px;
    position: fixed;
    -webkit-appearance: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-family: Arial,Baskerville,monospace;
    line-height: 35px;
    text-shadow: 0 1px 2px rgba(0,0,0,.6);
    border: 0;
    background: 0 0;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.lity-close::-moz-focus-inner {
    border: 0;
    padding: 0
}

.lity-close:active,.lity-close:focus,.lity-close:hover,.lity-close:visited {
    text-decoration: none;
    text-align: center;
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-family: Arial,Baskerville,monospace;
    line-height: 35px;
    text-shadow: 0 1px 2px rgba(0,0,0,.6);
    border: 0;
    background: 0 0;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.lity-image img {
    max-width: 100%;
    display: block;
    line-height: 0;
    border: 0
}

.lity-facebookvideo .lity-container,.lity-googlemaps .lity-container,.lity-iframe .lity-container,.lity-vimeo .lity-container,.lity-youtube .lity-container {
    width: 100%;
    max-width: 964px
}

.lity-iframe-container {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: auto;
    pointer-events: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-overflow-scrolling: touch
}

.lity-iframe-container iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,.6);
    box-shadow: 0 0 8px rgba(0,0,0,.6);
    background: #000
}

.lity-hide {
    display: none
}

.featherlight .featherlight-close-icon {
    top: 1.3em;
    right: 2em;
    font-size: 1.25em
}

.featherlight .featherlight-content {
    padding: 75px 2em 45px;
    border-radius: 4px
}

.featherlight .featherlight-inner {
    padding-top: 2em;
    position: relative
}

.featherlight .featherlight-inner:before {
    content: '';
    display: inline-block;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.lity {
    background-color: rgba(0,0,0,.8);
    z-index: 9999
}

.lity-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.lity-wrap:before {
    content: none
}

.lity-container {
    border-radius: 4px
}

.lity-content {
    overflow-x: hidden;
    overflow-y: auto
}

.lity-close {
    right: 30px;
    top: 30px
}

.lity-close:active {
    top: 30px
}

.lqd-modal-inner {
    width: 100%;
    position: relative;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    overflow: auto;
    background: #fff;
    cursor: auto;
    white-space: normal
}

.lqd-modal-head h2 {
    margin-top: 0;
    margin-bottom: 1.25em;
    padding-bottom: 1em;
    border-bottom: 1px solid rgba(0,0,0,.15);
    font-size: 28px;
    font-weight: 500
}

@-webkit-keyframes cd-bounce-in {
    0% {
        width: 0
    }

    60% {
        width: 55%
    }

    100% {
        width: 50%
    }
}

@keyframes cd-bounce-in {
    0% {
        width: 0
    }

    60% {
        width: 55%
    }

    100% {
        width: 50%
    }
}

.cd-image-container {
    position: relative;
    margin: 0 auto 30px
}

.cd-image-container:hover .cd-image-label {
    opacity: 0;
    visibility: visible;
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.cd-image-container img {
    display: block;
    width: 100%
}

.cd-image-label {
    padding: 6px 14px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    opacity: 0;
    background-color: #fff;
    font-size: 14px;
    line-height: 1.5em;
    border-radius: 2px;
    font-weight: 500;
    color: #000;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: opacity .3s .7s,-webkit-transform .3s .7s;
    transition: opacity .3s .7s,-webkit-transform .3s .7s;
    transition: transform .3s .7s,opacity .3s .7s;
    transition: transform .3s .7s,opacity .3s .7s,-webkit-transform .3s .7s
}

.cd-image-label.is-hidden {
    visibility: hidden
}

.is-visible .cd-image-label {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.cd-resize-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    overflow: hidden;
    border-right: 3px solid rgba(255,255,255,.75);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.cd-resize-img img {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: auto;
    max-width: none
}

.cd-resize-img .cd-image-label {
    right: auto;
    left: 15px
}

.is-visible .cd-resize-img {
    width: 50%;
    -webkit-animation: cd-bounce-in .7s;
    animation: cd-bounce-in .7s
}

.cd-handle {
    display: inline-block;
    position: absolute;
    height: 40px;
    width: 40px;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    border-radius: 50%;
    background-color: #fff;
    cursor: move;
    opacity: 0;
    color: #000;
    -webkit-box-shadow: 0 0 0 4px rgba(0,0,0,.1);
    box-shadow: 0 0 0 4px rgba(0,0,0,.1);
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.cd-handle:before {
    content: '\f07e';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    font: normal normal 17px/1 fontAwesome;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.is-visible .cd-handle {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 0 .7s,-webkit-transform .3s .7s;
    transition: opacity 0 .7s,-webkit-transform .3s .7s;
    transition: transform .3s .7s,opacity 0 .7s;
    transition: transform .3s .7s,opacity 0 .7s,-webkit-transform .3s .7s
}

.cd-handle.draggable:before {
    -webkit-transform: translate(-50%,-50%) scale(1.15);
    transform: translate(-50%,-50%) scale(1.15)
}

.ld-pb-top {
    display: inline-block;
    position: relative
}

.ld-pb-top:after {
    content: '';
    display: inline-block;
    width: 150%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px dashed #ddd
}

.ld-pb-top .ld-pb-icon-wrap,.ld-pb-top figure {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 208px;
    height: 208px;
    margin: 0;
    border-radius: 50em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    overflow: hidden
}

.ld-pb-top figure:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: rgba(0,0,0,.3)
}

.wpb_column:last-child .ld-pb-top:after {
    content: none
}

.ld-pb-icon-wrap {
    background-color: #e8faf4;
    color: #3ed2a7;
    font-size: 90px;
    line-height: 1
}

.ld-pb-icon-wrap i {
    position: relative;
    z-index: 2
}

.ld-pb-num {
    display: block;
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    z-index: 2;
    font-size: 55px;
    line-height: 1;
    font-weight: 600;
    color: #fff;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.ld-pb {
    margin-bottom: 30px
}

.ld-pb h3 {
    margin-top: 1.25em;
    font-size: 20px
}

.ld-msg-close {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 34px;
    height: 34px;
    padding: 0;
    margin-top: -17px;
    position: absolute;
    top: 50%;
    right: 30px;
    border: none;
    background: 0 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 38px;
    line-height: 1;
    color: #000;
    opacity: .4;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.ld-msg-close span {
    display: inline-block
}

.ld-msg-close:hover {
    opacity: 1
}

.ld-msg-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 34px;
    height: 34px;
    margin-right: 26px;
    border: 1.2px solid;
    border-radius: 50em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 30px
}

.ld-msg-txt h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5em;
    color: #34495E
}

.ld-msg-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ld-msg {
    padding: 20px 40px 20px 20px;
    margin-bottom: 30px;
    position: relative;
    background-color: #F8FAFC
}

.ld-msg:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 2.5px;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: .3
}

.ld-msg-success:after {
    background-color: #63DBC1;
    opacity: .5
}

.ld-msg-success .ld-msg-icon {
    color: #63DBC1
}

.ld-msg-warning:after {
    background-color: #F2C223
}

.ld-msg-warning .ld-msg-icon {
    color: #F2C223
}

.ld-msg-error:after {
    background-color: #E33847
}

.ld-msg-error .ld-msg-icon {
    color: #E33847
}

.ld-flipbox-face,.ld-flipbox-inner,.ld-flipbox-wrap {
    width: 100%;
    min-height: inherit;
    border-radius: inherit
}

.ld-flipbox-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    padding: 40px 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #fff
}

.ld-flipbox-inner h1,.ld-flipbox-inner h2,.ld-flipbox-inner h3,.ld-flipbox-inner h4,.ld-flipbox-inner h5,.ld-flipbox-inner h6 {
    color: inherit
}

.ld-flipbox-inner :last-child {
    margin-bottom: 0
}

.ld-flipbox-face {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-size: cover;
    background-position: center;
    background-color: var(--color-primary);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.ld-flipbox-face,.ld-flipbox-wrap {
    -webkit-transform-style: inherit;
    transform-style: inherit;
    -webkit-perspective: inherit;
    perspective: inherit
}

.ld-flipbox-front .ld-flipbox-inner {
    -webkit-transform: translate3d(0,0,60px) scale(0.85);
    transform: translate3d(0,0,60px) scale(0.85)
}

.ld-flipbox-back {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: rotate3d(0,1,0,180deg);
    transform: rotate3d(0,1,0,180deg)
}

.ld-flipbox-back .ld-flipbox-inner {
    -webkit-transform: translate3d(0,0,60px) scale(0.95);
    transform: translate3d(0,0,60px) scale(0.95)
}

.ld-flipbox-bt .ld-flipbox-back,.ld-flipbox-tb .ld-flipbox-back {
    -webkit-transform: rotateY(-180deg) rotateZ(-180deg);
    transform: rotateY(-180deg) rotateZ(-180deg)
}

.ld-flipbox-wrap {
    position: relative;
    -webkit-transition-property: -webkit-box-shadow,-webkit-transform;
    transition-property: -webkit-box-shadow,-webkit-transform;
    transition-property: box-shadow,transform;
    transition-property: box-shadow,transform,-webkit-box-shadow,-webkit-transform;
    -webkit-transition-duration: .6s;
    transition-duration: .6s;
    -webkit-transition-timing-function: cubic-bezier(0.4,0,.2,1);
    transition-timing-function: cubic-bezier(0.4,0,.2,1)
}

.ld-flipbox-shadow .ld-flipbox-wrap {
    -webkit-box-shadow: 0 50px 60px rgba(0,0,0,.15);
    box-shadow: 0 50px 60px rgba(0,0,0,.15)
}

.ld-flipbox-bt.ld-flipbox-shadow:hover .ld-flipbox-wrap,.ld-flipbox-tb.ld-flipbox-shadow:hover .ld-flipbox-wrap {
    -webkit-box-shadow: 0 -50px 60px rgba(0,0,0,.15);
    box-shadow: 0 -50px 60px rgba(0,0,0,.15)
}

.ld-flipbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 270px;
    margin-bottom: 30px;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1200px;
    perspective: 1200px
}

.ld-flipbox:hover .ld-flipbox-wrap {
    -webkit-transform: rotate3d(0,1,0,180deg);
    transform: rotate3d(0,1,0,180deg);
    -webkit-transform-origin: 50% 50%!important;
    transform-origin: 50% 50%!important
}

.ld-flipbox:hover.ld-flipbox-rl .ld-flipbox-wrap {
    -webkit-transform: rotate3d(0,-1,0,180deg);
    transform: rotate3d(0,-1,0,180deg)
}

.ld-flipbox:hover.ld-flipbox-bt .ld-flipbox-wrap {
    -webkit-transform: rotate3d(1,0,0,180deg);
    transform: rotate3d(1,0,0,180deg)
}

.ld-flipbox:hover.ld-flipbox-tb .ld-flipbox-wrap {
    -webkit-transform: rotate3d(-1,0,0,180deg);
    transform: rotate3d(-1,0,0,180deg)
}

.ld-flipbox:hover.ld-flipbox-shadow-onhover .ld-flipbox-wrap {
    -webkit-box-shadow: 0 50px 60px rgba(0,0,0,.15);
    box-shadow: 0 50px 60px rgba(0,0,0,.15)
}

.ld-flipbox:hover.ld-flipbox-shadow-onhover.ld-flipbox-bt .ld-flipbox-wrap,.ld-flipbox:hover.ld-flipbox-shadow-onhover.ld-flipbox-tb .ld-flipbox-wrap {
    -webkit-box-shadow: 0 -50px 60px rgba(0,0,0,.15);
    box-shadow: 0 -50px 60px rgba(0,0,0,.15)
}

.is-ie .ld-flipbox-face,.is-ie .ld-flipbox-face .ld-flipbox-inner {
    -webkit-transform: none;
    transform: none
}

.is-ie .ld-flipbox-back {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s,visibility .3s;
    transition: opacity .3s,visibility .3s
}

.is-ie .ld-flipbox:hover .ld-flipbox-wrap {
    -webkit-transform: none!important;
    transform: none!important
}

.is-ie .ld-flipbox:hover .ld-flipbox-back {
    opacity: 1;
    visibility: visible
}

.one-ib-circ-icn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 80px;
    height: 80px;
    margin: -50px 0 0 -50px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50em;
    font-size: 30px;
    opacity: 0
}

.one-ib-circ-icn span {
    display: inherit;
    width: inherit;
    height: inherit;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: inherit;
    background-color: #fff;
    color: #000;
    -webkit-box-shadow: 0 8px 16px rgba(0,0,0,.08);
    box-shadow: 0 8px 16px rgba(0,0,0,.08);
    -webkit-transition: color .3s;
    transition: color .3s
}

.one-ib-circ-icn span:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    opacity: 0;
    background-color: var(--color-primary);
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.one-ib-circ-icn span:after {
    content: '';
    display: inline-block;
    width: 126%;
    height: 126%;
    position: absolute;
    top: -13%;
    left: -13%
}

.one-ib-circ-icn i,.one-ib-circ-icn img,.one-ib-circ-icn svg {
    position: relative;
    z-index: 1
}

.one-ib-circ-icn img,.one-ib-circ-icn svg {
    max-width: 60%
}

.one-ib-circ-icn:hover span {
    color: #fff
}

.one-ib-circ-icn:hover span:before {
    opacity: 1
}

.one-ib-circ-icn:hover svg {
    stroke: #fff
}

.one-ib-circ-icn:hover svg circle,.one-ib-circ-icn:hover svg line,.one-ib-circ-icn:hover svg path,.one-ib-circ-icn:hover svg polygon,.one-ib-circ-icn:hover svg polyline,.one-ib-circ-icn:hover svg rect {
    stroke: inherit
}

.one-ib-circ-icn:hover~.one-ib-circ-cnt h3,.one-ib-circ-icn:hover~.one-ib-circ-cnt hr,.one-ib-circ-icn:hover~.one-ib-circ-cnt p {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.one-ib-circ-icn:hover~.one-ib-circ-cnt hr {
    -webkit-transition-delay: .07s;
    transition-delay: .07s
}

.one-ib-circ-icn:hover~.one-ib-circ-cnt p {
    -webkit-transition-delay: .14s;
    transition-delay: .14s
}

.one-ib-circ-cnt {
    width: 60%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.625em;
    color: #666
}

.one-ib-circ-cnt h3,.one-ib-circ-cnt hr,.one-ib-circ-cnt p {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition-property: opacity,visibility,-webkit-transform;
    transition-property: opacity,visibility,-webkit-transform;
    transition-property: transform,opacity,visibility;
    transition-property: transform,opacity,visibility,-webkit-transform;
    -webkit-transition-duration: .45s;
    transition-duration: .45s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1)
}

.one-ib-circ-cnt h3 {
    color: #333;
    font-size: 21px;
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.one-ib-circ-cnt hr {
    width: 17%;
    border-color: rgba(0,0,0,.1);
    -webkit-transition-delay: .05s;
    transition-delay: .05s
}

.one-ib-circ-cnt p {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.one-ib-circ-item:last-child .one-ib-circ-cnt h3,.one-ib-circ-item:last-child .one-ib-circ-cnt hr,.one-ib-circ-item:last-child .one-ib-circ-cnt p {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.one-ib-circ-item:hover~.one-ib-circ-item .one-ib-circ-cnt h3,.one-ib-circ-item:hover~.one-ib-circ-item .one-ib-circ-cnt hr,.one-ib-circ-item:hover~.one-ib-circ-item .one-ib-circ-cnt p {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
}

.one-ib-circ-inner {
    position: absolute;
    top: 40px;
    right: 40px;
    bottom: 40px;
    left: 40px;
    -webkit-transform: translateZ(80px) scale(0.86666);
    transform: translateZ(80px) scale(0.86666)
}

.one-ib-circ-inner>.one-ib-circ-item,.one-ib-circ-inner>.vc_ld_icon_box_circle_item {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    position: absolute!important;
    top: 50%;
    left: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.one-ib-circ-inner>.vc_ld_icon_box_circle_item .one-ib-circ-item {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.one-ib-circ-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #efefef;
    border-radius: 50em;
    -webkit-transform-style: inherit;
    transform-style: inherit;
    -webkit-perspective: inherit;
    perspective: inherit
}

.one-ib-circ {
    position: relative;
    padding-bottom: 100%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1200px;
    perspective: 1200px
}

.one-row-overlay {
    display: block;
    position: absolute!important;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.one-row_bottom_divider,.one-row_top_divider {
    width: 100%;
    position: absolute;
    left: 0
}

.one-row_bottom_divider svg,.one-row_top_divider svg {
    width: 100%;
    height: 100%;
    position: relative
}

.one-row_bottom_divider svg.centered-angle,.one-row_top_divider svg.centered-angle {
    width: 102%;
    left: -1%
}

.one-row_bottom_divider {
    bottom: -1px
}

.one-row_top_divider {
    top: -1px
}

.one-row_top_divider svg {
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg)
}

.one-row_top_divider svg.one-divider-circle,.one-row_top_divider svg.one-divider-triangle {
    width: auto;
    position: relative;
    left: 50%;
    -webkit-transform: rotateZ(180deg) translateX(-50%);
    transform: rotateZ(180deg) translateX(-50%);
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.one-roadmap-bar {
    display: inline-block;
    width: 15px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 50em
}

.one-roadmap-bar:after,.one-roadmap-bar:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    background: var(--color-primary);
    -webkit-transition: -webkit-transform .45s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform .45s cubic-bezier(0.23,1,.32,1);
    transition: transform .45s cubic-bezier(0.23,1,.32,1);
    transition: transform .45s cubic-bezier(0.23,1,.32,1),-webkit-transform .45s cubic-bezier(0.23,1,.32,1)
}

.one-roadmap-bar:before {
    width: 100%;
    height: 15px;
    border-radius: 50em
}

.one-roadmap-bar:after {
    width: 2px;
    height: 100%;
    left: 6px;
    -webkit-transform-origin: top center;
    transform-origin: top center
}

.one-roadmap-info h6,.one-roadmap-info p {
    margin: 0;
    line-height: 1.5em;
    letter-spacing: normal;
    color: inherit
}

.one-roadmap-info h6 {
    margin-bottom: .25em;
    font-size: 15px
}

.one-roadmap-info p {
    font-size: 16px
}

.one-roadmap-mark {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 32px;
    height: 32px;
    margin-left: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 2px solid;
    border-radius: 50em;
    font-size: 30px;
    opacity: .43
}

.one-roadmap-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    padding-bottom: 1em;
    padding-left: 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000
}

.one-roadmap-item:last-of-type {
    padding-bottom: 0
}

.one-roadmap-item:last-of-type .one-roadmap-bar:after {
    content: none
}

.one-roadmap-item-checked .one-roadmap-bar,.one-roadmap-item-checked .one-roadmap-info {
    opacity: .6
}

.one-roadmap-item-checked .one-roadmap-mark {
    opacity: 1
}

.one-roadmap {
    padding: 45px 40px 45px 60px
}

.one-roadmap:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 1px solid;
    opacity: .3
}

.one-roadmap[data-custom-animations=true] .one-roadmap-item {
    opacity: 0
}

.one-roadmap[data-custom-animations=true] .one-roadmap-bar:after,.one-roadmap[data-custom-animations=true] .one-roadmap-bar:before {
    -webkit-transform: scale(0);
    transform: scale(0)
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(0) .one-roadmap-bar:before {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(0) .one-roadmap-bar:after {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(1) .one-roadmap-bar:before {
    -webkit-transition-delay: .2631578947s;
    transition-delay: .2631578947s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(1) .one-roadmap-bar:after {
    -webkit-transition-delay: .2816901408s;
    transition-delay: .2816901408s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(2) .one-roadmap-bar:before {
    -webkit-transition-delay: .5263157895s;
    transition-delay: .5263157895s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(2) .one-roadmap-bar:after {
    -webkit-transition-delay: .5633802817s;
    transition-delay: .5633802817s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(3) .one-roadmap-bar:before {
    -webkit-transition-delay: .7894736842s;
    transition-delay: .7894736842s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(3) .one-roadmap-bar:after {
    -webkit-transition-delay: .8450704225s;
    transition-delay: .8450704225s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(4) .one-roadmap-bar:before {
    -webkit-transition-delay: 1.0526315789s;
    transition-delay: 1.0526315789s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(4) .one-roadmap-bar:after {
    -webkit-transition-delay: 1.1267605634s;
    transition-delay: 1.1267605634s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(5) .one-roadmap-bar:before {
    -webkit-transition-delay: 1.3157894737s;
    transition-delay: 1.3157894737s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(5) .one-roadmap-bar:after {
    -webkit-transition-delay: 1.4084507042s;
    transition-delay: 1.4084507042s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(6) .one-roadmap-bar:before {
    -webkit-transition-delay: 1.5789473684s;
    transition-delay: 1.5789473684s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(6) .one-roadmap-bar:after {
    -webkit-transition-delay: 1.6901408451s;
    transition-delay: 1.6901408451s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(7) .one-roadmap-bar:before {
    -webkit-transition-delay: 1.8421052632s;
    transition-delay: 1.8421052632s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(7) .one-roadmap-bar:after {
    -webkit-transition-delay: 1.9718309859s;
    transition-delay: 1.9718309859s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(8) .one-roadmap-bar:before {
    -webkit-transition-delay: 2.1052631579s;
    transition-delay: 2.1052631579s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(8) .one-roadmap-bar:after {
    -webkit-transition-delay: 2.2535211268s;
    transition-delay: 2.2535211268s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(9) .one-roadmap-bar:before {
    -webkit-transition-delay: 2.3684210526s;
    transition-delay: 2.3684210526s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(9) .one-roadmap-bar:after {
    -webkit-transition-delay: 2.5352112676s;
    transition-delay: 2.5352112676s
}

.one-roadmap.ca-initvalues-applied .one-roadmap-bar:after,.one-roadmap.ca-initvalues-applied .one-roadmap-bar:before {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.countdown {
    font-size: 5.3125em;
    line-height: 1em;
    font-weight: 600
}

.countdown .countdown-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.countdown .countdown-section {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 2%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    position: relative
}

.countdown .countdown-sep {
    margin-top: -.35em
}

.countdown .countdown-amount {
    letter-spacing: -.05em;
    line-height: 1em
}

.countdown .countdown-period {
    margin-top: .5em;
    font-size: .1764705882em;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: .1em;
    opacity: .5
}

.countdown.light {
    color: #fff
}

.countdown.primary {
    color: var(--color-primary)
}

.countdown.primary .countdown-period {
    color: #fff
}

.countdown.countdown-sep-off .countdown-sep {
    display: none
}

ol.breadcrumb {
    background: 0 0;
    color: var(--color-link)
}

ol.breadcrumb>li {
    color: inherit
}

ol.breadcrumb>li:not(:last-child):after {
    content: '\f105';
    margin-left: .75em;
    margin-right: .5em;
    font-family: FontAwesome;
    font-size: .85em
}

ol.breadcrumb>li:hover {
    opacity: 1
}

ol.breadcrumb>li+li:before {
    content: none
}

ol.breadcrumb>.active {
    color: inherit
}

ol.breadcrumb span {
    opacity: .6;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

ol.breadcrumb a:hover span {
    opacity: 1
}

ol.breadcrumb.text-darker {
    color: #545663
}

ol.breadcrumb.text-darker a {
    color: inherit
}

ol.breadcrumb.text-darker a:hover {
    color: #000
}

.one-bullet-list {
    margin-bottom: 30px
}

.one-bullet-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: .5em
}

.one-bullet-list li:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 1em;
    border-radius: 50em;
    background-color: var(--color-primary)
}

.one-bullet-list .inline-nav li {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.one-bullet-list .inline-nav li+li {
    margin-left: 2.5em
}

.lqd-vbg-video .mejs__overlay,.lqd-vbg-video .mejs__poster,.lqd-vbg-video:not([data-property]) {
    -o-object-fit: cover;
    object-fit: cover;
    font-family: "object-fit:cover";
    width: 100%!important;
    height: 100%!important
}

.lqd-vbg-video .YTPOverlay,.lqd-vbg-video .inlinePlayButton,.lqd-vbg-video .mb_YTPBar,.lqd-vbg-video .mejs-controls,.lqd-vbg-video .mejs__controls,.lqd-vbg-video .mejs__overlay-play {
    display: none!important
}

.lqd-vbg-inner,.lqd-vbg-loader,.lqd-vbg-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden
}

.lqd-vbg-inner:after {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1
}

.lqd-vbg-wrap .inline-YTPlayer {
    height: 100%
}

.lqd-frickin-img-bg,.lqd-frickin-img-holder {
    -webkit-transition: opacity 1s 0 cubic-bezier(0.23,1,.32,1),-webkit-transform 1.5s .25s cubic-bezier(0.23,1,.32,1);
    transition: opacity 1s 0 cubic-bezier(0.23,1,.32,1),-webkit-transform 1.5s .25s cubic-bezier(0.23,1,.32,1);
    transition: opacity 1s 0 cubic-bezier(0.23,1,.32,1),transform 1.5s .25s cubic-bezier(0.23,1,.32,1);
    transition: opacity 1s 0 cubic-bezier(0.23,1,.32,1),transform 1.5s .25s cubic-bezier(0.23,1,.32,1),-webkit-transform 1.5s .25s cubic-bezier(0.23,1,.32,1);
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
    opacity: 0
}

.lqd-frickin-img-bg {
    display: inline-block;
    position: absolute;
    top: 25px;
    left: 25px;
    right: 25px;
    bottom: 25px;
    z-index: 0;
    background-color: var(--color-primary)
}

.lqd-frickin-img-holder {
    z-index: 2;
    opacity: 0;
    -webkit-box-shadow: 0 30px 50px rgba(0,0,0,.17);
    box-shadow: 0 30px 50px rgba(0,0,0,.17)
}

.lqd-frickin-img-holder figure {
    background-size: cover
}

.lqd-frickin-img-holder img {
    width: 100%
}

.lqd-frickin-img-holder [data-responsive-bg=true]>img {
    visibility: hidden
}

.lqd-frickin-img-inner {
    padding: 25px
}

.lqd-frickin-img,.lqd-frickin-img-holder {
    position: relative
}

.lqd-frickin-img {
    margin-bottom: 30px
}

.lqd-frickin-img.is-in-view .lqd-frickin-img-bg,.lqd-frickin-img.is-in-view .lqd-frickin-img-holder {
    opacity: 1
}

.lqd-frickin-img.is-in-view .lqd-frickin-img-bg {
    -webkit-transform: translate(-12px,12px);
    transform: translate(-12px,12px)
}

.lqd-frickin-img.is-in-view .lqd-frickin-img-holder {
    -webkit-transform: translate(12px,-12px);
    transform: translate(12px,-12px)
}

.lqd-frickin-img.is-in-view.lqd-freak-to-left .lqd-frickin-img-bg {
    -webkit-transform: translate(12px,12px);
    transform: translate(12px,12px)
}

.lqd-frickin-img.is-in-view.lqd-freak-to-left .lqd-frickin-img-holder {
    -webkit-transform: translate(-12px,-12px);
    transform: translate(-12px,-12px)
}

.lqd-promo-cat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-style: italic;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    width: 30px
}

.lqd-promo-cat ul {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.lqd-promo-dynamic-shape {
    display: block;
    width: 124%;
    height: 124%;
    position: absolute;
    top: -15%;
    left: -25%;
    z-index: 0
}

.lqd-promo-dynamic-shape svg {
    overflow: visible
}

.lqd-promo-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(58.75% - 30px);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.lqd-promo-img img {
    width: 100%
}

.lqd-promo-img-inner {
    -webkit-box-shadow: 0 50px 100px rgba(0,0,0,.3);
    box-shadow: 0 50px 100px rgba(0,0,0,.3);
    opacity: 0;
    overflow: hidden;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.lqd-promo-img-inner.block-revealer {
    opacity: 1
}

.lqd-promo-img-inner .block-revealer__element {
    will-change: transform
}

.lqd-promo-img-inner.revealing-ended .block-revealer__element {
    will-change: auto
}

.lqd-promo-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 60px 0 60px 15px;
    width: 39.25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.lqd-promo-content>* {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0
}

.lqd-promo-content h2 {
    margin: 0 -1em;
    font-size: 120px;
    font-weight: 700;
    line-height: 1em
}

.lqd-promo-content .lqd-lines,.lqd-promo-content .lqd-words {
    white-space: nowrap
}

.lqd-promo-content .split-inner {
    display: inline-block
}

.lqd-promo-content .btn {
    margin-top: auto
}

.lqd-promo-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative
}

.lqd-promo-wrap {
    position: relative;
    margin-top: 120px;
    margin-bottom: 120px
}

.lqd-promo-alt {
    margin-top: 0;
    margin-bottom: 0;
    color: #181b31
}

.lqd-promo-alt .lqd-promo-inner {
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.lqd-promo-alt .lqd-promo-cat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    width: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0;
    -webkit-writing-mode: inherit;
    -ms-writing-mode: inherit;
    writing-mode: inherit;
    font-style: normal;
    font-size: 11px
}

.lqd-promo-alt .lqd-promo-cat:before {
    content: '';
    display: inline-block;
    width: 2em;
    height: 1px;
    margin-right: 1em;
    background-color: #000
}

.lqd-promo-alt .lqd-promo-cat ul {
    -webkit-transform: none;
    transform: none;
    margin: 0
}

.lqd-promo-alt .lqd-promo-cat li:not(:last-child):after {
    content: '|';
    margin-left: .75em
}

.lqd-promo-alt .lqd-promo-content h2 {
    margin-top: .75em;
    margin-bottom: .75em
}

.lqd-promo-alt .lqd-promo-img-inner {
    -webkit-box-shadow: none;
    box-shadow: none
}

@-webkit-keyframes lqdBackToTopArrow {
    40% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
        opacity: 0
    }

    41% {
        -webkit-transform: translateY(15px);
        transform: translateY(15px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
}

@keyframes lqdBackToTopArrow {
    40% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
        opacity: 0
    }

    41% {
        -webkit-transform: translateY(15px);
        transform: translateY(15px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
}

.lqd-back-to-top {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    -webkit-transition: all .65s cubic-bezier(0.23,1,.32,1);
    transition: all .65s cubic-bezier(0.23,1,.32,1)
}

.lqd-back-to-top a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 45px;
    height: 45px;
    border-radius: 50em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    -webkit-box-shadow: 0 0 30px -2px rgba(0,0,0,.15);
    box-shadow: 0 0 30px -2px rgba(0,0,0,.15);
    font-size: 18px;
    color: #000;
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.23,1,.32,1),-webkit-box-shadow 1s cubic-bezier(0.23,1,.32,1);
    transition: -webkit-transform 1s cubic-bezier(0.23,1,.32,1),-webkit-box-shadow 1s cubic-bezier(0.23,1,.32,1);
    transition: transform 1s cubic-bezier(0.23,1,.32,1),box-shadow 1s cubic-bezier(0.23,1,.32,1);
    transition: transform 1s cubic-bezier(0.23,1,.32,1),box-shadow 1s cubic-bezier(0.23,1,.32,1),-webkit-transform 1s cubic-bezier(0.23,1,.32,1),-webkit-box-shadow 1s cubic-bezier(0.23,1,.32,1)
}

.lqd-back-to-top a i {
    display: inline-block;
    line-height: 1;
    margin-top: -1px;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease,-webkit-transform .3s ease
}

.lqd-back-to-top a:hover {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-box-shadow: 0 0 40px rgba(0,0,0,.15);
    box-shadow: 0 0 40px rgba(0,0,0,.15)
}

.lqd-back-to-top a:hover i {
    -webkit-animation: lqdBackToTopArrow 1s cubic-bezier(0.23,1,.32,1) both alternate;
    animation: lqdBackToTopArrow 1s cubic-bezier(0.23,1,.32,1) both alternate
}

.lqd-back-to-top.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.lqd-h-sep {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.lqd-h-sep-inner {
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0;
    height: 1px;
    background-color: var(--color-primary)
}

.lqd-rst-menu {
    padding-bottom: 1.25em;
    margin-bottom: 1.5em
}

.lqd-rst-menu:not(:last-of-type) {
    border-bottom: 1px dashed #dbdbdb
}

.lqd-rst-menu ul {
    font-size: 13px;
    color: #a7a7a7
}

.ld-img-hover-opacity {
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.ld-img-hover-opacity:hover {
    opacity: 1
}

.lqd-column.pull-down {
    margin-bottom: -340px
}

.lqd-column.pull-up-05x {
    margin-top: -4.5%
}

.lqd-column.pull-up {
    margin-top: -10%
}

.lqd-column.pull-up-2x {
    margin-top: -20%
}

.lqd-column.pull-up-3x {
    margin-top: -30%
}

.lqd-column.pull-up-4x {
    margin-top: -40%
}

.column-shadowed-1 {
    -webkit-box-shadow: 0 20px 50px 0 rgba(0,0,0,.1);
    box-shadow: 0 20px 50px 0 rgba(0,0,0,.1)
}

.white-box-shadow {
    -webkit-box-shadow: -10px 0 30px 1px #fff,10px 0 30px 1px #fff;
    box-shadow: -10px 0 30px 1px #fff,10px 0 30px 1px #fff
}

.liquid-column-overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute!important;
    top: 0;
    left: 0;
    -webkit-transition: background .3s,opacity .3s;
    transition: background .3s,opacity .3s;
    border-radius: inherit
}

.liquid-column-overlay~.lqd-column-inner {
    position: relative
}

.liquid-column-overlay-hover {
    opacity: 0
}

.lqd-column:hover .liquid-column-overlay-hover {
    opacity: 1
}

.lqd-column:hover .liquid-column-overlay-hover+.liquid-column-overlay {
    opacity: 0
}

@media screen and (min-width: 1200px) {
    .stretch-to-right {
        margin-right:-22vw!important
    }

    .stretch-to-left {
        margin-left: -22vw!important
    }
}

@media screen and (max-width: 767px) {
    .d-flex>.lqd-column:not([class*=col-xs-]) {
        width:100%
    }
}

.vc_row {
    position: relative
}

.vc_row.bg-none,.vc_row[data-row-bg] {
    background-image: none!important
}

.vc_row[data-parallax]:not(.liquid-parallax-bg) {
    background-size: 140%!important
}

.vc_row:hover .liquid-row-overlay-hover {
    opacity: 1
}

.vc_row:hover .liquid-row-overlay-hover+.liquid-row-overlay {
    opacity: 0
}

.vc_row-flex .ld-row:after,.vc_row-flex .ld-row:before {
    content: none
}

.ld-container.container-fluid {
    width: 100%
}

.row-bg-loader {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    background-image: url(data:image/svg+xml;base64,PCEtLSBCeSBTYW0gSGVyYmVydCAoQHNoZXJiKSwgZm9yIGV2ZXJ5b25lLiBNb3JlIEAgaHR0cDovL2dvby5nbC83QUp6YkwgLS0+Cjxzdmcgd2lkdGg9IjM4IiBoZWlnaHQ9IjM4IiB2aWV3Qm94PSIwIDAgMzggMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjMDAwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlLXdpZHRoPSIyIj4KICAgICAgICAgICAgPGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjIiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMzYgMThjMC05Ljk0LTguMDYtMTgtMTgtMTgiPgogICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0KICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iCiAgICAgICAgICAgICAgICAgICAgdHlwZT0icm90YXRlIgogICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiCiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIKICAgICAgICAgICAgICAgICAgICBkdXI9IjAuOHMiCiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICAgICAgICAgICAgPC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    background-position: center;
    background-size: 38px;
    background-repeat: no-repeat;
    -webkit-transition-property: opacity,visibility;
    transition-property: opacity,visibility;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
    transition-timing-function: cubic-bezier(0.23,1,.32,1)
}

.row-bg-appended .row-bg-loader,.slideshow-applied .row-bg-loader {
    opacity: 0;
    visibility: hidden
}

.row-bg-loader.style-2 {
    /* background-image: url(../themes/responsiv-clean/assets/img/spinners/spinner-1.gif); */
    background-size: 80px
}

.row-bg-loader.style-3 {
    /* background-image: url(../themes/responsiv-clean/assets/img/spinners/spinner-2.gif) */
}

.row-bg,.row-bg-inner,.row-bg-wrap {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: inherit;
    background-position: inherit;
    background-attachment: inherit;
    background-repeat: no-repeat
}

.row-bg-wrap {
    overflow: hidden
}

.bg-not-loaded .row-bg-inner {
    opacity: 0
}

@media screen and (min-width: 992px) {
    .vc_row.pull-down {
        margin-bottom:-340px
    }

    .vc_row.pull-up {
        margin-top: -230px
    }
}

.wpb_text_column>.wpb_wrapper>ul {
    padding-left: 0
}

.wpb_text_column ul {
    list-style-position: inside
}

body.search .content {
    padding-top: 100px;
    padding-bottom: 100px
}

body.search .content article {
    margin-bottom: 3em
}

body.search .content .entry-title {
    margin: 1em 0 .75em
}

body.search .content .entry-title a {
    color: #000
}

body.search .content .entry-title a:hover {
    color: var(--color-primary)
}

body.search .titlebar {
    border-bottom: 1px solid rgba(0,0,0,.07)
}

body.search .titlebar-inner {
    padding-top: 100px;
    padding-bottom: 100px
}

body.search .titlebar-inner h1 {
    font-size: 48px
}

body.search .no-results .page-header {
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 2.5em
}

body.search .no-results .page-header .page-title {
    margin-top: 0;
    margin-bottom: .5em;
    font-size: 42px
}

body.search .no-results .search-form input {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 50px;
    padding: 5px 2em;
    border: none;
    border-radius: 3px;
    background: 0 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 400
}

body.search .no-results .search-form input[type=search] {
    border: 1px solid #eaeaea;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    transition: box-shadow .3s;
    transition: box-shadow .3s,-webkit-box-shadow .3s
}

body.search .no-results .search-form input[type=search]:focus {
    -webkit-box-shadow: 0 10px 25px rgba(0,0,0,.1);
    box-shadow: 0 10px 25px rgba(0,0,0,.1);
    outline: 0
}

body.search .no-results .search-form input[type=submit] {
    background-color: var(--color-primary);
    color: #fff
}

.page-404 .btn {
    font-size: 13px;
    -webkit-box-shadow: 0 8px 15px rgba(0,0,0,.07);
    box-shadow: 0 8px 15px rgba(0,0,0,.07)
}

.page-404 .btn .btn-icon {
    font-size: 2.15em;
    margin-right: .5em;
    text-shadow: 0 .25px 0 currentColor,0 -.25px 0 currentColor,.25px 0 0 currentColor,-.25px 0 0 currentColor
}

.page-404 .btn:hover {
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    box-shadow: 0 10px 20px rgba(0,0,0,.1)
}

.text-404 {
    position: relative
}

.text-404 .ld-particles-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: auto
}

.text-404 .re-particles-inner {
    position: relative
}

.text-404 .re-particles-inner:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0 0 15px 2px #fff inset,0 0 30px 2px #fff inset,0 0 45px 2px #fff inset,0 0 60px 2px #fff inset;
    box-shadow: 0 0 15px 2px #fff inset,0 0 30px 2px #fff inset,0 0 45px 2px #fff inset,0 0 60px 2px #fff inset
}

.text-404 h1 {
    margin: 0 0 .45em;
    font-size: 300px;
    line-height: 1;
    font-weight: 700;
    position: relative
}

.has-sidebar .contents-container>.container {
    width: 100%
}

.main-sidebar .widget,.wpb_widgetised_column .widget {
    margin-bottom: 45px
}

.main-sidebar .widget-title,.wpb_widgetised_column .widget-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0;
    text-transform: none;
    margin-top: 0;
    margin-bottom: 1em
}

.main-sidebar ul,.wpb_widgetised_column ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.main-sidebar ul ol,.main-sidebar ul ul,.wpb_widgetised_column ul ol,.wpb_widgetised_column ul ul {
    margin-left: 10px;
    margin-top: 1em;
    margin-bottom: 1em
}

.main-sidebar a,.wpb_widgetised_column a {
    color: #000
}

.main-sidebar a:hover,.wpb_widgetised_column a:hover {
    color: var(--color-primary)
}

.main-sidebar .ui-selectmenu-button,.main-sidebar select,.wpb_widgetised_column .ui-selectmenu-button,.wpb_widgetised_column select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 50px;
    padding: 10px 15px;
    background: 0 0;
    border: 1px solid #d8dbe2;
    border-radius: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.main-sidebar .ui-selectmenu-button .ui-selectmenu-icon,.wpb_widgetised_column .ui-selectmenu-button .ui-selectmenu-icon {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.widget_archive ul,.widget_categories ul,.widget_meta ul,.widget_nav_menu ul,.widget_pages ul,.widget_recent_comments ul,.widget_recent_entries ul {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .1em
}

.widget_archive ul li,.widget_categories ul li,.widget_meta ul li,.widget_nav_menu ul li,.widget_pages ul li,.widget_recent_comments ul li,.widget_recent_entries ul li {
    margin-bottom: 1em
}

.widget_archive ul a,.widget_categories ul a,.widget_meta ul a,.widget_nav_menu ul a,.widget_pages ul a,.widget_recent_comments ul a,.widget_recent_entries ul a {
    position: relative
}

.widget_archive ul a:before,.widget_categories ul a:before,.widget_meta ul a:before,.widget_nav_menu ul a:before,.widget_pages ul a:before,.widget_recent_comments ul a:before,.widget_recent_entries ul a:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -.35em;
    left: 0;
    background-color: currentColor;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
}

.widget_archive ul a:hover:before,.widget_categories ul a:hover:before,.widget_meta ul a:hover:before,.widget_nav_menu ul a:hover:before,.widget_pages ul a:hover:before,.widget_recent_comments ul a:hover:before,.widget_recent_entries ul a:hover:before {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.widget_menu li {
    margin-bottom: .75em
}

.widget_product_search,.widget_search {
    position: relative
}

.widget_product_search input,.widget_product_search label,.widget_search input,.widget_search label {
    width: 100%;
    display: block
}

.widget_product_search input,.widget_search input {
    padding: 15px
}

.widget_product_search input[type=search],.widget_search input[type=search] {
    border: 1px solid #dedede;
    padding-left: 45px
}

.widget_product_search input[type=search]:focus,.widget_search input[type=search]:focus {
    outline: 0;
    border-color: #b8b8b8
}

.widget_search label {
    position: relative
}

.widget_search label:after {
    content: '\f002';
    font: normal normal 16px/1 fontAwesome
}

.widget_search input[type=submit],.widget_search label:after {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 32px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 8px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.widget_search input[type=submit] {
    border: none;
    background: 0 0;
    opacity: 0
}

.widget_product_search button[type=submit] {
    display: inline-block;
    width: 32px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 8px;
    background: 0 0;
    border: none;
    text-indent: -99999px
}

.widget_product_search button[type=submit]:after {
    content: '\f002';
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font: normal normal 16px/1 fontAwesome
}

.widget_product_search button[type=submit]:focus {
    outline: 0
}

.widget_calendar #wp-calendar {
    width: 100%;
    border: 1px solid #ededed
}

.widget_calendar caption,.widget_calendar tfoot td {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .075em;
    padding: 10px 15px;
    color: #000
}

.widget_calendar caption {
    border: 1px solid #ededed;
    border-bottom: none
}

.widget_calendar tfoot {
    border-top: 1px solid #ededed
}

.widget_calendar tbody td,.widget_calendar tbody th,.widget_calendar thead td,.widget_calendar thead th {
    text-align: center;
    padding: 5px
}

.widget_calendar thead th {
    color: #000;
    font-weight: 600
}

.ld_widget_recent_entries li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 22px
}

.ld_widget_recent_entries li figure {
    margin-right: 10px;
    width: 80px
}

.ld_widget_recent_entries li figure img {
    width: 100%
}

.ld_widget_recent_entries li span {
    display: block;
    font-size: 13px;
    margin-top: 4px
}

.ld_widget_recent_entries li .ld_entries_contents {
    overflow: hidden;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-top: -6px
}

.ld_widget_popular_entries li {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 26px
}

.ld_widget_popular_entries li figure {
    width: 100%;
    margin-bottom: .5em
}

.ld_widget_popular_entries li figure img {
    width: 100%
}

.ld_widget_popular_entries li span {
    display: block;
    margin-top: .15em;
    font-size: 15px
}

.ld_widget_social_icons a:hover {
    color: #fff
}

.widget_tag_cloud a {
    display: inline-block;
    border: 1px solid #dedede;
    padding: .25em 1em;
    margin-bottom: 5px;
    margin-right: 2px;
    font-size: 12px!important;
    line-height: 1.5em;
    font-weight: 500;
    color: #000
}

.widget_tag_cloud a:hover {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
    color: #fff;
    -webkit-box-shadow: 0 10px 25px rgba(0,0,0,.1);
    box-shadow: 0 10px 25px rgba(0,0,0,.1)
}

@media screen and (min-width: 1200px) {
    .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .social-icon a {
        color:rgba(255,255,255,.7)!important
    }

    .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .social-icon a:hover {
        color: #fff!important
    }

    .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger,.lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .main-nav>li>a {
        color: rgba(255,255,255,.7)
    }

    .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger:hover,.lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .main-nav>li>a:hover {
        color: #fff
    }

    .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-light {
        opacity: 1;
        visibility: visible
    }

    .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .social-icon a {
        color: rgba(0,0,0,.7)!important
    }

    .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .social-icon a:hover {
        color: #000!important
    }

    .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger,.lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .main-nav>li>a {
        color: rgba(0,0,0,.7)
    }

    .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger:hover,.lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .main-nav>li>a:hover {
        color: #000
    }

    .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-dark {
        opacity: 1;
        visibility: visible
    }

    .lqd-stack-moving-down .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger,.lqd-stack-moving-down .mainbar-wrap:not(.is-stuck) .main-nav>li>a,.lqd-stack-moving-down .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-dark,.lqd-stack-moving-down .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-light,.lqd-stack-moving-down .mainbar-wrap:not(.is-stuck) .social-icon a {
        -webkit-transition-delay: .35s;
        transition-delay: .35s
    }
}

@media screen and (max-width: 1199px) {
    .lqd-stack-has-prevnext-buttons .lqd-stack-prevnext-wrap {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important;
        position: absolute;
        top: auto!important;
        left: auto!important;
        right: 20px!important;
        bottom: 40px!important;
        -webkit-transform: none!important;
        transform: none!important
    }

    .lqd-stack-has-prevnext-buttons .lqd-stack-prevnext-wrap button {
        margin: 0 5px!important
    }

    .lqd-stack-has-prevnext-buttons .lqd-stack-button-labbel,.lqd-stack-has-prevnext-buttons .lqd-stack-prevnext-button {
        -webkit-transform: none!important;
        transform: none!important
    }

    .lqd-stack-has-prevnext-buttons .lqd-stack-prevnext-button {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto
    }

    .lqd-stack-has-prevnext-buttons .lqd-back-to-top {
        display: none
    }

    .lqd-stack-has-prevnext-buttons.lqd-stack-buttons-style-1 .lqd-stack-prevnext-wrap {
        bottom: 50px!important;
        left: 20px!important;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    #pp-nav {
        display: none;
        right: 20px
    }

    .lqd-stack-extra {
        display: none
    }
}

@media screen and (min-width: 992px) {
    .header-side {
        width:375px;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0
    }

    .header-side .mainbar,.header-side .mainbar-container,.header-side .mainbar-row,.header-side .mainbar-wrap {
        width: 100%;
        height: 100%
    }

    .header-side .mainbar-container {
        padding: 0
    }

    .header-side .mainbar-wrap {
        padding: 12vh 50px;
        overflow: hidden;
        position: relative
    }

    .header-side .mainbar {
        overflow: hidden
    }

    .header-side .mainbar-row {
        width: calc(100% + 20px);
        margin: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        overflow-x: hidden;
        overflow-y: auto
    }

    .header-side .mainbar-row>[class^=col] {
        padding: 0;
        margin: 30px 0;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex: 1 auto;
        flex: 1 auto
    }

    .header-side .mainbar-row>[class^=col]:first-child {
        margin-top: 0
    }

    .header-side .mainbar-row>[class^=col]:last-child {
        margin-bottom: 0;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .header-side .mainbar-row>[class^=col]:last-child .header-module {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .header-side .navbar-header {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .header-side .navbar-brand {
        padding: 0
    }

    .header-side .navbar-collapse {
        width: 100%;
        overflow: hidden!important
    }

    .header-side .main-nav {
        width: calc(100% + 25px);
        padding-right: 25px;
        display: block;
        overflow-x: hidden;
        overflow-y: auto
    }

    .header-side .main-nav>li {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .header-side .main-nav>li>a {
        display: block;
        width: 100%;
        padding-left: 0
    }

    .header-side .nav-item-children {
        padding-right: 15px
    }

    .header-side .header-module {
        margin-bottom: 15px;
        margin-left: 0!important
    }

    .header-side .header-module>h1,.header-side .header-module>h2,.header-side .header-module>h3,.header-side .header-module>h4,.header-side .header-module>h5,.header-side .header-module>h6 {
        margin-top: 0;
        margin-bottom: .25em
    }

    .header-side .ld-dropdown-menu-content,.header-side .ld-module-dropdown {
        background: 0 0
    }

    .header-side .ld-dropdown-menu-content {
        margin-top: 1em;
        width: auto;
        padding: 0;
        border: none
    }

    .header-side .ld-module-search .ld-module-trigger {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: -webkit-transform .45s cubic-bezier(0.86,0,.07,1);
        transition: -webkit-transform .45s cubic-bezier(0.86,0,.07,1);
        transition: transform .45s cubic-bezier(0.86,0,.07,1);
        transition: transform .45s cubic-bezier(0.86,0,.07,1),-webkit-transform .45s cubic-bezier(0.86,0,.07,1)
    }

    .header-side .ld-module-search .ld-module-trigger.collapse {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }

    .header-side .ld-module-search .ld-search-form-container {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: -webkit-transform .45s cubic-bezier(0.86,0,.07,1);
        transition: -webkit-transform .45s cubic-bezier(0.86,0,.07,1);
        transition: transform .45s cubic-bezier(0.86,0,.07,1);
        transition: transform .45s cubic-bezier(0.86,0,.07,1),-webkit-transform .45s cubic-bezier(0.86,0,.07,1)
    }

    .header-side .ld-module-search .ld-module-dropdown {
        width: 250px;
        height: auto!important;
        top: 0;
        left: 0;
        right: auto;
        overflow: hidden
    }

    .header-side .ld-module-search .ld-module-dropdown[aria-expanded=true] .ld-search-form-container {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    .header-side .ld-search-form-container {
        width: auto;
        padding: 0;
        border: none
    }

    .header-side-style-1 .navbar-collapse {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 375px;
        height: 100vh!important;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        -webkit-transform: translateX(-200%);
        transform: translateX(-200%);
        background-color: #fdfdfe;
        -webkit-box-shadow: 0 0 0 #f0f1f6 inset;
        box-shadow: 0 0 0 #f0f1f6 inset;
        -webkit-transition: all .45s cubic-bezier(0.7,0,.2,1);
        transition: all .45s cubic-bezier(0.7,0,.2,1)
    }

    .header-side-style-1 .navbar-collapse[aria-expanded=true] {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-box-shadow: -70px 0 70px #f0f1f6 inset;
        box-shadow: -70px 0 70px #f0f1f6 inset
    }

    .header-side-style-1 .main-nav {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0
    }

    .header-side-style-3 .mainbar-row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .header-side-style-3 .mainbar-row>[class^=col] {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .header-side-style-3 .header-module,.header-side-style-3 .navbar-collapse,.header-side-style-3 .navbar-header {
        -webkit-box-flex: 1;
        -ms-flex: 1 auto;
        flex: 1 auto
    }

    .header-side-style-3 .navbar-header {
        margin-bottom: 45px
    }

    .header-side-style-3 .navbar-collapse {
        margin-bottom: 40px
    }

    .header-style-side #content,.header-style-side #wrap>.main-header,.header-style-side .main-footer,.header-style-side .titlebar {
        -webkit-transition: -webkit-transform .45s cubic-bezier(0.7,0,.2,1);
        transition: -webkit-transform .45s cubic-bezier(0.7,0,.2,1);
        transition: transform .45s cubic-bezier(0.7,0,.2,1);
        transition: transform .45s cubic-bezier(0.7,0,.2,1),-webkit-transform .45s cubic-bezier(0.7,0,.2,1)
    }

    .side-nav-showing #content,.side-nav-showing #wrap>.main-header,.side-nav-showing .main-footer,.side-nav-showing .titlebar {
        -webkit-transform: translateX(375px);
        transform: translateX(375px)
    }

    .lqd-stack-initiated .header-side {
        width: 200px
    }

    .lqd-stack-initiated .header-side .mainbar-wrap {
        padding: 10vh 45px
    }

    .lqd-stack-initiated.header-style-side #wrap {
        padding-left: 0
    }

    .header-fullscreen-style-1 .navbar-fullscreen {
        width: 100%;
        height: 100%!important;
        padding: 10vh 0 15vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        background-color: #fff;
        -webkit-transition: all .3s ease;
        transition: all .3s ease
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav {
        display: block
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateY(-25%) rotateX(45deg);
        transform: translateY(-25%) rotateX(45deg);
        -webkit-transition: all .45s cubic-bezier(0.23,1,.32,1);
        transition: all .45s cubic-bezier(0.23,1,.32,1)
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li:nth-child(10) {
        -webkit-transition-delay: .0588235294s;
        transition-delay: .0588235294s
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li:nth-child(9) {
        -webkit-transition-delay: .1176470588s;
        transition-delay: .1176470588s
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li:nth-child(8) {
        -webkit-transition-delay: .1764705882s;
        transition-delay: .1764705882s
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li:nth-child(7) {
        -webkit-transition-delay: .2352941176s;
        transition-delay: .2352941176s
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li:nth-child(6) {
        -webkit-transition-delay: .2941176471s;
        transition-delay: .2941176471s
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li:nth-child(5) {
        -webkit-transition-delay: .3529411765s;
        transition-delay: .3529411765s
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li:nth-child(4) {
        -webkit-transition-delay: .4117647059s;
        transition-delay: .4117647059s
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li:nth-child(3) {
        -webkit-transition-delay: .4705882353s;
        transition-delay: .4705882353s
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li:nth-child(2) {
        -webkit-transition-delay: .5294117647s;
        transition-delay: .5294117647s
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li:nth-child(1) {
        -webkit-transition-delay: .5882352941s;
        transition-delay: .5882352941s
    }

    .header-fullscreen-style-1 .navbar-fullscreen .main-nav>li>a {
        padding-left: 0;
        padding-right: 0
    }

    .header-fullscreen-style-1 .navbar-fullscreen .nav-item-children {
        text-align: center;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .header-fullscreen-style-1 .navbar-fullscreen .nav-item-children>li>a {
        padding: 0
    }

    .header-fullscreen-style-1 .navbar-fullscreen .nav-item-children>li:hover>a {
        background-color: transparent
    }

    .header-fullscreen-style-1 .navbar-fullscreen .megamenu .nav-item-children {
        display: none;
        visibility: visible;
        left: auto!important;
        right: auto!important
    }

    .header-fullscreen-style-1 .navbar-fullscreen .megamenu .ld-container,.header-fullscreen-style-1 .navbar-fullscreen .megamenu .megamenu-column,.header-fullscreen-style-1 .navbar-fullscreen .megamenu .megamenu-container {
        width: 100%!important
    }

    .header-fullscreen-style-1 .navbar-fullscreen .megamenu section.vc_row {
        padding: 0!important
    }

    .header-fullscreen-style-1 .navbar-fullscreen .megamenu .ld-row {
        display: block
    }

    .header-fullscreen-style-1 .navbar-fullscreen .header-module {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .header-fullscreen-style-1 .navbar-fullscreen[aria-expanded=true] {
        opacity: 1;
        visibility: visible
    }

    .header-fullscreen-style-1 .navbar-fullscreen[aria-expanded=true] .main-nav>li {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0) rotateX(0);
        transform: translateY(0) rotateX(0)
    }

    .navbar-logo-centered .navbar-brand {
        -webkit-box-ordinal-group: inherit;
        -ms-flex-order: inherit;
        order: inherit;
        padding-left: 35px;
        padding-right: 35px;
        -ms-flex-negative: 0;
        flex-shrink: 0
    }

    .text-lg-right .header-module {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .text-lg-left .header-module {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .text-lg-center .header-module {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-collapse~.header-module {
        margin-left: 25px
    }

    .navbar-collapse:not(.navbar-fullscreen) .header-module {
        display: none
    }

    .nav-trigger.navbar-toggle {
        display: none
    }

    .ld-search-form .input-icon {
        pointer-events: none
    }

    .megamenu {
        position: static!important
    }

    .main-nav:not(.main-nav-side) .megamenu:not(.position-applied) .nav-item-children {
        display: block!important;
        visibility: hidden
    }

    .megamenu .megamenu-container.container {
        padding-left: 15px;
        padding-right: 15px
    }

    .megamenu .megamenu-container .container {
        width: 100%
    }

    .megamenu .nav-item-children {
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;
        background: 0 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .megamenu .megamenu-inner-row {
        background-color: #fff;
        -webkit-box-shadow: 0 16px 50px rgba(0,0,0,.07);
        box-shadow: 0 16px 50px rgba(0,0,0,.07)
    }

    .megamenu .megamenu-inner-row.vc_row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .megamenu .megamenu-inner-row.vc_row:after {
        content: none
    }

    .megamenu .megamenu-inner-row.vc_row-has-bg:before {
        background-color: inherit
    }

    .megamenu.megamenu-content-stretch .nav-item-children {
        left: 0!important;
        right: 0!important
    }

    .megamenu.megamenu-fullwidth .nav-item-children {
        width: 100vw;
        max-width: none;
        left: 50%!important;
        right: 50%!important;
        margin-left: -50vw!important;
        margin-right: -50vw!important
    }

    .megamenu.megamenu-fullwidth .megamenu-container {
        width: 100vw!important;
        max-width: none
    }

    .megamenu.position-applied .megamenu-column {
        -webkit-box-flex: 1;
        -ms-flex: 1 auto;
        flex: 1 auto;
        padding: 48px 35px 48px 0
    }

    .megamenu.position-applied .megamenu-column.group-dark {
        background-color: #f3f6f9;
        padding-left: 48px
    }

    .megamenu.position-applied .megamenu-column.group-dark:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1000%;
        height: 100%;
        background-color: #f3f6f9
    }

    .megamenu.position-applied .megamenu-column.group-dark * {
        position: relative;
        z-index: 2
    }

    .megamenu-inner-row .row {
        margin-left: 0;
        margin-right: 0
    }

    .navbar-header {
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-header .header-module,.navbar-header .mobile-logo-default {
        display: none
    }

    .navbar-collapse {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        height: auto!important;
        -ms-flex-preferred-size: 0;
        flex-basis: 0
    }

    .navbar-collapse-clone,.navbar-collapse>.nav-trigger {
        display: none!important
    }

    .main-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .main-nav>li,.main-nav>li>a {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .main-nav>li:first-child {
        padding-left: 0
    }

    .main-nav>li:last-child {
        padding-right: 0
    }

    .main-nav>li.nav-item-cloned {
        display: none
    }

    .main-nav .submenu-expander {
        display: none!important
    }

    .main-nav-hover-linethrough>li>a .link-ext,.main-nav-hover-underline-1>li>a .link-ext,.main-nav-hover-underline-3>li>a .link-ext {
        display: inline-block;
        width: 100%;
        height: .0625em;
        min-height: 1px;
        position: absolute;
        bottom: -.1875em;
        left: 0;
        background-color: #000;
        -webkit-transform-origin: right center;
        transform-origin: right center;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: -webkit-transform .25s cubic-bezier(0,0,.2,1);
        transition: -webkit-transform .25s cubic-bezier(0,0,.2,1);
        transition: transform .25s cubic-bezier(0,0,.2,1);
        transition: transform .25s cubic-bezier(0,0,.2,1),-webkit-transform .25s cubic-bezier(0,0,.2,1)
    }

    .main-nav-hover-linethrough>li.active>a .link-ext,.main-nav-hover-linethrough>li.current-menu-item>a .link-ext,.main-nav-hover-linethrough>li.is-active>a .link-ext,.main-nav-hover-linethrough>li>a:hover .link-ext,.main-nav-hover-underline-1>li.active>a .link-ext,.main-nav-hover-underline-1>li.current-menu-item>a .link-ext,.main-nav-hover-underline-1>li.is-active>a .link-ext,.main-nav-hover-underline-1>li>a:hover .link-ext,.main-nav-hover-underline-3>li.active>a .link-ext,.main-nav-hover-underline-3>li.current-menu-item>a .link-ext,.main-nav-hover-underline-3>li.is-active>a .link-ext,.main-nav-hover-underline-3>li>a:hover .link-ext {
        -webkit-transform-origin: left center;
        transform-origin: left center;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }

    .main-nav-hover-linethrough>li>a .link-ext {
        width: 114%;
        bottom: 50%;
        left: -7%;
        margin-top: -.0312em
    }

    .main-nav-hover-underline-2>li>a .link-ext {
        display: inline-block;
        width: 107%;
        height: .4em;
        position: absolute;
        bottom: .25em;
        left: -3.5%;
        background: #f4bcba;
        background: -webkit-gradient(linear,left top,right top,from(#f4bc8b),to(#f1aacc));
        background: linear-gradient(to right,#f4bc8b 0,#f1aacc 100%);
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: right top;
        transform-origin: right top;
        -webkit-transition: -webkit-transform .3s cubic-bezier(0.23,1,.32,1);
        transition: -webkit-transform .3s cubic-bezier(0.23,1,.32,1);
        transition: transform .3s cubic-bezier(0.23,1,.32,1);
        transition: transform .3s cubic-bezier(0.23,1,.32,1),-webkit-transform .3s cubic-bezier(0.23,1,.32,1)
    }

    .main-nav-hover-underline-2>li.active>a .link-ext,.main-nav-hover-underline-2>li.current-menu-item>a .link-ext,.main-nav-hover-underline-2>li.is-active>a .link-ext,.main-nav-hover-underline-2>li>a:hover .link-ext {
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-transform: scaleY(1);
        transform: scaleY(1)
    }

    .main-nav-hover-underline-3 .link-txt {
        position: static
    }

    .main-nav-hover-underline-3>li>a .link-ext {
        height: .21428571428571em;
        min-height: 2px;
        width: 100%;
        left: 0;
        bottom: 0
    }

    .main-nav-side-style-2>li>a .link-ext {
        display: inline-block;
        width: .25em;
        height: .25em;
        min-width: 4px;
        min-height: 4px;
        border-radius: 50em;
        position: absolute;
        top: 50%;
        right: -1em;
        left: auto;
        margin-top: -.12em;
        background: #181b31;
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateY(200%);
        transform: translateY(200%);
        -webkit-transition: all .3s;
        transition: all .3s
    }

    .main-nav-side-style-2>li.active>a .link-ext,.main-nav-side-style-2>li.current-menu-item>a .link-ext,.main-nav-side-style-2>li.is-active>a .link-ext,.main-nav-side-style-2>li>a:hover .link-ext {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    .main-nav-hover-fade-inactive:hover>li>a {
        opacity: .35
    }

    .main-nav-hover-fade-inactive:hover>li:hover>a {
        opacity: 1
    }

    .navbar-visible-ontoggle {
        padding-right: 5px;
        padding-left: 5px
    }

    .navbar-visible-ontoggle .main-nav>li {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
        -webkit-transition: all .3s ease;
        transition: all .3s ease
    }

    .navbar-visible-ontoggle .main-nav>li:nth-child(1) {
        -webkit-transition-delay: .05s;
        transition-delay: .05s
    }

    .navbar-visible-ontoggle .main-nav>li:nth-child(2) {
        -webkit-transition-delay: .1s;
        transition-delay: .1s
    }

    .navbar-visible-ontoggle .main-nav>li:nth-child(3) {
        -webkit-transition-delay: .15s;
        transition-delay: .15s
    }

    .navbar-visible-ontoggle .main-nav>li:nth-child(4) {
        -webkit-transition-delay: .2s;
        transition-delay: .2s
    }

    .navbar-visible-ontoggle .main-nav>li:nth-child(5) {
        -webkit-transition-delay: .25s;
        transition-delay: .25s
    }

    .navbar-visible-ontoggle .main-nav>li:nth-child(6) {
        -webkit-transition-delay: .3s;
        transition-delay: .3s
    }

    .navbar-visible-ontoggle .main-nav>li:nth-child(7) {
        -webkit-transition-delay: .35s;
        transition-delay: .35s
    }

    .navbar-visible-ontoggle .main-nav>li:nth-child(8) {
        -webkit-transition-delay: .4s;
        transition-delay: .4s
    }

    .navbar-visible-ontoggle .main-nav>li:nth-child(9) {
        -webkit-transition-delay: .45s;
        transition-delay: .45s
    }

    .navbar-visible-ontoggle .main-nav>li:nth-child(10) {
        -webkit-transition-delay: .5s;
        transition-delay: .5s
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li {
        opacity: 1;
        visibility: visible;
        -webkit-transform: none;
        transform: none
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li:nth-child(10) {
        -webkit-transition-delay: .05s;
        transition-delay: .05s
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li:nth-child(9) {
        -webkit-transition-delay: .1s;
        transition-delay: .1s
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li:nth-child(8) {
        -webkit-transition-delay: .15s;
        transition-delay: .15s
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li:nth-child(7) {
        -webkit-transition-delay: .2s;
        transition-delay: .2s
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li:nth-child(6) {
        -webkit-transition-delay: .25s;
        transition-delay: .25s
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li:nth-child(5) {
        -webkit-transition-delay: .3s;
        transition-delay: .3s
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li:nth-child(4) {
        -webkit-transition-delay: .35s;
        transition-delay: .35s
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li:nth-child(3) {
        -webkit-transition-delay: .4s;
        transition-delay: .4s
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li:nth-child(2) {
        -webkit-transition-delay: .45s;
        transition-delay: .45s
    }

    .navbar-visible-ontoggle[aria-expanded=true] .main-nav>li:nth-child(1) {
        -webkit-transition-delay: .5s;
        transition-delay: .5s
    }

    .navbar-visible-ontoggle[aria-expanded=false].collapsing .main-nav>li {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }

    .main-nav-side {
        overflow-x: hidden;
        overflow-y: auto
    }

    .main-nav-side>li,.main-nav-side>li:first-child,.main-nav-side>li:last-child {
        padding-left: 1.666em;
        padding-right: 1.666em
    }

    .main-nav-side .nav-item-children {
        display: none;
        width: 100%;
        padding: .625em 0;
        border-radius: 0;
        position: relative!important;
        top: auto!important;
        left: auto!important;
        right: auto!important;
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-size: 16px;
        line-height: 1.5em;
        overflow-x: hidden;
        overflow-y: auto
    }

    .main-nav-side .nav-item-children>li {
        display: block;
        width: 100%;
        font-size: 1em;
        font-weight: 400
    }

    .main-nav-side .nav-item-children>li>a {
        padding: .75em 1.25em
    }

    .main-nav-side .nav-item-children>li:hover>a {
        background-color: transparent
    }

    .main-nav-side .nav-item-children .nav-item-children {
        padding-left: 1.25em
    }

    .main-nav-side .megamenu .nav-item-children {
        display: none;
        padding-left: 1.25em;
        width: auto!important;
        left: auto!important;
        right: auto!important;
        margin-left: 0!important;
        margin-right: 0!important;
        visibility: visible
    }

    .main-nav-side .megamenu .ld-container,.main-nav-side .megamenu .megamenu-column,.main-nav-side .megamenu .megamenu-container {
        width: 100%!important;
        padding: 0
    }

    .main-nav-side .megamenu section.vc_row {
        padding: 0!important
    }

    .main-nav-side .megamenu .megamenu-column,.main-nav-side .megamenu .vc_column-inner,.main-nav-side .megamenu .vc_row,.main-nav-side .megamenu .wpb_wrapper {
        background: none!important;
        border: none!important;
        padding: 0!important;
        margin: 0!important;
        -webkit-box-shadow: none!important;
        box-shadow: none!important
    }

    .main-nav-side .megamenu .ld-row {
        display: block;
        margin: 0
    }

    .main-nav-side .megamenu .megamenu-inner-row:before {
        content: none!important
    }

    .main-nav-side-style-1>li,.main-nav-side-style-1>li:first-child,.main-nav-side-style-1>li:last-child {
        padding-left: 50px;
        padding-right: 50px
    }

    .main-nav-side-style-2>li {
        padding-left: 0!important;
        padding-right: 0!important
    }

    .main-nav-fullscreen-style-1 {
        width: 50%;
        margin: 5vh auto;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: center
    }

    .main-nav-fullscreen-style-1>li {
        padding-left: 0!important;
        padding-right: 0!important;
        margin-bottom: 1em;
        overflow: hidden
    }

    .main-nav-fullscreen-style-1>li>a {
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .main-nav-fullscreen-style-1 .nav-item-children {
        width: calc(100% + 24px);
        padding: .625em 0;
        position: relative;
        top: auto;
        left: auto;
        font-size: 16px;
        line-height: 1.5em;
        overflow-x: hidden;
        overflow-y: auto
    }

    .main-nav-fullscreen-style-1 .nav-item-children>li {
        display: block;
        width: 100%;
        padding: .75em 1.25em;
        font-size: 1em;
        font-weight: 400
    }

    .main-header[data-react-to-megamenu=true] .mainbar-wrap .megamenu-hover-bg {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        -webkit-transition: opacity .3s cubic-bezier(0.02,.01,.47,1);
        transition: opacity .3s cubic-bezier(0.02,.01,.47,1)
    }

    .main-header[data-react-to-megamenu=true].megamenu-item-active .megamenu-hover-bg {
        opacity: 1
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .social-icon a {
        color: rgba(0,0,0,.7)!important
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .social-icon a:hover {
        color: #000!important
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger,.main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .main-nav>li>a,.main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .phone-number span,.main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .phone-number span i {
        color: rgba(0,0,0,.7)
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger:hover,.main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .main-nav>li>a:hover {
        color: #000
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .ld-module-search-visible-form .ld-search-form input {
        border-color: rgba(0,0,0,.2);
        color: #000
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-dark {
        opacity: 1!important;
        visibility: visible!important
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-light {
        opacity: 0;
        visibility: hidden
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .social-icon a {
        color: rgba(255,255,255,.7)!important
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .social-icon a:hover {
        color: #fff!important
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger,.main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .main-nav>li>a {
        color: rgba(255,255,255,.7)
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger:hover,.main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .main-nav>li>a:hover {
        color: #fff
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .ld-module-search-visible-form .ld-search-form input {
        border-color: rgba(255,255,255,.2);
        color: #fff
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .ld-module-search-visible-form .input-icon {
        color: rgba(255,255,255,.7)
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-light {
        opacity: 1!important;
        visibility: visible!important
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-dark {
        opacity: 0;
        visibility: hidden
    }

    .mainbar-row>[class^=col] {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap
    }

    .mainbar-row>[class^=col].text-right {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .mainbar-row>[class^=col].text-center {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .mainbar-row>[class^=col].text-left {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .secondarybar-row>[class^=col].text-right {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .secondarybar-row>[class^=col].text-center {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .secondarybar-row>[class^=col].text-left {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .is-stuck .ld-module-search-visible-form .ld-search-form input {
        color: #fff;
        border-color: rgba(255,255,255,.2)
    }

    .is-stuck .header-module .ld-module-trigger,.is-stuck .main-nav>li>a,.is-stuck .social-icon a {
        color: rgba(255,255,255,.8)!important
    }

    .is-stuck .ld-module-search-visible-form .input-icon,.is-stuck .main-nav>li>a:hover,.is-stuck .social-icon a:hover {
        color: #fff!important
    }

    .mainbar-wrap:not(.is-stuck) .visible-when-stuck {
        display: none
    }
}

@media screen and (max-width: 991px) {
    .page-blog-post h1.pt-100 {
        display:none
    }

    .page-blog-post header h1.blog-single-title {
        margin-top: 70px
    }

    [data-overlay-onmobile=true] .main-header-overlay {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0
    }

    [data-mobile-nav-trigger-alignment=left] .navbar-header .navbar-brand {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    [data-mobile-nav-trigger-alignment=left] .navbar-header .navbar-brand-inner {
        margin-left: -20px!important
    }

    [data-mobile-nav-trigger-alignment=left] .navbar-header .lqd-mobile-modules-container {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    [data-mobile-nav-trigger-alignment=left] .navbar-header .lqd-mobile-modules-container+.navbar-brand {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    [data-mobile-nav-trigger-alignment=left] .navbar-header .lqd-mobile-modules-container+.navbar-brand,[data-mobile-nav-trigger-alignment=left] .navbar-header .lqd-mobile-modules-container+.navbar-brand .navbar-brand-inner {
        margin-left: 0!important;
        margin-right: 0!important
    }

    [data-mobile-nav-trigger-alignment=left] .navbar-header .navbar-toggle {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-left: 0!important
    }

    [data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-brand {
        margin-right: 0;
        margin-left: 0!important
    }

    [data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-brand-inner {
        margin-right: -20px!important
    }

    [data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-toggle {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    [data-mobile-nav-trigger-alignment=right] .navbar-header .lqd-mobile-modules-container+.navbar-brand {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    [data-mobile-nav-trigger-alignment=right] .navbar-header .lqd-mobile-modules-container+.navbar-brand,[data-mobile-nav-trigger-alignment=right] .navbar-header .lqd-mobile-modules-container+.navbar-brand .navbar-brand-inner {
        margin-left: 0!important;
        margin-right: 0!important
    }

    [data-mobile-logo-alignment=center] .navbar-header .navbar-brand {
        margin-left: auto!important;
        margin-right: auto!important;
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    [data-mobile-logo-alignment=center] .navbar-header .navbar-brand-inner {
        margin-left: 0;
        margin-right: 0
    }

    [data-mobile-logo-alignment=center] .navbar-header .navbar-toggle {
        -webkit-box-flex: 0;
        -ms-flex: 0 1;
        flex: 0 1
    }

    [data-mobile-logo-alignment=center] .navbar-header .lqd-mobile-modules-container {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    [data-mobile-logo-alignment=center] .navbar-header .lqd-mobile-modules-container~.navbar-brand,[data-mobile-logo-alignment=center] .navbar-header .lqd-mobile-modules-container~.navbar-brand .navbar-brand-inner {
        margin-left: 0!important;
        margin-right: 0!important
    }

    [data-mobile-logo-alignment=center] .navbar-header .lqd-mobile-modules-container~.navbar-toggle {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%
    }

    [data-mobile-logo-alignment=center][data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-brand {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    [data-mobile-logo-alignment=center][data-mobile-nav-trigger-alignment=right] .navbar-header .lqd-mobile-modules-container {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    html[dir=rtl] [data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-toggle {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    html[dir=rtl] [data-mobile-nav-trigger-alignment=left] .navbar-header .navbar-toggle,html[dir=rtl] [data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-brand {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    html[dir=rtl] [data-mobile-nav-trigger-alignment=left] .navbar-header .navbar-brand {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .main-header .header-module {
        display: none
    }

    .navbar-collapse .header-module {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: 0!important;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding-left: 15px;
        padding-right: 15px
    }

    .navbar-collapse .header-module:first-of-type {
        margin-top: 20px
    }

    .navbar-header .header-module {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        position: static;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .navbar-header .header-module+.header-module {
        margin-left: 18px
    }

    .navbar-header .navbar-brand+.header-module {
        margin-left: auto
    }

    .lqd-mobile-modules-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    [data-mobile-nav-align=left] .navbar-collapse .header-module {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding-left: 15px;
        padding-right: 15px
    }

    .nav-trigger {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .navbar-header .nav-trigger {
        margin: 0;
        -webkit-box-pack: end;
        -ms-flex-pack: start;
        justify-content: flex-end
    }

    .ld-module-dropdown {
        top: 100%
    }

    .ld-module-cart .ld-module-trigger-icon {
        display: inline-block;
        position: relative
    }

    .ld-module-cart .ld-module-trigger-icon:after,.ld-module-cart .ld-module-trigger-icon:before {
        content: '';
        display: inline-block;
        width: 1.5px;
        height: 21px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -10px;
        background-color: currentColor;
        -webkit-transform-origin: bottom center;
        transform-origin: bottom center;
        -webkit-transition: opacity .3s .05s,-webkit-transform .3s;
        transition: opacity .3s .05s,-webkit-transform .3s;
        transition: transform .3s,opacity .3s .05s;
        transition: transform .3s,opacity .3s .05s,-webkit-transform .3s
    }

    .ld-module-cart .ld-module-trigger-icon:before {
        -webkit-transform: rotate(45deg) translate(-4px,-2.5px);
        transform: rotate(45deg) translate(-4px,-2.5px)
    }

    .ld-module-cart .ld-module-trigger-icon:after {
        -webkit-transform: rotate(-45deg) translate(2px,-4px);
        transform: rotate(-45deg) translate(2px,-4px);
        left: auto;
        right: 0
    }

    .ld-module-cart .ld-module-trigger-icon i {
        display: inline-block;
        font-family: liquid-icon!important;
        opacity: 0;
        -webkit-transform: scale(0.85);
        transform: scale(0.85);
        -webkit-transition: opacity .3s,-webkit-transform .3s;
        transition: opacity .3s,-webkit-transform .3s;
        transition: transform .3s,opacity .3s;
        transition: transform .3s,opacity .3s,-webkit-transform .3s
    }

    .ld-module-cart .ld-module-trigger-icon i:before {
        content: "\e929"
    }

    .ld-module-cart .ld-module-trigger-count {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
        opacity: 0;
        -webkit-transition: opacity .3s,-webkit-transform .3s;
        transition: opacity .3s,-webkit-transform .3s;
        transition: transform .3s,opacity .3s;
        transition: transform .3s,opacity .3s,-webkit-transform .3s
    }

    .ld-module-cart .ld-module-trigger.collapsed .ld-module-trigger-icon {
        display: inline-block;
        position: relative
    }

    .ld-module-cart .ld-module-trigger.collapsed .ld-module-trigger-icon:after,.ld-module-cart .ld-module-trigger.collapsed .ld-module-trigger-icon:before {
        opacity: 0;
        -webkit-transform: rotate(0) scaleY(0.75);
        transform: rotate(0) scaleY(0.75)
    }

    .ld-module-cart .ld-module-trigger.collapsed .ld-module-trigger-icon i {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    .ld-module-cart .ld-module-trigger.collapsed .ld-module-trigger-count {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }

    a.remove.ld-cart-product-remove {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        position: relative;
        top: auto;
        left: auto;
        opacity: 1;
        visibility: visible
    }

    .ld-module-search .ld-module-dropdown {
        top: 0;
        right: 0
    }

    .ld-module-search .ld-module-dropdown.in,.ld-module-search .ld-module-dropdown[aria-expanded=true].collapsing {
        height: 100%!important
    }

    .ld-search-form-container {
        height: 100%;
        width: 100vw;
        padding: 0;
        border: none
    }

    .ld-search-form {
        height: 100%
    }

    .ld-search-form input {
        height: 100%;
        border: none;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 2px solid #eaeaea;
        border-radius: 0;
        color: inherit;
        background: 0 0
    }

    .ld-search-form .input-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        width: 50px;
        height: 50px;
        right: 0;
        left: auto;
        color: #000;
        font-size: 36px;
        cursor: pointer;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 3px
    }

    .ld-search-form .input-icon:hover {
        background-color: rgba(0,0,0,.1)
    }

    .ld-search-form .input-icon i:before {
        content: '\e94a'
    }

    .lqd-mobile-modules-container .social-icon {
        font-size: 16px
    }

    .lqd-mobile-modules-container .social-icon li {
        margin-right: .75em
    }

    .main-header .navbar-brand {
        padding: 22px 0;
        max-width: none!important
    }

    .main-header .mobile-logo-default~.logo-default {
        display: none
    }

    .main-header .main-nav {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5em;
        text-transform: none;
        letter-spacing: 0;
        text-align: left
    }

    .main-header .main-nav>li>a {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        letter-spacing: inherit
    }

    .navbar-header {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding-left: 25px;
        padding-right: 25px
    }

    .navbar-collapse {
        overflow-x: hidden;
        overflow-y: auto;
        color: #000
    }

    .navbar-collapse .social-icon li a {
        color: inherit;
        opacity: .7
    }

    .navbar-collapse .social-icon li a:hover {
        opacity: 1
    }

    .navbar-collapse .btn-naked,.navbar-collapse .btn-underlined {
        color: inherit;
        border-color: currentColor
    }

    .navbar-collapse .btn-naked:after,.navbar-collapse .btn-naked:before,.navbar-collapse .btn-underlined:after,.navbar-collapse .btn-underlined:before {
        background-color: currentColor
    }

    .navbar-collapse .btn-naked:before,.navbar-collapse .btn-underlined:before {
        opacity: .5
    }

    .navbar-collapse .btn-naked .btn-txt,.navbar-collapse .btn-underlined .btn-txt {
        opacity: .7;
        -webkit-transition: opacity .3s;
        transition: opacity .3s
    }

    .navbar-collapse .btn-naked:hover,.navbar-collapse .btn-underlined:hover {
        color: inherit
    }

    .navbar-collapse .btn-naked:hover .btn-txt,.navbar-collapse .btn-underlined:hover .btn-txt {
        opacity: 1
    }

    ul.nav.main-nav>li {
        padding-left: 0;
        padding-right: 0
    }

    ul.nav.main-nav>li>a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 15px 25px;
        border-bottom: 1px solid rgba(0,0,0,.05);
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #000
    }

    ul.nav.main-nav>li>a:hover {
        color: #000
    }

    ul.nav.main-nav+.header-module {
        margin-top: 15px
    }

    .mainbar-row>.navbar-header {
        margin-left: 15px;
        margin-right: 15px
    }

    [data-mobile-nav-align=center] .navbar-collapse {
        text-align: center
    }

    [data-mobile-nav-align=center] .navbar-collapse .header-module {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    [data-mobile-nav-align=center] ul.nav.main-nav>li>a {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    [data-mobile-nav-align=right] .navbar-collapse .header-module {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    [data-mobile-nav-style=classic] .navbar-collapse,[data-mobile-nav-style=minimal] .navbar-collapse {
        max-height: 90vh;
        -webkit-box-shadow: 0 10px 50px rgba(0,0,0,.05);
        box-shadow: 0 10px 50px rgba(0,0,0,.05);
        background-color: #fff
    }

    [data-mobile-nav-style=minimal] ul.nav.main-nav,[data-mobile-nav-style=modern] ul.nav.main-nav {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 16px
    }

    [data-mobile-nav-style=minimal] ul.nav.main-nav>li>a,[data-mobile-nav-style=modern] ul.nav.main-nav>li>a {
        border: none
    }

    [data-mobile-nav-style=modern]:before {
        content: '';
        display: inline-block;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        -webkit-transform: scale(1.75);
        transform: scale(1.75);
        background-image: -webkit-gradient(linear,right bottom,left top,from(#1DE1BC),to(#DA0BEE));
        background-image: linear-gradient(to top left,#1DE1BC 0,#DA0BEE 100%);
        -webkit-transition: opacity .3s,-webkit-transform .3s;
        transition: opacity .3s,-webkit-transform .3s;
        transition: opacity .3s,transform .3s;
        transition: opacity .3s,transform .3s,-webkit-transform .3s;
        -webkit-transition-delay: .05s;
        transition-delay: .05s
    }

    [data-mobile-nav-style=modern] #wrap {
        -webkit-transition: height .3s,-webkit-transform .55s cubic-bezier(0.23,1,.32,1);
        transition: height .3s,-webkit-transform .55s cubic-bezier(0.23,1,.32,1);
        transition: transform .55s cubic-bezier(0.23,1,.32,1),height .3s;
        transition: transform .55s cubic-bezier(0.23,1,.32,1),height .3s,-webkit-transform .55s cubic-bezier(0.23,1,.32,1)
    }

    [data-mobile-nav-style=modern] .navbar-toggle {
        pointer-events: none
    }

    [data-mobile-nav-style=modern] .navbar-toggle.mobile-nav-trigger-cloned {
        pointer-events: all
    }

    [data-mobile-nav-style=modern] .navbar-collapse-clone {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        width: 70vw;
        height: 80vh!important;
        padding-top: 20px;
        border: none;
        position: fixed;
        top: 12vh;
        right: 0;
        z-index: 90;
        background: none!important;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-transform: translate3d(25vw,0,0);
        transform: translate3d(25vw,0,0);
        opacity: 0;
        visibility: hidden;
        overflow: visible!important;
        -webkit-transition-property: opacity,visibility,-webkit-transform;
        transition-property: opacity,visibility,-webkit-transform;
        transition-property: transform,opacity,visibility;
        transition-property: transform,opacity,visibility,-webkit-transform;
        -webkit-transition-duration: .45s;
        transition-duration: .45s;
        -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
        transition-timing-function: cubic-bezier(0.23,1,.32,1)
    }

    [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger {
        position: absolute;
        top: -20px;
        right: 0;
        z-index: 10;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        color: #fff;
        pointer-events: all;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-transition: none!important;
        transition: none!important
    }

    [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger .bars {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 8px;
        width: 42px;
        height: 42px;
        border: 2.4px solid rgba(255,255,255,.4);
        border-radius: 50em;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-transition: none!important;
        transition: none!important
    }

    [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger .bar {
        background-color: #fff
    }

    [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger .bar:first-child,[data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger .bar:last-child {
        display: none
    }

    [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger .bar:nth-child(2) {
        -webkit-transform: translateY(2px) rotate(135deg)!important;
        transform: translateY(2px) rotate(135deg)!important;
        -webkit-transition: none!important;
        transition: none!important
    }

    [data-mobile-nav-style=modern] .navbar-collapse-clone ul,[data-mobile-nav-style=modern] .navbar-collapse-clone ul.nav.main-nav {
        -webkit-box-flex: 0;
        -ms-flex: 0 auto;
        flex: 0 auto
    }

    [data-mobile-nav-style=modern] .navbar-collapse-clone ul .nav-item-children>li>a,[data-mobile-nav-style=modern] .navbar-collapse-clone ul .nav-item-children>li>a:hover,[data-mobile-nav-style=modern] .navbar-collapse-clone ul.nav.main-nav .nav-item-children>li>a,[data-mobile-nav-style=modern] .navbar-collapse-clone ul.nav.main-nav .nav-item-children>li>a:hover,[data-mobile-nav-style=modern] .navbar-collapse-clone ul.nav.main-nav>li>a,[data-mobile-nav-style=modern] .navbar-collapse-clone ul.nav.main-nav>li>a:hover,[data-mobile-nav-style=modern] .navbar-collapse-clone ul>li>a,[data-mobile-nav-style=modern] .navbar-collapse-clone ul>li>a:hover {
        color: #fff
    }

    [data-mobile-nav-style=modern] .navbar-collapse-inner {
        display: block;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto
    }

    [data-mobile-nav-style=modern] .megamenu .megamenu-container {
        padding: 0 35px
    }

    [data-mobile-nav-style=modern] .megamenu .ld-container,[data-mobile-nav-style=modern] .megamenu .ld-row,[data-mobile-nav-style=modern] .megamenu .megamenu-column,[data-mobile-nav-style=modern] .megamenu .vc_column-inner,[data-mobile-nav-style=modern] .megamenu .vc_row {
        width: 100%;
        border: none!important;
        background: none!important;
        padding: 0!important;
        margin: 0!important
    }

    [data-mobile-nav-style=modern] .megamenu .ld-fancy-heading>* {
        color: #fff
    }

    .mobile-nav-activated [data-mobile-nav-style=modern]:before {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        -webkit-transition-delay: 0;
        transition-delay: 0
    }

    .mobile-nav-activated [data-mobile-nav-style=modern] #wrap {
        overflow: hidden;
        background-color: #fff;
        -webkit-transform: translate3d(-80vw,0,0);
        transform: translate3d(-80vw,0,0)
    }

    .mobile-nav-activated [data-mobile-nav-style=modern] .navbar-collapse-clone {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
        opacity: 1;
        visibility: visible!important;
        -webkit-transition-delay: .1s;
        transition-delay: .1s
    }

    .module-collapsing,.module-expanding {
        overflow: hidden
    }

    .module-collapsing [data-mobile-nav-style=modern] #wrap,.module-expanding [data-mobile-nav-style=modern] #wrap {
        -webkit-transition-property: height,-webkit-transform;
        transition-property: height,-webkit-transform;
        transition-property: transform,height;
        transition-property: transform,height,-webkit-transform;
        -webkit-transition-duration: .45s;
        transition-duration: .45s;
        -webkit-transition-timing-function: cubic-bezier(0.23,1,.32,1);
        transition-timing-function: cubic-bezier(0.23,1,.32,1);
        background-color: #fff;
        overflow: hidden
    }

    .module-collapsing [data-mobile-nav-style=modern] #wrap {
        overflow: hidden
    }

    [data-mobile-nav-scheme=gray] .navbar-collapse {
        background-color: #f9f9f9;
        color: #000
    }

    [data-mobile-nav-scheme=gray] .main-nav .lqd-custom-menu>li:hover,[data-mobile-nav-scheme=gray] .main-nav .lqd-custom-menu>li>a,[data-mobile-nav-scheme=gray] ul.nav.main-nav>li:hover,[data-mobile-nav-scheme=gray] ul.nav.main-nav>li>a {
        color: #000
    }

    [data-mobile-nav-scheme=dark] .navbar-collapse {
        background-color: #191D18;
        color: #fff
    }

    [data-mobile-nav-scheme=dark] .main-nav .lqd-custom-menu>li>a,[data-mobile-nav-scheme=dark] ul.nav.main-nav>li>a {
        border-color: rgba(255,255,255,.1);
        color: #fff
    }

    [data-mobile-nav-scheme=dark] .main-nav .lqd-custom-menu>li>a:hover,[data-mobile-nav-scheme=dark] ul.nav.main-nav>li>a:hover {
        color: #fff
    }

    [data-mobile-nav-scheme=dark] .submenu-expander {
        background-color: rgba(255,255,255,.05)
    }

    [data-mobile-header-scheme=light] .navbar-header {
        background-color: #fff
    }

    [data-mobile-header-scheme=light] .lqd-mobile-modules-container .social-icon a,[data-mobile-header-scheme=light] .lqd-mobile-modules-container .social-icon a:hover {
        color: #000
    }

    [data-mobile-header-scheme=light] .lqd-mobile-modules-container .ld-module-trigger {
        color: #000!important
    }

    [data-mobile-nav-scheme=light] .navbar-collapse {
        background-color: #fff;
        color: #000
    }

    [data-mobile-header-scheme=gray] .main-header .navbar-header {
        background-color: #f6f6f6
    }

    [data-mobile-header-scheme=gray] .main-header .lqd-mobile-modules-container .social-icon a,[data-mobile-header-scheme=gray] .main-header .lqd-mobile-modules-container .social-icon a:hover {
        color: #000
    }

    [data-mobile-header-scheme=gray] .main-header .ld-module-trigger {
        color: #000!important
    }

    [data-mobile-header-scheme=dark] .navbar-header {
        background-color: #191D18
    }

    [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .social-icon a,[data-mobile-header-scheme=dark] .lqd-mobile-modules-container .social-icon a:hover {
        color: #000
    }

    [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .ld-module-trigger {
        color: #fff!important
    }

    [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .ld-search-form-container {
        background-color: #191D18
    }

    [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .ld-search-form input {
        border-color: rgba(255,255,255,.45);
        color: #fff
    }

    [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .ld-search-form .input-icon {
        color: #fff
    }

    [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .ld-search-form .input-icon:hover {
        background-color: rgba(255,255,255,.1)
    }

    [data-mobile-header-scheme=dark] .nav-trigger .bar {
        background-color: #fff
    }

    html[dir=rtl] [data-mobile-nav-style=modern] .navbar-collapse-clone {
        right: 10vw
    }

    .main-nav .children,.nav-item-children {
        display: none;
        min-width: 0;
        padding: 15px 0;
        border-radius: 0;
        position: static;
        top: auto;
        left: auto;
        right: auto;
        visibility: visible;
        text-align: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        letter-spacing: inherit
    }

    .main-nav .children>li>a,.nav-item-children>li>a {
        padding: 8px 35px;
        color: inherit
    }

    .main-nav .children>li:hover>a,.nav-item-children>li:hover>a {
        background: 0 0
    }

    .main-nav .children .nav-item-children,.nav-item-children .nav-item-children {
        padding: 8px 0 8px 15px
    }

    [data-mobile-nav-scheme=dark] .nav-item-children {
        background-color: #1b201a
    }

    [data-mobile-nav-scheme=dark] .nav-item-children>li>a {
        opacity: .75
    }

    [data-mobile-nav-scheme=dark] .nav-item-children>li.active>a,[data-mobile-nav-scheme=dark] .nav-item-children>li.current-menu-item>a,[data-mobile-nav-scheme=dark] .nav-item-children>li:hover>a {
        color: inherit;
        opacity: 1
    }

    [data-mobile-nav-align=center] .nav-item-children {
        text-align: center
    }

    [data-mobile-nav-align=center] .nav-item-children .nav-item-children {
        padding-left: 15px;
        padding-right: 15px
    }

    [data-mobile-nav-style=minimal] .nav-item-children {
        font-size: 14px
    }

    [data-mobile-nav-style=modern] .main-nav .children,[data-mobile-nav-style=modern] .nav-item-children {
        background: 0 0
    }

    .megamenu .nav-item-children {
        left: auto!important
    }

    .megamenu .megamenu-container {
        padding-top: 15px;
        padding-bottom: 15px
    }

    .megamenu .megamenu-column {
        padding-left: 15px;
        padding-right: 15px
    }

    .megamenu .ld-container,.megamenu .vc_column-inner {
        padding-left: 15px!important;
        padding-right: 15px!important
    }

    .megamenu .ld-row {
        display: block;
        margin-left: -15px!important;
        margin-right: -15px!important
    }

    .megamenu .vc_row,.megamenu .wpb_wrapper {
        background: none!important;
        padding: 0!important;
        margin: 0!important
    }

    .megamenu .vc_row {
        border: none!important
    }

    .megamenu .wpb_single_image.invisible {
        visibility: visible
    }

    .megamenu-container {
        width: auto!important
    }

    .main-header .mainbar-wrap {
        padding: 0!important;
        margin: 0!important
    }

    .main-header .mainbar-wrap .mainbar-container {
        width: 100%;
        max-width: none;
        padding-left: 0!important;
        padding-right: 0!important
    }

    .main-header .mainbar-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0!important;
        padding-right: 0!important;
        margin-left: -15px!important;
        margin-right: -15px!important
    }

    .main-header .mainbar-row>[class^=col] {
        -webkit-box-flex: 1;
        -ms-flex: 1 auto;
        flex: 1 auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 15px!important;
        padding-right: 15px!important;
        margin-left: 0!important;
        margin-right: 0!important;
        min-height: 0
    }

    .main-header .mainbar-row>[class^=col]>.main-nav,.secondarybar-wrap {
        display: none
    }

    [data-mobile-secondary-bar=true] .secondarybar-wrap {
        display: block
    }

    [data-mobile-secondary-bar=true] .secondarybar-wrap .header-module {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.text-transform-none {
    text-transform: none!important
}

.text-underline {
    text-decoration: underline
}

.ltr-sp-0 {
    letter-spacing: 0!important
}

.ltr-sp--015 {
    letter-spacing: -.015em!important
}

.ltr-sp--025 {
    letter-spacing: -.025em!important
}

.ltr-sp--05 {
    letter-spacing: -.05em!important
}

.ltr-sp--075 {
    letter-spacing: -.075em!important
}

.ltr-sp--1 {
    letter-spacing: -.1em!important
}

.ltr-sp--135 {
    letter-spacing: -.135em!important
}

.ltr-sp--15 {
    letter-spacing: -.15em!important
}

.ltr-sp--175 {
    letter-spacing: -.175em!important
}

.ltr-sp--2 {
    letter-spacing: -.2em!important
}

.ltr-sp-015 {
    letter-spacing: .015em!important
}

.ltr-sp-025 {
    letter-spacing: .025em!important
}

.ltr-sp-05 {
    letter-spacing: .05em!important
}

.ltr-sp-075 {
    letter-spacing: .075em!important
}

.ltr-sp-1 {
    letter-spacing: .1em!important
}

.ltr-sp-135 {
    letter-spacing: .135em!important
}

.ltr-sp-15 {
    letter-spacing: .1em!important
}

.ltr-sp-175 {
    letter-spacing: .175em!important
}

.ltr-sp-2 {
    letter-spacing: .2em!important
}

.ltr-sp-25 {
    letter-spacing: .25em!important
}

.ltr-sp-3 {
    letter-spacing: .3em!important
}

.ltr-sp-35 {
    letter-spacing: .35em!important
}

.ltr-sp-4 {
    letter-spacing: .4em!important
}

.ltr-sp-5 {
    letter-spacing: .5em!important
}

.lh-05 {
    line-height: .5em!important
}

.lh-55 {
    line-height: .55em!important
}

.lh-75 {
    line-height: .75em!important
}

.lh-85 {
    line-height: .85em!important
}

.lh-1 {
    line-height: 1em!important
}

.lh-105 {
    line-height: 1.05em!important
}

.lh-11 {
    line-height: 1.1em!important
}

.lh-115 {
    line-height: 1.15em!important
}

.lh-125 {
    line-height: 1.25em!important
}

.lh-13 {
    line-height: 1.3em!important
}

.lh-15 {
    line-height: 1.5em!important
}

.lh-16 {
    line-height: 1.6em!important
}

.lh-165 {
    line-height: 1.65em!important
}

.lh-175 {
    line-height: 1.75em!important
}

.lh-185 {
    line-height: 1.85em!important
}

.lh-2 {
    line-height: 2em!important
}

.lh-215 {
    line-height: 2.15em!important
}

.lh-225 {
    line-height: 2.25em!important
}

.lh-25 {
    line-height: 2.5em!important
}

.font-weight-thin {
    font-weight: 100!important
}

.font-weight-light {
    font-weight: 300!important
}

.font-weight-normal {
    font-weight: 400!important
}

.font-weight-medium {
    font-weight: 500!important
}

.font-weight-semibold {
    font-weight: 600!important
}

.font-weight-bold {
    font-weight: 700!important
}

.font-weight-extrabold {
    font-weight: 900!important
}

.font-style-normal {
    font-style: normal!important
}

.font-style-italic {
    font-style: italic!important
}

.ws-nowrap {
    white-space: nowrap
}

.font-size-10 {
    font-size: 10px!important
}

.font-size-11 {
    font-size: 11px!important
}

.font-size-12 {
    font-size: 12px!important
}

.font-size-13 {
    font-size: 13px!important
}

.font-size-14 {
    font-size: 14px!important
}

.font-size-15 {
    font-size: 15px!important
}

.font-size-16 {
    font-size: 16px!important
}

.font-size-17 {
    font-size: 17px!important
}

.font-size-18 {
    font-size: 18px!important
}

.font-size-19 {
    font-size: 19px!important
}

.font-size-20 {
    font-size: 20px!important
}

.font-size-21 {
    font-size: 21px!important
}

.font-size-22 {
    font-size: 22px!important
}

.font-size-24 {
    font-size: 24px!important
}

.font-size-26 {
    font-size: 26px!important
}

.font-size-28 {
    font-size: 28px!important
}

.font-size-32 {
    font-size: 32px!important
}

.font-size-34 {
    font-size: 34px!important
}

.font-size-35 {
    font-size: 35px!important
}

.font-size-36 {
    font-size: 36px!important
}

.font-size-38 {
    font-size: 38px!important
}

.font-size-40 {
    font-size: 40px!important
}

.font-size-42 {
    font-size: 42px!important
}

.font-size-44 {
    font-size: 44px!important
}

.font-size-46 {
    font-size: 46px!important
}

.font-size-48 {
    font-size: 48px!important
}

.font-size-50 {
    font-size: 50px!important
}

.font-size-1-15x {
    font-size: 1.15em
}

.font-size-1-25x {
    font-size: 1.25em
}

.font-size-1-5x {
    font-size: 1.5em
}

.font-size-1-6x {
    font-size: 1.6em
}

.font-size-1-75x {
    font-size: 1.75em
}

.font-size-2x {
    font-size: 2em
}

.font-size-2-15x {
    font-size: 2.15em
}

.font-size-2-25x {
    font-size: 2.25em
}

.font-size-2-5x {
    font-size: 2.5em
}

.font-size-2-6x {
    font-size: 2.6em
}

.font-size-2-75x {
    font-size: 2.75em
}

.font-size-3x {
    font-size: 3em
}

.font-size-3-15x {
    font-size: 3.15em
}

.font-size-3-3x {
    font-size: 3.3em
}

.font-weight-100 {
    font-weight: 100
}

.font-weight-200 {
    font-weight: 200
}

.font-weight-300 {
    font-weight: 300
}

.font-weight-400 {
    font-weight: 400
}

.font-weight-500 {
    font-weight: 500
}

.font-weight-600 {
    font-weight: 600
}

.font-weight-700 {
    font-weight: 700
}

.font-weight-800 {
    font-weight: 800
}

.text-primary {
    color: var(--color-primary)!important
}

.text-secondary {
    color: var(--color-secondary)!important
}

.text-tertiary {
    color: var(--color-tertiary)!important
}

.text-white {
    color: #fff!important
}

.text-black {
    color: #000!important
}

.text-dark {
    color: #181b31!important
}

.text-havelock-blue {
    color: #4c93dc!important
}

.text-turquoise {
    color: #3cd3d1!important
}

.text-neon-carrot {
    color: #ff8e32!important
}

.text-arapawa {
    color: #120a57!important
}

.text-san-marino {
    color: #4A62AC!important
}

.text-hover-primary:hover {
    color: var(--color-primary)!important
}

.text-hover-secondary:hover {
    color: var(--color-secondary)!important
}

.text-hover-white:hover {
    color: #fff!important
}

.text-hover-black:hover {
    color: #000!important
}

.text-hover-dark:hover {
    color: #181b31!important
}

.text-fade-white-01 {
    color: rgba(255,255,255,.1)!important
}

.text-fade-dark-01 {
    color: rgba(0,0,0,.1)!important
}

.text-fade-white-02 {
    color: rgba(255,255,255,.2)!important
}

.text-fade-dark-02 {
    color: rgba(0,0,0,.2)!important
}

.text-fade-white-03 {
    color: rgba(255,255,255,.3)!important
}

.text-fade-dark-03 {
    color: rgba(0,0,0,.3)!important
}

.text-fade-white-04 {
    color: rgba(255,255,255,.4)!important
}

.text-fade-dark-04 {
    color: rgba(0,0,0,.4)!important
}

.text-fade-white-05 {
    color: rgba(255,255,255,.5)!important
}

.text-fade-dark-05 {
    color: rgba(0,0,0,.5)!important
}

.text-fade-white-06 {
    color: rgba(255,255,255,.6)!important
}

.text-fade-dark-06 {
    color: rgba(0,0,0,.6)!important
}

.text-fade-white-07 {
    color: rgba(255,255,255,.7)!important
}

.text-fade-dark-07 {
    color: rgba(0,0,0,.7)!important
}

.text-fade-white-08 {
    color: rgba(255,255,255,.8)!important
}

.text-fade-dark-08 {
    color: rgba(0,0,0,.8)!important
}

.text-fade-white-09 {
    color: rgba(255,255,255,.9)!important
}

.text-fade-dark-09 {
    color: rgba(0,0,0,.9)!important
}

.ml-auto {
    margin-left: auto!important
}

.mr-auto {
    margin-right: auto!important
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.pt-10 {
    padding-top: 10px!important
}

.pb-10 {
    padding-bottom: 10px!important
}

.mt-10 {
    margin-top: 10px!important
}

.mb-10 {
    margin-bottom: 10px!important
}

.pt-15 {
    padding-top: 15px!important
}

.pb-15 {
    padding-bottom: 15px!important
}

.mt-15 {
    margin-top: 15px!important
}

.mb-15 {
    margin-bottom: 15px!important
}

.pt-20 {
    padding-top: 20px!important
}

.pb-20 {
    padding-bottom: 20px!important
}

.mt-20 {
    margin-top: 20px!important
}

.mb-20 {
    margin-bottom: 20px!important
}

.pt-25 {
    padding-top: 25px!important
}

.pb-25 {
    padding-bottom: 25px!important
}

.mt-25 {
    margin-top: 25px!important
}

.mb-25 {
    margin-bottom: 25px!important
}

.pt-30 {
    padding-top: 30px!important
}

.pb-30 {
    padding-bottom: 30px!important
}

.mt-30 {
    margin-top: 30px!important
}

.mb-30 {
    margin-bottom: 30px!important
}

.pt-35 {
    padding-top: 35px!important
}

.pb-35 {
    padding-bottom: 35px!important
}

.mt-35 {
    margin-top: 35px!important
}

.mb-35 {
    margin-bottom: 35px!important
}

.pt-40 {
    padding-top: 40px!important
}

.pb-40 {
    padding-bottom: 40px!important
}

.mt-40 {
    margin-top: 40px!important
}

.mb-40 {
    margin-bottom: 40px!important
}

.pt-45 {
    padding-top: 45px!important
}

.pb-45 {
    padding-bottom: 45px!important
}

.mt-45 {
    margin-top: 45px!important
}

.mb-45 {
    margin-bottom: 45px!important
}

.pt-50 {
    padding-top: 50px!important
}

.pb-50 {
    padding-bottom: 50px!important
}

.mt-50 {
    margin-top: 50px!important
}

.mb-50 {
    margin-bottom: 50px!important
}

.pt-55 {
    padding-top: 55px!important
}

.pb-55 {
    padding-bottom: 55px!important
}

.mt-55 {
    margin-top: 55px!important
}

.mb-55 {
    margin-bottom: 55px!important
}

.pt-60 {
    padding-top: 60px!important
}

.pb-60 {
    padding-bottom: 60px!important
}

.mt-60 {
    margin-top: 60px!important
}

.mb-60 {
    margin-bottom: 60px!important
}

.pt-65 {
    padding-top: 65px!important
}

.pb-65 {
    padding-bottom: 65px!important
}

.mt-65 {
    margin-top: 65px!important
}

.mb-65 {
    margin-bottom: 65px!important
}

.pt-70 {
    padding-top: 70px!important
}

.pb-70 {
    padding-bottom: 70px!important
}

.mt-70 {
    margin-top: 70px!important
}

.mb-70 {
    margin-bottom: 70px!important
}

.pt-75 {
    padding-top: 75px!important
}

.pb-75 {
    padding-bottom: 75px!important
}

.mt-75 {
    margin-top: 75px!important
}

.mb-75 {
    margin-bottom: 75px!important
}

.pt-80 {
    padding-top: 80px!important
}

.pb-80 {
    padding-bottom: 80px!important
}

.mt-80 {
    margin-top: 80px!important
}

.mb-80 {
    margin-bottom: 80px!important
}

.pt-85 {
    padding-top: 85px!important
}

.pb-85 {
    padding-bottom: 85px!important
}

.mt-85 {
    margin-top: 85px!important
}

.mb-85 {
    margin-bottom: 85px!important
}

.pt-90 {
    padding-top: 90px!important
}

.pb-90 {
    padding-bottom: 90px!important
}

.mt-90 {
    margin-top: 90px!important
}

.mb-90 {
    margin-bottom: 90px!important
}

.pt-95 {
    padding-top: 95px!important
}

.pb-95 {
    padding-bottom: 95px!important
}

.mt-95 {
    margin-top: 95px!important
}

.mb-95 {
    margin-bottom: 95px!important
}

.pt-100 {
    padding-top: 100px!important
}

.mt-100 {
    margin-top: 100px!important
}

.mb-100 {
    margin-bottom: 100px!important
}

.pt-105 {
    padding-top: 105px!important
}

.pb-105 {
    padding-bottom: 105px!important
}

.mt-105 {
    margin-top: 105px!important
}

.mb-105 {
    margin-bottom: 105px!important
}

.pt-110 {
    padding-top: 110px!important
}

.pb-110 {
    padding-bottom: 110px!important
}

.mt-110 {
    margin-top: 110px!important
}

.mb-110 {
    margin-bottom: 110px!important
}

.pt-115 {
    padding-top: 115px!important
}

.pb-115 {
    padding-bottom: 115px!important
}

.mt-115 {
    margin-top: 115px!important
}

.mb-115 {
    margin-bottom: 115px!important
}

.pt-120 {
    padding-top: 120px!important
}

.pb-120 {
    padding-bottom: 120px!important
}

.mt-120 {
    margin-top: 120px!important
}

.mb-120 {
    margin-bottom: 120px!important
}

.pt-125 {
    padding-top: 125px!important
}

.pb-125 {
    padding-bottom: 125px!important
}

.mt-125 {
    margin-top: 125px!important
}

.mb-125 {
    margin-bottom: 125px!important
}

.pt-130 {
    padding-top: 130px!important
}

.pb-130 {
    padding-bottom: 130px!important
}

.mt-130 {
    margin-top: 130px!important
}

.mb-130 {
    margin-bottom: 130px!important
}

.pt-135 {
    padding-top: 135px!important
}

.pb-135 {
    padding-bottom: 135px!important
}

.mt-135 {
    margin-top: 135px!important
}

.mb-135 {
    margin-bottom: 135px!important
}

.pt-140 {
    padding-top: 140px!important
}

.pb-140 {
    padding-bottom: 140px!important
}

.mt-140 {
    margin-top: 140px!important
}

.mb-140 {
    margin-bottom: 140px!important
}

.pt-145 {
    padding-top: 145px!important
}

.pb-145 {
    padding-bottom: 145px!important
}

.mt-145 {
    margin-top: 145px!important
}

.mb-145 {
    margin-bottom: 145px!important
}

.pt-150 {
    padding-top: 150px!important
}

.pb-150 {
    padding-bottom: 150px!important
}

.mt-150 {
    margin-top: 150px!important
}

.mb-150 {
    margin-bottom: 150px!important
}

.pt-155 {
    padding-top: 155px!important
}

.pb-155 {
    padding-bottom: 155px!important
}

.mt-155 {
    margin-top: 155px!important
}

.mb-155 {
    margin-bottom: 155px!important
}

.pt-160 {
    padding-top: 160px!important
}

.pb-160 {
    padding-bottom: 160px!important
}

.mt-160 {
    margin-top: 160px!important
}

.mb-160 {
    margin-bottom: 160px!important
}

.pt-165 {
    padding-top: 165px!important
}

.pb-165 {
    padding-bottom: 165px!important
}

.mt-165 {
    margin-top: 165px!important
}

.mb-165 {
    margin-bottom: 165px!important
}

.pt-170 {
    padding-top: 170px!important
}

.pb-170 {
    padding-bottom: 170px!important
}

.mt-170 {
    margin-top: 170px!important
}

.mb-170 {
    margin-bottom: 170px!important
}

.pt-175 {
    padding-top: 175px!important
}

.pb-175 {
    padding-bottom: 175px!important
}

.mt-175 {
    margin-top: 175px!important
}

.mb-175 {
    margin-bottom: 175px!important
}

.pt-180 {
    padding-top: 180px!important
}

.pb-180 {
    padding-bottom: 180px!important
}

.mt-180 {
    margin-top: 180px!important
}

.mb-180 {
    margin-bottom: 180px!important
}

.pt-185 {
    padding-top: 185px!important
}

.pb-185 {
    padding-bottom: 185px!important
}

.mt-185 {
    margin-top: 185px!important
}

.mb-185 {
    margin-bottom: 185px!important
}

.pt-190 {
    padding-top: 190px!important
}

.pb-190 {
    padding-bottom: 190px!important
}

.mt-190 {
    margin-top: 190px!important
}

.mb-190 {
    margin-bottom: 190px!important
}

.pt-195 {
    padding-top: 195px!important
}

.pb-195 {
    padding-bottom: 195px!important
}

.mt-195 {
    margin-top: 195px!important
}

.mb-195 {
    margin-bottom: 195px!important
}

.pt-200 {
    padding-top: 200px!important
}

.pb-200 {
    padding-bottom: 200px!important
}

.mt-200 {
    margin-top: 200px!important
}

.mb-200 {
    margin-bottom: 200px!important
}

.pt-205 {
    padding-top: 205px!important
}

.pb-205 {
    padding-bottom: 205px!important
}

.mt-205 {
    margin-top: 205px!important
}

.mb-205 {
    margin-bottom: 205px!important
}

.pt-210 {
    padding-top: 210px!important
}

.pb-210 {
    padding-bottom: 210px!important
}

.mt-210 {
    margin-top: 210px!important
}

.mb-210 {
    margin-bottom: 210px!important
}

.pt-215 {
    padding-top: 215px!important
}

.pb-215 {
    padding-bottom: 215px!important
}

.mt-215 {
    margin-top: 215px!important
}

.mb-215 {
    margin-bottom: 215px!important
}

.pt-220 {
    padding-top: 220px!important
}

.pb-220 {
    padding-bottom: 220px!important
}

.mt-220 {
    margin-top: 220px!important
}

.mb-220 {
    margin-bottom: 220px!important
}

.pt-225 {
    padding-top: 225px!important
}

.pb-225 {
    padding-bottom: 225px!important
}

.mt-225 {
    margin-top: 225px!important
}

.mb-225 {
    margin-bottom: 225px!important
}

.pt-230 {
    padding-top: 230px!important
}

.pb-230 {
    padding-bottom: 230px!important
}

.mt-230 {
    margin-top: 230px!important
}

.mb-230 {
    margin-bottom: 230px!important
}

.pt-235 {
    padding-top: 235px!important
}

.pb-235 {
    padding-bottom: 235px!important
}

.mt-235 {
    margin-top: 235px!important
}

.mb-235 {
    margin-bottom: 235px!important
}

.pt-240 {
    padding-top: 240px!important
}

.pb-240 {
    padding-bottom: 240px!important
}

.mt-240 {
    margin-top: 240px!important
}

.mb-240 {
    margin-bottom: 240px!important
}

.pt-245 {
    padding-top: 245px!important
}

.pb-245 {
    padding-bottom: 245px!important
}

.mt-245 {
    margin-top: 245px!important
}

.mb-245 {
    margin-bottom: 245px!important
}

.pt-250 {
    padding-top: 250px!important
}

.pb-250 {
    padding-bottom: 250px!important
}

.mt-250 {
    margin-top: 250px!important
}

.mb-250 {
    margin-bottom: 250px!important
}

.pt-255 {
    padding-top: 255px!important
}

.pb-255 {
    padding-bottom: 255px!important
}

.mt-255 {
    margin-top: 255px!important
}

.mb-255 {
    margin-bottom: 255px!important
}

.pt-260 {
    padding-top: 260px!important
}

.pb-260 {
    padding-bottom: 260px!important
}

.mt-260 {
    margin-top: 260px!important
}

.mb-260 {
    margin-bottom: 260px!important
}

.pt-265 {
    padding-top: 265px!important
}

.pb-265 {
    padding-bottom: 265px!important
}

.mt-265 {
    margin-top: 265px!important
}

.mb-265 {
    margin-bottom: 265px!important
}

.pt-270 {
    padding-top: 270px!important
}

.pb-270 {
    padding-bottom: 270px!important
}

.mt-270 {
    margin-top: 270px!important
}

.mb-270 {
    margin-bottom: 270px!important
}

.pt-275 {
    padding-top: 275px!important
}

.pb-275 {
    padding-bottom: 275px!important
}

.mt-275 {
    margin-top: 275px!important
}

.mb-275 {
    margin-bottom: 275px!important
}

.pt-280 {
    padding-top: 280px!important
}

.pb-280 {
    padding-bottom: 280px!important
}

.mt-280 {
    margin-top: 280px!important
}

.mb-280 {
    margin-bottom: 280px!important
}

.pt-285 {
    padding-top: 285px!important
}

.pb-285 {
    padding-bottom: 285px!important
}

.mt-285 {
    margin-top: 285px!important
}

.mb-285 {
    margin-bottom: 285px!important
}

.branded [class*=fa-behance-square],.branded [class*=fa-behance] {
    background-color: #1769ff
}

.branded [class*=fa-codepen] {
    background-color: #0ebeff
}

.branded [class*=fa-deviantart] {
    background-color: #05cc47
}

.branded [class*=fa-digg] {
    background-color: #005be2
}

.branded [class*=fa-dribbble] {
    background-color: #ea4c89
}

.branded [class*=fa-facebook-square],.branded [class*=fa-facebook] {
    background-color: #3b5998
}

.branded [class*=fa-flickr] {
    background-color: #0063dc
}

.branded [class*=fa-github] {
    background-color: #4078c0
}

.branded [class*=fa-google] {
    background-color: #4285f4
}

.branded [class*=fa-google-plus] {
    background-color: #dd4b39
}

.branded [class*=fa-instagram] {
    background-color: #405de6
}

.branded [class*=fa-jsfiddle] {
    background-color: #0084FF
}

.branded [class*=fa-linkedin] {
    background-color: #0077b5
}

.branded [class*=fa-medium] {
    background-color: #00ab6c
}

.branded [class*=fa-paypal] {
    background-color: #003087
}

.branded [class*=fa-pinterest-p],.branded [class*=fa-pinterest] {
    background-color: #bd081c
}

.branded [class*=fa-reddit-square],.branded [class*=fa-reddit] {
    background-color: #ff4500
}

.branded [class*=fa-skype] {
    background-color: #00aff0
}

.branded [class*=fa-slack] {
    background-color: #6ecadc
}

.branded [class*=fa-snapchat] {
    background-color: #fffc00
}

.branded [class*=fa-soundcloud] {
    background-color: #f80
}

.branded [class*=fa-spotify] {
    background-color: #1db954
}

.branded [class*=fa-stack-overflow] {
    background-color: #f48024
}

.branded [class*=fa-telegram] {
    background-color: #08c
}

.branded [class*=fa-trello] {
    background-color: #0079bf
}

.branded [class*=fa-tumblr] {
    background-color: #35465c
}

.branded [class*=fa-twitch] {
    background-color: #6441a5
}

.branded [class*=fa-twitter-square],.branded [class*=fa-twitter] {
    background-color: #1da1f2
}

.branded [class*=fa-vimeo] {
    background-color: #1ab7ea
}

.branded [class*=fa-wordpress] {
    background-color: #21759b
}

.branded [class*=fa-youtube-play],.branded [class*=fa-youtube] {
    background-color: red
}

.branded-text [class*=fa-behance-square],.branded-text [class*=fa-behance] {
    color: #1769ff
}

.branded-text [class*=fa-codepen] {
    color: #0ebeff
}

.branded-text [class*=fa-deviantart] {
    color: #05cc47
}

.branded-text [class*=fa-digg] {
    color: #005be2
}

.branded-text [class*=fa-dribbble] {
    color: #ea4c89
}

.branded-text [class*=fa-facebook-square],.branded-text [class*=fa-facebook] {
    color: #3b5998
}

.branded-text [class*=fa-flickr] {
    color: #0063dc
}

.branded-text [class*=fa-github] {
    color: #4078c0
}

.branded-text [class*=fa-google] {
    color: #4285f4
}

.branded-text [class*=fa-google-plus] {
    color: #dd4b39
}

.branded-text [class*=fa-instagram] {
    color: #405de6
}

.branded-text [class*=fa-jsfiddle] {
    color: #0084FF
}

.branded-text [class*=fa-linkedin] {
    color: #0077b5
}

.branded-text [class*=fa-medium] {
    color: #00ab6c
}

.branded-text [class*=fa-paypal] {
    color: #003087
}

.branded-text [class*=fa-pinterest-p],.branded-text [class*=fa-pinterest] {
    color: #bd081c
}

.branded-text [class*=fa-reddit-square],.branded-text [class*=fa-reddit] {
    color: #ff4500
}

.branded-text [class*=fa-skype] {
    color: #00aff0
}

.branded-text [class*=fa-slack] {
    color: #6ecadc
}

.branded-text [class*=fa-snapchat] {
    color: #fffc00
}

.branded-text [class*=fa-soundcloud] {
    color: #f80
}

.branded-text [class*=fa-spotify] {
    color: #1db954
}

.branded-text [class*=fa-stack-overflow] {
    color: #f48024
}

.branded-text [class*=fa-telegram] {
    color: #08c
}

.branded-text [class*=fa-trello] {
    color: #0079bf
}

.branded-text [class*=fa-tumblr] {
    color: #35465c
}

.branded-text [class*=fa-twitch] {
    color: #6441a5
}

.branded-text [class*=fa-twitter-square],.branded-text [class*=fa-twitter] {
    color: #1da1f2
}

.branded-text [class*=fa-vimeo] {
    color: #1ab7ea
}

.branded-text [class*=fa-wordpress] {
    color: #21759b
}

.branded-text [class*=fa-youtube-play],.branded-text [class*=fa-youtube] {
    color: red
}

.overflow-hidden {
    overflow: hidden!important
}

.overflow-visible {
    overflow: visible!important
}

.star-rating {
    padding: 0;
    margin: 0;
    list-style: none
}

.star-rating li {
    display: inline-block;
    font-size: 1em;
    line-height: 1.5em;
    color: #fc0;
    letter-spacing: .1em;
    margin: 0
}

.star-rating.square li {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 30px;
    height: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 2px;
    font-size: 20px;
    color: #fff;
    background-color: #ff7a4b
}

.star-rating.square.sm li {
    width: 13px;
    height: 13px;
    font-size: 8px
}

.liquid-overlay-link {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: -99999px
}

.add-dropcap:first-letter {
    float: left;
    margin-right: .1em;
    font-size: 4em;
    line-height: .75em;
    font-weight: 700;
    color: #000
}

.fullwidth {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw
}

.fullheight {
    min-height: 100vh
}

.inner-title-height {
    min-height: 75vh
}

.is-ie .fullheight {
    height: 100vh
}

[data-hover3d=true] [data-stacking-factor] {
    -webkit-transition: none;
    transition: none
}

.ld-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.perspective {
    -webkit-perspective: 1800px;
    perspective: 1800px;
    position: relative;
    z-index: 1
}

.will-change {
    will-change: transform,opacity
}

.transition-none {
    -webkit-transition: none!important;
    transition: none!important
}

.z-index-2 {
    z-index: 2
}

.z-index-3 {
    z-index: 3
}

.z-index-4 {
    z-index: 4
}

.z-index-5 {
    z-index: 5
}

.pos-rel {
    position: relative
}

.pos-abs {
    position: absolute!important
}

.pos-fix {
    position: fixed!important
}

.pos-stc {
    position: static!important
}

.border-gray {
    border: 1px solid #ddd
}

.border-athens-gray {
    border: 1px solid #E8E9F1
}

.bb-light {
    border-bottom: 1px solid #ebedf6
}

.border-fade-white-005 {
    border: 1px solid rgba(255,255,255,.05)
}

.border-fade-black-005 {
    border: 1px solid #e5e8ec
}

.bt-fade-white-005 {
    border-top: 1px solid rgba(255,255,255,.05)
}

.bb-fade-white-005 {
    border-bottom: 1px solid rgba(255,255,255,.05)
}

.bt-fade-black-005 {
    border-top: 1px solid rgba(0,0,0,.05)
}

.bb-fade-black-005 {
    border-bottom: 1px solid rgba(0,0,0,.05)
}

.border-fade-white-01 {
    border: 1px solid rgba(255,255,255,.1)
}

.border-fade-black-01 {
    border: 1px solid rgba(0,0,0,.1)
}

.bt-fade-white-01 {
    border-top: 1px solid rgba(255,255,255,.1)
}

.bb-fade-white-01 {
    border-bottom: 1px solid rgba(255,255,255,.1)
}

.bt-fade-black-01 {
    border-top: 1px solid rgba(0,0,0,.1)
}

.bb-fade-black-01 {
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.border-fade-white-015 {
    border: 1px solid rgba(255,255,255,.15)
}

.border-fade-black-015 {
    border: 1px solid rgba(0,0,0,.15)
}

.bt-fade-white-015 {
    border-top: 1px solid rgba(255,255,255,.15)
}

.bb-fade-white-015 {
    border-bottom: 1px solid rgba(255,255,255,.15)
}

.bt-fade-black-015 {
    border-top: 1px solid rgba(0,0,0,.15)
}

.bb-fade-black-015 {
    border-bottom: 1px solid rgba(0,0,0,.15)
}

.border-fade-white-02 {
    border: 1px solid rgba(255,255,255,.2)
}

.border-fade-black-02 {
    border: 1px solid rgba(0,0,0,.2)
}

.bt-fade-white-02 {
    border-top: 1px solid rgba(255,255,255,.2)
}

.bb-fade-white-02 {
    border-bottom: 1px solid rgba(255,255,255,.2)
}

.bt-fade-black-02 {
    border-top: 1px solid rgba(0,0,0,.2)
}

.bb-fade-black-02 {
    border-bottom: 1px solid rgba(0,0,0,.2)
}

.border-fade-white-025 {
    border: 1px solid rgba(255,255,255,.25)
}

.border-fade-black-025 {
    border: 1px solid rgba(0,0,0,.25)
}

.bt-fade-white-025 {
    border-top: 1px solid rgba(255,255,255,.25)
}

.bb-fade-white-025 {
    border-bottom: 1px solid rgba(255,255,255,.25)
}

.bt-fade-black-025 {
    border-top: 1px solid rgba(0,0,0,.25)
}

.bb-fade-black-025 {
    border-bottom: 1px solid rgba(0,0,0,.25)
}

.border-fade-white-03 {
    border: 1px solid rgba(255,255,255,.3)
}

.border-fade-black-03 {
    border: 1px solid rgba(0,0,0,.3)
}

.bt-fade-white-03 {
    border-top: 1px solid rgba(255,255,255,.3)
}

.bb-fade-white-03 {
    border-bottom: 1px solid rgba(255,255,255,.3)
}

.bt-fade-black-03 {
    border-top: 1px solid rgba(0,0,0,.3)
}

.bb-fade-black-03 {
    border-bottom: 1px solid rgba(0,0,0,.3)
}

.border-fade-white-035 {
    border: 1px solid rgba(255,255,255,.35)
}

.border-fade-black-035 {
    border: 1px solid rgba(0,0,0,.35)
}

.bt-fade-white-035 {
    border-top: 1px solid rgba(255,255,255,.35)
}

.bb-fade-white-035 {
    border-bottom: 1px solid rgba(255,255,255,.35)
}

.bt-fade-black-035 {
    border-top: 1px solid rgba(0,0,0,.35)
}

.bb-fade-black-035 {
    border-bottom: 1px solid rgba(0,0,0,.35)
}

.border-fade-white-04 {
    border: 1px solid rgba(255,255,255,.4)
}

.border-fade-black-04 {
    border: 1px solid rgba(0,0,0,.4)
}

.bt-fade-white-04 {
    border-top: 1px solid rgba(255,255,255,.4)
}

.bb-fade-white-04 {
    border-bottom: 1px solid rgba(255,255,255,.4)
}

.bt-fade-black-04 {
    border-top: 1px solid rgba(0,0,0,.4)
}

.bb-fade-black-04 {
    border-bottom: 1px solid rgba(0,0,0,.4)
}

.border-fade-white-045 {
    border: 1px solid rgba(255,255,255,.45)
}

.border-fade-black-045 {
    border: 1px solid rgba(0,0,0,.45)
}

.bt-fade-white-045 {
    border-top: 1px solid rgba(255,255,255,.45)
}

.bb-fade-white-045 {
    border-bottom: 1px solid rgba(255,255,255,.45)
}

.bt-fade-black-045 {
    border-top: 1px solid rgba(0,0,0,.45)
}

.bb-fade-black-045 {
    border-bottom: 1px solid rgba(0,0,0,.45)
}

.border-fade-white-05 {
    border: 1px solid rgba(255,255,255,.5)
}

.border-fade-black-05 {
    border: 1px solid rgba(0,0,0,.5)
}

.bt-fade-white-05 {
    border-top: 1px solid rgba(255,255,255,.5)
}

.bb-fade-white-05 {
    border-bottom: 1px solid rgba(255,255,255,.5)
}

.bt-fade-black-05 {
    border-top: 1px solid rgba(0,0,0,.5)
}

.bb-fade-black-05 {
    border-bottom: 1px solid rgba(0,0,0,.5)
}

.border-fade-white-055 {
    border: 1px solid rgba(255,255,255,.55)
}

.border-fade-black-055 {
    border: 1px solid rgba(0,0,0,.55)
}

.bt-fade-white-055 {
    border-top: 1px solid rgba(255,255,255,.55)
}

.bb-fade-white-055 {
    border-bottom: 1px solid rgba(255,255,255,.55)
}

.bt-fade-black-055 {
    border-top: 1px solid rgba(0,0,0,.55)
}

.bb-fade-black-055 {
    border-bottom: 1px solid rgba(0,0,0,.55)
}

.border-fade-white-06 {
    border: 1px solid rgba(255,255,255,.6)
}

.border-fade-black-06 {
    border: 1px solid rgba(0,0,0,.6)
}

.bt-fade-white-06 {
    border-top: 1px solid rgba(255,255,255,.6)
}

.bb-fade-white-06 {
    border-bottom: 1px solid rgba(255,255,255,.6)
}

.bt-fade-black-06 {
    border-top: 1px solid rgba(0,0,0,.6)
}

.bb-fade-black-06 {
    border-bottom: 1px solid rgba(0,0,0,.6)
}

.border-fade-white-065 {
    border: 1px solid rgba(255,255,255,.65)
}

.border-fade-black-065 {
    border: 1px solid rgba(0,0,0,.65)
}

.bt-fade-white-065 {
    border-top: 1px solid rgba(255,255,255,.65)
}

.bb-fade-white-065 {
    border-bottom: 1px solid rgba(255,255,255,.65)
}

.bt-fade-black-065 {
    border-top: 1px solid rgba(0,0,0,.65)
}

.bb-fade-black-065 {
    border-bottom: 1px solid rgba(0,0,0,.65)
}

.border-fade-white-07 {
    border: 1px solid rgba(255,255,255,.7)
}

.border-fade-black-07 {
    border: 1px solid rgba(0,0,0,.7)
}

.bt-fade-white-07 {
    border-top: 1px solid rgba(255,255,255,.7)
}

.bb-fade-white-07 {
    border-bottom: 1px solid rgba(255,255,255,.7)
}

.bt-fade-black-07 {
    border-top: 1px solid rgba(0,0,0,.7)
}

.bb-fade-black-07 {
    border-bottom: 1px solid rgba(0,0,0,.7)
}

.border-fade-white-075 {
    border: 1px solid rgba(255,255,255,.75)
}

.border-fade-black-075 {
    border: 1px solid rgba(0,0,0,.75)
}

.bt-fade-white-075 {
    border-top: 1px solid rgba(255,255,255,.75)
}

.bb-fade-white-075 {
    border-bottom: 1px solid rgba(255,255,255,.75)
}

.bt-fade-black-075 {
    border-top: 1px solid rgba(0,0,0,.75)
}

.bb-fade-black-075 {
    border-bottom: 1px solid rgba(0,0,0,.75)
}

.border-fade-white-08 {
    border: 1px solid rgba(255,255,255,.8)
}

.border-fade-black-08 {
    border: 1px solid rgba(0,0,0,.8)
}

.bt-fade-white-08 {
    border-top: 1px solid rgba(255,255,255,.8)
}

.bb-fade-white-08 {
    border-bottom: 1px solid rgba(255,255,255,.8)
}

.bt-fade-black-08 {
    border-top: 1px solid rgba(0,0,0,.8)
}

.bb-fade-black-08 {
    border-bottom: 1px solid rgba(0,0,0,.8)
}

.border-fade-white-085 {
    border: 1px solid rgba(255,255,255,.85)
}

.border-fade-black-085 {
    border: 1px solid rgba(0,0,0,.85)
}

.bt-fade-white-085 {
    border-top: 1px solid rgba(255,255,255,.85)
}

.bb-fade-white-085 {
    border-bottom: 1px solid rgba(255,255,255,.85)
}

.bt-fade-black-085 {
    border-top: 1px solid rgba(0,0,0,.85)
}

.bb-fade-black-085 {
    border-bottom: 1px solid rgba(0,0,0,.85)
}

.bb-gray {
    border-bottom: 1px solid #edecec
}

.border-color-white {
    border-color: #fff
}

.border-color-primary {
    border-color: var(--color-primary)
}

.border-color-secondary {
    border-color: var(--color-secondary)
}

.border-none {
    border: none!important
}

.bordered-section-white {
    border: 2vw solid #fff
}

.bordered-section-white-3vw {
    border: 3vw solid #fff
}

.border-radius-0 {
    border-radius: 0!important
}

.border-radius-1 {
    border-radius: 1px!important
}

.border-radius-2 {
    border-radius: 2px!important
}

.border-radius-3 {
    border-radius: 3px!important
}

.border-radius-4 {
    border-radius: 4px!important
}

.border-radius-5 {
    border-radius: 5px!important
}

.border-radius-6 {
    border-radius: 6px!important
}

.border-radius-7 {
    border-radius: 7px!important
}

.border-radius-8 {
    border-radius: 8px!important
}

.border-radius-9 {
    border-radius: 9px!important
}

.border-radius-10 {
    border-radius: 10px!important
}

.border-radius-11 {
    border-radius: 11px!important
}

.border-radius-12 {
    border-radius: 12px!important
}

.border-radius-13 {
    border-radius: 13px!important
}

.border-radius-14 {
    border-radius: 14px!important
}

.border-radius-15 {
    border-radius: 15px!important
}

.border-radius-16 {
    border-radius: 16px!important
}

.border-radius-17 {
    border-radius: 17px!important
}

.border-radius-18 {
    border-radius: 18px!important
}

.border-radius-19 {
    border-radius: 19px!important
}

.border-radius-20 {
    border-radius: 20px!important
}

.border-radius-21 {
    border-radius: 21px!important
}

.border-radius-22 {
    border-radius: 22px!important
}

.border-radius-23 {
    border-radius: 23px!important
}

.border-radius-24 {
    border-radius: 24px!important
}

.border-radius-25 {
    border-radius: 25px!important
}

.semi-round {
    border-radius: 2px
}

.round {
    border-radius: 4px
}

.circle {
    border-radius: 50em
}

.w-5 {
    width: 5%!important
}

.w-10 {
    width: 10%!important
}

.w-15 {
    width: 15%!important
}

.w-20 {
    width: 20%!important
}

.w-25 {
    width: 25%!important
}

.w-30 {
    width: 30%!important
}

.w-35 {
    width: 35%!important
}

.w-40 {
    width: 40%!important
}

.w-45 {
    width: 45%!important
}

.w-50 {
    width: 50%!important
}

.w-55 {
    width: 55%!important
}

.w-60 {
    width: 60%!important
}

.w-65 {
    width: 65%!important
}

.w-70 {
    width: 70%!important
}

.w-75 {
    width: 75%!important
}

.w-80 {
    width: 80%!important
}

.w-85 {
    width: 85%!important
}

.w-90 {
    width: 90%!important
}

.w-95 {
    width: 95%!important
}

.w-100 {
    width: 100%!important
}

.w-105 {
    width: 105%!important
}

.w-110 {
    width: 110%!important
}

.w-115 {
    width: 115%!important
}

.w-120 {
    width: 120%!important
}

.w-125 {
    width: 125%!important
}

.w-130 {
    width: 130%!important
}

.w-135 {
    width: 135%!important
}

.w-140 {
    width: 140%!important
}

.w-145 {
    width: 145%!important
}

.w-150 {
    width: 150%!important
}

.w-155 {
    width: 155%!important
}

.w-160 {
    width: 160%!important
}

.w-165 {
    width: 165%!important
}

.w-170 {
    width: 170%!important
}

.w-175 {
    width: 175%!important
}

.w-180 {
    width: 180%!important
}

.w-185 {
    width: 185%!important
}

.w-190 {
    width: 190%!important
}

.w-195 {
    width: 195%!important
}

.w-200 {
    width: 200%!important
}

.bg-primary {
    background: var(--color-primary)!important
}

.bg-secondary {
    background: var(--color-secondary)!important
}

.bg-transparent {
    background: 0 0!important
}

.bg-white {
    background: #fff!important
}

.bg-black {
    background: #000!important
}

.bg-gray {
    background: #f4f5f8!important
}

.bg-gray-2 {
    background: #fafafa!important
}

.bg-gray-3 {
    background: #f1f1f1!important
}

.bg-light {
    background: #f7f8fb!important
}

.bg-dark {
    background: #212121!important
}

.bg-mirage {
    background: #181b31!important
}

.bg-vulcan {
    background: #141622!important
}

.bg-charade {
    background: #2D323D!important
}

.bg-tundora {
    background: #40383C!important
}

.bg-shark {
    background: #27282a!important
}

.bg-gigas {
    background: #4439a9!important
}

.bg-athens-gray {
    background: #f5f7fa!important
}

.bg-ebony-clay {
    background: #252c42!important
}

.bg-link-water {
    background: #DCE9F6!important
}

.bg-swans-down {
    background: #dbf1f1!important
}

.bg-old-lace {
    background: #fcece1!important
}

.bg-woodsmoke {
    background: #0e0f14!important
}

.bg-cape-cod {
    background: #343535!important
}

.bg-zircon {
    background: #f8faff!important
}

.bg-fade-dark-005 {
    background-color: rgba(0,0,0,.05)!important
}

.bg-fade-white-005 {
    background-color: rgba(255,255,255,.05)!important
}

.bg-fade-dark-01 {
    background-color: rgba(0,0,0,.1)!important
}

.bg-fade-white-01 {
    background-color: rgba(255,255,255,.1)!important
}

.bg-fade-dark-015 {
    background-color: rgba(0,0,0,.15)!important
}

.bg-fade-white-015 {
    background-color: rgba(255,255,255,.15)!important
}

.bg-fade-dark-02 {
    background-color: rgba(0,0,0,.2)!important
}

.bg-fade-white-02 {
    background-color: rgba(255,255,255,.2)!important
}

.bg-fade-dark-025 {
    background-color: rgba(0,0,0,.25)!important
}

.bg-fade-white-025 {
    background-color: rgba(255,255,255,.25)!important
}

.bg-fade-dark-03 {
    background-color: rgba(0,0,0,.3)!important
}

.bg-fade-white-03 {
    background-color: rgba(255,255,255,.3)!important
}

.bg-fade-dark-035 {
    background-color: rgba(0,0,0,.35)!important
}

.bg-fade-white-035 {
    background-color: rgba(255,255,255,.35)!important
}

.bg-fade-dark-04 {
    background-color: rgba(0,0,0,.4)!important
}

.bg-fade-white-04 {
    background-color: rgba(255,255,255,.4)!important
}

.bg-fade-dark-045 {
    background-color: rgba(0,0,0,.45)!important
}

.bg-fade-white-045 {
    background-color: rgba(255,255,255,.45)!important
}

.bg-fade-dark-05 {
    background-color: rgba(0,0,0,.5)!important
}

.bg-fade-white-05 {
    background-color: rgba(255,255,255,.5)!important
}

.bg-fade-dark-055 {
    background-color: rgba(0,0,0,.55)!important
}

.bg-fade-white-055 {
    background-color: rgba(255,255,255,.55)!important
}

.bg-fade-dark-06 {
    background-color: rgba(0,0,0,.6)!important
}

.bg-fade-white-06 {
    background-color: rgba(255,255,255,.6)!important
}

.bg-fade-dark-065 {
    background-color: rgba(0,0,0,.65)!important
}

.bg-fade-white-065 {
    background-color: rgba(255,255,255,.65)!important
}

.bg-fade-dark-07 {
    background-color: rgba(0,0,0,.7)!important
}

.bg-fade-white-07 {
    background-color: rgba(255,255,255,.7)!important
}

.bg-fade-dark-075 {
    background-color: rgba(0,0,0,.75)!important
}

.bg-fade-white-075 {
    background-color: rgba(255,255,255,.75)!important
}

.bg-fade-dark-08 {
    background-color: rgba(0,0,0,.8)!important
}

.bg-fade-white-08 {
    background-color: rgba(255,255,255,.8)!important
}

.bg-fade-dark-085 {
    background-color: rgba(0,0,0,.85)!important
}

.bg-fade-white-085 {
    background-color: rgba(255,255,255,.85)!important
}

.bg-hover-secondary:hover {
    background-color: var(--color-secondary)!important
}

.bg-hover-white:hover {
    background-color: #fff!important
}

.bg-hover-light:hover {
    background-color: #f7f8fb!important
}

.bg-hover-dark:hover {
    background-color: #212121!important
}

.bg-hover-charade:hover {
    background-color: #2D323D!important
}

.bg-hover-tundora:hover {
    background-color: #40383C!important
}

.bg-gradient-primary-lr {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)))!important;
    background: linear-gradient(to right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)!important
}

.bg-gradient-primary-rl {
    background: -webkit-gradient(linear,right top,left top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)))!important;
    background: linear-gradient(to left,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)!important
}

.bg-gradient-primary-tb {
    background: -webkit-gradient(linear,left top,left bottom,from(var(--color-gradient-start)),to(var(--color-gradient-stop)))!important;
    background: linear-gradient(to bottom,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)!important
}

.bg-gradient-primary-bt {
    background: -webkit-gradient(linear,left bottom,left top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)))!important;
    background: linear-gradient(to top,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)!important
}

.bg-gradient-primary-tl {
    background: -webkit-gradient(linear,right bottom,left top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)))!important;
    background: linear-gradient(to top left,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)!important
}

.bg-gradient-primary-tr {
    background: -webkit-gradient(linear,left bottom,right top,from(var(--color-gradient-start)),to(var(--color-gradient-stop)))!important;
    background: linear-gradient(to top right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)!important
}

.bg-gradient-primary-br {
    background: -webkit-gradient(linear,left top,right bottom,from(var(--color-gradient-start)),to(var(--color-gradient-stop)))!important;
    background: linear-gradient(to bottom right,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)!important
}

.bg-gradient-primary-bl {
    background: -webkit-gradient(linear,right top,left bottom,from(var(--color-gradient-start)),to(var(--color-gradient-stop)))!important;
    background: linear-gradient(to bottom left,var(--color-gradient-start) 0,var(--color-gradient-stop) 100%)!important
}

.bg-gradient-secondary-lr {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)))!important;
    background: linear-gradient(to right,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)!important
}

.bg-gradient-secondary-rl {
    background: -webkit-gradient(linear,right top,left top,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)))!important;
    background: linear-gradient(to left,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)!important
}

.bg-gradient-secondary-tb {
    background: -webkit-gradient(linear,left top,left bottom,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)))!important;
    background: linear-gradient(to bottom,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)!important
}

.bg-gradient-secondary-bt {
    background: -webkit-gradient(linear,left bottom,left top,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)))!important;
    background: linear-gradient(to top,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)!important
}

.bg-gradient-secondary-tl {
    background: -webkit-gradient(linear,right bottom,left top,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)))!important;
    background: linear-gradient(to top left,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)!important
}

.bg-gradient-secondary-tr {
    background: -webkit-gradient(linear,left bottom,right top,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)))!important;
    background: linear-gradient(to top right,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)!important
}

.bg-gradient-secondary-br {
    background: -webkit-gradient(linear,left top,right bottom,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)))!important;
    background: linear-gradient(to bottom right,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)!important
}

.bg-gradient-secondary-bl {
    background: -webkit-gradient(linear,right top,left bottom,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)))!important;
    background: linear-gradient(to bottom left,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)!important
}

.bg-gradient-tertiary-lr {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start-tertiary)),to(var(--color-gradient-stop-tertiary)))!important;
    background: linear-gradient(to right,var(--color-gradient-start-tertiary) 0,var(--color-gradient-stop-tertiary) 100%)!important
}

.bg-gradient-tertiary-rl {
    background: -webkit-gradient(linear,right top,left top,from(var(--color-gradient-start-tertiary)),to(var(--color-gradient-stop-tertiary)))!important;
    background: linear-gradient(to left,var(--color-gradient-start-tertiary) 0,var(--color-gradient-stop-tertiary) 100%)!important
}

.bg-gradient-tertiary-tb {
    background: -webkit-gradient(linear,left top,left bottom,from(var(--color-gradient-start-tertiary)),to(var(--color-gradient-stop-tertiary)))!important;
    background: linear-gradient(to bottom,var(--color-gradient-start-tertiary) 0,var(--color-gradient-stop-tertiary) 100%)!important
}

.bg-gradient-tertiary-bt {
    background: -webkit-gradient(linear,left bottom,left top,from(var(--color-gradient-start-tertiary)),to(var(--color-gradient-stop-tertiary)))!important;
    background: linear-gradient(to top,var(--color-gradient-start-tertiary) 0,var(--color-gradient-stop-tertiary) 100%)!important
}

.bg-gradient-tertiary-tl {
    background: -webkit-gradient(linear,right bottom,left top,from(var(--color-gradient-start-tertiary)),to(var(--color-gradient-stop-tertiary)))!important;
    background: linear-gradient(to top left,var(--color-gradient-start-tertiary) 0,var(--color-gradient-stop-tertiary) 100%)!important
}

.bg-gradient-tertiary-tr {
    background: -webkit-gradient(linear,left bottom,right top,from(var(--color-gradient-start-tertiary)),to(var(--color-gradient-stop-tertiary)))!important;
    background: linear-gradient(to top right,var(--color-gradient-start-tertiary) 0,var(--color-gradient-stop-tertiary) 100%)!important
}

.bg-gradient-tertiary-br {
    background: -webkit-gradient(linear,left top,right bottom,from(var(--color-gradient-start-tertiary)),to(var(--color-gradient-stop-tertiary)))!important;
    background: linear-gradient(to bottom right,var(--color-gradient-start-tertiary) 0,var(--color-gradient-stop-tertiary) 100%)!important
}

.bg-gradient-tertiary-bl {
    background: -webkit-gradient(linear,right top,left bottom,from(var(--color-gradient-start-tertiary)),to(var(--color-gradient-stop-tertiary)))!important;
    background: linear-gradient(to bottom left,var(--color-gradient-start-tertiary) 0,var(--color-gradient-stop-tertiary) 100%)!important
}

.bg-gradient-black-transparent-tb {
    background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.7)),to(transparent))!important;
    background: linear-gradient(to bottom,rgba(0,0,0,.7) 0,transparent 100%)!important
}

.bg-gradient-black-transparent-bt {
    background: -webkit-gradient(linear,left bottom,left top,from(rgba(0,0,0,.7)),to(transparent))!important;
    background: linear-gradient(to top,rgba(0,0,0,.7) 0,transparent 100%)!important
}

.bg-gradient-black-transparent-lr {
    background: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.7)),to(transparent))!important;
    background: linear-gradient(to right,rgba(0,0,0,.7) 0,transparent 100%)!important
}

.bg-gradient-black-transparent-rl {
    background: -webkit-gradient(linear,right top,left top,from(rgba(0,0,0,.7)),to(transparent))!important;
    background: linear-gradient(to left,rgba(0,0,0,.7) 0,transparent 100%)!important
}

.bg-gradient-gray-transparent-bt {
    background: -webkit-gradient(linear,left bottom,left top,from(#f1f4f9),to(transparent))!important;
    background: linear-gradient(to top,#f1f4f9 0,transparent 100%)!important
}

.bg-gradient-gray-transparent-tb {
    background: -webkit-gradient(linear,left top,left bottom,from(#f1f4f9),to(transparent))!important;
    background: linear-gradient(to bottom,#f1f4f9 0,transparent 100%)!important
}

.bg-gradient-cream {
    background: -webkit-gradient(linear,left top,left bottom,from(#fff8f5),color-stop(50%,#fff));
    background: linear-gradient(180deg,#fff8f5 0,#fff 50%)
}

.bg-gradient-primary-35p {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-primary)),color-stop(35%,var(--color-primary)),color-stop(35%,transparent));
    background: linear-gradient(to right,var(--color-primary) 0,var(--color-primary) 35%,transparent 35%)
}

.bg-fixed {
    background-attachment: fixed
}

.bg-cover {
    background-size: cover!important
}

.bg-contain {
    background-size: contain!important
}

.bg-no-repeat {
    background-repeat: no-repeat!important
}

.bg-center {
    background-position: center!important
}

.bg-left-center {
    background-position: left center!important
}

.bg-left-top {
    background-position: left top!important
}

.bg-left-bottom {
    background-position: left bottom!important
}

.bg-right-center {
    background-position: right center!important
}

.bg-right-top {
    background-position: right top!important
}

.bg-right-bottom {
    background-position: right bottom!important
}

.bg-center-bottom {
    background-position: center bottom!important
}

.bg-center-top {
    background-position: center top!important
}

.bg-none {
    background-image: none!important
}

@media (max-width: 768px) {
    .bg-sm-none {
        background-image:none!important
    }
}

@media (max-width: 992px) {
    .bg-md-none {
        background-image:none!important
    }
}

@media (max-width: 1200px) {
    .bg-lg-none {
        background-image:none!important
    }
}

.opacity-01 {
    opacity: .1
}

.ld-pf-item:hover .opacity-01 {
    opacity: .1!important
}

.opacity-02 {
    opacity: .2
}

.ld-pf-item:hover .opacity-02 {
    opacity: .2!important
}

.opacity-03 {
    opacity: .3
}

.ld-pf-item:hover .opacity-03 {
    opacity: .3!important
}

.opacity-04 {
    opacity: .4
}

.ld-pf-item:hover .opacity-04 {
    opacity: .4!important
}

.opacity-05 {
    opacity: .5
}

.ld-pf-item:hover .opacity-05 {
    opacity: .5!important
}

.opacity-06 {
    opacity: .6
}

.ld-pf-item:hover .opacity-06 {
    opacity: .6!important
}

.opacity-07 {
    opacity: .7
}

.ld-pf-item:hover .opacity-07 {
    opacity: .7!important
}

.opacity-08 {
    opacity: .8
}

.ld-pf-item:hover .opacity-08 {
    opacity: .8!important
}

.opacity-09 {
    opacity: .9
}

.ld-pf-item:hover .opacity-09 {
    opacity: .9!important
}

.opacity-1 {
    opacity: 1
}

.ld-pf-item:hover .opacity-1 {
    opacity: 1!important
}

.reset-opacity-onhover {
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.reset-opacity-onhover:hover {
    opacity: 1
}

.box-shadow-1 {
    -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,.08);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,.08)
}

.box-shadow-2 {
    -webkit-box-shadow: 0 40px 90px 0 rgba(131,0,255,.2);
    box-shadow: 0 40px 90px 0 rgba(131,0,255,.2)
}

.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important
}

.d-flex:after,.d-flex:before {
    content: none
}

.d-inline-flex {
    display: -webkit-inline-box!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
}

.d-inline-flex:after,.d-inline-flex:before {
    content: none
}

.flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important
}

.flex-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
}

.flex-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important
}

.flex-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
}

.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.flex-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.justify-content-start {
    -webkit-box-pack: start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
}

.justify-content-end {
    -webkit-box-pack: end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
}

.justify-content-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important
}

.justify-content-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
}

.justify-content-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
}

.align-items-start {
    -webkit-box-align: start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important
}

.align-items-end {
    -webkit-box-align: end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important
}

.align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
}

.align-items-baseline {
    -webkit-box-align: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important
}

.align-items-stretch {
    -webkit-box-align: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important
}

@media (min-width: 768px) {
    .d-sm-flex {
        display:-webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-sm-flex:after,.d-sm-flex:before {
        content: none
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-sm-inline-flex:after,.d-sm-inline-flex:before {
        content: none
    }

    .flex-sm-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-sm-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .justify-content-sm-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-sm-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-sm-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .align-items-sm-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-sm-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-sm-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }
}

@media (min-width: 992px) {
    .d-md-flex {
        display:-webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-md-flex:after,.d-md-flex:before {
        content: none
    }

    .d-md-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-md-inline-flex:after,.d-md-inline-flex:before {
        content: none
    }

    .flex-md-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-md-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .justify-content-md-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-md-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-md-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-md-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .align-items-md-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-md-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-md-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }
}

@media (min-width: 1200px) {
    .d-lg-flex {
        display:-webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-lg-flex:after,.d-lg-flex:before {
        content: none
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-lg-inline-flex:after,.d-lg-inline-flex:before {
        content: none
    }

    .flex-lg-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-lg-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .justify-content-lg-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-lg-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-lg-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .align-items-lg-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-lg-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-lg-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }
}

.text-left {
    text-align: left!important
}

.text-left div.wpb_single_image {
    text-align: inherit
}

.text-left .liquid-counter-element {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.text-right {
    text-align: right!important
}

.text-right div.wpb_single_image {
    text-align: inherit
}

.text-right .liquid-counter-element {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.text-center {
    text-align: center!important
}

.text-center div.wpb_single_image {
    text-align: inherit
}

.text-center .liquid-counter-element {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (min-width: 768px) {
    .text-sm-left {
        text-align:left!important
    }

    .text-sm-left div.wpb_single_image {
        text-align: inherit
    }

    .text-sm-left .liquid-counter-element {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .text-sm-right {
        text-align: right!important
    }

    .text-sm-right div.wpb_single_image {
        text-align: inherit
    }

    .text-sm-right .liquid-counter-element {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .text-sm-center {
        text-align: center!important
    }

    .text-sm-center div.wpb_single_image {
        text-align: inherit
    }

    .text-sm-center .liquid-counter-element {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

@media (min-width: 992px) {
    .text-md-left {
        text-align:left!important
    }

    .text-md-left div.wpb_single_image {
        text-align: inherit
    }

    .text-md-left .liquid-counter-element {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .text-md-right {
        text-align: right!important
    }

    .text-md-right div.wpb_single_image {
        text-align: inherit
    }

    .text-md-right .liquid-counter-element {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .text-md-center {
        text-align: center!important
    }

    .text-md-center div.wpb_single_image {
        text-align: inherit
    }

    .text-md-center .liquid-counter-element {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

@media (min-width: 1200px) {
    .text-lg-left {
        text-align:left!important
    }

    .text-lg-left div.wpb_single_image {
        text-align: inherit
    }

    .text-lg-left .liquid-counter-element {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .text-lg-right {
        text-align: right!important
    }

    .text-lg-right div.wpb_single_image {
        text-align: inherit
    }

    .text-lg-right .liquid-counter-element {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .text-lg-center {
        text-align: center!important
    }

    .text-lg-center div.wpb_single_image {
        text-align: inherit
    }

    .text-lg-center .liquid-counter-element {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.m-0 {
    margin: 0!important
}

.mt-0,.my-0 {
    margin-top: 0!important
}

.mb-0,.my-0 {
    margin-bottom: 0!important
}

.ml-0,.mx-0 {
    margin-left: 0!important
}

.mr-0,.mx-0 {
    margin-right: 0!important
}

.m-1 {
    margin: .25rem!important
}

.mt-1,.my-1 {
    margin-top: .25rem!important
}

.mb-1,.my-1 {
    margin-bottom: .25rem!important
}

.ml-1,.mx-1 {
    margin-left: .25rem!important
}

.mr-1,.mx-1 {
    margin-right: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.mt-2,.my-2 {
    margin-top: .5rem!important
}

.mb-2,.my-2 {
    margin-bottom: .5rem!important
}

.ml-2,.mx-2 {
    margin-left: .5rem!important
}

.mr-2,.mx-2 {
    margin-right: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.mt-3,.my-3 {
    margin-top: 1rem!important
}

.mb-3,.my-3 {
    margin-bottom: 1rem!important
}

.ml-3,.mx-3 {
    margin-left: 1rem!important
}

.mr-3,.mx-3 {
    margin-right: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.mt-4,.my-4 {
    margin-top: 1.5rem!important
}

.mb-4,.my-4 {
    margin-bottom: 1.5rem!important
}

.ml-4,.mx-4 {
    margin-left: 1.5rem!important
}

.mr-4,.mx-4 {
    margin-right: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.mt-5,.my-5 {
    margin-top: 3rem!important
}

.mb-5,.my-5 {
    margin-bottom: 3rem!important
}

.ml-5,.mx-5 {
    margin-left: 3rem!important
}

.mr-5,.mx-5 {
    margin-right: 3rem!important
}

.m-6 {
    margin: 4.5rem!important
}

.mt-6,.my-6 {
    margin-top: 4.5rem!important
}

.mb-6,.my-6 {
    margin-bottom: 4.5rem!important
}

.ml-6,.mx-6 {
    margin-left: 4.5rem!important
}

.mr-6,.mx-6 {
    margin-right: 4.5rem!important
}

.m-7 {
    margin: 5rem!important
}

.mt-7,.my-7 {
    margin-top: 5rem!important
}

.mb-7,.my-7 {
    margin-bottom: 5rem!important
}

.ml-7,.mx-7 {
    margin-left: 5rem!important
}

.mr-7,.mx-7 {
    margin-right: 5rem!important
}

.m-8 {
    margin: 5.5rem!important
}

.mt-8,.my-8 {
    margin-top: 5.5rem!important
}

.mb-8,.my-8 {
    margin-bottom: 5.5rem!important
}

.ml-8,.mx-8 {
    margin-left: 5.5rem!important
}

.mr-8,.mx-8 {
    margin-right: 5.5rem!important
}

.m-9 {
    margin: 6rem!important
}

.mt-9,.my-9 {
    margin-top: 6rem!important
}

.mb-9,.my-9 {
    margin-bottom: 6rem!important
}

.ml-9,.mx-9 {
    margin-left: 6rem!important
}

.mr-9,.mx-9 {
    margin-right: 6rem!important
}

.p-0 {
    padding: 0!important
}

.pt-0,.py-0 {
    padding-top: 0!important
}

.pb-0,.py-0 {
    padding-bottom: 0!important
}

.pl-0,.px-0 {
    padding-left: 0!important
}

.pr-0,.px-0 {
    padding-right: 0!important
}

.p-1 {
    padding: .25rem!important
}

.pt-1,.py-1 {
    padding-top: .25rem!important
}

.pb-1,.py-1 {
    padding-bottom: .25rem!important
}

.pl-1,.px-1 {
    padding-left: .25rem!important
}

.pr-1,.px-1 {
    padding-right: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.pt-2,.py-2 {
    padding-top: .5rem!important
}

.pb-2,.py-2 {
    padding-bottom: .5rem!important
}

.pl-2,.px-2 {
    padding-left: .5rem!important
}

.pr-2,.px-2 {
    padding-right: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.pt-3,.py-3 {
    padding-top: 1rem!important
}

.pb-3,.py-3 {
    padding-bottom: 1rem!important
}

.pl-3,.px-3 {
    padding-left: 1rem!important
}

.pr-3,.px-3 {
    padding-right: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.pt-4,.py-4 {
    padding-top: 1.5rem!important
}

.pb-4,.py-4 {
    padding-bottom: 1.5rem!important
}

.pl-4,.px-4 {
    padding-left: 1.5rem!important
}

.pr-4,.px-4 {
    padding-right: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.pt-5,.py-5 {
    padding-top: 3rem!important
}

.pb-5,.py-5 {
    padding-bottom: 3rem!important
}

.pl-5,.px-5 {
    padding-left: 3rem!important
}

.pr-5,.px-5 {
    padding-right: 3rem!important
}

.p-6 {
    padding: 4.5rem!important
}

.pt-6,.py-6 {
    padding-top: 4.5rem!important
}

.pb-6,.py-6 {
    padding-bottom: 4.5rem!important
}

.pl-6,.px-6 {
    padding-left: 4.5rem!important
}

.pr-6,.px-6 {
    padding-right: 4.5rem!important
}

.p-7 {
    padding: 5rem!important
}

.pt-7,.py-7 {
    padding-top: 5rem!important
}

.pb-7,.py-7 {
    padding-bottom: 5rem!important
}

.pl-7,.px-7 {
    padding-left: 5rem!important
}

.pr-7,.px-7 {
    padding-right: 5rem!important
}

.p-8 {
    padding: 5.5rem!important
}

.pt-8,.py-8 {
    padding-top: 5.5rem!important
}

.pb-8,.py-8 {
    padding-bottom: 5.5rem!important
}

.pl-8,.px-8 {
    padding-left: 5.5rem!important
}

.pr-8,.px-8 {
    padding-right: 5.5rem!important
}

.p-9 {
    padding: 6rem!important
}

.pt-9,.py-9 {
    padding-top: 6rem!important
}

.pb-9,.py-9 {
    padding-bottom: 6rem!important
}

.pl-9,.px-9 {
    padding-left: 6rem!important
}

.pr-9,.px-9 {
    padding-right: 6rem!important
}

.m-auto {
    margin: auto!important
}

.mt-auto,.my-auto {
    margin-top: auto!important
}

.mb-auto,.my-auto {
    margin-bottom: auto!important
}

.float-none {
    float: none
}

@media (max-width: 768px) {
    .m-xs-0 {
        margin:0!important
    }

    .mt-xs-0,.my-xs-0 {
        margin-top: 0!important
    }

    .mb-xs-0,.my-xs-0 {
        margin-bottom: 0!important
    }

    .ml-xs-0,.mx-xs-0 {
        margin-left: 0!important
    }

    .mr-xs-0,.mx-xs-0 {
        margin-right: 0!important
    }

    .m-xs-1 {
        margin: .25rem!important
    }

    .mt-xs-1,.my-xs-1 {
        margin-top: .25rem!important
    }

    .mb-xs-1,.my-xs-1 {
        margin-bottom: .25rem!important
    }

    .ml-xs-1,.mx-xs-1 {
        margin-left: .25rem!important
    }

    .mr-xs-1,.mx-xs-1 {
        margin-right: .25rem!important
    }

    .m-xs-2 {
        margin: .5rem!important
    }

    .mt-xs-2,.my-xs-2 {
        margin-top: .5rem!important
    }

    .mb-xs-2,.my-xs-2 {
        margin-bottom: .5rem!important
    }

    .ml-xs-2,.mx-xs-2 {
        margin-left: .5rem!important
    }

    .mr-xs-2,.mx-xs-2 {
        margin-right: .5rem!important
    }

    .m-xs-3 {
        margin: 1rem!important
    }

    .mt-xs-3,.my-xs-3 {
        margin-top: 1rem!important
    }

    .mb-xs-3,.my-xs-3 {
        margin-bottom: 1rem!important
    }

    .ml-xs-3,.mx-xs-3 {
        margin-left: 1rem!important
    }

    .mr-xs-3,.mx-xs-3 {
        margin-right: 1rem!important
    }

    .m-xs-4 {
        margin: 1.5rem!important
    }

    .mt-xs-4,.my-xs-4 {
        margin-top: 1.5rem!important
    }

    .mb-xs-4,.my-xs-4 {
        margin-bottom: 1.5rem!important
    }

    .ml-xs-4,.mx-xs-4 {
        margin-left: 1.5rem!important
    }

    .mr-xs-4,.mx-xs-4 {
        margin-right: 1.5rem!important
    }

    .m-xs-5 {
        margin: 3rem!important
    }

    .mt-xs-5,.my-xs-5 {
        margin-top: 3rem!important
    }

    .mb-xs-5,.my-xs-5 {
        margin-bottom: 3rem!important
    }

    .ml-xs-5,.mx-xs-5 {
        margin-left: 3rem!important
    }

    .mr-xs-5,.mx-xs-5 {
        margin-right: 3rem!important
    }

    .m-xs-6 {
        margin: 4.5rem!important
    }

    .mt-xs-6,.my-xs-6 {
        margin-top: 4.5rem!important
    }

    .mb-xs-6,.my-xs-6 {
        margin-bottom: 4.5rem!important
    }

    .ml-xs-6,.mx-xs-6 {
        margin-left: 4.5rem!important
    }

    .mr-xs-6,.mx-xs-6 {
        margin-right: 4.5rem!important
    }

    .m-xs-7 {
        margin: 5rem!important
    }

    .mt-xs-7,.my-xs-7 {
        margin-top: 5rem!important
    }

    .mb-xs-7,.my-xs-7 {
        margin-bottom: 5rem!important
    }

    .ml-xs-7,.mx-xs-7 {
        margin-left: 5rem!important
    }

    .mr-xs-7,.mx-xs-7 {
        margin-right: 5rem!important
    }

    .m-xs-8 {
        margin: 5.5rem!important
    }

    .mt-xs-8,.my-xs-8 {
        margin-top: 5.5rem!important
    }

    .mb-xs-8,.my-xs-8 {
        margin-bottom: 5.5rem!important
    }

    .ml-xs-8,.mx-xs-8 {
        margin-left: 5.5rem!important
    }

    .mr-xs-8,.mx-xs-8 {
        margin-right: 5.5rem!important
    }

    .m-xs-9 {
        margin: 6rem!important
    }

    .mt-xs-9,.my-xs-9 {
        margin-top: 6rem!important
    }

    .mb-xs-9,.my-xs-9 {
        margin-bottom: 6rem!important
    }

    .ml-xs-9,.mx-xs-9 {
        margin-left: 6rem!important
    }

    .mr-xs-9,.mx-xs-9 {
        margin-right: 6rem!important
    }

    .p-xs-0 {
        padding: 0!important
    }

    .pt-xs-0,.py-xs-0 {
        padding-top: 0!important
    }

    .pb-xs-0,.py-xs-0 {
        padding-bottom: 0!important
    }

    .pl-xs-0,.px-xs-0 {
        padding-left: 0!important
    }

    .pr-xs-0,.px-xs-0 {
        padding-right: 0!important
    }

    .p-xs-1 {
        padding: .25rem!important
    }

    .pt-xs-1,.py-xs-1 {
        padding-top: .25rem!important
    }

    .pb-xs-1,.py-xs-1 {
        padding-bottom: .25rem!important
    }

    .pl-xs-1,.px-xs-1 {
        padding-left: .25rem!important
    }

    .pr-xs-1,.px-xs-1 {
        padding-right: .25rem!important
    }

    .p-xs-2 {
        padding: .5rem!important
    }

    .pt-xs-2,.py-xs-2 {
        padding-top: .5rem!important
    }

    .pb-xs-2,.py-xs-2 {
        padding-bottom: .5rem!important
    }

    .pl-xs-2,.px-xs-2 {
        padding-left: .5rem!important
    }

    .pr-xs-2,.px-xs-2 {
        padding-right: .5rem!important
    }

    .p-xs-3 {
        padding: 1rem!important
    }

    .pt-xs-3,.py-xs-3 {
        padding-top: 1rem!important
    }

    .pb-xs-3,.py-xs-3 {
        padding-bottom: 1rem!important
    }

    .pl-xs-3,.px-xs-3 {
        padding-left: 1rem!important
    }

    .pr-xs-3,.px-xs-3 {
        padding-right: 1rem!important
    }

    .p-xs-4 {
        padding: 1.5rem!important
    }

    .pt-xs-4,.py-xs-4 {
        padding-top: 1.5rem!important
    }

    .pb-xs-4,.py-xs-4 {
        padding-bottom: 1.5rem!important
    }

    .pl-xs-4,.px-xs-4 {
        padding-left: 1.5rem!important
    }

    .pr-xs-4,.px-xs-4 {
        padding-right: 1.5rem!important
    }

    .p-xs-5 {
        padding: 3rem!important
    }

    .pt-xs-5,.py-xs-5 {
        padding-top: 3rem!important
    }

    .pb-xs-5,.py-xs-5 {
        padding-bottom: 3rem!important
    }

    .pl-xs-5,.px-xs-5 {
        padding-left: 3rem!important
    }

    .pr-xs-5,.px-xs-5 {
        padding-right: 3rem!important
    }

    .p-xs-6 {
        padding: 4.5rem!important
    }

    .pt-xs-6,.py-xs-6 {
        padding-top: 4.5rem!important
    }

    .pb-xs-6,.py-xs-6 {
        padding-bottom: 4.5rem!important
    }

    .pl-xs-6,.px-xs-6 {
        padding-left: 4.5rem!important
    }

    .pr-xs-6,.px-xs-6 {
        padding-right: 4.5rem!important
    }

    .p-xs-7 {
        padding: 5rem!important
    }

    .pt-xs-7,.py-xs-7 {
        padding-top: 5rem!important
    }

    .pb-xs-7,.py-xs-7 {
        padding-bottom: 5rem!important
    }

    .pl-xs-7,.px-xs-7 {
        padding-left: 5rem!important
    }

    .pr-xs-7,.px-xs-7 {
        padding-right: 5rem!important
    }

    .p-xs-8 {
        padding: 5.5rem!important
    }

    .pt-xs-8,.py-xs-8 {
        padding-top: 5.5rem!important
    }

    .pb-xs-8,.py-xs-8 {
        padding-bottom: 5.5rem!important
    }

    .pl-xs-8,.px-xs-8 {
        padding-left: 5.5rem!important
    }

    .pr-xs-8,.px-xs-8 {
        padding-right: 5.5rem!important
    }

    .p-xs-9 {
        padding: 6rem!important
    }

    .pt-xs-9,.py-xs-9 {
        padding-top: 6rem!important
    }

    .pb-xs-9,.py-xs-9 {
        padding-bottom: 6rem!important
    }

    .pl-xs-9,.px-xs-9 {
        padding-left: 6rem!important
    }

    .pr-xs-9,.px-xs-9 {
        padding-right: 6rem!important
    }

    .m-xs-auto {
        margin: auto!important
    }

    .mt-xs-auto,.my-xs-auto {
        margin-top: auto!important
    }

    .mb-xs-auto,.my-xs-auto {
        margin-bottom: auto!important
    }
}

@media (min-width: 768px) {
    .m-sm-0 {
        margin:0!important
    }

    .mt-sm-0,.my-sm-0 {
        margin-top: 0!important
    }

    .mb-sm-0,.my-sm-0 {
        margin-bottom: 0!important
    }

    .ml-sm-0,.mx-sm-0 {
        margin-left: 0!important
    }

    .mr-sm-0,.mx-sm-0 {
        margin-right: 0!important
    }

    .m-sm-1 {
        margin: .25rem!important
    }

    .mt-sm-1,.my-sm-1 {
        margin-top: .25rem!important
    }

    .mb-sm-1,.my-sm-1 {
        margin-bottom: .25rem!important
    }

    .ml-sm-1,.mx-sm-1 {
        margin-left: .25rem!important
    }

    .mr-sm-1,.mx-sm-1 {
        margin-right: .25rem!important
    }

    .m-sm-2 {
        margin: .5rem!important
    }

    .mt-sm-2,.my-sm-2 {
        margin-top: .5rem!important
    }

    .mb-sm-2,.my-sm-2 {
        margin-bottom: .5rem!important
    }

    .ml-sm-2,.mx-sm-2 {
        margin-left: .5rem!important
    }

    .mr-sm-2,.mx-sm-2 {
        margin-right: .5rem!important
    }

    .m-sm-3 {
        margin: 1rem!important
    }

    .mt-sm-3,.my-sm-3 {
        margin-top: 1rem!important
    }

    .mb-sm-3,.my-sm-3 {
        margin-bottom: 1rem!important
    }

    .ml-sm-3,.mx-sm-3 {
        margin-left: 1rem!important
    }

    .mr-sm-3,.mx-sm-3 {
        margin-right: 1rem!important
    }

    .m-sm-4 {
        margin: 1.5rem!important
    }

    .mt-sm-4,.my-sm-4 {
        margin-top: 1.5rem!important
    }

    .mb-sm-4,.my-sm-4 {
        margin-bottom: 1.5rem!important
    }

    .ml-sm-4,.mx-sm-4 {
        margin-left: 1.5rem!important
    }

    .mr-sm-4,.mx-sm-4 {
        margin-right: 1.5rem!important
    }

    .m-sm-5 {
        margin: 3rem!important
    }

    .mt-sm-5,.my-sm-5 {
        margin-top: 3rem!important
    }

    .mb-sm-5,.my-sm-5 {
        margin-bottom: 3rem!important
    }

    .ml-sm-5,.mx-sm-5 {
        margin-left: 3rem!important
    }

    .mr-sm-5,.mx-sm-5 {
        margin-right: 3rem!important
    }

    .m-sm-6 {
        margin: 4.5rem!important
    }

    .mt-sm-6,.my-sm-6 {
        margin-top: 4.5rem!important
    }

    .mb-sm-6,.my-sm-6 {
        margin-bottom: 4.5rem!important
    }

    .ml-sm-6,.mx-sm-6 {
        margin-left: 4.5rem!important
    }

    .mr-sm-6,.mx-sm-6 {
        margin-right: 4.5rem!important
    }

    .m-sm-7 {
        margin: 5rem!important
    }

    .mt-sm-7,.my-sm-7 {
        margin-top: 5rem!important
    }

    .mb-sm-7,.my-sm-7 {
        margin-bottom: 5rem!important
    }

    .ml-sm-7,.mx-sm-7 {
        margin-left: 5rem!important
    }

    .mr-sm-7,.mx-sm-7 {
        margin-right: 5rem!important
    }

    .m-sm-8 {
        margin: 5.5rem!important
    }

    .mt-sm-8,.my-sm-8 {
        margin-top: 5.5rem!important
    }

    .mb-sm-8,.my-sm-8 {
        margin-bottom: 5.5rem!important
    }

    .ml-sm-8,.mx-sm-8 {
        margin-left: 5.5rem!important
    }

    .mr-sm-8,.mx-sm-8 {
        margin-right: 5.5rem!important
    }

    .m-sm-9 {
        margin: 6rem!important
    }

    .mt-sm-9,.my-sm-9 {
        margin-top: 6rem!important
    }

    .mb-sm-9,.my-sm-9 {
        margin-bottom: 6rem!important
    }

    .ml-sm-9,.mx-sm-9 {
        margin-left: 6rem!important
    }

    .mr-sm-9,.mx-sm-9 {
        margin-right: 6rem!important
    }

    .p-sm-0 {
        padding: 0!important
    }

    .pt-sm-0,.py-sm-0 {
        padding-top: 0!important
    }

    .pb-sm-0,.py-sm-0 {
        padding-bottom: 0!important
    }

    .pl-sm-0,.px-sm-0 {
        padding-left: 0!important
    }

    .pr-sm-0,.px-sm-0 {
        padding-right: 0!important
    }

    .p-sm-1 {
        padding: .25rem!important
    }

    .pt-sm-1,.py-sm-1 {
        padding-top: .25rem!important
    }

    .pb-sm-1,.py-sm-1 {
        padding-bottom: .25rem!important
    }

    .pl-sm-1,.px-sm-1 {
        padding-left: .25rem!important
    }

    .pr-sm-1,.px-sm-1 {
        padding-right: .25rem!important
    }

    .p-sm-2 {
        padding: .5rem!important
    }

    .pt-sm-2,.py-sm-2 {
        padding-top: .5rem!important
    }

    .pb-sm-2,.py-sm-2 {
        padding-bottom: .5rem!important
    }

    .pl-sm-2,.px-sm-2 {
        padding-left: .5rem!important
    }

    .pr-sm-2,.px-sm-2 {
        padding-right: .5rem!important
    }

    .p-sm-3 {
        padding: 1rem!important
    }

    .pt-sm-3,.py-sm-3 {
        padding-top: 1rem!important
    }

    .pb-sm-3,.py-sm-3 {
        padding-bottom: 1rem!important
    }

    .pl-sm-3,.px-sm-3 {
        padding-left: 1rem!important
    }

    .pr-sm-3,.px-sm-3 {
        padding-right: 1rem!important
    }

    .p-sm-4 {
        padding: 1.5rem!important
    }

    .pt-sm-4,.py-sm-4 {
        padding-top: 1.5rem!important
    }

    .pb-sm-4,.py-sm-4 {
        padding-bottom: 1.5rem!important
    }

    .pl-sm-4,.px-sm-4 {
        padding-left: 1.5rem!important
    }

    .pr-sm-4,.px-sm-4 {
        padding-right: 1.5rem!important
    }

    .p-sm-5 {
        padding: 3rem!important
    }

    .pt-sm-5,.py-sm-5 {
        padding-top: 3rem!important
    }

    .pb-sm-5,.py-sm-5 {
        padding-bottom: 3rem!important
    }

    .pl-sm-5,.px-sm-5 {
        padding-left: 3rem!important
    }

    .pr-sm-5,.px-sm-5 {
        padding-right: 3rem!important
    }

    .p-sm-6 {
        padding: 4.5rem!important
    }

    .pt-sm-6,.py-sm-6 {
        padding-top: 4.5rem!important
    }

    .pb-sm-6,.py-sm-6 {
        padding-bottom: 4.5rem!important
    }

    .pl-sm-6,.px-sm-6 {
        padding-left: 4.5rem!important
    }

    .pr-sm-6,.px-sm-6 {
        padding-right: 4.5rem!important
    }

    .p-sm-7 {
        padding: 5rem!important
    }

    .pt-sm-7,.py-sm-7 {
        padding-top: 5rem!important
    }

    .pb-sm-7,.py-sm-7 {
        padding-bottom: 5rem!important
    }

    .pl-sm-7,.px-sm-7 {
        padding-left: 5rem!important
    }

    .pr-sm-7,.px-sm-7 {
        padding-right: 5rem!important
    }

    .p-sm-8 {
        padding: 5.5rem!important
    }

    .pt-sm-8,.py-sm-8 {
        padding-top: 5.5rem!important
    }

    .pb-sm-8,.py-sm-8 {
        padding-bottom: 5.5rem!important
    }

    .pl-sm-8,.px-sm-8 {
        padding-left: 5.5rem!important
    }

    .pr-sm-8,.px-sm-8 {
        padding-right: 5.5rem!important
    }

    .p-sm-9 {
        padding: 6rem!important
    }

    .pt-sm-9,.py-sm-9 {
        padding-top: 6rem!important
    }

    .pb-sm-9,.py-sm-9 {
        padding-bottom: 6rem!important
    }

    .pl-sm-9,.px-sm-9 {
        padding-left: 6rem!important
    }

    .pr-sm-9,.px-sm-9 {
        padding-right: 6rem!important
    }

    .m-sm-auto {
        margin: auto!important
    }

    .mt-sm-auto,.my-sm-auto {
        margin-top: auto!important
    }

    .mb-sm-auto,.my-sm-auto {
        margin-bottom: auto!important
    }
}

@media (min-width: 992px) {
    .m-md-0 {
        margin:0!important
    }

    .mt-md-0,.my-md-0 {
        margin-top: 0!important
    }

    .mb-md-0,.my-md-0 {
        margin-bottom: 0!important
    }

    .ml-md-0,.mx-md-0 {
        margin-left: 0!important
    }

    .mr-md-0,.mx-md-0 {
        margin-right: 0!important
    }

    .m-md-1 {
        margin: .25rem!important
    }

    .mt-md-1,.my-md-1 {
        margin-top: .25rem!important
    }

    .mb-md-1,.my-md-1 {
        margin-bottom: .25rem!important
    }

    .ml-md-1,.mx-md-1 {
        margin-left: .25rem!important
    }

    .mr-md-1,.mx-md-1 {
        margin-right: .25rem!important
    }

    .m-md-2 {
        margin: .5rem!important
    }

    .mt-md-2,.my-md-2 {
        margin-top: .5rem!important
    }

    .mb-md-2,.my-md-2 {
        margin-bottom: .5rem!important
    }

    .ml-md-2,.mx-md-2 {
        margin-left: .5rem!important
    }

    .mr-md-2,.mx-md-2 {
        margin-right: .5rem!important
    }

    .m-md-3 {
        margin: 1rem!important
    }

    .mt-md-3,.my-md-3 {
        margin-top: 1rem!important
    }

    .mb-md-3,.my-md-3 {
        margin-bottom: 1rem!important
    }

    .ml-md-3,.mx-md-3 {
        margin-left: 1rem!important
    }

    .mr-md-3,.mx-md-3 {
        margin-right: 1rem!important
    }

    .m-md-4 {
        margin: 1.5rem!important
    }

    .mt-md-4,.my-md-4 {
        margin-top: 1.5rem!important
    }

    .mb-md-4,.my-md-4 {
        margin-bottom: 1.5rem!important
    }

    .ml-md-4,.mx-md-4 {
        margin-left: 1.5rem!important
    }

    .mr-md-4,.mx-md-4 {
        margin-right: 1.5rem!important
    }

    .m-md-5 {
        margin: 3rem!important
    }

    .mt-md-5,.my-md-5 {
        margin-top: 3rem!important
    }

    .mb-md-5,.my-md-5 {
        margin-bottom: 3rem!important
    }

    .ml-md-5,.mx-md-5 {
        margin-left: 3rem!important
    }

    .mr-md-5,.mx-md-5 {
        margin-right: 3rem!important
    }

    .m-md-6 {
        margin: 4.5rem!important
    }

    .mt-md-6,.my-md-6 {
        margin-top: 4.5rem!important
    }

    .mb-md-6,.my-md-6 {
        margin-bottom: 4.5rem!important
    }

    .ml-md-6,.mx-md-6 {
        margin-left: 4.5rem!important
    }

    .mr-md-6,.mx-md-6 {
        margin-right: 4.5rem!important
    }

    .m-md-7 {
        margin: 5rem!important
    }

    .mt-md-7,.my-md-7 {
        margin-top: 5rem!important
    }

    .mb-md-7,.my-md-7 {
        margin-bottom: 5rem!important
    }

    .ml-md-7,.mx-md-7 {
        margin-left: 5rem!important
    }

    .mr-md-7,.mx-md-7 {
        margin-right: 5rem!important
    }

    .m-md-8 {
        margin: 5.5rem!important
    }

    .mt-md-8,.my-md-8 {
        margin-top: 5.5rem!important
    }

    .mb-md-8,.my-md-8 {
        margin-bottom: 5.5rem!important
    }

    .ml-md-8,.mx-md-8 {
        margin-left: 5.5rem!important
    }

    .mr-md-8,.mx-md-8 {
        margin-right: 5.5rem!important
    }

    .m-md-9 {
        margin: 6rem!important
    }

    .mt-md-9,.my-md-9 {
        margin-top: 6rem!important
    }

    .mb-md-9,.my-md-9 {
        margin-bottom: 6rem!important
    }

    .ml-md-9,.mx-md-9 {
        margin-left: 6rem!important
    }

    .mr-md-9,.mx-md-9 {
        margin-right: 6rem!important
    }

    .p-md-0 {
        padding: 0!important
    }

    .pt-md-0,.py-md-0 {
        padding-top: 0!important
    }

    .pb-md-0,.py-md-0 {
        padding-bottom: 0!important
    }

    .pl-md-0,.px-md-0 {
        padding-left: 0!important
    }

    .pr-md-0,.px-md-0 {
        padding-right: 0!important
    }

    .p-md-1 {
        padding: .25rem!important
    }

    .pt-md-1,.py-md-1 {
        padding-top: .25rem!important
    }

    .pb-md-1,.py-md-1 {
        padding-bottom: .25rem!important
    }

    .pl-md-1,.px-md-1 {
        padding-left: .25rem!important
    }

    .pr-md-1,.px-md-1 {
        padding-right: .25rem!important
    }

    .p-md-2 {
        padding: .5rem!important
    }

    .pt-md-2,.py-md-2 {
        padding-top: .5rem!important
    }

    .pb-md-2,.py-md-2 {
        padding-bottom: .5rem!important
    }

    .pl-md-2,.px-md-2 {
        padding-left: .5rem!important
    }

    .pr-md-2,.px-md-2 {
        padding-right: .5rem!important
    }

    .p-md-3 {
        padding: 1rem!important
    }

    .pt-md-3,.py-md-3 {
        padding-top: 1rem!important
    }

    .pb-md-3,.py-md-3 {
        padding-bottom: 1rem!important
    }

    .pl-md-3,.px-md-3 {
        padding-left: 1rem!important
    }

    .pr-md-3,.px-md-3 {
        padding-right: 1rem!important
    }

    .p-md-4 {
        padding: 1.5rem!important
    }

    .pt-md-4,.py-md-4 {
        padding-top: 1.5rem!important
    }

    .pb-md-4,.py-md-4 {
        padding-bottom: 1.5rem!important
    }

    .pl-md-4,.px-md-4 {
        padding-left: 1.5rem!important
    }

    .pr-md-4,.px-md-4 {
        padding-right: 1.5rem!important
    }

    .p-md-5 {
        padding: 3rem!important
    }

    .pt-md-5,.py-md-5 {
        padding-top: 3rem!important
    }

    .pb-md-5,.py-md-5 {
        padding-bottom: 3rem!important
    }

    .pl-md-5,.px-md-5 {
        padding-left: 3rem!important
    }

    .pr-md-5,.px-md-5 {
        padding-right: 3rem!important
    }

    .p-md-6 {
        padding: 4.5rem!important
    }

    .pt-md-6,.py-md-6 {
        padding-top: 4.5rem!important
    }

    .pb-md-6,.py-md-6 {
        padding-bottom: 4.5rem!important
    }

    .pl-md-6,.px-md-6 {
        padding-left: 4.5rem!important
    }

    .pr-md-6,.px-md-6 {
        padding-right: 4.5rem!important
    }

    .p-md-7 {
        padding: 5rem!important
    }

    .pt-md-7,.py-md-7 {
        padding-top: 5rem!important
    }

    .pb-md-7,.py-md-7 {
        padding-bottom: 5rem!important
    }

    .pl-md-7,.px-md-7 {
        padding-left: 5rem!important
    }

    .pr-md-7,.px-md-7 {
        padding-right: 5rem!important
    }

    .p-md-8 {
        padding: 5.5rem!important
    }

    .pt-md-8,.py-md-8 {
        padding-top: 5.5rem!important
    }

    .pb-md-8,.py-md-8 {
        padding-bottom: 5.5rem!important
    }

    .pl-md-8,.px-md-8 {
        padding-left: 5.5rem!important
    }

    .pr-md-8,.px-md-8 {
        padding-right: 5.5rem!important
    }

    .p-md-9 {
        padding: 6rem!important
    }

    .pt-md-9,.py-md-9 {
        padding-top: 6rem!important
    }

    .pb-md-9,.py-md-9 {
        padding-bottom: 6rem!important
    }

    .pl-md-9,.px-md-9 {
        padding-left: 6rem!important
    }

    .pr-md-9,.px-md-9 {
        padding-right: 6rem!important
    }

    .m-md-auto {
        margin: auto!important
    }

    .mt-md-auto,.my-md-auto {
        margin-top: auto!important
    }

    .mb-md-auto,.my-md-auto {
        margin-bottom: auto!important
    }
}

@media (min-width: 1200px) {
    .m-lg-0 {
        margin:0!important
    }

    .mt-lg-0,.my-lg-0 {
        margin-top: 0!important
    }

    .mb-lg-0,.my-lg-0 {
        margin-bottom: 0!important
    }

    .ml-lg-0,.mx-lg-0 {
        margin-left: 0!important
    }

    .mr-lg-0,.mx-lg-0 {
        margin-right: 0!important
    }

    .m-lg-1 {
        margin: .25rem!important
    }

    .mt-lg-1,.my-lg-1 {
        margin-top: .25rem!important
    }

    .mb-lg-1,.my-lg-1 {
        margin-bottom: .25rem!important
    }

    .ml-lg-1,.mx-lg-1 {
        margin-left: .25rem!important
    }

    .mr-lg-1,.mx-lg-1 {
        margin-right: .25rem!important
    }

    .m-lg-2 {
        margin: .5rem!important
    }

    .mt-lg-2,.my-lg-2 {
        margin-top: .5rem!important
    }

    .mb-lg-2,.my-lg-2 {
        margin-bottom: .5rem!important
    }

    .ml-lg-2,.mx-lg-2 {
        margin-left: .5rem!important
    }

    .mr-lg-2,.mx-lg-2 {
        margin-right: .5rem!important
    }

    .m-lg-3 {
        margin: 1rem!important
    }

    .mt-lg-3,.my-lg-3 {
        margin-top: 1rem!important
    }

    .mb-lg-3,.my-lg-3 {
        margin-bottom: 1rem!important
    }

    .ml-lg-3,.mx-lg-3 {
        margin-left: 1rem!important
    }

    .mr-lg-3,.mx-lg-3 {
        margin-right: 1rem!important
    }

    .m-lg-4 {
        margin: 1.5rem!important
    }

    .mt-lg-4,.my-lg-4 {
        margin-top: 1.5rem!important
    }

    .mb-lg-4,.my-lg-4 {
        margin-bottom: 1.5rem!important
    }

    .ml-lg-4,.mx-lg-4 {
        margin-left: 1.5rem!important
    }

    .mr-lg-4,.mx-lg-4 {
        margin-right: 1.5rem!important
    }

    .m-lg-5 {
        margin: 3rem!important
    }

    .mt-lg-5,.my-lg-5 {
        margin-top: 3rem!important
    }

    .mb-lg-5,.my-lg-5 {
        margin-bottom: 3rem!important
    }

    .ml-lg-5,.mx-lg-5 {
        margin-left: 3rem!important
    }

    .mr-lg-5,.mx-lg-5 {
        margin-right: 3rem!important
    }

    .m-lg-6 {
        margin: 4.5rem!important
    }

    .mt-lg-6,.my-lg-6 {
        margin-top: 4.5rem!important
    }

    .mb-lg-6,.my-lg-6 {
        margin-bottom: 4.5rem!important
    }

    .ml-lg-6,.mx-lg-6 {
        margin-left: 4.5rem!important
    }

    .mr-lg-6,.mx-lg-6 {
        margin-right: 4.5rem!important
    }

    .m-lg-7 {
        margin: 5rem!important
    }

    .mt-lg-7,.my-lg-7 {
        margin-top: 5rem!important
    }

    .mb-lg-7,.my-lg-7 {
        margin-bottom: 5rem!important
    }

    .ml-lg-7,.mx-lg-7 {
        margin-left: 5rem!important
    }

    .mr-lg-7,.mx-lg-7 {
        margin-right: 5rem!important
    }

    .m-lg-8 {
        margin: 5.5rem!important
    }

    .mt-lg-8,.my-lg-8 {
        margin-top: 5.5rem!important
    }

    .mb-lg-8,.my-lg-8 {
        margin-bottom: 5.5rem!important
    }

    .ml-lg-8,.mx-lg-8 {
        margin-left: 5.5rem!important
    }

    .mr-lg-8,.mx-lg-8 {
        margin-right: 5.5rem!important
    }

    .m-lg-9 {
        margin: 6rem!important
    }

    .mt-lg-9,.my-lg-9 {
        margin-top: 6rem!important
    }

    .mb-lg-9,.my-lg-9 {
        margin-bottom: 6rem!important
    }

    .ml-lg-9,.mx-lg-9 {
        margin-left: 6rem!important
    }

    .mr-lg-9,.mx-lg-9 {
        margin-right: 6rem!important
    }

    .p-lg-0 {
        padding: 0!important
    }

    .pt-lg-0,.py-lg-0 {
        padding-top: 0!important
    }

    .pb-lg-0,.py-lg-0 {
        padding-bottom: 0!important
    }

    .pl-lg-0,.px-lg-0 {
        padding-left: 0!important
    }

    .pr-lg-0,.px-lg-0 {
        padding-right: 0!important
    }

    .p-lg-1 {
        padding: .25rem!important
    }

    .pt-lg-1,.py-lg-1 {
        padding-top: .25rem!important
    }

    .pb-lg-1,.py-lg-1 {
        padding-bottom: .25rem!important
    }

    .pl-lg-1,.px-lg-1 {
        padding-left: .25rem!important
    }

    .pr-lg-1,.px-lg-1 {
        padding-right: .25rem!important
    }

    .p-lg-2 {
        padding: .5rem!important
    }

    .pt-lg-2,.py-lg-2 {
        padding-top: .5rem!important
    }

    .pb-lg-2,.py-lg-2 {
        padding-bottom: .5rem!important
    }

    .pl-lg-2,.px-lg-2 {
        padding-left: .5rem!important
    }

    .pr-lg-2,.px-lg-2 {
        padding-right: .5rem!important
    }

    .p-lg-3 {
        padding: 1rem!important
    }

    .pt-lg-3,.py-lg-3 {
        padding-top: 1rem!important
    }

    .pb-lg-3,.py-lg-3 {
        padding-bottom: 1rem!important
    }

    .pl-lg-3,.px-lg-3 {
        padding-left: 1rem!important
    }

    .pr-lg-3,.px-lg-3 {
        padding-right: 1rem!important
    }

    .p-lg-4 {
        padding: 1.5rem!important
    }

    .pt-lg-4,.py-lg-4 {
        padding-top: 1.5rem!important
    }

    .pb-lg-4,.py-lg-4 {
        padding-bottom: 1.5rem!important
    }

    .pl-lg-4,.px-lg-4 {
        padding-left: 1.5rem!important
    }

    .pr-lg-4,.px-lg-4 {
        padding-right: 1.5rem!important
    }

    .p-lg-5 {
        padding: 3rem!important
    }

    .pt-lg-5,.py-lg-5 {
        padding-top: 3rem!important
    }

    .pb-lg-5,.py-lg-5 {
        padding-bottom: 3rem!important
    }

    .pl-lg-5,.px-lg-5 {
        padding-left: 3rem!important
    }

    .pr-lg-5,.px-lg-5 {
        padding-right: 3rem!important
    }

    .p-lg-6 {
        padding: 4.5rem!important
    }

    .pt-lg-6,.py-lg-6 {
        padding-top: 4.5rem!important
    }

    .pb-lg-6,.py-lg-6 {
        padding-bottom: 4.5rem!important
    }

    .pl-lg-6,.px-lg-6 {
        padding-left: 4.5rem!important
    }

    .pr-lg-6,.px-lg-6 {
        padding-right: 4.5rem!important
    }

    .p-lg-7 {
        padding: 5rem!important
    }

    .pt-lg-7,.py-lg-7 {
        padding-top: 5rem!important
    }

    .pb-lg-7,.py-lg-7 {
        padding-bottom: 5rem!important
    }

    .pl-lg-7,.px-lg-7 {
        padding-left: 5rem!important
    }

    .pr-lg-7,.px-lg-7 {
        padding-right: 5rem!important
    }

    .p-lg-8 {
        padding: 5.5rem!important
    }

    .pt-lg-8,.py-lg-8 {
        padding-top: 5.5rem!important
    }

    .pb-lg-8,.py-lg-8 {
        padding-bottom: 5.5rem!important
    }

    .pl-lg-8,.px-lg-8 {
        padding-left: 5.5rem!important
    }

    .pr-lg-8,.px-lg-8 {
        padding-right: 5.5rem!important
    }

    .p-lg-9 {
        padding: 6rem!important
    }

    .pt-lg-9,.py-lg-9 {
        padding-top: 6rem!important
    }

    .pb-lg-9,.py-lg-9 {
        padding-bottom: 6rem!important
    }

    .pl-lg-9,.px-lg-9 {
        padding-left: 6rem!important
    }

    .pr-lg-9,.px-lg-9 {
        padding-right: 6rem!important
    }

    .m-lg-auto {
        margin: auto!important
    }

    .mt-lg-auto,.my-lg-auto {
        margin-top: auto!important
    }

    .mb-lg-auto,.my-lg-auto {
        margin-bottom: auto!important
    }
}

@media screen and (min-width: 1200px) {
    [data-fullheight=true] {
        height:100vh
    }
}

@media screen and (max-width: 991px) {
    .content .h1,.content h1 {
        font-size:44px
    }

    .content .h2,.content h2 {
        font-size: 32px
    }

    .content .h3,.content h3 {
        font-size: 24px
    }

    .content .h4,.content h4 {
        font-size: 18px
    }

    .content .h5,.content h5 {
        font-size: 16px
    }

    .content .h6,.content h6 {
        font-size: 15px
    }
}

@media screen and (max-width: 1199px) {
    .lqd-sticky-bg,.lqd-sticky-bg-wrap {
        height:100%;
        min-height: 0;
        max-height: none;
        position: absolute;
        top: 0;
        left: 0
    }
}

@media screen and (min-width: 1200px) and (max-width:1440px) {
    .header-side {
        width:250px
    }

    .header-side .mainbar-wrap {
        padding: 12vh 25px
    }
}

@media screen and (max-width: 767px) {
    .ld-module-cart .ld-cart-contents,.ld-module-cart .ld-module-dropdown {
        width:100%
    }

    .ld-cart-contents .empty,.ld-cart-foot,.ld-cart-head,.ld-cart-product {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media screen and (min-width: 992px) and (max-width:1199px) {
    .mainbar-row>[class^=col] {
        padding-left:15px!important;
        padding-right: 15px!important
    }
}

@media screen and (max-width: 782px) {
    .admin-bar .is-stuck {
        top:46px
    }
}

@media screen and (max-width: 600px) {
    .admin-bar .is-stuck {
        top:0
    }
}

@media screen and (min-width: 1200px) {
    .lqd-sticky-footer-sentinel.height-applied+.footer-stuck {
        margin:0;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden
    }

    .lqd-sticky-footer-sentinel.height-applied+.footer-stuck.is-inview {
        opacity: 1;
        visibility: visible
    }

    .site-boxed-layout .lqd-sticky-footer-sentinel.height-applied+.footer-stuck {
        margin: 0 auto
    }
}

@media screen and (max-width: 1199px) {
    .lqd-sticky-footer-sentinel {
        display:none
    }
}

@media screen and (max-width: 767px) {
    .titlebar-inner h1 {
        font-size:48px!important
    }
}

@media screen and (max-width: 479px) {
    .titlebar-inner h1 {
        font-size:38px!important
    }
}

@media screen and (min-width: 1200px) {
    .header-style-side #wrap {
        padding-left:375px
    }
}

@media screen and (min-width: 1200px) and (max-width:1440px) {
    .header-style-side #wrap {
        padding-left:250px
    }
}

@media screen and (min-width: 1200px) and (max-width:1580px) {
    .header-style-side .vc_row>.container {
        width:100%;
        padding-left: 15px;
        padding-right: 15px
    }

    .header-style-side.lqd-stack-initiated .vc_row .lqd-stack-section-inner>.container {
        width: 970px
    }
}

.vc_mobile [data-liquid-stack=true]>section {
    height: auto
}

@media all and (max-width: 992px) {
    .btn {
        white-space:normal
    }

    .btn-xsm>span {
        padding: .5em .8em
    }

    .btn-xsm.wide>span {
        padding-left: 1.5em;
        padding-right: 1.5em
    }

    .btn-sm>span {
        padding: .6em 1.2em
    }

    .btn-sm.wide>span {
        padding-left: 2.5em;
        padding-right: 2.5em
    }

    .btn-md>span {
        padding: 1em 2em
    }

    .btn-lg>span {
        padding: 1.15em 2.5em
    }

    .btn-lg.wide>span {
        padding-left: 3.3em;
        padding-right: 3.3em
    }

    .btn-xlg>span {
        padding: 1.3em 3.1em
    }

    .btn-xlg.wide>span {
        padding-left: 4.1em;
        padding-right: 4.1em
    }
}

@media screen and (max-width: 480px) {
    .accordion-expander {
        right:15px
    }

    .accordion-title a {
        padding-right: 50px
    }
}

@media screen and (max-width: 1199px) {
    .iconbox[data-plugin-animated-icon] object,.iconbox[data-plugin-animated-icon]>svg {
        opacity:1
    }
}

@media screen and (min-width: 992px) {
    .tabs-nav-items-bordered .tabs-nav li+li {
        border-left-width:0
    }

    .tabs-nav-side {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .tabs-nav-side .tabs-nav {
        margin-bottom: 0
    }
}

@media screen and (max-width: 991px) {
    .tabs-nav-side .tabs-content,.tabs-nav-side .tabs-nav {
        width:100%
    }

    .tabs-nav-side .tabs-nav {
        margin-bottom: 0
    }

    .tabs-nav-side .tabs-content {
        padding-left: 0;
        padding-right: 0
    }
}

@media screen and (max-width: 767px) {
    .tabs-nav {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .tabs-nav li {
        margin-left: 0!important;
        margin-right: 0!important
    }

    .tabs-nav a {
        width: 100%
    }

    .tabs-nav-items-bordered .tabs-nav li+li {
        border-top-width: 0
    }
}

@media (max-width: 991px) {
    .ld-sf--button-inline form {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .ld-sf--button-inline form p {
        padding-right: 0!important
    }
}

@media (max-width: 767px) {
    .ld-sf--button-inside.ld-sf--button-bordered [type=email],.ld-sf--button-inside.ld-sf--button-bordered [type=text],.ld-sf--button-inside.ld-sf--button-solid [type=email],.ld-sf--button-inside.ld-sf--button-solid [type=text] {
        padding-right:2.5em!important
    }
}

@media screen and (max-width: 1199px) {
    .pricing-table-colorful {
        margin-left:0;
        margin-right: 0
    }

    .pricing-table-colorful.featured {
        margin-top: 0
    }
}

@media screen and (min-width: 992px) {
    .is-last .testimonials-details-only .testimonial-info {
        -webkit-box-align:start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

@media screen and (max-width: 991px) {
    .testimonials-details .carousel-nav {
        text-align:center!important
    }
}

@media screen and (max-width: 767px) {
    .testimonial-avatar-topleft .avatar,.testimonial-avatar-topright .avatar {
        position:relative;
        top: auto;
        left: auto;
        -webkit-transform: none;
        transform: none
    }

    .testimonial-avatar-topleft.text-left .avatar,.testimonial-avatar-topleft.text-right .avatar,.testimonial-avatar-topright.text-left .avatar,.testimonial-avatar-topright.text-right .avatar {
        margin-right: 15px!important
    }

    .testimonial-avatar-topleft.text-center .avatar,.testimonial-avatar-topright.text-center .avatar {
        margin-bottom: 15px!important
    }

    .testimonials-quotes .carousel-nav {
        margin-top: 0!important;
        text-align: center!important
    }

    .testimonials-details-only .avatar {
        margin-bottom: .5em
    }

    .testimonials-details-only .testimonial-details {
        text-align: center!important;
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .testimonials-details-only .testimonial-info {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important;
        text-align: center!important
    }

    .testimonials-quote-only .testimonial-quote {
        text-align: center;
        font-size: 22px
    }
}

@media screen and (min-width: 1200px) {
    .fancy-title .carousel-nav {
        position:absolute;
        top: 50%;
        right: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

@media screen and (min-width: 992px) {
    .carousel-nav-line-between .flickity-prev-next-button.previous {
        margin-right:40px
    }

    .carousel-nav-line-between .flickity-prev-next-button.previous:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -25px;
        background-color: rgba(0,0,0,.1)
    }

    .carousel-nav-line-between.carousel-nav-vertical .flickity-prev-next-button.previous {
        margin-right: 0;
        margin-bottom: 45px
    }

    .carousel-nav-line-between.carousel-nav-vertical .flickity-prev-next-button.previous:after {
        width: 100%;
        height: 1px;
        top: auto;
        bottom: -25px;
        right: 0
    }
}

@media screen and (max-width: 991px) {
    .carousel-nav {
        margin-top:2em!important;
        position: relative!important;
        top: auto!important;
        left: auto!important;
        bottom: auto!important;
        right: auto!important;
        -webkit-transform: none!important;
        transform: none!important
    }

    .carousel-nav .flickity-button {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important;
        position: relative!important;
        top: auto!important;
        left: auto!important;
        bottom: auto!important;
        right: auto!important;
        -webkit-transform: none!important;
        transform: none!important;
        margin: 0 15px!important
    }

    .carousel-nav-right .carousel-nav {
        text-align: left!important
    }
}

@media screen and (max-width: 767px) {
    .carousel-item:not([class*=col-xs-]) {
        width:100%
    }
}

@media screen and (min-width: 992px) {
    .liquid-blog-item-split {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .liquid-blog-item-split .liquid-blog-item-inner,.liquid-blog-item-split .liquid-lp-media {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%
    }

    .liquid-blog-item-split .liquid-blog-item-inner {
        padding-left: 50px;
        padding-right: 50px
    }

    .liquid-blog-item-fullwidth .liquid-blog-item-inner {
        width: 70%
    }

    .flickity-equal-cells .carousel-item:not(:first-child) .liquid-blog-item-carousel {
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        height: 100%
    }

    .liquid-blog-item-square .liquid-lp-title {
        font-size: 33px
    }

    .col-md-6>.liquid-blog-item-square .liquid-blog-item-inner {
        padding-left: 45px;
        padding-right: 45px
    }

    .col-md-3>.liquid-blog-item-square .liquid-blog-item-inner {
        padding-bottom: 11%
    }

    .col-md-3>.liquid-blog-item-square .liquid-lp-title {
        font-size: 18px;
        line-height: 1.666em
    }
}

@media screen and (min-width: 992px) and (max-width:1199px) {
    .liquid-blog-item-masonry {
        min-height:400px!important
    }
}

@media screen and (max-width: 1199px) {
    .ld-lp-carousel-filterable .filter-list {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap
    }

    .ld-lp-carousel-filterable .filter-list li {
        width: 100%;
        text-align: left
    }

    .ld-lp-carousel-filterable .carousel-items {
        margin-top: 2em
    }
}

@media screen and (max-width: 991px) {
    .liquid-blog-item-split {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .liquid-blog-item-fullwidth {
        padding: 0!important
    }

    .liquid-blog-item-fullwidth .liquid-blog-item-inner {
        position: relative;
        top: auto;
        left: auto
    }

    .liquid-lp-sp-block.liquid-lp-sp-instagram h3 {
        margin-left: 0;
        margin-right: 0
    }
}

@media screen and (min-width: 992px) {
    .liquid-filter-items {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .liquid-filter-items.align-items-center .filter-list {
        margin-bottom: 0
    }

    .filter-list-inline li+li {
        margin-left: 1.45em
    }
}

@media screen and (max-width: 991px) {
    .liquid-filter-items {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .liquid-filter-items-inner {
        width: 100%
    }

    .liquid-filter-items-inner>* {
        margin-bottom: 30px!important
    }

    .liquid-filter-items-inner .filter-list {
        width: 100%
    }

    .liquid-filter-items-inner+.carousel-nav {
        margin-top: 0!important
    }

    .liquid-filter-items-label+.filter-list {
        margin-left: 0
    }

    .filter-list-inline li {
        margin: 0 .5em
    }
}

@media screen and (max-width: 767px) {
    .filter-list {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .filter-list li {
        margin-bottom: 1em;
        text-align: center
    }
}

@media screen and (min-width: 992px) {
    .fancy-box-offer {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .fancy-box-offer .fancy-box-cell:first-child {
        -webkit-box-flex: 2;
        -ms-flex-positive: 2;
        flex-grow: 2
    }

    .fancy-box-offer .fancy-box-cell:last-child {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }
}

@media screen and (max-width: 991px) {
    .fancy-box-offer {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .fancy-box-offer .fancy-box-cell {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin-bottom: 1.5em
    }

    .fancy-box-offer .fancy-box-cell:before {
        content: attr(data-text);
        margin-bottom: .5em
    }

    .fancy-box-offer .fancy-box-header {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }

    .fancy-box-offer-header {
        padding-bottom: 0
    }

    .fancy-box-offer-header .fancy-box-cell:not(.fancy-box-header) {
        display: none
    }

    .fancy-box-offer-header .fancy-box-header {
        margin-bottom: 0
    }
}

@media screen and (max-width: 767px) {
    .fancy-box-overlay {
        padding-bottom:90%
    }

    .fancy-box-travel.fancy-box-wide {
        padding-bottom: 50%
    }

    .fancy-box-offer .fancy-box-cell {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%
    }

    .fancy-box-offer .fancy-box-header {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
}

@media screen and (min-width: 992px) and (max-width:1199px) {
    .liquid-form-reservation .input-container {
        -ms-flex-preferred-size:50%;
        flex-basis: 50%
    }

    .liquid-form-reservation .input-container:nth-last-child(2) {
        padding-left: 0;
        border: none
    }
}

@media screen and (max-width: 991px) {
    .liquid-form-reservation .input-container {
        -ms-flex-preferred-size:100%;
        flex-basis: 100%;
        padding: 0;
        border: none
    }
}

@media screen and (max-width: 991px) {
    .liquid-ig-feed .liquid-ig-feed-list>li {
        -ms-flex-preferred-size:33.3333333333%;
        flex-basis: 33.3333333333%
    }

    [data-list-columns="1"] .liquid-ig-feed-list>li,[data-list-columns="2"] .liquid-ig-feed-list>li {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
}

@media screen and (max-width: 767px) {
    .liquid-ig-feed .liquid-ig-feed-list>li {
        -ms-flex-preferred-size:50%;
        flex-basis: 50%
    }

    [data-list-columns="1"] .liquid-ig-feed-list>li,[data-list-columns="2"] .liquid-ig-feed-list>li {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
}

@media screen and (min-width: 992px) {
    .liquid-img-group-browser {
        margin-left:3.5em
    }

    .liquid-img-group-browser figure {
        height: 91%;
        top: 9%
    }

    .liquid-img-group-content.content-fixed-left,.liquid-img-group-content.content-fixed-right {
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl
    }

    .liquid-img-group-content.content-fixed-left p,.liquid-img-group-content.content-fixed-right p {
        margin-bottom: 0
    }

    .liquid-img-group-content.content-fixed-left {
        margin-right: 1.5em
    }

    .liquid-img-group-content.content-fixed-right {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-left: 1.5em
    }
}

@media screen and (max-width: 991px) {
    .liquid-img-group-img-container {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .liquid-img-group-content {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin-top: 1em
    }

    .liquid-img-group-content.content-floated-mid,.liquid-img-group-content.content-floated-mid-left,.liquid-img-group-content.content-floated-mid-right {
        margin: 0;
        left: 50%
    }

    .liquid-img-group-content.content-floated-mid-left>.btn,.liquid-img-group-content.content-floated-mid-right>.btn,.liquid-img-group-content.content-floated-mid>.btn {
        margin-left: 0!important;
        margin-right: 0!important
    }

    .liquid-img-group-browser {
        margin-bottom: 2em
    }

    .liquid-img-group-browser .liquid-img-group-img-container {
        margin-bottom: 1.5em
    }

    .liquid-img-group-browser .liquid-img-group-url {
        height: 7.5%
    }
}

@media screen and (max-width: 767px) {
    .liquid-img-group-browser .liquid-img-group-url {
        display:none
    }
}

@media screen and (max-width: 991px) {
    .masonry-item:not([class*=col-sm-]) {
        width:100%
    }
}

@media screen and (max-width: 767px) {
    .masonry-item:not([class*=col-xs-]) {
        width:100%
    }
}

@media screen and (max-width: 479px) {
    .ld-tm-circ .ld-tm-avatar img {
        width:50px
    }
}

@media screen and (min-width: 1200px) {
    .carousel-item .pf-hover-shadow {
        margin-bottom:50px
    }
}

@media screen and (max-width: 1200px) {
    .pf-info hr {
        display:none
    }
}

@media screen and (min-width: 992px) {
    .pf-single-header.bg-solid {
        padding-left:50px;
        padding-right: 50px
    }

    .pf-single-header.pull-up {
        margin-top: -60px
    }
}

@media screen and (min-width: 1200px) and (max-width:1440px) {
    .has-sidebar .blog-single-content.expanded {
        margin-left:-14%
    }
}

@media screen and (min-width: 1200px) {
    .blog-single-cover[data-fullheight=true] {
        height:87vh
    }

    .blog-single-cover[data-fullheight=true] .blog-single-media {
        height: 100%
    }

    .blog-single-cover[data-fullheight=true] .cover-carousel,.blog-single-cover[data-fullheight=true] .cover-carousel .carousel-item,.blog-single-cover[data-fullheight=true] .cover-carousel .carousel-item-inner,.blog-single-cover[data-fullheight=true] .cover-carousel .carousel-items,.blog-single-cover[data-fullheight=true] .cover-carousel .flickity-viewport {
        height: 100%!important
    }

    .blog-single-cover .blog-single-details {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    .blog-single-cover.spaced {
        margin-left: 50px;
        margin-right: 50px
    }

    .blog-single-content.expanded {
        padding-left: calc(26%);
        padding-right: calc(26%);
        margin-right: calc(-26%);
        margin-left: calc(-26%)
    }

    .post-has-no-content .blog-single-content.pull-up {
        margin-top: 0
    }

    .blog-single-content .stretch-to-sides {
        margin-left: -26%;
        margin-right: -26%
    }

    .blog-single-content .fullwidth figcaption {
        width: 40%
    }

    .blog-single-footer,.post-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .blog-single-footer>span,.blog-single-footer>ul {
        max-width: 50%
    }

    .post-nav .nav-next,.post-nav .nav-previous {
        max-width: 40%
    }

    .has-sidebar .blog-single-content.expanded {
        padding-right: 13%;
        padding-left: 13%;
        margin-right: -13%;
        margin-left: -13%
    }

    .blog-single-post-has-not-thumbnail .blog-single-content.pull-up {
        margin-top: 0;
        padding-top: 5em
    }

    .blog-single-post-has-not-thumbnail .lqd-main-header-default+.content .blog-single-content.pull-up {
        padding: 0;
        margin-left: 0;
        margin-right: 0
    }
}

@media screen and (min-width: 992px) {
    .post-meta {
        margin-bottom:3.75em
    }

    .blog-single-details-extra h3 {
        margin-bottom: 2em
    }

    .has-sidebar .blog-single-content .fullwidth {
        width: 100%;
        right: 0;
        left: 0;
        margin-left: 0;
        margin-right: 0
    }

    .has-sidebar .blog-single-content .fullwidth figcaption {
        width: 70%
    }

    .has-sidebar .blog-single-content .stretch-to-sides {
        margin-left: -13%;
        margin-right: -13%
    }

    .has-sidebar .blog-single-content.expanded .fullwidth {
        width: 126%;
        right: -13%;
        left: -13%;
        margin-right: -13%
    }
}

@media screen and (max-width: 991px) {
    .blog-single-details-extra .carousel-thumbs .carousel-item {
        width:33.3333333333%
    }

    .post-meta {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .post-meta>span {
        margin: 0 0 1em!important
    }

    .post-meta>span:last-child {
        margin: 0!important
    }

    .blog-single-footer,.post-nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .blog-single-cover.spaced {
        margin-left: 15px;
        margin-right: 15px
    }
}

@media screen and (max-width: 767px) {
    .blog-single-details-extra .carousel-thumbs .carousel-item {
        width:50%
    }
}

@media screen and (max-width: 479px) {
    .pingback .comment-meta {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .pingback time {
        margin: 0 0 1em
    }
}

@media screen and (max-width: 1199px) {
    .blog-single-default .blog-single-cover {
        margin-bottom:2.5em
    }

    .blog-single-default .blog-single-media {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0
    }

    .blog-single-default .blog-single-details {
        padding: 10em 0
    }
}

@media screen and (min-width: 1200px) {
    .blog-single-image-left:not(.has-sidebar) .blog-single-cover {
        float:left;
        width: 470px;
        margin-left: -26%;
        margin-right: 17%
    }

    .blog-single-image-left:not(.has-sidebar) .blog-single-details {
        margin-right: -26%
    }
}

@media screen and (min-width: 1200px) {
    .blog-single-cover-fade .post-meta {
        margin-bottom:0
    }
}

@media screen and (min-width: 1200px) {
    .ld-fancy-heading [data-split-text] {
        visibility:hidden
    }

    .ld-fancy-heading [data-split-text].split-text-applied {
        visibility: visible
    }
}

@media screen and (min-width: 992px) {
    .ld-shop-banner-style1 {
        padding-bottom:90.091%
    }

    .ld-shop-banner-style1 .ld-shop-banner-carousel-main,.ld-shop-banner-style1 .ld-shop-banner-carousel-main .carousel-items,.ld-shop-banner-style1 .ld-shop-banner-container,.ld-shop-banner-style1 .ld-shop-banner-image,.ld-shop-banner-style1 .ld-shop-banner-inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0
    }

    .ld-shop-banner-style1 .ld-shop-banner-carousel-main .flickity-viewport {
        height: 100%!important
    }

    .ld-shop-banner-style1 .ld-shop-banner-carousel-main .carousel-item,.ld-shop-banner-style1 .ld-shop-banner-carousel-main .carousel-item-inner {
        height: 100%
    }
}

@media screen and (max-width: 991px) {
    .ld-shop-banner-style1 .liquid-overlay-link {
        bottom:50%;
        height: auto;
        -webkit-transform: none;
        transform: none
    }

    .ld-shop-banner-style1 .ld-shop-banner-image {
        -webkit-transition: -webkit-box-shadow .3s;
        transition: -webkit-box-shadow .3s;
        transition: box-shadow .3s;
        transition: box-shadow .3s,-webkit-box-shadow .3s
    }

    .ld-shop-banner-style1 .ld-shop-banner-carousel-nav {
        width: 100%;
        height: auto;
        margin-top: 1em;
        position: relative;
        bottom: auto;
        right: auto;
        -webkit-transform: none;
        transform: none
    }

    .ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item {
        width: 25%;
        opacity: 1;
        visibility: visible;
        -webkit-transform: none;
        transform: none
    }

    .ld-shop-banner-style1:hover .ld-shop-banner-image {
        -webkit-box-shadow: 0 40px 60px rgba(0,0,0,.1);
        box-shadow: 0 40px 60px rgba(0,0,0,.1)
    }

    .ld-shop-banner-style1:hover .ld-shop-banner-inner {
        -webkit-box-shadow: none;
        box-shadow: none
    }
}

@media screen and (max-width: 767px) {
    .ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item {
        width:33.3333333333%!important
    }
}

@media screen and (min-width: 992px) {
    .featherlight-content>iframe.featherlight-inner {
        min-width:850px
    }
}

@media screen and (max-width: 991px) {
    [class*=vc_col-md] .ld-pb-top:after {
        content:none
    }
}

@media screen and (max-width: 767px) {
    [class*=vc_col-sm] .ld-pb-top:after {
        content:none
    }
}

@media screen and (max-width: 767px) {
    .one-roadmap {
        padding-left:25px;
        padding-right: 25px
    }

    .one-roadmap:before {
        top: 15px;
        right: 15px;
        left: 15px;
        bottom: 15px
    }

    .one-roadmap-info {
        max-width: 85%
    }
}

@media screen and (max-width: 991px) {
    .countdown .countdown-section {
        -ms-flex-preferred-size:45%;
        flex-basis: 45%;
        margin: .2352941176em 0
    }

    .countdown .countdown-section:nth-child(3)+.countdown-sep {
        display: none
    }
}

@media screen and (max-width: 480px) {
    .countdown .countdown-section {
        -ms-flex-preferred-size:100%;
        flex-basis: 100%
    }

    .countdown .countdown-sep {
        display: none
    }
}

@media screen and (max-width: 767px) {
    .one-bullet-list .inline-nav {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .one-bullet-list .inline-nav li {
        margin-left: 0!important;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 50%
    }
}

@media screen and (min-width: 992px) {
    .lqd-promo-alt .lqd-promo-cat {
        margin-left:-100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .lqd-promo-alt .lqd-promo-cat:before {
        margin-left: 1em;
        margin-right: 0
    }

    .lqd-promo-alt .lqd-promo-content {
        width: 25%;
        text-align: right;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .lqd-promo-alt .lqd-promo-content h2 {
        margin-right: 0;
        margin-left: -100%
    }

    .lqd-promo-alt .lqd-promo-img {
        width: 75%
    }

    .lqd-promo-alt .lqd-promo-img-inner {
        margin-left: -10%
    }

    .lqd-promo-reverse .lqd-promo-inner {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .lqd-promo-reverse.lqd-promo-alt .lqd-promo-cat {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-left: 0;
        margin-right: -100%
    }

    .lqd-promo-reverse.lqd-promo-alt .lqd-promo-cat:before {
        margin-left: 0;
        margin-right: 1em
    }

    .lqd-promo-reverse.lqd-promo-alt .lqd-promo-content {
        text-align: left;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .lqd-promo-reverse.lqd-promo-alt .lqd-promo-content h2 {
        margin-left: 0;
        margin-right: -100%
    }

    .lqd-promo-reverse.lqd-promo-alt .lqd-promo-img-inner {
        margin-left: 0;
        margin-right: -10%
    }
}

@media screen and (min-width: 1200px) {
    .lqd-promo-inner {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .lqd-promo-alt .lqd-promo-content h2 {
        font-size: 72px
    }

    .lqd-promo-reverse .lqd-promo-cat {
        margin-right: 0;
        margin-left: 15px
    }

    .lqd-promo-reverse .lqd-promo-content {
        padding-left: 0;
        padding-right: 15px
    }

    .lqd-promo-reverse:not(.lqd-promo-alt) .lqd-promo-content {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        text-align: right
    }
}

@media screen and (min-width: 1200px) and (max-width:1580px) {
    .header-style-side.lqd-stack-initiated .lqd-promo-alt .lqd-promo-content h2 {
        font-size:60px
    }
}

@media screen and (max-width: 1199px) {
    .lqd-promo-img {
        width:58.75%
    }

    .lqd-promo-content {
        padding-top: 50px;
        padding-bottom: 0
    }

    .lqd-promo-content h2 {
        font-size: 100px
    }

    .lqd-promo-alt .lqd-promo-content h2 {
        font-size: 60px
    }
}

@media screen and (max-width: 991px) {
    .lqd-promo-cat {
        -webkit-writing-mode:inherit;
        -ms-writing-mode: inherit;
        writing-mode: inherit
    }

    .lqd-promo-cat ul {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        margin: 0 0 15px
    }

    .lqd-promo-cat,.lqd-promo-content,.lqd-promo-img {
        width: 100%
    }

    .lqd-promo-cat {
        margin-right: 0
    }

    .lqd-promo-img-inner {
        -webkit-box-shadow: 0 15px 30px rgba(0,0,0,.15);
        box-shadow: 0 15px 30px rgba(0,0,0,.15)
    }

    .lqd-promo-inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .lqd-promo-content {
        padding-top: 30px;
        padding-left: 0;
        padding-right: 0
    }

    .lqd-promo-content h2 {
        margin: 0 0 .25em;
        font-size: 62px
    }
}

@media screen and (max-width: 767px) {
    .lqd-promo-dynamic-shape {
        display:none
    }

    .lqd-promo-wrap {
        margin-top: 0;
        margin-bottom: 30px
    }

    .lqd-promo-content {
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .lqd-promo-content h2 {
        font-size: 10vw
    }

    .lqd-promo-cat::before {
        content: none!important
    }
}

@media screen and (min-width: 1200px) {
    .lqd-page-frame {
        position:absolute;
        z-index: 3;
        background-color: var(--color-primary)
    }

    .lqd-page-frame[data-orientation=h] {
        left: 0;
        width: 100%;
        height: 20px
    }

    .lqd-page-frame[data-orientation=v] {
        top: 0;
        width: 20px;
        height: 100%
    }

    .lqd-page-frame-top {
        top: 0;
        -webkit-transform-origin: center top;
        transform-origin: center top
    }

    .lqd-page-frame-right {
        right: 0;
        -webkit-transform-origin: right center;
        transform-origin: right center
    }

    .lqd-page-frame-bottom {
        bottom: 0;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom
    }

    .lqd-page-frame-left {
        left: 0;
        -webkit-transform-origin: left center;
        transform-origin: left center
    }

    .page-has-frame #wrap {
        padding: 20px
    }

    .page-has-frame .is-stuck {
        left: 20px;
        right: 20px
    }

    .page-has-frame .megamenu.megamenu-fullwidth .nav-item-children {
        width: calc(100vw - 40px);
        left: calc(50% + 10px)!important;
        right: calc(50% + 10px)!important;
        margin-left: calc(-50vw + 10px)!important;
        margin-right: calc(-50vw + 10px)!important
    }

    .page-has-frame .megamenu.megamenu-fullwidth .megamenu-container {
        width: calc(100vw - 40px)!important
    }
}

.heading1 {
    font-size: 50px
}

.heading2 {
    font-size: 44px
}

.heading3 {
    font-size: 35px
}

.heading4 {
    font-size: 26px
}

.heading5 {
    font-size: 20px
}

.heading6 {
    font-size: 18px
}

.casestudy-slider,.casestudy-slider .carousel-item,.casestudy-slider .carousel-items {
    height: 100%
}

.flickity-enabled.is-draggable .flickity-viewport {
    min-height: 100%
}

.pagination {
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%
}

.pagination ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: 0 0
}

.pagination li {
    display: inline;
    margin: 0;
    padding: 0
}

.pagination li a {
    border: 1px solid #e2e2e2;
    color: #242424;
    text-decoration: none;
    display: inline-block;
    transition: all .2s ease 0;
    height: 40px;
    line-height: 40px;
    padding: 0;
    text-align: center;
    width: 40px;
    background-color: #fff
}

.pagination li.active a {
    background-color: var(--color-primary);
    color: #fff;
    border-color: var(--color-primary)
}

.pagination li a.active,.pagination li a:hover {
    background-color: var(--color-primary);
    color: #fff
}

body.scheme-light header .mainbar-wrap:not(.is-stuck) .main-nav>li>a {
    color: #000
}

body.scheme-light header .mainbar-wrap:not(.is-stuck) .phone-number span {
    color: #000
}

body.scheme-light header .mainbar-wrap .phone-number span {
    font-weight: 600
}

body.scheme-light header .mainbar-wrap:not(.is-stuck) .navbar-brand .navbar-brand-inner .logo-default {
    display: none
}

body.scheme-light header .mainbar-wrap:not(.is-stuck) .navbar-brand .navbar-brand-inner .logo-dark {
    display: block;
    visibility: visible;
    opacity: 1;
    position: relative
}

body.scheme-dark header .mainbar-wrap:not(.is-stuck) .phone-number span {
    color: #fff
}

body.scheme-dark header .mainbar-wrap .phone-number span {
    font-weight: 600
}

body.scheme-dark header .mainbar-wrap .phone-number .iconbox-icon-container {
    width: 38px;
    height: 38px;
    background-color: rgba(255,255,255,.3);
    border-radius: 100%;
    text-align: center;
    line-height: 36px;
    margin-right: 0
}

.megamenu-column .menu-item-description {
    font-size: 14px
}

body.scheme-dark .titlebar .titlebar-scroll-link i,body.scheme-dark .titlebar h1,body.scheme-dark .titlebar p.page-description {
    color: #fff
}

body.scheme-dark header#layout-header .btn.border-thin>span {
    padding: .6em 1em
}

header.nav-dark .main-nav>li>a {
    color: rgba(0,0,0,.75)
}

header.nav-dark .mainbar-wrap:not(.is-stuck) .phone-number span {
    color: rgba(0,0,0,.75)
}

body.scheme-light .titlebar-inner h1 {
    font-weight: 700
}

header .lqd-custom-menu li {
    display: block;
    font-size: 16px
}

header .lqd-custom-menu li a:hover .menu-item-title {
    text-decoration: underline
}

header .lqd-custom-menu li .menu-item-title {
    margin-bottom: 6px;
    color: var(--color-primary);
    font-weight: 600
}

.megamenu .megamenu-column:not(.group-dark) .lqd-custom-menu li a {
    padding: 12px;
    margin-left: -12px
}

.megamenu .lqd-custom-menu li:hover a {
    background-color: #f3f6f9
}

.megamenu .lqd-custom-menu li .menu-item-description {
    color: #000;
    text-decoration: none
}

.megamenu .group-dark .megamenu-heading {
    margin-bottom: 30px
}

.megamenu .group-dark .lqd-custom-menu li {
    margin-bottom: 18px
}

.megamenu .group-dark .menu-item-title {
    color: var(--color-primary)
}

footer.main-footer .lqd-custom-menu li {
    margin-bottom: 2px
}

footer.main-footer .iconbox .iconbox-icon-container img[src$=".svg"] {
    width: 30px
}

footer.main-footer .iconbox-inline {
    margin-bottom: 1.35em
}

footer#layout-footer {
    border-top: 1px solid #e5e8ec
}

footer .company-address * {
    font-size: 1em
}

#awards-recognitions .fancy-box-contents h3 {
    font-size: 1.2rem;
    margin-bottom: 10px
}

#awards-recognitions .flickity-viewport {
    overflow: visible
}

#awards-recognitions button.flickity-button.flickity-prev-next-button.next {
    right: 0
}

#awards-recognitions .awards-link {
    color: var(--color-primary)
}

#awards-recognitions #awards-recognitions-footer {
    font-size: 19px
}

#awards-recognitions .fancy-box-contents .fancy-box-info {
    font-size: 1em
}

#testimonials .testimonial-content {
    font-size: 2.2em;
    line-height: 1.5;
    font-weight: 700;
    color: #000
}

#testimonials .clutch-rating .col:first-child {
    position: relative
}

#testimonials .clutch-rating .col:first-child:before {
    content: '';
    position: absolute;
    right: 0;
    height: 67%;
    width: 1px;
    background: #e0e0e0;
    top: 6px
}

body.page-home .header-module .iconbox.phone-number>span {
    font-size: 15px;
    font-weight: 600
}

.page-home .iconbox-contents-show-onhover ul {
    line-height: 21px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    font-size: 14px
}

.page-home .iconbox-contents-show-onhover ul li a {
    font-weight: 500
}

.page-home .iconbox-contents-show-onhover ul li a:hover {
    color: var(--color-link-hover)!important
}

.page-home .iconbox-contents-show-onhover {
    overflow: hidden
}

.page-home .iconbox-contents-show-onhover.iconbox-heading-sm h3 {
    width: min-intrinsic;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: table-caption;
    display: -ms-grid;
    -ms-grid-columns: min-content
}

.page-home #our-services .iconbox-icon-container>svg {
    width: 65px
}

.page-home #our-services .iconbox:hover .blockchain-development-icon .blockchain-development-icon-0,.page-home #our-services .iconbox:hover .blockchain-development-icon .blockchain-development-icon-1,.page-home #our-services .iconbox:hover .blockchain-development-icon .blockchain-development-icon-2,.page-home #our-services .iconbox:hover .blockchain-development-icon .blockchain-development-icon-3,.page-home #our-services .iconbox:hover .game-app-development-icon .game-app-development-icon-0,.page-home #our-services .iconbox:hover .game-app-development-icon .game-app-development-icon-1,.page-home #our-services .iconbox:hover .game-app-development-icon .game-app-development-icon-2,.page-home #our-services .iconbox:hover .game-development-icon .game-development-icon-0,.page-home #our-services .iconbox:hover .mobile-app-development-icon .mobile-app-development-icon-01,.page-home #our-services .iconbox:hover .mobile-app-development-icon .mobile-app-development-icon-02 {
    stroke: #1e1666!important
}

.page-home #our-services .iconbox .iconbox-icon-container {
    margin-bottom: .3em
}

.hero-badges-wrap {
    position: absolute;
    bottom: 0;
    width: 100%
}

.hero-badges-wrap .hero-badges {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.hero-badges-wrap .badges-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left
}

.hero-badges-wrap .badges-row h4 {
    font-size: .75rem;
    font-weight: 500;
    margin: 0 30px 0 0;
    color: #fff
}

.hero-badges-wrap .badges-row .badges {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    padding: 0
}

.hero-badges-wrap .badges-row .badges li:not(:last-child) {
    margin-right: 30px
}

#hero-slider .carousel-item .carousel-item-inner {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

#hero-slider .slider-content {
    left: 0;
    width: 100%;
    z-index: 1
}

#hero-slider,#hero-slider .bg-cover {
    width: 100%
}

.page-services #device-development .tabs-content .iconbox h3 {
    margin-bottom: 0
}

.page-services #device-development .tabs-nav-side .tabs-content {
    padding-left: 5em
}

.page-services #device-development .tabs-nav-side .tabs-content .iconbox {
    padding: 50px 10%
}

.page-services #device-development .tabs-nav-side .tabs-content .iconbox img {
    width: 200px
}

.page-services .casestudy-slider .fancy-box-contents {
    margin: 10%
}

.page-services #about-company .iconbox-shadow {
    width: 100%;
    padding: 30px 5%;
    text-align: center
}

.page-services #about-company .liquid-counter-text {
    text-transform: uppercase;
    font-size: 14px
}

.page-blog #content {
    background-color: #f5f7fa
}

.page-blog .liquid-blog-posts .liquid-blog-item-inner {
    position: relative;
    padding: 30px 30px 50px
}

.page-blog .liquid-blog-posts .liquid-blog-item-inner .liquid-lp-footer {
    position: absolute;
    bottom: 0;
    display: block;
    margin-bottom: 17px
}

.page-blog-post .post-author figure {
    width: 80px
}

.page-blog-post .blog-single-content img {
    margin-bottom: 0
}

.page-blog-post .blog-single-content h2 {
    font-size: 35px
}

.page-blog-post.h3-size-change .blog-single-content h3 {
    font-size: 28px
}

.page-work .work-wrap .work-item.theme-light h2,.page-work .work-wrap .work-item.theme-light p {
    color: #fff
}

.page-work .work-wrap .work-item.theme-light .btn,.page-work .work-wrap .work-item.theme-dark .btn {
    background-color: #fff
}

.page-work .work-wrap .work-item.theme-light .btn span,.page-work .work-wrap .work-item.theme-dark .btn span {
    color: #000
}

.page-work .work-wrap .work-item.theme-light .btn,.page-work .work-wrap .work-item.theme-dark .btn {
    border-color: #fff
}

.page-work .project-tags {
    margin: 8px 0 25px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    list-style: none
}

.page-work .project-tags li {
    margin: 16px 16px 0 0;
    padding: 5px 19px;
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    border-radius: 2px;
    border: 1px solid #fff
}

.contact-slider-images .carousel-item {
    width: 100%
}

.testimonial-box {
    background-color: #fff;
    padding: 2rem;
    text-align: center
}

.testimonial-box .client-image img {
    width: 150px;
    margin: auto;
    margin-bottom: 20px!important
}

.testimonial-box .client-info span {
    display: block
}

.testimonial-box .client-info span:first-child {
    font-weight: 900!important
}

.testimonial-box p {
    font-size: 18px;
    line-height: 28px
}

.testi-img img {
    max-width: 100%;
    border-radius: 100%
}

.testi-cli {
    display: inline-block;
    align-items: center
}

.testi-img {
    width: 75px;
    height: 75px;
    margin: auto
}

.testi-dis {
    display: inline-block
}

.single_testimonial blockquote {
    font-size: 27px
}

.single_testimonial .t-name span {
    font-size: 27px;
    font-weight: 400
}

.cd-testimonials-all {
    margin-left: -2%;
    margin-right: -2%
}

.cd-author .cd-author-info,.cd-author img {
    display: inline-block;
    vertical-align: middle
}

.cd-author-info li {
    list-style: none!important
}

.cd-author img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba(0,0,0,.3)
}

.cd-author .cd-author-info {
    text-align: left;
    line-height: 1.2;
    padding-left: 60px;
    margin: 0
}

.cd-author .cd-author-info li:first-child {
    font-size: 14px;
    font-size: .875rem;
    font-weight: 600;
    margin-bottom: 5px
}

.cd-author .cd-author-info li:last-child {
    font-size: 12px;
    font-size: .75rem;
    color: #6b6b70
}

@media only screen and (min-width: 768px) {
    .cd-author img {
        width:50px;
        height: 50px
    }

    .cd-author .cd-author-info {
        line-height: 1.4
    }

    .cd-author .cd-author-info li:first-child {
        font-size: 17px
    }

    .cd-author .cd-author-info li:last-child {
        font-size: 14px;
        font-size: .875rem
    }
}

.cd-see-all {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #252527;
    text-align: center;
    text-transform: uppercase;
    color: #6b6b70;
    font-weight: 700;
    font-size: 12px;
    font-size: .75rem;
    -webkit-transition: color,.2s;
    -moz-transition: color,.2s;
    transition: color,.2s
}

.no-touch .cd-see-all:hover {
    color: #79b6e4
}

.cd-testimonials-all .cd-testimonials-all-wrapper {
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%
}

.cd-testimonials-all .cd-testimonials-all-wrapper>ul {
    margin: 0;
    padding: 0
}

.cd-testimonials-all .cd-testimonials-all-wrapper>ul::after {
    clear: both;
    content: "";
    display: table
}

.cd-testimonials-all .cd-testimonials-item {
    margin-bottom: 2em;
    list-style: none!important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.cd-testimonials-all .quote {
    position: relative;
    background: #f5f7fb;
    color: #152331;
    padding: 2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6;
    margin-bottom: 1em;
    font-size: 1em
}

.cd-testimonials-all .quote::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 2em;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-top-color: #f3f4f5
}

.cd-testimonials-all .cd-author {
    text-align: left;
    margin-left: 1.5em;
    line-height: 1.2em
}

.cd-testimonials-all .cd-author img {
    box-shadow: 0 0 0 transparent;
    position: absolute
}

.cd-testimonials-all.is-visible {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity .3s 0s,visibility 0 0;
    -moz-transition: opacity .3s 0s,visibility 0 0;
    transition: opacity .3s 0s,visibility 0 0
}

.cd-testimonials-all.is-visible .cd-testimonials-all-wrapper {
    -webkit-overflow-scrolling: touch
}

.cd-testimonials-all.is-visible .cd-testimonials-item {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0) scale(1);
    -moz-transform: translate3d(0,0,0) scale(1);
    -ms-transform: translate3d(0,0,0) scale(1);
    -o-transform: translate3d(0,0,0) scale(1);
    transform: translate3d(0,0,0) scale(1)
}

@media only screen and (min-width: 768px) {
    .cd-testimonials-all .cd-testimonials-item {
        width:46%;
        margin: 0 2% 3em
    }

    .cd-testimonials-all .cd-author {
        margin-left: 1em
    }
}

@media only screen and (min-width: 1170px) {
    .cd-testimonials-all .cd-testimonials-item {
        width:30%;
        margin: 0 1.6666% 3em
    }
}

#department-list .all {
    background: #fff
}

#department-list .all:hover {
    border-color: #1f74fb
}

#department-list .all:first-child,#department-list .all:nth-child(4n+5) {
    margin-left: 0
}

#department-list .all {
    margin-left: 2.1%;
    width: 23.4%;
    padding: 1.5em 1.5em 3em;
    height: 100%;
    position: relative;
    min-height: 271px;
    max-height: 270px
}

#department-list .all h4 {
    font-size: 24px;
    font-weight: 700
}

#department-list .job-box .readmore_arrow {
    position: absolute;
    bottom: 1.7em;
    z-index: 5;
    display: block
}

#department-list .all .column-link {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    transition: box-shadow .3s;
    -webkit-transition:all .2s ease;
}

#job-list .job-location {
    text-transform: uppercase;
    color: #152432;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 1em 0;
    display: block
}

#job-list .job-position .btn {
    position: absolute;
    bottom: 32px
}

.job-position h3 {
    color: #152331!important
}

.job-position:hover {
    cursor: pointer
}

.job-position h3 a {
    color: #152331
}

.job-position:hover .btn-text.btn-black {
    color: #1f74fb
}

.job-position .job-cat {
    text-transform: uppercase;
    font-weight: 600;
    color: #9aa8b5;
    margin-bottom: 10px;
    display: block;
    line-height: 21px
}

.link-job {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

#job-detail {
    margin-bottom: 7%
}

#job-detail .job-form {
    padding: 1px
}

#job-detail .col-md-8 ul li {
    line-height: 1.7;
    font-size: 1.2em
}

#job-detail .job-sidebar small {
    font-size: 1em
}

#job-detail .applyforjob {
    width: 100%;
    line-height: 3.4;
    margin: 0 0 20px
}

#job-detail .applyforjob i {
    margin-right: 10px
}

#job-detail .job-header {
    list-style: none;
    padding: 0;
    margin: 0
}

#job-detail .job-header li {
    margin-right: 2%;
    text-transform: uppercase;
    font-weight: 400;
    color: #9aa3a7;
    font-size: 15px;
    display: inline-block;
    margin-bottom: 0
}

#job-detail .job-header li b {
    color: #152432
}

#job-detail .job-header li tag {
    padding: 4px 15px;
    background-color: #f5f7fb;
    display: block;
    font-weight: 700
}

#job-detail .job-sidebar {
    font-size: 18px;
    text-align: center;
    display: block;
    width: 100%;
    padding: 0
}

.social-sharing {
    list-style: none;
    margin-bottom: 0;
    margin-top: 30px;
    padding: 0
}

.social-sharing li {
    list-style: none;
    display: block;
    margin-bottom: 12px
}

.social-sharing li a.social-btn {
    padding: 10px 20px;
    background-color: #f5f7fb;
    color: #152432;
    font-weight: 600
}

.social-sharing li a.social-btn:hover {
    background-color: #e5e9f0
}

#applyjob .form-wrap .form-style-one small {
    font-size: 15px;
    text-align: center;
    display: block;
    color: #b6c0cf
}

.flex-cont {
    display: flex;
    align-items: initial;
    justify-content: space-between
}

.pr-20 {
    padding-right: 20px
}

.pr-30 {
    padding-right: 30px
}

.pr-40 {
    padding-right: 40px
}

.pr-50 {
    padding-right: 50px
}

.pl-10 {
    padding-left: 10px
}

.pl-20 {
    padding-left: 20px
}

.pl-30 {
    padding-left: 30px
}

.pl-40 {
    padding-left: 40px
}

.pl-50 {
    padding-left: 50px
}

.video-banner-height {
    max-height: 650px
}

.video-banner-height-min {
    max-height: 650px;
    min-height: 650px
}

.portfolio-banner-height {
    max-height: 750px
}

.portfolio-banner-height-min {
    max-height: 750px;
    min-height: 750px
}

.medium-btn-slider {
    background-color: #f88379!important;
    color: #fff;
    font-size: 1em
}

.pb-180 {
    padding-bottom: 70px!important
}

.pb-100 {
    padding-bottom: 100px!important
}

.sub-heading-sec {
    color: #03b4ff;
    text-transform: none;
    font-size: 20px;
    line-height: 1.5
}

.bt-light {
    border-top: 1px solid #ddd
}

.gredient-colors {
    color: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)))!important;
    background: linear-gradient(to right,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)!important
}

.gredient-section {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-gradient-start-secondary)),to(var(--color-gradient-stop-secondary)))!important;
    background: linear-gradient(to right,var(--color-gradient-start-secondary) 0,var(--color-gradient-stop-secondary) 100%)!important
}

.gredient-section h3 {
    color: #fff;
    font-size: 30px!important
}

.gredient-section p {
    color: #fff
}

.gredient-section-one h3 {
    color: #fff;
    font-size: 30px!important
}

.gredient-section-one p {
    color: #fff
}

.portfolio-banner-height-min h1,.video-banner-height-min h1 {
    margin-bottom: 15px;
    display: block;
    margin-top: 15px
}

.text-blue {
    color: #03b4ff
}

.headingh2 h2 {
    margin-bottom: 0!important
}

.border-r img {
    border-radius: 6px
}

.h3custom h3 {
    font-size: 25px;
    font-weight: 700
}

#section-three-boxed-grey {
    background: #f5f7fb;
    padding: 70px 0 100px
}

#section-three-boxed-grey .iconbox-xl .iconbox-icon-container img[src$=".svg"] {
    width: 150px!important
}

#section-three-boxed-grey .contents.h3custom h3 {
    font-size: 15px!important;
    font-size: 20px!important;
    line-height: 1.3!important;
    font-weight: 600!important;
    min-height: 78px;
    text-align: center
}

#section-three-boxed-grey .border-athens-gray {
    text-align: center;
    display: block;
    padding: 30px;
    background: #fff;
    box-shadow: 0 4px 9px 0 rgba(23,28,33,.06);
    border-radius: 5px!important;
    border: 0
}

@-webkit-keyframes hvr-bob {
    0% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }

    50% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px)
    }

    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }
}

@keyframes hvr-bob {
    0% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }

    50% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px)
    }

    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }
}

@-webkit-keyframes hvr-bob-float {
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }
}

@keyframes hvr-bob-float {
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }
}

.hvr-bob {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0,0,0,0)
}

.hvr-bob:active,.hvr-bob:focus,.hvr-bob:hover {
    -webkit-animation-name: hvr-bob-float,hvr-bob;
    animation-name: hvr-bob-float,hvr-bob;
    -webkit-animation-duration: .3s,1.5s;
    animation-duration: .3s,1.5s;
    -webkit-animation-delay: 0s,.3s;
    animation-delay: 0s,.3s;
    -webkit-animation-timing-function: ease-out,ease-in-out;
    animation-timing-function: ease-out,ease-in-out;
    -webkit-animation-iteration-count: 1,infinite;
    animation-iteration-count: 1,infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal,alternate;
    animation-direction: normal,alternate
}

.greysection-bg {
    padding: 70px 0;
    background: #f5f7fb!important
}

.nav-collapsed-own .tabs-nav li.active a {
    color: #04b4ff!important;
    font-weight: 700
}

.nav-collapsed-own .tabs-nav li {
    font-weight: 700;
    color: #232323!important
}

.nav-collapsed-own .tabs-nav-active-underlined .tabs-nav li:after {
    background: #04b4ff!important
}

.nav-collapsed-own a {
    color: #232323!important
}

.tabs-nav li a:hover {
    opacity: .7
}

.nav-collapsed-own .emp-name {
    letter-spacing: -.01em;
    font-size: 1em;
    line-height: 1.1em;
    color: #000;
    text-align: center;
    font-weight: 700
}

.nav-collapsed-own .emp-designation {
    font-size: .8em!important;
    line-height: 1.5em;
    margin-top: 3px!important;
    text-align: center;
    opacity: 1
}

.center-box-div {
    justify-content: center;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center
}

.center-box-div-two {
    justify-content: center;
    align-items: initial!important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center
}

#description-logo .content-small-w p {
    width: 75%;
    text-align: center;
    margin: 0 auto
}

.center-row .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.logo-images-des img {
    width: 20%
}

.holder {
    width: 560px;
    height: 315px;
    position: relative
}

.frame {
    width: 100%;
    height: 100%
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 560%;
    height: 315px;
    cursor: pointer
}

.modal-iframe iframe {
    width: 100%!important;
    height: 100%!important;
    min-height: 500px!important
}

.modal-iframe .modal-content {
    background: 0 0;
    box-shadow: none;
    border: 0
}

.modal-iframe button {
    background: none!important;
    border: 0;
    text-align: right;
    font-size: 25px;
    color: #fff;
    opacity: .5;
    position: absolute;
    right: 0;
    top: -30px
}

iframe {
    border: none;
    max-width: 100%
}

.video {
    height: 0;
    padding: 0 0 56.25%;
    position: relative
}

.video-iframe,.video-play {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.video-play {
    align-items: center;
    color: transparent;
    cursor: pointer;
    font-size: 80px;
    display: flex;
    justify-content: center;
    pointer-events: none;
    background: url(http://dev.cubix.co/storage/app/media/about/icon-play.svg) 50% 50% no-repeat;
    -o-background-size: 100% 100%
}

.video:hover .video-play {
    color: #11a7aa
}

.ytp-chrome-top.ytp-show-cards-title {
    display: none!important
}

.testimonails-own i.fa.fa-star {
    color: #f8b820;
    padding: 0 2px
}

.ourabout-testimonails button.flickity-button.flickity-prev-next-button.next {
    right: -30px
}

.ourabout-testimonails button.flickity-button.flickity-prev-next-button.previous {
    left: -30px
}

.testimonial-info i.fa.fa-star {
    color: #ff3c2e
}

.testimonails-own .testimonial-info {
    padding-left: 10px
}

.testimonails-own .testimonial .avatar {
    width: 50px;
    margin-right: 20px;
    border-radius: 50em;
    overflow: hidden;
    position: relative;
    z-index: 2
}

.testimonails-own .testimonial-quote p {
    min-height: 140px
}

.testimonails-own .testimonial .testimonial-details h5 {
    font-size: 22px;
    color: #16171e
}

.nametestimonials .testimonial-info h5 {
    font-size: 18px!important
}

.nametestimonials .testimonial-info h6 {
    font-size: .875rem!important
}

.testimonails-own .flickity-viewport {
    max-height: 350px!important
}

.holder .overlay {
    width: auto!important
}

.youtube-video .holder {
    margin: 0 0 30px
}

.heading-ico {
    width: 70px;
    height: 70px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.22);
    background: #fff;
    border-radius: 50%;
    padding: 14px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 20px;
    display: inline-block
}

.h3-subheading-second {
    font-family: Poppins;
    font-size: 30px!important;
    line-height: 1.4!important;
    font-weight: 600!important
}

.box-icons-sub {
    text-align: center
}

.box-icons-sub img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px
}

.box-icons-sub h4 {
    margin: 0;
    padding: 0;
    font-size: 20px!important;
    line-height: 1.3!important;
    font-weight: 600!important
}

.contentul ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1.2em;
    text-indent: -1.2em
}

.contentul li {
    padding: 0 0 8px;
    font-size: 18px
}

.contentul li:before {
    content: "O";
    display: block;
    float: left;
    width: 1.2em;
    color: #03b4ff
}

.sides-image-content-section h2 {
    margin-top: 10px!important
}

.white-bgstriped {
    box-shadow: 0 4px 9px 0 rgba(23,28,33,.06);
    padding: 2rem;
    transition: all .4s cubic-bezier(0.4,0,.2,1)
}

.white-bgstriped h2 {
    font-size: 25px!important;
    line-height: 1.3!important;
    font-weight: 600!important;
    color: #000!important;
    margin: 0
}

.btn-common {
    font-weight: 600!important;
    letter-spacing: 0;
    color: #fff;
    border: 0;
    border-radius: 3px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-top: 0;
    line-height: normal;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -o-transition: all .25s ease;
    -ms-transition: all .25s ease
}

.btn-common:hover {
    color: #fff;
    box-shadow: 0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08);
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    filter: brightness(110%);
    -webkit-filter: brightness(110%);
    -moz-filter: brightness(110%);
    -o-filter: brightness(110%);
    -ms-filter: brightness(110%);
    cursor: pointer
}

.btn-common.medium {
    font-size: 1em;
    padding: 15px 30px
}

.blue-btn-common-accent-blue {
    background-color: #03b4ff!important
}

.Technology-logos figure {
    width: 15%;
    display: inline-block
}

.Technology-logos {
    margin: 0 auto;
    text-align: center
}

.background-cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center
}

.elvn {
    width: 80%;
    margin: 0 auto
}

.Industries-icon {
    margin: 0 auto;
    text-align: center
}

.Industries-icon figure {
    width: 15%;
    display: inline-block;
    margin: 0 auto;
    text-align: center
}

.Industries-icon img {
    width: 50px!important;
    display: inline-block
}

.Industries-icon.img-large-logos img {
    width: 80%!important;
    display: inline-block
}

.Industries-icon figure h3 {
    font-size: 20px!important;
    line-height: 1.3!important;
    font-weight: 600!important;
    margin: 0;
    padding: 15px 0
}

.Technology-logos img {
    width: 120px
}

.pr-15 {
    padding-right: 15px
}

.pl-15 {
    padding-left: 15px
}

.border-banner hr {
    opacity: .3
}

.simple-banner-height-700 {
    max-height: 700px
}

.simple-banner-height-min {
    max-height: 670px;
    min-height: 670px
}

.medium-btn-slider-blue {
    background-color: #03b4ff!important;
    color: #fff;
    font-size: 1em
}

.common-white p {
    font-size: 20px
}

.build-box-content img {
    width: 50px;
    padding: 0!important
}

.boxed-shaddow-common {
    box-shadow: 0 4px 9px 0 rgba(23,28,33,.06)
}

.build-box-content {
    min-height: 280px;
    padding: 30px;
    background: #fff;
    margin-bottom: 30px
}

.build-box-content h5 {
    margin: 0;
    padding-bottom: 15px
}

.common-grey {
    background: #f5f7fb
}

.center-headings {
    text-align: center;
    margin: 0 auto
}

.width-70-img {
    width: 70%;
    margin: 0 auto;
    text-align: center
}

.circle-testimonialsd img {
    max-width: 85%;
    border-radius: 100%;
    filter: grayscale(1);
    cursor: pointer
}

.circle-testimonialsd .is-nav-selected img {
    filter: none!important
}

blockquote.testimonial-font-size {
    font-size: 23px!important;
    color: #000
}

blockquote.testimonial-font-size p {
    line-height: 1.5!important
}

.auther-name-testimonials p {
    font-size: 22px;
    color: #000;
    padding: 30px 0 0;
    margin-bottom: 0;
    line-height: 0
}

.auther-name-testimonials span {
    font-size: 18px;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
    color: #7d7d7d
}

.medium-red-fade-color-button {
    background-color: #f88379!important;
    color: #fff;
    font-size: 1em
}

.contact-sliders h2 {
    font-size: 25px;
    color: #fff;
    margin-bottom: 10px
}

.parallax-slider {
    height: 100%;
    background: linear-gradient(to bottom,#012690 0,#1cafc6 100%);
    padding-left: 0!important;
    padding-right: 0
}

.contact-slider-images img {
    width: 100%
}

.contact-slider-images h2 {
    margin-bottom: 10px;
    color: #fff;
    font-size: 25px
}

.slider-menu-contact .carousel-item-inner {
    font-size: 17px!important;
    color: #fff;
    cursor: pointer;
    opacity: .7
}

.slider-menu-contact {
    margin: 50px auto 0;
    text-align: center;
    width: 100%!important
}

.slider-menu-contact figure {
    width: 25%;
    display: inline-block
}

.contact-slider-images p {
    padding-bottom: 0!important;
    margin-bottom: 0
}

.btn-text-only {
    border: none;
    background-color: transparent!important;
    display: inline-block;
    position: relative;
    padding: 3px 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px
}

.btn-text-only.btn-light {
    color: #fff
}

.btn-text-only:after,.btn-text-only:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #04b4ff;
    opacity: .25
}

a.btn-text-only.view_location.btn-light:hover {
    color: #03b4ff
}

.btn-text-only:focus:after,.btn-text-only:hover:after {
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.btn-text-only:after {
    opacity: 1;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transition: -webkit-transform .4s cubic-bezier(0.4,0,.2,1);
    transition: -webkit-transform .4s cubic-bezier(0.4,0,.2,1);
    transition: transform .4s cubic-bezier(0.4,0,.2,1);
    transition: transform .4s cubic-bezier(0.4,0,.2,1),-webkit-transform .4s cubic-bezier(0.4,0,.2,1)
}

.btn-text-only.btn-light:after,.btn-text-only.btn-light:before {
    border-color: #fff
}

.parallax__layer--bg {
    top: 35%;
    width: 100%
}

.parallax__layer {
    position: absolute;
    left: 0;
    top: 0
}

.parallax__layer__cell img {
    max-width: 100%;
    width: 100%
}

.parallax__layer__cell {
    position: absolute
}

.parallax__layer__cell:nth-child(1) {
    left: 0;
    top: 150px
}

.mc4wp-form-fields input[type=date],.mc4wp-form-fields input[type=email],.mc4wp-form-fields input[type=password],.mc4wp-form-fields input[type=search],.mc4wp-form-fields input[type=tel],.mc4wp-form-fields input[type=text],.mc4wp-form-fields input[type=url] {
    transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    background: #f5f7fa;
    border-radius: 0;
    border: 0;
    padding: 0 0 0 15px;
    box-shadow: none;
    line-height: 2.4em;
    height: 55px;
    font-size: 17px!important;
    color: #333;
    display: block;
    width: 100%
}

.mc4wp-form-fields input[type=date]:focus,.mc4wp-form-fields input[type=email]:focus,.mc4wp-form-fields input[type=password]:focus,.mc4wp-form-fields input[type=search]:focus,.mc4wp-form-fields input[type=tel]:focus,.mc4wp-form-fields input[type=text]:focus,.mc4wp-form-fields input[type=url]:focus {
    background: #edf0f5
}

.mc4wp-form-fields button[type=submit],.mc4wp-form-fields input[type=button],.mc4wp-form-fields input[type=submit] {
    padding: 16px 11px;
    cursor: pointer;
    transition: all .2s linear 0;
    -moz-transition: all .2s linear 0;
    -webkit-transition: all .2s linear 0;
    -o-transition: all .2s linear 0;
    width: auto;
    font-size: 12px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -o-border-radius: 4px
}

.mc4wp-form-fields select {
    transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    border: 0;
    padding: 0 0 0 15px;
    box-shadow: none;
    line-height: 2.4em;
    height: 55px;
    font-size: 17px!important;
    color: #333;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    /* background: url(../themes/responsiv-clean/assets/img/arrow-select.svg) center right 10px/30px no-repeat #f5f7fa */
}

.mc4wp-form-fields textarea {
    transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    background: #f5f7fa;
    border-radius: 0;
    border: 0;
    padding: 15px;
    box-shadow: none;
    font-size: 17px!important;
    color: #333;
    display: block;
    width: 100%;
    min-height: 100px
}

.mc4wp-form-fields textarea:focus {
    background: #edf0f5
}

.form-style-one .row {
    padding-bottom: 10px!important
}

.form-style-one .input {
    position: relative;
    display: inline-block;
    width: 100%
}

.form-style-one .input_phone {
    position: relative;
    display: block;
    float: left;
    padding: .8em;
    width: 60%;
    background: #fff;
    color: #0a0a0a;
    font-weight: 300;
    -webkit-appearance: none;
    border-radius: 3px;
    border: 1px solid #b0bbca;
    box-shadow: inset 0 2px 0 0 rgba(188,198,211,.14)
}

.form-style-one .input_select {
    position: relative;
    display: block;
    float: right;
    padding: .8em;
    width: 60%;
    border-radius: 0;
    color: #0a0a0a;
    font-weight: 300;
    -webkit-appearance: none;
    border: 2px solid #dde1e8;
    /* background: url(../themes/responsiv-clean/assets/img/down-arrow-red.svg) right 20px center/20px no-repeat #fff */
}

.form-style-one .input_field:focus {
    outline: 0
}

.form-style-one .input_label {
    display: inline-block;
    float: left;
    padding: 0 1em;
    width: 40%;
    color: #b3b8c1;
    font-size: 70.25%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.form-style-one .input_phone-float {
    width: 100%;
    padding: .7em .6em .5em .7em;
    font-weight: 300;
    font-size: 21px;
    line-height: 28px
}

.form-style-one .input_select-float {
    width: 100%;
    padding: 1.08em .5em;
    font-weight: 300;
    font-size: 20px;
    line-height: 1em
}

.form-style-one .input_label-float {
    position: absolute;
    width: 100%;
    text-align: left;
    padding: 0;
    height: 100%;
    pointer-events: none;
    left: 0
}

.form-style-one .input_label-content-float {
    padding: 0;
    font-size: 17px;
    display: inline-block;
    vertical-align: bottom;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translate3d(15px,8px,0);
    transform: translate3d(15px,8px,0);
    -webkit-transition: -webkit-transform .3s;
    transition: transform .3s;
    line-height: 28px
}

.form-style-one .input-filled .input_label-content-float,.form-style-one .input_field-float.valid+.input_label-float .input_label-content-float,.form-style-one .input_field-float:focus+.input_label-float .input_label-content-float {
    -webkit-transform: translate3d(15px,8px,0) scale3d(0.7,.7,1);
    transform: translate3d(15px,8px,0) scale3d(0.7,.7,1)
}

.form-style-one .input_field-float.error+.input_label-float .input_label-content-float {
    color: #1f74fb
}

.form-style-one textarea.input_field-float {
    line-height: 1.4em
}

.form-style-one label[for=nda] {
    padding-left: 40px;
    text-align: left;
    color: #b3b8c1;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    float: left
}

.form-style-one label[for=nda] input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 3px solid #dde1e8
}

.form-style-one label[for=nda] input:checked~.checkmark {
    border-color: #1f74fb
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none
}

.form-style-one label[for=nda] input:checked~.checkmark:after {
    display: block
}

.form-style-one label[for=nda] .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #1f74fb;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.form-style-one input.error,.form-style-one textarea.error {
    border-bottom-color: #fb1f1f!important;
    border-bottom: 2px solid
}

.form-style-one label.error {
    display: none!important
}

#call-to-action {
    padding: 4em 0;
    background-color: #1f74fb;
    position: relative;
    z-index: 10
}

#call-to-action h4 {
    margin-bottom: 0!important;
    text-transform: uppercase;
    font-size: 3em;
    margin-right: 60px;
    display: inline-block;
    line-height: 73px
}

#contact_page-container {
    padding: 0 20px
}

.form-style-one input {
    height: 62px!important
}

.clears {
    clear: both!important
}

#contact_page .span_6 {
    width: 49%
}

.form-wrap .col {
    position: relative;
    z-index: 10;
    display: block;
    float: left;
    width: 100%
}

.clr:after,.col:after,.group:after,.row:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden
}

.form-style-one .input_field {
    position: relative;
    display: block;
    float: left;
    padding: .8em;
    width: 60%;
    background: #fff;
    color: #0a0a0a;
    font-weight: 300;
    -webkit-appearance: none;
    line-height: 1.4em;
    border-radius: 3px;
    border: 1px solid #b0bbca;
    border-bottom: 2px solid #b0bbca;
    box-shadow: inset 0 2px 0 0 rgba(188,198,211,.14)
}

.form-style-one .input_field-float {
    width: 100%;
    padding: 1.4em .6em .5em .7em;
    font-weight: 300;
    font-size: 19px;
    resize: none
}

.col-right-spce {
    margin-right: 2%
}

.flex-v-middle {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-align-content: center;
    align-items: center;
    -moz-box-pack: space-around;
    -ms-flex-pack: space-around;
    align-self: center;
    align-content: center;
    margin: auto
}

.overflow_heights {
    overflow: auto;
    height: 100%;
    position: relative
}

.btn.light-blue {
    background: #03b4ff;
    color: #fff!important
}

.btn.medium {
    font-size: 1em;
    padding: 15px 30px
}

.btn-block {
    width: 100%
}

.form-style-one .row {
    margin-left: 0;
    margin-right: 0
}

#contact_page-container h3 {
    margin-bottom: 10px
}

a.hide-d {
    display: none!important
}

.testimonials-center-m figure.avatar {
    display: inline;
    margin-right: 10px
}

.clearall {
    clear: both
}

.inner-bxd {
    position: relative;
    color: #fff;
    z-index: 10;
    min-height: 250px
}

.box-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    z-index: 1;
    background-position: center;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: all .6s cubic-bezier(0.24,1,.3,1);
    -webkit-transition: all .6s cubic-bezier(0.24,1,.3,1)
}

.box-bg:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: ' ';
    background-color: rgba(0,0,0,.55);
    transition: all .6s cubic-bezier(0.24,1,.3,1);
    -webkit-transition: all .6s cubic-bezier(0.24,1,.3,1)
}

.nectar-fancy-box:hover .box-bg {
    transform: scale(1.13);
    -webkit-transform: scale(1.13)
}

.nectar-fancy-box {
    overflow: hidden;
    position: relative;
    padding: 8%;
    margin-bottom: 30px;
    min-height: 376px
}

.inner-bxd h2 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #fff;
    font-size: 27.5px
}

.blue-a,.blue-a:hover {
    color: #04b4ff
}

.bg-greybox {
    background: #ebeff5
}

.bg-whitesbox {
    background: #fff
}

.contentul-sm ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1.2em;
    text-indent: -1.2em
}

.contentul-sm li {
    padding: 0 0 8px;
    font-size: 18px;
    text-indent: -16px;
    list-style: none
}

.contentul-sm li:before {
    content: "O";
    display: block;
    float: left;
    width: 1.2em;
    color: #03b4ff;
    font-size: 14px;
    font-weight: 700;
    text-indent: -20px
}

.p-30 {
    padding: 30px
}

.ourblockchian-service h2 {
    font-size: 27.5px;
    margin-top: 0;
    margin-bottom: 8px
}

.ourblockchian-service h3 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 8px
}

ul.contentul-sm {
    margin-left: 0!important;
    padding-left: 20px
}

.row-own {
    display: inline-block
}

.ourblockchian-service {
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 285px
}

.min-h-one {
    min-height: 397px
}

.min-h-two {
    min-height: 302px
}

figure.imgwidth-logo-sl img {
    max-width: 100px!important
}

.playbtn-svgs .play_button_with_text {
    display: flex;
    align-items: center;
    width: 230px
}

.playbtn-svgs .nectar_video_lightbox span {
    height: 100%;
    transition: all .4s cubic-bezier(0.19,1,.22,1) 0;
    -webkit-transition: all .4s cubic-bezier(0.19,1,.22,1) 0;
    z-index: 11;
    position: relative;
    display: inline-block;
    width: auto;
    line-height: 0
}

.playbtn-svgs .nectar_video_lightbox span .inner-wrap {
    background-color: #fff;
    border-radius: 100%;
    width: 60px;
    text-align: center;
    height: 60px
}

.playbtn-svgs .nectar_video_lightbox svg {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 2px;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    z-index: 11
}

.playbtn-svgs .nectar_video_lightbox path {
    fill: #e8504d
}

.playbtn-svgs .nectar_video_lightbox span.link-text {
    font-weight: 600;
    text-transform: uppercase;
    line-height: 23px;
    margin-left: 10px
}

.playbtn-svgs p {
    margin-bottom: 0!important;
    color: #fff
}

.playbtn-svgs a {
    text-decoration: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.sideban-small img {
    width: 90%;
    display: block
}

.customfull-height,.customfull-height-contact {
    height: 100vh
}

.full-w-custom {
    width: 100%
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.row.no-gutters>[class*=" col-"],.row.no-gutters>[class^=col-] {
    padding-right: 0;
    padding-left: 0
}

.no-space .col-xs-12 {
    padding: 0
}

.badges-rows ul {
    margin: 0;
    padding: 0;
    display: inline-block
}

.badges-rows h4 {
    display: inline-block
}

.badges-rows li {
    margin: 0;
    list-style: none;
    display: inline-block;
    padding: 0 5px
}

.badges-rows h4 {
    font-size: 14px;
    color: #fff;
    padding-right: 10px
}

.badges-rows {
    margin: 0 auto
}

.sidenav-bg {
    background: #f4f4f4
}

.sidenav-bg li:first-child {
    background-image: linear-gradient(to right, #e1468d, rgba(196, 81, 202, .68) 25%, #7746e7 63%), linear-gradient(to left, #e5468f, #e5468f);
    border-radius: 6px 6px 0 0;
  }
  

.sidenav-bg h4 {
    font-size: 18px!important;
    padding: 10px!important;
    margin: 0;
    color: #fff
}

.sidenav-bg.tabs-nav li {
    font-size: 14px!important
}

.sidenav-bg li a {
    padding: .75em 1.25em!important
}

.tabs-nav-side-alt .tabs-nav.sidenav-bg li a:after {
    top: .75em
}

.tabs-nav-side-alt .tabs-nav.sidenav-bg li {
    border-bottom: 1px solid #dcdcdc
}

.tabs-nav-side-alt .tabs-nav.sidenav-bg li:first-child {
    border-bottom: 0
}

.tabs-nav-side-alt .tabs-nav.sidenav-bg li:last-child {
    border-bottom: 0
}

.sidenav-bg {
    border-radius: 0 0 6px 6px
}

.ournav-tabs-custom .tabs-content {
    padding-top: 0
}

.dinline-box {
    display: inline-block!important;
    padding-right: 15px
}

.icon-text {
    margin-bottom: 15px
}

.icon-text p {
    padding-bottom: 0!important;
    margin-bottom: 0;
    line-height: normal!important
}

ul.sps-list li {
    font-size: 16px;
    margin-bottom: 10px;
    position: relative;
    list-style: none
}

ul.sps-list li:before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #7746e7;
    position: absolute;
    left: -13px;
    top: 11px
}

ul.sps-list {
    margin-left: 0!important;
    padding: 0 0 0 18px
}

.ulbox-list {
    background: #f1f1f1;
    padding: 15px
}

.ulbox-list img {
    position: absolute;
    right: -12px;
    width: 180px;
    top: -28px
}

.ulbox-list {
    position: relative
}

.carousel-img figure {
    width: 144px
}

.ourcheckban span.checkmark {
    border-color: #fff!important;
    border: 2px solid
}

.ourcheckban[for=nda] .checkmark:after {
    border-color: #fff!important;
    left: 7px!important;
    top: 3px!important;
    width: 7px!important;
    height: 12px!important;
    border: solid #1f74fb;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.pr-md-15 {
    padding-right: 15px
}

.z-index-1 {
    position: relative;
    z-index: 1
}

.dblock-small {
    text-indent: -20px;
    padding-left: 40px
}

.iconbox_custom {
    height: 100%;
    padding: 2.3em;
    box-shadow: 0 4px 9px 0 rgba(23,28,33,.06);
    border-radius: 5px
}

.iconbox_custom h3 {
    font-size: 24px!important;
    line-height: 1.3!important;
    font-weight: 600!important
}

.iconbox_custom p {
    font-size: 18px
}

.nector_icon {
    margin-bottom: 15px
}

.custom_border iframe {
    border: 15px solid #fff;
    border-radius: 14px
}

.btn-white {
    background-color: #fff!important
}

.btn-blueclr {
    color: #5650cc!important
}

.playblue-fill {
    fill: #5650cc!important
}

.btn-greenclr {
    color: #00ab99!important
}

.playgreen-fill {
    fill: #00ab99!important
}

.tabs-style a {
    font-size: 18px
}

.row-flex-own {
    display: flex;
    flex-wrap: wrap
}

.btn-light-green {
    background: #00d6a0!important
}

@-webkit-keyframes bounce {
    0% {
        transform: translateY(-5px)
    }

    50% {
        transform: translateY(10px)
    }

    100% {
        transform: translateY(-5px)
    }
}

@keyframes bounce {
    0% {
        transform: translateY(-5px)
    }

    50% {
        transform: translateY(10px)
    }

    100% {
        transform: translateY(-5px)
    }
}

.bounce-slow-images {
    -webkit-animation: bounce 3s infinite ease-in-out;
    -o-animation: bounce 3s infinite ease-in-out;
    -ms-animation: bounce 3s infinite ease-in-out;
    -moz-animation: bounce 3s infinite ease-in-out;
    animation: bounce 3s infinite ease-in-out
}

.bgblackcloud {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: #1c1b29
}

.bg-herobox {
    border: 1px solid #2b2d46;
    opacity: 1;
    transform: translate(0px,0);
    background: #2b2d46;
    border-radius: 5px
}

.sonar-wrapper {
    position: relative;
    z-index: 0;
    overflow: initial;
    padding: 0
}

.sonar-emitter {
    position: relative;
    border-radius: 9999px;
    background-color: HSL(50,100%,50%)
}

.sonar-wave {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    border-radius: 9999px;
    background-color: HSL(50,100%,50%);
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    animation: sonarWave 2s linear infinite
}

@keyframes sonarWave {
    from {
        opacity: 1
    }

    to {
        transform: scale(2);
        opacity: 0
    }
}

.sonar-wrapper {
    height: 30%
}

figure.icon-glow img {
    position: relative;
    z-index: 99999!important;
    width: 70px;
    height: 70px
}

.bluewave .sonar-wave {
    background-color: #1852a9
}

.yellowwave .sonar-wave {
    background: #a16400
}

.lightyellow .sonar-wave {
    background-color: #fef447
}

.bluepays {
    fill: #00b0ff!important
}

.btn-bluebg {
    background: #00b0ff!important
}

.bg-chainbox {
    border: 1px solid #182458;
    opacity: 1;
    transform: translate(0px,0);
    background-color: #182458;
    border-radius: 5px
}

.cubix-chain-logo {
    margin: 0 auto;
    text-align: center
}

.cubix-chain-logo figure {
    width: 15%;
    display: inline-block;
    margin: 0 auto;
    text-align: center
}

.cubix-chain-logo img {
    width: 120px;
    display: inline-block
}

.chainimage {
    text-align: center;
    margin: 0 auto
}

.chainimage img {
    width: 70%
}

.dinlineowns {
    display: inline-block!important;
    vertical-align: middle!important;
    margin-right: 10px
}

.blue-icon-wave .sonar-wave {
    background-color: #41aefd!important
}

.mt-auto-own {
    text-align: center
}

.text-green-messenger,.text-green-messenger:hover {
    color: #56cad8
}

.fullheight-nine {
    height: 95vh
}

.icon-with-content .icon {
    width: 50px;
    vertical-align: top
}

.dinlineowns-one {
    display: inline-block
}

.img-icon-head {
    width: 12%;
    display: inline-block
}

.content-withhead {
    width: 87%;
    display: inline-block
}

.img-icon-head img {
    width: 40px
}

.img-icon-head {
    vertical-align: top
}

.messengerstyle-tabs .tabs-nav>li,.tabs-nav>li>a {
    display: block
}

.tabs-nav.text-rightown li {
    text-align: right!important
}

.tabs-nav.text-leftown li {
    text-align: left!important
}

.messengerstyle-tabs li.active h2 {
    color: #56cad8!important
}

.text-rightown {
    text-align: right
}

.wd-70 img {
    width: 70%;
    margin: 0 auto;
    text-align: center
}

.wd-70 {
    margin: 0 auto;
    text-align: center
}

.wd-90 img {
    width: 90%;
    margin: 0 auto;
    text-align: center
}

.wd-90 {
    margin: 0 auto;
    text-align: center
}

.fullheight-one {
    height: 100vh
}

.btn-yellowsclr {
    color: #fb883c!important
}

.chat-icotab img {
    width: 40px;
    display: block;
    text-align: center;
    margin: 0 auto
}

.tabs-nav.chat-icotab li {
    margin: 0 15px!important
}

ul.contentul-yellow {
    padding-left: 0!important
}

.contentul-yellow ul {
    list-style: none;
    margin-left: 0;
    text-indent: -1.2em;
    padding-left: 0
}

.contentul-yellow li {
    padding: 0 0 8px;
    font-size: 18px;
    list-style: none
}

.contentul-yellow li:before {
    content: "O";
    display: block;
    float: left;
    width: 1.2em;
    color: #fb883c
}

ul.contentul-blue {
    padding-left: 0!important
}

.contentul-blue ul {
    list-style: none;
    margin-left: 0;
    text-indent: -1.2em;
    padding-left: 0
}

.contentul-blue li {
    padding: 0 0 8px;
    font-size: 18px;
    list-style: none
}

.contentul-blue li:before {
    content: "o";
    display: block;
    float: left;
    width: 1em;
    color: #17baff
}

.img-chatvideo .nectar_video_lightbox.play_button {
    position: absolute;
    width: 90px;
    height: 90px;
    display: inline-block;
    transform: translateY(-50%) translateX(0);
    -webkit-transform: translateY(-50%) translateX(0);
    left: 0;
    top: 50%;
    z-index: 10;
    right: 0;
    margin: auto
}

.img-chatvideo .nectar_video_lightbox.play_button:before {
    display: block!important;
    position: absolute!important;
    content: ' '!important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: 200px!important;
    box-sizing: border-box!important;
    -webkit-box-sizing: border-box!important;
    transition: all .4s cubic-bezier(0.19,1,.22,1) 0!important;
    -webkit-transition: all .4s cubic-bezier(0.19,1,.22,1) 0!important
}

.img-chatvideo .nectar_video_lightbox span {
    display: block;
    height: 100%;
    width: 100%;
    transition: all .4s cubic-bezier(0.19,1,.22,1) 0;
    -webkit-transition: all .4s cubic-bezier(0.19,1,.22,1) 0;
    z-index: 11;
    position: relative
}

.img-chatvideo .nectar_video_lightbox svg {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 2px;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    z-index: 11
}

.img-chatvideo .nectar_video_lightbox path {
    fill: #fff
}

#contactform-container .btn.light-blue,.img-chatvideo .nectar_video_lightbox.play_button:before {
    background: #fb883c!important
}

.img-chatvideo .nectar_video_lightbox:hover:before {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    opacity: .8
}

.img-chatvideo a {
    text-decoration: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.color-blue-btns {
    color: #0081be
}

.color-purple-btns {
    color: #9462ba
}

.Industries-iconred {
    margin: 0 auto;
    text-align: center
}

.Industries-iconred figure {
    width: 15%;
    display: inline-block;
    margin: 0 auto;
    text-align: center
}

.Industries-iconred img {
    width: 80px!important;
    display: inline-block
}

.Industries-iconred .img-large-logos img {
    width: 80%!important;
    display: inline-block
}

.Industries-iconred figure h3 {
    font-size: 20px!important;
    line-height: 1.3!important;
    font-weight: 600!important;
    margin: 0;
    padding: 15px 0
}

span.number-rouand {
    width: 85px;
    height: 85px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    color: #fff;
    font-weight: 700;
    font-size: 28px;
    background-color: #3db3e3;
    border: 5px solid #d9f0fa
}

.box-numbers-mvp {
    border: 1px solid #f2f2f2;
    background: #fff;
    -webkit-box-shadow: 0 1.875rem 2.875rem 0 rgba(49,60,98,.05);
    box-shadow: 0 1.875rem 2.875rem 0 rgba(49,60,98,.05);
    border-radius: .3125rem;
    overflow: hidden;
    min-height: 160px
}

.font-size-30 {
    font-size: 30px!important
}

.p-padding-20 {
    padding: 20px
}

.box-mvpbottoms,.center-imgsd {
    margin: 0 auto;
    text-align: center
}

.bluebgsbtn {
    background-color: #03b4ff!important
}

.form-stylings label {
    display: none!important
}

.form-stylings body.public.app.app-embed {
    background: 0 0
}

.color-dark-purple {
    color: #180e3e!important
}

.cloud-scroll-bg:after {
    content: 'o';
    z-index: 0;
    position: absolute;
    left: 0;
    /* background-image: url(../themes/responsiv-clean/assets/images/cloud-of-venus/cloud-waves.png); */
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 10px;
    width: 100%;
    height: 350px;
    animation: cloud-slide 500s linear infinite;
    -ms-animation: cloud-slide 500s linear infinite;
    -moz-animation: cloud-slide 500s linear infinite;
    -webkit-animation: cloud-slide 500s linear infinite;
    bottom: 0
}

@keyframes cloud-slide {
    from {
        background-position: 0 0
    }

    to {
        background-position: -6000px 0
    }
}

@-webkit-keyframes cloud-slide {
    from {
        background-position: 0 0
    }

    to {
        background-position: -6000px 0
    }
}

@-ms-keyframes cloud-slide {
    from {
        background-position: 0 0
    }

    to {
        background-position: -6000px 0
    }
}

@-moz-keyframes cloud-slide {
    from {
        background-position: 0 0
    }

    to {
        background-position: -6000px 0
    }
}

.cloud-scroll-bg:before {
    background-color: #fa402d;
    height: 8px;
    width: 100%;
    content: '';
    position: absolute;
    bottom: 0;
    box-shadow: 0 -7px 11px 1px rgba(250,64,45,.7);
    -webkit-box-shadow: 0 -7px 11px 1px rgba(250,64,45,.7);
    -moz-box-shadow: 0 -7px 11px 1px rgba(250,64,45,.7);
    z-index: 999;
    left: 0
}

.could-of-venus {
    border: 1px solid #00263e;
    opacity: 1;
    transform: translate(0px,0);
    background: #00263e;
    border-radius: 5px
}

.redwave-own .sonar-wave {
    background-color: #dd2224
}

.light-bluewaves .sonar-wave {
    background-color: #41aefd
}

.blue-waves-ico .sonar-wave {
    background-color: #1588d1
}

.backgroundstrip-venus {
    /* background-image: url(../themes/responsiv-clean/assets/images/cloud-of-venus/cubix-cloud-of-venus-cloud-of-venus-bg-line.png); */
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-position: center!important;
    background-repeat: inherit;
    min-height: 150px;
    padding-top: 15px!important
}

.backgroundstrip-venus h2 {
    color: #fff!important
}

.backgroundstrip-space-pirates {
    /* background-image: url(../themes/responsiv-clean/assets/images/space-pirates/cubix-space-pirates-call-to-action-background.png); */
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-position: center!important;
    background-repeat: inherit;
    min-height: 150px;
    padding-top: 15px!important
}

.backgroundstrip-space-pirates h2,.today-today-stripe h2 {
    color: #fff!important
}

.today-today-stripe {
    /* background-image: url(../themes/responsiv-clean/assets/images/today-today/cubix-today-call-to-action-background.png); */
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-position: center!important;
    background-repeat: inherit;
    min-height: 150px;
    padding-top: 35px!important;
    padding-left: 112px
}

.black-wave .sonar-wave {
    background: #000
}

.reds-wave .sonar-wave {
    background: #d93c3d
}

.color-pink {
    color: #e86050!important
}

.hiddenover-flow-slides .flickity-viewport {
    overflow: inherit
}

.ourlarge-bann img {
    width: 120%!important;
    margin-left: -13%!important;
    max-width: 120%!important
}

.lqd-modal-inner {
    padding: 20px 0;
    border-radius: 6px
}

.popup-form {
    width: 540px!important
}

.overflow-own {
    overflow: hidden
}

.portfolio-forms {
    padding: 15px!important;
    border: 2px solid #f3f3f3!important
}

.portfolio-forms input {
    border: 1px solid #e5e5e5;
    width: 100%;
    padding: 8px;
    font-size: 14px
}

.portfolio-forms ::-webkit-input-placeholder {
    color: #9b9b9b;
    font-size: 14px
}

.portfolio-forms :-ms-input-placeholder {
    color: #9b9b9b;
    font-size: 14px
}

.portfolio-forms ::placeholder {
    color: #9b9b9b;
    font-size: 14px
}

.sidemobie1:after {
    content: ""!important;
    width: 499px!important;
    height: 250px!important;
    /* background: url(../themes/responsiv-clean/assets/images/curious-portfolio/Curious-section-1mobile-group.png) left bottom/20% no-repeat!important; */
    position: absolute!important;
    display: inline-block!important;
    left: -99px;
    bottom: 33px
}

.sidemobie2:after {
    content: ""!important;
    width: 499px!important;
    height: 350px!important;
    /* background: url(../themes/responsiv-clean/assets/images/curious-portfolio/Curious-section-2mobile-group.png) right bottom/50% no-repeat!important; */
    position: absolute!important;
    display: inline-block!important;
    right: -200px;
    bottom: 33px
}

.iconbox_custom_port {
    height: 100%;
    padding: 1.5em;
    border-radius: 10px;
    box-shadow: 0 0 30px 0 rgba(54,37,37,.14);
    text-align: left!important;
    display: block
}

.iconbox_custom_port .nector_icon {
    display: none
}

.iconbox.iconbox_custom_port h3 {
    line-height: normal;
    text-align: left;
    margin-bottom: 5px;
    display: block
}

.iconbox_custom_port p {
    font-size: 15px
}

.iconbox_custom_port h3 {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 8px
}

.content-section-cu h2 {
    background: #000;
    display: inline-block;
    color: #fff;
    padding: 8px;
    margin: 8px
}

.content-section-cu h3 {
    background: #000;
    display: inline-block;
    color: #fff;
    padding: 8px;
    margin: 5px
}

.content-section-cu {
    text-align: center
}

.content-section-cu-new h2 {
    background: #fff;
    display: inline-block;
    color: #74ce5a;
    padding: 8px;
    margin: 8px
}

.content-section-cu-new h3 {
    background: #fff;
    display: inline-block;
    color: #74ce5a;
    padding: 8px;
    margin: 5px
}

.content-section-cu-new {
    text-align: center
}

.our-custom-fromcss {
    background: #fff;
    padding: 40px;
    margin: 0 auto;
    text-align: center
}

.sidemobiehictic:after {
    content: ""!important;
    /* background: url(../themes/responsiv-clean/assets/images/hictic-portfolio/section-1-mobile1-group.png) left bottom/contain no-repeat!important; */
    position: absolute!important;
    display: inline-block!important;
    left: -192px;
    bottom: 47px;
    min-height: 602px!important;
    width: 140%
}

.sidemobie3:after {
    content: ""!important;
    /* background: url(../themes/responsiv-clean/assets/images/foodly-portfolio/section1-mobile-group.png) left bottom/100% no-repeat!important; */
    position: absolute!important;
    display: inline-block!important;
    left: -192px;
    bottom: 47px;
    min-height: 602px!important
}

.sidemobiefoodly:after {
    content: ""!important;
    width: 499px!important;
    min-height: 600px!important;
    /* background: url(../themes/responsiv-clean/assets/images/foodly-portfolio/section1-mobile-group.png) left bottom/40% no-repeat!important; */
    position: absolute!important;
    display: inline-block!important;
    left: -196px;
    bottom: 50px
}

.foodly-round:after {
    content: ""!important;
    width: 499px!important;
    min-height: 600px!important;
    /* background: url(../themes/responsiv-clean/assets/images/foodly-portfolio/mobile-round.png) left bottom/70% no-repeat!important; */
    position: absolute!important;
    display: inline-block!important;
    left: 120px;
    top: -157px!important
}

.engro-round:after {
    content: ""!important;
    width: 220px!important;
    min-height: 148px!important;
    /* background: url(../themes/responsiv-clean/assets/images/engro-portfolio/section2-mobile2group.png) left bottom/contain no-repeat!important; */
    position: absolute!important;
    display: block!important;
    right: -137px;
    bottom: 311px!important;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 25px 2px rgba(0,0,0,.18);
    -moz-box-shadow: 0 0 25px 2px rgba(0,0,0,.18);
    box-shadow: 0 0 25px 2px rgba(0,0,0,.18)
}

.fade-own .slick-arrow {
    display: none!important
}

.our-imgfade {
    width: 120%!important;
    overflow: inherit!important
}

.Industries-icon.wearbale-imglogo img {
    width: 80%!important
}

.about-logos img {
    width: 18%
}

.about-logos {
    margin: 0 auto;
    text-align: center;
    display: inline-block
}

.phone-num-color {
    color: #000!important
}

.position-topheader {
    position: absolute;
    z-index: 999;
    width: 100%;
    display: block
}

.t-right-num {
    position: absolute;
    right: 0!important;
    top: 35px
}

.view_location {
    z-index: 999!important
}

#error-404 {
    text-align: center;
    margin-bottom: 0;
    padding: 50px;
    position: relative
}

#error-404 .floating-planet {
    position: absolute;
    width: 49%;
    left: 50%;
    top: 27%;
    margin-left: -23%
}

.error-404-holder {
    position: relative;
    margin-bottom: 30px
}

.floating {
    -webkit-animation-name: Floatingx;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-name: Floating;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out
}

@-webkit-keyframes Floatingx {
    from {
        -webkit-transform: translate(0,0)
    }

    65% {
        -webkit-transform: translate(0,15px)
    }

    to {
        -webkit-transform: translate(0,0)
    }
}

@-moz-keyframes Floating {
    from {
        -moz-transform: translate(0,0)
    }

    65% {
        -moz-transform: translate(0,15px)
    }

    to {
        -moz-transform: translate(0,0)
    }
}

.all-testimonials blockquote {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 500
}

.all-testimonials span {
    font-family: Poppins;
    font-size: 24px!important;
    font-weight: 400;
    display: block;
    margin-top: 10px
}

.all-testimonials .small-title {
    font-size: 18px!important;
    margin-top: 8px;
    padding-top: 0;
    color: #7c838a;
    font-weight: 400;
    display: block;
    line-height: 0
}

.all-test-avatar .avatar {
    max-width: 100%;
    border-radius: 100%;
    filter: grayscale(1);
    cursor: pointer
}

.all-test-avatar .is-nav-selected .avatar {
    max-width: 100%;
    border-radius: 100%;
    filter: initial;
    cursor: pointer
}

.white-roundico .iconbox-icon-container {
    background-color: #fff
}

.img-widthsico img {
    width: 45px!important
}

.backgroud_image_left {
    background-position: center left!important;
    background-repeat: no-repeat!important;
    background-size: cover!important
}

.stretch-to-right .liquid-img-container-inner {
    box-shadow: none!important
}

.connect-c-c {
    height: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto!important
}

.box-process-right {
    text-align: right
}

.process-icon-content .elvn {
    width: 85%!important
}

.process-icon-content img {
    width: 85%
}

.process-icon-content {
    margin: 0 auto;
    text-align: center
}

.box-process-left p {
    margin-bottom: 0
}

.height-process {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center
}

.colorbtn-messengers {
    color: #48b8b3
}

.cloudbgs a {
    z-index: 999
}

.page-work .liquid-img-group-single[data-animate-shadow] .liquid-img-container-inner {
    box-shadow: none!important
}

.cloudbgs:after {
    content: 'o';
    position: absolute;
    left: 0;
    /* background-image: url(../themes/responsiv-clean/assets/images/cloud-of-venus/cloud-waves.png); */
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 10px;
    width: 100%;
    height: 350px;
    animation: cloud-slide 500s linear infinite;
    -ms-animation: cloud-slide 500s linear infinite;
    -moz-animation: cloud-slide 500s linear infinite;
    -webkit-animation: cloud-slide 500s linear infinite;
    bottom: 0;
    z-index: 99!important
}

.ico-careers img {
    width: 50px;
    margin-bottom: 10px;
    height: 45px;
    vertical-align: bottom
}

.spaceing-2nd img {
    right: -55px;
    position: relative
}

.spaceing-1nd {
    position: relative;
    left: -120px!important
}

#awards-recognitions .carousel-container {
    width: 100%!important
}

#awards-recognitions .flickity-slider .carousel-item {
    width: 26%
}

.egrocesser-mobile-images.liquid-img-group-single {
    top: 0!important
}

.page-services-game-development .liquid-img-group-single[data-animate-shadow] img {
    opacity: 1!important
}

.time-fordge-port {
    background-repeat: no-repeat!important;
    background-color: #241e4c!important;
    background-size: cover!important
}

.tou-height {
    min-height: 400px
}

.btn:focus,.btn:hover,a.btn.focus {
    text-decoration: none
}

.page-services-web-development img {
    opacity: 9!important
}

.btn-common.focus,.btn-common:focus,.btn-common:hover {
    color: #fff!important;
    text-decoration: none
}

.titlebar-inner {
    padding-top: 0;
    padding-bottom: 0
}

.page-insights-post .post-single {
    padding-top: 20%!important
}

.page-insights-post a {
    color: var(--color-primary)
}

.page-insights-post a:hover {
    color: #2b53ec
}

a.btn {
    color: #fff
}

.page-work button.load-btn {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 90px auto;
    background: #5174fd;
    border-radius: 3px;
    box-shadow: none;
    border: 0;
    padding: .85em 1.5em;
    color: #fff;
    font-weight: 700;
    letter-spacing: .1em!important;
    text-transform: uppercase;
    font-size: 13px
}

.font-size-23 {
    font-size: 23px!important
}

.page-about-events .titlebar-scroll-link {
    display: none!important
}

.event-contents {
    border-radius: 0 0 5px 5px;
    position: relative;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    background: #fff;
    box-shadow: 0 4px 9px 0 rgba(23,28,33,.06);
    border: 1px solid #efefef;
    padding: 20px
}

.ico-width-home-port img {
    width: 80px!important;
    height: 80px
}

.page-work .liquid-img-group-single[data-animate-shadow] img {
    opacity: 1!important
}

.page-about-events .inner-title-height {
    min-height: 50vh
}

.page-about-events .titlebar-col {
    padding-top: 70px
}

.page-work .stretch-to-right {
    margin-right: -4vw!important
}

.page-work .stretch-to-left {
    margin-left: -4vw!important
}

.page-work section.vc_row.work-item .row {
    height: 580px
}

.page-work .liquid-img-group-single[data-animate-shadow] img {
    transition: none!important
}

.page-work .backgroud_image_right {
    background-size: 100% 100%!important
}

.pr-150,.pr-200 {
    padding-right: 150px
}

.pl-150,.pl-200 {
    padding-left: 150px
}

.page-blog .liquid-lp-footer a {
    color: #000!important
}

.page-services-sharepoint-solutions .slider-content {
    position: absolute
}

footer span.published {
    margin-bottom: 50px!important;
    display: block
}

.ourblog-custom .blog-single-media {
    background-size: contain!important;
    -webkit-filter: blur(8px);
    filter: blur(8px)
}

.wp-caption {
    border: 1px solid #ccc;
    background: #eee;
    padding: 5px;
    display: table;
    margin: 0 auto 30px;
    text-align: center!important
}

.wp-caption img {
    display: block;
    text-align: center;
    margin: 0 auto!important;
    padding-bottom: 10px!important
}

.blog-single-cover .blog-single-media {
    background-size: contain;
    -webkit-filter: blur(8px);
    filter: blur(8px)
}

.width-image-featured-content {
    background-size: 100%
}

.ld-float-form>p {
    padding-bottom: 15px!important;
    font-weight: 700
}

.wp-block-image {
    text-align: center
}

.wp-caption-text {
    padding-bottom: 0!important
}

img.wp-block-image {
    text-align: center;
    margin: 0 auto;
    display: block
}

.shaddow-nones {
    box-shadow: none!important
}

img.section1-image-2-trans {
    position: relative;
    left: 50px
}

.section1-image-1-trans {
    transform: translateX(57.27px)
}

.img-c-res img {
    transform: translatex(80px)!important
}

.width-imgfamily img {
    opacity: 1!important
}

.image-rounds-sec img {
    position: relative;
    left: 280px!important
}

.work-item {
    overflow: hidden
}

.h3-991 a {
    display: none
}

img.image-widths-space {
    width: 70%!important;
    margin: 0 auto;
    text-align: center;
    display: block
}

.blur-offd {
    height: 100vh!important
}

.blur-offd figure {
    webkit-filter: blur(8px);
    filter: initial!important;
    background-repeat: no-repeat;
    background-size: 100%!important
}

.blur-offd img {
    background-repeat: no-repeat;
    background-size: 100%!important
}

.blur-offd.ourblog-custom .blog-single-media {
    background-size: cover!important
}

.liquid-img-group-single[data-animate-shadow] img {
    opacity: 1!important
}

.header-center-text {
    margin: 0 auto!important;
    text-align: center!important;
    display: block!important;
    width: 100%!important
}

.header-center-text .post-meta {
    display: block
}

.space-imagep img {
    position: relative;
    top: 70px;
    width: 70%
}

.space-imagep {
    position: relative;
    top: 0
}

.portfoliobanner-sizeimg img {
    width: 80%;
    text-align: right;
    display: block;
    margin: 0 auto
}

button#button_686890 {
    background: #282b30!important;
    border: 1px solid #282b30;
    height: 40px
}

.header-settings .header-module a.btn {
    background: #000
}

.sidebarNav h4 {
    font-weight: 700;
    border-bottom: 1px solid #bec8c9;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    margin-top: 0;
    font-size: 20px;
    padding-top: 3px
}

.sidebarNav .nav li a {
    border-left: 5px solid transparent;
    padding: 0 0 0 .5rem;
    margin-bottom: .5rem;
    line-height: 1.5;
    font-size: 16px!important
}

.sidebarNav .nav li.active a {
    border-left: 5px solid #5174fd;
    color: #5174fd;
    font-weight: 700
}

.sidebarNav a {
    color: #000
}

.sidebarNav .nav li a:focus,.sidebarNav .nav li a:hover {
    text-decoration: none;
    background-color: transparent;
    outline: 0
}

.scroll-content {
    padding: 0!important;
    overflow-x: hidden
}

.panel-affix {
    min-width: 225px
}

.main-footer {
    color: #808291
}

.main-footer .footer-bottom *,.main-footer .footer-middle * {
    font-size: 1em
}

footer.main-footer .iconbox-inline .widget-title {
    margin-bottom: 0
}

.link-style li a {
    font-size: 15px!important;
    font-weight: 400!important;
    color: #808291!important
}

footer.main-footer .location-link {
    font-weight: 700
}

footer .footer-bottom {
    border-top: 1px solid #e5e8ec
}

footer.main-footer .social-icon li {
    font-size: 1.2em
}

.link-style li a:hover,footer.main-footer .social-icon li a:hover,footer.main-footer p a:hover {
    color: #181b31!important
}

.main-header .is-stuck {
    box-shadow: none
}

.ld-float-form {
    border-left: 1px solid #282b30;
    border-right: 1px solid #282b30;
    border-bottom: 1px solid #282b30;
    border-radius: 9px;
    background-color: #fff;
    box-shadow: 5px 24px 40px 0 rgba(42,49,59,.11);
    width: 280px;
    border-top: 10px solid #282b30;
    position: fixed;
    z-index: 165;
    right: 0;
    top: 130px;
    padding: 20px 20px 0;
    display: none
}

.ld-float-form.ld-float-form-Cus {
    background-color: #FFF9C4
}

.ld-float-form .inp {
    margin-bottom: 15px
}

.ld-float-form .inp input {
    height: 45px;
    background-color: #fff;
    border: 1px solid #aeb1b9;
    padding: 0 10px;
    width: 100%;
    font-size: 15px
}

.ld-float-form .ld-submit-btn {
    height: 45px;
    background-color: #1fb3fc!important;
    font-size: 16px!important;
    text-transform: capitalize!important;
    font-weight: 500!important
}

.ld-float-form .ld-submit-btn.btn.medium {
    padding: 11px 30px
}

.ld-float-form>p {
    font-size: 16px;
    color: #000;
    line-height: 22px
}

.ld-float-form .form-action,.ld-float-form .form-group {
    margin-bottom: 10px
}

.ld-float-form .ld-form-contact {
    margin-top: 15px;
    display: none
}

.ld-float-form .ld-form-contact p {
    padding-bottom: 0;
    font-size: 16px
}

.ld-float-form .ld-form-contact ul li a {
    font-size: 15px
}

.ld-float-form .form-control {
    box-shadow: none;
    border: 1px solid #797979
}

.ld-float-form .form-control:focus {
    border-color: #282b30;
    outline: 0;
    box-shadow: none
}

.btn-primary {
    width: 100%;
    background: #5174fd!important;
    color: #fff
}

h1.text-white.ltr-sp--015.h2 {
    font-size: 50px
}

.btn-primary-black {
    font-family: montserratbold;
    font-family: Averta;
    font-size: 16px;
    color: #fff!important;
    background-color: #282b30;
    padding: 20px 32px;
    display: inline-block;
    line-height: 1;
    position: relative;
    overflow: hidden;
    transition: all 1s ease;
    z-index: 1;
    text-align: center;
    border-radius: 4px;
    margin-top: 30px;
    text-transform: uppercase;
    font-weight: 700
}

.headerWrapCus {
    padding: 150px 0 75px!important
}

.lity-container {
    z-index: 9992;
    position: relative;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    white-space: normal;
    max-width: 100%;
    max-height: 100%;
    outline: 0!important;
    width: auto;
    overflow: hidden
}

.popup-form-custom label {
    display: none
}

.popup-form-custom .form-group input {
    width: 100%;
    font-size: 19px;
    resize: none;
    padding: 1.3em .6em;
    background: #fff;
    color: #0a0a0a;
    font-weight: 300;
    -webkit-appearance: none;
    line-height: 1.4em;
    border-radius: 3px;
    border: 1px solid #b0bbca;
    border-bottom: 2px solid #b0bbca;
    box-shadow: inset 0 2px 0 0 rgba(188,198,211,.14)
}

.popup-form-custom .form-group textarea {
    width: 100%;
    padding: .6em;
    font-size: 19px;
    resize: none;
    background: #fff;
    color: #0a0a0a;
    font-weight: 300;
    -webkit-appearance: none;
    line-height: 1.4em;
    border-radius: 3px;
    border: 1px solid #b0bbca;
    border-bottom: 2px solid #b0bbca;
    box-shadow: inset 0 2px 0 0 rgba(188,198,211,.14)
}

.popup-form-custom a.btn-solid {
    font-size: 15px;
    padding: .5em .6em;
    width: 100%!important;
    font-weight: 700
}

.dis-nones {
    display: none!important
}

.form_div_sc {
    z-index: 99;
    position: fixed;
    right: -250px
}

.form_div_sc h3 {
    font-size: 23px
}

.form_div {
    border-radius: 9px;
    background-color: #fff;
    box-shadow: 5px 24px 40px 0 rgba(42,49,59,.11);
    width: 220px;
    border-top: 10px solid #4869e8!important;
    position: fixed;
    z-index: 165;
    right: 20px!important;
    top: 130px;
    padding: 20px 20px 0;
    display: none;
    border: 0
}

.form_div.active {
    right: -3px
}

.form_div h3 {
    margin-bottom: 20px!important;
    margin-top: 10px
}

.toggleone {
    position: absolute;
    height: 171px;
    width: 48px;
    text-align: center;
    cursor: pointer;
    background: #282b30;
    left: -48px;
    top: 50%;
    line-height: 68px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transform: translateY(-50%);
    transition: all .2s ease-in-out
}

.toggleone.active {
    height: 50px
}

.toggleone.active:before {
    content: '\f00d';
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 18px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #282b30;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: 900
}

.toggleone span {
    color: #fff;
    display: block;
    transform: rotate(-90deg);
    width: 150px;
    height: 100%;
    font-size: 18px;
    letter-spacing: 1px;
    z-index: 1;
    font-weight: 600;
    text-transform: uppercase
}

.toggleone.active span {
    display: none
}

.headerWrap {
    overflow: hidden
}

#divEight,#divFive,#divFour,#divOne,#divSeven,#divSevenn,#divSix,#divThree,#divTwo,#divsix_six,#divsix_six_one,#divtwo_two {
    outline: 0
}

.toggleone.active {
    display: none
}

.hero-badges-wrap.off-positions {
    position: inherit
}

.collapse.in {
    display: block;
    outline: 0!important
}

.iconbox-filled-hover.iconbox-light-onhover:hover p {
    color: #1e1666!important
}

.box-spacedl {
    padding: 0 30px
}

.boximage-graysd {
    background: #fff!important;
    color: #000!important;
    text-align: right;
    padding: 15px 15px 0
}

.boximage-graysd h6 {
    font-style: italic
}

.ourteam-images .pos-rel {
    display: block;
    background: #fff;
    box-shadow: 0 4px 9px 0 rgba(23,28,33,.06);
    border: 0;
    border-radius: 6px!important
}

.tabs-pane {
    outline: 0!important
}

.video-wrapper {
    position: relative
}

.video-wrapper>video {
    width: 100%;
    vertical-align: middle
}

.video-wrapper>video.has-media-controls-hidden::-webkit-media-controls {
    display: none
}

.video-overlay-play-button {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px calc(50% - 50px);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    opacity: .95;
    cursor: pointer;
    background-image: linear-gradient(transparent,#000);
    transition: opacity 150ms
}

.video-overlay-play-button:hover {
    opacity: 1
}

.video-overlay-play-button.is-hidden {
    display: none
}

.lqd-column.pull-up.custom-up {
    margin-top: -15%
}

.space-setting .pos-rel {
    margin-bottom: 25px
}

.space-setting .social-icon {
    font-size: 24px!important
}

.space-setting .social-icon i {
    color: #fff!important
}

.liquid-counter-element.liquid-counter-element-hover.ourhovers-white {
    background: none!important
}

.space-setting .ld-tm-name {
    font-size: 20px!important
}

.image-sizessc img {
    width: 60%;
    margin-top: 20px
}

.bg-dnone {
    display: none
}

.bg-settingsr {
    background-size: cover;
    background-repeat: no-repeat
}

.ournav-team h3.ld-tm-name {
    font-size: 20px
}

.blog-single-details.ourspce-cus {
    background: none!important
}

.form_div.bgs-whitesd {
    background: #fff!important
}

.industries-tabs ul li a {
    font-size: 13px;
    padding: 0!important;
    letter-spacing: normal;
    width: 100%
}

.industries-tabs ul li {
    width: 100%!important;
    text-align: left!important
}

.industries-tabs .tabs-nav li {
    justify-content: left!important
}

.lqd-frickin-img-holder figure {
    max-width: 460px!important;
    background-repeat: no-repeat!important
}

#spacetops .sticky-item {
    top: 63px!important
}

.oursidetabs.tabs-nav-side-alt .tabs-nav li {
    border-bottom: 0!important;
    font-size: 16px!important;
    font-weight: 600;
    text-transform: initial!important
}

.oursidetabs.tabs-nav-side-alt .tabs-nav {
    width: 100%!important
}

.oursidetabs.tabs.tabs-nav-side.tabs-nav-side-alt .tabs-content {
    width: 100%
}

.side-navspc-tabs li {
    margin-bottom: 8px;
    color: #000
}

a.our-btncolor {
    color: #000;
    margin-top: 0!important
}

a.our-btncolor :hover {
    color: #5174fd
}

.ourspcepos-t.lqd-parallax-iconboxes {
    margin-top: -122%
}

.radius-tabsd {
    border-radius: 6px!important
}

.oursp-tabs.filter-list-style-1 li {
    margin-bottom: 15px!important
}

.oursp-tabs.filter-list li span {
    display: inline-block;
    color: #000
}

.ourspcepos-t h2.heading3 {
    font-size: 22px!important;
    margin-bottom: 10px!important
}

a.div-c-box {
    color: #000!important
}

a.our-btncolor {
    font-size: 14px!important
}

a.our-btncolor:active,a.our-btncolor:visited {
    color: #000!important
}

.cimg-all {
    display: block;
    margin: 0 auto;
    text-align: center
}

@media only screen and (max-width: 1200px) {
    .page-blog-post .blog-single-details.t-zero12 {
        top:0!important
    }

    .blog-single-details.ourspce-cus {
        background: block!important
    }

    .iconbox h3 {
        font-size: 20px
    }

    .disnone12 {
        display: none
    }

    .minus-margins {
        margin-top: -50px
    }

    .blur-offd.height-autosd {
        height: auto!important
    }

    .blog-single-details.ourspce-cus {
        padding-top: 50px!important;
        padding-bottom: 30px!important
    }
}

@media only screen and (max-width: 1024px) {
    h1.f-size1024 {
        font-size:38px
    }

    .display-none-1024 {
        display: none!important
    }

    .nine-full .col-md-9 {
        width: 80%
    }

    .ico-spce.iconbox {
        height: 100%;
        margin-bottom: 0;
        padding-bottom: 30px!important
    }

    .nine-full {
        margin-bottom: 30px
    }

    .imgsize-sm img {
        width: 88%
    }
}

@media only screen and (max-width: 991px) {
    .ourspcepos-t.lqd-parallax-iconboxes {
        margin-top:-268px;
        width: 100%;
        padding-left: 20px!important
    }

    .fatherhood-stripe {
        padding-right: 0;
        padding-left: 0!important
    }

    .sm-100-w img {
        width: 100%!important
    }

    .ournav-team h3.ld-tm-name {
        font-size: 15px!important
    }

    .ournav-team h6.ld-tm-pos {
        font-size: 11px!important
    }

    .displaynone-1200s {
        display: none!important
    }

    h1.f-size1024 {
        font-size: 28px!important
    }

    .white-bgstriped .text-center.lefts h2 {
        text-align: center!important;
        display: block;
        width: 100%;
        margin: 0 auto
    }

    .dd-blocks {
        display: block!important
    }

    .white-bgstriped h2 {
        font-size: 18px!important
    }

    .sidemobiehictic:after {
        left: -52px;
        width: 80%
    }

    .fontsize-smd {
        font-size: 12px!important
    }

    .nine-full .col-md-9 {
        width: 100%
    }

    .sidemobiefoodly:after {
        left: -98px;
        background-size: 20%!important
    }

    .foodly-round:after {
        background-size: 40%!important;
        top: -297px!important
    }

    .sidemobie2:after {
        right: -100px;
        background-size: 30%!important
    }

    .sidemobie1:after {
        left: -58px;
        background-size: 12%!important
    }

    .tabs-nav-side-alt .tabs-content {
        width: 67.5%
    }

    .tabs-nav-side-alt .tabs-nav {
        width: 30%;
        margin-right: 15px
    }

    .tabs-nav-side {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .pb-btms.hero-badges-wrap {
        bottom: 40px!important
    }

    section.fullheight.headerWrap .video-banner-height {
        background: #4e01c1;
        background: -moz-linear-gradient(-45deg,rgba(78,1,193,1) 0,rgba(2,103,217,1) 100%);
        background: -webkit-linear-gradient(-45deg,rgba(78,1,193,1) 0,rgba(2,103,217,1) 100%);
        background: linear-gradient(135deg,rgba(78,1,193,1) 0,rgba(2,103,217,1) 100%)
    }
}

@media screen and (max-width: 767px) {
    .ourspcepos-t.lqd-parallax-iconboxes {
        margin-top:-338px;
        width: 100%
    }

    .our-logosd img {
        max-width: 100%!important
    }

    .mobilesize-av img {
        width: 40%!important;
        display: block;
        text-align: center;
        margin: 0 auto
    }

    .customfull-height.height-offd-mobile {
        height: auto;
        padding-bottom: 30px;
        text-align: center
    }

    #department-list .all {
        height: 100%;
        width: 100%!important
    }

    .ourvideo-mobileu iframe {
        width: 100%;
        margin-bottom: 20px
    }

    .ourcenter-mobile,.ourcenter-mobile .contents h3,.ourcenter-mobile .contents p {
        text-align: center;
        display: block
    }

    .headings-left-sm h6 {
        text-align: left
    }

    #department-list .all {
        margin-left: 0
    }

    .sm-100-w img {
        width: 60%!important
    }

    .sm-inlinesd figure.img-set-wd-60 {
        width: 49%;
        display: inline-block
    }

    .text-cens-tab img {
        text-align: center;
        display: block;
        margin: 0 auto!important
    }

    .ld-fancy-heading.center-all-center,.ld-fancy-heading.center-all-center p {
        text-align: Center;
        display: block;
        margin: 0 auto
    }

    .mobile-h-cus {
        text-align: center!important
    }

    .mobile-w-cus {
        margin: 0 auto;
        text-align: center;
        width: 60%
    }

    .centercenters {
        text-align: center
    }

    .text-center-xs {
        text-align: center!important
    }

    .centerall-m {
        text-align: center
    }

    .w-sm-80 {
        width: 80%;
        margin: 0 auto
    }

    .flex-offd {
        flex: auto!important
    }

    .tabs-nav-side-alt .tabs-content {
        width: 100%
    }

    .flex-offd .iconbox-icon-wrap {
        display: block!important;
        width: 100%!important
    }

    .tabs-nav-side-alt .tabs-nav {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px
    }

    .navbar-header .iconbox-icon-wrap {
        text-align: right
    }

    .row.row-off {
        margin-right: auto;
        margin-left: auto
    }

    .nine-full {
        margin-bottom: 0!important
    }

    .ico-spce.iconbox.text-left {
        text-align: left!important;
        display: block
    }

    .iconbox .iconbox-icon-container {
        justify-content: left
    }

    .paddings-section-image {
        padding: 40px 0!important
    }

    .ico-spce.iconbox {
        margin-bottom: 30px
    }

    .portfolio-forms a {
        position: relative;
        z-index: 999
    }

    .ourheight-mobilesd {
        height: 500px;
        display: block;
        overflow: initial!important
    }
}

@media screen and (max-width: 576px) {
    .dis-none-xxs {
        display:none!important
    }

    .navhides .carousel-nav {
        display: none
    }

    .minus-margins {
        margin-top: 0
    }

    .pb-btms.pb-60 {
        padding-bottom: 0!important
    }

    .ulbox-list img {
        position: initial;
        right: 0;
        width: 180px;
        top: 0
    }

    .Industries-icon figure h3 {
        font-size: 16px!important
    }

    .image-sizessc img {
        width: 100%;
        margin-top: 20px
    }
}

@media screen and (max-width: 380px) {
    .pb-btms ul.badges img {
        max-width:80px!important
    }

    .content-section-cu h2,.content-section-cu h3 {
        font-size: 11px!important
    }
}

@media screen and (max-width: 1199px) {
    .ld-float-form.our-blackbtns {
        display:none!important
    }

    .main-header .is-stuck {
        background: #fff!important
    }

    .ld-float-form.our-blackbtn {
        display: none!important
    }
}

@media screen and (max-width: 991px) {
    .sm-mobile-ico {
        text-align:center;
        width: 40px;
        height: 40px;
        line-height: 40px!important;
        background-color: #fff;
        border-radius: 999px;
        color: #39ca86
    }

    .main-header .is-stuck .sm-mobile-ico {
        background-color: #0643d6;
        color: #fff
    }

    h1.text-white.ltr-sp--015.h2 {
        font-size: 42px
    }

    .headerWrapCus .ld-fancy-heading {
        padding: 80px 0 25px!important
    }
}

@media (max-width: 767px) {
    .visible-sm {
        display:block!important
    }
}

@media (max-width: 680px) {
    .header-module~.header-module {
        margin-left:10px
    }
}

@media (max-width: 374px) {
    .header-module .iconbox {
        display:none
    }
}

@media only screen and (min-width: 1200px) {
    .page-work .stretch-to-right {
        margin-right:0!important
    }

    .page-work .stretch-to-left {
        margin-left: 0!important
    }
}

@media only screen and (max-width: 1200px) {
    .page-blog-post .blog-single-details {
        width:100%!important;
        position: absolute!important;
        top: 50%!important;
        left: 0;
        -webkit-transform: translateY(-50%)!important;
        transform: translateY(-50%)!important
    }

    .foodly-image-2-space img {
        position: relative;
        left: 50px
    }

    .img-second-sec-home img {
        position: relative;
        right: -150px
    }

    .img-one-curious img {
        position: relative;
        left: 45px
    }

    .section1-image-1-trans {
        transform: translateX(37.27px)!important
    }
}

@media only screen and (max-width: 1199px) {
    .foodly-image-2-space img {
        width:100%!important
    }
}

@media only screen and (max-width: 1100px) {
    .post-meta .block {
        font-size:10px
    }

    .image-rounds-sec img {
        position: relative;
        left: 160px!important
    }
}

@media only screen and (max-width: 1400px) {
    #awards-recognitions .flickity-slider .carousel-item {
        width:27.5%
    }

    .animation-images-scroll img {
        width: 85%
    }

    img.small-controlsimg {
        transform: translateX(75.16px)!important
    }

    .mobile-control-trns {
        transform: translateX(56.71px)
    }

    .image-rounds-sec img {
        position: relative;
        left: 290px
    }

    .spaceing-2nd img {
        right: -100px;
        position: relative
    }
}

@media only screen and (max-width: 1300px) {
    .our-imgfade {
        width:100%!important;
        overflow: inherit!important
    }

    #awards-recognitions .flickity-slider .carousel-item {
        width: 26%
    }
}

.page-404 {
    padding-top: 120px;
    padding-bottom: 120px
}

@media only screen and (max-width: 1200px) {
    .page-blog-post .blog-single-details {
        position:inherit!important;
        transform: inherit!important;
        -webkit-transform: inherit!important
    }

    .section1-image-1-trans {
        transform: translateX(35.27px)
    }

    img.section3-image-1 {
        transform: translateX(115.16px)!important
    }

    img.section3-image-2 {
        transform: translateX(98.94px)
    }

    .mt-1200-50 {
        margin-top: 50px!important
    }

    .mt-1200-30 {
        margin-top: 100px!important
    }

    button.flickity-button.flickity-prev-next-button.previous {
        left: -5px;
        width: 20px
    }

    button.flickity-button.flickity-prev-next-button.next {
        right: -5px;
        width: 20px
    }

    #section-three-boxed-grey .border-athens-gray {
        min-height: 260px!important
    }

    .sides-image-content-section .pt-7 {
        padding-top: 0!important
    }

    .pl-md-7.twelve-sc {
        padding-left: 0!important
    }

    .pr-md-7.twelve-sc {
        padding-right: 0!important
    }

    .pl-left-twleve,.pr-right-twleve {
        padding: 7%
    }

    #awards-recognitions .flickity-slider .carousel-item {
        width: 25%
    }

    .footerspace-m img {
        width: 25px!important
    }

    .company-address .iconbox-icon-container {
        margin-right: 10px
    }

    .company-address h3,.company-address p,.link-style h3,.link-style li a {
        font-size: 14px!important
    }

    .po-nav {
        position: absolute;
        width: 100%;
        left: 0!important
    }

    .po-nav ul {
        position: absolute;
        left: 200px!important
    }
}

@media only screen and (min-width: 1169px) {
    .cd-overlay.is-visible {
        -webkit-transform:translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px)
    }
}

@media only screen and (max-width: 1170px) {
    .page-solutions-chat-bot .tabs-nav-sm a {
        font-size:14px!important
    }

    .image-width-1170c img {
        width: 75%
    }

    .img-second-sec-home img {
        transform: translate(50px,100px)
    }

    img.section1-image-2-trans {
        position: relative;
        left: 50px!important
    }

    .img-one-curious img {
        position: relative;
        left: -25px
    }

    img.section2-image-2-trans {
        width: 75%!important;
        transform: translate(-12.03px,14.78px)
    }

    img.section2-image-1-trans {
        transform: translate(42.97px,3.78px);
        width: 88%
    }

    img.section3-image-1 {
        transform: translateX(52.16px)!important
    }

    img.section3-image-2 {
        transform: translateX(25.94px)
    }

    a.hide-d {
        display: block!important
    }

    .simple-banner-height-min {
        max-height: 750px;
        min-height: 750px
    }

    .simple-banner-height-700 {
        max-height: 750px
    }

    .tabs-custom-sm .tabs-nav-sm .tabs-nav li {
        margin-left: 10px;
        margin-right: 10px
    }

    #awards-recognitions .flickity-slider .carousel-item {
        width: 27.3%
    }

    .footerspace-m img {
        width: 25px!important
    }

    .company-address .iconbox-icon-container {
        margin-right: 10px
    }

    .company-address h3,.company-address p,.link-style h3,.link-style li a {
        font-size: 14px!important
    }

    .po-nav {
        position: absolute;
        width: 100%;
        left: 0!important
    }

    .po-nav ul {
        position: absolute;
        left: 200px!important
    }
}

@media only screen and (max-width: 1190px) {
    .box-numbers-mvp {
        height:100%
    }
}

@media only screen and (max-width: 1024px) {
    #awards-recognitions .flickity-slider .carousel-item {
        width:25%
    }
}

@media only screen and (max-width: 991px) {
    .tabs-nav.chat-icotab li {
        margin:0 10px!important
    }

    ul.nav.main-nav>li>a {
        background: #fff
    }

    .mainbar .main-nav-trigger,.mainbar .navbar-header {
        background: #FFF
    }

    .header-light .logo-dark {
        display: none!important
    }

    img.section2-image-2-trans {
        width: 82%!important
    }

    .img-one-curious img {
        left: 30px
    }

    .image-rounds-sec img {
        left: 80px!important
    }

    img.section2-image-1-trans {
        transform: translate(72.97px,3.78px);
        width: 75%!important
    }

    .img-second-sec-home img {
        transform: translate(-50px,80px)!important;
        width: 100%!important;
        position: relative;
        right: 80px
    }

    .image-rounds-sec img {
        position: relative
    }

    .foodly-image-2-space img {
        position: relative;
        left: -40px
    }

    .image-mes img {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        display: block
    }

    .dnone-sm {
        display: none
    }

    .section1-image-1-trans {
        transform: translateX(-76.73px)!important
    }

    .left-off-mobile .lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
        left: 20px
    }

    img.section2-image-2-trans {
        transform: translate(-37.03px,30.78px)
    }

    .liquid-img-group-single.single-mobile-left-off {
        right: 30px!important
    }

    img.section3-image-1 {
        transform: translateX(-3.84px)!important
    }

    img.section3-image-2 {
        transform: translateX(-78.06px)
    }

    .sm-mobile-ico {
        text-align: center;
        width: 40px;
        height: 40px;
        line-height: 40px!important;
        background-color: #0643d6;
        border-radius: 999px;
        color: #fff
    }

    span.number-rouand {
        width: 45px;
        height: 45px;
        font-size: 18px
    }

    .po-nav {
        position: initial!important;
        width: auto!important;
        left: 0!important
    }

    .po-nav ul {
        position: initial!important;
        left: 0!important
    }

    .box-numbers-mvp p {
        font-size: 15px!important
    }

    .ourblockchian-service .contentul-sm li {
        font-size: 15px
    }

    .font-size-sm-20 {
        font-size: 20px!important
    }

    .font-size-sm-30 {
        font-size: 30px!important
    }

    .sideban-small img {
        width: 70%;
        margin: 0 auto
    }

    .dblock-small {
        display: block!important
    }

    .pr-md-15 {
        padding-right: 0
    }

    .build-box-content {
        min-height: auto;
        min-height: 375px!important
    }

    #features .align-items-center {
        align-items: initial!important
    }

    .oursmall-about-res img {
        max-width: 100%;
        margin: 0 auto!important;
        text-align: center
    }

    #digital-project .liquid-img-group-single {
        margin: 0 auto;
        text-align: center;
        display: block
    }

    .responsive-images-abouts,.responsive-images-abouts .liquid-img-group-single {
        margin: 0 auto;
        text-align: center
    }

    #section-three-boxed-grey .border-athens-gray {
        min-height: 260px!important
    }

    .section-three-boxed-grey .contents.h3custom h3 {
        text-align: center!important;
        margin: 0 auto
    }

    #section-three-boxed-grey .lqd-column .contents.h3custom {
        margin: 0 auto;
        text-align: center;
        display: block;
        width: 100%!important
    }

    #section-three-boxed-grey .contents.h3custom h3 {
        min-height: auto!important
    }

    .fancy-title h2 {
        font-size: 28px!important
    }

    .fancy-title h2 .font-size-35 {
        font-size: 28px!important
    }

    .Industries-icon figure,.Technology-logos figure {
        width: 30%
    }

    .Technology-logos img {
        width: 100px
    }

    .commonh2-section h2 {
        font-size: 28px!important;
        margin-top: 30px!important
    }

    .ourimage-resizedd {
        width: 70%;
        margin-bottom: 40px
    }

    .h2space-reverse {
        text-align: center
    }

    .pb-65.commonh2-section {
        margin-bottom: 0!important
    }

    .vc_row.pb-65.commonh2-section {
        padding-bottom: 0!important
    }

    .sides-image-content-section .stretch-to-right {
        margin-right: 0!important
    }

    .sides-image-content-section .stretch-to-left {
        margin-left: 0!important
    }

    .pl-right-twleve,.pr-right-twleve {
        padding: 0!important
    }

    .h3-subheading-second {
        font-size: 28px!important
    }

    .box-icons-sub {
        margin-bottom: 10px;
        margin-top: 10px
    }

    .medium-btn-slider {
        background-color: #f88379!important;
        color: #fff;
        font-size: .8em
    }

    .simple-banner-height-700,.simple-banner-height-min {
        height: 100%
    }

    .simple-banner-height-min .mt-100 {
        margin-top: 0!important
    }

    .portfolio-banner-height {
        max-height: 100%
    }

    .iconbox_custom {
        padding: 1em
    }

    .video-banner-height {
        max-height: 100%
    }

    .simple-banner-height-min {
        max-height: 100%;
        min-height: 100%;
        padding: 100px 0 50px;
        text-align: center
    }

    .simple-banner-height-700 {
        max-height: 100%
    }

    .banner-bulits {
        text-align: left;
        width: 100%;
        padding: 0 15px
    }

    .section-img-m figure {
        margin: 0 auto;
        text-align: center;
        padding-bottom: 30px
    }

    figure.width-70-img {
        margin-top: 40px
    }

    .testimonials-quotes blockquote p {
        text-align: center
    }

    .auther-name-testimonials {
        margin: 0 auto;
        text-align: center
    }

    .testimonials-center-m {
        margin: 0 auto;
        text-align: center;
        display: block;
        width: 100%
    }

    .btn-white {
        background-color: #fff!important
    }

    .mt-m-3 {
        margin-top: 30px
    }

    .pos-ab {
        position: absolute
    }

    .hero-badges-wrap {
        position: absolute;
        width: 100%;
        bottom: 0!important
    }

    .hero-badges-wrap .badges-row h4 {
        margin-right: 10px;
        min-width: 75px
    }

    .heroslider-nav .carousel-nav {
        z-index: 999;
        top: 225px!important;
        position: absolute!important;
        width: 100%
    }

    .pt-sm-5-own {
        padding-top: 50px
    }

    .heroslider-nav button.flickity-button.flickity-prev-next-button.next {
        right: 0!important;
        position: absolute!important
    }

    .heroslider-nav button.flickity-button.flickity-prev-next-button.previous {
        left: 0!important;
        position: absolute!important
    }

    #awards-recognitions .flickity-slider .carousel-item {
        width: 35%
    }

    .mobileimage-sc {
        width: 50%!important
    }

    .hero-badges-wrap .badges-row {
        width: 45%!important
    }

    .badges-row.pb-sm-1-own {
        padding-bottom: 0!important
    }

    .hero-badges-wrap .badges-row .badges li:not(:last-child) {
        margin-right: 22px
    }

    .hover-bxdmain h3 {
        font-size: 20px
    }

    .h3-991 h3 {
        font-size: 20px!important
    }

    .company-address .iconbox-icon-wrap {
        display: none
    }

    .main-footer .footer-bottom *,.main-footer .footer-middle * {
        font-size: 14px
    }

    .liquid-counter-md .liquid-counter-element {
        font-size: 34px
    }

    .footerspace-m h3,h3.emp-name {
        font-size: 18px!important
    }

    .client-trans-number .ltr-sp-2 {
        letter-spacing: normal!important
    }

    .Industries-iconred img {
        width: 50px!important;
        display: inline-block
    }

    .Industries-iconred figure h3 {
        font-size: 16px!important
    }

    .blockchains .Industries-icon figure h3 {
        padding-top: 0!important
    }

    #section-three-boxed-grey .contents.h3custom h3 {
        font-size: 18px!important;
        line-height: normal!important
    }

    .ourblockchian-service {
        padding-top: 20px;
        padding-bottom: 20px
    }

    .blockchains .Industries-icon figure {
        width: 20%
    }

    .gredient-section h3 {
        font-size: 20px!important
    }

    .banner-autoheight {
        min-height: auto;
        padding: 100px 0 40px
    }

    .white-bgstriped .text-center h2 {
        text-align: left!important
    }

    h1 {
        font-size: 30px!important;
        line-height: normal
    }

    h2 {
        font-size: 28px!important;
        line-height: normal
    }

    h3,h4,h5 {
        font-size: 20px!important;
        line-height: normal
    }

    p {
        line-height: normal
    }

    .pt-sm-0-own {
        padding-top: 0!important
    }

    .pt-sm-1-own {
        padding-top: 10px!important
    }

    .pt-sm-2-own {
        padding-top: 20px!important
    }

    .pt-sm-3-own {
        padding-top: 30px!important
    }

    .pt-sm-4-own {
        padding-top: 40px!important
    }

    .pt-sm-5-own {
        padding-top: 50px!important
    }

    .pt-sm-6-own {
        padding-top: 60px!important
    }

    .pt-sm-7-own {
        padding-top: 70px!important
    }

    .pt-sm-8-own {
        padding-top: 80px!important
    }

    .pt-sm-9-own {
        padding-top: 90px!important
    }

    .pt-sm-10-own {
        padding-top: 100px!important
    }

    .pb-sm-0-own {
        padding-bottom: 0!important
    }

    .pb-sm-1-own {
        padding-bottom: 10px!important
    }

    .pb-sm-2-own {
        padding-bottom: 20px!important
    }

    .pb-sm-3-own {
        padding-bottom: 30px!important
    }

    .pb-sm-4-own {
        padding-bottom: 40px!important
    }

    .pb-sm-5-own {
        padding-bottom: 50px!important
    }

    .pb-sm-6-own {
        padding-bottom: 60px!important
    }

    .pb-sm-7-own {
        padding-bottom: 70px!important
    }

    .pb-sm-8-own {
        padding-bottom: 80px!important
    }

    .pb-sm-9-own {
        padding-bottom: 90px!important
    }

    .pb-sm-10-own {
        padding-bottom: 100px!important
    }

    .pr-sm-0-own {
        padding-right: 0!important
    }

    .pr-sm-1-own {
        padding-right: 10px!important
    }

    .pr-sm-2-own {
        padding-right: 20px!important
    }

    .pr-sm-3-own {
        padding-right: 30px!important
    }

    .pr-sm-4-own {
        padding-right: 40px!important
    }

    .pr-sm-5-own {
        padding-right: 50px!important
    }

    .pr-sm-6-own {
        padding-right: 60px!important
    }

    .pr-sm-7-own {
        padding-right: 70px!important
    }

    .pr-sm-8-own {
        padding-right: 80px!important
    }

    .pr-sm-9-own {
        padding-right: 90px!important
    }

    .pr-sm-10-own {
        padding-right: 100px!important
    }

    .pl-sm-0-own {
        padding-left: 0!important
    }

    .pl-sm-1-own {
        padding-left: 10px!important
    }

    .pl-sm-2-own {
        padding-left: 20px!important
    }

    .pl-sm-3-own {
        padding-left: 30px!important
    }

    .pl-sm-4-own {
        padding-left: 40px!important
    }

    .pl-sm-5-own {
        padding-left: 50px!important
    }

    .pl-sm-6-own {
        padding-left: 60px!important
    }

    .pl-sm-7-own {
        padding-left: 70px!important
    }

    .pl-sm-8-own {
        padding-left: 80px!important
    }

    .pl-sm-9-own {
        padding-left: 90px!important
    }

    .pl-sm-10-own {
        padding-left: 100px!important
    }

    .mt-sm-0-own {
        margin-top: 0!important
    }

    .mt-sm-1-own {
        margin-top: 10px!important
    }

    .mt-sm-2-own {
        margin-top: 20px!important
    }

    .mt-sm-3-own {
        margin-top: 30px!important
    }

    .mt-sm-4-own {
        margin-top: 40px!important
    }

    .mt-sm-5-own {
        margin-top: 50px!important
    }

    .mt-sm-6-own {
        margin-top: 60px!important
    }

    .mt-sm-7-own {
        margin-top: 70px!important
    }

    .mt-sm-8-own {
        margin-top: 80px!important
    }

    .mt-sm-9-own {
        margin-top: 90px!important
    }

    .mt-sm-10-own {
        margin-top: 100px!important
    }

    .mb-sm-0-own {
        margin-bottom: 0!important
    }

    .mb-sm-1-own {
        margin-bottom: 10px!important
    }

    .mb-sm-2-own {
        margin-bottom: 20px!important
    }

    .mb-sm-3-own {
        margin-bottom: 30px!important
    }

    .mb-sm-4-own {
        margin-bottom: 40px!important
    }

    .mb-sm-5-own {
        margin-bottom: 50px!important
    }

    .mb-sm-6-own {
        margin-bottom: 60px!important
    }

    .mb-sm-7-own {
        margin-bottom: 70px!important
    }

    .mb-sm-8-own {
        margin-bottom: 80px!important
    }

    .mb-sm-9-own {
        margin-bottom: 90px!important
    }

    .mb-sm-10-own {
        margin-bottom: 100px!important
    }

    .mr-sm-0-own {
        margin-right: 0!important
    }

    .mr-sm-1-own {
        margin-right: 10px!important
    }

    .mr-sm-2-own {
        margin-right: 20px!important
    }

    .mr-sm-3-own {
        margin-right: 30px!important
    }

    .mr-sm-4-own {
        margin-right: 40px!important
    }

    .mr-sm-5-own {
        margin-right: 50px!important
    }

    .mr-sm-6-own {
        margin-right: 60px!important
    }

    .mr-sm-7-own {
        margin-right: 70px!important
    }

    .mr-sm-8-own {
        margin-right: 80px!important
    }

    .mr-sm-9-own {
        margin-right: 90px!important
    }

    .mr-sm-10-own {
        margin-right: 100px!important
    }

    .ml-sm-0-own {
        margin-left: 0!important
    }

    .ml-sm-1-own {
        margin-left: 10px!important
    }

    .ml-sm-2-own {
        margin-left: 20px!important
    }

    .ml-sm-3-own {
        margin-left: 30px!important
    }

    .ml-sm-4-own {
        margin-left: 40px!important
    }

    .ml-sm-5-own {
        margin-left: 50px!important
    }

    .ml-sm-6-own {
        margin-left: 60px!important
    }

    .ml-sm-7-own {
        margin-left: 70px!important
    }

    .ml-sm-8-own {
        margin-left: 80px!important
    }

    .ml-sm-9-own {
        margin-left: 90px!important
    }

    .ml-sm-10-own {
        margin-left: 100px!important
    }

    .ourblockchian-service h2 {
        font-size: 20px!important
    }

    .cubix-chain-logo h3 {
        font-size: 15px!important
    }

    .navbar-collapse {
        max-height: calc(100vh)!important;
        overflow-y: auto!important
    }

    .navbar-collapse.in {
        overflow-y: auto!important;
        display: block!important;
        height: 100vh!important
    }

    .d-sm-none {
        display: none!important
    }

    .our-navbar-responsive .link-txt {
        width: 100%
    }

    .our-posd-nav {
        position: absolute;
        left: 94%
    }

    .main-nav .link-txt .txt {
        width: 100%!important;
        display: block
    }
}

@media only screen and (max-width: 767px) {
    .text-portfolion {
        width:80%;
        text-align: center;
        padding: 0 20px;
        display: block;
        margin: 0 auto
    }

    .img-second-sec-home img {
        transform: translate(50px,80px)!important
    }

    .foodly-image-2-space img,.img-one-curious img {
        position: relative;
        left: 0
    }

    .page-blog-post .post-meta {
        flex-direction: inherit!important
    }

    .page-blog-post .post-meta span {
        margin: 0 20px 0 0
    }

    .page-work .backgroud_image_left,.page-work .backgroud_image_right {
        background-size: cover!important
    }

    .page-work section.vc_row.work-item .row {
        height: 100%!important
    }

    .cubix-chain-logo h3 {
        font-size: 15px!important
    }

    .page-solutions-chat-bot .tabs-nav-sm a {
        font-size: 13px!important;
        margin-top: 15px
    }

    .own-img-sm-smallsd img {
        width: 70%
    }

    .d-flex-noned.d-flex.d-on-smd {
        display: flex!important
    }

    .tabs-nav-sm .tabs-nav {
        flex-direction: initial!important
    }

    .tabs-nav-sm .tabs-nav li {
        width: 20%
    }

    .d-flex-noned.d-flex {
        display: initial!important
    }

    .content-withhead {
        width: 100%
    }

    .sm-img-sliders img {
        width: 70%;
        margin: 0 auto
    }

    .iconbox_custom {
        padding: 2em
    }

    img.section1-image-2-trans {
        transform: translatex(5.27px);
        width: 85%!important
    }

    img.section1-image-3-trans {
        transform: translate(64.97px,-1.22px)
    }

    .spaceing-2nd img {
        right: 0;
        position: relative
    }

    .img-second-sec img {
        position: relative;
        right: 0
    }

    img.section3-image-2 {
        transform: translateX(6.94px)
    }

    img.section2-image-2-trans {
        width: 80%!important
    }

    .img-icon-head {
        width: 100%!important;
        display: inline-block
    }

    .mobilecenterwave .sonar-emitter {
        width: 70px!important;
        margin: 0 auto
    }

    .mobile-width-m img {
        width: 60%
    }

    .text-center-mobile {
        margin: 0 auto;
        text-align: center
    }

    .tabs-nav.text-leftown li,.tabs-nav.text-rightown li,.text-leftown,.text-rightown {
        text-align: center!important
    }

    .video-banner-height-min a.btn.medium-btn-slider {
        text-align: center!important;
        margin: 0 auto!important;
        width: 200px;
        display: block!important
    }

    .flexoofed.d-flex {
        flex: initial!important;
        display: block!important;
        text-align: center;
        width: 100%!important
    }

    .flexoof .d-flex {
        flex: initial!important;
        display: block!important;
        text-align: center;
        width: 100%
    }

    span.number-rouand {
        width: 45px;
        height: 45px;
        font-size: 18px;
        margin: 0 auto 10px
    }

    .flex-xs-column-reverse-own-md {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .min-h-two,.ourblockchian-service {
        height: auto!important
    }

    .white-bgstriped .text-center h2 {
        text-align: center!important
    }

    .nector-small .nectar-fancy-box {
        padding: 20px;
        min-height: auto;
        text-align: center
    }

    .nector-small .inner-bxd {
        min-height: auto
    }

    .min-h-one,.min-h-two,.ourblockchian-service {
        min-height: auto!important
    }

    .video-banner-height-min {
        max-height: 100%;
        min-height: 100%;
        padding: 100px 0 50px
    }

    .row-own {
        display: inline-block;
        width: 100%
    }

    .video-banner-height-min {
        text-align: left!important
    }

    .video-banner-height-min h1 {
        text-align: center!important
    }

    .heading-ico {
        text-align: center;
        margin: 0 auto;
        display: block!important
    }

    .video-banner-height-min .ld-fancy-heading p {
        text-align: center!important;
        margin: 0 auto;
        width: 100%!important
    }

    .mobilesize blockquote.testimonial-font-size {
        font-size: 16px!important
    }

    .mobilesize .auther-name-testimonials p {
        font-size: 20px!important;
        padding-bottom: 10px
    }

    .mobile-center {
        text-align: center!important
    }

    .phone-num-color {
        color: #fff!important
    }

    .playbtn-svgs .play_button_with_text {
        margin: 0 auto
    }

    .d-flex-noned.d-flex.flex-wrap {
        display: inherit!important
    }

    .d-flex-none.d-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: initial!important;
        flex-wrap: initial!important
    }

    .row-flex-own {
        display: initial;
        flex-wrap: initial
    }

    .t-center-m a {
        display: block;
        text-align: left;
        margin: 0 0 10px
    }

    .portfolio-banner-height-min {
        max-height: 100%;
        min-height: 100%;
        padding: 100px 0 50px;
        text-align: center
    }

    .portfolio-banner-height-min a.btn.medium-btn-slider {
        text-align: center!important;
        margin: 0 auto;
        max-width: 220px;
        display: block!important
    }

    .portfolio-banner-height-min h1 {
        text-align: center!important
    }

    .portfolio-banner-height-min .ld-fancy-heading p {
        text-align: center!important;
        margin: 0 auto;
        width: 100%!important
    }

    .about-logos img {
        width: 18%!important
    }

    .our-footernv h3 {
        display: none!important
    }

    .company-address .iconbox-custom {
        margin-bottom: 10px!important
    }

    .footerspace-m .lqd-column:first-child {
        margin-top: 0
    }

    .footerspace-m .lqd-column {
        margin-top: 30px
    }

    .main-footer .footer-bottom *,.main-footer .footer-middle * {
        font-size: 18px!important
    }

    .company-address .iconbox-icon-wrap {
        display: block
    }

    .company-address p {
        margin-bottom: 0
    }

    .badges-row.pb-sm-1-own {
        padding-bottom: 10px!important
    }

    .hero-badges-wrap .badges-row {
        width: 100%!important
    }

    .iconbox.text-left {
        -webkit-box-align: center;
        -ms-flex-align: start;
        align-items: center
    }

    .hero-badges h4 {
        display: none!important
    }

    .hero-badges-wrap .badges-row .badges li {
        display: inline-block!important;
        width: 12%
    }

    .hero-badges-wrap .badges-row {
        display: inline-block!important;
        -ms-flex-align: center;
        text-align: center!important
    }

    .hero-badges-wrap .badges-row .badges {
        list-style: none;
        display: -ms-flexbox;
        display: block;
        -ms-flex-align: center;
        margin: 0;
        padding: 0;
        width: 100%!important
    }

    #section-three-boxed-grey .border-athens-gray {
        min-height: auto!important;
        width: 50%;
        margin: 0 auto
    }

    .customfull-height-contact {
        height: auto!important
    }

    .height-process {
        display: block
    }

    .box-process-left,.box-process-right {
        text-align: center
    }

    .process-icon-content img {
        width: 100px;
        padding: 20px 0
    }

    .process-icon-content .elvn {
        width: 100px!important;
        padding: 20px 0
    }

    .contentul {
        text-align: left
    }

    .heading-ico {
        margin-top: 10px!important
    }

    .pt-xs-0-own {
        padding-top: 0!important
    }

    .pt-xs-1-own {
        padding-top: 10px!important
    }

    .pt-xs-2-own {
        padding-top: 20px!important
    }

    .pt-xs-3-own {
        padding-top: 30px!important
    }

    .pt-xs-4-own {
        padding-top: 40px!important
    }

    .pt-xs-5-own {
        padding-top: 50px!important
    }

    .pt-xs-6-own {
        padding-top: 60px!important
    }

    .pt-xs-7-own {
        padding-top: 70px!important
    }

    .pt-xs-8-own {
        padding-top: 80px!important
    }

    .pt-xs-9-own {
        padding-top: 90px!important
    }

    .pt-xs-10-own {
        padding-top: 100px!important
    }

    .pb-xs-0-own {
        padding-bottom: 0!important
    }

    .pb-xs-1-own {
        padding-bottom: 10px!important
    }

    .pb-xs-2-own {
        padding-bottom: 20px!important
    }

    .pb-xs-3-own {
        padding-bottom: 30px!important
    }

    .pb-xs-4-own {
        padding-bottom: 40px!important
    }

    .pb-xs-5-own {
        padding-bottom: 50px!important
    }

    .pb-xs-6-own {
        padding-bottom: 60px!important
    }

    .pb-xs-7-own {
        padding-bottom: 70px!important
    }

    .pb-xs-8-own {
        padding-bottom: 80px!important
    }

    .pb-xs-9-own {
        padding-bottom: 90px!important
    }

    .pb-xs-10-own {
        padding-bottom: 100px!important
    }

    .pr-xs-0-own {
        padding-right: 0!important
    }

    .pr-xs-1-own {
        padding-right: 10px!important
    }

    .pr-xs-2-own {
        padding-right: 20px!important
    }

    .pr-xs-3-own {
        padding-right: 30px!important
    }

    .pr-xs-4-own {
        padding-right: 40px!important
    }

    .pr-xs-5-own {
        padding-right: 50px!important
    }

    .pr-xs-6-own {
        padding-right: 60px!important
    }

    .pr-xs-7-own {
        padding-right: 70px!important
    }

    .pr-sm-8-own {
        padding-right: 80px!important
    }

    .pr-xs-9-own {
        padding-right: 90px!important
    }

    .pr-xs-10-own {
        padding-right: 100px!important
    }

    .pl-xs-0-own {
        padding-left: 0!important
    }

    .pl-xs-1-own {
        padding-left: 10px!important
    }

    .pl-xs-2-own {
        padding-left: 20px!important
    }

    .pl-xs-3-own {
        padding-left: 30px!important
    }

    .pl-xs-4-own {
        padding-left: 40px!important
    }

    .pl-xs-5-own {
        padding-left: 50px!important
    }

    .pl-xs-6-own {
        padding-left: 60px!important
    }

    .pl-xs-7-own {
        padding-left: 70px!important
    }

    .pl-xs-8-own {
        padding-left: 80px!important
    }

    .pl-xs-9-own {
        padding-left: 90px!important
    }

    .pl-xs-10-own {
        padding-left: 100px!important
    }

    .mt-xs-0-own {
        margin-top: 0!important
    }

    .mt-xs-1-own {
        margin-top: 10px!important
    }

    .mt-xs-2-own {
        margin-top: 20px!important
    }

    .mt-xs-3-own {
        margin-top: 30px!important
    }

    .mt-xs-4-own {
        margin-top: 40px!important
    }

    .mt-xs-5-own {
        margin-top: 50px!important
    }

    .mt-xs-6-own {
        margin-top: 60px!important
    }

    .mt-xs-7-own {
        margin-top: 70px!important
    }

    .mt-xs-8-own {
        margin-top: 80px!important
    }

    .mt-xs-9-own {
        margin-top: 90px!important
    }

    .mt-xs-10-own {
        margin-top: 100px!important
    }

    .mb-xs-0-own {
        margin-bottom: 0!important
    }

    .mb-xs-1-own {
        margin-bottom: 10px!important
    }

    .mb-xs-2-own {
        margin-bottom: 20px!important
    }

    .mb-xs-3-own {
        margin-bottom: 30px!important
    }

    .mb-xs-4-own {
        margin-bottom: 40px!important
    }

    .mb-xs-5-own {
        margin-bottom: 50px!important
    }

    .mb-xs-6-own {
        margin-bottom: 60px!important
    }

    .mb-xs-7-own {
        margin-bottom: 70px!important
    }

    .mb-xs-8-own {
        margin-bottom: 80px!important
    }

    .mb-xs-9-own {
        margin-bottom: 90px!important
    }

    .mb-xs-10-own {
        margin-bottom: 100px!important
    }

    .mr-xs-0-own {
        margin-right: 0!important
    }

    .mr-xs-1-own {
        margin-right: 10px!important
    }

    .mr-xs-2-own {
        margin-right: 20px!important
    }

    .mr-xs-3-own {
        margin-right: 30px!important
    }

    .mr-xs-4-own {
        margin-right: 40px!important
    }

    .mr-xs-5-own {
        margin-right: 50px!important
    }

    .mr-xs-6-own {
        margin-right: 60px!important
    }

    .mr-xs-7-own {
        margin-right: 70px!important
    }

    .mr-xs-8-own {
        margin-right: 80px!important
    }

    .mr-xs-9-own {
        margin-right: 90px!important
    }

    .mr-xs-10-own {
        margin-right: 100px!important
    }

    .ml-xs-0-own {
        margin-left: 0!important
    }

    .ml-xs-1-own {
        margin-left: 10px!important
    }

    .ml-xs-2-own {
        margin-left: 20px!important
    }

    .ml-xs-3-own {
        margin-left: 30px!important
    }

    .ml-xs-4-own {
        margin-left: 40px!important
    }

    .ml-xs-5-own {
        margin-left: 50px!important
    }

    .ml-xs-6-own {
        margin-left: 60px!important
    }

    .ml-xs-7-own {
        margin-left: 70px!important
    }

    .ml-xs-8-own {
        margin-left: 80px!important
    }

    .ml-xs-9-own {
        margin-left: 90px!important
    }

    .ml-xs-10-own {
        margin-left: 100px!important
    }

    .min-h-one {
        height: auto!important
    }

    .text-center-sm {
        text-align: center!important;
        margin: 0 auto!important;
        display: block!important
    }

    .text-center-991 {
        text-align: center!important
    }

    .pr-10,.pr-20,.pr-30,.pr-40,.pr-50 {
        padding-right: 0
    }

    .pl-10,.pl-20,.pl-30,.pl-40,.pl-50 {
        padding-left: 0
    }

    .logo-images-des img {
        width: 50%
    }

    .build-box-content {
        min-height: auto!important
    }

    .ournav-team .tabs-nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: initial;
        flex-direction: initial
    }

    h1 {
        font-size: 30px!important
    }

    #features h5,.gredient-section h3,h2 {
        font-size: 28px!important
    }

    .liquid-counter-md .liquid-counter-element {
        font-size: 37px
    }

    .mb-150.ourspace-control {
        margin-bottom: 100px!important
    }

    #section-three-boxed-grey {
        padding: 70px 0!important
    }

    .client-trans-number .ltr-sp-2 {
        letter-spacing: 0!important
    }

    .sides-image-content-section .pl-md-7,.sides-image-content-section .pr-md-7 {
        padding-left: 0!important
    }

    .white-bgstriped h2 {
        padding-bottom: 20px
    }

    .flex-xs-column-reverse-own {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .own-row-reverse {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .own-row-reverse>div {
        width: 100%
    }

    .sideban-small,.sideban-small img {
        height: 100%!important
    }

    .btn-white {
        background-color: #fff!important
    }

    .tabs-nav-sm.lispace .tabs-nav a {
        padding-left: 5px;
        padding-right: 5px
    }

    #testimonials .testimonial-content {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 700;
        color: #000
    }

    .lqd-column.our-footernv {
        margin-top: 0
    }

    .aboutmain-img .liquid-img-group-single {
        width: 50%;
        margin: 0 auto
    }

    #awards-recognitions .flickity-slider .carousel-item {
        width: 33.5%
    }

    .our-posd-nav {
        position: absolute;
        left: 92%
    }
}

@media only screen and (max-width: 576px) {
    .cubix-chain-logo figure {
        width:30%
    }

    .popup-form {
        width: 100%!important
    }

    .content-section-cu h2 {
        font-size: 12px!important
    }

    .paddings-section-image {
        padding: 10px 0!important
    }

    .content-section-cu h3 {
        font-size: 12px!important
    }

    .sidemobiefoodly:after {
        left: -64px;
        background-size: 13%!important
    }

    .foodly-round:after {
        background-size: 26%!important;
        top: -417px!important;
        left: 23px
    }

    .page-blog-post .blog-single-details {
        top: 70%!important
    }

    .tabs-nav-sm .tabs-nav li {
        width: 45%
    }

    .pt-xxs-0-own {
        padding-top: 0!important
    }

    .pt-xxs-1-own {
        padding-top: 10px!important
    }

    .pt-xxs-2-own {
        padding-top: 20px!important
    }

    .pt-xxs-3-own {
        padding-top: 30px!important
    }

    .pt-xxs-4-own {
        padding-top: 40px!important
    }

    .pt-xxs-5-own {
        padding-top: 50px!important
    }

    .pt-xxs-6-own {
        padding-top: 60px!important
    }

    .pt-xxs-7-own {
        padding-top: 70px!important
    }

    .pt-xxs-8-own {
        padding-top: 80px!important
    }

    .pt-xxs-9-own {
        padding-top: 90px!important
    }

    .pt-xxs-10-own {
        padding-top: 100px!important
    }

    .pb-xxs-0-own {
        padding-bottom: 0!important
    }

    .pb-xxs-1-own {
        padding-bottom: 10px!important
    }

    .pb-xxs-2-own {
        padding-bottom: 20px!important
    }

    .pb-xxs-3-own {
        padding-bottom: 30px!important
    }

    .pb-xxs-4-own {
        padding-bottom: 40px!important
    }

    .pb-xxs-5-own {
        padding-bottom: 50px!important
    }

    .pb-xxs-6-own {
        padding-bottom: 60px!important
    }

    .pb-xxs-7-own {
        padding-bottom: 70px!important
    }

    .pb-xxs-8-own {
        padding-bottom: 80px!important
    }

    .pb-xxs-9-own {
        padding-bottom: 90px!important
    }

    .pb-xxs-10-own {
        padding-bottom: 100px!important
    }

    .pr-xxs-0-own {
        padding-right: 0!important
    }

    .pr-xxs-1-own {
        padding-right: 10px!important
    }

    .pr-xxs-2-own {
        padding-right: 20px!important
    }

    .pr-xxs-3-own {
        padding-right: 30px!important
    }

    .pr-xxs-4-own {
        padding-right: 40px!important
    }

    .pr-xxs-5-own {
        padding-right: 50px!important
    }

    .pr-xxs-6-own {
        padding-right: 60px!important
    }

    .pr-xxs-7-own {
        padding-right: 70px!important
    }

    .pr-xxs-8-own {
        padding-right: 80px!important
    }

    .pr-xxs-9-own {
        padding-right: 90px!important
    }

    .pr-xxs-10-own {
        padding-right: 100px!important
    }

    .pl-xxs-0-own {
        padding-left: 0!important
    }

    .pl-xxs-1-own {
        padding-left: 10px!important
    }

    .pl-xxs-2-own {
        padding-left: 20px!important
    }

    .pl-xxs-3-own {
        padding-left: 30px!important
    }

    .pl-xxs-4-own {
        padding-left: 40px!important
    }

    .pl-xxs-5-own {
        padding-left: 50px!important
    }

    .pl-xxs-6-own {
        padding-left: 60px!important
    }

    .pl-xxs-7-own {
        padding-left: 70px!important
    }

    .pl-xxs-8-own {
        padding-left: 80px!important
    }

    .pl-xxs-9-own {
        padding-left: 90px!important
    }

    .pl-xxs-10-own {
        padding-left: 100px!important
    }

    .mt-xxs-0-own {
        margin-top: 0!important
    }

    .mt-xxs-1-own {
        margin-top: 10px!important
    }

    .mt-xxs-2-own {
        margin-top: 20px!important
    }

    .mt-xxs-3-own {
        margin-top: 30px!important
    }

    .mt-xxs-4-own {
        margin-top: 40px!important
    }

    .mt-xxs-5-own {
        margin-top: 50px!important
    }

    .mt-xxs-6-own {
        margin-top: 60px!important
    }

    .mt-xxs-7-own {
        margin-top: 70px!important
    }

    .mt-xxs-8-own {
        margin-top: 80px!important
    }

    .mt-xxs-9-own {
        margin-top: 90px!important
    }

    .mt-xxs-10-own {
        margin-top: 100px!important
    }

    .mb-xxs-0-own {
        margin-bottom: 0!important
    }

    .mb-xxs-1-own {
        margin-bottom: 10px!important
    }

    .mb-xxs-2-own {
        margin-bottom: 20px!important
    }

    .mb-xxs-3-own {
        margin-bottom: 30px!important
    }

    .mb-xxs-4-own {
        margin-bottom: 40px!important
    }

    .mb-xxs-5-own {
        margin-bottom: 50px!important
    }

    .mb-xxs-6-own {
        margin-bottom: 60px!important
    }

    .mb-xxs-7-own {
        margin-bottom: 70px!important
    }

    .mb-xxs-8-own {
        margin-bottom: 80px!important
    }

    .mb-xxs-9-own {
        margin-bottom: 90px!important
    }

    .mb-xxs-10-own {
        margin-bottom: 100px!important
    }

    .mr-xxs-0-own {
        margin-right: 0!important
    }

    .mr-xxs-1-own {
        margin-right: 10px!important
    }

    .mr-xxs-2-own {
        margin-right: 20px!important
    }

    .mr-xxs-3-own {
        margin-right: 30px!important
    }

    .mr-xxs-4-own {
        margin-right: 40px!important
    }

    .mr-xxs-5-own {
        margin-right: 50px!important
    }

    .mr-xxs-6-own {
        margin-right: 60px!important
    }

    .mr-xxs-7-own {
        margin-right: 70px!important
    }

    .mr-xxs-8-own {
        margin-right: 80px!important
    }

    .mr-xxs-9-own {
        margin-right: 90px!important
    }

    .mr-xxs-10-own {
        margin-right: 100px!important
    }

    .ml-xxs-0-own {
        margin-left: 0!important
    }

    .ml-xxs-1-own {
        margin-left: 10px!important
    }

    .ml-xxs-2-own {
        margin-left: 20px!important
    }

    .ml-xxs-3-own {
        margin-left: 30px!important
    }

    .ml-xxs-4-own {
        margin-left: 40px!important
    }

    .ml-xxs-5-own {
        margin-left: 50px!important
    }

    .ml-xxs-6-own {
        margin-left: 60px!important
    }

    .ml-xxs-7-own {
        margin-left: 70px!important
    }

    .ml-xxs-8-own {
        margin-left: 80px!important
    }

    .ml-xxs-9-own {
        margin-left: 90px!important
    }

    .ml-xxs-10-own {
        margin-left: 100px!important
    }

    .headers-logos img {
        width: 100px!important
    }

    .Industries-iconred figure h3 {
        width: 54%;
        display: block!important;
        margin: 0 auto
    }

    .Industries-iconred figure {
        width: 49%;
        display: inline-block;
        margin: 0 auto;
        text-align: center
    }

    .container {
        width: 100%
    }

    .content .h3,.content h3 {
        font-size: 20px
    }

    .blockchains .Industries-icon figure {
        width: 30%
    }

    .contents-sm h3 {
        font-size: 20px!important
    }

    .contents-sm-small img {
        max-width: 100px!important
    }

    .contents-sm p,.contents-sm-small h3 {
        font-size: 18px
    }

    .margin-0-nav .carousel-nav {
        margin-top: 0!important
    }

    .text-right.margin-0-nav {
        text-align: center!important
    }

    #awards-recognitions .flickity-slider .carousel-item {
        width: 80%
    }

    #section-three-boxed-grey .border-athens-gray,.youtubevideo-xs .col-xxs-12 {
        width: 100%
    }

    .about-logos img {
        width: 25%!important
    }

    .h3-991 h3 {
        font-size: 18px!important
    }

    .aboutmain-img .liquid-img-group-single {
        width: 100%
    }

    .center-box-div.center-box-div-sm {
        text-align: initial!important;
        justify-content: inherit!important;
        justify-content: center;
        align-items: initial!important;
        display: block
    }

    .testimonail-sm .testimonial-details {
        text-align: center;
        margin: 0 auto
    }

    .testimonail-sm .testimonial-quote {
        text-align: center
    }

    img.section1-image-2-trans {
        width: 100%
    }

    img.section2-image-2-trans {
        transform: translate(-.03px,29.78px)
    }

    .flexoofed.d-flex {
        width: auto!important
    }

    .egrocesser-mobile-images img {
        width: 100%
    }

    img.section1-image-2-trans {
        transform: translatex(50.27px)
    }

    img.section2-image-2-trans {
        width: 100%!important;
        transform: translate(27.97px,14.78px)!important
    }

    .section1-image-1-trans {
        transform: translateX(-49.73px)
    }

    img.section1-image-3-trans {
        transform: translate(-35.03px,-53.22px);
        width: 100%
    }

    .lqd-parallax-images-3 .liquid-img-group-single:last-child {
        right: 15%!important;
        left: 15px!important
    }

    .egrocerlast-bg img {
        width: 100%
    }

    .our-posd-nav {
        position: absolute;
        left: 90%
    }
}

@media only screen and (max-width: 510px) {
    .oursmall-about-res img {
        max-width:100%!important
    }

    .home-reverse-mobile {
        display: flex;
        flex-direction: row-reverse
    }

    .mobile-ninth-f img {
        width: 75%!important
    }
}

@media only screen and (max-width: 490px) {
    .slider-menu-contact {
        width:95%!important;
        margin-top: 30px
    }

    .hero-badges-wrap {
        bottom: -20px!important
    }

    .hero-badges-wrap .badges-row .badges li {
        width: 13%
    }

    .tabs-nav-sm .tabs-nav li {
        width: 40%
    }
}

@media only screen and (max-width: 400px) {
    .h3-991 h3 {
        font-size:17px!important
    }

    #awards-recognitions .carousel-container {
        width: 100vw
    }

    .testimonails-own .flickity-viewport {
        max-height: 410px!important
    }

    .Industries-icon figure h3 {
        font-size: 16px!important
    }

    .sides-image-content-section .stretch-to-right {
        margin-right: 0!important
    }

    .slider-menu-contact .carousel-item-inner {
        font-size: 16px!important
    }

    h1.blog-single-title {
        font-size: 20px!important
    }
}

@media screen and (max-width: 380px) {
    .content-section-cu h2,.content-section-cu h3 {
        font-size:11px!important
    }
}

@media only screen and (max-width: 360px) {
    .Industries-iconred figure h3 {
        width:86%;
        display: block!important;
        margin: 0 auto
    }
}

@media only screen and (max-width: 320px) {
    .slider-menu-contact .carousel-item-inner {
        font-size:13px!important
    }
}

#press-release article figure {
    width: 85%
}

.col-xxs-1,.col-xxs-10,.col-xxs-11,.col-xxs-12,.col-xxs-2,.col-xxs-3,.col-xxs-4,.col-xxs-5,.col-xxs-6,.col-xxs-7,.col-xxs-8,.col-xxs-9 {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative
}

@media (max-width: 384px) {
    .col-xxs-1,.col-xxs-10,.col-xxs-11,.col-xxs-2,.col-xxs-3,.col-xxs-4,.col-xxs-5,.col-xxs-6,.col-xxs-7,.col-xxs-8,.col-xxs-9 {
        float:left
    }

    .col-xxs-1 {
        width: 8.3333333333333%
    }

    .col-xxs-2 {
        width: 16.666666666667%
    }

    .col-xxs-3 {
        width: 25%
    }

    .col-xxs-4 {
        width: 33.333333333333%
    }

    .col-xxs-5 {
        width: 41.666666666667%
    }

    .col-xxs-6 {
        width: 50%
    }

    .col-xxs-7 {
        width: 58.333333333333%
    }

    .col-xxs-8 {
        width: 66.666666666667%
    }

    .col-xxs-9 {
        width: 75%
    }

    .col-xxs-10 {
        width: 83.333333333333%
    }

    .col-xxs-11 {
        width: 91.666666666667%
    }

    .col-xxs-12 {
        width: 100%
    }

    .col-xxs-push-1 {
        left: 8.3333333333333%
    }

    .col-xxs-push-2 {
        left: 16.666666666667%
    }

    .col-xxs-push-3 {
        left: 25%
    }

    .col-xss-push-4 {
        left: 33.333333333333%
    }

    .col-xxs-push-5 {
        left: 41.666666666667%
    }

    .col-xxs-push-6 {
        left: 50%
    }

    .col-xxs-push-7 {
        left: 58.333333333333%
    }

    .col-xxs-push-8 {
        left: 66.666666666667%
    }

    .col-xxs-push-9 {
        left: 75%
    }

    .col-xxs-push-10 {
        left: 83.333333333333%
    }

    .col-xxs-push-11 {
        left: 91.666666666667%
    }

    .col-xxs-pull-1 {
        right: 8.3333333333333%
    }

    .col-xxs-pull-2 {
        right: 16.666666666667%
    }

    .col-xxs-pull-3 {
        right: 25%
    }

    .col-xxs-pull-4 {
        right: 33.333333333333%
    }

    .col-xxs-pull-5 {
        right: 41.666666666667%
    }

    .col-xxs-pull-6 {
        right: 50%
    }

    .col-xxs-pull-7 {
        right: 58.333333333333%
    }

    .col-xxs-pull-8 {
        right: 66.666666666667%
    }

    .col-xxs-pull-9 {
        right: 75%
    }

    .col-xxs-pull-10 {
        right: 83.333333333333%
    }

    .col-xxs-pull-11 {
        right: 91.666666666667%
    }

    .col-xxs-offset-1 {
        margin-left: 8.3333333333333%
    }

    .col-xxs-offset-2 {
        margin-left: 16.666666666667%
    }

    .col-xxs-offset-3 {
        margin-left: 25%
    }

    .col-xxs-offset-4 {
        margin-left: 33.333333333333%
    }

    .col-xxs-offset-5 {
        margin-left: 41.666666666667%
    }

    .col-xxs-offset-6 {
        margin-left: 50%
    }

    .col-xxs-offset-7 {
        margin-left: 58.333333333333%
    }

    .col-xxs-offset-8 {
        margin-left: 66.666666666667%
    }

    .col-xxs-offset-9 {
        margin-left: 75%
    }

    .col-xxs-offset-10 {
        margin-left: 83.333333333333%
    }

    .col-xxs-offset-11 {
        margin-left: 91.666666666667%
    }
}

.hidden-xxs {
    display: block!important
}

tr.hidden-xxs {
    display: table-row!important
}

td.hidden-xxs,th.hidden-xxs {
    display: table-cell!important
}

@media (max-width: 384px) {
    .hidden-xxs,td.hidden-xxs,th.hidden-xxs,tr.hidden-xxs {
        display:none!important
    }
}

@media (min-width: 385px) and (max-width:767px) {
    .hidden-xxs.hidden-xs,td.hidden-xxs.hidden-xs,th.hidden-xxs.hidden-xs,tr.hidden-xxs.hidden-xs {
        display:none!important
    }
}

@media (min-width: 768px) and (max-width:991px) {
    .hidden-xxs.hidden-sm,td.hidden-xxs.hidden-sm,th.hidden-xxs.hidden-sm,tr.hidden-xxs.hidden-sm {
        display:none!important
    }
}

@media (min-width: 992px) and (max-width:1199px) {
    .hidden-xxs.hidden-md,td.hidden-xxs.hidden-md,th.hidden-xxs.hidden-md,tr.hidden-xxs.hidden-md {
        display:none!important
    }
}

@media (min-width: 1200px) {
    .hidden-xxs.hidden-lg,td.hidden-xxs.hidden-lg,th.hidden-xxs.hidden-lg,tr.hidden-xxs.hidden-lg {
        display:none!important
    }
}

@media (max-width: 384px) {
    .hidden-xs.hidden-xxs,td.hidden-xs.hidden-xxs,th.hidden-xs.hidden-xxs,tr.hidden-xs.hidden-xxs {
        display:none!important
    }
}

@media (max-width: 384px) {
    .hidden-sm.hidden-xxs,td.hidden-sm.hidden-xxs,th.hidden-sm.hidden-xxs,tr.hidden-sm.hidden-xxs {
        display:none!important
    }
}

@media (max-width: 384px) {
    .hidden-md.hidden-xxs,td.hidden-md.hidden-xxs,th.hidden-md.hidden-xxs,tr.hidden-md.hidden-xxs {
        display:none!important
    }
}

@media (max-width: 384px) {
    .hidden-lg.hidden-xxs,td.hidden-lg.hidden-xxs,th.hidden-lg.hidden-xxs,tr.hidden-lg.hidden-xxs {
        display:none!important
    }
}

@media (max-width: 767px) {
    .hidden-lg.hidden-xs,.hidden-md.hidden-xs,.hidden-sm.hidden-xs,.hidden-xs,td.hidden-lg.hidden-xs,td.hidden-md.hidden-xs,td.hidden-sm.hidden-xs,td.hidden-xs,th.hidden-lg.hidden-xs,th.hidden-md.hidden-xs,th.hidden-sm.hidden-xs,th.hidden-xs,tr.hidden-lg.hidden-xs,tr.hidden-md.hidden-xs,tr.hidden-sm.hidden-xs,tr.hidden-xs {
        display:none!important
    }
}

.visible-xxs,td.visible-xxs,th.visible-xxs,tr.visible-xxs {
    display: none!important
}

@media (max-width: 384px) {
    .visible-xxs {
        display:block!important
    }

    tr.visible-xxs {
        display: table-row!important
    }

    td.visible-xxs,th.visible-xxs {
        display: table-cell!important
    }
}

@media (min-width: 385px) and (max-width:767px) {
    .visible-xxs.visible-xs {
        display:block!important
    }

    tr.visible-xxs.visible-xs {
        display: table-row!important
    }

    td.visible-xxs.visible-xs,th.visible-xxs.visible-xs {
        display: table-cell!important
    }
}

@media (min-width: 768px) and (max-width:991px) {
    .visible-xxs.visible-sm {
        display:block!important
    }

    tr.visible-xxs.visible-sm {
        display: table-row!important
    }

    td.visible-xxs.visible-sm,th.visible-xxs.visible-sm {
        display: table-cell!important
    }
}

@media (min-width: 992px) and (max-width:1199px) {
    .visible-xxs.visible-md {
        display:block!important
    }

    tr.visible-xxs.visible-md {
        display: table-row!important
    }

    td.visible-xxs.visible-md,th.visible-xxs.visible-md {
        display: table-cell!important
    }
}

@media (min-width: 1200px) {
    .visible-xxs.visible-lg {
        display:block!important
    }

    tr.visible-xxs.visible-lg {
        display: table-row!important
    }

    td.visible-xxs.visible-lg,th.visible-xxs.visible-lg {
        display: table-cell!important
    }
}

@media (max-width: 384px) {
    .visible-xs.visible-xxs {
        display:block!important
    }

    tr.visible-xs.visible-xxs {
        display: table-row!important
    }

    td.visible-xs.visible-xxs,th.visible-xs.visible-xxs {
        display: table-cell!important
    }
}

@media (max-width: 384px) {
    .visible-sm.visible-xxs {
        display:block!important
    }

    tr.visible-sm.visible-xxs {
        display: table-row!important
    }

    td.visible-sm.visible-xxs,th.visible-sm.visible-xxs {
        display: table-cell!important
    }
}

@media (max-width: 384px) {
    .visible-md.visible-xxs {
        display:block!important
    }

    tr.visible-md.visible-xxs {
        display: table-row!important
    }

    td.visible-md.visible-xxs,th.visible-md.visible-xxs {
        display: table-cell!important
    }
}

@media (max-width: 384px) {
    .visible-lg.visible-xxs {
        display:block!important
    }

    tr.visible-lg.visible-xxs {
        display: table-row!important
    }

    td.visible-lg.visible-xxs,th.visible-lg.visible-xxs {
        display: table-cell!important
    }
}

@media (max-width: 767px) {
    .visible-xs {
        display:block!important
    }

    tr.visible-xs {
        display: table-row!important
    }

    td.visible-xs,th.visible-xs {
        display: table-cell!important
    }
}

@media (max-width: 767px) {
    .visible-sm.visible-xs {
        display:block!important
    }

    tr.visible-sm.visible-xs {
        display: table-row!important
    }

    td.visible-sm.visible-xs,th.visible-sm.visible-xs {
        display: table-cell!important
    }
}

@media (max-width: 767px) {
    .visible-md.visible-xs {
        display:block!important
    }

    tr.visible-md.visible-xs {
        display: table-row!important
    }

    td.visible-md.visible-xs,th.visible-md.visible-xs {
        display: table-cell!important
    }
}

@media (max-width: 767px) {
    .visible-lg.visible-xs {
        display:block!important
    }

    tr.visible-lg.visible-xs {
        display: table-row!important
    }

    td.visible-lg.visible-xs,th.visible-lg.visible-xs {
        display: table-cell!important
    }

    .order-1-sm {
        -ms-flex-order: 1!important;
        order: 1
    }

    .order-2-sm {
        -ms-flex-order: 2;
        order: 2
    }

    .order-3-sm {
        -ms-flex-order: 3;
        order: 3
    }

    .order-4-sm {
        -ms-flex-order: 4;
        order: 4
    }

    .order-5-sm {
        -ms-flex-order: 5;
        order: 5
    }

    .order-6-sm {
        -ms-flex-order: 6;
        order: 6
    }

    .order-7-sm {
        -ms-flex-order: 7;
        order: 7
    }

    .order-8-sm {
        -ms-flex-order: 8;
        order: 8
    }

    .order-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-10-sm {
        -ms-flex-order: 10;
        order: 10
    }

    .order-11-sm {
        -ms-flex-order: 11;
        order: 11
    }

    .order-12-sm {
        -ms-flex-order: 12;
        order: 12
    }
}

.order-1 {
    -ms-flex-order: 1;
    order: 1
}

.order-2 {
    -ms-flex-order: 2;
    order: 2
}

.order-3 {
    -ms-flex-order: 3;
    order: 3
}

.order-4 {
    -ms-flex-order: 4;
    order: 4
}

.order-5 {
    -ms-flex-order: 5;
    order: 5
}

.order-6 {
    -ms-flex-order: 6;
    order: 6
}

.order-7 {
    -ms-flex-order: 7;
    order: 7
}

.order-8 {
    -ms-flex-order: 8;
    order: 8
}

.order-9 {
    -ms-flex-order: 9;
    order: 9
}

.order-10 {
    -ms-flex-order: 10;
    order: 10
}

.order-11 {
    -ms-flex-order: 11;
    order: 11
}

.order-12 {
    -ms-flex-order: 12;
    order: 12
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: .8
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: 0!important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.mfp-align-top .mfp-container:before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-ajax-holder .mfp-content,.mfp-inline-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-arrow,.mfp-close,.mfp-counter,.mfp-preloader {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none!important
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

.mfp-preloader a {
    color: #CCC
}

.mfp-preloader a:hover {
    color: #FFF
}

.mfp-s-error .mfp-content,.mfp-s-ready .mfp-preloader {
    display: none
}

button.mfp-arrow,button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial,Baskerville,monospace
}

.mfp-close:focus,.mfp-close:hover {
    opacity: 1
}

.mfp-close:active {
    top: 1px
}

.mfp-close-btn-in .mfp-close {
    color: #333
}

.mfp-iframe-holder .mfp-close,.mfp-image-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-arrow {
    position: absolute;
    opacity: .65;
    margin: -55px 0 0;
    top: 50%;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:focus,.mfp-arrow:hover {
    opacity: 1
}

.mfp-arrow:after,.mfp-arrow:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: inset transparent
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: .7
}

.mfp-arrow-left {
    left: 0
}

.mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F
}

.mfp-arrow-right {
    right: 0
}

.mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px
}

.mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0,0,0,.6);
    background: #000
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0;
    margin: 0 auto
}

.mfp-figure {
    line-height: 0
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0,0,0,.6);
    background: #444
}

.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-figure figure {
    margin: 0
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

@media screen and (max-width: 800px) and (orientation:landscape),screen and (max-height:300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left:0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0,0,0,.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0,0,0,.6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform:scale(0.75);
        transform: scale(0.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

.fontschange .own-contents h1 {
    font-weight: 400
}

.fontschange h1.own-contents {
    font-weight: 600;
    font-family: Poppins!important;
    font-size: 37.5px!important;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.fontschange .own-contents h2 {
    font-weight: 400
}

.fontschange h3.own-contents {
    font-weight: 27px;
    font-weight: 600;
    font-family: Poppins!important;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.fontschange .own-contents p {
    font-size: 21px;
    font-family: Averta,sans-serif;
    font-weight: 400;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.fontschange label.own-contents,.fontschange p.own-contents {
    font-size: 21px;
    font-family: Averta,sans-serif;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.fontschange .own-contents p em {
    font-size: 21px;
    font-family: Averta,sans-serif;
    font-weight: 400;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-style: initial
}

.fontschange .own-contents p i {
    font-size: 21px;
    font-family: Averta,sans-serif;
    font-weight: 400;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.fontschange .own-contents h1,.fontschange .own-contents h2,.fontschange .own-contents h3 {
    font-family: Poppins!important;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.fontschange .own-contents-sub h1 {
    font-size: 32px;
    font-family: Poppins!important;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.fontschange .own-contents-sub h2 {
    font-family: Poppins!important;
    font-size: 32px!important;
    color: rgba(0,0,0,.84);
    font-weight: 600;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.fontschange .own-contents h4,.fontschange .own-contents-sub h3,.fontschange .own-contents-sub h4,.fontschange .own-contents-sub h5,.fontschange .own-contents-sub h6 {
    font-family: Poppins!important;
    color: rgba(0,0,0,.84);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

.tabs-inlineh5 li.h5 {
    font-size: 16px
}

.logosareatech {
    text-align: center
}

.logosareatech a.icon-block {
    display: inline-block;
    width: auto;
    text-align: center;
    font-size: 12px!important
}

.logosareatech a.icon-block h5 {
    font-size: 15px!important
}

.logosareatech .icon-box {
    height: 50px;
    display: flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center
}

.tabs-nav-lg a {
    color: #424242
}

.tabs-nav-lg a:active,.tabs-nav-lg a:focus,.tabs-nav-lg a:hover {
    color: #5174fd!important
}

.tabs-nav-lg .tabs-nav li.active a {
    color: #5174fd
}

.tabsli_style ul.nav.tabs-nav {
    border-bottom: 0
}

.tabsli_style li.active {
    border-bottom: 2px solid #5174fd
}

.header-settings .headerWrap {
    z-index: 999
}

.slider-bgs .slider {
    opacity: 0
}

.slider-bgs .slider.slick-initialized {
    opacity: 1
}

footer.blog-single-footer.entry-footer .social-icon li {
    text-indent: 0!important;
    font-size: 15px
}

span.posted-on a,span.posted-on span {
    font-size: 12px!important;
    margin-right: 1em
}

.post-author-info.clearfix p {
    margin-bottom: 0!important
}

.blog-single-footer>span,.blog-single-footer>ul {
    margin: 0 0 2.5em!important
}

.titlebar-col h1 {
    font-size: 45px!important;
    font-weight: 700
}

.page-blog .liquid-blog-item-inner {
    height: auto!important
}

.companydetailmenu h5 {
    font-size: 12px;
    margin: 0!important;
    padding: 0!important
}

.companydetailmenu h4 {
    font-size: 28px;
    margin-bottom: 0!important;
    padding: 0!important
}

.our-ebooks {
    padding: 80px 0 0
}

.our-ebooks:last-child {
    padding: 80px 0
}

.our-ebooks {
    padding-left: 15px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    padding-right: 15px
}

.our-ebooks h3 {
    margin-top: 0;
    padding-top: 0!important
}

p.pstyle-ebook span {
    color: #5174fd;
    padding: 0 5px
}

p.pstyle-ebook span.slashe {
    color: #5174fd57
}

p.pstyle-ebook {
    padding-bottom: 0;
    margin-bottom: 10px
}

.ebookchange img {
    max-width: 150px;
    margin: 0 auto;
    text-align: center;
    display: block
}

.ebookchange p.pstyle-ebook {
    font-size: 13px;
    text-align: center
}

.ebookchange h3 {
    font-size: 18px;
    text-align: center;
    margin-top: 0
}

.boxstyle {
    background: #fff;
    padding: 5px 10px 15px;
    border-radius: 6px;
    box-shadow: 0 4px 9px 0 rgba(23,28,33,.06)
}

.lqd-modal-inner {
    padding-bottom: 0!important
}

.darkblue {
    background: rgba(50,45,157,1)
}

.lightred {
    background: rgba(255,53,69,1)
}

.darkgreen {
    background: rgba(45,157,79,1)
}

.lightgreen {
    background: rgba(45,157,139,1)
}

.darkorange {
    background: rgba(242,152,0,1)
}

.darkpuple {
    background: rgba(99,45,157,1)
}

.boxstyle-web {
    min-height: 280px;
    padding: 25px;
    overflow: hidden;
    cursor: pointer
}

.boxstyle-web h4 {
    color: rgba(255,255,255,1);
    font-size: 14px;
    font-weight: 100!important;
    opacity: .5;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase
}

.boxstyle-web h3 {
    color: rgba(255,255,255,1);
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
    line-height: 18pt
}

.boxstyle-web .liquid-lp-read-more .btn-line {
    BACKGROUND: #fff
}

.boxstyle-web .liquid-lp-footer {
    bottom: 25px;
    position: absolute
}

img.sideicobox {
    position: absolute;
    right: -20px;
    bottom: -20px;
    max-width: 120px
}

.header-settings header .lqd-custom-menu li .menu-item-title {
    margin-bottom: 0!important;
    font-size: 15px
}

.header-settings .megamenu .megamenu-column:not(.group-dark) .lqd-custom-menu li a {
    padding: 9.75px!important
}

.spaceings .Industries-icon figure h3 {
    font-size: 16px!important
}

.spaceings .Industries-icon.img-large-logos img {
    width: 60%!important;
    display: inline-block
}

.spaceings .inner-bxd.bgncbox {
    background: 0 0;
    color: #000;
    padding: 0;
    min-height: auto
}

.spaceings .inner-bxd.bgncbox h2 {
    color: #000
}

.spaceings .ourblockchian-service {
    min-height: auto;
    padding: 40px
}

.spaceings .ourblockchian-service.bg-whitesbox .col-md-6 {
    padding-left: 0
}

.spaceings .ourblockchian-service .row-own {
    width: 100%
}

.spaceings .ourblockchian-service.bg-whitesbox {
    min-height: auto;
    padding: 40px 40px 30px
}

.newimage-setings {
    width: 100%!important
}

.smarttick,.smarttick ul {
    list-style: none;
    padding-left: 0!important
}

.smarttick li {
    position: relative;
    padding-left: 20px!important;
    list-style: none;
    line-height: 38px!important
}

.smarttick li:before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/common-images/smarttick.png);
    background-size: cover;
    background-position: center;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

.formcss {
    background: #eef0f5;
    padding: 30px
}

.formcss input {
    width: 100%;
    border: 1px solid #f7f7f9;
    padding: 8px
}

.formcss input::-webkit-input-placeholder {
    color: #cdd2e0
}

.formcss input:-ms-input-placeholder {
    color: #cdd2e0
}

.formcss input::placeholder {
    color: #cdd2e0
}

.formcss button {
    width: 100%;
    background: #5174fd;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 8px
}

.image-circled {
    display: inline-block;
    vertical-align: middle
}

.contentroundimgs {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px
}

.contentroundimgs h6 {
    margin: 0
}

.contentroundimgs p {
    margin-bottom: 0;
    font-size: 12px;
    color: #acb6cf
}

.formcss .form-group:last-child {
    margin-bottom: 0!important
}

@media all and (max-width: 767px) {
    body.page-blog-post {
        position:relative
    }

    .form_div_toggle {
        top: 30%
    }

    .fullheight .row .col-md-6 a {
        text-align: center;
        margin: 0 auto;
        width: 100%
    }

    .ourblockchian-service.bg-whitesbox .col-md-6 {
        padding-left: 15px!important
    }
}

.header-module a.btn {
    background: #ff8839!important
}

.twitter-tweet {
    margin: 0 auto
}

.col-md-3.widthautocsblog {
    width: 30%
}

.pr-10 {
    padding-right: 10px
}

span.hotlinebox a {
    color: #fff
}

#seopage-form {
    background: #fff;
    padding: 50px 0
}

#form-app .required-control .control-label:after {
    display: none!important;
    color: transparent!important;
    content: none!important
}

span.hotlinebox {
    padding-left: 20px
}

.logosseo .carousel-img figure {
    width: 120px!important
}

#seopage-form #contact_page-container {
    padding: 0;
    width: 70%!important;
    margin: 0 auto!important
}

.seobackgroundcvr::after {
    display: block;
    content: ".";
    background-color: #1869f4;
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/banner-topseo1.png);
    background-position: top right;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 700px
}

.seobackgroundcvrfooter::after {
    display: block;
    content: ".";
    background-color: #1869f4;
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/footerbg.png);
    background-position: top right;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 700px
}

.designradius .iconbox {
    border-radius: 13px!important
}

.row.designradius .iconbox_custom {
    padding: 30px
}

.designradius .iconbox_custom {
    height: 90%
}

.designradius .iconbox-filled-hover a.iconhover:hover path,.designradius .iconbox-filled-hover:hover path {
    fill: #fff
}

.designradius a.iconhover {
    z-index: 9999
}

.zoomed {
    transition: all 0s ease-in-out
}

.zoomed:hover {
    transform: scale(1.03)
}

.blockdivs,.blockdivs_767 {
    display: block
}

.designradius .iconbox-filled-hover {
    border-radius: 26px!important;
    box-shadow: none!important
}

.designradius .iconbox-filled-hover:hover {
    border: 1px solid #fff!important;
    border-radius: 26px!important;
    box-shadow: 0 22px 25px 0 rgba(15,99,244,.21)!important
}

.p-35 {
    padding: 35px!important
}

.backgroundwhite-tabsd {
    -webkit-box-shadow: 0 10px 45px -9px rgba(0,0,0,.2);
    -moz-box-shadow: 0 10px 45px -9px rgba(0,0,0,.2);
    box-shadow: 0 10px 45px -9px rgba(0,0,0,.2)
}

.imageshad img {
    width: 90%;
    text-align: right;
    float: right;
    -webkit-box-shadow: 0 10px 45px -9px rgba(0,0,0,.2);
    -moz-box-shadow: 0 10px 45px -9px rgba(0,0,0,.2);
    box-shadow: 0 40px 35px -9px rgba(0,0,0,.2)
}

.ourseo-slides ol.flickity-page-dots {
    display: none!important
}

.mycustomlink .liquid-blog-scheme-dark .btn-line,.mycustomlink .liquid-lp-read-more .btn-line-after:after {
    color: #0f65f4!important
}

.ourseo-slides .flickity-enabled.is-draggable .flickity-viewport {
    min-height: 500px!important;
    max-height: 660px!important
}

.ourseo-slides .flickity-prev-next-button.previous {
    left: -6%!important
}

.ourseo-slides .flickity-prev-next-button.next {
    right: -6%!important
}

.ourseo-slides .carousel-item-inner {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    justify-content: center;
    align-items: center
}

.font-size-90 {
    font-size: 90px!important
}

hr.hrsetseo {
    width: 55%;
    text-align: left;
    margin-left: 0;
    border-bottom: 1px solid #eee
}

.box-bullits ul {
    font-size: 18px!important;
    margin: 0!important;
    padding: 0!important;
    text-indent: 18px!important;
    list-style-type: square!important;
    line-height: 32px!important
}

figure.imgseoafter img {
    width: 60px;
    position: relative;
    left: -20px
}

.boximageicon-seo {
    width: 60px;
    height: 60px;
    background: #Fff;
    border-radius: 11px;
    text-align: left;
    margin-left: 0
}

a.boximageicon-seo {
    padding: 10px!important;
    margin-right: 30px
}

a.boximageicon-seo:hover {
    background: #0f63f4;
    border: 2px solid #fff!important;
    border-radius: 11px!important;
    box-shadow: 0 22px 25px 0 rgba(15,99,244,.21)!important
}

.boxseo-data img {
    width: 70px;
    display: inline-block
}

.boxseo-data h3 {
    padding-left: 15px;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle
}

a.boximageicon-seo:hover img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1)
}

.bgafters:after {
    content: "";
    width: 192px;
    height: 385px;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/boxround-image.png) center right/50% 50% no-repeat;
    position: absolute;
    display: inline-block;
    right: -80px;
    top: 0
}

.bgbefores:before {
    content: "";
    width: 345px;
    height: 344px;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/boxround-image-half.png) bottom right/40% 40% no-repeat;
    position: absolute;
    display: inline-block;
    bottom: -28.5%!important
}

hr.hrstyleseofotter {
    width: 70%;
    margin: 0 auto;
    text-align: center
}

.footerformseo {
    -webkit-box-shadow: 0 5px 19px 0 rgba(50,50,50,.52);
    -moz-box-shadow: 0 5px 19px 0 rgba(50,50,50,.52);
    box-shadow: 0 5px 19px 0 rgba(50,50,50,.52)
}

.p-50 {
    padding: 50px!important
}

.p-40 {
    padding: 40px!important
}

.box-blogsseo h4 {
    font-size: 13px;
    margin: 18px 0
}

.box-blogsseo h3 {
    font-size: 30px;
    margin: 18px 0
}

.box-blogsseo a {
    font-size: 13px;
    margin: 0 0 18px;
    font-weight: 700
}

.oursliderimages img {
    -webkit-box-shadow: 0 30px 21px -4px rgba(196,196,196,.47);
    -moz-box-shadow: 0 30px 21px -4px rgba(196,196,196,.47);
    box-shadow: 0 30px 21px -4px rgba(196,196,196,.47)
}

.lowercase-text {
    text-transform: capitalize!important
}

.box-bullits:after {
    content: "";
    display: inline-block;
    position: absolute;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/box-after.png) center/100% 100% no-repeat;
    height: 70px;
    width: 70px;
    top: 50%;
    right: 50px
}

.bg-nonesdq:after {
    background: none!important
}

.backgroundwhite-tabsd .tabs-nav-lg .tabs-nav a {
    padding: 0 .6em 1.2em!important
}

.mytabbannners .background-cover {
    background-size: cover!important;
    background-position: center center!important;
    background-repeat: no-repeat!important
}

.boxhoverseo {
    box-shadow: 0 0 1rem 0 rgba(0,0,0,.1);
    padding: 20px;
    width: 90%;
    margin: 0 auto;
    top: -20px;
    position: relative;
    background: #fff
}

.fromLefts:after {
    display: block;
    content: '';
    border-bottom: solid 5px #1869f4;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 100% 50%;
    position: relative;
    bottom: -20px;
    margin-left: -20px;
    margin-right: -20px
}

span.rrright-text {
    text-align: right;
    float: right;
    top: 0;
    color: #b9b9b9;
    font-size: 14px
}

.ourboxblog-side {
    background: #1869f4!important;
    min-height: 317px!important;
    display: block;
    color: #fff!important;
    padding: 40px;
    margin: 0 auto;
    text-align: center
}

.ourboxblog-side img {
    width: 100px;
    margin-bottom: 20px
}

.ournew-blogcustom p,.ournew-blogcustom ul li {
    font-size: 21px
}

.ournew-blogcustom p {
    margin-top: 50px;
    margin-bottom: 50px
}

.ournew-blogcustom .lqd-modal-content p {
    margin: 10px 0 20px!important
}

.ournew-blogcustom ul li {
    list-style: none!important;
    line-height: 45px;
    margin: 0!important
}

.ournew-blogcustom ul {
    list-style: none!important;
    text-indent: 0;
    margin: 0!important;
    padding: 0!important
}

h1.fontownsize-side {
    font-size: 47px!important
}

.fromLefts:hover:after {
    transform: scaleX(1);
    transform-origin: 0 50%
}

.mt-sm-4 {
    margin-top: 0!important
}

.min-h2image img {
    width: 88%
}

.linehight-off .menu-item-description,.linehight-off ul.lqd-custom-menu a {
    line-height: normal!important
}

.banner-mobile-sidesc::after {
    display: block;
    content: ".";
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/bgnew-image.png);
    background-position: top right;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 40%!important
}

.oursnvc .carousel-nav button {
    width: 50px!important;
    opacity: .9
}

.my_jobhover:hover {
    background: #ff8839!important;
    z-index: 1
}

.iconboxhoversd .my_jobhover:hover {
    height: 140%;
    background-image: linear-gradient(to right,rgb(11,204,238) 0,rgb(172,29,225) 100%) !important;
     }

.iconboxhoversd #department-list .all {
    max-height: 270px !important;
}

.iconboxhoversd .iconboxhoversd h4 {
    font-size: 20px !important;
    margin-bottom: 10px
}

.iconboxhoversd .job-box p {
    font-size: 14px !important
}

.iconboxhoversd a.readmore_arrow {
    font-size: 14px !important
}

.iconboxhoversd #department-list .all h4 {
    font-size: 20px
}

.ourboxes-tabs-style .iconbox:active {
    background: #252c42 !important;
    cursor: pointer
}

.ourboxes-tabs-style .iconbox:active path {
    fill: #Fff !important
}

.ourboxes-tabs-style .iconbox:active h3 {
    color: #fff !important
}

.ourboxes-tabs-style .iconbox:focus {
    background: #252c42 !important;
    cursor: pointer
}

.ourboxes-tabs-style .iconbox:focus path {
    fill: #Fff !important
}

.ourboxes-tabs-style .iconbox:focus h3 {
    color: #fff !important
}

.ourboxes-tabs-style .iconbox:visited {
    background: #252c42 !important;
    cursor: pointer
}

.ourboxes-tabs-style .iconbox:visited path {
    fill: #Fff !important
}

.ourboxes-tabs-style .iconbox:visited h3 {
    color: #fff !important
}

.ourresbxds a {
    -webkit-box-shadow: 0 25px 70px rgba(0,0,0,.07);
    box-shadow: 0 9px 16px rgba(0,0,0,.07);
    display: block;
    border-radius: 13px;
    padding: 30px !important
}

.ourresbxds a p {
    color: #7b7b7b
}

.ourresbxds a:hover {
    -webkit-box-shadow: 0 25px 70px rgba(0,0,0,.07);
    box-shadow: 0 9px 16px rgba(0,0,0,.07);
    display: block;
    background-image: linear-gradient(to right,rgb(11,204,238) 0,rgb(172,29,225) 100%) !important;
    border-radius: 13px;
    padding: 30px !important
}

.foractive .iconbox.active {
    background: #252C42!important
}

.foractive .iconbox.active path {
    fill: #fff !important
}

.foractive .iconbox.active h3 {
    color: #fff !important
}

a.overbox-respons h4 {
    margin-top: 0
}

.ouractiveboxs a {
    color: #000
}

.nvcareerpage li {
    padding: 0 40px 0 40px
}

.nvcareerpage ul {
    margin-left: 0 !important;
    padding-left: 0 !important
}

.nvcareerpage li a {
    font-weight: bold;
    text-transform: uppercase !important
}

a.overbox-respons p {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important
}

.ournavinline {
    display: inline-block;
    width: auto !important;
    display: inline-flex
}

.ournavinline a {
    padding-top: 0 !important;
    margin-top: 0 !important
}

.ournavinline a {
    padding-top: 0 !important;
    margin-top: 0
}

.ourhoverfxd a:first-child {
    padding-bottom: 0 !important
}

li.ourhoverfxd:hover {
    background: #f3f6f9 !important;
    display: contents;
    width: 100% !important;
    display: grid;
    min-height: 69px !important
}

.megamenu-column a {
    -webkit-transition: inherit !important;
    transition: inherit !important
}

a.ournavdd:hover {
    text-decoration: none;
    color: #4869e8;
    color: #000
}

li.ourhoverfxd {
    min-height: 69px !important;
    cursor: pointer
}

a.ournavdd {
    color: #000
}

.ourresbxds a:hover h4 {
    color: #Fff !important
}

.ourresbxds a:hover p {
    color: #Fff !important
}

.iconboxhoversd #department-list .all {
    min-height: 230px    !important;}

.iconboxhoversd .my_jobhover {
    border-radius: 13px
}

.iconboxhoversd .my_jobhover:hover .job-box a.readmore_arrow {
    background-color: rgb(255 255 255)!important;   
    padding: 6px 10px;
    border-radius: 3px;
    border-bottom: 0!important
}

.my_jobhover:hover+.hovercustom-boxjob {
    z-index: 9
}

.my_jobhover:hover .job-box h4,.my_jobhover:hover .job-box p {
    color: #fff!important
}

.my_jobhover:hover .job-box a.readmore_arrow {
    background-color: rgba(255,225,225,1)!important;
    padding: 6px 10px;
    border-radius: 3px;
    border-bottom: 0!important
}

.my_jobhover:hover .btn-underlined:after {
    background: none!important;
    display: none!important
}

.image100 img {
    width: 100%!important
}

.inlinebuttons {
    display: inline-block;
    margin-right: 50px
}

.inlinebuttons a {
    color: #000;
    font-weight: 700;
    font-size: 22px
}

.inlinebuttons a::after {
    border-bottom: 2px solid #0082b4!important;
    width: 70%!important;
    display: block;
    content: "";
    margin-top: 5px!important;
    text-align: center;
    margin: 0 auto
}

.inlinebuttons a:hover {
    color: #0082b4
}

.inlinebuttons a:hover::after {
    transition-duration: 1s;
    width: 100%!important
}

.contentport-ww {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    font-weight: 600
}

.mt-md-40p {
    margin-top: 40%
}

.extraspcd {
    position: relative
}

.extraspcd h1 {
    position: absolute;
    top: -120px!important
}

.navspca .tabs-nav-lg .tabs-nav a {
    padding: 1em
}

.logo-main-ports-client img {
    float: right;
    position: relative;
    top: -50px
}

.desgnsterheader h2,.ourup h2 {
    text-transform: initial!important
}

.ourup h1 .lqd-lines:nth-child(2) {
    text-transform: initial!important
}

.ourup h2 .lqd-lines:nth-child(2) {
    text-transform: initial!important
}

.ourup h1 .lqd-lines:nth-child(2) ::first-letter {
    text-transform: initial!important
}

.ourup h2 .lqd-lines:nth-child(2) ::first-letter {
    text-transform: initial!important
}

.ourup h1 .lqd-lines:nth-child(3) ::first-letter {
    text-transform: initial!important
}

.ourup h2 .lqd-lines:nth-child(3) ::first-letter {
    text-transform: initial!important
}

.ourup h1.blog-single-title {
    text-transform: initial!important
}

.ournav_custom_Tabs li a {
    padding: 1.5em 1.6em!important;
    font-size: 16.5px!important;
    font-weight: 600
}

.seopage-node .liquid-counter-md .liquid-counter-element {
    font-size: 40px!important
}

.contentul_Seo ul {
    list-style: none;
    margin-left: 0;
    padding-left: 20px;
    text-indent: -24px
}

.contentul_Seo li {
    padding: 0 0 30px;
    font-size: 18px
}

.contentul_Seo li:before {
    content: "";
    display: block;
    float: left;
    height: 14px;
    width: 14px;
    margin-right: 10px;
    background-size: 100%;
    left: -21px!important;
    position: relative;
    top: 6px;
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/nodejs/checked-icon.png)
}

.smartboxsc span.smartboxd {
    border: 1.5px solid #5174fd;
    padding: 10px;
    margin-right: 15px;
    border-radius: 10px;
    color: #5174fd;
    font-weight: 600;
    display: inline-block
}

.ournav_custom_Tabs.lispc li a {
    padding: 1em 1.3em!important
}

.smartboxsc.boxsmartbottom .smartboxd {
    margin-bottom: 20px
}

.lispc .tabs-nav-active-underlined .tabs-nav li:after {
    margin: 0 auto;
    text-align: center;
    width: 30%;
    left: inherit
}

span.smartboxd::before {
    display: none!important
}

.bgnodejs-last {
    position: relative
}

.bgnodejs-last .liquid-row-overlay {
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    left: 150px
}

.ournew-boxtechs .boximageicon-seo {
    border: 1px solid #aaa
}

.custom-listSeo ul {
    list-style: none!important;
    margin-left: 0;
    padding-left: 0
}

.custom-listSeo {
    list-style: none;
    padding-left: 0
}

.custom-listSeo li {
    position: relative;
    padding-left: 30px;
    font-size: 18px;
    padding-bottom: 18px
}

.custom-listSeo li:before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/nodejs/ticksmarts.png);
    background-size: cover;
    background-position: center;
    left: 0;
    top: 4px
}

.ourcuxtomaccor .panel {
    background: 0 0
}

.ourcuxtomaccor .accordion-expander {
    position: relative;
    float: right;
    padding-top: 32px
}

.ourcuxtomaccor h4 a {
    margin-left: 0!important;
    padding-left: 0!important;
    padding-top: 0
}

.ourcuxtomaccor .accordion-light.accordion-body-underlined {
    border: 0!important
}

.ourcuxtomaccor .accordion-content {
    padding: 0 20px 25px!important
}

.ourcuxtomaccor .panel {
    border: 0!important
}

.oursmart-techsec span {
    display: inline-block;
    width: 80px;
    height: 80px;
    background: #fff!important;
    border-radius: 11px;
    margin-right: 15px
}

.oursmart-techsec span img {
    padding: 15px;
    width: 100%
}

.oursmart-techsec span.hrefsets {
    height: auto;
    padding-top: 27px;
    padding-bottom: 27px;
    margin: 0 auto;
    text-align: center
}

.nodejsslides.ourseo-slides {
    height: 540px
}

.slick-slide img {
    border-radius: 10px 10px 0 0 !important
}

.content-portfoliosd {
    padding: 18px 15px 18px 15px;
    background: #fff;
    border-radius: 0 0 10px 10px;
    font-size: 20px;
    font-weight: bold
}

a.small-portfolioimages {
    color: #000 !important
}

a.small-portfolioimages:focus {
    outline: 0 !important
}

.testimonial-content h5:nth-child(2) {
    text-transform: inherit !important
}

.testimonial-content h5 {
    text-transform: inherit !important
}

.pl-md-30 {
    padding-left: 30px
}

.ouractiveboxs .activedboxsed {
    background: #4869e8;
    transform: scale(1.03)
}

.ouractiveboxs .activedboxsed p {
    color: #fff !important
}

.ouractiveboxs .activedboxsed h3 {
    color: #fff !important
}

.ouractiveboxs .iconbox-filled-hover:hover {
    color: #fff
}

.ouractiveboxs .activedboxsed.iconbox-filled-hover path {
    fill: #fff !important
}

.vc_row.pull-up.mi-150 {
    margin-top: -200px !important
}

.ourheight-boxes .liquid-parallax-figure {
    height: 100% !important
}

.ourboxes-tabs-style .iconbox .contents p {
    width: 80% !important;
    margin: 0 auto;
    font-size: 16px
}

.ourboxes-tabs-style .iconbox:hover {
    background: #252c42 !important;
    cursor: pointer
}

.ourboxes-tabs-style .iconbox:hover p {
    color: #Fff
}

.ourboxes-tabs-style .iconbox:hover h3 {
    color: #Fff !important
}

.ourboxes-tabs-style .iconbox:hover path {
    fill: #fff !important
}

@media all and (max-width: 1200px) {
    .seobackgroundcvr::after {
        background-size:590px!important
    }
}

@media all and (max-width: 1024px) {
    .mleft1024 {
        margin-left:40px
    }

    .header-module .font-size-15 {
        font-size: 12px!important
    }

    .ournav_custom_Tabs li a {
        padding: 1.2em 0.6em!important
    }

    .box-bullits:after {
        display: none !important
    }

    .bgnodejs-last .liquid-row-overlay {
        display: none
    }

    .ournav_custom_Tabs.lispc li a {
        padding: 1em 0.8em!important
    }

    .lispc .col-md-7 {
        width: auto !important
    }

    .our1024100 .col-md-7 {
        width: 100% !important
    }

    .our1024100 .col-md-5 {
        width: 100% !important
    }
}

@media all and (max-width: 991px) {
    .m0-mobiles {
        margin:0 auto!important;
        text-align: center!important
    }

    .nodejsslides.ourseo-slides {
        height: 870px!important
    }

    .oursmart-techsec span {
        margin-bottom: 15px
    }

    .banner-mobile-sidesc {
        background-image: none!important
    }

    .mleft1024 {
        margin-left: 0
    }

    .bgbefores:after,.bgbefores:before,.disnone-991 {
        display: none!important
    }

    .box-bullits:after {
        content: "";
        background: none!important
    }

    .hundrednine .col-md-5,.hundrednine .col-md-6,.hundrednine .col-md-7,.hundrednine .col-sm-5,.hundrednine .col-sm-6,.hundrednine .col-sm-7 {
        width: 100%!important;
        display: block!important
    }

    .d-flexnone-hun,.ourseo-slides .carousel-item-inner {
        display: inherit!important
    }

    .own-row-reverse-one {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .own-row-reverse-one>div {
        width: 100%
    }

    figure.img-smallmbd img {
        margin: 0 auto;
        text-align: center;
        display: block;
        height: 443px!important
    }

    .ourseo-slides .flickity-enabled.is-draggable .flickity-viewport {
        min-height: 810px!important;
        max-height: 810px!important
    }

    .ourseo-slides {
        width: 100%
    }

    .m0_auto {
        margin: 0 auto;
        text-align: center;
        display: block
    }

    .headingiconseo img {
        margin: 0 auto;
        text-align: center!important;
        display: block!important;
        width: 80px!important
    }

    .headingiconseo {
        width: 100%!important
    }

    a.boximageicon-seo {
        margin-right: 0;
        padding: .7em .7em .7em;
         margin:0
    }

    .m0_auto .tabs-nav {
        margin: 0 auto;
        text-align: center;
        display: block
    }

    figure.imageshad {
        width: 80%;
        margin: 50px auto 0;
        text-align: center
    }

    .boximageicon-seo img {
        width: 30px
    }

    .box-blogsseo img {
        width: 100%!important
    }

    .pr-sm-0 {
        padding-right: 0!important
    }

    .mt-sm-4 {
        margin-top: 1.5rem!important
    }

    .mt-sm-5 {
        margin-top: 3em!important
    }

    .ourtabsmbs {
        padding: 30px!important
    }

    .ourtabsmbs li {
        display: inline-block!important;
        width: auto!important
    }

    .ourtabsmbs .tabs-nav-active-underlined .tabs-nav li:after {
        bottom: 8px!important
    }

    .ourtabsmbs .tabs-nav {
        display: inline-block
    }

    a.boximageicon-seo {
        margin-right: 20px!important
    }
}

@media all and (max-width: 767px) {
    .bgnodejs-last .liquid-row-overlay {
        display:none!important
    }

    .pl-md-30 {
        padding-left: 0 !important
    }

    a.boximageicon-seo {
        padding: 1em!important;
        margin: 0 6px!important;
        display: inline!important
    }

    .text-center-767 {
        text-align: center!important
    }

    .blockdivs_767 {
        display: inline-block
    }

    .m0-mobiles {
        margin: 0 auto!important;
        text-align: center!important
    }

    .box-bullits h2 {
        margin-top: 20px!important
    }

    .d-flexnone-767,.d-flexnone-seven {
        display: inherit!important
    }

    .box-bullits ul {
        list-style: none!important
    }

    hr.hrsetseo {
        margin: 20px auto;
        text-align: center
    }

    .boximageicon-seo {
        display: inherit;
        width: 60px!important;
        height: 60px
    }

    .ournavtechnologies-home .nav.tabs-nav,.ourtabs-imageschange .nav.tabs-nav {
        flex-direction: inherit!important
    }

    .ournavtechnologies-home .col-sm-4 {
        width: 30%!important;
        display: inline-block
    }
}

@media all and (max-width: 767px) {
    .extraspcd h1 {
        position:inherit!important
    }
}

@media all and (min-width: 768px) {
    .hundrednine .col-md-5,.hundrednine .col-md-6,.hundrednine .col-md-7,.hundrednine .col-sm-5,.hundrednine .col-sm-6,.hundrednine .col-sm-7 {
        width:100%!important;
        display: block!important
    }
}

@media all and (max-width: 576px) {
    .page-blog-post .own-contents h4 {
        font-size:25px!important
    }

    .page-blog-post .own-contents h3 {
        font-size: 26px!important
    }

    .footer-bottom .col-md-6 {
        flex: initial!important;
        width: 100%;
        display: block!important
    }

    .footer-bottom .d-flex {
        margin: 0 auto;
        text-align: center;
        display: block!important
    }

    .footer-bottom .text-right {
        text-align: center!important
    }

    .page-blog-post .post-author {
        padding-top: 0!important
    }

    .footer-bottom .text-right ul {
        margin-top: 35px!important
    }

    .posted-on .entry-date {
        margin-left: 20px!important;
        font-size: 14px!important;
        text-transform: uppercase!important
    }

    .post-author figure {
        text-align: center!important;
        float: none!important;
        display: block!important;
        margin: 0 auto!important
    }

    .post-author .post-author-info {
        float: none!important;
        display: block;
        width: 100%;
        margin-top: 20px!important;
        text-align: center
    }

    nav.post-nav {
        display: none!important
    }

    .post-author {
        border: 0!important
    }

    .page-blog-post .footer-top {
        padding-top: 60px!important
    }

    .blog-single-content {
        padding-left: 15px!important;
        padding-right: 15px!important
    }

    .hotlinebox {
        display: none!important
    }

    .m0-mobiles {
        margin: 0 auto!important;
        text-align: center!important
    }

    .font-mblh1 h1 {
        font-size: 30px!important
    }

    #seopage-form #contact_page-container {
        width: 80%!important
    }

    #seopage-form {
        padding: 30px 0!important
    }

    a.boximageicon-seo {
        padding: 1em!important;
        margin: 0 6px!important;
        display: inline!important
    }

    .boximageicon-seo img {
        width: 30px
    }

    .ourseo-slides .flickity-enabled.is-draggable .flickity-viewport {
        min-height: 940px!important;
        max-height: 940px!important
    }
}

@media all and (max-width: 767px) {
    .ournav_custom_Tabs .tabs-nav {
        flex-direction:inherit
    }

    .ournav_custom_Tabs .tabs-nav-underlined .tabs-nav {
        border-bottom: 0!important
    }

    .box-bullits ul {
        list-style-type: none!important
    }

    hr.hrsetseo {
        width: 55%;
        text-align: center!important;
        border-bottom: 1px solid #eee;
        margin: 18px auto!important
    }

    .oursm-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }
}

.h-line {
    border-bottom: 1px solid rgba(151,151,151,0.16);
    display: block;
    width: 100%;
    padding: 20px 0
}

.section-forth:after {
    position: absolute;
    display: block;
    content: "";
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/section-forth-bg.png);
    background-position: top right;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: auto;
    top: -100px;
    z-index: -1;
    right: -50px
}

.section-sixth {
    background-color: #176af4
}

.section-second {
    background-color: #176af4
}

.section-one-bg {
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/triangle-bg.png);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: bottom right
}

.section-one-bg .lqd-column {
    cursor: pointer
}

.section-forth .lqd-column {
    cursor: pointer
}

.section-fifth .box-bullits:after {
    height: 155px;
    width: 155px;
    top: 50px;
    right: 0;
    background-size: contain
}

.section-fifth .carousel-nav {
    text-align: right;
    margin-top: 30px
}

.section-fifth .box-bullits ul {
    padding-left: 20px !important
}

.page-test-blockchain-development .main-footer .footer-top {
    display: none
}

@media only screen and (max-width: 600px) {
    .section-sixth {
        background-size:auto
    }

    .section-forth:after {
        background: none
    }

    .tabs-bg-wrapper-main {
        display: table !important;
        width: 100%;
        height: 100%
    }

    .tabs-sideimg {
        display: none
    }

    ._carousel-caption {
        display: block !important;
        width: 95vw;
        padding: 0 20px 30px
    }

    .sp-section-top .top-form-overlay:before {
        background-image: none !important
    }

    .sp-section-top .top-form-overlay:after {
        background-image: none !important
    }

    .sp-technology-section-before:before {
        background-image: none !important
    }

    .sp-technology-section-after:after {
        background-image: none !important
    }

    .sp-bottom-right-bg img {
        display: none !important
    }
}

@media screen and (min-width: 1024px) and (max-height:1310px) {
}

.section-sp-two .iconbox_custom {
    padding: 1.5em
}

.section-sp-three .iconbox_custom p {
    padding-bottom: 0 !important
}

.section-sp-six {
    background: #f5f5f5;
    z-index: 1
}

.section-sp-five:after {
    position: absolute;
    display: block;
    content: "";
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/sharepoint-page/sp-right-img.jpg);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 48%;
    right: 0;
    top: 0
}

.section-sp-six:after {
    position: absolute;
    display: block;
    content: "";
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/sharepoint-page/sp-left-img.jpg);
    background-position: bottom left;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 48%;
    top: 0;
    left: 0
}

.section-our-sol .fancy-box-contents {
    margin-top: -60px;
    z-index: 100;
    position: relative;
    background: white;
    margin-left: 20px;
    margin-right: 20px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-shadow: 0 30px 50px rgba(0,0,0,.07);
    box-shadow: 0 30px 50px rgba(0,0,0,.07)
}

.section-our-sol .fancy-box.fancy-box-classes {
    box-shadow: none
}

.seobackgroundcvrsp:after {
    display: block;
    content: "";
    background-color: #484be6;
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/sharepoint-page/sp-top-form-bg.png);
    background-position: top right;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 700px
}

.sp-section-top .top-form-overlay:before {
    display: block;
    content: "";
    background-color: #4869e8;
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/sharepoint-page/sp-top-before1.png);
    background-position: center 115px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: auto
}

.sp-technology-section-before:before {
    content: "";
    width: 345px;
    height: 344px;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/sharepoint-page/sp-top-after1.png) bottom right/40% 40% no-repeat;
    position: absolute;
    display: inline-block;
    bottom: -12.5%!important;
    right: 0
}

.sp-technology-section-after:after {
    content: "";
    width: 192px;
    height: 385px;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/boxround-image.png) center right/50% 50% no-repeat;
    position: absolute;
    display: inline-block;
    right: 100px;
    top: -113px;
    transform: rotate(-90deg)
}

.page-services-sharepoint-solutions button.flickity-prev-next-button.previous {
    transform: translateY(-50%) translateX(-100%)
}

.page-services-sharepoint-solutions button.flickity-prev-next-button.next {
    transform: translateY(-50%) translateX(100%)
}

.page-services-sharepoint-solutions button.flickity-prev-next-button.previous svg:after {
    content: "";
    width: 17px;
    height: 27px;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/sharepoint-page/arrow-p.png) center right no-repeat
}

.page-services-sharepoint-solutions button.flickity-prev-next-button.next svg:after {
    content: "";
    width: 17px;
    height: 27px;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/sharepoint-page/arrow-n.png) center right no-repeat
}

.page-services-sharepoint-solutions #seo-form-custom .form-action button {
    background: #484be6 !important
}

.page-services-sharepoint-solutions .flickity-prev-next-button svg line {
    display: none
}

.page-services-sharepoint-solutions .section-sp-two .flickity-prev-next-button svg {
    width: 35px;
    height: 30px
}

.services-slide-section button.flickity-prev-next-button.previous {
    transform: translateY(-50%) translateX(-100%)
}

.services-slide-section button.flickity-prev-next-button.next {
    transform: translateY(-50%) translateX(100%)
}

.services-slide-section button.flickity-prev-next-button.previous svg:after {
    content: "";
    width: 17px;
    height: 27px;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/sharepoint-page/arrow-p.png) center right no-repeat
}

.services-slide-section button.flickity-prev-next-button.next svg:after {
    content: "";
    width: 17px;
    height: 27px;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/sharepoint-page/arrow-n.png) center right no-repeat
}

.services-slide-section #seo-form-custom .form-action button {
    background: #484be6 !important
}

.services-slide-section .flickity-prev-next-button svg line {
    display: none
}

.services-slide-section .flickity-prev-next-button svg {
    width: 35px;
    height: 30px
}

.sp-section-top #seopage-form {
    z-index: 1;
    position: relative
}

.sp-bottom-right-bg img {
    width: 191px;
    height: 191px;
    position: absolute;
    bottom: -70px;
    right: -50px;
    z-index: 0
}

.form-control.error:focus {
    border-color: red !important
}

input.error {
    border-bottom: 1px solid red !important
}

.cst-transform-caps h1 .lqd-lines:nth-child(2) {
    text-transform: initial !important
}

.cst-transform-caps h1.blog-single-title {
    text-transform: initial !important
}

@media (min-width: 1025px) and (max-width:1199px) {
    .main-header .btn-sm >span {
        padding:.85em 1em
    }

    .main-header .btn-icon,.btn-txt {
        font-size: 12px !important
    }
}

.page-services-mobile-app-development #awards-recognitions-nav .carousel-nav:nth-child(2) {
    display: none
}

@media only screen and (max-width: 767px) {
    .carousel-item-inner {
        text-align:center;
        margin: 0 auto
    }

    .statepgaes-cmn-top .hero-badges-wrap .badges-row h4 {
        text-align: center;
        font-size: 16px !important;
        margin-bottom: 5px;
        display: block !important
    }

    .statepgaes-cmn-top .hero-badges-wrap .badges-row .badges {
    }

    .statepgaes-cmn-top .carousel-nav-solid .flickity-prev-next-button {
        width: 60px !important
    }

    .statepgaes-cmn-top .ourseo-slides .carousel-nav {
        display: inline-block !important;
        margin-top: 20px !important
    }

    button.flickity-button.flickity-prev-next-button.next {
        width: 60px
    }

    button.flickity-button.flickity-prev-next-button.previous {
        width: 60px
    }

    .bd-after::after {
        height: 161px !important;
        width: 121px !important;
        bottom: -63px !important;
        right: 80px !important
    }

    .bdbackgroundcvrfooter:after {
        background-image: none !important
    }

    .page-big-data-test section.pt-90 {
        padding-top: 60px !important;
        padding-bottom: 60px !important
    }

    .header-section-top-glb #seopage-form {
        width: 100% !important
    }

    #seopage-form h3 {
        font-size: 25px !important;
        text-align: left
    }
}

.cst-app-cost-blog .lqd-lines.split-unit span {
    text-transform: initial !important;
    display: table !important
}

.state-svgs-icons .iconbox-icon-container>svg {
    width: 65px
}

.state-svgs-icons .iconbox:hover .blockchain-development-icon .blockchain-development-icon-0,.state-svgs-icons .iconbox:hover .blockchain-development-icon .blockchain-development-icon-1,.state-svgs-icons .iconbox:hover .blockchain-development-icon .blockchain-development-icon-2,.state-svgs-icons .iconbox:hover .blockchain-development-icon .blockchain-development-icon-3,.state-svgs-icons .iconbox:hover .game-app-development-icon .game-app-development-icon-0,.state-svgs-icons .iconbox:hover .game-app-development-icon .game-app-development-icon-1,.state-svgs-icons .iconbox:hover .game-app-development-icon .game-app-development-icon-2,.state-svgs-icons .iconbox:hover .game-development-icon .game-development-icon-0,.state-svgs-icons .iconbox:hover .mobile-app-development-icon .mobile-app-development-icon-01,.state-svgs-icons .iconbox:hover .mobile-app-development-icon .mobile-app-development-icon-02 {
    stroke: #9ee2cf!important
}

.state-svgs-icons .iconbox .iconbox-icon-container {
    margin-bottom: .3em
}

.ourseo-slides .carousel-items >.carousel-item {
    position: inherit
}

#upload_bttn {
    border: 1px solid #b0bbca;
    border-bottom-color: rgb(176,187,202);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 15px;
    box-shadow: inset 0 2px 0 0 rgba(188,198,211,.14);
    border-bottom: 2px solid #b0bbca;
    border-radius: 3px
}

.bd-before:before {
    content: "";
    display: inline-block;
    position: absolute;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/big-data/bd-tabs-right.png) center right no-repeat;
    height: 89px;
    width: 178px;
    bottom: 80px;
    right: -110px;
    transform: rotate(90deg)
}

.bd-after:after {
    content: "";
    display: inline-block;
    position: absolute;
    background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/big-data/bd-tabs-bottom.png) center right no-repeat;
    height: 171px;
    width: 151px;
    bottom: -140px;
    right: 150px
}

.blog_next_nav_end .post-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: wrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.page-insights-post .post-single .post-image {
    text-align: center
}

.page-insights-post .post-content img {
    display: block;
    margin: 0 auto
}

.designradius .iconbox-filled-hover {
    border-radius: 4px !important
}

.designradius .iconbox-filled-hover:hover {
    border-radius: 4px !important
}

.bd-our-process .box-bullits:after {
    content: "";
    display: inline-block;
    position: absolute;
    height: 105px;
    width: 73px;
    top: 20%;
    right: 20px
}

.header-section-top-glb #seopage-form {
    width: 80%;
    float: right
}

.header-section-top-glb #seopage-form #contact_page-container {
    width: 78% !important
}

.header-section-top-glb .top-form-overlay:before {
    background-color: #4869e8;
    background-position: 58% 125px;
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/c-images/gl-top-header.png)
}

.header-section-top-glb .sp-bottom-right-bg img {
    left: 10px
}

.bd-section-top:before {
    display: block;
    content: "";
    background-image: url(https://www.cubix.co/themes/responsiv-clean/assets/images/big-data/top-bg-right-after.png);
    background-position: top right;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1
}

.state-svgs-icons1 .iconbox-icon-container>svg {
    width: 65px
}

.state-svgs-icons1 .iconbox:hover .blockchain-development-icon .blockchain-development-icon-0,.state-svgs-icons1 .iconbox:hover .blockchain-development-icon .blockchain-development-icon-1,.state-svgs-icons1 .iconbox:hover .blockchain-development-icon .blockchain-development-icon-2,.state-svgs-icons1 .iconbox:hover .blockchain-development-icon .blockchain-development-icon-3,.state-svgs-icons1 .iconbox:hover .game-app-development-icon .game-app-development-icon-0,.state-svgs-icons1.iconbox:hover .game-app-development-icon .game-app-development-icon-1,.state-svgs-icons1 .iconbox:hover .game-app-development-icon .game-app-development-icon-2,.state-svgs-icons1 .iconbox:hover .game-development-icon .game-development-icon-0,.state-svgs-icons1 .iconbox:hover .mobile-app-development-icon .mobile-app-development-icon-01,.state-svgs-icons1 .iconbox:hover .mobile-app-development-icon .mobile-app-development-icon-02 {
    stroke: #000!important
}

.state-svgs-icons1 .iconbox .iconbox-icon-container {
    margin-bottom: .3em
}

@media only screen and (max-width: 600px) {
    .col-md-custom-col {
        padding:0
    }
}

@media only screen and (min-width: 991px) {
    .col-md-custom-col {
        width:calc(100%/5);
        padding-right: 0
    }
}

.key-col {
    background: white;
    padding: 40px 25px;
    width: 100%;
    cursor: pointer
}

.key-col .liquid-counter-element {
    padding-bottom: 15px !important;
    font-size: 40px
}

.key-col .backgroundcliptext .liquid-counter-element-hover span {
}

.page-blog-post blockquote {
    padding: 0 80px;
    position: relative;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    text-align: justify !important;
    margin-left: 1.2em
}

.page-blog-post .testimonial__icon {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 5rem;
    margin-bottom: .5em;
    padding-right: 2rem;
    color: #717171
}

.scheme-gray-light {
    background-color: #F5F6F9
}

@media only screen and (max-width: 767px) {
    .scheme-gray-light {
        padding-top:3rem !important;
        padding-bottom: 3rem !important
    }

    .main-header-overlay {
        overflow-x: hidden
    }
}

.page-blog-post .own-contents ul {
    text-indent: 0
}

@media only screen and (max-width: 991px) {
    .menu-item-description a.ournavdd {
        color:#000 !important
    }

    .blog-single-content.entry-content.pull-up.expanded {
        padding-top: 30px
    }

    .blog-top-bar {
        display: none
    }

    .page-portfolio-post .section-lastbg {
        height: 40vh !important
    }

    .newsletter-section .btn-apply-job {
        width: 100%;
        margin-top: 15px
    }

    .btn-apply-job {
        margin-top: 30px
    }

    .wrapper--narrow {
        padding-left: 0 !important;
        padding-right: 0 !important
    }
}

.page-portfolio-post .section-lastbg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover !important
}

#thankyou-msg {
    height: 33px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1111111;
    width: 100%
}

#thankyou-msg .alert.alert-success.alert-dismissible.show {
    border-radius: 0;
    padding: 5px 0;
    font-size: 13px
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important
}

.form-control.error:focus {
    border-bottom: 1px solid red !important;
    border-top-color: #b0bbca !important;
    border-left-color: #b0bbca !important;
    border-right-color: #b0bbca !important
}

.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FFF;
    color: #FFF;
    -webkit-animation: dot-flashing 1s infinite linear alternate;
    animation: dot-flashing 1s infinite linear alternate;
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.dot-flashing::before,.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0
}

.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FFF;
    color: #FFF;
    -webkit-animation: dot-flashing 1s infinite alternate;
    animation: dot-flashing 1s infinite alternate;
    -webkit-animation-delay: 0s;
    animation-delay: 0s
}

.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FFF;
    color: #FFF;
    -webkit-animation: dot-flashing 1s infinite alternate;
    animation: dot-flashing 1s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

@-webkit-keyframes dot-flashing {
    0% {
        background-color: #FFF
    }

    50%,100% {
        background-color: rgba(255,255,255,0.3)
    }
}

@keyframes dot-flashing {
    0% {
        background-color: #FFF
    }

    50%,100% {
        background-color: rgba(255,255,255,0.3)
    }
}

.snippetform {
    text-align: center;
    display: table;
    margin: 0 auto;
    padding: 5px 0
}

.popup-form-custom button {
    font-size: 15px;
    padding: 1.3em .6em;
    width: 100% !important;
    font-weight: 700
}

.popup-form-custom div#messages {
    display: none !important
}

.popup-form-custom label.error {
    display: none !important
}

.popup-form-custom input.error {
    border-bottom: 1px solid red !important
}

.popup-form-custom textarea.error {
    border-bottom: 1px solid red !important
}

.popup-form-custom .form-group {
    margin-bottom: 15px !important
}

.popup-form-custom .form-control::placeholder {
    color: #000;
    opacity: 1;
    font-size: 15px
}

#footer_form #message {
    height: auto !important
}

.company-address .widget-title {
    text-transform: initial !important
}

.company-address .lqd-column:nth-child(2) {
    margin-bottom: 2em
}

.company-address p {
    margin-bottom: 0.5em
}

.boodle-port.backgroud_image_left {
    background-size: auto 100% !important;
    background-position: -110px center !important;
    background-repeat: no-repeat !important
}

.university-form-pop label {
    display: none
}

.btn-apply-job {
    display: inline-flex;
    border: 0;
    align-items: center;
    justify-content: center;
    min-width: 11.5em;
    padding: 1em 2.3em;
    border-radius: 4px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    cursor: pointer;
    background: #4869e8;
    color: white;
    text-transform: uppercase
}

.total_jobs_list .btn-apply-job {
    display: inline-flex;
    border: 0;
    align-items: center;
    justify-content: center;
    min-width: 11.5em;
    padding: .9em 2.3em;
    border-radius: 4px;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    cursor: pointer;
    background: #4869e8;
    color: white
}

.university-form-pop .form-group {
    margin-bottom: 15px !important
}

.university-form-pop .form-group input {
    width: 100%;
    font-size: 19px;
    resize: none;
    padding: 1.3em .6em;
    background: #fff;
    color: #0a0a0a;
    font-weight: 300;
    -webkit-appearance: none;
    line-height: 1.4em;
    border-radius: 3px;
    border: 1px solid #b0bbca;
    border-bottom-color: rgb(176,187,202);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom: 2px solid #b0bbca;
    box-shadow: inset 0 2px 0 0 rgba(188,198,211,.14)
}

.university-form-pop .form-control {
    height: 43px
}

.university-form-pop .form-control::placeholder {
    color: #000;
    opacity: 1;
    font-size: 15px
}

.university-form-pop .form-group input[type="file"] {
    padding: 5px 6px;
    height: 50px
}

.university-form-pop .col-md-12 {
    padding: 0
}

.jobs-list-details {
    padding: 1rem 1rem 3rem;
    text-align: left
}

article.jobs_card {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    overflow: hidden;
    transition: box-shadow 200ms
}

.jobs_card__container .jobs-title {
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.5;
    margin-top: 0;
    max-height: 35px;
    overflow: hidden;
    position: relative;
    text-transform: initial !important
}

.jobs_card__container .description {
    color: #3a3b3a;
    font-size: 12px;
    height: 100px;
    overflow: hidden;
    position: relative
}

.open-position {
    text-decoration: underline;
    color: #121212;
    font-size: 13px
}

._jobs-section .load-btn {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background: #5174fd;
    border-radius: 3px;
    box-shadow: none;
    border: 0;
    padding: 1em 2.5em;
    color: #fff;
    font-weight: 700;
    letter-spacing: .1em !important;
    text-transform: inherit;
    font-size: 13px;
    display: table;
    width: auto;
    border-radius: 4px
}

.theme--white {
    background: #FFF
}

.job-offers__title {
    text-align: center;
    color: #121212;
    padding: 0.6rem 0;
    margin-top: 0;
    font-size: 30px
}

h4.job-offers__title {
    font-size: 21px
}

.job-offers__title span {
    color: #4869e8
}

.check-icon {
    padding-left: 40px;
    list-style: none
}

.check-icon >li {
    margin: 15px 0;
    position: relative
}

.check-icon >li::before {
    content: "\f058";
    font-family: fontawesome;
    display: block;
    font-size: 25px;
    display: inline-block;
    width: 25px;
    color: #4869e8;
    height: 25px;
    margin-top: 2px;
    margin-left: -40px;
    margin-right: 15px;
    line-height: 25px;
    position: absolute;
    top: 0
}

#long_description >p {
    margin-bottom: 0
}

#long_description >p:empty {
    display: none
}

#long_description >h2 {
    margin: 10px 0
}

#forms-filter {
    margin-top: 90px
}

.form-control {
    height: 43px
}

.job-offers__label {
    display: table;
    width: 100%;
    font-weight: bold
}

@media screen and (min-width: 1024px) {
    .job-offers__container {
        display:flex;
        align-items: center;
        justify-content: center
    }

    .jobs__select__box {
        cursor: pointer;
        display: block;
        position: relative;
        font-size: 16px;
        width: 18em;
        height: 40px
    }

    .job-preview {
        display: grid;
        grid-template-columns: 5fr 3fr 2fr;
        column-gap: 1.5rem;
        text-align: left;
        width: 100%;
        padding: 60px;
        border-radius: 4px;
        border: solid .1rem #e9e9e9;
        transition-duration: 300ms;
        transition-property: box-shadow,border
    }
}

.job-preview {
    text-align: left;
    width: 100%;
    padding: 2em;
    border-radius: 4px;
    border: solid .1rem #e9e9e9;
    transition-duration: 300ms;
    transition-property: box-shadow,border
}

.job-preview .status.status--ongoing {
    margin-bottom: 0 !important
}

.job-offers__container {
    margin-right: -2.4rem;
    margin-bottom: -2.4rem
}

.job-offers__category {
}

.total_jobs_list {
    margin-top: 90px
}

.total_jobs_list .form-control {
    height: 43px
}

.job-offers__label {
    display: table;
    width: 100%;
    font-weight: bold
}

.job-offers__container {
    margin-right: -2.4rem;
    margin-bottom: -2.4rem
}

.job-offers__category {
}

.total_jobs_list select.form-control {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    border: 1px solid #878787;
    border-radius: .3em
}

.total_jobs_list .switch {
    position: relative;
    display: inline-block;
    width: 2.7em
}

.total_jobs_list .switch input {
    opacity: 0;
    width: 0;
    height: 0
}

.total_jobs_list .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #999;
    -webkit-transition: .4s;
    transition: .4s;
    width: 3.4em
}

.total_jobs_list .slider:before {
    position: absolute;
    content: "";
    height: 1.5em;
    width: 1.5em;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s
}

.total_jobs_list input:checked + .slider {
    background-color: #2196F3
}

.total_jobs_list input:focus + .slider {
    box-shadow: 0 0 1px #2196F3
}

.total_jobs_list input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px)
}

.total_jobs_list .slider.round {
    border-radius: 34px
}

.total_jobs_list .slider.round:before {
    border-radius: 50%
}

.job-offers__list {
    width: 100%;
    padding-bottom: 0rem
}

.job-preview:hover {
    box-shadow: 20px 20px 40px 0 rgba(75,75,75,0.12);
    border: solid .1rem #fff;
    border: solid .1rem #e9e9e9
}

.job-preview__header {
    color: #121212;
    margin-bottom: 1rem;
    cursor: pointer;
    display: block;
    margin-top: 0;
    font-size: 22px
}

.job-preview__details--underscore {
    color: #121212;
    text-decoration: underline;
    font-size: 13px
}

.status--ongoing {
    color: #0643d6;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-top: 40px
}

.total_jobs_list .status {
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    color: #4869e8;
    margin-top: 40px
}

.job-preview__subheader {
    font-weight: bold;
    color: #121212;
    margin-bottom: 1rem;
    font-size: 16px
}

.job-preview__details {
    font-size: 13px;
    color: #121212
}

.job-preview__list {
    margin-top: 10px;
    padding-left: 0
}

.job-preview__list .job-preview__details {
    display: inline-block;
    color: #4b4b4b
}

.job-preview__details--underscore {
    color: #4b4b4b;
    text-decoration: underline
}

.job-offers__title {
    text-align: center;
    color: #121212;
    padding: 0.6rem 0;
    margin-top: 0;
    font-size: 30px
}

.job-offers__title span {
    color: #4869e8
}

.job-preview__details.job-preview__details--underscore {
    display: block;
    margin-top: 40px;
    text-align: center
}

.job-preview:not(:last-child) {
    margin-bottom: 25px
}

.jobs-list-details {
    padding: 2em
}

.return_offers {
    display: inline-flex;
    align-items: center;
    color: #999;
    font-size: 13px
}

.return_offers__label {
    margin-left: 1em;
    font-weight: bold
}

article.jobs_card:hover {
    box-shadow: 0 2rem 4rem rgba(0,0,0,.1)
}

article.jobs_card:hover .jobs-title {
    color: #4869e8
}

.status-soon {
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    color: #f7b329
}

.consultant__author {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px 10px
}

.consultant__author .author {
    flex: 1 0 auto
}

.consultant__author .author {
    display: flex;
    align-items: center
}

.consultant__author .consultant {
    padding: 40px;
    border: 1px solid #e3e3e3;
    border-radius: .6rem
}

.consultant__author .author__photo {
    width: 80px;
    height: 80px;
    border: 1px solid #e3e3e3;
    flex: none;
    display: inline-flex;
    margin-right: 10px;
    border-radius: 50%
}

.consultant__link {
    padding: 5px 2px 6px;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    font-size: 13px;
    font-weight: bold;
    color: #0f74b6;
    display: flex;
    align-items: center
}

.consultant__logo {
    background-color: #0f74b6;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    margin-right: 1px
}

.job-offer__form {
    display: flex;
    z-index: 11
}

.layout--column .wrapper {
    display: grid;
    grid-gap: 6rem 2.4rem;
    grid-template-columns: 100%
}

.job-offer .job-details {
    position: sticky;
    position: -webkit-sticky;
    top: 10rem
}

.status--ongoing {
    color: #0643d6
}

.job-details__b2b .h4 {
    font-weight: bold;
    font-size: 21px;
    line-height: 1.5
}

.job-details__b2b-label {
    color: #4b4b4b;
    font-size: 16px
}

.job-details__coe {
    margin-bottom: 30px
}

.job-details__coe-salary {
    font-weight: bold;
    font-size: 15px
}

label._sr-only {
    font-size: 14px
}

.job-details__coe-label {
    color: #999;
    font-size: 13px;
    padding-left: 10px
}

.form .hs_email {
    width: 100%;
    position: relative;
    text-align: left
}

.wrapper--narrow {
    max-width: 48.4rem
}

.wrapper--narrow {
    max-width: 124.8rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 80px;
    padding-right: 80px
}

.layout--blocks__block {
    flex: 0 1 50%;
    min-width: 0;
    padding: 30px 0
}

.layout--blocks__block .wrapper--narrow {
    max-width: 48.4rem
}

.job-offer .wrapper {
    align-items: start
}

.job-details {
    display: flex;
    flex-direction: column
}

.job-description .icon-list {
    margin-bottom: 90px
}

.icon-list {
    padding-left: 0;
    list-style: none
}

.icon-list__item:not(:last-child) {
    margin-bottom: 10px
}

.icon-list__item {
    margin-bottom: 30px
}

.icon-list__icon {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    background-color: #f7f8f7;
    border-radius: 50%
}

.icon-list__icon img {
    max-width: 20px;
    max-height: 20px
}

.icon-list .rich-text,.icon-list__label {
    margin: auto 0;
    font-size: 13px
}

.icon-list__item {
    display: flex
}

.job-offers__title {
    margin-top: 20px;
    margin-bottom: 10px
}

.veiw-all-port {
    padding: 1.15em 2.1em;
    color: white !important;
    font-size: 14px!important;
    font-weight: 600!important;
    border-width: 1px;
    letter-spacing: .1em;
    border-radius: 4px;
    background: var(--color-primary);
    text-align: center !important;
    text-transform: uppercase !important;
    display: inline-block !important;
    margin-top: 10px
}

.author__name {
    font-weight: bold;
    font-size: 18px;
    color: #3a3b3a
}

.author__caption {
    color: #878787;
    font-size: 14px;
    line-height: initial
}

.stacks-faqs-wrapper .accordion-active-color-primary .active .accordion-title a {
    color: #000;
    text-transform: initial
}

.stacks-faqs-wrapper .accordion-item.panel {
    margin-bottom: 0
}

.stacks-faqs-wrapper .accordion-title a {
    padding: 2em 30px 2em 0;
    text-transform: initial;
    font-size: 24px
}

.spotit-portfolio {
    background-size: contain !important;
    background-color: #00b3d9 !important;
    background-position: -110px center !important
}

.oomco-port h2 {
    text-transform: initial !important
}

.hs_custom_form label {
    text-align: left
}

.blog-single-content h3 {
    font-size: 28px
}

.blog-single-content h4 {
    font-size: 24px !important
}

.blog-single-content h5 {
    font-size: 20px !important;
    font-family: 'Averta',sans-serif !important ;
    color: rgb(0 0 0) !important;
    font-weight: 700 !important
}

@media screen and (max-width: 1023px) {
    .likeman-port .fancy-title.mb-60.text-portfolion {
        background:#2d2295b5
    }

    .text-portfolion {
        width: 100%;
        padding: 10px
    }

    .boodle-port .text-portfolion {
        background: #3427DBAD
    }
}

:root {
    --color-primary: #4869e8;
    --color-gradient-start: #4762ff;
    --color-gradient-stop: #ff798e;
    --color-gradient-start-secondary: #0bccee;
    --color-gradient-stop-secondary: #ac1de1;
    --color-gradient-start-tertiary: #ffba83;
    --color-gradient-stop-tertiary: #ffa5cd
}

body {
    font-family: 'Averta',sans-serif;
    font-weight: 400;
    line-height: 1.6;
    font-size: 1em;
    color: #000
}

.h1,h1, .h2,h2, .h3,h3, .h4,h4, .h5,h5, .h6,h6 {
    font-family: 'Averta',sans-serif;
    color: #000
}

.h1, h1 {
    font-weight: 700;
    line-height: 1.244em;
    font-size: 45px
}

.h2, h2 {
    font-size: 42px;
    font-weight: 700;
    line-height: 1.285em
}

.h3, h3 {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.342em
}

.h4, h4 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.35em
}

.h5, h5 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.35em
}

.h6, h6 {
    font-size: 21px;
    font-weight: 400;
    line-height: 1.2em
}

p {
    line-height: 1.7;
    font-size: 1.2em
}

.main-nav >li >a {
    font-size: 17px;
    letter-spacing: 0.02em;
    color: #fff
}

.main-nav >li.is-active >a, .main-nav >li.current-menu-item >a, .main-nav >li:hover >a, .main-nav >li >a:hover {
    color: rgba(255,255,255,0.85)
}

.main-footer {
    color: #808291
}

.main-footer .footer-middle *,.main-footer .footer-bottom * {
    font-size: 1em
}

@media (min-width: 991px) {
    .main-header .is-stuck {
        background:#fff !important
    }

    .mainbar-wrap.is-stuck .social-icon a,.mainbar-wrap.is-stuck .header-module .ld-module-trigger,.mainbar-wrap.is-stuck .main-nav >li >a,.mainbar-wrap.is-stuck .ld-module-search-visible-form .ld-search-form input,.mainbar-wrap.is-stuck .header-module .iconbox i,.mainbar-wrap.is-stuck .header-module .iconbox h3 {
        color: #424242 !important
    }

    .mainbar-wrap.is-stuck .ld-module-search-visible-form .ld-search-form input {
        border-color: #424242 !important
    }

    .is-stuck .btn {
        color: #fff;
        border-color: #5174fd
    }

    .is-stuck .btn.btn-solid {
        background-color: #5174fd
    }

    .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .btn.btn-solid {
        background-color: #5174fd;
        color: #fff
    }

    .is-stuck .btn:hover {
        border-color: #3a3a3a;
        color: #fff
    }

    .is-stuck .btn:hover,.is-stuck .btn.btn-solid:hover {
        background-color: #3a3a3a
    }

    .megamenu-heading {
        color: #000
    }
}

.mejs__offscreen {
    border: 0;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal
}

.mejs__container {
    background: #000;
    font-family: Helvetica,Arial,serif;
    position: relative;
    text-align: left;
    text-indent: 0;
    vertical-align: top
}

.mejs__container,.mejs__container * {
    box-sizing: border-box
}

.mejs__container video::-webkit-media-controls,.mejs__container video::-webkit-media-controls-panel,.mejs__container video::-webkit-media-controls-panel-container,.mejs__container video::-webkit-media-controls-start-playback-button {
    -webkit-appearance: none;
    display: none!important
}

.mejs__fill-container,.mejs__fill-container .mejs__container {
    height: 100%;
    width: 100%
}

.mejs__fill-container {
    background: transparent;
    margin: 0 auto;
    overflow: hidden;
    position: relative
}

.mejs__container:focus {
    outline: none
}

.mejs__iframe-overlay {
    height: 100%;
    position: absolute;
    width: 100%
}

.mejs__embed,.mejs__embed body {
    background: #000;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%
}

.mejs__fullscreen {
    overflow: hidden!important
}

.mejs__container-fullscreen {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000
}

.mejs__container-fullscreen .mejs__mediaelement,.mejs__container-fullscreen video {
    height: 100%!important;
    width: 100%!important
}

.mejs__background,.mejs__mediaelement {
    left: 0;
    position: absolute;
    top: 0
}

.mejs__mediaelement {
    height: 100%;
    width: 100%;
    z-index: 0
}

.mejs__poster {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1
}

:root .mejs__poster-img {
    display: none
}

.mejs__poster-img {
    border: 0;
    padding: 0
}

.mejs__overlay {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0
}

.mejs__layer {
    z-index: 1
}

.mejs__overlay-play {
    cursor: pointer
}

.mejs__overlay-button {
    /* background: url(../themes/responsiv-clean/assets/vendors/mediaelement/mejs-controls.svg) no-repeat; */
    background-position: 0 -39px;
    height: 80px;
    width: 80px
}

.mejs__overlay:hover>.mejs__overlay-button {
    background-position: -80px -39px
}

.mejs__overlay-loading {
    height: 80px;
    width: 80px
}

.mejs__overlay-loading-bg-img {
}

@-webkit-keyframes a {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes a {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.mejs__controls {
    bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    left: 0;
    list-style-type: none;
    margin: 0;
    padding: 0 10px;
    position: absolute;
    width: 100%;
    z-index: 3
}

.mejs__controls:not([style*="display:none"]) {
    background: rgba(255,0,0,.7);
    background: -webkit-linear-gradient(transparent,rgba(0,0,0,.35));
    background: linear-gradient(transparent,rgba(0,0,0,.35))
}

.mejs__button,.mejs__time,.mejs__time-rail {
    font-size: 10px;
    height: 40px;
    line-height: 10px;
    margin: 0;
    width: 32px
}

.mejs__button>button {
    /* background: transparent url(../themes/responsiv-clean/assets/vendors/mediaelement/mejs-controls.svg); */
    border: 0;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0;
    margin: 10px 6px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-decoration: none;
    width: 20px
}

.mejs__button>button:focus {
    outline: 1px dotted #999
}

.mejs__container-keyboard-inactive [role=slider],.mejs__container-keyboard-inactive [role=slider]:focus,.mejs__container-keyboard-inactive a,.mejs__container-keyboard-inactive a:focus,.mejs__container-keyboard-inactive button,.mejs__container-keyboard-inactive button:focus {
    outline: 0
}

.mejs__time {
    box-sizing: content-box;
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    height: 24px;
    overflow: hidden;
    padding: 16px 6px 0;
    text-align: center;
    width: auto
}

.mejs__play>button {
    background-position: 0 0
}

.mejs__pause>button {
    background-position: -20px 0
}

.mejs__replay>button {
    background-position: -160px 0
}

.mejs__time-rail {
    direction: ltr;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 40px;
    margin: 0 10px;
    padding-top: 10px;
    position: relative
}

.mejs__time-buffering,.mejs__time-current,.mejs__time-float,.mejs__time-float-corner,.mejs__time-float-current,.mejs__time-hovered,.mejs__time-loaded,.mejs__time-marker,.mejs__time-total {
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute
}

.mejs__time-total {
    background: hsla(0,0%,100%,.3);
    margin: 5px 0 0;
    width: 100%
}

.mejs__time-buffering {
    -webkit-animation: b 2s linear infinite;
    animation: b 2s linear infinite;
    background: -webkit-linear-gradient(135deg,hsla(0,0%,100%,.4) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.4) 0,hsla(0,0%,100%,.4) 75%,transparent 0,transparent);
    background: linear-gradient(-45deg,hsla(0,0%,100%,.4) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.4) 0,hsla(0,0%,100%,.4) 75%,transparent 0,transparent);
    background-size: 15px 15px;
    width: 100%
}

@-webkit-keyframes b {
    0% {
        background-position: 0 0
    }

    to {
        background-position: 30px 0
    }
}

@keyframes b {
    0% {
        background-position: 0 0
    }

    to {
        background-position: 30px 0
    }
}

.mejs__time-loaded {
    background: hsla(0,0%,100%,.3)
}

.mejs__time-current,.mejs__time-handle-content {
    background: hsla(0,0%,100%,.9)
}

.mejs__time-hovered {
    background: hsla(0,0%,100%,.5);
    z-index: 10
}

.mejs__time-hovered.negative {
    background: rgba(0,0,0,.2)
}

.mejs__time-buffering,.mejs__time-current,.mejs__time-hovered,.mejs__time-loaded {
    left: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: all .15s ease-in;
    transition: all .15s ease-in;
    width: 100%
}

.mejs__time-buffering {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.mejs__time-hovered {
    -webkit-transition: height .1s cubic-bezier(.44,0,1,1);
    transition: height .1s cubic-bezier(.44,0,1,1)
}

.mejs__time-hovered.no-hover {
    -webkit-transform: scaleX(0)!important;
    -ms-transform: scaleX(0)!important;
    transform: scaleX(0)!important
}

.mejs__time-handle,.mejs__time-handle-content {
    border: 4px solid transparent;
    cursor: pointer;
    left: 0;
    position: absolute;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    z-index: 11
}

.mejs__time-handle-content {
    border: 4px solid hsla(0,0%,100%,.9);
    border-radius: 50%;
    height: 10px;
    left: -7px;
    top: -4px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    width: 10px
}

.mejs__time-rail .mejs__time-handle-content:active,.mejs__time-rail .mejs__time-handle-content:focus,.mejs__time-rail:hover .mejs__time-handle-content {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.mejs__time-float {
    background: #eee;
    border: 1px solid #333;
    bottom: 100%;
    color: #111;
    display: none;
    height: 17px;
    margin-bottom: 9px;
    position: absolute;
    text-align: center;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 36px
}

.mejs__time-float-current {
    display: block;
    left: 0;
    margin: 2px;
    text-align: center;
    width: 30px
}

.mejs__time-float-corner {
    border: 5px solid #eee;
    border-color: #eee transparent transparent;
    border-radius: 0;
    display: block;
    height: 0;
    left: 50%;
    line-height: 0;
    position: absolute;
    top: 100%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0
}

.mejs__long-video .mejs__time-float {
    margin-left: -23px;
    width: 64px
}

.mejs__long-video .mejs__time-float-current {
    width: 60px
}

.mejs__broadcast {
    color: #fff;
    height: 10px;
    position: absolute;
    top: 15px;
    width: 100%
}

.mejs__fullscreen-button>button {
    background-position: -80px 0
}

.mejs__unfullscreen>button {
    background-position: -100px 0
}

.mejs__mute>button {
    background-position: -60px 0
}

.mejs__unmute>button {
    background-position: -40px 0
}

.mejs__volume-button {
    position: relative
}

.mejs__volume-button>.mejs__volume-slider {
    -webkit-backface-visibility: hidden;
    background: rgba(50,50,50,.7);
    border-radius: 0;
    bottom: 100%;
    display: none;
    height: 115px;
    left: 50%;
    margin: 0;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 25px;
    z-index: 1
}

.mejs__volume-button:hover {
    border-radius: 0 0 4px 4px
}

.mejs__volume-total {
    background: hsla(0,0%,100%,.5);
    height: 100px;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 8px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 2px
}

.mejs__volume-current {
    left: 0;
    margin: 0;
    width: 100%
}

.mejs__volume-current,.mejs__volume-handle {
    background: hsla(0,0%,100%,.9);
    position: absolute
}

.mejs__volume-handle {
    border-radius: 1px;
    cursor: ns-resize;
    height: 6px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 16px
}

.mejs__horizontal-volume-slider {
    display: block;
    height: 36px;
    position: relative;
    vertical-align: middle;
    width: 56px
}

.mejs__horizontal-volume-total {
    background: rgba(50,50,50,.8);
    height: 8px;
    top: 16px;
    width: 50px
}

.mejs__horizontal-volume-current,.mejs__horizontal-volume-total {
    border-radius: 2px;
    font-size: 1px;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute
}

.mejs__horizontal-volume-current {
    background: hsla(0,0%,100%,.8);
    height: 100%;
    top: 0;
    width: 100%
}

.mejs__horizontal-volume-handle {
    display: none
}

.mejs__captions-button,.mejs__chapters-button {
    position: relative
}

.mejs__captions-button>button {
    background-position: -140px 0
}

.mejs__chapters-button>button {
    background-position: -180px 0
}

.mejs__captions-button>.mejs__captions-selector,.mejs__chapters-button>.mejs__chapters-selector {
    background: rgba(50,50,50,.7);
    border: 1px solid transparent;
    border-radius: 0;
    bottom: 100%;
    margin-right: -43px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 50%;
    visibility: visible;
    width: 86px
}

.mejs__chapters-button>.mejs__chapters-selector {
    margin-right: -55px;
    width: 110px
}

.mejs__captions-selector-list,.mejs__chapters-selector-list {
    list-style-type: none!important;
    margin: 0;
    overflow: hidden;
    padding: 0
}

.mejs__captions-selector-list-item,.mejs__chapters-selector-list-item {
    color: #fff;
    cursor: pointer;
    display: block;
    list-style-type: none!important;
    margin: 0 0 6px;
    overflow: hidden;
    padding: 0 10px
}

.mejs__captions-selector-list-item:hover,.mejs__chapters-selector-list-item:hover {
    background-color: #c8c8c8!important;
    background-color: hsla(0,0%,100%,.4)!important
}

.mejs__captions-selector-input,.mejs__chapters-selector-input {
    clear: both;
    float: left;
    left: -1000px;
    margin: 3px 3px 0 5px;
    position: absolute
}

.mejs__captions-selector-label,.mejs__chapters-selector-label {
    cursor: pointer;
    float: left;
    font-size: 10px;
    line-height: 15px;
    padding: 4px 0 0
}

.mejs__captions-selected,.mejs__chapters-selected {
    color: #21f8f8
}

.mejs__captions-translations {
    font-size: 10px;
    margin: 0 0 5px
}

.mejs__captions-layer {
    bottom: 0;
    color: #fff;
    font-size: 16px;
    left: 0;
    line-height: 20px;
    position: absolute;
    text-align: center
}

.mejs__captions-layer a {
    color: #fff;
    text-decoration: underline
}

.mejs__captions-layer[lang=ar] {
    font-size: 20px;
    font-weight: 400
}

.mejs__captions-position {
    bottom: 15px;
    left: 0;
    position: absolute;
    width: 100%
}

.mejs__captions-position-hover {
    bottom: 35px
}

.mejs__captions-text,.mejs__captions-text * {
    background: hsla(0,0%,8%,.5);
    box-shadow: 5px 0 0 hsla(0,0%,8%,.5),-5px 0 0 hsla(0,0%,8%,.5);
    padding: 0;
    white-space: pre-wrap
}

.mejs__container.mejs__hide-cues video::-webkit-media-text-track-container {
    display: none
}

.mejs__overlay-error {
    position: relative
}

.mejs__overlay-error>img {
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1
}

.mejs__cannotplay,.mejs__cannotplay a {
    color: #fff;
    font-size: .8em
}

.mejs__cannotplay {
    position: relative
}

.mejs__cannotplay a,.mejs__cannotplay p {
    display: inline-block;
    padding: 0 15px;
    width: 100%
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: none
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-track, .slick-slider .slick-list {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0)
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:before, .slick-track:after {
    display: table;
    content: ''
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

[dir='rtl'] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

@charset 'UTF-8';.slick-loading .slick-list {
    /* background: #fff url('../themes/responsiv-clean/assets/css/./ajax-loader.gif') center center no-repeat */
}

@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    /* src: url('../themes/responsiv-clean/assets/css/./fonts/slick.eot'); */
    /* src: url('../themes/responsiv-clean/assets/css/./fonts/slick.eot?#iefix') format('embedded-opentype'),url('../themes/responsiv-clean/assets/css/./fonts/slick.woff') format('woff'),url('../themes/responsiv-clean/assets/css/./fonts/slick.ttf') format('truetype'),url('../themes/responsiv-clean/assets/css/./fonts/slick.svg#slick') format('svg') */
}

.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
    opacity: 1
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: .25
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-prev {
    left: -25px
}

[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto
}

.slick-prev:before {
    content: '←'
}

[dir='rtl'] .slick-prev:before {
    content: '→'
}

.slick-next {
    right: -25px
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px
}

.slick-next:before {
    content: '→'
}

[dir='rtl'] .slick-next:before {
    content: '←'
}

.slick-dotted.slick-slider {
    margin-bottom: 30px
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent
}

.slick-dots li button:hover, .slick-dots li button:focus {
    outline: none
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black
}

div#seopage-form {
    background: #fff !important
}

.hs_custom_form label:after {
    display: none !important
}

.hs_custom_form .required-control .control-label:after {
    display: none !important;
    ;color : transparent !important;
    content: none !important
}

.hs_custom_form .form-group {
    border: 0 !important;
    margin-bottom: 25px !important
}

.hs_custom_form .form-group input {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    padding-left: 0 ;
    border-radius: 0 !important;
    height: 30px !important
}

.hs_custom_form .form-group textarea {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    padding-left: 0 ;
    border-radius: 0 !important;
    height: 30px !important;
    overflow-y: hidden !important
}

.hs_custom_form label {
    margin-bottom: 0 !important;
    display: block
}

.hs_custom_form ::-webkit-input-placeholder {
    color: #fff !important;
    display: none !important
}

.hs_custom_form ::-moz-placeholder {
    color: #fff !important;
    display: none !important
}

.hs_custom_form :-ms-input-placeholder {
    color: #fff !important;
    display: none !important
}

.hs_custom_form :-moz-placeholder {
    color: #fff !important;
    display: none !important
}

.hs_custom_form input[type=text]:focus,.hs_custom_form textarea:focus {
    outline: none;
    box-shadow: none !important;
    border-bottom: 1px solid #1869f4
}

.hs_custom_form input[type=email]:focus,.hs_custom_form textarea:focus {
    outline: none;
    box-shadow: none !important;
    border-bottom: 1px solid #1869f4
}

.hs_custom_form div#messages {
    display: none !important
}

.hs_custom_form label.error {
    display: none !important
}

.hs_custom_form input.error {
    border-bottom: 1px solid red !important
}

.hs_custom_form textarea.error {
    border-bottom: 1px solid red !important
}

.hs_custom_form textarea {
    border-radius: 0 !important;
    height: 30px !important
}

.hs_custom_form button {
    height: 49px;
    width: 100%;
    background: #07013b !important;
    border-radius: 12px !important;
    border: 0 !important;
    box-shadow: none !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    margin-top: 10px
}

.hs_custom_form button:hover {
    background: #130d42 !important
}

.hs_custom_form button:focus {
    border-radius: 12px !important;
    border: 0 !important;
    outline: none !important
}

.form-group,.form-action {
    margin-bottom: 0 !important
}

#seopage-form #contact_page-container {
    padding: 0;
    width: 70% !important;
    margin: 0 auto !important
}

.hs_custom_form label {
    color: #aeadad;
    font-weight: 500;
    font-size: 14px
}

.seo-form-custom-footer .form-group:nth-child(2) {
    width: 48%;
    display: inline-block
}

.seo-form-custom-footer .form-group:nth-child(3) {
    width: 48%;
    display: inline-block;
    float: right
}

.hs_custom_form .seo-form-custom-footer .hs_custom_form .form-group textarea {
    height: 60px !important
}

.hs_custom_form .form-control.error:focus {
    border-color: red !important;
    border-bottom: 1px solid red !important
}

.hs_custom_form input.error {
    border-bottom: 1px solid red !important
}

.hs_custom_form .form-control {
    box-shadow: none;
    color: #313941;
    font-size: 15px
}

.hs_custom_game_form label {
    display: none
}

.hs_custom_game_form input.form-control {
    height: 60px !important;
    border: 1px solid #455da5;
    padding-left: 25px !important;
    color: white !important
}

.hs_custom_game_form label:after {
    display: none
}

.hs_custom_game_form .required-control .control-label:after {
    display: none !important;
    ;color : transparent !important;
    content: none !important
}

.hs_custom_game_form .form-group {
    border: 0 !important;
    margin-bottom: 25px !important;
    color: white !important
}

.hs_custom_game_form .form-group input {
    border: 1px solid #455da5 !important;
    background: rgba(15,17,41,0.52);
    padding-left: 25px;
    border-radius: 0 !important;
    height: 50px !important;
    color: white !important
}

.hs_custom_game_form .form-group textarea {
    border: 1px solid #455da5 !important;
    color: white !important;
    padding-left: 25px;
    border-radius: 0 !important;
    height: 141px !important;
    overflow-y: hidden !important;
    background: rgba(15,17,41,0.52)
}

.hs_custom_game_form .form-group:nth-child(2) {
    float: left;
    width: 48%;
    display: inline-table
}

.hs_custom_game_form .form-group:nth-child(3) {
    float: right;
    width: 47%;
    display: inline-table
}

.hs_custom_game_form label {
    margin-bottom: 0 !important
}

.hs_custom_game_form .btn.btn-primary {
    background: #008bff !important;
    border-radius: 0 !important;
    height: 49px
}

.hs_custom_game_form div#messages {
    display: none !important
}

.hs_custom_game_form label.error {
    display: none !important
}

.hs_custom_game_form input.error {
    border-bottom: 1px solid red !important
}

.hs_custom_game_form textarea.error {
    border-bottom: 1px solid red !important
}

.ar_hs_form label {
    display: none
}

.ar_hs_form input.form-control {
    height: 60px !important;
    border: 1px solid #FFF;
    padding-left: 25px !important;
    color: white !important
}

.ar_hs_form label:after {
    display: none
}

.ar_hs_form .required-control .control-label:after {
    display: none !important;
    ;color : transparent !important;
    content: none !important
}

.ar_hs_form .form-group {
    border: 0 !important;
    margin-bottom: 25px !important;
    color: white !important
}

.ar_hs_form .form-group input {
    border: 1px solid #FFF !important;
    background: rgba(114,29,235,0.14);
    padding-left: 25px;
    border-radius: 0 !important;
    height: 50px !important;
    color: white !important
}

.ar_hs_form .form-group textarea {
    border: 1px solid #FFF !important;
    color: white !important;
    padding-left: 25px;
    border-radius: 0 !important;
    height: 141px !important;
    overflow-y: hidden !important;
    background: rgba(114,29,235,0.14)
}

.ar_hs_form .form-group:nth-child(2) {
    float: left;
    width: 48%;
    display: inline-table
}

.ar_hs_form .form-group:nth-child(3) {
    float: right;
    width: 47%;
    display: inline-table
}

.ar_hs_form label {
    margin-bottom: 0 !important
}

.ar_hs_form div#messages {
    display: none !important
}

.ar_hs_form label.error {
    display: none !important
}

.ar_hs_form input.error {
    border-bottom: 1px solid red !important
}

.ar_hs_form textarea.error {
    border-bottom: 1px solid red !important
}

.ar_hs_form textarea {
    border-radius: 0 !important;
    height: 30px !important
}

.ar_hs_form button {
    height: 49px;
    width: 100%;
    background: #721deb !important;
    border-radius: 12px !important;
    border: 0 !important;
    box-shadow: none !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    margin-top: 10px;
    border-radius: 0 !important
}

.ar_hs_form button:hover {
    background: #6309e3 !important
}

.ar_hs_form button:focus {
    border-radius: 0 !important;
    border: 0 !important;
    outline: none !important
}

.contact-page-form-custom .btn-primary {
    background: #03b4ff!important
}

.contact-page-form-custom .form-group input {
    width: 100%;
    font-weight: 300;
    font-size: 19px;
    resize: none;
    border: 0;
    padding: 1.4em .6em;
    background: #fff;
    color: #0a0a0a;
    font-weight: 300;
    -webkit-appearance: none;
    line-height: 1.4em;
    border-radius: 3px;
    border: 1px solid #b0bbca;
    border-bottom: 2px solid #b0bbca;
    box-shadow: inset 0 2px 0 0 rgba(188,198,211,0.14)
}

.contact-page-form-custom .form-group textarea {
    width: 100%;
    padding: .6em .6em 1.4em;
    font-weight: 300;
    font-size: 19px;
    resize: none;
    border: 0;
    background: #fff;
    color: #0a0a0a;
    font-weight: 300;
    -webkit-appearance: none;
    line-height: 1.4em;
    border-radius: 3px;
    border: 1px solid #b0bbca;
    border-bottom: 2px solid #b0bbca;
    box-shadow: inset 0 2px 0 0 rgba(188,198,211,0.14)
}

.contact-page-form-custom button {
    font-size: 15px;
    padding: 1.4em .6em;
    width: 100%!important;
    font-weight: 700
}

.contact-page-form-custom .form-group label.error {
    color: red;
    font-size: 12px;
    font-weight: 400
}

.contact-page-form-custom textarea.error {
    border-bottom: 2px solid red!important
}

.contact-page-form-custom .form-group label.error {
    display: none!important
}

.contact-page-form-custom .form-group input.error {
    border-bottom: 2px solid red!important
}

.contact-page-form-custom .form-group textarea.error {
    border-bottom: 2px solid red!important
}

.contact-page-form-custom .form-group:nth-child(2) {
    width: 49%;
    display: inline-block!important
}

.contact-page-form-custom .form-group:nth-child(3) {
    width: 49%;
    display: inline-block!important;
    text-align: right;
    position: absolute;
    right: 0!important
}

.contact-page-form-custom .form-group {
    margin-bottom: 15px !important
}

.how-to-upload-app-to-google-play-store #header-container {
    display: none !important
}

.how-to-upload-app-to-google-play-store .modal-dialog-centered {
    display: none !important
}

.how-to-upload-app-to-google-play-store .modal-backdrop.in {
    display: none !important
}

.how-to-upload-app-to-google-play-store .form_div_toggle {
    display: none !important
}

.how-to-upload-app-to-google-play-store .main-footer .footer-top .btn-solid {
    display: none !important
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.mfp-align-top .mfp-container:before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-inline-holder .mfp-content,.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-close,.mfp-arrow,.mfp-preloader,.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none !important
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

.mfp-preloader a {
    color: #CCC
}

.mfp-preloader a:hover {
    color: #FFF
}

.mfp-s-ready .mfp-preloader {
    display: none
}

.mfp-s-error .mfp-content {
    display: none
}

button.mfp-close,button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial,Baskerville,monospace
}

.mfp-close:hover,.mfp-close:focus {
    opacity: 1
}

.mfp-close:active {
    top: 1px
}

.mfp-close-btn-in .mfp-close {
    color: #333
}

.mfp-image-holder .mfp-close,.mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:hover,.mfp-arrow:focus {
    opacity: 1
}

.mfp-arrow:before,.mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7
}

.mfp-arrow-left {
    left: 0
}

.mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F
}

.mfp-arrow-right {
    right: 0
}

.mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px
}

.mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0,0,0,0.6);
    background: #000
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto
}

.mfp-figure {
    line-height: 0
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0,0,0,0.6);
    background: #444
}

.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-figure figure {
    margin: 0
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

@media screen and (max-width: 800px) and (orientation:landscape),screen and (max-height:300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left:0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0,0,0,0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0,0,0,0.6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform:scale(0.75);
        transform: scale(0.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}


/* Custom By Tauqeer */

.box-hover:hover, .box-hover:hover h3{
color:white;
background-color:#4869e8;
}
.collapse {
    visibility: inherit !important;
}
/* Custom By Tauqeer  END*/

